import React from 'react'

export const SixImageGallery = ({ value }) => {
	return (
		<>
			<div className="flex flex-1 flex-col gap-2 btnnn">
				<div className="flex flex-1 flex-col" >
					<img className="object-cover h-full" src={value?.wall_images[0]} alt='' />
				</div>
				<div className="hidden md:flex flex-1 flex-row gap-2">
					<div className="flex flex-1 flex-col" >
						<img className="object-cover h-full" src={value?.wall_images[1]} alt='' />
					</div>
					<div className="hidden md:flex flex-1 flex-col">
						<img className="object-cover h-full" src={value?.wall_images[2]} alt='' />
					</div>
				</div>
			</div>

			<div className="flex flex-1 flex-col gap-2 btnnn">
				<div className="hidden md:flex flex-1 flex-row gap-2" >
					<div className="flex flex-1 flex-col">
						<img className="object-cover h-full" src={value?.wall_images[3]} alt='' />
					</div>
					<div className="hidden md:flex flex-1 flex-col" >
						<img className="object-cover h-full" src={value?.wall_images[4]} alt='' />
					</div>
				</div>

				<div className="flex flex-1 flex-col relative" id={`openModalBtn_${value?.wall_images[5]}`}>
					<img src={value?.wall_images[5]} className='object-cover h-full' alt={`image-${value?.wall_images[5]}`} />
					{
						value?.wall_images?.length > 6 && (
							<span className="absolute fs-30 dark-color rubik-500 grid place-items-center h-full w-full">
								+{value.wall_images.length - 6}
							</span>
						)
					}
				</div>
			</div>

			<div id="myModal" className="modal ot_imgGallery" style={{ display: 'none' }}>
				<span className="close">&times;</span>
				<div className="modal-content">
					<div className="slider">
						{value?.wall_images?.map((value, index) => {
							return (
								<div key={index}><img src={value} alt="Image 1" /></div>
							)
						})}
					</div>
				</div>
			</div>
		</>
	)
}

export const FiveImageGallery = ({ value }) => {
	return (<React.Fragment>
		<div className="flex flex-1 flex-col gap-2">
			<div className="flex flex-1 flex-col">
				<img className="object-cover h-full" src={value?.wall_images[0]} alt='' />
			</div>
			<div className="hidden md:flex flex-1 flex-row gap-2">
				<div className="flex flex-1 flex-col">
					<img className="object-cover h-full" src={value?.wall_images[1]} alt='' />
				</div>
				<div className="hidden md:flex flex-1 flex-col">
					<img className="object-cover h-full" src={value?.wall_images[2]} alt='' />
				</div>
			</div>
		</div>

		<div className="flex flex-1 flex-col gap-2">
			<div className="hidden md:flex flex-1 flex-row gap-2">
				<div className="flex flex-1 flex-col">
					<img className="object-cover h-full" src={value?.wall_images[3]} alt='' />
				</div>
				<div className="hidden md:flex flex-1 flex-col">
					<img className="object-cover h-full" src={value?.wall_images[4]} alt='' />
				</div>
			</div>
		</div>
	</React.Fragment>)
}

export const FourImageGallery = ({ value }) => {
	return (<React.Fragment>
		<div className="flex flex-1 flex-col gap-2">
			<div className="flex flex-1 flex-col">
				<img className="object-cover h-full" src={value?.wall_images[0]} alt='' />
			</div>
			<div className="hidden md:flex flex-1 flex-row gap-2">
				<div className="flex flex-1 flex-col">
					<img className="object-cover h-full" src={value?.wall_images[1]} alt='' />
				</div>
				<div className="hidden md:flex flex-1 flex-col">
					<img className="object-cover h-full" src={value?.wall_images[2]} alt='' />
				</div>
			</div>
		</div>

		<div className="flex flex-1 flex-col gap-2">
			<div className="hidden md:flex flex-1 flex-row gap-2">
				<div className="flex flex-1 flex-col">
					<img className="object-cover h-full" src={value?.wall_images[3]} alt='' />
				</div>
			</div>
		</div>
	</React.Fragment>)
}

export const ThreeImageGallery = ({ value }) => {
	return (<React.Fragment>
		<div className="flex flex-1 flex-col gap-2" >
			<div className="flex flex-1 flex-col">
				<img className="object-cover h-96" src={value?.wall_images[0]} alt='' />
			</div>
			<div className="hidden md:flex flex-1 flex-row gap-2">
				<div className="flex flex-1 flex-col">
					<img className="object-cover h-full" src={value?.wall_images[1]} alt='' />
				</div>
				<div className="hidden md:flex flex-1 flex-col">
					<img className="object-cover h-full" src={value?.wall_images[2]} alt='' />
				</div>
			</div>
		</div>
	</React.Fragment>)
}

export const TwoImageGallery = ({ value }) => {
	return (<React.Fragment>
		<div className="flex flex-1 flex-col gap-1">
			<div className="flex flex-1 flex-col">
				<img className="object-cover h-96 " src={value?.wall_images[0]} alt='' />
			</div>
			<div className="hidden md:flex flex-1 flex-row gap-1">
				<div className="flex flex-1 flex-col">
					<img className="object-cover h-96" src={value?.wall_images[1]} alt='' />
				</div>
			</div>
		</div>
	</React.Fragment>)
}


export const SingleImageGallery = ({ value }) => {
	return (<React.Fragment>
		<div className="flex flex-1 flex-col gap-2">
			<div className="flex flex-1 flex-col">
				<img className="object-cover w-full rounded-lg" src={value?.wall_images[0]} alt='' />
			</div>
		</div>
	</React.Fragment>)
}

