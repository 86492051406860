import {
	FETCH_PHARMACY_PROFILE_REQUEST,
	FETCH_PHARMACY_PROFILE_SUCCESS,
	FETCH_PHARMACY_PROFILE_FAILURE,
} from '../../constants';

import { pharmacy_team_users, pharmacy_user_profile } from '../../../networking/urlEndPoints';
import Axios from '../../../networking/intraceptor';

export const fetchPharmacyProfileRequest = () => ({
	type: FETCH_PHARMACY_PROFILE_REQUEST,
});

export const fetchPharmacyProfileSuccess = (profileData) => ({
	type: FETCH_PHARMACY_PROFILE_SUCCESS,
	payload: profileData,
});

export const fetchPharmacyProfileFailure = (error) => ({
	type: FETCH_PHARMACY_PROFILE_FAILURE,
	payload: error,
});

export const fetchUserProfile = () => {
	return async (dispatch) => {
		dispatch(fetchPharmacyProfileRequest());
		try {
			const response = await Axios.get(`${pharmacy_user_profile}`);
			if (response?.status === 200) {
				dispatch(fetchPharmacyProfileSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchPharmacyProfileFailure(error?.response))
			}
		}
	};
};

export const fetchPharmacyTeamsDropdownList = (type, params) => {
    return async (dispatch) => {
        dispatch(fetchPharmacyProfileRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_team_users}`, { params })
            if (response?.status === 200) {
                dispatch(fetchPharmacyProfileSuccess(response?.data?.data?.user, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchPharmacyProfileFailure(error?.response, type))
            }
        }
    }
}
