import React, {
	useState,
	useRef
} from 'react'

import {
	useCustomLocation,
	useCustomNavigate,
	useCustomDispatch,
	useCustomSelector
} from '../../hooks'

import { useCustomSelectors } from '../../services/selectors/allSelectors'
import { updateCoverPicture } from '../../services/actions/auth/updateUserProfileCoverPictureActions'
import { updateProfilePicture } from '../../services/actions/auth/updateUserProfileActions'
import { ProfilePicLoader } from './loadingView'

import AvatarEditor from 'react-avatar-editor';
import * as routeNames from '../../routes/routeName'
import { AppreciationsIcons, DashBoardSidebarIcon, ModalCloseIcon, ProfileCameraIcon, ProfileCoverIcon, SettingIcon, SettingIcons, TeamsIcons, TimeOffRequestIcon, WorkSpaceSidebarIcon } from '../../assets/svg/icons'

const ResponsiveSidebar = ({ addPatients }) => {

	const navigate = useCustomNavigate()
	const dispatch = useCustomDispatch()

	const location = useCustomLocation()

	const fileInputRef = useRef(null);
	const imageRef = useRef(null)
	const fileOverImageRef = useRef(null);

	const { profileData } = useCustomSelectors()

	const [image, setImage] = useState(null);
	const [editor, setEditor] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);

	const [scale, setScale] = useState(1);
	const [profilePicLoader, setProfilePicLoader] = useState(false)

	const updateCoverPic = useCustomSelector(state => state.updateCoverPicture)

	const onFileInputChange = async (e) => {
		setProfilePicLoader(true)
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();

			reader.onload = (event) => {
				if (imageRef.current) {
					imageRef.current.src = event.target.result;
				}
			};
			reader.readAsDataURL(file);
			await dispatch(updateProfilePicture(file, setProfilePicLoader))
		}
	}

	const changeImage = () => {
		fileInputRef.current.click();
	}


	const handleButtonClick = () => {
		if (fileOverImageRef.current) {
			fileOverImageRef.current.click();
		}
	};


	const handleCoverImageUpdate = (e) => {
		const file = e.target.files[0];
		setImage(URL.createObjectURL(file));
	};


	const handleSaveImage = async () => {
		if (editor) {

			const canvas = editor.getImageScaledToCanvas();
			canvas.toBlob(async (blob) => {
				if (blob) {
					const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
					setCroppedImage(file);
					try {
						await dispatch(updateCoverPicture(file));
					} catch (error) {
						console.error('Error dispatching the action:', error);
					}
					setCroppedImage('')
					setImage(null)
				}
			}, 'image/jpeg');
		}
	}
	return (
		<>
			<div id="drawer-navigation" className="block lg:hidden fixed top-0 left-0 z-40 w-80 h-screen transition-transform -translate-x-full bg-white dark:bg-gray-800" tabIndex="-1" aria-labelledby="drawer-navigation-label">

				<button type="button" data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation" className="z-10 bg-transparent rounded-full text-sm p-1.5 absolute top-2.5 right-2.5 text-white closeBtn" >
					<ModalCloseIcon />
				</button>

				<div className="scroll-box relative sm:relative md:relative lg:sticky h-screen lg:col-span-3 md:col-span-12 sm:col-span-12 col-span-12 overflow-auto">
					<div className="border-none bg-white border relative overflow-x-auto h-auto sm:h-auto md:h-auto lg:h-auto">

						<div className="bg-blue-600 h-24 w-full rounded-t-none">
							<input type="file" ref={fileOverImageRef} style={{ display: 'none' }} onChange={handleCoverImageUpdate} />

							{updateCoverPic?.loading ?
								<div className='absolute left-2 top-1 rounded-md light-color rubik-400 fs-14 p-2 mb-2 h-8 w-8 flex items-center' >
									<ProfilePicLoader />
								</div>
								:
								<>
									{
										image ?
											<button className='absolute left-2 top-1 rounded-md light-color rubik-400 fs-14 camera_blueBgColor p-2 mb-2 h-6 w-12 flex items-center' onClick={handleSaveImage}>Save</button>
											:
											<button type="button" onClick={handleButtonClick} className="absolute left-1 top-1 light-color rubik-400 fs-16 camera_blueBgColor rounded-full p-2 mb-2 h-8 w-8 flex items-center">
												<ProfileCoverIcon />
											</button>
									}

									{image ? (
										<div className='overflow-hidden'>
											<AvatarEditor
												ref={(ref) => setEditor(ref)}
												image={image}
												width={365}
												height={100}
												border={1}
												color={[255, 255, 255, 0.6]}
												scale={scale}
											/>
										</div>
									) :

										profileData?.cover_photo ?
											<img src={profileData?.cover_photo} className="w-full h-24" style={{ width: '100%' }} alt="" />
											:

											<div className="bg-blue-600 h-24 w-full rounded-t-lg">
											</div>
									}
								</>
							}

						</div>

						<div className="relative pt-30 -mt-16 text-center  w-full">
							<div className="relative uploadImage  rounded-lg mx-auto">
								{profileData &&
									<>
										{profilePicLoader ?
											<div style={{ position: 'absolute', top: '54px', left: '57px' }}>
												<ProfilePicLoader />
											</div>
											: null}

										<img src={profileData?.thumbnail} className="w-28 h-28 rounded-xl bg-light" alt="" ref={imageRef} /> </>}

								<input type="file" accept="image/*" onChange={onFileInputChange} id="fileInput" ref={fileInputRef} className="hidden" />

								<div className="ot_takePictureBg rounded-lg rounded-t-none cursor-pointer" onClick={changeImage} style={{ position: 'absolute', right: '10px' }}>
									<ProfileCameraIcon />
								</div>

								<span className="bottom-0 absolute profile-activity-indicator  w-3.5 h-3.5 bg-green-500 border-2 border-white dark:border-gray-800 rounded-full" style={{ position: 'absolute', right: '4px' }}></span>
							</div>

							<div className="pt-3 pb-3">
								<h3 className="text-bold text-xl rubik-500 fs-18">{profileData?.name?.toUpperCase()}</h3>
							</div>
						</div>
						<div>
							<ul className="pt-2 pb-4">
								{/* ----------------------------------------------------------DashBoard Section Start------------------------------------------------- */}
								<li className="py-3 px-4 border-t grid"
									data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation"
								>
									<a className={`w-full btnnn ${location.pathname === routeNames.DASHBOARD_ROUTE ? 'active' : ''}  `}
										onClick={() => {
											return navigate(routeNames.DASHBOARD_ROUTE)
										}}
									>
										<p className="flex gap-4 leading-none items-center rubik-400 grey-color fs-18">
											<DashBoardSidebarIcon />
										</p>
									</a>
								</li>

								<li className="py-3 px-4 border-t grid">
									<a className={`w-full btnnn ${location.pathname === routeNames.PHARMACY_WORK_SPACE ? 'active' : ''}  `}
										onClick={() => { return navigate(routeNames.PHARMACY_WORK_SPACE) }}>
										<p className="flex gap-4 leading-none items-center rubik-400 grey-color fs-18">
											<WorkSpaceSidebarIcon />
										</p>
									</a>
								</li>

								{/* ----------------------------------------------------------Time Of Request Section Start------------------------------------------ */}

								<li className="py-3 px-4 border-t grid">
									<a className={`w-full btnnn ${location.pathname === routeNames.ALLREQUEST_ROUTE ? 'active' : ''}  `}
										onClick={() => { return navigate(routeNames.ALLREQUEST_ROUTE) }}>
										<p className="leading-none flex gap-4 items-center rubik-400 grey-color fs-18">
											<TimeOffRequestIcon />
										</p>
									</a>
								</li>

								<li className="py-3 px-4 border-t grid" data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation">
									<a className={`w-full btnnn ${location.pathname === routeNames.APPRECIATIONS_ROUTE ? 'active' : ''}  `} onClick={() => navigate(routeNames.APPRECIATIONS_ROUTE)}>
										<p className="leading-none flex gap-4 items-center rubik-400 grey-color fs-18">
											<AppreciationsIcons />
										</p>
									</a>
								</li>

								{/* --------------------------------------------------------------Appreciations Section End------------------------------------------------- */}

								{/* --------------------------------------------------------------Teams Section Start------------------------------------------------- */}
								<li className="py-3 px-4 border-t grid" data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation"	>
									<a className={`w-full btnnn ${location.pathname === routeNames.ALLEMPLOYEES_ROUTE ? 'active' : ''}  `} onClick={() => navigate(routeNames.ALLEMPLOYEES_ROUTE)}>
										<p className="leading-none flex gap-4 items-center rubik-400 grey-color fs-18">
											<TeamsIcons />
										</p>
									</a>
								</li>
								{/* --------------------------------------------------------------Teams Section Start----------------------------------------------------------- */}

								{/* --------------------------------------------------------------Settings Section Start----------------------------------------------------------- */}
								<li className="py-3 px-4 border-t grid" data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation">
									<a className={`w-full btnnn ${location.pathname === routeNames.SETTINGS_ROUTE ? 'active' : ''}  `} onClick={() => navigate(routeNames.SETTINGS_ROUTE)}>
										<p className="flex gap-4 items-center rubik-400 grey-color fs-18 leading-none">
											<SettingIcons />
										</p>
									</a>
								</li>
								{/* --------------------------------------------------------------Settings Section End----------------------------------------------------------- */}
							</ul>
						</div>
					</div >
				</div >
			</div>
			{/* </div> */}
		</>
	)
}

export default ResponsiveSidebar