import React from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export const CustomPagination = ({ count, page, onChange }) => {

	React.useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === 'ArrowLeft' && page > 1) {
				onChange(null, page - 1);
			} else if (
				e.key === 'ArrowRight' &&
				page < count
			) {
				onChange(null, page + 1);
			}
		}
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [page, onChange]);

	return (
		<React.Fragment>
			<Stack spacing={2}>
				<Pagination
					count={count}
					variant="outlined"
					page={page}
					defaultPage={1}
					onChange={onChange}
					color="primary"
				/>
			</Stack>
		</React.Fragment>
	)
}
