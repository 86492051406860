import {
	PHARMACY_PATIENT_NOTE_MARKSREAD_REQUEST,
	PHARMACY_PATIENT_NOTE_MARKSREAD_SUCCESS,
	PHARMACY_PATIENT_NOTE_MARKSREAD_FAILURE
} from '../../../constants';

import Axios from '../../../../networking/intraceptor'
import { pharmacy_patient_notes_markasread } from '../../../../networking/urlEndPoints'
import { fetchPatientNotifications } from './pharmacyPatientNotifications';

export const PatientNoteMarksReadRequest = () => ({
	type: PHARMACY_PATIENT_NOTE_MARKSREAD_REQUEST,
});

export const PatientNoteMarksReadSuccess = (data) => ({
	type: PHARMACY_PATIENT_NOTE_MARKSREAD_SUCCESS,
	payload: data,
});

export const PatientNoteMarksReadFailure = (error) => ({
	type: PHARMACY_PATIENT_NOTE_MARKSREAD_FAILURE,
	payload: error,
});

export const patientNoteMarkAsRead = (id, page) => {
	return async (dispatch) => {
		dispatch(PatientNoteMarksReadRequest());
		try {
			const response = await Axios.post(`${pharmacy_patient_notes_markasread}`, {
				note_id: id
			})

			if (response?.status === 200) {
				dispatch(PatientNoteMarksReadSuccess(response?.data));
				const addTeamNoteData = {
					marknotes: true
				}
				dispatch(fetchPatientNotifications(page, null, addTeamNoteData));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(PatientNoteMarksReadFailure(error?.response));
			}
		}
	}
}

