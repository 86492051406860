import React, {
    useEffect,
    useRef,
    useState
} from 'react'

import { CalenderIcon, CityIcon, EnvelopIcon, LocationIcon, ModalCloseIcon, PHNIcon, ProvinceIcon, UserIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'

import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors'
import { PharmacyDepartments } from '../../components/pharmacy/admin/form'

import { useCustomSelectors } from '../../services/selectors/allSelectors'
import { handleAddNewPharmacyUser, handleSendOnBoardingRequest, handleUpdatePharmacyUser, handleUpdateUserPasscode, handleUpdateUserPassword, updateTemProfile } from '../../services/actions/admin/team/addTeamData'

import * as Hooks from '../../hooks'

import Button from '../../components/common/button'
import {
    EditMode,
    Visible
} from '../../assets/svg/icons'

import { LoadingView } from '../../components/common'
import { ErrorMessages } from '../../components/error/alertMessages'
import { initialAddPateintDOBPicker } from '../../assets/js/datepicker'
import { useCustomPatientSelectors } from '../../services/selectors/patientSelectors'
import { initalShowToastMessage } from '../../utils/constants/formInitialStates'
import { fetchParticularTeamUserList } from '../../services/actions/pharmacy/arv/patientARVList'
import { useARVSelectors } from '../../services/selectors/arvSelectors'
import { ErrorToastMessage, SuccessToastMessage } from '../../components/loaders/toastMessage'

export const TextInput = ({ formData,
    setFormData,
    label,
    errorMessage,
    textType = 'text',
    enable = false,
    name = "" }) => {

    return (<div className="relative w-full">
        <input type={textType} id={name} autoComplete="off"
            onChange={(e) => setFormData({ ...formData, [name]: name === 'passcode' ? e.target.value.slice(0, 4) : e.target.value })}
            name={name}
            value={formData[name]}
            disabled={enable}
            className={`block ${errorMessage && 'border-red-500'} ${enable && 'bg-gray-100'} pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
            bg-white `}
            placeholder=""
        />
        <label htmlFor={name}
            className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                        origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                        peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
            {errorMessage ? '' : label}
        </label>
        {errorMessage && <p className='text-red-500 fs-12 rubik-400'>{errorMessage}</p>}
    </div>)
}

export const AddNewUser = ({ setShowModal, showModal, logData, setShowToast, editLog, pageNumber }) => {

    const dispatch = Hooks.useCustomDispatch()
    const [selectedFile, setSelectedFile] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        username: '',
        email: '',
        pharmacist: '',
        pharmacy_portal: '',
        employee_portal: '',
        profile_photo: '',
        passcode: '',
        department_name: '',
        admin_module: '',
        status: ''
    })

    const [errorMessage, setErrorMessages] = useState({
        name: '',
        username: '',
        email: '',
        pharmacist: '',
        pharmacy_portal: '',
        employee_portal: '',
        profile_photo: '',
        passcode: '',
        department_name: ''
    })

    const { addTeamDataResponse } = useAdminSelectors()
    const { pharmacyLoginUser } = useCustomSelectors()
    // const { name, user_name, email, photo, employee_portal, admin_module, pharmacy_portal, type, id, status } = logData

    const {
        name = '',
        user_name = '',
        email = '',
        photo = '',
        employee_portal = false,
        admin_module = false,
        pharmacy_portal = false,
        type = '',
        id = null,
        status = ''
    } = logData || {};


    const handleSumitAddUserDetails = () => {

        const specificValidations = {
            name: 'Name is required',
            username: 'User Name is required',
            email: 'Email is required',
            department_name: 'Please select at least one department',
        }

        if (!editLog) {
            specificValidations.passcode = 'Passcode is required'
        }

        const allValidations = { ...specificValidations };

        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(allValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ]))

        setErrorMessages({ ...errorMessage, ...newErrorMessages, });
        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

        if (editLog && allDataPresent) {
            dispatch(handleUpdatePharmacyUser('update_user_details', setShowModal, setShowToast, formData, setErrorMessages, selectedFile, id, pageNumber))
        }

        else if (allDataPresent) {
            dispatch(handleAddNewPharmacyUser('add_new_user', setShowModal, setShowToast, formData, setErrorMessages, selectedFile))
        }
    }

    useEffect(() => {
        if (editLog) {
            setFormData((prevStat) => ({
                ...prevStat,
                name: name || '',
                username: user_name || '',
                email: email || '',
                pharmacist: type === 'pharmacist' ? 1 : 0,
                pharmacy_portal: pharmacy_portal,
                employee_portal: employee_portal,
                admin_module: admin_module,
                status: status
            }))
        }
    }, [editLog, logData])

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-3xl max-h-full" >
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-700 dark:text-white -mb-2">
                                {editLog ? 'Edit Details' : 'Add User'}
                            </h3>
                            <button type="button" onClick={() => { return handleHideModal(setShowModal, showModal, 'add_new_user') }}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>

                        </div>

                        <div className="p-6 md:p-5 space-y-4">
                            <form className=''>
                                <div className='grid grid-cols-2 gap-4'>
                                    <div>
                                        <TextInput name={'name'} label={'NAME'} formData={formData} setFormData={setFormData} errorMessage={errorMessage.name} />
                                    </div>

                                    <div>
                                        <TextInput name={'username'} label={'USER NAME'} formData={formData} setFormData={setFormData} errorMessage={errorMessage.username} />
                                    </div>
                                </div>


                                <div className='grid grid-cols-1 gap-4 mt-2'>
                                    <TextInput name={'email'} label={'EMAIL'} formData={formData} setFormData={setFormData} errorMessage={errorMessage.email} />
                                </div>

                                <div className="grid grid-cols-1 gap-4 mt-2">
                                    <div className="">
                                        <input style={{ background: '#fff', color: "#002ae0", border: "1px solid #3a52bb" }} className='rounded-md w-fill' type="file" accept="image/*"
                                            onChange={(event) => setSelectedFile(event.target.files[0])} />
                                    </div>

                                    {
                                        selectedFile ? (
                                            <div className="bg-gray-100 p-2 w-56 flex items-center justify-center rounded-md">
                                                <img src={URL?.createObjectURL(selectedFile)} alt="Selected" className="w-32 h-32 object-cover rounded-full" />
                                                <div className='pt-24'>
                                                    <button className='fs-10 bg-red-500 text-white p-1 rounded-md' onClick={() => setSelectedFile(null)}>Remove</button>
                                                </div>
                                            </div>
                                        ) :
                                            editLog && photo ?
                                                <div className="bg-gray-100 p-2 w-56 flex items-center justify-center rounded-md">
                                                    <img src={photo} alt="Selected" className="w-32 h-32 object-cover rounded-full" />
                                                </div> : null
                                    }
                                </div>

                                <div className='grid grid-cols-1 gap-4 mt-2'>

                                    <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">

                                        {pharmacyLoginUser?.pharmacy_minor_ailment === 'Minor Ailment' &&
                                            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                                <div className="flex items-center ps-3">
                                                    <input defaultChecked={editLog ? type === 'pharmacist' ? true : false : false} onChange={(e) => { setFormData({ ...formData, pharmacist: e.target.checked }) }} id="vue-checkbox-list" type="checkbox" value="" className="w-4 h-4 ml-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                    <label htmlFor="vue-checkbox-list" className="w-full py-3  ml-2 ms-2 text-sm font-medium text-gray-700 dark:text-gray-300">Pharmacist</label>
                                                </div>
                                            </li>}

                                        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                            <div className="flex items-center ps-3">
                                                <input defaultChecked={editLog ? pharmacy_portal === 1 ? true : false : false} onChange={(e) => { setFormData({ ...formData, pharmacy_portal: e.target.checked }) }} id="react-checkbox-list" type="checkbox" value="" className="w-4 h-4 ml-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                <label htmlFor="react-checkbox-list" className="w-full py-3 ms-2 ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">Pharmacy Portal</label>
                                            </div>
                                        </li>

                                        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                            <div className="flex items-center ps-3">
                                                <input id="angular-checkbox-list" defaultChecked={editLog ? employee_portal === 1 ? true : false : false} onChange={(e) => { setFormData({ ...formData, employee_portal: e.target.checked }) }} type="checkbox" value="" className="w-4 h-4 ml-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                <label htmlFor="angular-checkbox-list" className="w-full py-3 ms-2 ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">Employee Portal</label>
                                            </div>
                                        </li>

                                        {editLog &&
                                            <li className="w-full dark:border-gray-600">
                                                <div className="flex items-center ps-3">
                                                    <input id="laravel-checkbox-list" defaultChecked={editLog ? admin_module === 1 ? true : false : false} onChange={(e) => { setFormData({ ...formData, admin_module: e.target.checked }) }} type="checkbox" value="" className="w-4 h-4 ml-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500        dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                    <label htmlFor="laravel-checkbox-list" className="w-full py-3 ms-2 text-sm ml-2 font-medium text-gray-700 dark:text-gray-300">Admin Module</label>
                                                </div>
                                            </li>
                                        }
                                    </ul>

                                </div>

                                {!editLog &&
                                    <div className='grid grid-cols-1 gap-4 mt-2'>
                                        <div>
                                            <TextInput name={'passcode'} textType='number' label={'Passcode'} formData={formData} setFormData={setFormData} errorMessage={errorMessage.passcode} />
                                        </div>
                                    </div>}

                                <div className='grid grid-cols-1 mt-2'>
                                    <PharmacyDepartments setFormData={setFormData} logData={logData} editLog={editLog} />
                                    {errorMessage.department_name && <p className='text-red-500 fs-12'>{errorMessage.department_name}</p>}
                                </div>

                                {
                                    editLog &&
                                    <div className='mt-2'>
                                        <h1 className='text-gray-700 rubik-500 fs-14 text-lg'>STATUS</h1>
                                        <label className="inline-flex items-center cursor-pointer">
                                            <input type="checkbox" defaultChecked={editLog ? status === 1 ? true : false : false}
                                                onChange={(e) => { setFormData({ ...formData, status: e.target.checked }) }} className="sr-only peer" />

                                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 ml-2">active / Inactive</span>
                                        </label>
                                    </div>
                                }


                                <div className='grid grid-cols-1 mt-2'>
                                    <Button text={(addTeamDataResponse.type === 'add_new_user' || addTeamDataResponse.type === 'update_user_details') && addTeamDataResponse.loading ? 'Processing...' : 'Submit'} width='100%' color='white'
                                        handleFilter={handleSumitAddUserDetails} />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}


export const UpdatePasscode = ({ setShowModal, showModal, logData, setShowToast, pageNumber }) => {

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    const [formdata, setFormData] = useState({ input1: '', input2: '', input3: '', input4: '' })

    const [errorMessage, setErrorMessage] = useState({ input1: '', input2: '', input3: '', input4: '', error: '' })
    const { addTeamDataResponse } = useAdminSelectors()

    const dispatch = Hooks.useCustomDispatch()

    const handleUpdatePasscode = () => {

        const specificValidations = {
            input1: 'input1 is required',
            input2: 'input1 Name is required',
            input3: 'input1 is required',
            input4: 'input1 is required',
        }

        const allValidations = { ...specificValidations };

        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(allValidations).map(([field, message]) => [
                field,
                validateField(field, formdata[field], message),
            ]))
        setErrorMessage({ ...errorMessage, ...newErrorMessages, });

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

        if (allDataPresent) {

            const data = [formdata.input1, formdata.input2, formdata.input3, formdata.input4]
            const { id } = logData

            const reqData = {
                user_id: id,
                attendancepasscode: data
            }

            if (id) dispatch(handleUpdateUserPasscode('update_passcode', setShowModal, setShowToast, reqData, setErrorMessage, pageNumber))
        }
    }

    const handleInputChange = (e, nextRef, field) => {
        const value = e.target.value;
        setFormData((prevData) => ({ ...prevData, [field]: value }));

        if (value && nextRef && nextRef.current) {
            nextRef.current.focus();
        }
    };

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" >
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-700 dark:text-white -mb-2">
                                Update Passcode
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'update_passcode')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-6 md:p-5 space-y-4">
                            <form className=''>

                                <div className='flex items-center justify-center gap-2'>
                                    <input
                                        type="number"
                                        maxLength={1}
                                        autoComplete="off"
                                        onChange={(e) => handleInputChange(e, input2Ref, 'input1')}
                                        ref={input1Ref}
                                        className={`block pl-2 px-2.5 pb-2.5 w-8 h-10 ${errorMessage.input1 && 'border-red-500'} text-center text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
                                        placeholder=""
                                    />
                                    <input
                                        type="number"
                                        maxLength={1}
                                        autoComplete="off"
                                        onChange={(e) => handleInputChange(e, input3Ref, 'input2')}
                                        ref={input2Ref}
                                        className={`block pl-2 px-2.5 pb-2.5 w-8 h-10 text-center ${errorMessage.input2 && 'border-red-500'} text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
                                        placeholder=""
                                    />
                                    <input
                                        type="number"
                                        maxLength={1}
                                        autoComplete="off"
                                        onChange={(e) => handleInputChange(e, input4Ref, 'input3')}
                                        ref={input3Ref}
                                        className={`block pl-2 px-2.5 pb-2.5 w-8 h-10 text-center text-sm  ${errorMessage.input3 && 'border-red-500'} text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
                                        placeholder=""
                                    />
                                    <input
                                        type="number"
                                        maxLength={1}
                                        autoComplete="off"
                                        onChange={(e) => handleInputChange(e, null, 'input4')}
                                        ref={input4Ref}
                                        className={`block pl-2 px-2.5 pb-2.5 w-8 h-10 text-center text-sm ${errorMessage.input4 && 'border-red-500'} text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
                                        placeholder=""
                                    />
                                </div>

                                {errorMessage.error && <p className='text-red-500 fs-12 text-center'>{errorMessage.error}</p>}

                                <div className='grid grid-cols-1 mt-2'>
                                    <Button text={addTeamDataResponse.type === 'update_passcode' && addTeamDataResponse.loading ? 'Processing...' : 'Submit'} width='100%' color='white'
                                        handleFilter={handleUpdatePasscode} />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export const UpdateUserPassword = ({ setShowModal, showModal, logData, setShowToast, pageNumber }) => {

    const [passwordValue, setPasswordValue] = useState({ password: '' })
    const [errorMessage, setErrorMessage] = useState('')
    const { addTeamDataResponse } = useAdminSelectors()

    const dispatch = Hooks.useCustomDispatch()

    const handleUpdatePassword = () => {
        if (!passwordValue.password) {
            setErrorMessage({ ...errorMessage, password: 'Password is required' })
        } else {
            const { id } = logData
            setErrorMessage({ ...errorMessage, password: '' })
            const reqData = {
                user_id: id,
                password: passwordValue.password
            }
            if (id) dispatch(handleUpdateUserPassword('update_password', setShowModal, setShowToast, reqData))
        }
    }

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" >
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-700 dark:text-white -mb-2">
                                Update Password
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'update_passcode')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-6 md:p-5 space-y-4">
                            <form className=''>

                                <div className='flex items-center justify-center gap-2'>
                                    <TextInput name={'password'} textType='text' label={'Password'} formData={passwordValue} setFormData={setPasswordValue}
                                        errorMessage={errorMessage.password} />
                                </div>

                                <div className='grid grid-cols-1 mt-2'>
                                    <Button text={addTeamDataResponse.type === 'update_password' && addTeamDataResponse.loading ? 'Processing...' : 'Submit'} width='100%' color='white'
                                        handleFilter={handleUpdatePassword} />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export const SendOnBoardingRequest = ({ setShowModal, showModal, logData, setShowToast }) => {

    const [formData, setFormData] = useState({ email: '', employee_portal: '' })
    const [errorMessage, setErrorMessage] = useState({ email: '' })

    const { addTeamDataResponse } = useAdminSelectors()

    const dispatch = Hooks.useCustomDispatch()

    const handleUpdateUserOnBoardingRequest = (e) => {
        e.preventDefault()
        if (!formData.email) {
            setErrorMessage({ ...errorMessage, email: 'Email is required' })
        } else {
            const { id } = logData
            setErrorMessage({ ...errorMessage, email: '' })
            const reqData = {
                user_id: id,
                email: formData.email,
                employee_portal: formData?.employee_portal ? 1 : 0
            }
            if (id) dispatch(handleSendOnBoardingRequest('Onboarding_request', setShowModal, setShowToast, reqData))
        }
    }

    useEffect(() => {
        setFormData({ ...formData, email: logData?.email, employee_portal: logData?.employee_portal ? 1 : 0 })
    }, [logData])

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" >
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-700 dark:text-white -mb-2">
                                Onboarding Request to {logData?.name}
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'boarding')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-6 md:p-5 space-y-4 -mt-4">
                            <form className=''>

                                <div className="flex items-center">
                                    <input defaultChecked={logData.employee_portal === 1 ? true : false} onChange={(e) => { setFormData({ ...formData, employee_portal: e.target.checked }) }} id="react-checkbox-list" type="checkbox" value="" className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                    <label htmlFor="react-checkbox-list" className="w-full py-3 ms-2 ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">Employee Portal</label>
                                </div>

                                <div className='flex items-center justify-center gap-2 mt-2'>
                                    <TextInput name={'email'} textType='email' label={'Email'} enable={true} formData={formData} setFormData={setFormData}
                                        errorMessage={errorMessage.email} />
                                </div>

                                <div className='grid grid-cols-1 mt-2'>
                                    <Button text={addTeamDataResponse.type === 'Onboarding_request' && addTeamDataResponse.loading ? 'Processing...' : 'Submit'} width='100%' color='white'
                                        handleFilter={handleUpdateUserOnBoardingRequest}
                                    />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export const NoPharmacyDepartMentsFoundModal = ({ setShowModal, showModal }) => {

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" >
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-700 dark:text-white -mb-2">

                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'add_new_user')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-6 md:p-5 space-y-4 -mt-4">
                            <form className=''>
                                <div>
                                    <h1 className='fs-14 rubik-500 text-gray-500'>
                                        Lets Start adding the Department first.
                                    </h1>
                                </div>
                                <div className='grid grid-cols-1 mt-2'>
                                    <Button text={'Proceed'} width='100%' color='white' />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export const UpdateTeamProfile = ({ setShowModal, showModal, logData }) => {

    const teamUserId = logData.id
    const dispatch = Hooks.useCustomDispatch();
    const FormFields = { first_name: '', last_name: '', dob: '',province:'', email: '', phone: '', city: '', address1: '', address2: '',  sin_number: '', zipcode: '' };
    const FormFieldsErrorss = { first_name: '', last_name: '', dob: '',province:'', email: '', phone: '', city: '', address1: '', address2: '', sin_number: '', zipcode: '' };
    const FormFieldsErrorsMsgs = {
        first_name: 'First Name is required',
        last_name: 'Last Name is required',
        dob: 'DOB is required',
        email: 'Email is required',
        phone: 'Phone is required',
        city: 'City is required',
        address1: 'Address Line 1 is required',
        address2: 'Address Line 2 is required',
        sin_number: 'SIN Number is required',
        zipcode: 'Zip code is required',
        province:'province is required'
    };
    const dobRef = useRef()
    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors();

    const [isEditMode, setIsEditMode] = useState(false);
    const { provinceList,
        updatePatientProfileResponse } = useCustomPatientSelectors()
        const { arvListListResponse } = useARVSelectors();
    const [updatePatientFormData, setUpdatePatientFormData] = useState(FormFields);
    const [formDataBorderColor, setFormDataBorderColor] = useState(FormFields);
    const [formFeildsErrors, setFormFieldsErrors] = useState(FormFieldsErrorss);
    const [showToast, setShowToast] = useState(initalShowToastMessage);
        const [name,setName] = useState('')
    const certificateTemplatesRequestList = arvListListResponse?.data?.data
    const handleEditClick = () => {
        setIsEditMode(!isEditMode);
    };

    useEffect(() => {
        const startDOBPickerInstance = initialAddPateintDOBPicker(dobRef, setUpdatePatientFormData, updatePatientFormData);
        
        return () => {
            if (startDOBPickerInstance) {
                startDOBPickerInstance.destroy();
            }
        };
     
    }, [dobRef,updatePatientFormData])

    useEffect(() => {
       
        
        
     
    }, [arvListListResponse])


   

    // useEffect(() => {

    //     dispatch(fetchParticularTeamUserList('fetch_particular_team_user_list', teamUserId))

    // }, [dispatch])

    const handleChangeFormData = (e) => {
        const { name, value } = e.target;
        setUpdatePatientFormData(prev => ({ ...prev, [name]: value }));
        setFormFieldsErrors(prev => ({ ...prev, [name]: '' }));
        setFormDataBorderColor(prev => ({ ...prev, [name]: '' }));
    };

    useEffect(() => {
        dispatch(fetchParticularTeamUserList('fetch_particular_team_user_list', teamUserId));
        setName(logData?.name)
    }, [logData]);

    useEffect(() => {
        // console.log(arvListListResponse?.data?.data?.details)
        if (arvListListResponse?.data?.data?.details) {

            // if (arvListListResponse?.data?.data?.details?.province) {
            //     console.log(arvListListResponse?.data?.data?.details)
            //     setUpdatePatientFormData(prevData => ({
            //         ...prevData,
            //         province: arvListListResponse?.data?.data?.details?.province,
                  
            //     }));
            // }

            const { first_name, last_name, dob, email, phone, city, address1, address2, province, sin_number, zipcode, full_address } = arvListListResponse.data.data.details;
            setUpdatePatientFormData({
                first_name: first_name || '',
                last_name: last_name || '',
                dob:  convertDateFormat(dob) || '',
                email: email || '',
                phone: phone || '',
                city: city || '',
                address1: address1 || '',
                address2: address2 || '',
                zipcode: zipcode || '',
                sin_number: sin_number || '',
                full_address: full_address || '',
                province:province || ''
            });
        }
    }, [arvListListResponse]);

    const updateProfile = (e) => {
        e.preventDefault();
        let hasError = false;
        const errorFields = [];

        Object.keys(FormFields).forEach(field => {
            if (!updatePatientFormData[field]) {
                setFormFieldsErrors(prev => ({ ...prev, [field]: FormFieldsErrorsMsgs[field] }));
                setFormDataBorderColor(prev => ({ ...prev, [field]: '#e85347' }));
                hasError = true;
                errorFields.push(field);
            }
        });
    
        if (hasError) {
           
            // console.log('Error messages:', errorFields.map(field => ({
            //     field,
            //     message: FormFieldsErrorsMsgs[field]
            // })));
        }
        if (!hasError) {
            const modifiedFormData = {
                first_name: updatePatientFormData.first_name,
                last_name: updatePatientFormData.last_name,
                email: updatePatientFormData.email,
                phone: updatePatientFormData.phone,
                city: updatePatientFormData.city,
                dob:updatePatientFormData.dob,
                address: updatePatientFormData.address1,
                full_address: updatePatientFormData.address2,
                province: updatePatientFormData.province,
                sin_number: updatePatientFormData.sin_number,
                zip_code: updatePatientFormData.zipcode,
                
            };
            dispatch(updateTemProfile('update_team_profile_data', setShowToast, modifiedFormData, teamUserId));
        }
    };

   

    function convertDateFormat(dateStr) {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
         return `${year}-${month}-${day}`;
      }
    

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative w-full max-w-2xl p-4 max-h-full" style={{maxWidth:'50rem'}}>
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-700 dark:text-white">
                            {name}
                        </h3>
                        <div className='flex items-center'>                        <div className="ml-4 mr-3">
                            <button
                                onClick={handleEditClick}
                                className="px-4 py-2 blueBg-color text-white rounded-md focus:outline-none"
                                type='button'
                            >
                                {isEditMode ? (
                                    <div className='flex'>
                                        <Visible />View Detail
                                    </div>
                                ) : (
                                    <div className='flex'>
                                        <EditMode />Edit Details
                                    </div>
                                )}
                            </button>
                        </div>
                        <button
                            type="button"
                            onClick={() => handleHideModal(setShowModal, showModal, 'add_new_user')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                        </div>

                    </div>
                 

                        <div className="p-4 md:p-5 space-y-4">
                        {arvListListResponse?.loading && arvListListResponse?.type === 'fetch_particular_team_user_list' ? (
                        <div className="flex justify-center items-center " style={{marginTop: '100px',marginBottom:"100px"}}>
                            <LoadingView />
                        </div>
                    ) : arvListListResponse?.error !== null && arvListListResponse?.type === 'fetch_particular_team_user_list' ? (
                        <ErrorMessages />
                    ) : certificateTemplatesRequestList?.data?.length === 0 && arvListListResponse?.type === 'fetch_particular_team_user_list' ? (
                        <p className='text-gray-500'>No data Found.</p>
                    ) : arvListListResponse?.type === 'fetch_particular_team_user_list' ? (
                            <form>
                                <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
                                <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative">
                                            <UserIcon />
                                            <input type="text"
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                style={{ borderColor: formDataBorderColor.first_name }}
                                                name='first_name'
                                                value={updatePatientFormData.first_name}
                                                id="first_name"
                                                placeholder=""
                                                disabled={!isEditMode} />

                                            <label htmlFor="first_name" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
                                                {formDataBorderColor.first_name == '#e85347' ? '' : 'First Name'}
                                            </label>
                                            <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.first_name}</span>
                                        </div>
                                    </div>
                                    <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative">
                                            <UserIcon />
                                            <input type="text"
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                style={{ borderColor: formDataBorderColor.last_name }}
                                                name='last_name'
                                                value={updatePatientFormData.last_name}
                                                id="last_name"
                                                placeholder=""
                                                disabled={!isEditMode} />

                                            <label htmlFor="last_name" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
                                                {formDataBorderColor.last_name == '#e85347' ? '' : 'Last Name'}
                                            </label>
                                            <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.last_name}</span>
                                        </div>
                                    </div>
                                    <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="block sm:block md:flex lg:flex items-center ">
                                            <div className="relative w-full">

                                                <input type="text" ref={dobRef}
                                                    className={`block pl-4 px-2.5  pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                    onChange={handleChangeFormData}
                                                    name='dob'
                                                    value={updatePatientFormData.dob}
                                                    id="dob"
                                                    placeholder=""
                                                    disabled={!isEditMode} />

                                                <label htmlFor="dob" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                    className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
                                                    Date of Birth
                                                </label>

                                                <div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
                                                    <CalenderIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
                                    <div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative">
                                            <EnvelopIcon />

                                            <input type="text"
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                value={updatePatientFormData.email}
                                                name='email'
                                                id="email"
                                                style={{ borderColor: formDataBorderColor.email }}
                                                placeholder=""
                                                disabled={!isEditMode} />

                                            <label htmlFor="postalCode" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
                                                {formDataBorderColor.email == '#e85347' ? '' : 'Email'}
                                            </label>
                                            <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.email}</span>
                                        </div>
                                    </div>
                                    <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative">
                                            <PHNIcon />
                                            <input type="text"
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                name='phone'
                                                value={updatePatientFormData.phone}
                                                id="phone"
                                                style={{ borderColor: formDataBorderColor.phone }}
                                                placeholder=""
                                                disabled={!isEditMode} />

                                            <label htmlFor="phone" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
                                                {formDataBorderColor.phone == '#e85347' ? '' : 'Phone'}
                                            </label>
                                            <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.phone}</span>
                                        </div>
                                    </div>

                                    <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative">
                                            <CityIcon />
                                            <input type="text"
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                name='city'
                                                value={updatePatientFormData.city}
                                                id="city"
                                                style={{ borderColor: formDataBorderColor.city }}
                                                placeholder=""
                                                disabled={!isEditMode} />

                                            <label htmlFor="city" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
                                                {formDataBorderColor.city == '#e85347' ? '' : 'City'}
                                            </label>
                                            <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.city}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
                                    {/* ... existing fields ... */}

                                    <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative">
                                            <LocationIcon />
                                            <input type="text"
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                    rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                    ${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                name='address1'
                                                value={updatePatientFormData.address1}
                                                id="address1"
                                                style={{ borderColor: formDataBorderColor.address1 }}
                                                placeholder=""
                                                disabled={!isEditMode} />
                                            <label htmlFor="address1" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                    rtl:peer-focus:left-auto start-1`}>
                                                {formDataBorderColor.address1 === '#e85347' ? '' : 'Address Line 1'}
                                            </label>
                                            <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.address1}</span>
                                        </div>
                                    </div>

                                    <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative">
                                            <LocationIcon />
                                            <input type="text"
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                    rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                    ${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                name='address2'
                                                value={updatePatientFormData.address2}
                                                id="address2"
                                                style={{ borderColor: formDataBorderColor.address2 }}
                                                placeholder=""
                                                disabled={!isEditMode} />
                                            <label htmlFor="address2" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                    rtl:peer-focus:left-auto start-1`}>
                                                {formDataBorderColor.address2 === '#e85347' ? '' : 'Full address'}
                                            </label>
                                            <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.address2}</span>
                                        </div>
                                    </div>

                                    <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative ot_addNoteText did-floating-label-content">
                                            <ProvinceIcon />
                                            <select
                                                style={{ height: '48px' }}
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                ${isEditMode ? 'cursor-pointer bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                value={updatePatientFormData.province}
                                                name="province"
                                                id="province"
                                                disabled={!isEditMode}
                                                style={{ borderColor: formDataBorderColor.province }}
                                            >

                                                {provinceList?.data?.map((value, index) => (
                                                    <option className="fs-14" value={value.short_name} key={index}>
                                                        {value.long_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label
                                                htmlFor="province"
                                                className="did-floating-label ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                rtl:peer-focus:left-auto start-1"
                                                style={{
                                                    color: 'grey',
                                                    backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}`
                                                }}
                                            >
                                                Province
                                            </label>
                                        </div>
                                        <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.province}</span>
                                    </div>
                                </div><div className='grid grid-cols-12 gap-4 md:gap-7 lg:gap-7'>
                                    <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative">

                                            <input type="text"
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                    rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                    ${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                name='sin_number'
                                                value={updatePatientFormData.sin_number}
                                                id="sin_number"
                                                style={{ borderColor: formDataBorderColor.sin_number }}
                                                placeholder=""
                                                disabled={!isEditMode} />
                                            <label htmlFor="sin_number" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                    rtl:peer-focus:left-auto start-1`}>
                                                {formDataBorderColor.sin_number === '#e85347' ? '' : 'SIN Number'}
                                            </label>
                                            <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.sin_number}</span>
                                        </div>
                                    </div>

                                    <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                        <div className="relative">
                                            <EnvelopIcon />
                                            <input type="text"
                                                className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                    rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                    ${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
                                                onChange={handleChangeFormData}
                                                name='zipcode'
                                                value={updatePatientFormData.zipcode}
                                                id="zipcode"
                                                style={{ borderColor: formDataBorderColor.zipcode }}
                                                placeholder=""
                                                disabled={!isEditMode} />
                                            <label htmlFor="zipcode" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
                                                className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                    rtl:peer-focus:left-auto start-1`}>
                                                {formDataBorderColor.zipcode === '#e85347' ? '' : 'Zip Code'}
                                            </label>
                                            <span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.zipcode}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
                                    <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
                                        <div className="col-span-12">
                                            <button type="submit" onClick={updateProfile} data-ripple-light="true"
                                                className={`rubik-500 flex rounded-md justify-center items-center text-white ${isEditMode ? 'blueBg-color' : 'cursor-not-allowed blueBg-color'}  w-full text-sm px-5 py-2.5 text-center`}
                                                disabled={!isEditMode}
                                            >
                                                {addTeamDataResponse.loading && addTeamDataResponse.type === 'update_team_profile_data' ? 'Updating...' : 'Update'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        ) : null}
                        </div>

                   
                </div>
            </div>
            {showToast.showToast && addTeamDataResponse.type === 'update_team_profile_data' && <SuccessToastMessage status={'Updated successfully.'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'update_team_profile_data' && <ErrorToastMessage status={'Something went wrong. Please try again later.'} />}

        </div>
    );
};


