import React, { useEffect, useState } from "react";

import { BreadCrumb, Footer, Sidebar } from "../../../components/common";

import { initalCompoundsFormData } from "../../../utils/constants/formInitialStates";
import { onhandlePharmacyCompoundFormDataChange } from "../../../utils/data/handleFormDataChange";

import { useCustomDispatch } from "../../../hooks";
import { useCustomSelectors } from "../../../services/selectors/allSelectors";
import { handlePharmacyOrderCompoundForm } from "../../../utils/validations/compoundValidations";

import { Loader } from "../../../components/common/loadingView";
import { Errors } from "../../../components/error/alertMessages";
import { useFormsSelectors } from "../../../services/selectors/formsSelector";

import { RemoveIcon } from "../../../assets/svg/icons";
import ChatBox from "../../../components/chat/chatbox";
import * as Pharmacy from "../../../components/pharmacy/form/form";
import { previewPdfFailure } from "../../../services/actions/pharmacy/forms/previewPdf";

const CompoundOrderForm = () => {
	const { pharmacyLoginData } = useCustomSelectors();
	const { previewPdfResponse } = useFormsSelectors();

	const [formData, setFormData] = useState(initalCompoundsFormData);
	const [errorMessages, setErrorMessages] = useState(initalCompoundsFormData);

	const dispatch = useCustomDispatch();

	const handleFormDataChange = (event) => {
		const { name, checked, value } = event.target;
		setFormData((prevValues) => ({
			...prevValues,
			rational:
				event.target.type === "checkbox"
					? checked
						? [...(prevValues.rational || []), parseInt(value, 10)]
						: (prevValues.rational || []).filter(
							(num) => num !== parseInt(value, 10)
						)
					: prevValues.rational,
		}));

		if (event.target.type !== "checkbox") {
			onhandlePharmacyCompoundFormDataChange(
				event,
				setFormData,
				formData,
				setErrorMessages,
				errorMessages
			);
		}
	};

	const toPharmacyFeilds = [
		{
			id: "compoundName",
			label: "Compound Name",
			name: "compound",
			value: formData.compound,
			errorMessage: errorMessages.compound,
		},
		{
			id: "quantity",
			label: "Quantity",
			name: "quantity",
			value: formData.quantity,
			errorMessage: errorMessages.quantity,
		},
		{
			id: "Notes",
			label: "Notes",
			name: "notes",
			value: formData.notes,
			errorMessage: errorMessages.notes,
		},
	];

	const [addNewCompounds, setAddNewCompounds] = useState([
		{ selectComponents: toPharmacyFeilds },
	]);

	const handleAddNew = () => {
		setAddNewCompounds([
			...addNewCompounds,
			{ selectComponents: toPharmacyFeilds },
		]);
	};

	useEffect(() => {
		setAddNewCompounds([{ selectComponents: toPharmacyFeilds }]);
	}, []);

	useEffect(() => {
		if (pharmacyLoginData) {
			setFormData({
				...formData,
				pharmacyName: pharmacyLoginData.name,
				pharmacyManager: pharmacyLoginData.manager_name,
			});
		}
	}, [pharmacyLoginData]);

	const handleFormDataChanges = (e, i, index) => {
		const { name, value } = e.target;
		setAddNewCompounds((prevCompounds) => {
			const updatedCompounds = [...prevCompounds];
			updatedCompounds[i].selectComponents[index].value = value;
			return updatedCompounds;
		});
	};

	const handleDelete = (index) => {
		debugger;
		setAddNewCompounds((prevCompounds) => {
			const updatedCompounds = [...prevCompounds];
			updatedCompounds.splice(index, 1);
			return updatedCompounds;
		});
	};

	useEffect(() => {
		dispatch(previewPdfFailure(null))
	}, [])

	return (
		<React.Fragment>
			<div className="BodyBgColor">
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content">
								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
											<Pharmacy.Date
												formData={formData}
												setFormData={setFormData}
											/>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<Pharmacy.ToPharmacy
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
												setErrorMessages={setErrorMessages}
											/>

											<Pharmacy.ToPharmacyPhone
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
											/>

											<Pharmacy.ToPharmacyFaxNumber
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
											/>
										</div>

										<div className="flex flex-wrap gap-x-4 gap-y-1 mt-4 ">
											{addNewCompounds?.map((value, i) => {
												return (
													<React.Fragment key={i}>
														{value?.selectComponents?.map((field, index) => (
															<div
																className="relative flex-0 w-full md:flex-1"
																key={index}
															>
																<input
																	type="text"
																	id={field.name[index]}
																	onChange={(e) =>
																		handleFormDataChanges(e, i, index)
																	}
																	name={field.name}
																	value={field.value}
																	placeholder=""
																	className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 
                            									appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 
                            									focus:border-blue-600 peer cursor-text bg-white`}
																/>
																<label
																	htmlFor={field.name[index]}
																	className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 
                            											scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
                            											peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                            											peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 
                            											rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
																>
																	{!field.errorMessage && field.label}
																</label>
															</div>
														))}
														{i > 0 && (
															<button
																onClick={() => handleDelete(i)}
																type="button"
																className="flex w-auto flex-0 py-4 items-center w-38 justify-center text-red-900 bg-white border border-red-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
															>
																<RemoveIcon color="red" />
															</button>
														)}
														<div className="w-full"></div>

														{/* {i !== 0 &&
													<div className="relative ot_addNoteText absolute right-30	">
														<button style={{ height: '32px', marginBottom: '0px', background: 'crimson' }}
															type="button"
															className="w-12 ti_heightScheduleInnerDateBtn blueBg-color sm:text-center md:text-start lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

															<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																<path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
															</svg>
														</button>
													</div>
												  } */}
													</React.Fragment>
												);
											})}
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-4 mt-4 ">
											<button
												onClick={handleAddNew}
												type="button"
												className="flex py-4 items-center w-38 justify-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
											>
												Add New Compound
											</button>
										</div>

										<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3 mt-4 border border-gray-200 rounded dark:border-gray-700">
											{[
												"Price & ingredients list only",
												"Price, ingredients list and proceed with order",
												"Proceed with order",
											]?.map((label, index) => (
												<div
													key={index}
													className="flex items-center mb-0 gap-3 px-3 py-1"
												>
													<input
														id={`checkbox-${index}`}
														name="rational"
														type="checkbox"
														value={index + 1}
														checked={formData?.rational?.includes(index + 1)}
														onChange={handleFormDataChange}
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
													/>
													<label
														htmlFor={`checkbox-${index}`}
														className="fs-14 rubik-400 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
													>
														{label}
													</label>
												</div>
											))}
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<Pharmacy.LoginPharmacyName
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
											/>

											<Pharmacy.LoginPharmacyManger
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
											/>

											<Pharmacy.Designation
												setFormData={setFormData}
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
											/>
										</div>

										{previewPdfResponse?.error !== null && (
											<>
												<br />
												<Errors
													error={
														"Something went wrong when generating the PDF. Please try to regenerate again."
													}
												/>
											</>
										)}

										<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
											<button
												style={{ height: "40px" }}
												type="button"
												onClick={() =>
													handlePharmacyOrderCompoundForm(
														formData,
														setErrorMessages,
														errorMessages,
														dispatch,
														addNewCompounds,
														"PDF"
													)
												}
												className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 			lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center"
											>
												{previewPdfResponse?.loading ? (
													<>
														Genrating PDF &nbsp;
														<Loader />{" "}
													</>
												) : (
													"Preview PDF"
												)}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ChatBox />
			<Footer />
		</React.Fragment>
	);
};

export default CompoundOrderForm;
