import { patches_send_fax } from "../../networking/urlEndPoints"

import Swal from 'sweetalert2';
import Axios from '../../networking/intraceptor';

export const SendFaxLog = (id, text) => {
    const swalWithTailwindButtons = Swal.mixin({
        customClass: {
            cancelButton: 'bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded',
            confirmButton: 'm-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded',
        },
        buttonsStyling: false
    });

    swalWithTailwindButtons
        .fire({
            title: 'Are you sure?',
            text: `This will send a fax to ${text}. Do you want to proceed?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        })
        .then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await Axios.post(`${patches_send_fax}`, {
                        log_id: id,
                    })
                    if (response?.status === 200) {
                        swalWithTailwindButtons.fire({
                            title: 'Success',
                            text: 'Fax Sent Successfully',
                            icon: 'success'
                        });
                    } else {
                        throw new Error(response)
                    }
                } catch (error) {
                    if (error?.response) {
                        swalWithTailwindButtons.fire({
                            title: 'Error',
                            text: `Oops! Got an issue while sending fax.please try again later.`,
                            icon: 'error'
                        });
                    }
                }
            }
            else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithTailwindButtons.fire({
                    title: 'Process has been cancelled',
                    icon: 'error'
                });
            }
        })
}



