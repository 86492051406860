import {
	INITIATE_CHAT_GROUP_REQUEST,
	INITIATE_CHAT_GROUP_SUCCESS,
	INITIATE_CHAT_GROUP_FAILURE
} from "../../constants";

const initialState = {
	loading: false,
	data: null,
	error: null,
};

const initiatedChatGroupReducer = (state = initialState, action) => {
	switch (action.type) {
		case INITIATE_CHAT_GROUP_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				data: null,
			};
		case INITIATE_CHAT_GROUP_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case INITIATE_CHAT_GROUP_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
export default initiatedChatGroupReducer