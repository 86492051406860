import {
    PHARMACY_PERMISSIONS_REQUEST,
    PHARMACY_PERMISSIONS_SUCCESS,
    PHARMACY_PERMISSIONS_FAILURE,
} from '../../constants';

import { pharmacy_user_permission } from '../../../networking/urlEndPoints'
import Axios from '../../../networking/intraceptor'

export const pharmacyPermissionsRequest = () => ({
    type: PHARMACY_PERMISSIONS_REQUEST,
});

export const pharmacyPermissionsSuccess = (data) => ({
    type: PHARMACY_PERMISSIONS_SUCCESS,
    payload: data,
});

export const pharmacyPermissionsFailure = (error) => ({
    type: PHARMACY_PERMISSIONS_FAILURE,
    payload: error,
});

export const authPharmacyPermissions = () => {
    return async (dispatch) => {
        dispatch(pharmacyPermissionsRequest());
        try {
            const response = await Axios.post(`${pharmacy_user_permission}`)

            if (response?.status === 200) {
                dispatch(pharmacyPermissionsSuccess(response?.data?.data));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyPermissionsFailure(error?.response));
            }
        }
    }
}

