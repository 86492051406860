import { handleAddNewTask, HandleADDShift, handleAddTeamWorkTimeShift, HandleEDITShift, handleUpdateNewTasks, handleUpdateTeamTimeShift, handleUpdateWinrXSettings, updateBreakSetting, updatePayrollSetting, updatePharmacyTimeSetting } from "../../services/actions/admin/team/addTeamData";

export const onhandleValidateTaskMangerForm = (formData, setErrorMessages, errorMessage, dispatch, setShowToast, type, id, setShowModal, paginationNumber) => {
    const specificValidations = {
        title: 'Title is required',
        descripition: 'descripition is required',
        assignTask: 'assignTask is required'
    }

    const allValidations = { ...specificValidations };

    const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

    const newErrorMessages = Object.fromEntries(
        Object.entries(allValidations).map(([field, message]) => [
            field,
            validateField(field, formData[field], message),
        ]))
    setErrorMessages({ ...errorMessage, ...newErrorMessages, });
    const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

    if (allDataPresent && type === 'edit' && id) {
        const { title, descripition, taskStatus, assignTask } = formData
        const reqData = {
            title: title,
            description: descripition,
            status: taskStatus,
            login_user_id: assignTask
        }
        dispatch(handleUpdateNewTasks('update_new_task', reqData, setShowToast, id, setShowModal, paginationNumber))
    }

    else if (allDataPresent) {
        const { title, descripition, taskStatus, assignTask } = formData
        const reqData = {
            title: title,
            description: descripition,
            status: taskStatus,
            login_user_id: Number(assignTask)
        }
        dispatch(handleAddNewTask('add_new_task', reqData, setShowToast))
    }
}

export const onhandleAddWorkShift = (formData,
    setErrorMessages,
    errorMessage,
    dispatch,
    setShowToast,
    formdata,
    setShowModal,
    isEdit, logData) => {

    const specificValidations = {
        date: 'Date is required',
        checkInTime: 'Check-in time is required',
        checkOutTime: 'Check-out time is required'
    }

    const allValidations = { ...specificValidations };

    const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

    const newErrorMessages = Object.fromEntries(
        Object.entries(allValidations).map(([field, message]) => [
            field,
            validateField(field, formData[field], message),
        ]))
    setErrorMessages({ ...errorMessage, ...newErrorMessages, });
    const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

    if (allDataPresent && isEdit) {
        const reqData = {
            date: formData.date,
            checkin_time: formData.checkInTime,
            checkout_time: formData.checkOutTime,
            breakin_time: formData.breakInTime,
            breakout_time: formData.breakOutTime,
            user_id: logData.user_id,
            record_id: logData.id
        }
        if (formData.breakTime) {
            reqData.breakCheckControl = 'on'
        }
        dispatch(handleUpdateTeamTimeShift('update_team_time_shift', reqData, formdata, setShowToast, setShowModal, setErrorMessages))
    }

    else if (allDataPresent) {
        const reqData = {
            date: formData.date,
            checkintime: formData.checkInTime,
            checkouttime: formData.checkOutTime,
            breakintime: formData.breakInTime,
            breakouttime: formData.breakOutTime,
            user_id: logData.id,
        }
        if (formData.breakTime) {
            reqData.breakCheckControl = 'on'
        }
        dispatch(handleAddTeamWorkTimeShift('add_team_work_shift', reqData, formdata, setShowToast, setShowModal, setErrorMessages))
    }
}

export const onhandleValidateBreakSettingForm = (formData,
    setErrorMessages,
    errorMessage,
    dispatch,
    setShowToast,
    formdata,
    setShowModal,
    isEdit, logData) => {

    const specificValidations = {
        breakin_diff_minutes: 'Breakin diff minutes is required',
        breakin_diff_hours: 'Break in hours is required',
        break_time: 'Break time is required',
    }

    const allValidations = { ...specificValidations };

    const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

    const newErrorMessages = Object.fromEntries(
        Object.entries(allValidations).map(([field, message]) => [
            field,
            validateField(field, formData[field], message),
        ]))
    setErrorMessages({ ...errorMessage, ...newErrorMessages, });
    const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

    if (allDataPresent) {
        const reqData = {
            breakin_diff_minutes: formData.breakin_diff_minutes,
            break_time: formData.break_time,
            breakin_diff_hours: formData.breakin_diff_hours,
        }
        if (formData.break_lock) {
            reqData.breakCheckControl = 1
        }
        if (!formData.break_lock) {
            reqData.breakCheckControl = 0
        }
        dispatch(updateBreakSetting('update_break_setting', reqData, formdata, setShowToast, setShowModal, setErrorMessages))
    }
}

export const onhandleValidatePayrollSettingForm = (formData,
    setErrorMessages,
    errorMessage,
    dispatch,
    setShowToast,
    formdata,
    setShowModal,
    isEdit, logData) => {

    let specificValidations = {
        payroll_period: 'payroll_period is required',
        hour: 'hours is required',
        // weekly_start_date: 'weekly_start_date is required'
    }

    if (formData?.payroll_period == 'week') {
        specificValidations = {
            payroll_period: 'payroll_period is required',
            hour: 'hours is required',
            // weekly_start_date: 'weekly_start_date is required'
        }
    } else if (formData?.payroll_period == 'twoweek') {
        specificValidations = {
            payroll_period: 'payroll_period is required',
            hour: 'hours is required',
            // two_weekly_start_date: 'two_weekly_start_date is required'
        }
    } else if (formData?.payroll_period == 'month') {
        specificValidations = {
            payroll_period: 'payroll_period is required',
            hour: 'hours is required',
            first_monthly_day: 'monthly_Start_day is required'
        }
    } else if (formData?.payroll_period == 'twiceMonth') {
        specificValidations = {
            payroll_period: 'payroll_period is required',
            hour: 'hours is required',
            first_monthly_day: 'first_monthly_day is required',
            second_monthly_day: 'second_monthly_day is required'
        }
    }

    const allValidations = { ...specificValidations };

    const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

    const newErrorMessages = Object.fromEntries(
        Object.entries(allValidations).map(([field, message]) => [
            field,
            validateField(field, formData[field], message),
        ]))
    setErrorMessages({ ...errorMessage, ...newErrorMessages, });
    const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

    if (allDataPresent) {
        let reqData;
        if (formData?.payroll_period == 'week') {
           
            reqData = {
                payroll_period: formData?.payroll_period,
                hour: formData?.hour,
                weekly_start_day: formData?.payroll_periods_begin_on
            }
        } else if (formData?.payroll_period == 'twoWeeks') {
            reqData = {
                payroll_period: formData?.payroll_period,
                hour: formData?.hour,
                two_weekly_start_date: formData?.date
            }
        } else if (formData?.payroll_period == 'month') {
            reqData = {
                payroll_period: formData?.payroll_period,
                hour: formData?.hour,
                monthly_start_day: Number(formData?.first_monthly_day?.replace(/[a-zA-Z]/g, ''))
            }
        } else if (formData?.payroll_period == 'twiceMonth') {
            reqData = {
                payroll_period: formData?.payroll_period,
                hour: formData?.hour,
                first_monthly_day: Number(formData?.first_monthly_day1?.replace(/[a-zA-Z]/g, '')),
                second_monthly_day: Number(formData?.second_monthly_day?.replace(/[a-zA-Z]/g, '')),
            }
        }
        dispatch(updatePayrollSetting('update_payroll_setting', reqData, formdata, setShowToast, setShowModal, setErrorMessages))
    }
}

export const onhandleValidatePharmacyTimeSettingForm = (formData,
    setErrorMessages,
    errorMessage,
    dispatch,
    setShowToast,
    formdata,
    setShowModal,
    isEdit, logData) => {

    const specificValidations = {
        schedule_day: 'Schedule is required',
        end_time: 'End Time is required',
        start_time: 'Start Time is required'
    }

    const allValidations = { ...specificValidations };

    const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

    const newErrorMessages = Object.fromEntries(
        Object.entries(allValidations).map(([field, message]) => [
            field,
            validateField(field, formData[field], message),
        ]))
    setErrorMessages({ ...errorMessage, ...newErrorMessages, });
    const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

    if (allDataPresent) {
        const reqData = {
            schedule_day: formData.schedule_day,
            end_time: formData.end_time,
            start_time: formData.start_time,
        }

        dispatch(updatePharmacyTimeSetting('enforcement_schedule_setting', reqData, formdata, setShowToast, setShowModal, setErrorMessages))
    }
}

export const onhandleValidataePharmacySettingsForm = (type, formData, setErrorMessages, errorMessage, dispatch, setShowToast) => {

    let specificValidations;

    if (type === 'winrx_settings') {
        specificValidations = {
            winrx_pharmacy_id: 'Pharmacy ID is required',
            winrx_token: 'Token is required',
        }
    }

    if (type === 'fax_settings') {
        specificValidations = {
            fax_accound_id: 'Account ID is required',
            fax_password: 'Fax password is required',
            sender_fax_number: 'Sender Fax Number is required',
            sender_email: 'Sender Email is required',
        }
    }

    if (type === 'app_notifications') {
        specificValidations = {
            app_fcm_token_key: 'FCM token is required',
        }
    }

    const allValidations = { ...specificValidations };

    const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

    const newErrorMessages = Object.fromEntries(Object.entries(allValidations).map(([field, message]) => [field, validateField(field, formData[field], message)]))

    setErrorMessages({ ...errorMessage, ...newErrorMessages, });
    const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

    const { winrx_pharmacy_id, winrx_status, winrx_token } = formData

    if (allDataPresent && type === 'winrx_settings') {
        const reqData = {
            winrx_pharmacy_id: winrx_pharmacy_id,
            winrx_pharmacy_token: winrx_token,
            winrx_active: winrx_status ? 1 : 0,
            setting_type: 'winrx'
        }
        dispatch(handleUpdateWinrXSettings('update_winrx_settings', setShowToast, reqData))
    }

    if (allDataPresent && type === 'fax_settings') {
        const reqData = {
            account_id: formData.fax_accound_id,
            password: formData.fax_password,
            email: formData.sender_email,
            srFax_active: formData.fax_status ? 1 : 0,
            setting_type: 'srfax',
            fax_number: formData.sender_fax_number
        }
        dispatch(handleUpdateWinrXSettings('update_fax_settings', setShowToast, reqData))
    }

    if (allDataPresent && type === 'app_notifications') {
        const reqData = {
            fcm_token_key: formData.app_fcm_token_key,
            setting_type: 'app_notifications',
        }
        dispatch(handleUpdateWinrXSettings('app_notifications', setShowToast, reqData))
    }
}

export const onhandleAddShiftValidationForm = (setErrorMessages, errorMessage, formData, dispatch, setShowModal, setShowToast,startDate, endDate) => {
    const specificValidations = {
        employeesID: 'Please select at least one employee.',
        occuernce: 'Please enter the number of occurrences.',
        date: 'Date is required.',
        startTime: 'Start Time is required.',
        endTime: 'End Time is required.',
        repeatOn: 'Please select at least one day of the week.'
    };

    const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

    const validateTimeOrder = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return '';
        }
        return new Date(`1970-01-01T${endTime}`) <= new Date(`1970-01-01T${startTime}`) ? 'End Time must be after Start Time.' : '';
    };

    const newErrorMessages = Object.fromEntries(
        Object.entries(specificValidations).map(([field, message]) => [
            field,
            validateField(field, formData[field], message),
        ])
    );

    newErrorMessages.endTime = newErrorMessages.endTime || validateTimeOrder(formData.startTime, formData.endTime);

    if (formData.repeatOn.length === 0) {
        newErrorMessages.repeatOn = 'Please select at least one day of the week.';
    }

    setErrorMessages({ ...errorMessage, ...newErrorMessages });

    const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

    if (allDataPresent) {

        const { startTime, endTime, date, occuernce, employeesID } = formData;
        const ids = employeesID?.map((value) => value.value);
        const reqData = {
            department_employees: ids,
            start_date: date,
            start_time: startTime,
            end_time: endTime,
            weekday: formData.repeatOn.slice(),
            occurrence: occuernce
        };
        dispatch(HandleADDShift('add_shift', setShowToast, setShowModal, reqData,startDate, endDate))
    }

}

export const onhandleEditScheduleShiftValidationForm = (setErrorMessages, errorMessage, formData, dispatch, setShowModal, setShowToast, id, startFormatted, endFormatted) => {
    const specificValidations = {
        startTime: 'Start Time is required.',
        endTime: 'End Time is required.',
    };

    const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

    const validateTimeOrder = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return '';
        }
        return new Date(`1970-01-01T${endTime}`) <= new Date(`1970-01-01T${startTime}`) ? 'End Time must be after Start Time.' : '';
    };

    const newErrorMessages = Object.fromEntries(
        Object.entries(specificValidations).map(([field, message]) => [
            field,
            validateField(field, formData[field], message),
        ])
    );

    newErrorMessages.endTime = newErrorMessages.endTime || validateTimeOrder(formData.startTime, formData.endTime);

    setErrorMessages({ ...errorMessage, ...newErrorMessages });

    const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

    if (allDataPresent) {
        const { startTime, endTime } = formData;
        const reqData = {
            start_time: startTime,
            end_time: endTime,
        };
        dispatch(HandleEDITShift('edit_shift', setShowToast, setShowModal, reqData, id, startFormatted, endFormatted))
    }
}