import React, { useEffect } from 'react'
import { initTabs } from 'flowbite';

import {
	LoadingView,
	Sidebar,
} from '../../../components/common';

import { ErrorMessages } from '../../../components/error/alertMessages';

import * as pharmacy from '../../../services/actions/pharmacy/other';
import * as Hooks from '../../../hooks'

const GeneralInformation = () => {

	const location = Hooks.useCustomLocation()
	const dispatch = Hooks.useCustomDispatch()
	const moduleData = Hooks.useCustomSelector(state => state?.pharmacySearch)
	const moduleList = moduleData?.data

	const moduleName = new URLSearchParams(location.search).get('module');
	const moduleID = parseInt(new URLSearchParams(location.search).get('id'))

	useEffect(() => {
		if (moduleName && moduleID) {
			dispatch(pharmacy.pharmacySearchModule(moduleName, moduleID))
		}
	}, [moduleID])

	useEffect(() => {
		initTabs()
	}, [moduleList])

	return (
		<React.Fragment>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" >
								<div className="container mt-2">

									{
										moduleData?.loading ?
											<div className='flex items-center justify-center mt-60'>
												<LoadingView />
											</div>
											: moduleData?.error !== null ?
												<ErrorMessages />
												:
												<div className="border rounded-lg px-5 py-5 md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white">
													<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
														<div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
															<div className="grid grid-cols-12 gap-2">
																<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">

																	{moduleList?.clinic?.name &&
																		<p className='rubik-500 fs-18'>	{moduleList?.clinic?.name}</p>}

																	{moduleList?.pharmacy?.name &&
																		<p className='rubik-500 fs-18'>	{moduleList?.pharmacy?.name}</p>}

																	<div className="mb-2">
																		<ul className="grid grid-cols-4 flex-wrap -mb-px text-sm font-medium text-center" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
																			<li className="me-2" role="presentation">
																				<button className="w-full fs-16 rubik-400 inline-block p-4 border-b-2 rounded-t-lg" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">General Details</button>
																			</li>
																			<li className="me-2" role="presentation">
																				<button className="w-full fs-16 rubik-400 inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" id="dashboard-tab" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false">About</button>
																			</li>
																			<li className="me-2" role="presentation">
																				<button className="w-full fs-16 rubik-400 inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" id="settings-tab" data-tabs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false">Open Hours</button>
																			</li>
																			<li role="presentation">
																				<button className="w-full fs-16 rubik-400 inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" id="contacts-tab" data-tabs-target="#contacts" type="button" role="tab" aria-controls="contacts" aria-selected="false">Services</button>
																			</li>
																		</ul>
																	</div>
																	<div id="default-tab-content">
																		<div className="hidden p-4 rounded-lg" id="profile" role="tabpanel" aria-labelledby="profile-tab">
																			<div className="relative overflow-x-auto shadow-md sm:rounded-md">
																				<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

																					<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
																						<tr>
																							<th scope="col" className="px-6 py-3">
																								<p className="rubik-500 fs-16">Location</p>
																							</th>
																							<th scope="col" className="px-6 py-3">
																								<p className="rubik-500 fs-16">Phone</p>
																							</th>

																							<th scope="col" className="px-6 py-3">
																								<p className="rubik-500 fs-16">Fax</p>
																							</th>

																							<th scope="col" className="px-6 py-3">
																								<p className="rubik-500 fs-16">Email</p>
																							</th>

																							<th scope="col" className="px-6 py-3">
																								<p className="rubik-500 fs-16">Website</p>
																							</th>
																						</tr>
																					</thead>

																					<tbody>
																						{moduleList?.clinic ?
																							<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
																								<th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
																									<p className="rubik-400 fs-16">
																										{moduleList?.clinic?.address},
																										{moduleList?.clinic?.province},
																										{moduleList?.clinic?.zip_code}
																									</p>

																								</th>

																								<td className="px-6 py-8">
																									<p className="rubik-400 fs-16 whitespace-nowrap"> {moduleList?.clinic?.phone}</p>
																								</td>


																								<td className="px-6 py-4">
																									<p className="rubik-400 fs-16 whitespace-nowrap">{moduleList?.clinic?.fax} </p>
																								</td>


																								<td className="px-6 py-4">
																									<p className="rubik-400 fs-16">{moduleList?.clinic?.email}</p>
																								</td>


																								<td className="px-6 py-4">
																									<a href={moduleList?.clinic?.website} target='_blank' className="rubik-400 fs-16">
																										{moduleList?.clinic?.website}</a>
																								</td>

																							</tr> : moduleList?.pharmacy ?

																								<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
																									<th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
																										<p className="rubik-400 fs-16">
																											{moduleList?.pharmacy?.address},
																											{moduleList?.pharmacy?.province},
																											{moduleList?.pharmacy?.zip_code}
																										</p>

																									</th>

																									<td className="px-6 py-8">
																										<p className="rubik-400 fs-16 whitespace-nowrap"> {moduleList?.pharmacy?.phone}</p>
																									</td>

																									<td className="px-6 py-4">
																										<p className="rubik-400 fs-16 whitespace-nowrap">{moduleList?.pharmacy?.fax} </p>
																									</td>

																									<td className="px-6 py-4">
																										<p className="rubik-400 fs-16">{moduleList?.pharmacy?.email}</p>
																									</td>

																									<td className="px-6 py-4">
																										<a href={moduleList?.pharmacy?.website} target='_blank' className="rubik-400 fs-16">
																											{moduleList?.pharmacy?.website}</a>
																									</td>
																								</tr>

																								: null}
																					</tbody>
																				</table>
																			</div>
																		</div>

																		<div className="hidden p-4 rounded-lg" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
																			<p className="rubik-400 dark:text-gray-400 mb-2" dangerouslySetInnerHTML={{ __html: moduleList?.about }}></p>
																		</div>

																		<div className="hidden p-4 rounded-lg" id="settings" role="tabpanel" aria-labelledby="settings-tab">
																			{moduleList?.working_hours?.length === 0 ? <p>No working hours available</p> :
																				<div className="border">
																					{moduleList?.working_hours?.map((value, index) => {
																						return (
																							<React.Fragment key={index}>
																								{value.day === 'Sunday' ?
																									<div className="grid grid-cols-2 border-b py-2 px-3">
																										<h5 className="fs-16 rubik-400">{value.day}</h5>
																										<h5 className="fs-16 rubik-400">{value.start} - {value.end}</h5>
																									</div> :
																									value.day === 'Saturday' ?
																										<div className="grid grid-cols-2 border-b py-2 px-3">
																											<h5 className="fs-16 rubik-400">{value.day}</h5>
																											<h5 className="fs-16 rubik-400">{value.start} - {value.end}</h5>
																										</div>
																										:
																										<div className="grid grid-cols-2 border-b py-2 px-3">
																											<h5 className="fs-16 rubik-400">{value.day}</h5>
																											<h5 className="fs-16 rubik-400">{value.start} - {value.end}</h5>
																										</div>}
																							</React.Fragment>
																						)
																					})}
																				</div>}
																		</div>
																		<div className="hidden p-4 rounded-lg" id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
																			{moduleList?.services?.length === 0 ? <p>No Service available</p> :
																				moduleList?.services?.map((value, index) => {
																					return (
																						<React.Fragment key={index}>
																							<div className="flex items-center mb-3">
																								<input disabled checked type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
																								<label htmlFor="disabled-checked-checkbox" className="ml-2 text-sm font-medium dark:text-gray-500 rubik-400 fs-16">{value.name}</label>
																							</div>
																						</ React.Fragment>
																					)
																				})}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default GeneralInformation