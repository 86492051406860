import { addWallPostLikes } from "../../services/actions/posts/addWallPostLikesActions";
import { addPostCommentsLikes } from "../../services/actions/posts/addUserCommentLikesActions";

export const initializePostLikes = (wallPostLists, setLikes, setTotalLikes) => {
	const postLikesStatus = wallPostLists?.map(value => (value?.user_default_like === 1 ? 1 : 0));
	const postLikesCount = wallPostLists?.map(value => value?.total_likes);

	if (postLikesStatus && postLikesCount) {
		setLikes(postLikesStatus);
		setTotalLikes(postLikesCount);
	}
};

export const toggleWallPostLikes = async (index, id, likes, totalLikes, setLikes, setTotalLikes, dispatch) => {
	const newLikes = [...likes];
	const newTotalLikes = [...totalLikes];
	newLikes[index] = newLikes[index] === 1 ? 0 : 1;
	if (newLikes[index] === 1) {
		newTotalLikes[index] += 1;
		dispatch(addWallPostLikes(id, 1));
	} else {
		newTotalLikes[index] -= 1;
		dispatch(addWallPostLikes(id, 0));
	}
	setLikes(newLikes);
	setTotalLikes(newTotalLikes);
};


export const initializeCommentLikes = (commentsData, setCommentLikes, setTotalCommentLikes) => {
	const commentLikesStatus = commentsData?.map(value => (value?.default_comment_likes === 1 ? 1 : 0));
	const commentLikesCount = commentsData?.map(value => value?.total_comment_likes);

	if (commentLikesStatus && commentLikesCount) {
		setCommentLikes(commentLikesStatus);
		setTotalCommentLikes(commentLikesCount);
	}
}

export const toggleWallPostCommentLikes = async (index, id, commentLikes, totalCommentLikes, setCommentLikes, setTotalCommentLikes, dispatch) => {
	const newCommentsLikes = [...commentLikes];
	const newTotalCommentsLikes = [...totalCommentLikes];
	newCommentsLikes[index] = newCommentsLikes[index] === 1 ? 0 : 1;
	if (newCommentsLikes[index] === 1) {
		newTotalCommentsLikes[index] += 1;
		dispatch(addPostCommentsLikes(id, 1))

	} else {
		newTotalCommentsLikes[index] -= 1;
		dispatch(addPostCommentsLikes(id, 0))

	}
	setCommentLikes(newCommentsLikes)
	setTotalCommentLikes(newTotalCommentsLikes)
}



