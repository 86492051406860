import React, { useState, useEffect } from 'react'

import {
	initalPharmacyInformationUpdateForm,
	initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import {
	BreadCrumb,
	Footer,
	Sidebar
} from '../../../components/common'

import { useCustomDispatch } from '../../../hooks'
import { Errors } from '../../../components/error/alertMessages'
import { Loader } from '../../../components/common/loadingView'

import { onhandlePharmacyFormDataChange } from '../../../utils/data/handleFormDataChange'
import { handlePharmacy_PlanG_PlanZ_Form } from '../../../utils/validations/formsValidations'

import { useFormsSelectors } from '../../../services/selectors/formsSelector'
import { SuccessToastMessage } from '../../../components/loaders/toastMessage'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

import ChatBox from '../../../components/chat/chatbox'

import * as Pharmacy from '../../../components/pharmacy/form/form'
import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'
import { faxFormFailure } from '../../../services/actions/pharmacy/forms/pharmacyUpdateNotificationsSrFaxForm'

const PlanZForm = () => {

	const dispatch = useCustomDispatch()

	const { previewPdfResponse, sendFaxResponse } = useFormsSelectors()
	const { pharmacyLoginData } = useCustomSelectors()

	const [formData, setFormData] = useState(initalPharmacyInformationUpdateForm)
	const [errorMessages, setErrorMessages] = useState(initalPharmacyInformationUpdateForm)
	const [showToast, setShowToast] = useState(initalShowToastMessage)

	const handleFormDataChange = (event) => {
		const { name, checked, value } = event.target;

		setFormData((prevValues) => ({
			...prevValues,
			planZFormRational: event.target.type === 'checkbox'
				? checked
					? [...(prevValues.planZFormRational || []), parseInt(value, 10)]
					: (prevValues.planZFormRational || []).filter(num => num !== parseInt(value, 10))
				: prevValues.planZFormRational,
		}))

		if (event.target.type !== 'checkbox') {
			onhandlePharmacyFormDataChange(event, setFormData, formData, setErrorMessages, errorMessages);
		}
	}

	useEffect(() => {
		dispatch(previewPdfFailure(null))
		dispatch(faxFormFailure(null))
	}, [])

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">

									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
											<Pharmacy.Date formData={formData}
												setFormData={setFormData} />

											<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
												<Pharmacy.FormWinRxPatients
													formData={formData}
													handleFormDataChange={handleFormDataChange} />
											</ul>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
											<Pharmacy.PatientPhn
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setErrorMessages={setErrorMessages}
												setFormData={setFormData} />

											<Pharmacy.PatientName
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
												setErrorMessages={setErrorMessages} />

											<Pharmacy.PatientDob
												setErrorMessages={setErrorMessages}
												formData={formData}
												setFormData={setFormData}
												handleFormDataChange={handleFormDataChange}
												errorMessages={errorMessages} />
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<div className="relative ot_addNoteText">
												<div className="relative did-floating-label-content">
													<Pharmacy.ClinicName
														formData={formData}
														errorMessages={errorMessages}
														setFormData={setFormData}
														setErrorMessages={setErrorMessages} />

													<Pharmacy.AddClinic />
												</div>
											</div>

											<div className="relative ot_addNoteText">
												<div className="relative did-floating-label-content">
													<Pharmacy.DoctorList
														formData={formData}
														errorMessages={errorMessages}
														setFormData={setFormData}
														setErrorMessages={setErrorMessages} />

													<Pharmacy.AddDoctor />

												</div>
											</div>

											<Pharmacy.ClinicFaxNumber
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages} />
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-10">
											<div className="relative">
												<h1 className='rubik-500' >Select the most applicable option. As the prescriber or pharmacist, I certify that:</h1>
												<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
													{[
														{ id: 1, label: 'The patient has applied for MSP and is waiting to be enrolled', value: 1 },
														{ id: 2, label: 'The patient understands that they must apply for MSP without delay.', value: 2 },
													]?.map((item, index) => (
														<li key={index} className={`ml-4 w-full border-b border-gray-200 sm:${index === 3 ? 'border-b-0' : 'border-b-0 sm:border-r'} dark:border-gray-600`}>
															<div className="flex items-center ps-3">
																<input
																	id={item.id}
																	name='planZFormApplicationOption'
																	type="radio"
																	onChange={(e) => { return setFormData({ ...formData, planZFormApplicationOption: item.value }) }}
																	defaultValue={formData?.planZFormApplicationOption}
																	className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
																/>
																<label htmlFor={item.id} className="w-full rubik-400  py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
																	{item.label}
																</label>
															</div>
														</li>
													))}
												</ul>
											</div>
										</div>
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">
											<div className="relative">
												<h1 className='rubik-500'>Both must be checked. I certify that:</h1>
											</div>

											{[
												'I have advised the patient that exceptional Plan Z coverage is for at most six months, with no possibility of renewal; and,',
												'Without prescribed medication, the patient is likely to be hospitalized for a psychiatric condition',
											]?.map((label, index) => (
												<div key={index} className="flex items-center mb-0 gap-3">
													<input id={`checkbox-${index}`} name="planZFormRational" type="checkbox"
														value={index + 1} checked={formData?.planZFormRational?.includes(index + 1)}
														onChange={handleFormDataChange}
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
													<label htmlFor={`checkbox-${index}`} className="fs-14 rubik-400 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
														{label}
													</label>
												</div>
											))}

										</div>

										<br />

										{previewPdfResponse?.error !== null &&
											<>
												<br />
												<Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
											</>
										}

										{sendFaxResponse?.error !== null &&
											<>
												<br />
												<Errors error={sendFaxResponse?.error.message ?
													sendFaxResponse?.error?.message :
													'Something went wrong when sending Fax. Please try again'} />
											</>
										}

										<div className='flex'>
											<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
												<button style={{ height: '40px' }} type="button"

													onClick={() => handlePharmacy_PlanG_PlanZ_Form(
														formData,
														setErrorMessages,
														errorMessages,
														dispatch,
														'PDF', 'PLAN-Z-FORM')}

													className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

													{previewPdfResponse?.loading ? <>
														Genrating PDF
														&nbsp;<Loader /> </> : 'Preview PDF'}

												</button>
											</div>

											{pharmacyLoginData?.srFax_active === 1 && pharmacyLoginData?.srFax_status === 1 && 
												<div className="pl-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
													<button style={{ height: '40px', backgroundColor: 'lightseagreen' }} type="button"

														onClick={() => handlePharmacy_PlanG_PlanZ_Form(
															formData,
															setErrorMessages,
															errorMessages,
															dispatch,
															'FAX', 'PLAN-Z-FORM',
															showToast,
															setShowToast)}

														className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center" >

														{sendFaxResponse?.loading ? <>
															Sending
															&nbsp;<Loader /> </> : 'Send Fax'}

													</button>
												</div>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ChatBox />
			<Footer />

			{showToast.showToast && <SuccessToastMessage status={'Fax sent'} />}
		</>
	)
}

export default PlanZForm