import {
	PREVIEW_PDF_REQUEST,
	PREVIEW_PDF_SUCCESS,
	PREVIEW_PDF_FAILURE
} from "../../../constants"

import Axios from "../../../../networking/intraceptor"
import { PreviewPdf } from "../../../../utils/helper/helper"

export const previewPdfRequest = () => ({
	type: PREVIEW_PDF_REQUEST,
})

export const previewPdfSuccess = (data) => ({
	type: PREVIEW_PDF_SUCCESS,
	payload: data,
})

export const previewPdfFailure = (error) => ({
	type: PREVIEW_PDF_FAILURE,
	payload: error,
})

export const pharmacyPreviewFormPdf = (path, requestedData) => {
	return async (dispatch) => {
		dispatch(previewPdfRequest())
		try {
			const response = await Axios.post(`${path}`, requestedData)
			if (response?.status === 200) {
				dispatch(previewPdfSuccess(response?.data))
				PreviewPdf(response?.data?.data)

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(previewPdfFailure(error?.response?.data))
			}
		}
	}
}

export const pharmacySentFaxLogsPreviewPdf = (path, type) => {
	return async (dispatch) => {
		dispatch(previewPdfRequest())
		try {
			const response = await Axios.post(`${path}`)
			if (response?.status === 200) {
				dispatch(previewPdfSuccess(response?.data))
				PreviewPdf(response?.data?.data)
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(previewPdfFailure(error?.response?.data))

				if (type === 'sent_fax_log') {
					const errorWindow = window.open(path, "_blank");
					errorWindow.document.write(`<h2>${error?.response?.data?.data?.error}</h2>`);
				}
			}
		}
	}
}

export const pharmacyPreviewInvoicePdf = (path) => {
	return async (dispatch) => {
		dispatch(previewPdfRequest())
		try {
			const response = await Axios.get(`${path}`)
			if (response?.status === 200) {
				dispatch(previewPdfSuccess(response?.data))
				PreviewPdf(response?.data?.data)

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(previewPdfFailure(error?.response?.data))
			}
		}
	}
}

export const previewAndDownloadCertificate = (path) => {
	return async (dispatch) => {
		dispatch(previewPdfRequest())
		try {
			const response = await Axios.get(`${path}`)
			if (response?.status === 200) {
				dispatch(previewPdfSuccess(response?.data))
				PreviewPdf(response?.data?.data)

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(previewPdfFailure(error?.response?.data))
			}
		}
	}
}