import React, {
    useEffect,
    useState,
} from "react";

import { useCustomDispatch } from "../../../hooks";
import { fetchCashChangeList } from "../../../services/actions/pharmacy/revenue/revenueList";

import { useRevenueSelector } from "../../../services/selectors/revenueSelectors";
import { AddCashChangeData } from "../../../services/actions/pharmacy/revenue/addRevenueData";

import ChatBox from "../../../components/chat/chatbox";
import * as Common from "../../../components/common";

const revenueListHeading = [
    { text: "CHANGE", classNames: ['p-3'] },
    { text: "NO OF PACKS", classNames: ['p-3'] },
    { text: "TOTAL", classNames: ['p-3'] },
];

const TableRowData = ({ value, className1 = 'p-3 w-96', }) => {
    return (<td  className={className1}>
        <p>{value}</p>
    </td>)
}

const CashChange = () => {

    const dispatch = useCustomDispatch()

    const { RevenueListResponse } = useRevenueSelector();

    const dailyRevenueList = RevenueListResponse?.data?.data
    const cashList = RevenueListResponse?.data?.data?.cashchange
    const listData = RevenueListResponse?.data?.data?.data

    useEffect(() => {
        dispatch(fetchCashChangeList('cash_change_list'))
        return () => { }
    }, [])

    const formattedCashListData = [{ change: cashList?.[0], no_of_packs: listData?.$100, total: listData?.t_$100 },
    { change: cashList?.[1], no_of_packs: listData?.$50, total: listData?.t_$50 },
    { change: cashList?.[2], no_of_packs: listData?.$20, total: listData?.t_$20 },
    { change: cashList?.[3], no_of_packs: listData?.$10, total: listData?.t_$10 },
    { change: cashList?.[4], no_of_packs: listData?.$5, total: listData?.t_$5 },
    { change: cashList?.[5], no_of_packs: listData?.$2, total: listData?.t_$2 },
    { change: cashList?.[6], no_of_packs: listData?.$1, total: listData?.t_$1 },
    { change: cashList?.[7], no_of_packs: listData?.["$0.25"], total: listData?.['t_$0.25'] },
    { change: cashList?.[8], no_of_packs: listData?.["$0.10"], total: listData?.['t_$0.10'] },
    { change: cashList?.[9], no_of_packs: listData?.["$0.05"], total: listData?.['t_$0.05'] }]

    const [packsValues, setPacksValues] = useState({});
    const [cashChangeList, setCashChangeList] = useState(formattedCashListData)

    const [superTotal, setSuperTotal] = useState(0)

    useEffect(() => {
        setCashChangeList(formattedCashListData)
        setSuperTotal(listData?.super_total)
        return () => { }
    }, [cashList, listData])

    const handleChange = (value, index, data) => {
        value = parseFloat(value);
        if (isNaN(value)) {
            value = 0;
        }
        const newPacksValues = { ...packsValues, [index]: value };
        setPacksValues(newPacksValues);

        const changeValue = parseFloat(cashChangeList[index].change.substring(1));

        const total = value * changeValue;
        const updatedList = cashChangeList.map((item, idx) => {
            if (idx === index) {
                return { ...item, no_of_packs: value, total: total };
            }
            return item;
        });
        setCashChangeList(updatedList);

        let supertotal = 0
        updatedList.forEach(item => {
            supertotal += parseFloat(item.total);
        })
        setSuperTotal(`$${supertotal}`)

        const requestedData = {
            change_100_num_of_pack: updatedList?.[0]?.no_of_packs,
            change_100_total: updatedList?.[0]?.total,

            change_50_num_of_pack: updatedList?.[1]?.no_of_packs,
            change_50_total: updatedList?.[1]?.total,

            change_20_num_of_pack: updatedList?.[2]?.no_of_packs,
            change_20_total: updatedList?.[2]?.total,

            change_10_num_of_pack: updatedList?.[3]?.no_of_packs,
            change_10_total: updatedList?.[3]?.total,

            change_5_num_of_pack: updatedList?.[4]?.no_of_packs,
            change_5_total: updatedList?.[4]?.total,

            change_2_num_of_pack: updatedList?.[5]?.no_of_packs,
            change_2_total: updatedList?.[5]?.total,

            change_1_num_of_pack: updatedList?.[6]?.no_of_packs,
            change_1_total: updatedList?.[6]?.total,

            change_025_num_of_pack: updatedList?.[7]?.no_of_packs,
            change_025_total: updatedList?.[7]?.total,

            change_010_num_of_pack: updatedList?.[8]?.no_of_packs,
            change_010_total: updatedList?.[8]?.total,

            change_005_num_of_pack: updatedList?.[9]?.no_of_packs,
            change_005_total: updatedList?.[9]?.total,

            super_total:(`$${supertotal}`)
        }
        dispatch(AddCashChangeData('save_cash_change_data', requestedData))
    }

    return (
        <>
            <div className="BodyBgColor">
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center 
                                                    text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                        {RevenueListResponse?.loading && RevenueListResponse?.type === 'cash_change_list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            RevenueListResponse?.error !== null && RevenueListResponse?.type === 'cash_change_list' ?

                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :

                                                dailyRevenueList?.data?.length === 0 && RevenueListResponse?.type === 'cash_change_list' ?

                                                    <div className="bg-white rounded-md mt-4">
                                                        <p className="text-gray-500">No Records are available.</p>
                                                    </div>

                                                    : RevenueListResponse?.type === 'cash_change_list' ?

                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {revenueListHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            cashChangeList?.map((value, idx) => {
                                                                                return (<React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: '1px solid lightgray' }}>
                                                                                        <TableRowData value={`${value.change}`} />

                                                                                        <td  className={'w-96'}>
                                                                                            <input type="text" autoComplete="off"
                                                                                                
                                                                                                defaultValue={packsValues[idx] || value.no_of_packs}
                                                                                                onChange={(e) => handleChange(e.target.value, idx, value)}

                                                                                                style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', width: '100px' }}
                                                                                                className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent border-b border-gray-300 
                                                                                                appearance-none 
                                                                                                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                                                                bg-white `}
                                                                                                placeholder=""
                                                                                            />
                                                                                        </td>
                                                                                        <TableRowData value={value.total} />
                                                                                    </tr>
                                                                                </React.Fragment>)
                                                                            })}

                                                                        <tr style={{ borderBottom: '1px solid lightgray' }}>
                                                                            <td  className={'p-3'}>
                                                                                <p><span className='rubik-500 text-gray-900'>SUPER TOTAL</span></p>
                                                                            </td>

                                                                            <td  className={'p-3'}>
                                                                                <p></p>
                                                                            </td>

                                                                            <td  className={'p-3'}>
                                                                                <p className='rubik-500 text-gray-400'>{superTotal}</p>
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>

                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />
            <Common.Footer />
        </>
    );
};

export default CashChange;
