import {
	PHARMACY_TIMEOFF_LIST_REQUEST,
	PHARMACY_TIMEOFF_LIST_SUCCESS,
	PHARMACY_TIMEOFF_LIST_FAILURE
} from "../../constants";

import { pharmacy_timeoff_lists } from "../../../networking/urlEndPoints";
import Axios from '../../../networking/intraceptor'

export const fetchPharmacyTimeOffRequest = () => ({
	type: PHARMACY_TIMEOFF_LIST_REQUEST,
});

export const fetchPharmacyTimeOffSuccess = (data) => ({
	type: PHARMACY_TIMEOFF_LIST_SUCCESS,
	payload: data,
});

export const fetchPharmacyTimeOffFailure = (error) => ({
	type: PHARMACY_TIMEOFF_LIST_FAILURE,
	payload: error,
});


export const fetchTimeoOffLists = () => {
	return async (dispatch) => {
		dispatch(fetchPharmacyTimeOffRequest());
		try {
			const response = await Axios.post(`${pharmacy_timeoff_lists}`, {
				status: 'All'
			});
			if (response?.status === 200) {
				dispatch(fetchPharmacyTimeOffSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchPharmacyTimeOffFailure(error?.response))
			}
		}
	};
};