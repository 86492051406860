export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const PHARMACY_PERMISSIONS_REQUEST = 'PHARMACY_PERMISSIONS_REQUEST';
export const PHARMACY_PERMISSIONS_SUCCESS = 'PHARMACY_PERMISSIONS_SUCCESS';
export const PHARMACY_PERMISSIONS_FAILURE = 'PHARMACY_PERMISSIONS_FAILURE';

export const FETCH_PHARMACY_PROFILE_REQUEST = 'FETCH_PHARMACY_PROFILE_REQUEST';
export const FETCH_PHARMACY_PROFILE_SUCCESS = 'FETCH_PHARMACY_PROFILE_SUCCESS';
export const FETCH_PHARMACY_PROFILE_FAILURE = 'FETCH_PHARMACY_PROFILE_FAILURE';

export const UPDATE_PHARMACY_USER_PROFILE_REQUEST = 'UPDATE_PHARMACY_USER_PROFILE_REQUEST';
export const UPDATE_PHARMACY_USER_PROFILE_SUCCESS = 'UPDATE_PHARMACY_USER_PROFILE_SUCCESS';
export const UPDATE_PHARMACY_USER_PROFILE_FAILURE = 'UPDATE_PHARMACY_USER_PROFILE_FAILURE';

export const UPDATE_PHARMACY_USER_PROFILE_COVER_REQUEST = 'UPDATE_PHARMACY_USER_PROFILE_COVER_REQUEST';
export const UPDATE_PHARMACY_USER_PROFILE_COVER_SUCCESS = 'UPDATE_PHARMACY_USER_PROFILE_COVER_SUCCESS';
export const UPDATE_PHARMACY_USER_PROFILE_COVER_FAILURE = 'UPDATE_PHARMACY_USER_PROFILE_COVER_FAILURE';

export const FETCH_HOLIDAYS_REQUEST = 'FETCH_HOLIDAYS_REQUEST';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_FAILURE = 'FETCH_HOLIDAYS_FAILURE';

export const FETCH_USER_CHECKINOUT_REQUEST = 'FETCH_USER_CHECKINOUT_REQUEST';
export const FETCH_USER_CHECKINOUT_SUCCESS = 'FETCH_USER_CHECKINOUT_SUCCESS';
export const FETCH_USER_CHECKINOUT_FAILURE = 'FETCH_USER_CHECKINOUT_FAILURE';

export const TIMEOFF_REQUEST = 'REQUEST_TIMEOFF_REQUEST';
export const TIMEOFF_REQUEST_SUCCESS = 'RECEIVE_TIMEOFF_REQUEST_SUCCESS';
export const TIMEOFF_REQUEST_FAILURE = 'RECEIVE_TIMEOFF_REQUEST_FAILURE';

export const FETCH_PHARMACY_TIMEOFF_REQUEST = 'FETCH_PHARMACY_TIMEOFF_REQUEST';
export const FETCH_PHARMACY_TIMEOFF_SUCCESS = 'FETCH_PHARMACY_TIMEOFF_SUCCESS';
export const FETCH_PHARMACY_TIMEOFF_FAILURE = 'FETCH_PHARMACY_TIMEOFF_FAILURE';

export const PHARMACY_TIMEOFF_LIST_REQUEST = 'FETCH_PHARMACY_TIMEOFF_LIST_REQUEST';
export const PHARMACY_TIMEOFF_LIST_SUCCESS = 'FETCH_PHARMACY_TIMEOFF_LIST_SUCCESS';
export const PHARMACY_TIMEOFF_LIST_FAILURE = 'FETCH_PHARMACY_TIMEOFF_LIST_FAILURE';

export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE';

export const FETCH_EMPLOYEES_SCHEDULE_REQUEST = "FETCH_EMPLOYEES_SCHEDULE_REQUEST";
export const FETCH_EMPLOYEES_SCHEDULE_SUCCESS = "FETCH_EMPLOYEES_SCHEDULE_SUCCESS";
export const FETCH_EMPLOYEES_SCHEDULE_FAILURE = "FETCH_EMPLOYEES_SCHEDULE_FAILURE";

export const EDIT_TIMEOFF_REQUEST = 'EDIT_REQUEST_TIMEOFF_REQUEST';
export const EDIT_TIMEOFF_REQUEST_SUCCESS = 'EDIT_RECEIVE_TIMEOFF_REQUEST_SUCCESS';
export const EDIT_TIMEOFF_REQUEST_FAILURE = 'EDIT_RECEIVE_TIMEOFF_REQUEST_FAILURE';

export const DELETE_TIMEOFF_REQUEST = 'DELETE_REQUEST_TIMEOFF_REQUEST';
export const DELETE_TIMEOFF_REQUEST_SUCCESS = 'DELETE_RECEIVE_TIMEOFF_REQUEST_SUCCESS';
export const DELETE_TIMEOFF_REQUEST_FAILURE = 'DELETE_RECEIVE_TIMEOFF_REQUEST_FAILURE';

export const ADD_WALL_POST_REQUEST = 'ADD_WALL_POST_REQUEST';
export const ADD_WALL_POST_SUCCESS = 'ADD_WALL_POST_SUCCESS';
export const ADD_WALL_POST_FAILURE = 'ADD_WALL_POST_FAILURE';

export const FETCH_PHARMACY_WALL_POSTS_LISTS_REQUEST = 'FETCH_PHARMACY_WALL_POSTS_LISTS_REQUEST';
export const FETCH_PHARMACY_WALL_POSTS_LISTS_SUCCESS = 'FETCH_PHARMACY_WALL_POSTS_LISTS_SUCCESS';
export const FETCH_PHARMACY_WALL_POSTS_LISTS_FAILURE = 'FETCH_PHARMACY_WALL_POSTS_LISTS_FAILURE';

export const ADD_WALL_POST_LIST_USER_COMMENTS_REQUEST = 'ADD_WALL_POST_LIST_USER_COMMENTS_REQUEST';
export const ADD_WALL_POST_LIST_USER_COMMENTS_SUCCESS = 'ADD_WALL_POST_LIST_USER_COMMENTS_SUCCESS';
export const ADD_WALL_POST_LIST_USER_COMMENTS_FAILURE = 'ADD_WALL_POST_LIST_USER_COMMENTS_FAILURE';

export const POST_COMMENTS_LISTS_REQUEST = 'POST_COMMENTS_LISTS_REQUEST';
export const POST_COMMENTS_LISTS_SUCCESS = 'POST_COMMENTS_LISTS_SUCCESS';
export const POST_COMMENTS_LISTS_FAILURE = 'POST_COMMENTS_LISTS_FAILURE';

export const FETCH_FCM_TOKEN_REQUEST = 'FETCH_FCM_TOKEN_REQUEST';
export const FETCH_FCM_TOKEN_SUCCESS = 'FETCH_FCM_TOKEN_SUCCESS';
export const FETCH_FCM_TOKEN_FAILURE = 'FETCH_FCM_TOKEN_FAILURE';

export const ADD_WALL_POST_USER_COMMENTS_REPLY_REQUEST = 'ADD_WALL_POST__USER_COMMENTS_REPLY_REQUEST';
export const ADD_WALL_POST__USER_COMMENTS_REPLY_SUCCESS = 'ADD_WALL_POST__USER_COMMENTS_REPLY_SUCCESS';
export const ADD_WALL_POST__USER_COMMENTS_REPLY_FAILURE = 'ADD_WALL_POST__USER_COMMENTS_REPLY_FAILURE';

export const POST_COMMENTS_REPLY_LISTS_REQUEST = 'POST_COMMENTS_REPLY_LISTS_REQUEST';
export const POST_COMMENTS_REPLY_LISTS_SUCCESS = 'POST_COMMENTS_REPLY_LISTS_SUCCESS';
export const POST_COMMENTS_REPLY_LISTS_FAILURE = 'POST_COMMENTS_REPLY_LISTS_FAILURE';

export const FETCH_PHARMACY_BROADCAST_NOTIFICATIONS_REQUEST = 'FETCH_PHARMACY_BROADCAST_NOTIFICATIONS_REQUEST';
export const FETCH_PHARMACY_BROADCAST_NOTIFICATIONS_SUCCESS = 'FETCH_PHARMACY_BROADCAST_NOTIFICATIONS_SUCCESS';
export const FETCH_PHARMACY_BROADCAST_NOTIFICATIONS_FAILURE = 'FETCH_PHARMACY_BROADCAST_NOTIFICATIONS_FAILURE';

export const FETCH_TIMESHEET_DATES_REQUEST = 'FETCH_TIMESHEET_DATES_REQUEST';
export const FETCH_TIMESHEET_DATES_SUCCESS = 'FETCH_TIMESHEET_DATES_SUCCESS';
export const FETCH_TIMESHEET_DATES_FAILURE = 'FETCH_TIMESHEET_DATES_FAILURE';


export const FETCH_WALL_POST_LIKES_REQUEST = 'FETCH_WALL_POST_LIKES_REQUEST';
export const FETCH_WALL_POST_LIKES_SUCCESS = 'FETCH_WALL_POST_LIKES_SUCCESS';
export const FETCH_WALL_POST_LIKES_FAILURE = 'FETCH_WALL_POST_LIKES_FAILURE';

export const FETCH_WALL_POST_LIKES_LIST_REQUEST = 'FETCH_WALL_POST_LIKES_LIST_REQUEST';
export const FETCH_WALL_POST_LIKES_LIST_SUCCESS = 'FETCH_WALL_POST_LIKES_LIST_SUCCESS';
export const FETCH_WALL_POST_LIKES_LIST_FAILURE = 'FETCH_WALL_POST_LIKES_LIST_FAILURE';

export const ADD_COMMENTS_LIKES_REQUEST = 'ADD_COMMENTS_LIKES_REQUEST';
export const ADD_COMMENTS_LIKES_SUCCESS = 'ADD_COMMENTS_LIKES_SUCCESS';
export const ADD_COMMENTS_LIKES_FAILURE = 'ADD_COMMENTS_LIKES_FAILURE';

// ---------------------------------CHat-List-Actions--------------------------

export const FETCH_CHAT_USER_LIST_REQUEST = 'FETCH_CHAT_USER_LIST_REQUEST';
export const FETCH_CHAT_USER_LIST_SUCCESS = 'FETCH_CHAT_USER_LIST_SUCCESS';
export const FETCH_CHAT_USER_LIST_FAILURE = 'FETCH_CHAT_USER_LIST_FAILURE';

export const ADD_MESSAGES_REQUEST = 'ADD_MESSAGES_REQUEST';
export const ADD_MESSAGES_SUCCESS = 'ADD_MESSAGES_SUCCESS';
export const ADD_MESSAGES_FAILURE = 'ADD_MESSAGES_FAILURE';

export const FETCH_CHAT_MESSAGES_LISTS_REQUEST = 'FETCH_CHAT_MESSAGES_LISTS_REQUEST';
export const FETCH_CHAT_MESSAGES_LISTS_SUCCESS = 'FETCH_CHAT_MESSAGES_LISTS_SUCCESS';
export const FETCH_CHAT_MESSAGES_LISTS_FAILURE = 'FETCH_CHAT_MESSAGES_LISTS_FAILURE';

export const ADD_CHAT_GROUP_REQUEST = 'ADD_CHAT_GROUP_REQUEST';
export const ADD_CHAT_GROUP_SUCCESS = 'ADD_CHAT_GROUP_SUCCESS';
export const ADD_CHAT_GROUP_FAILURE = 'ADD_CHAT_GROUP_FAILURE';

export const CHAT_GROUP_LISTS_REQUEST = 'CHAT_GROUP_LISTS_REQUEST'
export const CHAT_GROUP_LISTS_SUCCESS = 'CHAT_GROUP_LISTS_SUCCESS'
export const CHAT_GROUP_LISTS_FAILURE = 'CHAT_GROUP_LISTS_FAILURE'

export const FETCH_USER_APPRECIATION_REQUEST = 'FETCH_USER_APPRECIATION_REQUEST'
export const FETCH_USER_APPRECIATION_SUCCESS = 'FETCH_USER_APPRECIATION_SUCCESS'
export const FETCH_USER_APPRECIATION_FAILURE = 'FETCH_USER_APPRECIATION_FAILURE'

export const INITIATE_CHAT_GROUP_REQUEST = 'INITIATE_CHAT_GROUP_REQUEST'
export const INITIATE_CHAT_GROUP_SUCCESS = 'INITIATE_CHAT_GROUP_SUCCESS'
export const INITIATE_CHAT_GROUP_FAILURE = 'INITIATE_CHAT_GROUP_FAILURE'

export const GET_CHAT_MESSAGE_REQUEST = 'GET_CHAT_MESSAGE_REQUEST';
export const GET_CHAT_MESSAGE_SUCCESS = 'GET_CHAT_MESSAGE_SUCCESS';
export const GET_CHAT_MESSAGE_FAILURE = 'GET_CHAT_MESSAGE_FAILURE';

export const UPDATE_TU_ONLINE_STATUS_REQUEST = 'UPDATE_TU_ONLINE_STATUS_REQUEST'
export const UPDATE_TU_ONLINE_STATUS_SUCCESS = 'UPDATE_TU_ONLINE_STATUS_SUCCESS'
export const UPDATE_TU_ONLINE_STATUS_FAILURE = 'UPDATE_TU_ONLINE_STATUS_FAILURE'

export const ADD_RECENT_CHATS_REQUEST = 'ADD_RECENT_CHATS_REQUEST'
export const ADD_RECENT_CHATS_SUCCESS = 'ADD_RECENT_CHATS_SUCCESS'
export const ADD_RECENT_CHATS_FAILURE = 'ADD_RECENT_CHATS_FAILURE'

export const FETCH_USER_WALL_LIST_REQUEST = 'FETCH_USER_WALL_LIST_REQUEST'
export const FETCH_USER_WALL_LIST_SUCCESS = 'FETCH_USER_WALL_LIST_SUCCESS'
export const FETCH_USER_WALL_LIST_FAILURE = 'FETCH_USER_WALL_LIST_FAILURE'

// ---------------------------------Add Patients FormData Constants-----------------

export const FETCH_PATIENT_PROVINCE_LISTS_REQUEST = 'FETCH_PATIENT_PROVINCE_LISTS_REQUEST'
export const FETCH_PATIENT_PROVINCE_LISTS_SUCCESS = 'FETCH_PATIENT_PROVINCE_LISTS_SUCCESS'
export const FETCH_PATIENT_PROVINCE_LISTS_FAILURE = 'FETCH_PATIENT_PROVINCE_LISTS_FAILURE'

export const FETCH_PHARMACY_PATIENT_DATA_REQUEST = 'FETCH_PHARMACY_PATIENT_DATA_REQUEST'
export const FETCH_PHARMACY_PATIENT_DATA_SUCCESS = 'FETCH_PHARMACY_PATIENT_DATA_SUCCESS'
export const FETCH_PHARMACY_PATIENT_DATA_FAILURE = 'FETCH_PHARMACY_PATIENT_DATA_FAILURE'

export const ADD_PATIENT_DATA_REQUEST = 'ADD_PATIENT_DATA_REQUEST'
export const ADD_PATIENT_DATA_SUCCESS = 'ADD_PATIENT_DATA_SUCCESS'
export const ADD_PATIENT_DATA_FAILURE = 'ADD_PATIENT_DATA_FAILURE'

export const FETCH_PATIENTS_LIST_REQUEST = 'FETCH_PATIENTS_LIST_REQUEST'
export const FETCH_PATIENTS_LIST_SUCCESS = 'FETCH_PATIENTS_LIST_SUCCESS'
export const FETCH_PATIENTS_LIST_FAILURE = 'FETCH_PATIENTS_LIST_FAILURE'

export const PATIENT_UPDATE_STATUS_REQUEST = 'PATIENT_UPDATE_STATUS_REQUEST'
export const PATIENT_UPDATE_STATUS_SUCCESS = 'PATIENT_UPDATE_STATUS_SUCCESS'
export const PATIENT_UPDATE_STATUS_FAILURE = 'PATIENT_UPDATE_STATUS_FAILURE'

export const UPDATE_PATIENTS_PROFILE_REQUEST = 'UPDATE_PATIENTS_PROFILE_REQUEST'
export const UPDATE_PATIENTS_PROFILE_SUCCESS = 'UPDATE_PATIENTS_PROFILE_SUCCESS'
export const UPDATE_PATIENTS_PROFILE_FAILURE = 'UPDATE_PATIENTS_PROFILE_FAILURE'

export const FETCH_ALL_CLINICS_REQUEST = 'FETCH_ALL_CLINICS_REQUEST'
export const FETCH_ALL_CLINICS_SUCCESS = 'FETCH_ALL_CLINICS_SUCCESS'
export const FETCH_ALL_CLINICS_FAILURE = 'FETCH_ALL_CLINICS_FAILURE'

export const LINK_PATIENTS_TO_CLINIC_REQUEST = 'LINK_PATIENTS_TO_CLINIC_REQUEST'
export const LINK_PATIENTS_TO_CLINIC_SUCCESS = 'LINK_PATIENTS_TO_CLINIC_SUCCESS'
export const LINK_PATIENTS_TO_CLINIC_FAILURE = 'LINK_PATIENTS_TO_CLINIC_FAILURE'

export const PATIENT_NOTES_LIST_REQUEST = 'PATIENT_NOTES_LIST_REQUEST'
export const PATIENT_NOTES_LIST_SUCCESS = 'PATIENT_NOTES_LIST_SUCCESS'
export const PATIENT_NOTES_LIST_FAILURE = 'PATIENT_NOTES_LIST_FAILURE'

export const SAVE_PATIENT_NOTE_REQUEST = 'SAVE_PATIENT_NOTE_REQUEST'
export const SAVE_PATIENT_NOTE_SUCCESS = 'SAVE_PATIENT_NOTE_SUCCESS'
export const SAVE_PATIENT_NOTE_FAILURE = 'SAVE_PATIENT_NOTE_FAILURE'

export const SEND_CP_LINK_REQUEST = 'SEND_CP_LINK_REQUEST'
export const SEND_CP_LINK_SUCCESS = 'SEND_CP_LINK_SUCCESS'
export const SEND_CP_LINK_FAILURE = 'SEND_CP_LINK_FAILURE'

export const FETCH_DOCTORS_LIST_REQUEST = 'FETCH_DOCTORS_LIST_REQUEST'
export const FETCH_DOCTORS_LIST_SUCCESS = 'FETCH_DOCTORS_LIST_SUCCESS'
export const FETCH_DOCTORS_LIST_FAILURE = 'FETCH_DOCTORS_LIST_FAILURE'

export const UPDATE_OAT_FORM_PREF_REQUEST = 'UPDATE_OAT_FORM_PREF_REQUEST'
export const UPDATE_OAT_FORM_PREF_SUCCESS = 'UPDATE_OAT_FORM_PREF_SUCCESS'
export const UPDATE_OAT_FORM_PREF_FAILURE = 'UPDATE_OAT_FORM_PREF_FAILURE'

export const ADD_DOCTORS_REQUEST = 'ADD_DOCTORS_REQUEST'
export const ADD_DOCTORS_SUCCESS = 'ADD_DOCTORS_SUCCESS'
export const ADD_DOCTORS_FAILURE = 'ADD_DOCTORS_FAILURE'

export const FETCH_PATIENT_ACTIVE_LIST_REQUEST = 'FETCH_PATIENT_ACTIVE_LIST_REQUEST'
export const FETCH_PATIENT_ACTIVE_LIST_SUCCESS = 'FETCH_PATIENT_ACTIVE_LIST_SUCCESS'
export const FETCH_PATIENT_ACTIVE_LIST_FAILURE = 'FETCH_PATIENT_ACTIVE_LIST_FAILURE'

export const PHARMACY_UPDATE_CLINIC_FORM_REQUEST = 'PHARMACY_UPDATE_CLINIC_FORM_REQUEST'
export const PHARMACY_UPDATE_CLINIC_FORM_SUCCESS = 'PHARMACY_UPDATE_CLINIC_FORM_SUCCESS'
export const PHARMACY_UPDATE_CLINIC_FORM_FAILURE = 'PHARMACY_UPDATE_CLINIC_FORM_FAILURE'

export const PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_REQUEST = 'PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_REQUEST'
export const PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_SUCCESS = 'PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_SUCCESS'
export const PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_FAILURE = 'PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_FAILURE'


export const FETCH_PHARMACY_LIST_REQUEST = 'FETCH_PHARMACY_LIST_REQUEST'
export const FETCH_PHARMACY_LIST_SUCCESS = 'FETCH_PHARMACY_LIST_SUCCESS'
export const FETCH_PHARMACY_LIST_FAILURE = 'FETCH_PHARMACY_LIST_FAILURE'

export const ADD_RX_TRANSFER_REQUEST_REQUEST = 'ADD_RX_TRANSFER_REQUEST_REQUEST'
export const ADD_RX_TRANSFER_REQUEST_SUCCESS = 'ADD_RX_TRANSFER_REQUEST_SUCCESS'
export const ADD_RX_TRANSFER_REQUEST_FAILURE = 'ADD_RX_TRANSFER_REQUEST_FAILURE'

export const ADD_NEW_CLINIC_REQUEST = 'ADD_NEW_CLINIC_REQUEST'
export const ADD_NEW_CLINIC_SUCCESS = 'ADD_NEW_CLINIC_SUCCESS'
export const ADD_NEW_CLINIC_FAILURE = 'ADD_NEW_CLINIC_FAILURE'

export const SEND_FAX_REQUEST = 'SEND_FAX_REQUEST';
export const SEND_FAX_SUCCESS = 'SEND_FAX_SUCCESS';
export const SEND_FAX_FAILURE = 'SEND_FAX_FAILURE';

export const PREVIEW_PDF_REQUEST = 'PREVIEW_PDF_REQUEST';
export const PREVIEW_PDF_SUCCESS = 'PREVIEW_PDF_SUCCESS';
export const PREVIEW_PDF_FAILURE = 'PREVIEW_PDF_FAILURE';

export const PHARMACY_ADD_TEAM_NOTES_REQUEST = 'PHARMACY_ADD_TEAM_NOTES_REQUEST';
export const PHARMACY_ADD_TEAM_NOTES_SUCCESS = 'PHARMACY_ADD_TEAM_NOTES_SUCCESS';
export const PHARMACY_ADD_TEAM_NOTES_FAILURE = 'PHARMACY_ADD_TEAM_NOTES_FAILURE';

export const PHARMACY_COVERAGE_PATIENT_LIST_REQUEST = 'PHARMACY_COVERAGE_PATIENT_LIST_REQUEST';
export const PHARMACY_COVERAGE_PATIENT_LIST_SUCCESS = 'PHARMACY_COVERAGE_PATIENT_LIST_SUCCESS';
export const PHARMACY_COVERAGE_PATIENT_LIST_FAILURE = 'PHARMACY_COVERAGE_PATIENT_LIST_FAILURE';

export const PHARMACY_PATIENT_NOTIFICATIONS_REQUEST = 'PHARMACY_PATIENT_NOTIFICATIONS_REQUEST';
export const PHARMACY_PATIENT_NOTIFICATIONS_SUCCESS = 'PHARMACY_PATIENT_NOTIFICATIONS_SUCCESS';
export const PHARMACY_PATIENT_NOTIFICATIONS_FAILURE = 'PHARMACY_PATIENT_NOTIFICATIONS_FAILURE';

export const PHARMACY_TEAM_NOTES_MESSAGE_REQUEST = 'PHARMACY_TEAM_NOTES_MESSAGE_REQUEST';
export const PHARMACY_TEAM_NOTES_MESSAGE_SUCCESS = 'PHARMACY_TEAM_NOTES_MESSAGE_SUCCESS';
export const PHARMACY_TEAM_NOTES_MESSAGE_FAILURE = 'PHARMACY_TEAM_NOTES_MESSAGE_FAILURE';

export const PHARMACY_PATIENT_ADD_NOTE_REPLY_REQUEST = ' PHARMACY_PATIENT_ADD_NOTE_REPLY_REQUEST';
export const PHARMACY_PATIENT_ADD_NOTE_REPLY_SUCCESS = ' PHARMACY_PATIENT_ADD_NOTE_REPLY_SUCCESS';
export const PHARMACY_PATIENT_ADD_NOTE_REPLY_FAILURE = ' PHARMACY_PATIENT_ADD_NOTE_REPLY_FAILURE';

export const PHARMACY_PATIENT_NOTE_MARKSREAD_REQUEST = ' PHARMACY_PATIENT_NOTE_MARKSREAD_REQUEST';
export const PHARMACY_PATIENT_NOTE_MARKSREAD_SUCCESS = ' PHARMACY_PATIENT_NOTE_MARKSREAD_SUCCESS';
export const PHARMACY_PATIENT_NOTE_MARKSREAD_FAILURE = ' PHARMACY_PATIENT_NOTE_MARKSREAD_FAILURE';

export const PHARMACY_SEARCH_MODULE_REQUEST = ' PHARMACY_SEARCH_MODULE_REQUEST';
export const PHARMACY_SEARCH_MODULE_SUCCESS = ' PHARMACY_SEARCH_MODULE_SUCCESS';
export const PHARMACY_SEARCH_MODULE_FAILURE = ' PHARMACY_SEARCH_MODULE_FAILURE';

export const PHARMACY_MANAGER_CREATE_FOLDER_REQUEST = ' PHARMACY_MANAGER_CREATE_FOLDER_REQUEST';
export const PHARMACY_MANAGER_CREATE_FOLDER_SUCCESS = ' PHARMACY_MANAGER_CREATE_FOLDER_SUCCESS';
export const PHARMACY_MANAGER_CREATE_FOLDER_FAILURE = ' PHARMACY_MANAGER_CREATE_FOLDER_FAILURE';

export const PHARMACY_FILE_MANAGER_GET_FILES_REQUEST = ' PHARMACY_FILE_MANAGER_GET_FILES_REQUEST';
export const PHARMACY_FILE_MANAGER_GET_FILES_SUCCESS = ' PHARMACY_FILE_MANAGER_GET_FILES_SUCCESS';
export const PHARMACY_FILE_MANAGER_GET_FILES_FAILURE = ' PHARMACY_FILE_MANAGER_GET_FILES_FAILURE';

export const PHARMACY_ALL_TABLE_LIST_DATA_REQUEST = 'PHARMACY_ALL_TABLE_LIST_DATA_REQUEST';
export const PHARMACY_ALL_TABLE_LIST_DATA_SUCCESS = 'PHARMACY_ALL_TABLE_LIST_DATA_SUCCESS';
export const PHARMACY_ALL_TABLE_LIST_DATA_FAILURE = 'PHARMACY_ALL_TABLE_LIST_DATA_FAILURE';

export const PHARMACY_ADD_DATA_REQUEST = 'PHARMACY_ADD_DATA_REQUEST';
export const PHARMACY_ADD_DATA_SUCCESS = 'PHARMACY_ADD_DATA_SUCCESS';
export const PHARMACY_ADD_DATA_FAILURE = 'PHARMACY_ADD_DATA_FAILURE';

export const PHARMACY_ADD_DISPENSE_DATA_REQUEST = 'PHARMACY_ADD_DISPENSE_DATA_REQUEST';
export const PHARMACY_ADD_DISPENSE_DATA_SUCCESS = 'PHARMACY_ADD_DISPENSE_DATA_SUCCCESS';
export const PHARMACY_ADD_DISPENSE_DATA_FAILURE = 'PHARMACY_ADD_DISPENSE_DATA_FAILURE';

export const PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_REQUEST = 'PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_REQUEST';
export const PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_SUCCESS = 'PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_SUCCESS';
export const PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_FAILURE = 'PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_FAILURE';

export const PHARMACY_ALL_DISPENSE_LIST_DATA_REQUEST = 'PHARMACY_ALL_DISPENSE_LIST_DATA_REQUEST';
export const PHARMACY_ALL_DISPENSE_LIST_DATA_SUCCESS = 'PHARMACY_ALL_DISPENSE_LIST_DATA_SUCCESS';
export const PHARMACY_ALL_DISPENSE_LIST_DATA_FAILURE = 'PHARMACY_ALL_DISPENSE_LIST_DATA_FAILURE';

export const PHARMACY_DELETE_DISPENSE_DATA_REQUEST = 'PHARMACY_DELETE_DISPENSE_DATA_REQUEST';
export const PHARMACY_DELETE_DISPENSE_DATA_SUCCESS = 'PHARMACY_DELETE_DISPENSE_DATA_SUCCESS';
export const PHARMACY_DELETE_DISPENSE_DATA_FAILURE = 'PHARMACY_DELETE_DISPENSE_DATA_FAILURE';

export const PHARMACY_DELIVERY_DRIVERS_LIST_REQUEST = 'PHARMACY_DELIVERY_DRIVERS_LIST_REQUEST';
export const PHARMACY_DELIVERY_DRIVERS_LIST_SUCCESS = 'PHARMACY_DELIVERY_DRIVERS_LIST_SUCCESS';
export const PHARMACY_DELIVERY_DRIVERS_LIST_FAILURE = 'PHARMACY_DELIVERY_DRIVERS_LIST_FAILURE';

export const PHARMACY_ARCHIVED_DELVERIES_LIST_REQUEST = 'PHARMACY_ARCHIVED_DELVERIES_LIST_REQUEST';
export const PHARMACY_ARCHIVED_DELVERIES_LIST_SUCCESS = 'PHARMACY_ARCHIVED_DELVERIES_LIST_SUCCESS';
export const PHARMACY_ARCHIVED_DELVERIES_LIST_FAILURE = 'PHARMACY_ARCHIVED_DELVERIES_LIST_FAILURE';

export const PHARMACY_PATIENTS_ROUTE_LIST_REQUEST = 'PHARMACY_PATIENTS_ROUTE_LIST_REQUEST';
export const PHARMACY_PATIENTS_ROUTE_LIST_SUCCESS = 'PHARMACY_PATIENTS_ROUTE_LIST_SUCCESS';
export const PHARMACY_PATIENTS_ROUTE_LIST_FAILURE = 'PHARMACY_PATIENTS_ROUTE_LIST_FAILURE';

export const PHARMACY_PATIENT_PRESCRIPTION_DETAILS_REQUEST = 'PHARMACY_PATIENT_PRESCRIPTION_DETAILS_REQUEST';
export const PHARMACY_PATIENT_PRESCRIPTION_DETAILS_SUCCESS = 'PHARMACY_PATIENT_PRESCRIPTION_DETAILS_SUCCESS';
export const PHARMACY_PATIENT_PRESCRIPTION_DETAILS_FAILURE = 'PHARMACY_PATIENT_PRESCRIPTION_DETAILS_FAILURE';

export const PHARMACY_RX_NUMBER_ARCHIVED_LIST_REQUEST = 'PHARMACY_PATIENT_PRESCRIPTION_DETAILS_REQUEST';
export const PHARMACY_RX_NUMBER_ARCHIVED_LIST_SUCCESS = 'PHARMACY_RX_NUMBER_ARCHIVED_LIST_SUCCESS';
export const PHARMACY_RX_NUMBER_ARCHIVED_LIST_FAILURE = 'PHARMACY_RX_NUMBER_ARCHIVED_LIST_FAILURE';

export const PHARMACY_DISPENSE_LABELS_LIST_REQUEST = 'PHARMACY_DISPENSE_LABELS_LIST_REQUEST';
export const PHARMACY_DISPENSE_LABELS_LIST_SUCCESS = 'PHARMACY_DISPENSE_LABELS_LIST_SUCCESS';
export const PHARMACY_DISPENSE_LABELS_LIST_FAILURE = 'PHARMACY_DISPENSE_LABELS_LIST_FAILURE';

export const PHARMACY_RX_LABELS_LIST_REQUEST = 'PHARMACY_RX_LABELS_LIST_REQUEST';
export const PHARMACY_RX_LABELS_LIST_SUCCESS = 'PHARMACY_RX_LABELS_LIST_SUCCESS';
export const PHARMACY_RX_LABELS_LIST_FAILURE = 'PHARMACY_RX_LABELS_LIST_FAILURE';

export const SYNC_TODAYS_DELIVERIES_REQUEST = 'SYNC_TODAYS_DELIVERIES_REQUEST';
export const SYNC_TODAYS_DELIVERIES_SUCCESS = 'SYNC_TODAYS_DELIVERIES_SUCCESS';
export const SYNC_TODAYS_DELIVERIES_FAILURE = 'SYNC_TODAYS_DELIVERIES_FAILURE';

export const UPDATE_RX_WITNESS_DOSE_REQUEST = 'UPDATE_RX_WITNESS_DOSE_REQUEST';
export const UPDATE_RX_WITNESS_DOSE_SUCCESS = 'UPDATE_RX_WITNESS_DOSE_SUCCESS';
export const UPDATE_RX_WITNESS_DOSE_FAILURE = 'UPDATE_RX_WITNESS_DOSE_FAILURE';


// ----------Blister--------

export const PATIENT_BLISTER_PACK_LIST_REQUEST = 'PATIENT_BLISTER_PACK_LIST_REQUEST';
export const PATIENT_BLISTER_PACK_LIST_SUCCESS = 'PATIENT_BLISTER_PACK_LIST_SUCCESS';
export const PATIENT_BLISTER_PACK_LIST_FAILURE = 'PATIENT_BLISTER_PACK_LIST_FAILURE';

export const ADD_BLISTER_PACK_REQUEST = 'ADD_BLISTER_PACK_REQUEST';
export const ADD_BLISTER_PACK_SUCCESS = 'ADD_BLISTER_PACK_SUCCESS';
export const ADD_BLISTER_PACK_FAILURE = 'ADD_BLISTER_PACK_FAILURE';


export const LABEL_VALIDATE_INPUTS_REQUEST = 'LABEL_VALIDATE_INPUTS_REQUEST';
export const LABEL_VALIDATE_INPUTS_SUCCESS = 'LABEL_VALIDATE_INPUTS_SUCCESS';
export const LABEL_VALIDATE_INPUTS_FAILURE = 'LABEL_VALIDATE_INPUTS_FAILURE';


// -----------    ARV MANAGEMENT -------------------------------

export const ADD_ARV_DATA_REQUEST = 'ADD_ARV_DATA_REQUEST'
export const ADD_ARV_DATA_SUCCESS = 'ADD_ARV_DATA_SUCCESS'
export const ADD_ARV_DATA_FAILURE = 'ADD_ARV_DATA_FAILURE'

export const LIST_ARV_DATA_REQUEST = 'LIST_ARV_DATA_REQUEST'
export const LIST_ARV_DATA_SUCCESS = 'LIST_ARV_DATA_SUCCESS'
export const LIST_ARV_DATA_FAILURE = 'LIST_ARV_DATA_FAILURE'

export const LIST_ARV_DELETE_REQUEST = 'LIST_ARV_DELETE_REQUEST'
export const LIST_ARV_DELETE_SUCCESS = 'LIST_ARV_DELETE_SUCCESS'
export const LIST_ARV_DELETE_FAILURE = 'LIST_ARV_DELETE_FAILURE'

export const MEMO_REMINDER_UPDATE_REQUEST = 'MEMO_REMINDER_UPDATE_REQUEST'
export const MEMO_REMINDER_UPDATE_SUCCESS = 'MEMO_REMINDER_UPDATE_SUCCESS'
export const MEMO_REMINDER_UPDATE_FAILURE = 'LIST_ARV_DATA_FAILURE'

export const LIST_FDA_DATA_REQUEST = 'LIST_FDA_DATA_REQUEST'
export const LIST_FDA_DATA_SUCCESS = 'LIST_FDA_DATA_SUCCESS'
export const LIST_FDA_DATA_FAILURE = 'LIST_FDA_DATA_FAILURE'

export const DAILY_REVENUE_LIST_REQUEST = 'DAILY_REVENUE_LIST_REQUEST'
export const DAILY_REVENUE_LIST_SUCCESS = 'DAILY_REVENUE_LIST_SUCCESS'
export const DAILY_REVENUE_LIST_FAILURE = 'DAILY_REVENUE_LIST_FAILURE'

export const ADD_REVENUE_DATA_REQUEST = 'ADD_REVENUE_DATA_REQUEST'
export const ADD_REVENUE_DATA_SUCCESS = 'ADD_REVENUE_DATA_SUCCESS'
export const ADD_REVENUE_DATA_FAILURE = 'ADD_REVENUE_DATA_FAILURE'

export const LIST_OAT_DATA_REQUEST = 'LIST_OAT_DATA_REQUEST'
export const LIST_OAT_DATA_SUCCESS = 'LIST_OAT_DATA_SUCCESS'
export const LIST_OAT_DATA_FAILURE = 'LIST_OAT_DATA_FAILURE'


export const ADD_OAT_DATA_REQUEST = 'ADD_OAT_DATA_REQUEST'
export const ADD_OAT_DATA_SUCCESS = 'ADD_OAT_DATA_SUCCESS'
export const ADD_OAT_DATA_FAILURE = 'ADD_OAT_DATA_FAILURE'

export const ADD_INVENTORY_DATA_REQUEST = 'ADD_INVENTORY_DATA_REQUEST'
export const ADD_INVENTORY_DATA_SUCCESS = 'ADD_INVENTORY_DATA_SUCCESS'
export const ADD_INVENTORY_DATA_FAILURE = 'ADD_INVENTORY_DATA_FAILURE'

export const INVENTORY_DATA_LIST_REQUEST = 'INVENTORY_DATA_LIST_REQUEST'
export const INVENTORY_DATA_LIST_SUCCESS = 'INVENTORY_DATA_LIST_SUCCESS'
export const INVENTORY_DATA_LIST_FAILURE = 'INVENTORY_DATA_LIST_FAILURE'

export const DISPENSE_CUSTOM_DELETE_REQUEST = 'LIST_ARV_DELETE_REQUEST'
export const DISPENSE_CUSTOM_DELETE_SUCCESS = 'DISPENSE_CUSTOM_DELETE_SUCCESS'
export const DISPENSE_CUSTOM_DELETE_FAILURE = 'DISPENSE_CUSTOM_DELETE_FAILURE'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const LIST_APP_DATA_REQUEST = 'LIST_APP_DATA_REQUEST'
export const LIST_APP_DATA_SUCCESS = 'LIST_APP_DATA_SUCCESS'
export const LIST_APP_DATA_FAILURE = 'LIST_APP_DATA_FAILURE'

export const ANNOUNCE_DELETE_REQUEST = 'ANNOUNCE_DELETE_REQUEST'
export const ANNOUNCE_DELETE_SUCCESS = 'ANNOUNCE_DELETE_SUCCESS'
export const ANNOUNCE_DELETE_FAILURE = 'ANNOUNCE_DELETE_FAILURE'