import {
    TEAM_REQUEST_LIST_REQUEST,
    TEAM_REQUEST_LIST_SUCCESS,
    TEAM_REQUEST_LIST_FAILURE
} from "../constants"

import {
    p_cinout_logs,
    get_certificate_template,
    p_trequest_users,
    pharmacy_assign_certificate,
    pharmacy_certificate_template,
    pharmacy_task,
    puser_attendance_sheet,
    pharmacy_team_users,
    pharmacy_user_departments,
    pharmacy_group_list,
    on_boarding_user_profile
} from "../../../../networking/urlEndPoints"

import Axios from "../../../../networking/intraceptor"

export const fetchTeamsListRequest = (fetchType) => ({
    type: TEAM_REQUEST_LIST_REQUEST,
    fetchType: fetchType
})

export const fetchTeamsListSuccess = (data, fetchType) => ({
    type: TEAM_REQUEST_LIST_SUCCESS,
    payload: data,
    fetchType: fetchType
})

export const fetchTeamsListFailure = (error, fetchType) => ({
    type: TEAM_REQUEST_LIST_FAILURE,
    payload: error,
    fetchType: fetchType
})

export const fetchTimeOffRequestList = (type, params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`${p_trequest_users}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchAllTaskLists = (type, params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_task}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchPharmacyEmployeesTaskList = (type, params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_task}/singleuser`, {
                params
            })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchPharmacyTeamTimeSheet = (type, params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`${puser_attendance_sheet}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchCertificateTemplates = (type, params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_certificate_template}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fethEmployeeAssignedCertificateList = (type, params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_assign_certificate}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}


export const fetchSingleTemplate = (type, id) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`${get_certificate_template}?template_id=${id}`)

            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchPharmacyTeamsList = (type, params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_team_users}`, { params })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchCheckOutInLogsList = (type, params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`${p_cinout_logs}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

// ------------------------------------------Pharmacy Settings List-------------------------------------------


export const fetchPharmacySettingsList = (type) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`pharmacy-all-setting`)
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchPharmacyDepartmentAssigns = (type, params,setisLoaded = '') => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
         
            if (typeof setisLoaded === 'function') 
            {
                setisLoaded(false)
            }
          
            const response = await Axios.get(`${pharmacy_user_departments}`, {
                params
            })
            if (response?.status === 200) {
                
                dispatch(fetchTeamsListSuccess(response?.data, type))
                if (typeof setisLoaded === 'function') 
                    {
                        setisLoaded(true)
                    }
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchPharmacyDeliverySettingsList = (type) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`update-delivery-time-setting`)
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchPharmacySettings = (type) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
         
           
            const response = await Axios.get(`getPharmacyPermissions`)
            if (response?.status === 200) {
                
                dispatch(fetchTeamsListSuccess(response?.data, type))
               
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}


export const fetchPharmacyUser = (type) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
         
           
            const response = await Axios.get(`${pharmacy_group_list}`)
            if (response?.status === 200) {
                
                dispatch(fetchTeamsListSuccess(response?.data, type))
               
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}


export const fetchBreakPayrollValues = (type,params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`/pharmacy-settings`, { params })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}


export const fetchPharmacyTeamScheduleList = (type,params) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`/pharmacy-team-scedule`, { params })
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}

export const fetchDefaultpharmacistSettingsList = (type) => {
    return async (dispatch) => {
        dispatch(fetchTeamsListRequest(type))
        try {
            const response = await Axios.get(`default-pharmacist-setting`)
            if (response?.status === 200) {
                dispatch(fetchTeamsListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchTeamsListFailure(error?.response, type))
            }
        }
    }
}



