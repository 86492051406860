import React, { useEffect, useState } from "react";

import { useDispenseSelectors } from "../../../services/selectors/dispenseSelectors";
import { FetchPharmacyAccountabilityData } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import { FetchRxByRXNumbers } from "../../../services/actions/pharmacy/dispense/searchByRxNumberArchivedList";

import * as Common from "../../../components/common";
import * as Hooks from "../../../hooks";

import * as routeNames from "../../../routes/routeName";
import * as Dispense from '../../../components/pharmacy/dispense/dispense'

import ChatBox from "../../../components/chat/chatbox";

const patientListHeading = [
    { text: "RX NUMBER", classNames: ['p-3'] },
    { text: "NAME", classNames: ['p-3'] },
]

const RxListHeading = [
    { text: "DATE", classNames: ['p-3'] },
    { text: "RX", classNames: ['p-3'] },
    { text: "DRUG", classNames: ['p-3'] },
    { text: "SIG", classNames: ['p-3'] },
]

const TableRowData = ({ value, className1 = "fs-14 p-3" }) => {
    return (
        <td className={className1}>
            {value}
        </td>
    );
};

const no_of_records = 10;

const Accountability = () => {

    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();
    const dispatch = Hooks.useCustomDispatch();

    const searchParams = new URLSearchParams();
    const initialPage = parseInt(new URLSearchParams(location.search).get("page")) || 1;
    const [formData, setFormData] = React.useState({ search: '' });

    const [pageNumber, setPageNumber] = useState(initialPage);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null)

    const { dispenseMemo, searchByRxNumberArchivedListResponse } = useDispenseSelectors();
    const patientList = dispenseMemo?.data?.data;

    const rxNumberList = searchByRxNumberArchivedListResponse?.data?.data

    useEffect(() => {
        setPageNumber(initialPage);
    }, [initialPage]);

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: no_of_records,
        }
        if (value !== undefined) {
            setPageNumber(value)
        }
        const newUrl = `${routeNames.pharmacy_accountability}?${searchParams.toString()}`
        navigate(newUrl);
        setSelectedRow(false)
        dispatch(FetchPharmacyAccountabilityData("accountability", params));
    };

    const handleSearch = () => {
        const params = {
            search: formData.search
        }
        setSelectedRow(false)
        if (formData.search) dispatch(FetchPharmacyAccountabilityData("accountability", params));
    }

    const handleGetRxByRxNumbersdata = (rxnumber, idx) => {
        const params = {
            rxnumber: rxnumber
        }
        setSelectedRow(true);
        setSelectedIndex(idx)
        if (rxnumber) dispatch(FetchRxByRXNumbers('rxnumbers', params))
    }

    return (
        <>
            <div className="BodyBgColor ">
                <div className="container mx-auto px-4 pt-5 pb-5 ">
                    <div className="grid grid-cols-12 gap-4 w-full">
                        <div
                            className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left"
                        >
                            <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                <Common.BreadCrumb />
                            </h2>
                        </div>
                    </div>
                    <div className="flex bg-white mt-2">
                        <div className="grid grid-cols-12 gap-2 w-full" >
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                                <div className="content">

                                    <div className="container mt-2">
                                        <div className=" rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white pt-0 mb-64">

                                            <div className="flex flex-wrap lg:flex-nowrap gap-3">
                                                <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 w-full'>
                                                    <Dispense.TextInput label='Search' name='search' formData={formData}
                                                        setFormData={setFormData} />
                                                </div>

                                                <div className='flex-0  w-18'>
                                                    <button onClick={handleSearch} type="button" style={{ backgroundColor: '#3B82F6' }} className="flex text-white ml-auto w-full h-full pl-6 py-4 items-center justify-center border border-gray-300 	
														focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                                                    </button>
                                                </div>
                                            </div>


                                            {dispenseMemo?.loading && dispenseMemo?.type === "accountability" ?
                                                <div className="flex justify-center items-center mt-60 mb-80">
                                                    <Common.LoadingView />
                                                </div>

                                                : dispenseMemo?.error !== null && dispenseMemo?.type === "accountability" ?
                                                    <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                        <h1 className="text-4xl font-bold text-red-600 mb-2">
                                                            Oops!
                                                        </h1>
                                                        <p className="text-lg text-gray-800 mb-2">
                                                            Something went wrong while fetching the data.
                                                        </p>
                                                        <button
                                                            onClick={() => window.location.reload()}
                                                            className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500"
                                                        >
                                                            Please Try Again.
                                                        </button>
                                                    </div>
                                                    : patientList?.data?.length === 0 &&
                                                        dispenseMemo?.type === "accountability" ? (
                                                        <div className="bg-white rounded-md mt-2">
                                                            <p className="text-gray-500">
                                                                No Record available
                                                            </p>
                                                        </div>
                                                    ) : dispenseMemo?.type === "accountability" ? (

                                                        <div className='flex mt-4' style={{ width: '100%' }}>
                                                            <div style={{ width: '25%' }}>
                                                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-0">
                                                                    <div className="shadow-md border rounded-lg  relative overflow-x-auto">
                                                                        <table className="bl_oatLogHistory w-full text-sm text-left overflow-auto rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">

                                                                            <thead className="text-xs text-gray-900 uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400"
                                                                                style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                                <tr>
                                                                                    {patientListHeading?.map((header, index) => (
                                                                                        <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                            <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                        </th>
                                                                                    ))}
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                                {patientList?.data?.map((value, idx) => (
                                                                                    <React.Fragment key={idx} >
                                                                                        <tr onClick={() => {
                                                                                            handleGetRxByRxNumbersdata(value.rxnumber, idx)
                                                                                        }} className={`cursor-pointer hover:bg-gray-100 border-b ${selectedIndex === idx ? 'bg-blue-200' : ''}`}>
                                                                                            <TableRowData value={value?.rxnumber} />
                                                                                            <TableRowData value={value?.patient_name} />
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>

                                                                        {dispenseMemo?.error !== null ? null : (
                                                                            <>
                                                                                {patientList.data?.length !== 0 && (
                                                                                    <>
                                                                                        {patientList.pagination?.last_page !== 1 && (
                                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                                <nav aria-label="Page navigation example">
                                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                                        <CustomPagination
                                                                                                            count={
                                                                                                                patientList.pagination?.last_page
                                                                                                            }
                                                                                                            page={pageNumber}
                                                                                                            onChange={(event, value) => {
                                                                                                                handlePagination(event, value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </ul>
                                                                                                </nav>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                    </div>
                                                                </div>

                                                            </div>

                                                            {selectedRow &&
                                                                <div style={{ width: '75%' }}>

                                                                    {searchByRxNumberArchivedListResponse?.loading && searchByRxNumberArchivedListResponse?.type === "rxnumbers" ?

                                                                        <div className="flex justify-center items-center mt-60 mb-80">
                                                                            <Common.LoadingView />
                                                                        </div>

                                                                        : searchByRxNumberArchivedListResponse?.error !== null && searchByRxNumberArchivedListResponse?.type === "rxnumbers" ?

                                                                            <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                                                Oops! Got an issue when fetching data please try again
                                                                            </div>

                                                                            : rxNumberList.length === 0 && searchByRxNumberArchivedListResponse?.type === "rxnumbers" ?

                                                                                <div className="bg-white rounded-md">
                                                                                    <p className="text-gray-500">
                                                                                        No Record available
                                                                                    </p>
                                                                                </div>

                                                                                : searchByRxNumberArchivedListResponse?.type === "rxnumbers" ? (

                                                                                    <div className="p-4 pt-0 ">
                                                                                        <div className='sticky' style={{ top: '220px' }}>
                                                                                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

                                                                                                <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400 bg-white">

                                                                                                    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                                                        <tr>
                                                                                                            {RxListHeading?.map((header, index) => (
                                                                                                                <th key={index}
                                                                                                                    scope="col" className={header?.classNames?.join(" ")}>
                                                                                                                    <p className="rubik-500 fs-14">
                                                                                                                        {header?.text}
                                                                                                                    </p>
                                                                                                                </th>
                                                                                                            ))}
                                                                                                        </tr>
                                                                                                    </thead>

                                                                                                    <tbody>
                                                                                                        {rxNumberList?.map((value, idx) => {
                                                                                                            return (
                                                                                                                <tr key={idx} className="border-b border-gray-200 dark:border-gray-700">
                                                                                                                    <TableRowData value={value?.date} />
                                                                                                                    {/* <TableRowData value={value?.patient_name} /> */}
                                                                                                                    <TableRowData value={value?.rxnumber} />
                                                                                                                    <TableRowData value={value?.drug} />
                                                                                                                    <TableRowData value={value?.rxsig} />
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })}
                                                                                                    </tbody>
                                                                                                </table>

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>) : null}
                                                                </div>}
                                                        </div>


                                                    ) : null}
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div >
            </div >

            <ChatBox />

        </>
    );
}

export default Accountability;