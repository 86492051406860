import {
    PHARMACY_PERMISSIONS_REQUEST,
    PHARMACY_PERMISSIONS_SUCCESS,
    PHARMACY_PERMISSIONS_FAILURE,
} from '../../constants';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const authPermissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case PHARMACY_PERMISSIONS_REQUEST:
            return {
                ...state,
                loading: true,
                // data: null,
                error: null,
            };
        case PHARMACY_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case PHARMACY_PERMISSIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null,
            };
        default:
            return state;
    }
};

export default authPermissionReducer;
