import React from 'react'

const Button = ({ bgColor = '#3B82F6', width = '70px', handleFilter, color = 'gray', text = 'Filter', height = 'auto' }) => {

    return (
        <button type="button" style={{ backgroundColor: bgColor, width: width, color: color, height: height }} onClick={handleFilter}
            className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">{text}
        </button>
    )
}

export default Button