import {
	PHARMACY_SEARCH_MODULE_REQUEST,
	PHARMACY_SEARCH_MODULE_SUCCESS,
	PHARMACY_SEARCH_MODULE_FAILURE
} from '../../../constants';

import { pharmacy_module_search } from '../../../../networking/urlEndPoints'
import Axios from '../../../../networking/intraceptor'

export const searchModuleRequest = () => ({
	type: PHARMACY_SEARCH_MODULE_REQUEST,
});

export const searchModuleSuccess = (data) => ({
	type: PHARMACY_SEARCH_MODULE_SUCCESS,
	payload: data,
});

export const searchModuleFailure = (error) => ({
	type: PHARMACY_SEARCH_MODULE_FAILURE,
	payload: error,
});

export const pharmacySearchModule = (modules, id) => {
	return async (dispatch) => {
		dispatch(searchModuleRequest());
		try {
			const params = {
				module: modules,
				id: id
			}

			const response = await Axios.get(`${pharmacy_module_search}`, { params });

			if (response?.status === 200) {
				dispatch(searchModuleSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(searchModuleFailure(error?.response));
			}
		}
	}
}

