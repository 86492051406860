import {
	ADD_MESSAGES_REQUEST,
	ADD_MESSAGES_SUCCESS,
	ADD_MESSAGES_FAILURE,
} from '../../constants/index';

import axios from 'axios';
import Cookies from 'js-cookie';

import { pharmacy_add_messages_to_group } from '../../../networking/urlEndPoints';
import { authToken } from '../../../storage/authToken';
import { persistor } from '../../store/store'
import { fetchAllRecentChats } from './recentChatsListsActions';

export const addChatGroupMessagesRequest = () => ({
	type: ADD_MESSAGES_REQUEST,
});

export const addChatGroupMessagesSuccess = (messages) => ({
	type: ADD_MESSAGES_SUCCESS,
	payload: messages,
})

export const addChatGroupMessagesFailure = (error) => ({
	type: ADD_MESSAGES_FAILURE,
	payload: error,
})

export const addMessagesToGroup = (message_text, message_type, group_id, message_media, media_pdf) => {
	return async (dispatch) => {
		dispatch(addChatGroupMessagesRequest())
		try {
			const formData = new FormData();
			if (message_text) {
				formData.append('message_text', message_text);
			}
			if (message_type) {
				formData.append('message_type', message_type)
			}
			formData.append('group_id', group_id);
			formData.append('user_type', 'PharmacyLoginUser');

			if (message_media?.length !== 0) {
				message_media?.forEach((file, index) => {
					formData?.append(`message_media[${index}]`, file);
				});
			}

			if (media_pdf?.length !== 0) {
				media_pdf?.forEach((pdf, index) => {
					formData?.append(`media_pdf[${index}]`, pdf);
				});
			}

			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_add_messages_to_group}`, formData, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${authToken()}`,
					'Content-Type': 'multipart/form-data',
				},
			})
			if (response?.status === 200) {
				dispatch(addChatGroupMessagesSuccess(response?.data))
				dispatch(fetchAllRecentChats())
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response?.status === 401) {
				Cookies.remove('authToken')
				persistor.purge()
				window.location.href = '/'
			}
			else {
				dispatch(addChatGroupMessagesFailure(error?.response))
			}
		}
	}
}

