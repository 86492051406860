import {
	FETCH_TIMESHEET_DATES_REQUEST,
	FETCH_TIMESHEET_DATES_SUCCESS,
	FETCH_TIMESHEET_DATES_FAILURE,
} from '../../constants';

import { pharmacy_timesheet_dates } from '../../../networking/urlEndPoints'
import Axios from '../../../networking/intraceptor';

export const fetchTimesheetDatesRequest = () => ({
	type: FETCH_TIMESHEET_DATES_REQUEST,
});

export const fetchTimesheetDatesSuccess = (data) => ({
	type: FETCH_TIMESHEET_DATES_SUCCESS,
	payload: data,
});

export const fetchTimesheetDatesFailure = (error) => ({
	type: FETCH_TIMESHEET_DATES_FAILURE,
	error: true,
	payload: error,
});

export const fetchTimesheetDates = () => {
	return async (dispatch) => {
		dispatch(fetchTimesheetDatesRequest());
		try {
			const response = await Axios.get(`${pharmacy_timesheet_dates}`);
			if (response?.status === 200) {
				dispatch(fetchTimesheetDatesSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchTimesheetDatesFailure(error?.response))
			}
		}
	};
};
