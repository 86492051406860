import {
	PHARMACY_RX_LABELS_LIST_REQUEST,
	PHARMACY_RX_LABELS_LIST_SUCCESS,
	PHARMACY_RX_LABELS_LIST_FAILURE
} from '../../../constants';

import {
	pharmacy_rx_labels_list,
	pharmacy_update_rxlabelmute,
	label_update_schdule_inputs,
	pharmacy_sync_label_delivery,
	pharmacy_print_lebel_setting
} from '../../../../networking/urlEndPoints'
import { fetchDispenseLabelsList } from './labelList';

import Axios from '../../../../networking/intraceptor'

export const pharmacyAddlabelsDataRequest = (types) => ({
	type: PHARMACY_RX_LABELS_LIST_REQUEST,
	types: types
});

export const pharmacyAddlabelsDataSuccess = (data, types) => ({
	type: PHARMACY_RX_LABELS_LIST_SUCCESS,
	payload: data,
	types: types
});

export const pharmacyAddlabelsDataFailure = (error, types) => ({
	type: PHARMACY_RX_LABELS_LIST_FAILURE,
	payload: error,
	types: types
});

export const fetchRxLabelsList = (id, formData, type) => {
	return async (dispatch) => {
		dispatch(pharmacyAddlabelsDataRequest(type))
		try {
			const params = {
				from_date: formData.startDate,
				to_date: formData.endDate,
				rx_id: id
			}
			const response = await Axios.get(`${pharmacy_rx_labels_list}`, { params })

			if (response?.status === 200) {
				dispatch(pharmacyAddlabelsDataSuccess(response?.data, type));

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddlabelsDataFailure(error?.response, type));
			}
		}
	}
}


export const muteRxList = (id, value, type, formData, pageNumber, setShowModal, showModal) => {
	return async (dispatch) => {
		dispatch(pharmacyAddlabelsDataRequest(type))
		try {
			const requestedData = {
				rx_id: id,
				muteval: value
			}

			const params = {
				from_date: formData?.startDate,
				to_date: formData?.endDate,
				route: formData?.patientRoutes ? formData?.patientRoutes?.label : 'default',
				page: pageNumber,
				// muteunmute: value
			}

			const response = await Axios.post(`${pharmacy_update_rxlabelmute}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddlabelsDataSuccess(response?.data, type));
				if (type === 'muterxvalue') {
					if (typeof setShowModal === 'function') {
						setShowModal(prevState => ({
							...prevState,
							muteRxModal: false,
							showToast: true
						}));
					}
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false
						}));
					}, 2000);
					dispatch(fetchDispenseLabelsList(params))
				}
				document.body.style.overflow = 'auto'

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddlabelsDataFailure(error?.response, type));
			}
		}
	}
}


export const updateLabelInput = (type, requestedData, setShowModal, setRxNumber, data) => {

	return async (dispatch) => {
		dispatch(pharmacyAddlabelsDataRequest(type))

		try {
			const response = await Axios.post(`${label_update_schdule_inputs}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddlabelsDataSuccess(response?.data, type));

				if (typeof setShowModal === 'function') {
					setShowModal(prevState => ({
						...prevState,
						updateDispenseModal: false
					}))
					setRxNumber(response?.data?.data?.rxnumber)
					dispatch(fetchRxLabelsList(response?.data?.data?.labelid, data, 'rxlabel-list'))
				}
				document.body.style.overflow = 'auto'

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddlabelsDataFailure(error?.response, type));
			}
		}
	}
}


export const LabelSyncAncFilter = (type, requestedData, setShowModal) => {
	return async (dispatch) => {
		dispatch(pharmacyAddlabelsDataRequest(type))

		try {
			const response = await Axios.post(`${pharmacy_sync_label_delivery}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddlabelsDataSuccess(response?.data, type))

				if (typeof setShowModal === 'function') {
					setShowModal(prevState => ({
						...prevState,
						showToast: true
					}))

					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false
						}))
					}, 2000);
				}

				const params = {
					from_date: requestedData?.from_date,
					to_date: requestedData?.to_date,
					route: 'default',
					rxnumber: requestedData?.rxnumber,
					muteunmute: requestedData?.muteunmute
				}

				dispatch(fetchDispenseLabelsList(params))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddlabelsDataFailure(error?.response, type));

				if (typeof setShowModal === 'function') {
					setShowModal(prevState => ({
						...prevState,
						errorToast: true
					}))

					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							errorToast: false
						}))
					}, 2000);
				}
			}
		}
	}
}



export const PrintLabelSetting = (type, requestedData, setShowModal) => {

	return async (dispatch) => {
		dispatch(pharmacyAddlabelsDataRequest(type))

		try {
			const response = await Axios.post(`${pharmacy_print_lebel_setting}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddlabelsDataSuccess(response?.data, type));

				if (typeof setShowModal === 'function') {
					setShowModal(prevState => ({
						...prevState,
						updateLabelPrint: false,
						showToast: true
					}))

					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false
						}))
					}, 2000);
				}
				document.body.style.overflow = 'auto'

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddlabelsDataFailure(error?.response, type));
			}
		}
	}
}