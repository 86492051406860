export const auth = '..cb//ath..\\'

export const authPharmacyPermissions = '.v5!.*(e)'

export const chatUserLists = '..ch@&t//l*st..\\'

export const userProfile = '..c_U@er^p*f_l..'

export const pharmacyTimeOffLists = '@..$=b_p,g~}/.'

export const pharmacyWallPostsList = '_!--ph..w@4ls;t'

export const timesheetDates = '#d_cb/\ts'

export const userCommentReplyList = '..c)!^&R@lyLst'

export const userWallList = '*u!`^&'

export const patientProvinceList = 'PVLST'

export const pharmacyTeamNotesMessage = '_ntm_g@op'

export const pharmacyFileManagerGetAllFiles = '_ph./\\lt-mger'

export const recentChatLists = '_r./@c__ent'


