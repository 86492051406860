import React, { useState } from 'react'

import { pharmacyPatientPrescriptionViewPdf } from '../../../services/actions/pharmacy/dispense/addDispenseData'
import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'
import { initalArchivedDelveriesData } from '../../../utils/constants/formInitialStates'
import { searchByRxNumberArchivedlistData } from '../../../services/actions/pharmacy/dispense/searchByRxNumberArchivedList'
import { pharmacyPatientUpdateWitnessDose } from '../../../services/actions/pharmacy/dispense/updateRxWitnessDose'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'
import * as Icons from '../../../assets/svg/icons'
import * as constant from '../../../constants/dispense'
import * as Toast from '../../../components/loaders/toastMessage'

const PrescriptionListValues = ({ value, style = {} }) => {
    return (<th className="p-3" style={style}>
        <p className="rubik-400 fs-14 text-gray-700 font-medium">{value}</p>
    </th>)
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
};

const RxSheet = ({ activeTab }) => {

    const location = Hooks.useCustomLocation()
    const dispatch = Hooks.useCustomDispatch()

    const [formData, setFormData] = useState(initalArchivedDelveriesData)
    const [rxLoading, setRxLoading] = useState(false)

    const rxnumber = new URLSearchParams(location.search).get('rxnumber')

    const { addDispenseDataResponse,
        searchByRxNumberArchivedListResponse,
        pharmacyPatientUpdateRxWitnessDoseResponse
    } = useDispenseSelectors()

    const searchByRxNumberArchivedList = searchByRxNumberArchivedListResponse?.data?.data

    const [showModal, setShowModal] = useState({
        witError: false,
        witSuccess: false
    })

    React.useEffect(() => {
        setFormData({
            ...formData,
            rxnumber: rxnumber
        })
        return () => { }
    }, [rxnumber])

    React.useEffect(() => {
        if (rxnumber) {
            const data = {
                rxnumber: rxnumber
            }
            setRxLoading(true)
            dispatch(searchByRxNumberArchivedlistData(data, 'rx_list'))
        }

    }, [rxnumber])

    const handleFilterRxNumber = () => {
        if (formData.rxnumber) {
            setRxLoading(true)
            dispatch(searchByRxNumberArchivedlistData(formData, 'rx_list'))
        }
    }

    return (
        <React.Fragment>

            {/* ---------------------------------------------Search By RX Number Start----------------------------------------------------------  */}
            <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'searchrxsheet' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">
                {activeTab === 'searchrxsheet' && <>
                    <div className="flex flex-wrap items-center justify-between md:w-full lg:w-4/12">
                        <div className="flex-grow  md:w-auto">

                            <div className="relative w-full">
                                <input type="text" id={'rxnumber'} autoComplete="off"
                                    onChange={(e) => setFormData({ ...formData, rxnumber: e.target.value })}
                                    name={'rxnumber'}
                                    style={{ height: '42px' }}
                                    value={formData.rxnumber}
                                    className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
																	dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
																	bg-white `}
                                    placeholder=""
                                    onKeyPress={(e) => {
                                        if (
                                            isNaN(String.fromCharCode(e.charCode))) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <label htmlFor={'rxnumber'}
                                    className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
																		origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
																		peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
																		peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                    RX Number
                                </label>
                            </div>
                        </div>

                        <button onClick={handleFilterRxNumber}
                            style={{ background: '#3B82F6', color: 'white' }}
                            type="button"
                            className="flex-shrink-0 py-2.5 text-sm text-gray-900 bg-white border border-gray-300 
																focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium 
																rounded-lg dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 
																dark:hover:border-gray-600 dark:focus:ring-gray-700 w-24 ml-2">
                            Filter
                        </button>
                    </div>

                    {searchByRxNumberArchivedListResponse?.error !== null
                        && searchByRxNumberArchivedListResponse?.error?.data?.error === 'Rx number not found.'
                        && searchByRxNumberArchivedListResponse?.type === 'export_pdf' ?
                        searchByRxNumberArchivedListResponse?.error?.data?.error :
                        searchByRxNumberArchivedListResponse?.error !== null && searchByRxNumberArchivedListResponse?.type === 'export_pdf' ?
                            'Something went wrong while generating the PDF. Please try again' : null
                    }

                    <React.Fragment>
                        {searchByRxNumberArchivedListResponse?.loading && searchByRxNumberArchivedListResponse?.type === 'rx_list' && rxLoading ?
                            <div className="flex justify-center items-center mt-60 mb-80">
                                <Common.LoadingView />
                            </div> :
                            searchByRxNumberArchivedListResponse?.error !== null
                                && searchByRxNumberArchivedListResponse?.error?.data?.error === 'Rx number not found.' && searchByRxNumberArchivedListResponse?.type === 'rx_list' ?
                                searchByRxNumberArchivedListResponse?.error?.data?.error
                                : searchByRxNumberArchivedListResponse?.error !== null && searchByRxNumberArchivedListResponse?.error?.data === 'Invalid RX' && searchByRxNumberArchivedListResponse?.type === 'rx_list' ?
                                    <h1 className="text-lg font-medium text-red-600 mt-2">Invalid RX.</h1>
                                    :
                                    searchByRxNumberArchivedListResponse?.error !== null && searchByRxNumberArchivedListResponse?.type === 'rx_list' ?
                                        <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                            <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                            <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                            <button onClick={() => window.location.reload()}
                                                className="px-4  bg-red-600 text-white rounded hover:bg-red-700 
                            														   focus:outline-none focus:ring-2 focus:ring-red-500">
                                                Please Try Again.
                                            </button>
                                        </div> :

                                        searchByRxNumberArchivedListResponse?.data !== null && searchByRxNumberArchivedListResponse?.type === 'rx_list' ?
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                <div className="shadow-md border rounded-lg relative overflow-x-auto">
                                                    <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto">
                                                        <thead className="text-xs text-white uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400">
                                                            <tr>
                                                                {constant.RxNumberRowHeading?.map((column, index) => (
                                                                    <th key={index} className={column.classNames.join(' ')}>
                                                                        {column.text && <p className={column.pStyle} >{column.text}</p>}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <React.Fragment>
                                                                <tr style={{ borderBottom: `1px solid lightgray` }}>

                                                                    <th className="p-2 no-wrap w-56">
                                                                        <p className="rubik-400">{searchByRxNumberArchivedList?.date}</p>
                                                                    </th>

                                                                    <th className="p-2">
                                                                        <p className="rubik-400 fs-14">{searchByRxNumberArchivedList?.route}</p>
                                                                    </th>

                                                                    <th className="p-2">
                                                                        <p className="rubik-400 fs-14">{searchByRxNumberArchivedList?.name}</p>
                                                                    </th>

                                                                    <th className="p-2">
                                                                        <p className="rubik-400 fs-14">{searchByRxNumberArchivedList?.phn}</p>
                                                                    </th>

                                                                    <th className="p-2">
                                                                        <p className="rubik-400 fs-14">{searchByRxNumberArchivedList?.phone}</p>
                                                                    </th>

                                                                    <th className="p-2 text-right rounded-e-lg btnnn">
                                                                        <p className="rubik-400 fs-14 flex items-end justify-end"
                                                                            onClick={() => dispatch(pharmacyPatientPrescriptionViewPdf(searchByRxNumberArchivedList?.sheet, 'view_sheet', setShowModal))}>
                                                                            {addDispenseDataResponse?.loading && addDispenseDataResponse?.type === 'view_sheet' ? <Common.ProfilePicLoader /> : 'View'}
                                                                        </p>
                                                                    </th>
                                                                </tr>

                                                                <tr className={`bg-gray-200`}>
                                                                    <td scope='row' className='p-0' colSpan={11}>
                                                                        <div className="relative overflow-x-auto">
                                                                            <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto">

                                                                                <thead className="text-xs text-black-800 uppercase" style={{ background: 'rgb(107, 124, 198)', color: 'white', border: '0.1px solid gray' }}>
                                                                                    <tr style={{ padding: '0px' }}>
                                                                                        {constant.RxNumberRowSubRowsHeadings?.map((column, index) => (
                                                                                            <th key={index} className={column?.classNames?.join(' ')}>
                                                                                                <p className="rubik-500 fs-14">{column.text}</p>
                                                                                            </th>
                                                                                        ))}

                                                                                    </tr>
                                                                                </thead>

                                                                                <tbody>
                                                                                    {
                                                                                        searchByRxNumberArchivedList?.rx_drug_detail?.map((value, idx) => (
                                                                                            <tr key={idx} style={{ border: '0.1px solid gray' }}>

                                                                                                <PrescriptionListValues value={value.rx} />
                                                                                                <PrescriptionListValues value={value.narc} />
                                                                                                <PrescriptionListValues value={value.date} style={{ whiteSpace: 'nowrap' }} />
                                                                                                <PrescriptionListValues value={value.din} />
                                                                                                <PrescriptionListValues value={value.proceessing} style={{ whiteSpace: 'nowrap' }} />

                                                                                                <th className="p-3">
                                                                                                    <p className="rubik-400 fs-14">

                                                                                                        <input type='text'
                                                                                                            defaultValue={value.wit}
                                                                                                            onKeyPress={(e) => {
                                                                                                                const char = String.fromCharCode(e.charCode);
                                                                                                                const value = e.target.value;
                                                                                                                if (!/[0-9.]/.test(char) || (char === '.' && value.includes('.'))) {
                                                                                                                    e.preventDefault();
                                                                                                                }
                                                                                                            }}

                                                                                                            onChange={(e) => {
                                                                                                                return setRxLoading(false), setTimeout(() => {
                                                                                                                    e.target.value &&
                                                                                                                        dispatch(pharmacyPatientUpdateWitnessDose('update_wit_dose', setShowModal, value.rx, value.logid, e.target.value, value.patient_id, formData, 'rx_sheet_update_wit_dose'))
                                                                                                                }, 200)
                                                                                                            }}

                                                                                                            style={{ border: 'none', padding: '0px', backgroundColor: value?.color }}
                                                                                                            className='block pl-2 pt-4 w-full text-sm  bg-transparent border-1 
																																					border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
																																					cursor-text text-gray-700 font-sm'/>
                                                                                                    </p>
                                                                                                </th>

                                                                                                <PrescriptionListValues value={value.carry} className='' />
                                                                                                <PrescriptionListValues value={value.drug} className='' />
                                                                                                <PrescriptionListValues value={value.sig} />

                                                                                                <th className="p-3 btnnn rounded-e-lg flex">
                                                                                                    <p className="rubik-400 fs-14">{value.proceessing_date_time}</p>
                                                                                                    {value.dflag && <p className='-pl-2'><Icons.FlagIcon color='red' /></p>}
                                                                                                </th>
                                                                                                <th className="p-3 btnnn rounded-e-lg">
                                                                                                    {value.status === 'pending' ?
                                                                                                        <p className="rubik-400 fs-14 text-yellow-500" style={{ color: '' }}>Pending</p> :
                                                                                                        value.status === 'missed' ?
                                                                                                            <p className="rubik-400 fs-14 text-red-500">Missed</p> :
                                                                                                            value.status === 'delivered' ?
                                                                                                                <p className="rubik-400 fs-14 text-green-500">Delivered</p> :

                                                                                                                value.status === 'returned' ?
                                                                                                                    <p className="rubik-400 fs-14 text-red-500">Returned</p> :
                                                                                                                    null}
                                                                                                </th>

                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> : null}
                    </React.Fragment>
                </>}
            </div>
            {/* ---------------------------------------------Search By RX Number Start----------------------------------------------------------  */}

            {
                showModal?.witError && pharmacyPatientUpdateRxWitnessDoseResponse?.type === 'update_wit_dose' &&
                <Toast.ErrorToast status='Got an issue while updating. please try again' />
            }


            {
                showModal?.witSuccess && pharmacyPatientUpdateRxWitnessDoseResponse?.type === 'update_wit_dose' &&
                <Toast.SuccessToastMessage status='Updated Successfully.' />
            }

        </React.Fragment >
    )
}

export default RxSheet