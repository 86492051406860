import { removeWorkSpaceTab } from "../../../utils/helper/helper";
import { persistor } from '../../../services/store/store';

import Axios from "../../../networking/intraceptor";
import Cookies from "js-cookie";

export const handleLogoutPharmacy = async (setLoading, navigate) => {
	try {
		setLoading(true)
		const response = await Axios.get(`pharmacy-user-logout`);
		if (response.status === 200) {
			setLoading(false)
			Cookies.remove('authToken');
			localStorage.removeItem('@oat_p$(sDe)');
			navigate('/');
			persistor.purge();
			window.location.reload();
			removeWorkSpaceTab()
		} else {
			throw new Error(response);
		}
	} catch (error) {
		setLoading(false)
	}
}
