import React from 'react';
import { useCustomLocation } from '../../hooks';

import * as routeNames from '../../routes/routeName';

export const BreadCrumb = () => {

	const location = useCustomLocation();

	const routes = {
		[routeNames.ADD_PATIENTS]: { label: 'Add Patients', additionalLabel: 'You can add and remove patients as you want.', name: 'Patients' },
		[routeNames.PHARMACY_INFORMATION_UPDATE_FORM]: { label: 'Pharmacy Information Update Notification for Clinics', name: 'Forms' },
		[routeNames.PHARMACY_PRESCRIPTION_TRASNSFER_FORM]: { label: 'Rx Transfer Request', name: 'Forms' },
		[routeNames.pharmacy_Emergency_Supply_OAT_medications_form]: { label: 'Dr Notification for Emergency Supply', name: 'Forms' },
		[routeNames.pharmacy_medication_form]: { label: 'Anti-Viral Request Form', name: 'Forms' },
		[routeNames.missing_Rx_Requirements]: { label: 'Missing Rx Requirements', name: 'Forms' },
		[routeNames.pharmacy_emergency_supply_continuity_form]: { label: 'Emergency Supply for Continuity of Care', name: 'Forms' },
		[routeNames.patient_agreement_for_take_home_dosing]: { label: 'Patient Agreement For Take Home Dosing', name: 'Forms' },
		[routeNames.pharmacy_Plan_G_Form]: { label: 'Plan G Form', name: 'Forms' },
		[routeNames.pharmacy_Plan_Z_Form]: { label: 'Plan Z Form', name: 'Forms' },
		[routeNames.pharmacy_doctor_communication_form]: { label: 'Doctor Communication', name: 'Forms' },
		[routeNames.pharmacy_original_prescription_form]: { label: 'Request Copy of Original Rx for OAT/DD', name: 'Forms' },
		[routeNames.pharmacy_Pharmacist_Prescriber_Communication]: { label: 'Pharmacist Prescriber Communication.', name: 'Forms' },
		[routeNames.pharmacy_doctor_note_form]: { label: 'Doctor Note.', name: 'Forms' },
		[routeNames.pharmacy_tb_skin_test_mantoux_form]: { label: 'TB Skin Test (Mantoux Test) Report Form', name: 'Forms' },


		[routeNames.pharmacy_all_notifications]: { label: 'All Notifications', name: 'Dashboard' },
		[routeNames.pharmacy_compounds]: { label: 'Price List', name: 'Compound' },
		[routeNames.pharmacy_fax_log]: { label: 'Sent Fax Logs', name: 'Dashboard', additionalLabel: 'Manage Your List' },
		[routeNames.pharmacy_compound_agreement_form]: { label: 'Compounding Agreement', name: 'Compound' },
		[routeNames.pharmacy_controlled_order_request]: { label: 'Controlled Substances Order Request Form', name: 'Compound' },
		[routeNames.pharmacy_order_compound_form]: { label: 'Compound Order Form', name: 'Compound' },
		[routeNames.pharmacy_order_compounding_form]: { label: 'Compounding Order', name: 'Compound' },

		[routeNames.pharmacy_create_manual_delivery]: { label: 'Add Medication', name: 'Dispense' },
		[routeNames.pharmacy_route_delivery]: { label: 'Delivery List By Route', name: 'Dispense', additionalLabel: 'You can manage deliveries.' },
		[routeNames.pharmacy_driver]: { label: 'Manage Delivery Driver', name: 'Dispense', additionalLabel: 'You can add and remove delivery drivers as you want.' },
		[routeNames.pharmacy_deliveries_supervisor]: { label: 'Manage Delivery Supervisors', name: 'Dispense', additionalLabel: 'You can add and remove delivery supervisors as you want.' },
		[routeNames.pharmacy_delivery]: { label: 'Delivery List', name: 'Dispense', additionalLabel: 'You can manage deliveries.' },
		[routeNames.pharmacy_missed_deliveries]: { label: 'Delivery List', name: 'Dispense', additionalLabel: 'You can manage deliveries.' },
		[routeNames.pharmacy_archived_deliveries]: { label: 'Archived Deliveries', name: 'Dispense' },
		[routeNames.pharmacy_sig_check_ai]: { label: 'Sig Check - AI Alpha', name: 'Dispense' },
		[routeNames.pharmacy_delivery_lables]: { label: 'Delivery Labels', name: 'Dispense' },
		[routeNames.pharmacy_recurrence_delivery]: { label: 'Recurrence Delivery List', name: 'Dispense' },
		[routeNames.pharmacy_expiring_rx]: { label: 'Expiring Rx', name: 'Dispense' },
		[routeNames.pharmacy_patient_counselling]: { label: 'Counselling', name: 'Dispense' },
		[routeNames.pharmacy_consent_form_logs]: { label: 'Consent Submissions', name: 'Dispense' },
		[routeNames.pharmacy_drugs_billing]: { label: 'Today Billing', name: 'Dispense' },
		[routeNames.pharmacy_oat_prescriptions]: { label: 'Prescriptions', name: 'Dispense' },
		[routeNames.pharmacy_memo]: { label: 'Memo', name: 'Dispense' },
		[routeNames.upload_prescription]: { label: 'Upload Prescription', name: 'Dispense' },
		[routeNames.pharmacy_ongoing_prescritiptions]: { label: 'Ongoing Prescriptions', name: 'Dispense' },
		[routeNames.pharmacy_returned_patches]: { label: 'Returned Patches', name: 'Dispense' },
		[routeNames.pharmacy_reversed_deliveries]: { label: 'RU/RR/RE Deliveries ', name: 'Dispense' },
		[routeNames.pharmacy_tranfer_patient_concent]: { label: 'Transfer Consents ', name: 'Dispense' },
		[routeNames.pharmacy_accountability]: { label: 'Accountability ', name: 'Dispense' },

		[routeNames.pharmacy_patient_blister_pack]: { label: 'Add Patient Blister Pack', name: "Blister's" },
		[routeNames.pharmacy_blister_patients]: { label: 'Patient Blister Pack', name: "Blister's" },
		[routeNames.pharmacy_blister_packs]: { label: 'Blister Packs', name: "Blister's" },
		[routeNames.pharmacy_blister_daily_packs]: { label: "Today's Blister Pack", name: "Blister's" },
		[routeNames.pharmacy_blister_pack_on_shelf]: { label: "Blister Packs on Shelf", name: "Blister's" },

		[routeNames.pharmacy_patient_vials_pack]: { label: "Patient Vials", name: "Vials" },
		[routeNames.pharmacy_add_vials_patient]: { label: "Add Patient Vials Pack", name: "Vials" },
		[routeNames.pharmacy_vials_packs]: { label: "Vials Pack", name: "Vials" },
		[routeNames.pharmacy_dailyvials_packs]: { label: "Today's Vial Pack", name: "Vials" },


		[routeNames.pharmacy_add_medication_patients]: { label: "Patient Sepciality Medication Pack", name: "Sepciality Medication" },
		[routeNames.pharmacy_speciality_pack]: { label: "Patient Sepciality Medication Pack", name: "Sepciality Medication" },
		[routeNames.pharmacy_speciality_medication_packs]: { label: "Sepciality Medication", name: "Sepciality Medication" },
		[routeNames.pharmacy_dailymedication_packs]: { label: "Today's Speciality Medication Pack", name: "Sepciality Medication" },
		[routeNames.pharmacy_generate_invoice]: { label: "Generate Invoice", name: "Invoice" },
		[routeNames.pharmacy_previous_invoices]: { label: "Previous Invoice", name: "Invoice" },

		[routeNames.ADD_ARV]: { label: 'Add ARV', name: 'ARV' },
		[routeNames.ARV_LIST]: { label: 'ARV Lists', name: 'ARV' },

		[routeNames.pharmacy_daily_revenue]: { label: 'Pharmacy Daily Revenue', name: 'Revenue' },
		[routeNames.pharmacy_cash_change]: { label: 'Pharmacy Cash Change', name: 'Revenue' },
		[routeNames.pharmacy_deposit_info]: { label: 'Pharmacy Deposit Info', name: 'Revenue' },
		[routeNames.pharmacy_hand_cash]: { label: 'Pharmacy In Hand Cash', name: 'Revenue' },

		[routeNames.create_new_fda_form]: { label: 'Create New FDA', name: 'FDA FORM' },
		[routeNames.all_generated_fda_form_list]: { label: "All Generated FDA's", name: 'FDA FORM' },
		[routeNames.pharmacy_expiring_frequent_dispense]: { label: "Expiring FDA's", name: 'FDA FORM' },
		[routeNames.pharmacy_expired_frequent_dispense]: { label: "Expired FDA's", name: 'FDA FORM' },

		[routeNames.pharmacy_inventory_list]: { label: "Inventory List", name: 'INVENTORY' },
		[routeNames.pharmacy_total_reversed]: { label: "Total Reversed", name: 'INVENTORY' },

		[routeNames.pharmacy_patient_route]: { label: "Routes", name: 'Patients' },
		[routeNames.pharmacy_add_patient_route]: { label: "Add Patient Route", name: 'OAT' },
		[routeNames.pharmacy_reminder_notes]: { label: "Reminder Notifications", name: 'Dashboard' },

		[routeNames.pharmacy_add_travel_pack]: { label: "Travel Patients", name: 'Travel' },
		[routeNames.pharmacy_add_travel_patient]: { label: "Add Patients", name: 'Travel' },
		[routeNames.pharmacy_travel_logs]: { label: "Travel Logs", name: 'Travel' },
		[routeNames.pharmacy_billing_due]: { label: "Billing Due", name: 'Travel' },
		[routeNames.pharmacy_travel_returning_patients]: { label: "Return Date", name: 'Travel' },

		[routeNames.pharmacy_order_transfer]: { label: "Transfer Prescription", name: 'App', additionalLabel: 'You can manage your order.' },
		[routeNames.pharmacy_order_new]: { label: "New Prescription Order", name: 'App', additionalLabel: 'You can manage your order.' },
		[routeNames.pharmacy_app_user_list]: { label: "App User", name: 'App', additionalLabel: 'You can manage user' },
		[routeNames.pharmacy_app_announcements]: { label: "App Announcements", name: 'App', additionalLabel: 'You can manage your Announcements' },
		[routeNames.pharmacy_app_notification_list]: { label: "Custom Notification", name: 'App', additionalLabel: 'You can manage your Notification' },
		[routeNames.pharmacy_tb_skin_test_logs]: { label: 'TB Skin Test Logs', name: 'Forms' },
		[routeNames.pharmacy_tasks_list]: { label: 'TASKS LIST', name: 'DASHBOARD' },

		// ------------AdminBreadCrumbs------------------
		[routeNames.pharmacy_timeoff]: { label: 'TIME OFF REQUEST', name: 'TEAM' },
		[routeNames.pharmacy_create_new_task]: { label: 'CREATE NEW TASK', name: 'TEAM' },
		[routeNames.pharmacy_tasks]: { label: 'TASKS LIST', name: 'TEAM' },
		[routeNames.pharmacy_team_attendance]: { label: 'TIME SHEET', name: 'TEAM' },
		[routeNames.pharmacy_certificate_template]: { label: 'VIEW CERTIFICATE TEMPLATE', name: 'TEAM' },
		[routeNames.pharmacy_check_out_in]: { label: 'Check In Out Logs', name: 'Team' },
		[routeNames.pharmacy_break_parroll_settings]: { label: 'Break/Payroll Setting', name: 'Settings' },
		[routeNames.pharmacy_assign_certificate]: { label: 'EMPLOYEE CERTIFICATES', name: 'TEAM' },
		[routeNames.pharmacy_assign_certificate_employee]: { label: 'ASSIGN CERTIFICATE', name: 'TEAM' },
		[routeNames.pharmacy_department]: { label: 'DEPARTMENTS', name: 'TEAM' },
		[routeNames.pharmacy_user]: { label: 'Manage Users', name: 'TEAM', additionalLabel: 'You can add and remove users as you want.' },
		[routeNames.pharmacy_settings]: { label: 'Winrx Setting', name: 'Settings' },

		[routeNames.pharmacy_team_avalibility]: { label: 'Team Schedule', name: 'Team' }
	}

	const currentRoute = routes[location.pathname];

	return (
		<div className="grid grid-cols-12 gap-4 pl-10">
			<div className="mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">
				<nav className="flex" aria-label="Breadcrumb">
					<ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
						<li className="inline-flex items-center">
							<a className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
								{currentRoute && currentRoute.name}
							</a>
						</li>

						<li>
							<div className="flex items-center">
								<svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
								</svg>
								{currentRoute && (
									<a className="ms-1 text-sm font-medium text-blue-600 text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
										{currentRoute.label}
									</a>
								)}
							</div>
						</li>
					</ol>
				</nav>
				{currentRoute && currentRoute.additionalLabel && <p className="fs-14 rubik-400">{currentRoute.additionalLabel}</p>}
			</div>
		</div>
	);
};
