import React, { useEffect, useState, useRef } from 'react';
import { useCustomDispatch, useCustomLocation, useCustomNavigate } from '../../../hooks';
import { DropdownSelect } from "../../../components/pharmacy/dispense/dispense";
import { useCustomSelectors } from '../../../services/selectors/allSelectors';
import { useCustomLoadingStates } from '../../../hooks/customStates';
import { fetchCertificateTemplates, fetchSingleTemplate, fethEmployeeAssignedCertificateList } from '../../../services/actions/admin/team/fetchTeamsList';
import { useAdminSelectors } from '../../../services/selectors/adminModuleSelectors';
import * as Common from '../../../components/common';
import { LoadingView } from '../../../components/common';
import { Height } from '@mui/icons-material';
import WebFont from 'webfontloader';
import { fetchAllChatUserList } from '../../../services/actions/chat/allUserListActions';
import { fabric } from 'fabric';
import { assignCertificateToEmployee } from '../../../services/actions/admin/team/addTeamData';
import { initalShowToastMessage } from '../../../utils/constants/formInitialStates';
import { ErrorToastMessage, SuccessToastMessage } from "../../../components/loaders/toastMessage";

const AssignTaskSelect = ({ formData, setFormData, errorMessage, employeeList, profileData, placeholder = 'Assign To', padding = 'pb-2.5 pt-4', height = "auto", }) => {
    const dispatch = useCustomDispatch();

    useEffect(() => {
        if (employeeList?.length === 0 || employeeList === undefined) {
            dispatch(fetchAllChatUserList());
        }
    }, [dispatch, employeeList]);

    const handleSelectChange = (e) => {
        setFormData({
            ...formData,
            assignTask: {
                value: e.target.value,
                name: e.target.options[e.target.selectedIndex].text
            }
        });
    };

    return (
        <div className="relative w-full">
            <select
                id="assignTask"
                style={{ height: height }}
                name="assignTask"
                value={formData.assignTask.value || ''}
                placeholder="Assign Task"
                onChange={handleSelectChange}
                className={`block ${errorMessage.assignTask && 'border-red-500'} scroll-box pl-4 w-full px-2.5 ${padding} text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer bg-white`}
            >
                <option value="" disabled>{placeholder}</option>
                {employeeList?.map((option, index) => (
                    <React.Fragment key={index}>
                        {profileData?.user_id !== option.user_id && (
                            <option value={option.user_id}>
                                {option.name}
                            </option>
                        )}
                    </React.Fragment>
                ))}
            </select>
        </div>
    );
};

const AssignCertificateTemplate = () => {
    const [formData, setFormData] = useState({ assignTask: '', certificate: '' });
    const [loadingStates, setLoadingStates] = useState({});
    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors();
    const { chatUserList, profileData } = useCustomSelectors();
    const [jsonData, setJsonData] = useState('');
    const [errorMessages, setErrorMessages] = useState({ title: '', body: '' });
    const currentDate = new Date().toLocaleDateString();
    const [canvasKey, setCanvasKey] = useState(Date.now());
    const [JsonDataRelaced, setJsonDataRelaced] = useState('');
    const [canvasImageBase64, setCanvasImageBase64] = useState('');
    const dispatch = useCustomDispatch();
    const location = useCustomLocation();
    const navigate = useCustomNavigate();
    const startDateRef = React.useRef();
    const endDateRef = React.useRef();
    const { apiCallMade, setApiCallMade } = useCustomLoadingStates();
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    const [pageNumber, setPageNumber] = useState(initialPage);
    const [options, setOptions] = useState(['certificates']);
    const [getCert, setGetCert] = useState(true);
    const [getCertID, setGetCertID] = useState('');
    const employeeList = chatUserList?.data?.data;
    const [showToast, setShowToast] = useState(initalShowToastMessage);

    const inputFeilds = [
        { label: "Title", key: "title", error: errorMessages.title },
    ];
    const canvasRef = useRef(null);
    const fabricCanvasRef = useRef(null);
    const containerRef = useRef(null);

    const canvasInstanceRef = useRef(null);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            const updatedData = { ...prevFormData, [name]: value };
            return updatedData;
        });
    };

    const extractFontsFromJson = (jsonData) => {
        const fonts = new Set();
        try {
            const parsedData = JSON.parse(jsonData);
            parsedData.objects.forEach(obj => {
                if (obj.type === 'textbox' && obj.fontFamily) {
                    fonts.add(obj.fontFamily);
                    WebFont.load({
                        google: {
                            families: [obj.fontFamily],
                        },
                    });
                }
            });
        } catch (error) {
            console.error('Error parsing JSON data:', error);
        }
    };

    // Fetch certificate templates
    useEffect(() => {

        const fetchData = async () => {
            const params = {
                page: pageNumber,
            };
            dispatch(fetchCertificateTemplates('fetch_certificate_template_list', params));
        };

        fetchData();
    }, [dispatch]);

    // Fetch single certificate template
    const fetchParticularCertificate = async (id) => {
        setGetCert(false);
        if (containerRef) { containerRef.current.innerHTML = ''; }

        if (id) {
            setJsonDataRelaced('')
            setGetCertID(id);
            await dispatch(fetchSingleTemplate('fetch_single_certificate_template_list', id));
            setGetCert(true);
        }
    };

    useEffect(() => {

        if (formData.certificate !== '' && getCertID !== formData.certificate) {
            fetchParticularCertificate(formData.certificate);
        }
        if (formData.certificate !== '' && formData.assignTask.name !== '' && formData.assignTask.name !== undefined) {
            let jsonDataReplaced = jsonData;
            jsonDataReplaced = jsonDataReplaced.replace('{{current_date}}', currentDate);
            jsonDataReplaced = jsonDataReplaced.replace('{{employee_name}}', formData.assignTask.name);
            setJsonDataRelaced(jsonDataReplaced);
            extractFontsFromJson(jsonDataReplaced);
            setCanvasKey(Date.now());
        }
    }, [formData]);

    // Update jsonData when fetching single template
    useEffect(() => {
        if (TeamListResponse?.data && TeamListResponse?.type === 'fetch_single_certificate_template_list') {
            let jsonDataReplaced = TeamListResponse.data?.data?.certificate_template;
            jsonDataReplaced = jsonDataReplaced.replace('{{current_date}}', currentDate);
            if (formData.certificate !== '' && formData.assignTask.name !== '' && formData.assignTask.name !== undefined) {
                jsonDataReplaced = jsonDataReplaced.replace('{{employee_name}}', formData.assignTask.name);
            }
            setJsonData(TeamListResponse.data?.data?.certificate_template);
            setJsonDataRelaced(jsonDataReplaced);
            extractFontsFromJson(jsonDataReplaced);
        }
    }, [TeamListResponse]);

    // Update options when fetching certificate template list
    useEffect(() => {
        if (TeamListResponse?.data && TeamListResponse?.type === 'fetch_certificate_template_list') {
            let fetchedOptions = [{ value: '', label: 'Select Certificate' }];
            fetchedOptions = fetchedOptions.concat(TeamListResponse.data.data.data.map(item => ({
                value: item.id,
                label: item.title,
            })));
            setOptions(fetchedOptions);
        }
    }, [TeamListResponse]);

    const HandleSubmitPackDetails = () => {
        const specificValidations = {
            title: 'Title is required',
            assignTask: 'Please select Employee',

        };

        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(specificValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        );

        setErrorMessages({
            ...errorMessages,
            ...newErrorMessages,
        });

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

        if (allDataPresent) {
            const requestData = {
                title: formData.title,
                employeeid: formData.assignTask.value,
                certificateImage: getBased64()
            }

            dispatch(assignCertificateToEmployee('assign_certificate_to_employeee', setShowToast, requestData, navigate));
        }


    };

    const calculateMultiplier = (canvas) => {
        const desiredWidth = 1200;
        const desiredHeight = 800;

        const currentWidth = canvas.width;
        const currentHeight = canvas.height;

        const widthMultiplier = desiredWidth / currentWidth;
        const heightMultiplier = desiredHeight / currentHeight;

        return Math.min(widthMultiplier, heightMultiplier);
    };


    const getBased64 = () => {

        const canvas = canvasInstanceRef.current
        const multiplier = calculateMultiplier(canvas);

        const dataURL = canvas.toDataURL({
            format: 'png',
            multiplier: multiplier,
            quality: 1,
        });

        return dataURL

    };

    // Resizing code
    useEffect(() => {

        if (!containerRef.current || !JsonDataRelaced) return;
        containerRef.current.innerHTML = '';
        if (canvasInstanceRef.current) {
            canvasInstanceRef.current.dispose();
            containerRef.current.innerHTML = ''; // Clear the container
        }

        const container = containerRef.current;
        const canvasElement = document.createElement('canvas');
        canvasElement.style.boxShadow = 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px';
        container.appendChild(canvasElement);

        const canvas = new fabric.Canvas(canvasElement, {
            backgroundColor: '#ecf0f1',
            preserveObjectStacking: true,
        });
        canvasInstanceRef.current = canvas;

        const resizeCanvas = () => {
            const containerWidth = container.clientWidth - 300;
            const containerHeight = container.clientHeight - 300;

            let canvasWidth = containerWidth;
            let canvasHeight = containerWidth * (649 / 918);

            if (canvasHeight > containerHeight) {
                canvasHeight = containerHeight;
                canvasWidth = containerHeight * (918 / 649);
            }

            canvas.setDimensions({ width: canvasWidth, height: canvasHeight });
            canvas.setZoom(canvasWidth / 910);
            canvas.renderAll();
        };

        canvas.loadFromJSON(JsonDataRelaced, () => {
            canvas.forEachObject(obj => {
                // obj.selectable = false;
            });
            resizeCanvas();
            canvas.renderAll();
        });

        const resizeObserver = new ResizeObserver(resizeCanvas);
        resizeObserver.observe(container);

        return () => {
            resizeObserver.disconnect();
            if (canvasInstanceRef.current) {
                canvasInstanceRef.current.dispose();
                canvasInstanceRef.current = null;
            }
            if (containerRef.current) {
                containerRef.current.innerHTML = '';
            }
        };
    }, [JsonDataRelaced]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.innerHTML = `
            <div style="display: flex; flex-direction: column;margin-top:10px ; height: 100%; text-align: center;">
                <div style="font-size: 3.2rem; color: black; margin-bottom: 20px;">Certificate will appear here.</div>
                <div style="font-size: 18px; color: #888;max-width:500px;margin-right:auto;margin-left:auto;">
                    Kindly choose a Certificate template and an Employee to proceed from the left window.
                </div>
            </div>
        `;
        }
    }, []);

    return (
        <>
            <div className='BodyBgColor'>
                <div className=" mx-auto px-4 pt-5 pb-5 container" style={{ height: '100vh' }}>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="allRequest">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className='flex gap-0 ' style={{ height: '100vh', position: 'relative', background: "#fff" }}>
                                    <div className='mt-5 p-5'>
                                        <div className='col-span-3' style={{maxWidth:'300px'}}>
                                            <DropdownSelect
                                                height='40px'
                                                label=""
                                                name="certificate"
                                                formData={formData}
                                                setFormData={setFormData}
                                                padding='pb-1.5 pt-2'
                                                options={options}
                                            />
                                            <div className='mt-5'>
                                                {inputFeilds?.map((field, idx) => (
                                                    <div className="relative mb-4" key={idx}>
                                                        <input
                                                            style={{ height: '40px' }}
                                                            type="text"
                                                            id={field.key}
                                                            name={field.key}
                                                            value={formData[field.key]}
                                                            onChange={handleInputChange}
                                                            className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                                            placeholder=""
                                                            autoComplete='false'
                                                        />
                                                        <label
                                                            htmlFor={field.key}
                                                            className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                        >
                                                            {field.error ? '' : field.label}
                                                        </label>

                                                        {field.error && (
                                                            <p className="text-xs text-red-600 dark:text-red-400">
                                                                {field.error}
                                                            </p>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            <AssignTaskSelect
                                                height='40px'
                                                formData={formData}
                                                setFormData={setFormData}
                                                employeeList={employeeList}
                                                profileData={profileData}
                                                errorMessage={errorMessages}
                                                placeholder="Select Employee"
                                                padding='pb-1.5 pt-2'
                                            />
                                            <button type="button" onClick={HandleSubmitPackDetails}
                                                className={`mt-5 rounded-md rubik-500 flex justify-center items-center text-white blueBg-color   w-full text-sm px-5 py-2.5 text-center`}>
                                                {addTeamDataResponse.loading && addTeamDataResponse.type === 'assign_certificate_to_employeee' ? 'Assigning...' : 'Assign'}
                                            </button>
                                        </div>
                                    </div>

                                    <div className='w-full '>
                                        {getCert ? (
                                            <div
                                                className='mt-10 flex justify-center'
                                                ref={containerRef}
                                                style={{ width: '100%', height: '100%', position: 'relative' }}
                                            />

                                        ) : <div style={{ height: '50vh' }} className="flex justify-center items-center ">
                                            <LoadingView />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showToast.showToast && <SuccessToastMessage status={'Assigned successfully.'} />}
            {showToast.errorToast && <ErrorToastMessage status={'Something went wrong. Please try again.'} />}
        </>
    );
};

export default AssignCertificateTemplate;
