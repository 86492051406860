import {
    PHARMACY_PATIENT_NOTIFICATIONS_REQUEST,
    PHARMACY_PATIENT_NOTIFICATIONS_SUCCESS,
    PHARMACY_PATIENT_NOTIFICATIONS_FAILURE
} from '../../../constants';

const initialState = {
    data: null,
    loading: false,
    error: null,
    type: null,
}

const pharmacyPatientNotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PHARMACY_PATIENT_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                type: action.types,
            };
        case PHARMACY_PATIENT_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
                type: action.types,

                
            };
        case PHARMACY_PATIENT_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null,
                type: action.types,
            };
        default:
            return state;
    }
};

export default pharmacyPatientNotificationsReducer;
