import {
	ADD_DOCTORS_REQUEST,
	ADD_DOCTORS_SUCCESS,
	ADD_DOCTORS_FAILURE
} from "../../../constants"

import { doctor_new_doctor } from "../../../../networking/urlEndPoints"
import { hideAddDoctorModal } from "../../../../assets/js/custome"
import { FetchAllDoctorsList } from "./doctorsList"
import Axios from "../../../../networking/intraceptor"

export const addDoctorRequest = () => ({
	type: ADD_DOCTORS_REQUEST,
})

export const addDoctorSuccess = (data) => ({
	type: ADD_DOCTORS_SUCCESS,
	payload: data,
})

export const addDoctorFailure = (error) => ({
	type: ADD_DOCTORS_FAILURE,
	payload: error,
})

export const addNewDoctor = (formData, setShowModal, showModal) => {
	return async (dispatch) => {
		dispatch(addDoctorRequest())
		try {
			const response = await Axios.post(`${doctor_new_doctor}`, {
				first_name: formData.firstName,
				last_name: formData.lastName,
				cps_id: formData.cps_id
			})
			if (response?.status === 200) {
				dispatch(addDoctorSuccess(response?.data))
				dispatch(FetchAllDoctorsList())
				setTimeout(() => {
					hideAddDoctorModal(setShowModal, showModal)
				}, 2000)

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(addDoctorFailure(error?.response))
			}
		}
	}
}