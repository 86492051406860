import { useRef, useEffect, useState } from 'react';

const ImageCanvas = ({ canvaValue, setCanValue }) => {
	const canvasRef = useRef(null);
	const [clipboardImage, setClipboardImage] = useState('');

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');

		const imgURL = "";

		canvas.width = 1536 - 680;
		canvas.height = 746 + 460;

		loadCanvasImage(imgURL, context);

		window.addEventListener("paste", handlePaste);

		return () => {
			window.removeEventListener("paste", handlePaste);
		};
	}, []);

	const handlePaste = (e) => {
		if (e.clipboardData === undefined) return false;
		const imgs = e.clipboardData.items;

		for (let i = 0; i < imgs.length; i++) {
			if (imgs[i].type.indexOf("image") === -1) continue;
			const imgObj = imgs[i].getAsFile();
			const url = window.URL || window.webkitURL;
			const src = url.createObjectURL(imgObj);
			const canvas = canvasRef.current;
			const context = canvas.getContext('2d');

			context.clearRect(0, 0, canvas.width, canvas.height);
			loadCanvasImage(src, context);
			imgobjToBase64(imgObj);
		}
	};

	const imgobjToBase64 = (imgObj) => {
		const reader = new FileReader();
		reader.readAsDataURL(imgObj);

		reader.onloadend = function () {
			const base64data = reader.result;
			setClipboardImage(base64data);
			setCanValue(base64data)
		};
	};

	const loadCanvasImage = (src, context) => {
		const img = new Image();
		img.onload = function () {
			context.drawImage(img, 0, 0);
		};
		img.src = src;
	};

	return (
		<div style={{ border: '15px solid #e1e1e1', background: '#fff' }} className='rounded-md copyPastBg'>
			<p style={{ background: '#e1e1e1', paddingBottom: '10px' }} className='rubik-500'>Copy & Paste The Slip (ctrl + v)</p>
			<input type="hidden" name="clipboard_image" value={clipboardImage} />
			<canvas id="canvimg" ref={canvasRef}></canvas>
		</div>
	);
};

export default ImageCanvas;
