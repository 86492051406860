import Axios from "../../../../networking/intraceptor"
import { fetchAllTableListData } from "../other"

import * as path from '../../../../networking/urlEndPoints'

export const handleCompoundDelete = async (urlEndPoint,
	id, dispatch,
	setLoading,
	setShowModal,
	type,
) => {
	try {
		setLoading(true)
		const response = await Axios.post(`${urlEndPoint}`)
		if (response?.status === 200) {
			dispatch(fetchAllTableListData(path.pharmacy_compound_list, null, null))

			setLoading(false)

			if (typeof setShowModal === 'function' && type === 'pharmacy-compound') {
				setShowModal(prevState => ({
					...prevState,
					showToast: true,
					deleteModal: false
				}));
				setTimeout(() => {
					setShowModal(prevState => ({
						...prevState,
						showToast: false,
					}));
				}, 2000);
			}
		} else {
			throw new Error(response)
		}
	} catch (error) {
		if (error?.response) {
			setLoading(false)
			setShowModal(prevState => ({
				...prevState,
				errorShow: true
			}));
			setTimeout(() => {
				setShowModal(prevState => ({
					...prevState,
					errorShow: false
				}));
			}, 2000);
		}
	}
}

export const handleEditCompoundDelete = async (urlEndPoint, id, dispatch, setLoading, setShowModal, type) => {
	try {
		setLoading(true)
		const response = await Axios.delete(`${urlEndPoint}`)
		if (response?.status === 200) {
			dispatch(fetchAllTableListData(`${path.pharmacy_price_compound_list}/${id}`, null, null))
			setLoading(false)

			if (typeof setShowModal === 'function' && type === 'pharmacy-compound-price-list') {
				setShowModal(prevState => ({
					...prevState,
					showToast: true,
					deleteModal: false
				}));
				setTimeout(() => {
					setShowModal(prevState => ({
						...prevState,
						showToast: false,
					}));
				}, 2000);
			}
		} else {
			throw new Error(response)
		}
	} catch (error) {
		if (error?.response) {
			setLoading(false)
			setShowModal(prevState => ({
				...prevState,
				errorShow: true
			}));
			setTimeout(() => {
				setShowModal(prevState => ({
					...prevState,
					errorShow: false
				}));
			}, 2000);
		}
	}
}

