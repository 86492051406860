import React, { useEffect, useState } from 'react'

import {
    initalFdaFormData
} from '../../../utils/constants/formInitialStates'

import {
    BreadCrumb,
    Footer,
    Sidebar
} from '../../../components/common'

import { useCustomSelectors } from '../../../services/selectors/allSelectors'
import { useCustomDispatch } from '../../../hooks'
import { Errors } from '../../../components/error/alertMessages'
import { Loader } from '../../../components/common/loadingView'

import { onhandlePharmacyFormDataChange } from '../../../utils/data/handleFormDataChange'

import { useFormsSelectors } from '../../../services/selectors/formsSelector'
import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'

import { faxFormFailure } from '../../../services/actions/pharmacy/forms/pharmacyUpdateNotificationsSrFaxForm'
import { TextInput } from '../../../components/pharmacy/dispense/dispense'
import { handleSubmitNewFDAForm } from '../../../utils/validations/createNewFDAFormValidation'

import ChatBox from '../../../components/chat/chatbox'
import * as Pharmacy from '../../../components/pharmacy/form/form'

const CreateNewFDAForm = () => {

    const dispatch = useCustomDispatch()

    const { pharmacyLoginData } = useCustomSelectors()
    const { previewPdfResponse, sendFaxResponse } = useFormsSelectors()

    const [formData, setFormData] = useState(initalFdaFormData)
    const [errorMessages, setErrorMessages] = useState(initalFdaFormData)

    const createInitialFormDataArray = (numItems) => Array.from({ length: numItems }, () => ({ ...initalFdaFormData }));

    const [prescriberData, setprescriberData] = useState(createInitialFormDataArray(6));
    const [errorMessagesArray, setErrorMessagesArray] = useState(createInitialFormDataArray(6));

    const handleFormDataChange = (e) => {
        onhandlePharmacyFormDataChange(e, setFormData, formData, setErrorMessages, errorMessages)
    }

    useEffect(() => {
        if (pharmacyLoginData) {
            setFormData({
                ...formData,
                pharmacyManager: pharmacyLoginData.manager_name,
                pharmacyName: pharmacyLoginData.name,
                pharmacyfax: pharmacyLoginData?.fax
            })
        }
    }, [pharmacyLoginData])

    const rationalData = [
        "Cognitive impairment",
        "History of abuse or poor compliance",
        "No support structure (to assist with administration of drug therapy)",
        "Risk of dependence",
        "Susceptible to theft or loss of belongings",
        "Complex medication regime",
        "Physical or mental disability",
        "Literacy issues",
        "Language issues",
        "Non-compliance or misuse is suspected",
        "2-27–day dispensing verbally authorized by prescribe",
    ];

    const handleRationalDataChange = (event) => {

        const { name, checked, value } = event.target;

        setFormData((prevValues) => {
            if (event.target.type === 'checkbox') {
                const rational = Array.isArray(prevValues.rational)
                    ? prevValues.rational.slice()
                    : [];

                if (checked) {
                    rational.push(parseInt(value, 10));
                } else {
                    const index = rational.indexOf(parseInt(value, 10));
                    if (index !== -1) {
                        rational.splice(index, 1);
                    }
                }
                return {
                    ...prevValues,
                    rational: rational,
                };
            } else {
                return {
                    ...prevValues,
                    [name]: value,
                };
            }
        });
    }

    const handleChangePrescriberData = (index, newData) => {
        const newFormDataArray = [...prescriberData];
        newFormDataArray[index] = { ...newFormDataArray[index], ...newData };
        setprescriberData(newFormDataArray);
    };

    const handleErrorMessagesChange = (index, newErrors) => {
        const newErrorMessagesArray = [...errorMessagesArray];
        newErrorMessagesArray[index] = { ...newErrorMessagesArray[index], ...newErrors };
        setErrorMessagesArray(newErrorMessagesArray);
    };

    useEffect(() => {
        dispatch(previewPdfFailure(null))
        dispatch(faxFormFailure(null))
    }, [])

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="timeSheets">
                                <div className="grid grid-cols-12 gap-4 w-full">

                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
                                            <Pharmacy.Date formData={formData}
                                                setFormData={setFormData} />

                                            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                <Pharmacy.FormWinRxPatients
                                                    formData={formData}
                                                    handleFormDataChange={handleFormDataChange} />
                                            </ul>
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
                                            <Pharmacy.PatientPhn
                                                handleFormDataChange={handleFormDataChange}
                                                formData={formData}
                                                errorMessages={errorMessages}
                                                setErrorMessages={setErrorMessages}
                                                setFormData={setFormData} />

                                            <Pharmacy.PatientName
                                                handleFormDataChange={handleFormDataChange}
                                                formData={formData}
                                                errorMessages={errorMessages}
                                                setFormData={setFormData}
                                                setErrorMessages={setErrorMessages} zIndex={'z-50'} />

                                            <Pharmacy.PatientDob
                                                formData={formData}
                                                setErrorMessages={setErrorMessages}
                                                setFormData={setFormData}
                                                handleFormDataChange={handleFormDataChange}
                                                errorMessages={errorMessages} />
                                        </div>

                                        <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 relative -mt-4 mb-8'>
                                            <Pharmacy.AddDoctor text={'Add Prescriber'}/>
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">

                                            {prescriberData?.map((formData, index) => (
                                                <div key={index} className='flex gap-4'>
                                                    <div style={{ width: '250px' }}>
                                                        <Pharmacy.DoctorList
                                                            formData={formData}
                                                            errorMessages={errorMessagesArray[index]}
                                                            setFormData={(newData) => handleChangePrescriberData(index, newData)}
                                                            setErrorMessages={(newErrors) => handleErrorMessagesChange(index, newErrors)}
                                                            placeholder='Select Prescriber'
                                                        />
                                                    </div>
                                                    <div style={{ width: '250px' }} className='relative mt-4'>
                                                        <Pharmacy.Date
                                                            formData={formData}
                                                            setFormData={(newData) => handleChangePrescriberData(index, newData)}
                                                            name='initiatedDate'
                                                            dValue={formData.initiatedDate}
                                                            label='Initiated Date'
                                                        />
                                                    </div>
                                                </div>
                                            ))}

                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 mt-4 gap-4">
                                            <div className="relative">
                                                <h1 className='rubik-500'>Frequency of Dispensing</h1>
                                                <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg sm:flex     dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                    {[
                                                        { id: 1, label: 'Weekly', value: 'weekly' },
                                                        { id: 2, label: 'Biweekly', value: 'biweekly' },
                                                        { id: 3, label: 'Other', value: 'other' },
                                                    ]?.map((item, index) => (
                                                        <li key={index} className={`ml-4 w-full dark:border-gray-600`}>
                                                            <div className="flex items-center ps-3">
                                                                <input
                                                                    id={item.id}
                                                                    name='weekly'
                                                                    type="radio"
                                                                    onChange={(e) => { return setFormData({ ...formData, weekly: item.value }) }}
                                                                    defaultValue={formData?.weekly}
                                                                    defaultChecked={index === 0}
                                                                    className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                                />
                                                                <label htmlFor={item.id} className="w-full rubik-400  py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                                    {item.label}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            {formData.weekly === 'other' &&
                                                <div className="relative pt-3">
                                                    <TextInput setFormData={setFormData} formData={formData} label='Other Frequency'
                                                        height="48px" name='other_name'
                                                    />
                                                </div>}

                                            <div className="relative mt-3">
                                                <input
                                                    type="text"
                                                    id="deductible"
                                                    onChange={handleFormDataChange}
                                                    name="deductible"
                                                    value={formData.deductible}
                                                    className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.deductible && "border-red-600"
                                                        }`}
                                                    placeholder=""
                                                />
                                                <label
                                                    htmlFor="deductible"
                                                    className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
                                                >
                                                    {!errorMessages.deductible && "Deductible"}
                                                </label>
                                                {errorMessages.deductible && (
                                                    <p className="text-xs text-red-600 dark:text-red-400">
                                                        {errorMessages.deductible}
                                                    </p>
                                                )}

                                            </div>

                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                                            <Pharmacy.LoginPharmacyName
                                                setFormData={setFormData}
                                                handleFormDataChange={handleFormDataChange}
                                                formData={formData}
                                                errorMessages={errorMessages} />

                                            <Pharmacy.LoginPharmacyManger handleFormDataChange={handleFormDataChange}
                                                setFormData={setFormData}
                                                formData={formData}
                                                errorMessages={errorMessages} />

                                            <Pharmacy.Designation
                                                setFormData={setFormData}
                                                handleFormDataChange={handleFormDataChange}
                                                formData={formData} errorMessages={errorMessages} />
                                        </div>

                                        <div style={{ border: '1px solid lightgray' }} className='p-3  mt-4 rounded-lg'>
                                            <h1 className='rubik-500'>Rationale For Frequent Dispensing:</h1>
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 ">
                                                {rationalData?.map((label, index) => (
                                                    <div key={index} className="flex items-center gap-3">
                                                        <input id={`checkbox-${index}`} name="rational" type="checkbox"
                                                            value={index + 1} checked={formData?.rational?.includes(index + 1)}
                                                            onChange={handleRationalDataChange}
                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                        <label htmlFor={`checkbox-${index}`} className="fs-16 rubik-400 ms-2 text-sm font-medium text-gray-500 dark:text-gray-300">
                                                            {label}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                                            {formData?.rational?.map((value, index) => {
                                                return (<React.Fragment key={index}>
                                                    {value === 11 &&
                                                        <div className='mb-4'>
                                                            <Pharmacy.FDAFormDoctorList
                                                                formData={formData}
                                                                errorMessages={errorMessages}
                                                                setFormData={setFormData}
                                                                setErrorMessages={setErrorMessages} />
                                                        </div>}

                                                </React.Fragment>)
                                            })}
                                        </div>


                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 mt-4">
                                            <div className="relative">
                                                <textarea
                                                    type="text"
                                                    id={'note'}
                                                    onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                                                    name={'note'}
                                                    value={formData.note}
                                                    className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
                                                    placeholder={''}
                                                />
                                                <label
                                                    htmlFor={'note'}
                                                    className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
                                                >
                                                    Note
                                                </label>

                                            </div>
                                        </div>


                                        {previewPdfResponse?.error !== null &&
                                            <>
                                                <br />
                                                <Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
                                            </>
                                        }

                                        {sendFaxResponse?.error !== null &&
                                            <>
                                                <br />
                                                <Errors error={sendFaxResponse?.error.message ?
                                                    sendFaxResponse?.error?.message :
                                                    'Something went wrong when sending Fax. Please try again'} />
                                            </>
                                        }

                                        <div className='flex'>
                                            <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
                                                <button style={{ height: '40px' }} type="button"
                                                    onClick={() => handleSubmitNewFDAForm(
                                                        formData,
                                                        setErrorMessages,
                                                        errorMessages,
                                                        dispatch,
                                                        'PDF', prescriberData)}

                                                    className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

                                                    {previewPdfResponse?.loading ? <>
                                                        Genrating PDF
                                                        &nbsp;<Loader /> </> : 'Preview PDF'}

                                                </button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />
            <Footer />

        </>
    )
}

export default CreateNewFDAForm