import {
	FETCH_USER_WALL_LIST_REQUEST,
	FETCH_USER_WALL_LIST_SUCCESS,
	FETCH_USER_WALL_LIST_FAILURE
} from "../../constants";

import { pharmacy_user_wall_post_lists } from "../../../networking/urlEndPoints";
import Axios from '../../../networking/intraceptor'

export const fetchUserWallListRequest = () => ({
	type: FETCH_USER_WALL_LIST_REQUEST,
});

export const fetchUserWallListSuccess = (data) => ({
	type: FETCH_USER_WALL_LIST_SUCCESS,
	payload: data,
});

export const fetchUserWallListFailure = (error) => ({
	type: FETCH_USER_WALL_LIST_FAILURE,
	payload: error,
})

export const fetchUserWallLists = (setWallListLoading, setContentLoader) => {
	return async (dispatch) => {
		dispatch(fetchUserWallListRequest())

		const params = {
			no_of_records: '15',
		}

		try {
			const response = await Axios.get(`${pharmacy_user_wall_post_lists}`, { params });
			if (response?.status === 200) {
				dispatch(fetchUserWallListSuccess(response?.data));
				if (typeof setWallListLoading === 'function') {
					setWallListLoading(false)
				}

				if (typeof setContentLoader === 'function') {
					setContentLoader(false)
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchUserWallListFailure(error?.response))
				if (typeof setWallListLoading === 'function') {
					setWallListLoading(false)
				}

				if (typeof setContentLoader === 'function') {
					setContentLoader(false)
				}
			}
		}
	};
}


export async function fetchUserWallList(pageNumber, totalPagination, wallList, setWallList, setPageNumber) {
	if (pageNumber < totalPagination) {
		const nextPage = pageNumber + 1;
		const params = {
			no_of_records: '15',
			page: nextPage,
		};
		try {
			const response = await Axios.get(`${pharmacy_user_wall_post_lists}`, { params });
			if (response?.status === 200) {
				setWallList([...wallList, ...response?.data?.data?.data]);
				setPageNumber(nextPage);
			}
		} catch (error) {
			if (error?.response) {
			}
		}
	}
}