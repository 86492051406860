import {
	PHARMACY_PATIENT_NOTIFICATIONS_REQUEST,
	PHARMACY_PATIENT_NOTIFICATIONS_SUCCESS,
	PHARMACY_PATIENT_NOTIFICATIONS_FAILURE
} from '../../../constants';

import * as pdf from '../forms/previewPdf'

import Axios from '../../../../networking/intraceptor'
import { p_reminder_nofication, pharmacy_patient_notification_list } from '../../../../networking/urlEndPoints'
import { PreviewPdf } from '../../../../utils/helper/helper';

export const pharmacyPatientNotificationsRequest = (types) => ({
	type: PHARMACY_PATIENT_NOTIFICATIONS_REQUEST,
	types: types
});

export const pharmacyPatientNotificationsSuccess = (data, types) => ({
	type: PHARMACY_PATIENT_NOTIFICATIONS_SUCCESS,
	payload: data,
	types: types
});

export const pharmacyPatientNotificationsFailure = (error, types) => ({
	type: PHARMACY_PATIENT_NOTIFICATIONS_FAILURE,
	payload: error,
	types: types
});

export const fetchPatientNotifications = (page, setAddTeamNoteData, addTeamNoteData, type, types = "fetchPatientNotifications",) => {
	return async (dispatch) => {
		dispatch(pharmacyPatientNotificationsRequest(types));
		try {

			let params = {
				no_of_records: 10,
				page: page,
				noteoption: addTeamNoteData?.selectedNoteOptions,
				from_date: addTeamNoteData?.startDate,
				to_date: addTeamNoteData?.endDate,
				marknotes: addTeamNoteData?.marknotes ?? false
			}

			if (addTeamNoteData?.search) {
				params.search = addTeamNoteData?.search
			}

			if (typeof setAddTeamNoteData === 'function') {
				setAddTeamNoteData({
					...addTeamNoteData,
					initalListLoading: true
				})
			}
			const response = await Axios.get(`${pharmacy_patient_notification_list}`, { params });
			if (response?.status === 200) {
				dispatch(pharmacyPatientNotificationsSuccess(response?.data, types));
				if (typeof setAddTeamNoteData === 'function') {
					setAddTeamNoteData({
						...addTeamNoteData,
						initalListLoading: false
					})
				}

				if (type === 'PDF') {
					PreviewPdf(response?.data?.data)
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyPatientNotificationsFailure(error?.response, types));
			}
		}
	}
}

export const FetchPatientReminderNotificationList = (params, type = 'FetchPatientReminderNotificationList') => {
	return async (dispatch) => {
		dispatch(pharmacyPatientNotificationsRequest(type))
		try {
			const response = await Axios.get(`${p_reminder_nofication}`, { params })
			if (response?.status === 200) {
				dispatch(pharmacyPatientNotificationsSuccess(response?.data, type))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyPatientNotificationsFailure(error?.response, type))
			}
		}
	}
}

export const pharmacyPreviewPdf = (path, formData) => {
	return async (dispatch) => {
		dispatch(pdf.previewPdfRequest())
		try {
			const params = {
				exportpdf: true,
			}
			if (formData.route) {
				params.route = formData?.route
			}
			if (formData.search) {
				params.search = formData?.search
			}
			const response = await Axios.get(`${path}`, { params })
			if (response?.status === 200) {
				dispatch(pdf.previewPdfSuccess(response?.data))
				PreviewPdf(response?.data?.data)

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pdf.previewPdfFailure(error?.response?.data))
			}
		}
	}
}