import React, { useState, useEffect } from 'react'

import {
	BreadCrumb,
	Footer,
	Sidebar
} from '../../../components/common'

import { useCustomDispatch } from '../../../hooks'
import { Errors } from '../../../components/error/alertMessages'
import { Loader } from '../../../components/common/loadingView'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'

import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'
import { faxFormFailure } from '../../../services/actions/pharmacy/forms/pharmacyUpdateNotificationsSrFaxForm'
import { currentDate } from '../../../utils/constants/formInitialStates'

import ChatBox from '../../../components/chat/chatbox'

import * as Pharmacy from '../../../components/pharmacy/form/form'
import * as path from '../../../networking/urlEndPoints'
import * as forms from '../../../services/actions/pharmacy/forms'


const rationalData = [
	"I am aware that the accidental ingestion of even a small amount of my medication by a child or other person who is not a regular user could result in overdose or death.",
	"I will store my medication in a safe, locked location that cannot be accessed by other people or by pets.",
	"I will not sell or share my medication with another person. I understand that doing so is dangerous and may lead to loss of access to take-home doses or removal from the program.",
	"I will provide a urine sample within 24 hours of being asked. If I do not provide a sample as requested, or illicit drugs are found in my sample, I may lose access to take-home doses.",

	"I will bring my medication to my clinic or pharmacy within 24 hours if asked to do so. If I do not, I may lose access to take-home doses and have to return to daily witnessed ingestion.",
	'I am aware that I need to always bring my medication to all of my medical appointments for assessment by clinic staff. If I do not do this as requested, I may lose access to take-home doses and have to return to daily witnessed ingestion.',
	'I understand that I must be able to meet the above requirements to receive take-home doses. If my situation changes and I can no longer meet them I may lose access to take-home doses.'
];

const PatientAgreementForTakeHomeDosing = () => {

	const dispatch = useCustomDispatch()
	const { previewPdfResponse } = useFormsSelectors()

	const initalEmergencyFormData = {
		patientName: '',
		// date: new Date().toISOString().split('T')[0],
		date: currentDate,
		rational: []
	}

	const [formData, setFormData] = useState(initalEmergencyFormData)
	const [errorMessages, setErrorMessages] = useState(initalEmergencyFormData)

	const handleFormDataChange = (event) => {
		const { name, checked, value } = event.target;

		setFormData((prevValues) => {
			if (event.target.type === 'checkbox') {
				const rational = Array.isArray(prevValues.rational)
					? prevValues.rational.slice()
					: [];

				if (checked) {
					rational.push(parseInt(value, 10));
				} else {
					const index = rational.indexOf(parseInt(value, 10));
					if (index !== -1) {
						rational.splice(index, 1);
					}
				}
				return {
					...prevValues,
					rational: rational,
				};
			} else {
				return {
					...prevValues,
					[name]: value,
				};
			}
		});
	}

	const handleSubmitFormData = () => {
		const requestedData = {
			rational: formData.rational,
			date: formData.date,
			patient: formData.patientName.value
		}
		if (!formData.patientName) {
			setErrorMessages({
				...errorMessages,
				patientName: 'Patient is required'
			})
		} else {
			setErrorMessages({
				...errorMessages,
				patientName: ''
			})
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_patient_agreement_for_dosing, requestedData))
		}
	}

	useEffect(() => {
		dispatch(previewPdfFailure(null))
		dispatch(faxFormFailure(null))
	}, [])

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">

									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<Pharmacy.Date setFormData={setFormData} formData={formData} handleFormDataChange={handleFormDataChange} />
											<Pharmacy.PatientList setFormData={setFormData} formData={formData}
												setErrorMessages={setErrorMessages}
												errorMessages={errorMessages} />
										</div>


										<div className="grid grid-cols-12 gap-2 w-full mt-4 ">
											<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 ">
												{rationalData?.map((label, index) => (
													<div key={index} className="flex items-center mb-4 gap-3">
														<input id={`checkbox-${index}`} name="rational" type="checkbox"
															value={index + 1} checked={formData?.rational?.includes(index + 1)}
															onChange={handleFormDataChange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
														<label htmlFor={`checkbox-${index}`} className="fs-16 rubik-400 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
															{label}
														</label>
													</div>
												))}
											</div>
										</div>
										<br />

										{previewPdfResponse?.error !== null &&
											<>
												<br />
												<Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
											</>
										}

										<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2  mb-0 block text-left">
											<button style={{ height: '40px' }} type="button"

												onClick={handleSubmitFormData}

												className="ti_heightScheduleInnerDateBtn blueBg-color sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

												{previewPdfResponse?.loading ? <>
													Genrating PDF
													&nbsp;<Loader /> </> : 'Preview PDF'}
											</button>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
			<ChatBox />
			<Footer />

		</>
	)
}

export default PatientAgreementForTakeHomeDosing