import React, { useEffect } from "react";
import { authToken } from "../storage/authToken";
import { useCustomNavigate } from "../hooks";

const ProtectedRoute = (props) => {

	const { Component } = props

	const token = authToken()
	const navigate = useCustomNavigate()

	useEffect(() => {
		if (!token) {
			navigate('/')
		}
	})

	return (<Component />)
};

export default ProtectedRoute;
