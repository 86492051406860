
import React, { useState, useEffect } from 'react';
import { addUserActivityStatus } from '../../services/actions/chat/activityStatusActions';
import { useCustomDispatch } from '../../hooks';
import { authToken } from '../../storage/authToken';
import { subscribeUserStatus } from '../../utils/miscellaneous/ably';
import { useCustomSelectors } from '../../services/selectors/allSelectors';

const ActivityTracker = () => {

	let idleTimer;

	const dispatch = useCustomDispatch()
	const { pharmacyId } = useCustomSelectors()
	const token = authToken()

	const [activityStatus, setActivityStatus] = useState('online');

	const resetIdleTimer = () => {
		clearTimeout(idleTimer);
		idleTimer = setTimeout(() => {
			setActivityStatus('idle');
		}, 2 * 60 * 1000)
	};

	const handleActivity = () => {
		setActivityStatus('online');
		resetIdleTimer();
	};

	useEffect(() => {
		window.removeEventListener('mousemove', handleActivity)
		window.addEventListener('mousedown', handleActivity);
		window.addEventListener('click', handleActivity);
		window.addEventListener('keydown', handleActivity);

		resetIdleTimer();

		return () => {
			window.removeEventListener('mousemove', handleActivity)
			window.removeEventListener('mousedown', handleActivity);
			window.removeEventListener('click', handleActivity);
			window.removeEventListener('keydown', handleActivity);
			clearTimeout(idleTimer);
		};
	}, []);

	useEffect(() => {
		const offlineTimer = setTimeout(() => {
			setActivityStatus('offline');
		}, 5 * 60 * 1000)

		return () => {
			clearTimeout(offlineTimer);
		};
	}, [activityStatus])

	useEffect(() => {
		const activityInterval = {
			online: 1 * 60 * 1000,
			idle: 2 * 60 * 1000,
			offline: 5 * 60 * 1000,
		};

		const intervalId = setInterval(() => {
			if (token && activityStatus && activityStatus !== 'offline') {
				dispatch(addUserActivityStatus(activityStatus));
			}
		}, activityInterval[activityStatus]);

		return () => clearInterval(intervalId);
	}, [activityStatus, dispatch, token]);


	useEffect(() => {
		const ablyConnection = subscribeUserStatus(pharmacyId, dispatch)
		return () => {
			ablyConnection.close();
		};

	}, [pharmacyId])

	return null

};

export default ActivityTracker



