import {
	SEND_FAX_REQUEST,
	SEND_FAX_SUCCESS,
	SEND_FAX_FAILURE
} from "../../../constants"

import {
	showToastMessage,
	hideToastMessage
} from "../../../../assets/js/custome"

import Axios from "../../../../networking/intraceptor"

export const faxFormRequest = () => ({
	type: SEND_FAX_REQUEST,
})

export const faxFormSuccess = (data) => ({
	type: SEND_FAX_SUCCESS,
	payload: data,
})

export const faxFormFailure = (error) => ({
	type: SEND_FAX_FAILURE,
	payload: error,
})

export const pharmacySendFax = (path, requestedData, showToast, setShowToast) => {
	return async (dispatch) => {
		dispatch(faxFormRequest())
		try {
			const response = await Axios.post(`${path}`, requestedData)
			if (response?.status === 200) {
				dispatch(faxFormSuccess(response?.data))
				showToastMessage(showToast, setShowToast)

				setTimeout(() => {
					hideToastMessage(showToast, setShowToast)
				}, 2000)
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(faxFormFailure(error?.response?.data))
			}
		}
	}
}