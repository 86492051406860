import React from 'react'

export const CommentListLoader = ({ commentListLoader }) => {
	return (
		<React.Fragment>
			{
				commentListLoader?.map((value, index) => {
					return (
						<div className="p-4 pt-0 flex items-center gap-6" key={index}>
							<div className="block w-full p-2 bg-light border border-gray-200 rounded  dark:bg-gray-800 dark:border-gray-700">
								<div className="flex gap-3">
									<svg className="w-8 h-8 text-gray-200 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
										<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
									</svg>
									<div>
										<div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
										<div className="w-48 h-3 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></div>
									</div>
									<div className="h-4 bg-gray-300 ml-auto rounded-full dark:bg-gray-700 w-12"></div>
								</div>
							</div>
						</div>
					)
				})
			}
		</React.Fragment>
	)
}


export const CommentReplyListLoading = ({ commentReplyLoading }) => {
	return (<>
		{
			commentReplyLoading?.map((value, index) => {
				return (
					<div className="px-4  pt-0" key={index}>
						<div className="ot_allPostReply">
							<div className="borderBefore">
							</div>
							<div className="bottomBorder">
							</div>

							<div style={{ position: 'relative', bottom: '-70px' }}>
								<div className="block w-full p-1 mb-1 bg-light border border-gray-200 rounded  dark:bg-gray-800 dark:border-gray-700">
									<div className="flex gap-3">
										<div className="flex gap-3">
											<svg className="w-8 h-8 text-gray-200 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
												<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
											</svg>
											<div>
												<div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
												<div className="w-48 h-3 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></div>
											</div>
											<div className="h-4 bg-gray-300 ml-auto rounded-full dark:bg-gray-700 w-12" style={{ position: 'absolute', right: '6px' }}></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			})
		}
	</>)
}