import { useSelector } from 'react-redux'

export const useFormsSelectors = () => {

	const updateNoficationSRFaxResponse = useSelector(state => state.updateNoficationSRFax)
	const pharmaciesListResponse = useSelector(state => state.pharmaciesList)
	const RxTransferPdfResponse = useSelector(state => state.RxTransferPdf)
	const sendFaxResponse = useSelector(state => state.sendFax)
	const previewPdfResponse = useSelector(state => state.pharmacyPreviewFormPdf)
	const updateStatusRespons = useSelector(state => state.oatUpdateData)
	return {
		updateNoficationSRFaxResponse,
		pharmaciesListResponse,
		RxTransferPdfResponse,
		sendFaxResponse,
		previewPdfResponse,
		updateStatusRespons
	}
}
