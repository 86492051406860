import {
	FETCH_EMPLOYEES_SCHEDULE_REQUEST,
	FETCH_EMPLOYEES_SCHEDULE_SUCCESS,
	FETCH_EMPLOYEES_SCHEDULE_FAILURE
} from "../../constants";

const initialState = {
	data: [],
	loading: false,
	error: null,
};

const employeeSheuduleReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_EMPLOYEES_SCHEDULE_REQUEST:
			return {
				...state,
				loading: true,
				error: null
			};

		case FETCH_EMPLOYEES_SCHEDULE_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false,
				error: null,
			};

		case FETCH_EMPLOYEES_SCHEDULE_FAILURE:
			return {
				...state,
				data: [],
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};

export default employeeSheuduleReducer;
