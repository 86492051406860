import React, {
	useEffect,
	useRef,
	useState,
	useCallback
} from "react";

import {
	BreadCrumb,
	Footer,
	LoadingView,
	Sidebar
} from "../../../components/common";

import { ErrorMessages } from "../../../components/error/alertMessages";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import { useCustomSelectors } from "../../../services/selectors/allSelectors";

import { Errors } from "../../../components/error/alertMessages";
import { SuccessToastMessage } from "../../../components/loaders/toastMessage";

import { fetchPatientNotesList } from '../../../services/actions/patients/patientNotesList';
import { pharmacyPreviewPdf } from "../../../services/actions/pharmacy/other/pharmacyPatientNotifications";
import { useFormsSelectors } from "../../../services/selectors/formsSelector";
import { useCustomPatientSelectors } from '../../../services/selectors/patientSelectors';
import { UpdateNotes } from "../../../services/actions/patients/savePatientNote";

import Select from 'react-select';
import ChatBox from "../../../components/chat/chatbox";

import * as Hooks from '../../../hooks';
import * as path from '../../../networking/urlEndPoints';
import * as pharmacyTeamNotes from '../../../services/actions/pharmacy/other';
import * as styles from '../../../style';
import * as Helper from '../../../utils/helper/helper';
import * as useEffectHelper from '../../../utils/helper/useEffectsHelper';

export const isDarkColor = (color) => {
	const hex = color.replace('#', '');

	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

	return luminance < 0.5;
};

const AllNotifications = () => {

	const textareaRef = useRef(null);
	const startDateRef = useRef()
	const endDateRef = useRef()

	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();
	const location = Hooks.useCustomLocation();
	const [apiCallMade, setApiCallMade] = useState(false)

	const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

	const { patientListNotificationsResponse,
		patientNotificationsList,
		pharmacyPatientAddNoteReply } = useCustomSelectors();

	const { previewPdfResponse, } = useFormsSelectors()

	const { patientListNotesData,
		patientListNotesResponse
	} = useCustomPatientSelectors()


	const [addTeamNoteData, setAddTeamNoteData] = useState({
		notificationIndex: null,
		pageNumber: initialPage,
		addReply: '',
		showToast: false,
		initalListLoading: false,
		notificationId: null,
		tooltipVisible: false,
		startDate: null,
		endDate: null,
		selectedNoteOptions: null,
		patientNotesData: [],
		search: ''
	});

	const handlePetientNotesList = () => {
		// if (patientListNotesResponse?.data === null) {
		dispatch(fetchPatientNotesList('fetchPatientNotificationsList'))
		// }
	}
	const [color, setColor] = useState('#FFFFFF')

	useEffect(() => {
		setAddTeamNoteData({ ...addTeamNoteData, pageNumber: initialPage })
	}, [initialPage])

	useEffectHelper.useNotificationReplyScrollBarEffect(textareaRef,
		addTeamNoteData,
		setAddTeamNoteData,
		patientListNotesData,
		patientNotificationsList)

	const handleFilterNotesList = () => {
		if (addTeamNoteData.search || addTeamNoteData.startDate || addTeamNoteData.endDate || addTeamNoteData.selectedNoteOptions)
			dispatch(pharmacyTeamNotes.fetchPatientNotifications(null,
				setAddTeamNoteData,
				addTeamNoteData))
	}

	useEffect(() => {
		if (!apiCallMade) {
			dispatch(pharmacyTeamNotes.fetchPatientNotifications(addTeamNoteData?.pageNumber,
				setAddTeamNoteData,
				addTeamNoteData))
			setApiCallMade(true)
		}
	}, [])

	useEffectHelper.useDateRangePickerEffect(addTeamNoteData,
		setAddTeamNoteData,
		startDateRef,
		endDateRef)

	const debounce = (func, delay) => {
		let timeoutId;
		return (...args) => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				func(...args);
			}, delay);
		};
	};

	const debouncedDispatch = useCallback(
		debounce((patientid, value) => {
			dispatch(UpdateNotes(patientid,
				addTeamNoteData.pageNumber,
				value,
				null))
		}, 2000),
		[]
	);

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">

									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 gap-4 allNotificationSearchBg">
											<div className="relative">
												<input type="text" id="search" name='search' onChange={(e) => setAddTeamNoteData({ ...addTeamNoteData, search: e.target.value })}
													className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white  
														`} placeholder="" />
												<label htmlFor="search"
													className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
													Search
												</label>
											</div>

											{/* <DateRangePicker startDateRef={startDateRef} endDateRef={endDateRef} /> */}

											<div className="relative z-10" onClick={handlePetientNotesList} >
												{patientListNotificationsResponse.type === 'fetchPatientNotifications' &&

													<Select
														isClearable={false}
														options={addTeamNoteData?.patientNotesData}
														isSearchable={false}
														defaultValue={addTeamNoteData?.selectedNoteOptions}
														onChange={(selectedValues) => {
															setAddTeamNoteData({
																...addTeamNoteData,
																selectedNoteOptions: selectedValues?.label
															})
														}}
														className="basic-multi-select z-30"
														classNamePrefix="react-select"
														styles={styles.customPatientListStyle}
														placeholder='Note Option'
														noOptionsMessage={() => patientListNotesResponse?.loading ? 'Loading...' : 'No options'}
													/>

												}


											</div>

											<div className="relative  flex">
												{/* <p className="text-left mr-auto fs-12 text-gray-500 rubik-500">Search by PHN, Route, Patient name</p> */}
												<div className="flex justify-end">
													<button type="button" onClick={handleFilterNotesList}
														className="flex py-4 items-center justify-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
														Filter
													</button>
													{patientNotificationsList?.data?.length > 0 &&
														<button type="button"
															onClick={() => dispatch(pharmacyPreviewPdf(path.pharmacy_patient_notification_list))}
															className="blueBg-color hover:blueBg-color ml-2 flex py-4 w-44 items-center justify-center text-white bg-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white">
															{previewPdfResponse?.loading ?
																<p className='flex'>Genrating PDF...</p>
																:
																'Export PDF'
															}

														</button>}
												</div>
											</div>
										</div>
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mt-2">
											<div className="">

												{patientListNotificationsResponse?.loading && patientListNotificationsResponse.type === 'fetchPatientNotifications' && addTeamNoteData?.initalListLoading ?
													<div className="flex items-center justify-center  mt-60 mb-80">
														{/* <NotificationsListLoader /> */}
														<LoadingView />
													</div>
													: patientListNotificationsResponse?.error !== null && patientListNotificationsResponse.type === 'fetchPatientNotifications' ?
														<ErrorMessages />
														: patientNotificationsList?.data?.length <= 0 && patientListNotificationsResponse.type === 'fetchPatientNotifications' ?
															<h2>There are currently no notes available</h2> :
															patientListNotificationsResponse.type === 'fetchPatientNotifications' ?
																<div className="shadow-md border rounded-lg relative overflow-x-auto">
																	<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
																		<thead className="text-xs text-white uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400">
																			<tr>
																				<th scope="col" className="px-6 py-3 rounded-s-lg">
																					<p className="rubik-500 fs-14">Patient</p>
																				</th>
																				<th scope="col" className="px-6 py-3">
																					<p className="rubik-500 fs-14">Note</p>
																				</th>

																				<th scope="col" className="px-6 py-3">
																					<p className="rubik-500 fs-14">Date</p>
																				</th>

																				<th scope="col" className="px-6 py-3 rounded-e-lg">
																					<p className="rubik-500 fs-14">Added By</p>
																				</th>
																				<th scope="col" className="px-6 py-3 rounded-e-lg">
																					<p className="rubik-500 fs-14">Replies</p>
																				</th>
																			</tr>
																		</thead>

																		<tbody>
																			{patientNotificationsList?.data?.map((value, index) => {
																				const date = value?.created_at;
																				const textColor = value.color && isDarkColor(value.color) ? 'white' : 'black';
																				return (<React.Fragment key={index}>

																					<tr className="bg-white dark:bg-gray-800 border-b dark:border-gray-700" style={{ background: value.color, color: textColor }}>

																						<th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" style={{ color: textColor }}>
																							<p className="rubik-500 fs-14">{value?.patient_name}</p>
																							{value.route && <p className="rubik-500 fs-14">Route:{value?.route}</p>}
																							<p className="rubik-400 fs-14">PHN:{value?.phn}</p>
																						</th>

																						<td className="px-6 py-4">
																							<p className="rubik-400 fs-14">{value?.notes}</p>
																						</td>

																						<td className="px-6 py-4">
																							<p className="rubik-400 fs-14">{value?.date}</p>
																						</td>

																						<td className="px-6 py-4">
																							<p className="rubik-400 fs-14">{value?.added_by}</p>
																						</td>

																						<td className="px-6 py-4 flex">
																							<span onClick={(() => setAddTeamNoteData({ ...addTeamNoteData, notificationIndex: index, addReply: '' }))}
																								className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
																								<p className="btnnn"> {value?.reply?.length}</p>
																							</span>

																							<label className="ml-2">
																								<input
																									type="color"
																									defaultValue={value.color ? value.color : '#FFFFFF'}
																									// onChange={(event) => )}
																										className="w-6"
																									onChange={(e) => {
																										debouncedDispatch(value.note_id,
																											e.target.value,
																											null);
																									}}
																								/>
																							</label>

																						</td>

																					</tr>

																					{addTeamNoteData?.notificationIndex === index &&
																						<><tr className="w-full">
																							<td colSpan="5">
																								{Array.isArray(value.reply) ? (
																									value.reply.map((data, index) => {
																										const dateTime = new Date(data?.date_time);
																										const formattedDateTime = `@ ${dateTime.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })} on ${dateTime.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`;

																										return (
																											<React.Fragment key={index}>

																												{formattedDateTime && (
																													<div className="mt-2 mb-2 ml-6 borderLeftBgGreen p-3 bg-white rounded-md pl-2">
																														<p className="rubik-400 fs-14">{formattedDateTime}</p>
																														<p className="rubik-400 fs-14">{`${data?.added_by}: ${data?.notes}`}</p>

																														{/* {value.reply.map((reply, index) => (
																															<React.Fragment key={`reply-${index}`}>
																																<p className="rubik-400 fs-14">{`${reply?.added_by}: ${reply?.notes}`}</p>
																															</React.Fragment>
																														))} */}
																													</div>
																												)}
																											</React.Fragment>
																										);
																									})
																								) : (
																									<p>No replies found.</p>
																								)}

																								<div className="lightBgColor p-2 rounded-md mt-2 mb-2">
																									<textarea key={index} id={`message_${index}`} ref={textareaRef} rows="5" value={addTeamNoteData?.addReply}
																										onChange={(e) => setAddTeamNoteData({ ...addTeamNoteData, addReply: e.target.value })}
																										className="scrollToTop rubik-400 fs-16 resize-none block p-2.5 w-full text-sm text-gray-900 bg-white rounded-md 
																												border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 
																												dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
																										placeholder="Type your reply here...">
																									</textarea>

																									<div className="mt-2">
																										{pharmacyPatientAddNoteReply?.error !== null &&
																											<Errors error='Note not added please try again.' />}
																										<button
																											onClick={() => Helper.handalPatientNotesreply(value,
																												addTeamNoteData,
																												setAddTeamNoteData({ ...addTeamNoteData, initalListLoading: false }),
																												dispatch)}
																											type="button"
																											className="rubik-500 text-white fs-14 blueBg-color rounded-md text-sm px-3 py-1.5 mr-1">
																											{pharmacyPatientAddNoteReply?.loading ?
																												<p className='flex'>Adding...</p>
																												:
																												'Add Reply'
																											}

																										</button>
																										<button onClick={() => setAddTeamNoteData({ addReply: '' })} type="button"
																											className="rubik-500 text-white fs-14 redBg-color rounded-md text-sm px-3 py-1.5">
																											Cancel
																										</button>
																									</div>
																								</div>
																							</td>
																						</tr>
																						</>
																					}
																				</React.Fragment>)
																			})}
																		</tbody>
																	</table>

																	{patientListNotificationsResponse?.error !== null ? null : <>
																		{patientNotificationsList?.data?.length !== 0 && <>
																			{patientNotificationsList?.pagination?.last_page !== 1 &&
																				<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
																					<nav aria-label="Page navigation example">
																						<ul className="inline-flex -space-x-px text-base h-10">
																							<CustomPagination
																								count={patientNotificationsList?.pagination?.last_page}
																								page={addTeamNoteData?.pageNumber}
																								onChange={(event, value) => {
																									setAddTeamNoteData({
																										...addTeamNoteData,
																										pageNumber: initialPage,
																										initalListLoading: true,
																										notificationIndex: null
																									});
																									dispatch(pharmacyTeamNotes.fetchPatientNotifications(value))
																									const searchParams = new URLSearchParams();
																									searchParams.append('page', value);
																									const newUrl = `${location?.pathname}?${searchParams.toString()}`;
																									navigate(newUrl);
																								}}
																							/>
																						</ul>
																					</nav>
																				</div>
																			}
																		</>}
																	</>}
																</div> : null}


											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ChatBox />
			<Footer />

			{addTeamNoteData.showToast && <SuccessToastMessage status={'Note Reply added successfully'} />}
		</>
	)
}

export default AllNotifications;