import {
    ADD_BLISTER_PACK_REQUEST,
    ADD_BLISTER_PACK_SUCCESS,
    ADD_BLISTER_PACK_FAILURE
} from "../../../constants"

const initialState = {
    data: null,
    loading: false,
    error: null,
    type: null,
};

const AddBlisterPackReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_BLISTER_PACK_REQUEST:
            return {
                ...state,
                loading: true,
                data: null,
                error: null,
                type: action.types,
            };
        case ADD_BLISTER_PACK_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                type: action.types,
                error: null,
            };
        case ADD_BLISTER_PACK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                type: action.types,
                data: null,
            };
        default:
            return state;
    }
};

export default AddBlisterPackReducer;
