import { useSelector } from 'react-redux'

export const useInventorySelector = () => {

    const addInventoryResponse = useSelector(state => state.addInventory)
    const InventoryListResponse = useSelector(state => state.InventoryList)

    return {
        addInventoryResponse,
        InventoryListResponse
    }
}
