import {
	ADD_MESSAGES_REQUEST,
	ADD_MESSAGES_SUCCESS,
	ADD_MESSAGES_FAILURE,
} from '../../constants/index';

const initialState = {
	messages: null,
	loading: false,
	error: null,
};


const addMessageReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_MESSAGES_REQUEST:
			return {
				...state,
				loading: true,
				messages: null,
				error: null
			};
		case ADD_MESSAGES_SUCCESS:
			return {
				...state,
				loading: false,
				messages: action.payload,
				error: null,
			};
		case ADD_MESSAGES_FAILURE:
			return {
				...state,
				loading: false,
				messages: null,
				error: action.payload,
			};
		default:
			return state;
	}
}

export default addMessageReducer;
