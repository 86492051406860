import React, {
	useEffect,
	useState,
	useRef
} from "react";

import {
	handleHideModal,
	handleShowModal
} from "../../assets/js/custome";

import { pharmacy_add_new_compound } from "../../networking/urlEndPoints";
import { useCustomSelectors } from "../../services/selectors/allSelectors";

import { Loader } from "../../components/common/loadingView";
import { SuccessToastMessage } from "../../components/loaders/toastMessage";
import { useRemoveModalEffect } from "../../utils/helper/useEffectsHelper";
import { PlusIcon } from "../../assets/svg/icons";

import * as Hooks from "../../hooks";
import * as modalCompound from "../../services/actions/pharmacy/other";

export const AddCompound = () => {

	const modalRef = useRef()

	const [showModal, setShowModal] = useState({ compoundModal: false, showToast: false });
	const [formData, setFormData] = useState({ addCompound: "" });
	const [errorMessages, setErrorMessages] = useState({ addCompound: "" });

	const { pharmacyAddDataResponse } = useCustomSelectors();

	const dispatch = Hooks.useCustomDispatch();

	const handleFormDataChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
		setErrorMessages({ ...errorMessages, addCompound: "" });
	};

	const requestedData = {
		name: formData.addCompound,
	};

	useEffect(() => {
		if (pharmacyAddDataResponse?.loading) {
			setErrorMessages({ ...errorMessages, addCompound: "" });
		} else if (pharmacyAddDataResponse !== null) {
			setErrorMessages({
				...errorMessages,
				addCompound: pharmacyAddDataResponse?.error?.data?.data?.name?.[0],
			});
		}
	}, [pharmacyAddDataResponse]);

	useRemoveModalEffect(setShowModal, showModal, 'compoundModal', modalRef)

	return (
		<React.Fragment>
			<button type="button" onClick={() => {
				return (setErrorMessages({ ...errorMessages, addCompound: "" }),
					setFormData({ ...formData, addCompound: "" }),
					handleShowModal(setShowModal, showModal, "compoundModal")
				);
			}}
				className="blueBg-color hover:blueBg-color ml-2 h-12 flex py-4 items-center justify-center text-white bg-white font-medium rounded-lg 
				text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white">
				<PlusIcon color="white" style={{ paddingRight: 10 }} /> Add Compound
			</button>

			{showModal.compoundModal && (
				<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center 
						justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
					<div className="relative w-full max-w-md max-h-full" ref={modalRef}>
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<button onClick={() => handleHideModal(setShowModal, showModal, "compoundModal")}
								type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto 
								inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
								<svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
								</svg>
								<span className="sr-only">Close modal</span>
							</button>

							<h1 className="rubik-500 pl-4 pt-4">Add Compound</h1>

							<div className="px-4 pt-4">
								<div className="relative">
									<input
										type="text"
										id="addCompound"
										onChange={handleFormDataChange}
										name="addCompound"
										value={formData.addCompound}
										className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 
										appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 
										peer cursor-text bg-white ${errorMessages.addCompound && "border-red-600"}`} placeholder="" />
									<label htmlFor="addCompound" className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  
										origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
										peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 
										rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
										{!errorMessages.addCompound && "Compound Name"}
									</label>
									{errorMessages.addCompound && (
										<p className="mt-2 text-xs text-red-600 dark:text-red-400">
											{errorMessages.addCompound}
										</p>
									)}
								</div>
							</div>
							<div className="p-6 text-center">
								<button
									style={{ backgroundColor: "#3A52BB" }}
									onClick={() => {
										if (!formData.addCompound) {
											setErrorMessages({
												...errorMessages,
												addCompound: "Please Enter the Name",
											});
										} else {
											setErrorMessages({ ...errorMessages, addCompound: "" });
											dispatch(
												modalCompound.pharmacyAddData(
													pharmacy_add_new_compound,
													requestedData,
													showModal,
													setShowModal,
													"pharmacy-compound"
												)
											);
										}
									}}
									type="button"
									className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 
    								dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
								>
									{pharmacyAddDataResponse?.loading ? (
										<p className="flex">
											Adding... &nbsp;
											<Loader />
										</p>
									) : (
										"Add"
									)}
								</button>

								<button onClick={() =>
									handleHideModal(setShowModal, showModal, "compoundModal")
								}
									type="button"
									className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
			{showModal?.showToast && (
				<SuccessToastMessage status={"Compound Added Successfully"} />
			)}
		</React.Fragment>
	);
};
