import React, {
	useCallback,
	useState,
	useRef
} from 'react'

import { debounce } from 'lodash'
import { fetchPatientsList } from '../../services/actions/patients/patientsList'
import { useCustomPatientSelectors } from '../../services/selectors/patientSelectors'

import { fetchAllClinics } from '../../services/actions/pharmacy/clinic/getClinics'
import { fetchAllPharmacyLists } from '../../services/actions/pharmacy/forms/pharmacyList'
import { removeWorkSpaceTab } from '../../utils/helper/helper'
import { useFormsSelectors } from '../../services/selectors/formsSelector'
import { TopHeaderSearchStyle } from '../../style'

import { AddNotes } from '../../models/patients'
import { useCustomLoadingStates } from '../../hooks/customStates'
import { openPatientNoteModal } from '../../assets/js/custome'
import { ErrorToastMessage, SuccessToastMessage } from '../loaders/toastMessage'
import { patientNotesListFailure, patientNotesListSuccess } from '../../services/actions/patients/patientNotesList'

import isEqual from 'lodash/isEqual';
import Select from 'react-select'
import Cookies from 'js-cookie'

import * as Icons from '../../assets/svg/icons'
import * as Hooks from '../../hooks'
import * as routesNames from '../../routes/routeName'
import * as useEffectHelper from '../../utils/helper/useEffectsHelper'

export const SearchBar = () => {

	const inputRef = useRef(null);

	const location = Hooks.useCustomLocation()
	const navigate = Hooks.useCustomNavigate()
	const dispatch = Hooks.useCustomDispatch()

	const [searchValue, setSearchValue] = useState('')
	const [data, setData] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState()
	const [showList, setShowList] = useState(true);

	const { getClinicsData,
		getAllClinicsResponse } = useCustomPatientSelectors()

	const { pharmaciesListResponse } = useFormsSelectors()

	const {
		showToast, setShowToast,
		showModal, setShowModal
	} = useCustomLoadingStates()

	const handleInputChange = useCallback(
		debounce((e) => {
			e.preventDefault()
			const search = e.target.value;
			setShowList(true)
			setSearchValue(search)
			const queryStatus = new URLSearchParams(location.search).get('status');
			const page = search ? '' : parseInt(new URLSearchParams(location.search).get('page')) || 1;

			const searchParams = new URLSearchParams();

			if (queryStatus !== null) {
				searchParams.append('status', queryStatus);
			}

			if (page !== '') {
				searchParams.append('page', page);
			}

			if (search) {
				searchParams.append('search', search);
			}

			if (selectedOptions?.label === 'Patient') {
				const newUrl = `${routesNames.ALL_PATIENTS}?${searchParams.toString()}`;
				navigate(newUrl)
				Cookies.set('work_tab', 0)
				dispatch(fetchPatientsList(search, queryStatus, page));
			}

			if (selectedOptions?.label === 'Clinic' && search.length >= 3) {
				dispatch(fetchAllClinics(search))
				removeWorkSpaceTab()
			}

			if (selectedOptions?.label === 'Pharmacy' && search.length >= 3) {
				dispatch(fetchAllPharmacyLists(search))
				removeWorkSpaceTab()
			}

		}, 1000),
		[dispatch, location.search, navigate])

	useEffectHelper.useTopHeaderSearchEffect(inputRef, data, setSelectedOptions, setData)

	return (<>
		<div className="inline-block Oat_HeaderSeachBg flex relative md:hidden sm:hidden lg:block hidden">
			<div className='flex'>
				<Select
					isClearable={false}
					isSearchable={false}
					className="basic-multi-select z-30 h-11 rounded-md font-medium rounded-sm text-sm py-2.5 inline-flex items-center"
					classNamePrefix="react-select"
					placeholder='Pharmacy'
					options={data}
					value={selectedOptions}
					onChange={(selectedValues) => {
						if (!isEqual(selectedValues, selectedOptions)) {
							setSelectedOptions(selectedValues);
						}
					}}
					styles={TopHeaderSearchStyle} />

				<span style={{ position: 'absolute', left: '12rem' }}>
					<Icons.TopSearchIcon />
				</span>

				<input className="h-11 absolute shadow appearance-none border fs-18 rubik-400 rounded-lg w-96	 
									py-2  text-gray-700 leading-tight focus:outline-none focus:shadow-outline 
									focus:border-none"
					style={{
						position: 'absolute',
						height: '48px',
						top: '-2px',
						paddingLeft: '130px'
					}}
					type="text"
					ref={inputRef}
					placeholder="Search"
					onChange={handleInputChange} />

				<div className='flex items-center justify-center'>
					<button style={{ position: 'absolute', left: '400px' }} onClick={() => {
						return (openPatientNoteModal(setShowModal, showModal),
						dispatch(patientNotesListFailure(null, null)),
						dispatch(patientNotesListSuccess(null, null)))
					}} className="h-12 w-28 rounded-lg bg-transparent lg:bg-white bg-opacity-25 ot_HeadDropdwon text-white font-medium rounded-sm text-sm px-5 py-2.5 text-center inline-flex items-center justify-center" type="button">Add Note
					</button>
				</div>

			</div>

			{selectedOptions?.label === 'Clinic' && searchValue.length >= 3 && showList &&
				<div className="z-10 absolute top-10 left-60 mt-2 w-64 bg-white border border-gray-300 rounded-md shadow-lg"
					style={{ left: '127px', maxHeight: '200px', overflowY: 'auto' }}>
					{getAllClinicsResponse?.loading ?
						<div className="p-2 cursor-pointer">Searching...</div>
						:
						getClinicsData?.length === 0 ?
							<div className="p-2 cursor-pointer">No Result Found</div>
							:
							getAllClinicsResponse?.error !== null ?
								<div className="p-2 cursor-pointer">Some Error Occurred. Please search again.</div>
								:
								<div>
									{getClinicsData?.map((value, index) => (
										<React.Fragment key={index}>
											<div onClick={() => { return navigate(`${routesNames.pharmacy_search_module}?module=clinic&id=${value.id}`), setShowList(false) }} className="p-2 cursor-pointer">
												<p className='fs-14 rubik-400'>{value.name}</p>
												<p className='fs-12 pl-2 flex'><Icons.PhoneCallIcon /> {value?.phone}</p>
											</div>
										</React.Fragment>
									))}
								</div>
					}
				</div>
			}

			{selectedOptions?.label === 'Pharmacy' && searchValue.length >= 3 && showList &&
				<div className="z-10 absolute top-10 mt-2 w-64 bg-white border border-gray-300 rounded-md shadow-lg"
					style={{ left: '127px', maxHeight: '200px', overflowY: 'auto' }}>
					{pharmaciesListResponse?.loading ?
						<div className="p-2 cursor-pointer">Searching...</div>
						:
						pharmaciesListResponse?.data?.data?.length === 0 ?
							<div className="p-2 cursor-pointer">No Result Found</div>
							:
							pharmaciesListResponse?.error !== null ?
								<div className="p-2 cursor-pointer">Some Error Occurred. Please search again.</div>
								:
								<div>
									{pharmaciesListResponse?.data?.data?.map((value, index) => (
										<React.Fragment key={index}>
											<div onClick={() => { return navigate(`${routesNames.pharmacy_search_module}?module=pharmacies&id=${value.id}`), setShowList(false) }} className="p-2 cursor-pointer ">
												<p className='fs-14 rubik-400'>{value.name}</p>
												<p className='fs-12 pl-2 flex'>
													<Icons.PhoneCallIcon />
													{value?.phone}</p>
											</div>
										</React.Fragment>
									))}
								</div>
					}
				</div>
			}
		</div>

		{
			showModal.patientNoteModal &&
			<AddNotes
				showToast={showToast}
				setShowToast={setShowToast}
				setShowModal={setShowModal}
				showModal={showModal}
				type='Header_Add_Note'
			/>
		}

		{
			showToast?.patientNoteToast &&
			<SuccessToastMessage status='Note added sucessfully' />
		}


		{
			showToast?.patientNoteToastError &&
			<ErrorToastMessage />
		}

	</>
	)
}
