export const HOME_ROUTE = '/';
export const DASHBOARD_ROUTE = '/dashboard';
export const ONBOARDING_ROUTE = '/on-boarding';
export const APPRECIATIONS_ROUTE = '/appreciations';
export const TIMESHEET_ROUTE = '/time-sheet';

export const NEWREQUEST_ROUTE = '/new-request';
export const ALLREQUEST_ROUTE = '/timeoffrequest';
export const SETTINGS_ROUTE = '/settings';
export const SUCCESSFULL_ROUTE = '/thank-you';
export const ALLEMPLOYEES_ROUTE = '/all-employees';

export const EDIT_TIME_OFFR_REQUEST_ROUTE = '/edit-time-off-request';
export const CHAT_NOW = '/chat-now'
export const MY_PROFILE = '/my-profile';
export const ADD_PATIENTS = '/add-patient'

export const ALL_PATIENTS = '/pharmacy-patients'
export const PHARMACY_PRINT_OAT_LOG = '/pharmacy-patient-oat'
export const PHARMACY_WORK_SPACE = '/work-space'

export const PHARMACY_INFORMATION_UPDATE_FORM = '/pharmacy-information-update-form'
export const PHARMACY_PRESCRIPTION_TRASNSFER_FORM = '/pharmacy-prescription-transfer-form'
export const pharmacy_Emergency_Supply_OAT_medications_form = '/pharmacy-Emergency-Supply-OAT-medications-form'
export const pharmacy_medication_form = '/pharmacy-medication-form'
export const pharmacy_tb_skin_test_logs = '/pharmacy-tb-skin-test-logs'

export const missing_Rx_Requirements = '/pharmacy-missing-rx-requirement-form'
export const pharmacy_emergency_supply_continuity_form = '/pharmacy-emergency-supply-continuity-form'
export const patient_agreement_for_take_home_dosing = '/pharmacy-Patient-Agreement-Home-Dosing'
export const pharmacy_Plan_G_Form = '/pharmacy-Plan-G-Form'

export const pharmacy_Plan_Z_Form = '/pharmacy-Plan-Z-Form'
export const pharmacy_doctor_communication_form = '/pharmacy-doctor-communication-form'
export const pharmacy_Pharmacist_Prescriber_Communication = '/pharmacy-Pharmacist-Prescriber-Communication'

export const pharmacy_Team_Notes = '/pharmacy-team-notes'
export const pharmacy_original_prescription_form = '/pharmacy-original-prescription-form'

export const pharmacy_tb_skin_test_mantoux_form = '/pharmacy-tb-skin-test-mantoux-form'

export const pharmacy_search_module = '/search-result'
export const pharmacy_all_notifications = '/all-previous-notifications'
export const pharmacy_front_profile = '/pharmacy-profile'
export const pharmacy_file_manager = '/pharmacy-file-manager'

export const pharmacy_fax_log = '/pharmacy-fax-log'
export const pharmacy_break_parroll_settings = "/pharmacy-break-payroll-settings"
export const pharmacy_compounds = '/pharmacy-compound'
export const pharmacy_compound_agreement_form = '/pharmacy-compound-agreement-form'

export const pharmacy_compound_price_list = '/pharmacy-compound-price-list'
export const pharmacy_controlled_order_request = '/pharmacy-controlled-order-request'
export const pharmacy_order_compound_form = '/pharmacy-order-compound-form'
export const pharmacy_order_compounding_form = '/pharmacy-order-compounding-form'

export const pharmacy_create_manual_delivery = '/pharmacy-create-manual-delivery'
export const pharmacy_route_delivery = '/pharmacy-route-delivery'
export const pharmacy_driver = '/pharmacy-driver'
export const pharmacy_deliveries_supervisor = '/pharmacy-deliveries-supervisor'
export const pharmacy_delivery = '/pharmacy-delivery'
export const pharmacy_missed_deliveries = '/pharmacy-missed-deliveries'
export const pharmacy_archived_deliveries = '/pharmacy-archived-deliveries'
export const pharmacy_tranfer_patient_concent = '/pharmacy-tranfer-patient-concent'
export const pharmacy_sig_check_ai = '/pharmacy-sig-check-ai'

export const pharmacy_delivery_lables = '/pharmacy-delivery-lables'
export const pharmacy_recurrence_delivery = '/pharmacy-recurrence-delivery'
export const pharmacy_expiring_rx = '/pharmacy-expiring-rx'
export const pharmacy_drugs_billing = '/pharmacy-drugs-billing'
export const pharmacy_oat_prescriptions = '/pharmacy-oat-prescriptions'

export const pharmacy_patient_counselling = '/pharmacy-patient-counselling'

export const pharmacy_patient_blister_pack = '/pharmacy-add-patient-blister-pack'
export const pharmacy_blister_patients = '/pharmacy-patient-blister-pack'
export const pharmacy_blister_packs = '/pharmacy-blister-packs'
export const pharmacy_blister_daily_packs = '/daily-blister-pack'

export const pharmacy_blister_pack_on_shelf = '/pharmacy-blister-pack-on-shelf'

export const pharmacy_patient_vials_pack = '/pharmacy-patient-vials-pack'
export const pharmacy_add_vials_patient = '/pharmacy-add-vials-patient'

export const pharmacy_vials_packs = '/pharmacy-vials-packs'
export const pharmacy_dailyvials_packs = '/pharmacy-dailyvials-packs'

//Routes To Manager MEdications
export const pharmacy_add_medication_patients = '/pharmacy-add-medication-patients'
export const pharmacy_speciality_pack = '/pharmacy-speciality-pack'
export const pharmacy_speciality_medication_packs = '/pharmacy-speciality-medication-packs'
export const pharmacy_dailymedication_packs = '/pharmacy-dailymedication-packs'

//Routes To Manager Invoice
export const pharmacy_generate_invoice = '/generate-invoice'
export const pharmacy_previous_invoices = '/pharmacy-previous-invoices'

export const pharmacy_doctor_note_form = '/pharmacy-doctor-note-form'

export const pharmacy_consent_form_logs = '/pharmacy-consent-form-logs'


//Routes To Manager ARV
export const ADD_ARV = "/pharmacy-pateint-create-arv"
export const ARV_LIST = "/pharmacy-patient-arv"

export const pharmacy_memo = "/pharmacy-memo"
export const upload_prescription = "/pharmacy-upload-prescription"
export const pharmacy_uploaded_prescriptions_list = "/pharmacy-uploaded-prescriptions-list"

//Routes To Manager Revenue
export const pharmacy_daily_revenue = "/pharmacy-daily-revenue"
export const pharmacy_cash_change = "/pharmacy-cash-change"
export const pharmacy_deposit_info = "/pharmacy-deposit-info"
export const pharmacy_hand_cash = "/pharmacy-hand-cash"

//Routes To Manager FDA FORM

export const create_new_fda_form = "/pharmacy-frequent-dispensing-authorization"
export const all_generated_fda_form_list = "/pharmacy-frequent-dispensing-log"
export const pharmacy_expiring_frequent_dispense = "/pharmacy-expiring-frequent-dispense"
export const pharmacy_expired_frequent_dispense = "/pharmacy-expired-frequent-dispense"

//Routes To Manager Inventory
export const pharmacy_inventory_list = "/pharmacy-inventory-list"
export const pharmacy_total_reversed = "/pharmacy-total-reversed"
export const frequent_dispensing_authorization_list = "/frequent-dispensing-authorization-list"

// Routes To OAT FORM
export const pharmacy_patient_route = "/pharmacy-patient-route"
export const pharmacy_add_patient_route = '/pharmacy-add-patient-oat'
export const pharmacy_reminder_notes = '/pharmacy-reminder-notes'

// Travel Routes

export const pharmacy_add_travel_patient = '/pharmacy-add-travel-patient'
export const pharmacy_add_travel_pack = '/pharmacy-add-travel-pack'
export const pharmacy_travel_logs = '/pharmacy-travel-logs'
export const pharmacy_billing_due = '/pharmacy-billing-due'
export const pharmacy_travel_returning_patients = '/pharmacy-travel-returning-patients'

export const pharmacy_ongoing_prescritiptions = '/pharmacy-ongoing-prescritiptions'
export const pharmacy_returned_patches = '/pharmacy-returned-patches'

export const pharmacy_reversed_deliveries = "/pharmacy-reversed-deliveries"


// App Routes

export const pharmacy_order_transfer = '/pharmacy-order-transfer'
export const pharmacy_order_new = '/pharmacy-order-new'
export const pharmacy_view_order_details = '/pharmacy-order/view'
export const pharmacy_app_user_list = '/pharmacy-app-user-list'
export const pharmacy_app_announcements = '/pharmacy-app-announcements'
export const pharmacy_app_notification_list = '/pharmacy-app-notification-list'


//Admin Routes
export const pharmacy_timeoff = '/pharmacy-timeoff'
export const pharmacy_create_new_task = '/pharmacy-create-new-task'
export const pharmacy_tasks = '/pharmacy-tasks'
export const pharmacy_certificate_template = '/pharmacy-certificate-templates'
export const pharmacy_assign_certificate = '/pharmacy-assign-certificate'
export const pharmacy_assign_certificate_employee = '/pharmacy-assign-certificate-employee'
export const pharmacy_user = '/pharmacy-user'
export const pharmacy_settings = '/pharmacy-settings'
export const pharmacy_create_certificate = 'pharmacy-create-certificate'
export const pharmacy_team_avalibility = '/pharmacy-team-avalibility'
export const pharmacy_edit_certificate = 'pharmacy-edit-certificate'

//Team Routes
export const pharmacy_tasks_list = '/pharmacy-tasks-list'
export const pharmacy_team_attendance = '/pharmacy-team-attendance'
export const pharmacy_check_out_in = '/pharmacy-checkIn-out'

export const pharmacy_department = '/pharmacy-department'

export const pharmacy_holidays_calander = '/pharmacy-holidays-calander'
export const pharmacy_accountability = '/pharmacy-accountability'

export const pharmacy_pharmacist_signature_updation = '/pharmacy-pharmacist-signature'






























