import React, { useRef, useState } from "react";
import { useCustomDispatch } from "../../hooks";
import * as useEffectHelper from "../../utils/helper/useEffectsHelper";
import { handleHideModal } from "../../assets/js/custome";
import { ModalCloseIcon } from "../../assets/svg/icons";
import { handleDeleteARV } from "../../services/actions/pharmacy/arv/patientARVDelete";
import { useCustomPatientSelectors } from "../../services/selectors/patientSelectors";
import * as Pharmacy from "../../components/pharmacy/form/form";
import { onhandlePharmacyFormDataChange } from "../../utils/data/handleFormDataChange";
import { useFormsSelectors } from '../../services/selectors/formsSelector'
import { Loader } from '../../components/common/loadingView'
import { Errors } from '../../components/error/alertMessages'
import { SuccessToastMessage } from '../../components/loaders/toastMessage'
import {
    initalPharmacyInformationUpdateForm,
    initalShowToastMessage,
} from "../../utils/constants/formInitialStates";
import { TextInput } from "../../components/pharmacy/dispense/dispense";
import { handleARVFaxValidation } from "../../utils/validations/arvValidations";
import { useCustomSelectors } from "../../services/selectors/allSelectors";
import { handleDeleteReminderNotification } from "../../services/actions/pharmacy/notifications/notification";
export const DeleteReminderModalPopUp = ({
    setShowModal,
    showModal,
    remID,
    formData,
    pageNumber,
}) => {
    const modalRef = useRef(null);
    useEffectHelper.useRemoveModalEffect(
        setShowModal,
        showModal,
        "DeleteReminderModal",
        modalRef
    );
    const dispatch = useCustomDispatch();
    const { deleteARVResponse } = useCustomPatientSelectors();

    return (
        <>
            {showModal.DeleteReminderModal && (
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                >
                    <div ref={modalRef} className="relative w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                onClick={() =>
                                    handleHideModal(setShowModal, showModal, "DeleteReminderModal")
                                }
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                <ModalCloseIcon />
                            </button>

                            <div className="p-6 text-center pt-12">
                                <p className="rubik-500 text-gray-500">Are you sure?</p>
                                <p className="rubik-400 text-gray-500">
                                    You want to delete Reminder Notification?
                                </p>
                                <br />

                                <button
                                    onClick={() =>
                                        dispatch(
                                            handleDeleteReminderNotification(
                                                remID,
                                                "DeleteReminderModal",
                                                formData,
                                                pageNumber,
                                                setShowModal,
                                                showModal
                                            )
                                        )
                                    }
                                    style={{ backgroundColor: "#3A52BB" }}
                                    type="button"
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none 
												focus:ring-red-300 
                    							dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                >
                                    {deleteARVResponse?.loading ? "Processing..." : "Yes"}
                                </button>

                                <button
                                    onClick={() =>
                                        handleHideModal(setShowModal, showModal, "DeleteReminderModal")
                                    }
                                    type="button"
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                								border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                								dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
            )}
        </>
    );
};