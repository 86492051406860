import React, {
	useEffect,
	useRef,
	useState
} from 'react'

import {
	initalPharmacyInformationUpdateForm,
	initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import {
	BreadCrumb,
	Footer,
	Sidebar
} from '../../../components/common'

import { Loader } from '../../../components/common/loadingView'
import { Errors } from '../../../components/error/alertMessages'
import { useCustomDispatch } from '../../../hooks'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

import { onhandlePharmacyFormDataChange } from '../../../utils/data/handleFormDataChange'
import { handleDrNotificationForEmergencySupplyForm } from '../../../utils/validations/formsValidations'

import { initialPharmacyTransferDate } from '../../../assets/js/datepicker'
import { SuccessToastMessage } from '../../../components/loaders/toastMessage'
import { mediCationsData } from '../../../constants/forms'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'
import { mediCationStyle } from '../../../style'

import Select from 'react-select'
import ChatBox from '../../../components/chat/chatbox'

import * as Pharmacy from '../../../components/pharmacy/form/form'
import { faxFormFailure } from '../../../services/actions/pharmacy/forms/pharmacyUpdateNotificationsSrFaxForm'
import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'

const DrNotificationForEmergencySupply = () => {

	const dispatch = useCustomDispatch()
	const endDateRef = useRef()

	const { pharmacyLoginData } = useCustomSelectors()
	const { previewPdfResponse, sendFaxResponse } = useFormsSelectors()

	const [formData, setFormData] = useState(initalPharmacyInformationUpdateForm)
	const [errorMessages, setErrorMessages] = useState(initalPharmacyInformationUpdateForm)
	const [showToast, setShowToast] = useState(initalShowToastMessage)

	const [selectComponents, setSelectComponents] = useState([{ selectedOptions: null }]);
	const [mediCationData, setMedicationsData] = useState([]);

	const handleFormDataChange = (e) => {
		onhandlePharmacyFormDataChange(e, setFormData, formData, setErrorMessages, errorMessages)
	}

	useEffect(() => {
		if (pharmacyLoginData) {
			setFormData({
				...formData,
				pharmacyManager: pharmacyLoginData.manager_name,
				pharmacyName: pharmacyLoginData.name
			})
		}
	}, [pharmacyLoginData])

	useEffect(() => {
		const data = mediCationsData || [];
		const formattedNotesData = data?.map((value, index) => ({
			label: `${value.name}`,
			value: value.id,
		}));
		setMedicationsData(formattedNotesData)
	}, [])

	useEffect(() => {
		if (mediCationData && mediCationData?.length > 0) {
			setSelectComponents([{ selectedOptions: mediCationData[0] }]);
		}
	}, [mediCationData]);

	const handleChangeData = (index, selectedOptions) => {
		const updatedSelectComponents = [...selectComponents];
		updatedSelectComponents[index].selectedOptions = selectedOptions;
		setSelectComponents(updatedSelectComponents);
	};

	const handleAddNew = () => {
		setSelectComponents([...selectComponents, { selectedOptions: mediCationData?.length > 0 ? mediCationData?.[0] : null }])
	};

	const handleRemove = (index) => {
		const updatedSelectComponents = [...selectComponents];
		updatedSelectComponents.splice(index, 1);
		setSelectComponents(updatedSelectComponents);
	}

	const handleInputChange = (index, inputValue) => {
		const updatedSelectComponents = [...selectComponents];
		updatedSelectComponents[index].inputValue = inputValue;
		setSelectComponents(updatedSelectComponents);
	};


	useEffect(() => {
		const endDatePicker = initialPharmacyTransferDate(endDateRef, setFormData, formData);
		return () => {
			if (endDatePicker) {
				endDatePicker.destroy()
			}
		};
	}, [endDateRef, formData])

	useEffect(() => {
		dispatch(previewPdfFailure(null))
		dispatch(faxFormFailure(null))
	}, [])

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">

									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6" style={{ zIndex: '999' }}>
											<Pharmacy.Date formData={formData}
												setFormData={setFormData} />

											<div className="relative">
												<input type="text" id="text1" ref={endDateRef} name='transferDate' defaultValue={formData.transferDate}
													onChange={handleFormDataChange}
													className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
												<label htmlFor="text1" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Ended On</label>
											</div>

											<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
												<Pharmacy.FormWinRxPatients
													formData={formData}
													handleFormDataChange={handleFormDataChange} />
											</ul>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
											<Pharmacy.PatientPhn
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setErrorMessages={setErrorMessages}
												setFormData={setFormData} />

											<Pharmacy.PatientName
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
												setErrorMessages={setErrorMessages} />

											<Pharmacy.PatientDob
												setErrorMessages={setErrorMessages}
												formData={formData}
												setFormData={setFormData}
												handleFormDataChange={handleFormDataChange}
												errorMessages={errorMessages} />
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<div className="relative ot_addNoteText">
												<div className="relative did-floating-label-content">
													<Pharmacy.ClinicName
														formData={formData}
														errorMessages={errorMessages}
														setFormData={setFormData}
														setErrorMessages={setErrorMessages} />

													<Pharmacy.AddClinic />
												</div>
											</div>

											<div className="relative ot_addNoteText">
												<div className="relative did-floating-label-content">
													<Pharmacy.DoctorList
														formData={formData}
														errorMessages={errorMessages}
														setFormData={setFormData}
														setErrorMessages={setErrorMessages} />

													<Pharmacy.AddDoctor />

												</div>
											</div>

											<Pharmacy.ClinicFaxNumber
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages} />

										</div>

										<h1 style={{
											marginTop: '25px', marginBottom: '-30px', marginLeft: '19px', color: '#6B7280',
											position: 'relative', top: '10px', zIndex: 10, width: '65px', paddingLeft: '3px', background: 'white'
										}} className='fs-12'>Medication</h1>

										{selectComponents?.map((selectComponent, index) => (
											<div key={index} className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
												<div className="relative ot_addNoteText">
													<Select
														className="basic-multi-select"
														classNamePrefix="react-select"
														placeholder='Medication'
														isSearchable={false}
														options={mediCationData}
														value={selectComponent?.selectedOptions}
														onChange={(selectedOptions) => handleChangeData(index, selectedOptions)}
														styles={mediCationStyle}
													/>
												</div>

												<div className="relative ot_addNoteText flex">

													{selectComponent?.selectedOptions?.label === 'Other' && (
														<div className="" style={{ position: 'relative', marginTop: '28px', marginBottom: '-20px' }}>
															<input type="text" id="text1"
																value={selectComponent.inputValue || ''}
																onChange={(e) => handleInputChange(index, e.target.value)}
																className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
															<label htmlFor="text1" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Medicine Name</label>

														</div>
													)}

													<button style={{ height: '32px', marginTop: '36px', marginBottom: '-20px', background: 'grey', marginLeft: '5px' }} type="button"
														className="w-24 ti_heightScheduleInnerDateBtn blueBg-color sm:text-center md:text-start lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center"
														onClick={handleAddNew}>
														Add New
													</button>

													{index !== 0 &&
														<div className="relative ot_addNoteText">
															<button style={{ height: '32px', marginTop: '36px', marginBottom: '-20px', marginLeft: '6px', background: 'crimson' }}
																type="button"
																className="w-12 ti_heightScheduleInnerDateBtn blueBg-color sm:text-center md:text-start lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center"
																onClick={() => handleRemove(index)}>

																<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																	<path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
																</svg>
															</button>
														</div>}
												</div>
											</div>
										))}

										<br />

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<Pharmacy.LoginPharmacyName
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
											/>

											<Pharmacy.LoginPharmacyManger handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
											/>

											<Pharmacy.Designation
												handleFormDataChange={handleFormDataChange}
												formData={formData} errorMessages={errorMessages}
												setFormData={setFormData}
											/>
										</div>

										{previewPdfResponse?.error !== null &&
											<>
												<br />
												<Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
											</>
										}

										{sendFaxResponse?.error !== null &&
											<>
												<br />
												<Errors error={sendFaxResponse?.error.message ?
													sendFaxResponse?.error?.message :
													'Something went wrong when sending Fax. Please try again'} />
											</>
										}

										<div className='flex'>
											<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
												<button style={{ height: '40px' }} type="button"

													onClick={() => handleDrNotificationForEmergencySupplyForm(
														formData,
														setErrorMessages,
														errorMessages,
														dispatch,
														'PDF', selectComponents)}

													className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

													{previewPdfResponse?.loading ? <>
														Genrating PDF
														&nbsp;<Loader /> </> : 'Preview PDF'}

												</button>
											</div>

											{pharmacyLoginData?.srFax_active === 1 && pharmacyLoginData?.srFax_status === 1 && 
												<div className="pl-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
													<button style={{ height: '40px', backgroundColor: 'lightseagreen' }} type="button"

														onClick={() => handleDrNotificationForEmergencySupplyForm(
															formData,
															setErrorMessages,
															errorMessages,
															dispatch,
															'FAX', selectComponents,
															showToast,
															setShowToast)}

														className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center" >

														{sendFaxResponse?.loading ? <>
															Sending
															&nbsp;<Loader /> </> : 'Send Fax'}


													</button>
												</div>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ChatBox />
			<Footer />

			{showToast.showToast && <SuccessToastMessage status={'Fax sent'} />}

		</>
	)
}

export default DrNotificationForEmergencySupply