import React, {
    useEffect,
    useState
} from 'react'

import {
    ErrorToast,
    SuccessToastMessage
} from '../../../components/loaders/toastMessage'

import {
    currentDate,
    initalModals,
    initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors'
import { FetchTravelLogs } from '../../../services/actions/pharmacy/travel/travelList'

import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { initDropdowns } from 'flowbite'

import { TextInput } from '../../../components/pharmacy/dispense/dispense'
import { ArchivedDelveriesDateRangePicker } from '../../../components/pharmacy/form/dateRangePicker'
import { DeleteDailyRevenueModal } from '../../../models/revenue/dailyRevenue'
import { useRevenueSelector } from '../../../services/selectors/revenueSelectors'

import ChatBox from '../../../components/chat/chatbox'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'
import * as Icons from '../../../assets/svg/icons'
import * as Modals from '../../../assets/js/custome'

import AddTravelRecords from '../../../models/travel/travel'
import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'

const travelPatientListHeading = [
    { text: "PATIENT", classNames: ['p-3'] },
    { text: "TRAVEL DATE", classNames: ['p-3'] },
    { text: "DURATION", classNames: ['p-3'] },
    { text: "BILLED DAYS", classNames: ['p-3'] },
    { text: "DUE BILLING", classNames: ['p-3'] },
    { text: "BILL DATE", classNames: ['p-3'] },
    { text: "RETURN DATE", classNames: ['p-3'] },
    { text: "NOTE", classNames: ['p-3'] },
    { text: "", classNames: ['p-3'] },
];

const TableRowData = ({ value, className1 = 'p-3', }) => {
    return (<td  className={className1}>
        <p>{value}</p>
    </td>)
}

export const handleResetData = (formData, setFormData, location, navigate, pageNumber, dispatch, list_type, fetchType) => {

    const searchParams = new URLSearchParams();
    const startDate = currentDate;

    const endDate = (() => {
        const endDate = new Date(currentDate);
        endDate.setDate(endDate.getDate() + 7);
        return endDate.toISOString().split("T")[0];
    })();

    setFormData({
        ...formData, page: 1, search: '',
        startDate: startDate,
        endDate: endDate
    });

    searchParams.delete('page');
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);

    const value = {
        list_type: list_type,
        page: pageNumber,
        start_date: startDate,
        end_date: endDate,
    };

    dispatch(FetchTravelLogs(fetchType, value));
}


export const handleSearchData = (formData, pageNumber, dispatch, list_type, fetchType) => {
    const value = {
        list_type: list_type,
        page: pageNumber,
        start_date: formData.startDate,
        end_date: formData.endDate,
        search: formData.search
    };
    dispatch(FetchTravelLogs(fetchType, value));
};

export const handlePaginationData = (event, value, location, navigate, setPageNumber) => {
    const searchParams = new URLSearchParams();
    if (value) {
        setPageNumber(value);
        searchParams.append('page', value);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
    }
};

const TravelLogs = () => {

    const startDateRef = React.useRef()
    const endDateRef = React.useRef()

    const location = Hooks.useCustomLocation()
    const navigate = Hooks.useCustomNavigate()
    const dispatch = Hooks.useCustomDispatch()

    const { patientBlisterPackListResponse, AddBlisterPackResponse } = useBlisterSelectors()
    const { addRevenueDataResponse } = useRevenueSelector()

    const travelPatientList = patientBlisterPackListResponse?.data?.data
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [showModal, setShowModal] = useState(initalModals)

    const [showToast, setShowToast] = useState(initalShowToastMessage)

    const [travelLogsData, setTravelLogsData] = useState(null)
    const [params, setParams] = useState(null)

    const [formData, setFormData] = useState({
        search: '',
        startDate: currentDate,
        endDate: (() => {
            const endDate = new Date(currentDate);
            endDate.setDate(endDate.getDate() + 7);
            return endDate.toISOString().split("T")[0];
        })()
    })

    const search = new URLSearchParams(location.search).get('search') || formData?.search

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        const value = {
            list_type: 'travel_list',
            page: pageNumber,
            start_date: formData?.startDate,
            end_date: formData?.endDate,
        }
        setParams(value)
    }, [pageNumber])

    useEffect(() => {
        if (params) dispatch(FetchTravelLogs('travel_patients_list', params))
        return () => { }
    }, [params])

    const handlePagination = (event, value) => {
        handlePaginationData(event, value, location, navigate, setPageNumber);
    };

    const handleSearch = () => {
        handleSearchData(formData, pageNumber, dispatch, 'travel_list', 'travel_patients_list')
    }

    useEffect(() => {
        initDropdowns()
    }, [travelPatientList?.record])

    const handleResetFilteredData = () => {
        handleResetData(formData, setFormData, location, navigate, pageNumber, dispatch, 'travel_list', 'travel_patients_list');
    }

    useEffectHelper.useArchivedDelveriesDateRangePickerEffect(formData, setFormData, startDateRef, endDateRef)

    return (
        <React.Fragment>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">
                                            <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 w-full'>
                                                <TextInput label='Search' name='search' formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex flex-0 lg:w-4/12 md:w-6/12 sm:w-8/12 w-full' style={{ gridTemplateColumns: '2fr 2fr 7px 2fr 2fr' }}>
                                                <ArchivedDelveriesDateRangePicker
                                                    startDateRef={startDateRef}
                                                    endDateRef={endDateRef}
                                                    formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <button type="button" style={{ backgroundColor: '#3B82F6', width: '100px' }} onClick={handleSearch}
                                                className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                                            </button>

                                            <div className='flex-0 lg:w-1/12 md:w-4/12 sm:w-6/12 w-full'>
                                                <button type="button" style={{ backgroundColor: 'White' }} onClick={handleResetFilteredData}
                                                    className="flex  w-full h-full items-center justify-center text-gray-500 bg-white 
																border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
												dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Reset
                                                </button>
                                            </div>

                                        </div>

                                        {patientBlisterPackListResponse?.loading && patientBlisterPackListResponse?.type === 'travel_patients_list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            patientBlisterPackListResponse?.error !== null && patientBlisterPackListResponse?.type === 'travel_patients_list' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                travelPatientList?.record?.length === 0 && patientBlisterPackListResponse?.type === 'travel_patients_list' ?
                                                    <div className="bg-white rounded-md mt-2">
                                                        <p className="text-gray-500">No record available.</p>
                                                    </div>
                                                    : patientBlisterPackListResponse?.type === 'travel_patients_list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg  relative">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {travelPatientListHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            travelPatientList?.record?.map((value, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: `${'1px solid lightgray'}` }}>

                                                                                        <th  className="p-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                                            <p className="rubik-400 text-gray-500 fs-14">{value.patient.name}</p>
                                                                                            {value.patient.phone && <p className="rubik-400 text-gray-500 fs-12">Phone : {value.patient.phone}</p>}
                                                                                            <p className="rubik-400 text-gray-500 fs-12">PHN : {value.patient.phn}</p>
                                                                                        </th>


                                                                                        <TableRowData value={value.travel_date} />
                                                                                        <TableRowData value={value.travel_duration} />

                                                                                        <TableRowData value={value.billed_first_day} />
                                                                                        <TableRowData value={value.next_bill_qty} />
                                                                                        <TableRowData value={value.next_bill_date} />

                                                                                        <TableRowData value={value.return_date} />
                                                                                        <TableRowData value={value.note} />


                                                                                        <td  className="p-3 flex gap-2 items-center justify-center">

                                                                                            <div className='pt-5' onClick={() => {
                                                                                                return setTravelLogsData(value),
                                                                                                    Modals.handleShowModal(setShowModal, showModal, 'addBlisterPackModal')
                                                                                            }}>
                                                                                                <Icons.EditIcons />
                                                                                            </div>

                                                                                            <div className='pt-4 btnnn'
                                                                                                onClick={() => { return setTravelLogsData(value), Modals.handleShowModal(setShowModal, showModal, 'deleteRevenueList') }}
                                                                                            >
                                                                                                <Icons.DeleteIcon color='red' />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </React.Fragment>)

                                                                            )}
                                                                    </tbody>
                                                                </table>


                                                                {patientBlisterPackListResponse?.error !== null ? null : <>
                                                                    {travelPatientList?.record?.length !== 0 && <>
                                                                        {travelPatientList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={travelPatientList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />

            <Common.Footer />


            {showToast?.patientNoteToast && <SuccessToastMessage status='Note added successfully' />}

            {showToast.showToast && AddBlisterPackResponse?.type === 'update_travel_records' && <SuccessToastMessage status='Updated sucessfully' />}
            {showToast.errorToast && AddBlisterPackResponse?.type === 'update_travel_records' && <ErrorToast status={'Oops! Got an issue.please try to add again.'} />}

            {showToast?.sucessToast && addRevenueDataResponse.type === 'delete_travel_logs' && <SuccessToastMessage status='Deleted successfully.' />}
            {showToast?.errorToast && addRevenueDataResponse.type === 'delete_travel_logs' && <ErrorToast status={'Oops! Got an issue while deleting.please try again.'} />}

            {
                showModal?.addBlisterPackModal &&
                <AddTravelRecords
                    setShowModal={setShowModal}
                    showModal={showModal}
                    setShowToast={setShowToast}
                    travelLogsData={travelLogsData}
                    pageNumber={pageNumber}
                    formData={formData}
                    params={params}
                />
            }

            {showModal.deleteRevenueList &&
                <DeleteDailyRevenueModal
                    formData={formData}
                    text={'Are you sure you want to Delete ?'}
                    logData={travelLogsData}
                    pageNumber={pageNumber}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast}
                />
            }

        </React.Fragment>
    )
}

export default TravelLogs