import {
    ADD_BLISTER_PACK_REQUEST,
    ADD_BLISTER_PACK_SUCCESS,
    ADD_BLISTER_PACK_FAILURE
} from "../../../constants"

import { authToken } from '../../../../storage/authToken'
import axios from 'axios';
import { add_custom_notification, add_p_annoucement, update_order_status_notes, update_order_parcel, update_pill_reminder, update_p_announcement } from "../../../../networking/urlEndPoints"
import Axios from "../../../../networking/intraceptor"
import { fetchAllOrdersNotesList, fetchOrdersDetails } from "./prescriptionList"
import { PreviewPdf } from "../../../../utils/helper/helper"
import { fetchAppAnnounce, fetchAppNotifications } from "./appCommon"

// -----------------------Add request action ------------------------
export const addAppDataRequest = (types) => ({
    type: ADD_BLISTER_PACK_REQUEST,
    types: types
})

// -----------------------Success action ------------------------

export const addAppDataSuccess = (data, types) => ({
    type: ADD_BLISTER_PACK_SUCCESS,
    payload: data,
    types: types
})

// -----------------------Failure action ------------------------

export const addAppDataFailure = (error, types) => ({
    type: ADD_BLISTER_PACK_FAILURE,
    payload: error,
    types: types
})

// -----------------------Update Customer Notes---------------------------

export const HandleUpdateCustomerNotes = (type, requestData, setShowToast) => {

    return async (dispatch) => {
        dispatch(addAppDataRequest(type))
        try {

            const response = await Axios.post(`${update_order_status_notes}`, requestData)
            if (response?.status === 200) {

                dispatch(addAppDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                dispatch(fetchAllOrdersNotesList('view_order_notes_list', requestData.order_id))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addAppDataFailure(error?.response, type))
            }
        }
    }
}


export const handleUpdateCreateShipment = (type, requestData, setShowToast) => {

    return async (dispatch) => {
        dispatch(addAppDataRequest(type))
        try {

            const response = await Axios.post(`${update_order_parcel}`, requestData)
            if (response?.status === 200) {

                dispatch(addAppDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addAppDataFailure(error?.response, type))
            }
        }
    }
}

export const HandleCreatePillReminder = (type, requestData, setShowToast) => {

    return async (dispatch) => {
        dispatch(addAppDataRequest(type))
        try {

            const response = await Axios.post(`${update_pill_reminder}`, requestData)
            if (response?.status === 200) {

                dispatch(addAppDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addAppDataFailure(error?.response, type))
            }
        }
    }
}

export const HandleTransferPrescriptionViewSendFaxPDF = (type, requestData, setShowToast) => {

    return async (dispatch) => {
        dispatch(addAppDataRequest(type))
        try {

            const response = await Axios.post(`${'save-preview-fax'}`, requestData)
            if (response?.status === 200) {

                dispatch(addAppDataSuccess(response?.data, type))
                PreviewPdf(response?.data?.data)
                dispatch(fetchOrdersDetails('view_order_details', requestData.order_id))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addAppDataFailure(error?.response, type))
            }
        }
    }
}


export const AddAnnouncementAction = (type, requestData, setShowToast, setShowModal, page) => {

    return async (dispatch) => {
        dispatch(addAppDataRequest(type))
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${add_p_annoucement}`, requestData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken()}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            // const response = await Axios.post(`${add_p_annoucement}`, requestData)

            if (response?.status === 200) {

                dispatch(addAppDataSuccess(response?.data, type))

                setShowModal(prevState => ({
                    ...prevState,
                    AddAnnouncements: false,
                }));

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                document.body.style.overflow = 'auto'
                const params = {
                    page: page,
                    no_of_records: 10,
                }
                dispatch(fetchAppAnnounce('appAccouncements', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addAppDataFailure(error?.response, type))
            }
        }
    }
}

export const EditAnnouncementAction = (type, requestData, setShowToast, setShowModal, pageNumber = '1') => {

    return async (dispatch) => {
        dispatch(addAppDataRequest(type))
        try {
            // const response = await Axios.post(`${update_p_announcement}`, requestData)
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${update_p_announcement}`, requestData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken()}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response?.status === 200) {

                dispatch(addAppDataSuccess(response?.data, type))

                setShowModal(prevState => ({
                    ...prevState,
                    EditAnnouncements: false,
                }));

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: pageNumber,
                    no_of_records: 10,
                }

                document.body.style.overflow = 'auto'
                dispatch(fetchAppAnnounce('appAccouncements', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addAppDataFailure(error?.response, type))
            }
        }
    }
}

export const AddAppNotification = (type, requestData, setShowToast, setShowModal) => {

    return async (dispatch) => {
        dispatch(addAppDataRequest(type))
        try {

            const formData = new FormData();
            formData.append('banner', requestData.banner);
            // formData.append('text', requestData.title);
            // formData.append('from_date', requestData.fromdate);
            // formData.append('to_date', requestData.todate);
            // formData.append('status', requestData.status);

            const response = await Axios.post(`${add_custom_notification}`, requestData)

            if (response?.status === 200) {

                dispatch(addAppDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {

                    setShowModal(prevState => ({
                        ...prevState,
                        AddNotificationModal: false,
                    }));

                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {

                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: '1',
                    no_of_records: 10,
                }
                document.body.style.overflow = 'auto'
                dispatch(fetchAppNotifications('AppCustomNotification', params))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addAppDataFailure(error?.response, type))
            }
        }
    }
}


export const handleUpdateShipingBillingAddress = (type, requestData, setShowToast, setShowModal, order_id) => {

    return async (dispatch) => {
        dispatch(addAppDataRequest(type))
        try {

            const response = await Axios.post(`${'order-update-address'}`, requestData)
            if (response?.status === 200) {

                dispatch(addAppDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, addressModal: false }))
                    document.body.style.overflow = 'auto'
                }

                dispatch(fetchOrdersDetails('view_order_details', order_id))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addAppDataFailure(error?.response, type))
            }
        }
    }
}
