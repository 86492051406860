import {
    DAILY_REVENUE_LIST_REQUEST,
    DAILY_REVENUE_LIST_SUCCESS,
    DAILY_REVENUE_LIST_FAILURE,
} from "../../../constants"

const initialState = {
    data: null,
    loading: false,
    error: null,
    type: null,
};

const RevenueListReducer = (state = initialState, action) => {
    switch (action.type) {
        case DAILY_REVENUE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                data: null,
                error: null,
                type: action.fetch_type,
            };
        case DAILY_REVENUE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                type: action.fetch_type,
                error: null,
            };
        case DAILY_REVENUE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                type: action.fetch_type,
                data: null,
            };
        default:
            return state;
    }
};

export default RevenueListReducer;
