import Cookies from "js-cookie";
import { fetchUserCheckInOut } from "../../services/actions/main/userCheckInOutActions";
import { addPatientNoteReply } from "../../services/actions/pharmacy/other";

import * as path from '../../networking/urlEndPoints'
import AWS from 'aws-sdk';

export const getTimeSchedule = (startDateValue, endDateValue, setMessage, dispatch) => {
	if (!startDateValue) {
		setMessage('Please select the starting date.');
	} else if (!endDateValue) {
		setMessage('Please select the ending date.');
	} else if (startDateValue === endDateValue) {
		setMessage('Starting and ending date should not be the same.');
	} else if (new Date(endDateValue) < new Date(startDateValue)) {
		setMessage('Ending date should be greater than the starting date.');
	} else {
		const startDate = new Date(startDateValue);
		const endDate = new Date(endDateValue);
		const timeDiff = Math.abs(endDate - startDate);
		const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

		if (diffDays > 30) {
			setMessage('The gap between the starting and ending date should not exceed 30 days.');
		} else {
			setMessage('');
			dispatch(fetchUserCheckInOut(startDateValue, endDateValue));
		}
	}
};

export const displayScheduleForMonth = (startDateValue, endDateValue, setStartMonth, setEndMonth) => {
	const startYear = new Date(startDateValue).getFullYear();
	const startMonth = new Date(startDateValue).getMonth() + 1;
	const endYear = new Date(endDateValue).getFullYear();
	const endMonth = new Date(endDateValue).getMonth() + 1;

	const firstDayOfMonth = new Date(startYear, startMonth - 1, 1).toLocaleDateString('en-US', { month: 'long' });
	const lastDayOfMonth = new Date(endYear, endMonth, 0).toLocaleDateString('en-US', { month: 'long', year: 'numeric' });

	if (firstDayOfMonth === lastDayOfMonth) {
		setStartMonth(firstDayOfMonth);
		setEndMonth('');
	} else if (lastDayOfMonth === 'Invalid Date') {
		setEndMonth('');
		setStartMonth(firstDayOfMonth)
	} else if (firstDayOfMonth !== lastDayOfMonth) {
		setStartMonth(firstDayOfMonth)
		setEndMonth(lastDayOfMonth)
	}
}


export const formatTime = (dateTime) => {
	if (dateTime) {
		const dateObject = new Date(dateTime);
		const formattedTime = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
		return formattedTime;
	}
	return '';
};


export function calculateTotalHours(startDateValue, endDateValue) {
	if (startDateValue && endDateValue) {
		const startDate = new Date(startDateValue);
		const endDate = new Date(endDateValue);

		if (!isNaN(startDate) && !isNaN(endDate)) {
			const timeDiff = endDate - startDate;

			if (timeDiff >= 0) {
				const daysDiff = Math.ceil(timeDiff / (24 * 60 * 60 * 1000));
				const totalHours = (daysDiff + 1) * 8.00;

				if (totalHours % 1 === 0) {
					return totalHours.toFixed(2);
				} else {
					return totalHours.toFixed(2);
				}
			}
		}
	}

	return '';
}



export const formatDate = (inputDate) => {
	const dateObj = new Date(inputDate)
	const day = dateObj.getDate().toString().padStart(2, '0')
	const month = new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(dateObj)
	return `${day} ${month}`;
};

export const chatUserListLoader = Array.from({ length: 6 }, (_, index) => index);

export const patientNotificationListLoader = Array.from({ length: 10 }, (_, index) => index);

export const currentTime = new Date().toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })

export const commentListLoader = Array.from({ length: 2 }, (_, index) => index);

export const wallListLoader = Array.from({ length: 10 }, (_, index) => index);


export const formatMessageTime = (timestamp) => {
	const messageDate = new Date(timestamp);
	const currentDate = new Date();

	const messageDay = messageDate.getDate();
	const messageMonth = messageDate.getMonth();
	const messageYear = messageDate.getFullYear();

	const currentDay = currentDate.getDate();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();

	if (
		messageDay === currentDay &&
		messageMonth === currentMonth &&
		messageYear === currentYear
	) {
		const formattedTime = messageDate.toLocaleTimeString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		});
		return formattedTime;
	} else if (
		messageDay === currentDay - 1 &&
		messageMonth === currentMonth &&
		messageYear === currentYear
	) {
		return 'Yesterday';
	} else {
		const formattedDate = `${messageDate.getDate()}/${messageDate.getMonth() +
			1}/${messageDate.getFullYear()}`;
		return formattedDate;
	}
};


export const PreviewPdf = (response) => {
	const base64Pdf = response
	const binaryData = atob(base64Pdf);
	const arrayBuffer = new ArrayBuffer(binaryData.length);
	const uint8Array = new Uint8Array(arrayBuffer);

	for (let i = 0; i < binaryData.length; i++) {
		uint8Array[i] = binaryData.charCodeAt(i);
	}
	const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
	const pdfUrl = URL.createObjectURL(blob);
	window.open(pdfUrl, '_blank')
}


export const removeWorkSpaceTab = () => {
	Cookies.remove('work_tab')
}

export const handalPatientNotesreply = (value, addTeamNoteData, setAddTeamNoteData, dispatch) => {
	if (value && addTeamNoteData.addReply) {
		dispatch(addPatientNoteReply(value.note_id,
			addTeamNoteData.addReply,
			addTeamNoteData.pageNumber, addTeamNoteData, setAddTeamNoteData
		))
	}
}

export const fetchAllFoldersFromS3 = (folderName, setFolders, pharmacyId, setLoading, type) => {
	const s3 = new AWS.S3({
		accessKeyId: path.awsAccessKey,
		secretAccessKey: path.awsSecretKey,
		region: path.awsDefaultRegion
	});
	let params;
	const lastFolder = folderName?.split('/')?.filter(Boolean)?.pop();

	if (lastFolder && type === 'uploadImage') {
		params = {
			Bucket: path.awsBuketName,
			Prefix: `pharmacy_file_manager/${pharmacyId}/all_folders/${lastFolder}/`,
			Delimiter: '/'
		};
	} else if (folderName) {
		params = {
			Bucket: path.awsBuketName,
			Prefix: `pharmacy_file_manager/${pharmacyId}/all_folders/${folderName}/`,
			Delimiter: '/'
		}
	} else {
		params = {
			Bucket: path.awsBuketName,
			Prefix: `pharmacy_file_manager/${pharmacyId}/all_folders/`,
			Delimiter: '/'
		};
	}

	s3.listObjectsV2(params, async (err, data) => {
		if (err) {
			console.error('Error listing objects:', err);
			return;
		}

		const foldersArray = data.CommonPrefixes.map(prefix => {
			return prefix.Prefix.split('/').filter(Boolean).pop();
		});

		setFolders(foldersArray);
		if (typeof setLoading === 'function') setLoading(false);
		if (!folderName) return;

		try {
			const filesData = await s3.listObjectsV2(params).promise();
			const filesArray = filesData.Contents.map(file => {
				return file.Key.split('/').pop();
			});
			setFolders(prevFolders => [...prevFolders, ...filesArray]);
		} catch (error) {
			console.error('Error listing files:', error);
		}
	});

};


export const fetchImagesFromS3 = (setFiles, pharmacyId) => {
	const s3 = new AWS.S3({
		accessKeyId: path.awsAccessKey,
		secretAccessKey: path.awsSecretKey,
		region: path.awsDefaultRegion
	});

	const params = {
		Bucket: path.awsBuketName,
		Prefix: `pharmacy_file_manager/${pharmacyId}/all_files`
	};

	s3.listObjectsV2(params, (err, data) => {
		if (err) {
			return;
		}

		const imageKeys = data.Contents.filter(obj => {
			const key = obj.Key;
			const ext = key.split('.').pop().toLowerCase();
			return ['jpg', 'jpeg', 'png', 'pdf'].includes(ext);
		}).map(obj => obj.Key);
		setFiles(imageKeys);
	});
};

export const filemanagerFolderLoader = Array.from({ length: 3 }, (_, index) => index);
