import * as path from "../../networking/urlEndPoints";
import * as forms from "../../services/actions/pharmacy/forms";

const validateField = (fieldName, value, requiredMessage) =>
    !value || value.length === 0 ? requiredMessage : "";

export const handleARVFaxValidation = (
    arvID,
    formData,
    setErrorMessages,
    errorMessages,
    dispatch,
    type,
    showToast,
    setShowToast
) => {
    const specificValidations = {
        clinicFaxNumber: "Pharmacy Fax is required",
        doctorName: "Doctor name is required",
        clinicName: "Clinic Name is required",
        numberOfDaysSupplyRequested: "No. of days supply is required",
    };

    const allValidations = { ...specificValidations };

    const newErrorMessages = Object.fromEntries(
        Object.entries(allValidations).map(([field, message]) => [
            field,
            validateField(field, formData[field], message),
        ])
    );

    setErrorMessages({
        ...errorMessages,
        ...newErrorMessages,
    });

    const allDataPresent = Object.values(newErrorMessages).every(
        (message) => message === ""
    );

    if (allDataPresent) {
        const commonDataToBeSent = {
            arvlogid: arvID,
            clinic: formData.clinicName.value,
            doctor: formData.doctorName.value,
            clinicfaxnumber: formData.clinicName.fax,
            daysupply: formData.numberOfDaysSupplyRequested,
        };

        if (type === "PDF") {
            const requestedData = {
                ...commonDataToBeSent,

            };
            dispatch(
                forms.pharmacyPreviewFormPdf(path.arv_fax_preview, requestedData)
            );
        }

        if (type === "FAX") {
            const requestedData = {
                ...commonDataToBeSent,
            };
            dispatch(
                forms.pharmacySendFax(
                    path.arv_send_fax,
                    requestedData,
                    showToast,
                    setShowToast
                )
            );
        }
    }
};
