import {
	FETCH_EMPLOYEES_SCHEDULE_REQUEST,
	FETCH_EMPLOYEES_SCHEDULE_SUCCESS,
	FETCH_EMPLOYEES_SCHEDULE_FAILURE
} from "../../constants";

import { pharmacy_user_team_shift } from "../../../networking/urlEndPoints";
import Axios from "../../../networking/intraceptor";

export const fetchEmployeesWorkScheduleRequest = () => ({
	type: FETCH_EMPLOYEES_SCHEDULE_REQUEST,
});

export const fetchEmployeesWorkScheduleSuccess = (data) => ({
	type: FETCH_EMPLOYEES_SCHEDULE_SUCCESS,
	payload: data,
});

export const fetchEmployeesWorkScheduleFailure = (error) => ({
	type: FETCH_EMPLOYEES_SCHEDULE_FAILURE,
	payload: error,
});

export const getEmployeeWorkSchedule = () => {
	return async (dispatch) => {
		dispatch(fetchEmployeesWorkScheduleRequest());
		try {
			const response = await Axios.get(`${pharmacy_user_team_shift}`);
			if (response?.status === 200) {
				dispatch(fetchEmployeesWorkScheduleSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchEmployeesWorkScheduleFailure(error?.response))
			}
		}
	};
};
