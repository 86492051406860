import {
	PHARMACY_UPDATE_CLINIC_FORM_REQUEST,
	PHARMACY_UPDATE_CLINIC_FORM_SUCCESS,
	PHARMACY_UPDATE_CLINIC_FORM_FAILURE
} from "../../constants"
import { pharmacy_update_notification_clinic_form } from "../../../networking/urlEndPoints"

import Axios from "../../../networking/intraceptor"

export const updateNotificationsClinicsRequest = () => ({
	type: PHARMACY_UPDATE_CLINIC_FORM_REQUEST,
})

export const updateNotificationsClinicsSuccess = (data) => ({
	type: PHARMACY_UPDATE_CLINIC_FORM_SUCCESS,
	payload: data,
})

export const updateNotificationsClinicsFailure = (error) => ({
	type: PHARMACY_UPDATE_CLINIC_FORM_FAILURE,
	payload: error,
})

export const updateNotificationsClinics = (formData) => {
	return async (dispatch) => {
		dispatch(updateNotificationsClinicsRequest())
		const requestedData = {
			date: formData.date,
			clinic: formData.clinicName.value,
			doctor: formData.doctorName.value,
			clinicfaxnumber: formData.clinicFaxNumber,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyfax: formData.pharmacyFax,
			pharmacyphone: formData.pharmacyPhone,
			pharmacyemail: formData.pharmacyEmail,
			pharmacyuserdesignation: formData.desgination
		}
		if (formData.fetchType === 'winrx') {
			requestedData.fetchtype = formData.fetchType
			requestedData.winrxpatientphn = formData.phn
			requestedData.winrxpatientname = formData.name
			requestedData.winrxpatientdob = formData.dob
		}

		if (formData.fetchType === 'local') {
			requestedData.fetchtype = formData.fetchType
			requestedData.patient = formData.patientName.value
			requestedData.patientphn = formData.phn
			requestedData.patientdob = formData.dob
		}

		try {
			const response = await Axios.post(`${pharmacy_update_notification_clinic_form}`, requestedData)
			if (response?.status === 200) {
				dispatch(updateNotificationsClinicsSuccess(response?.data))
				const base64Pdf = response?.data?.data
				const binaryData = atob(base64Pdf);
				const arrayBuffer = new ArrayBuffer(binaryData.length);
				const uint8Array = new Uint8Array(arrayBuffer);

				for (let i = 0; i < binaryData.length; i++) {
					uint8Array[i] = binaryData.charCodeAt(i);
				}
				const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
				const pdfUrl = URL.createObjectURL(blob);
				window.open(pdfUrl, '_blank')

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(updateNotificationsClinicsFailure(error?.response))
			}
		}
	}
}
