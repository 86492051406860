import React from 'react'

import {  todayBillngTableHeading } from '../../../constants/dispense'
import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'

import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { FetchTodaysBillingList } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'

import ChatBox from '../../../components/chat/chatbox'

const TableRowData = ({ value, className1 = 'p-3', className2 = 'rubik-400 fs-14' }) => {
    return (<td  className={className1}>
        <p className={className2}>{value}</p>
    </td>)
}

const BillingList = () => {

    const location = Hooks.useCustomLocation()
    const dispatch = Hooks.useCustomDispatch()

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    const [pageNumber, setPageNumber] = React.useState(initialPage)

    const { dispenseListDataResponse } = useDispenseSelectors()
    const billingDrugList = dispenseListDataResponse?.data?.data

    React.useEffect(() => {
        setPageNumber(initialPage)
    }, [initialPage])

    React.useEffect(() => {
        if (pageNumber) {
            const params = {
                page: pageNumber,
            }
            dispatch(FetchTodaysBillingList(params, 'today_billing_sheet'))
        }
        return () => { }
    }, [])

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
													sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                        {dispenseListDataResponse?.loading && dispenseListDataResponse?.type === 'today_billing_sheet' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            dispenseListDataResponse?.error !== null && dispenseListDataResponse?.type === 'today_billing_sheet' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4  bg-red-600 text-white rounded hover:bg-red-700 
																	focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                billingDrugList?.data?.length === 0 ?
                                                    <div className="bg-white pt-4 rounded-md">
                                                        <p className="text-gray-500">No Record Available.</p>
                                                    </div>
                                                    :
                                                    dispenseListDataResponse?.type === 'today_billing_sheet' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative overflow-x-auto">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {todayBillngTableHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            billingDrugList?.data?.map((value, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: `${'1px solid lightgray'}` }}>
                                                                                        <TableRowData value={value?.date} />
                                                                                        <TableRowData value={value?.name} />
                                                                                        <TableRowData value={value?.route} />
                                                                                        <TableRowData value={value?.phn} />
                                                                                        <TableRowData value={value?.rx} />
                                                                                        <TableRowData value={value?.drug} />
                                                                                        <TableRowData value={value?.current_dose} />
                                                                                        <TableRowData value={value?.previous_dose} />
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                                {dispenseListDataResponse?.error !== null ? null : <>
                                                                    {billingDrugList?.data?.length !== 0 && <>
                                                                        {billingDrugList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center 
																			md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={billingDrugList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={(event, value) => {
                                                                                                const params = {
                                                                                                    page: value,
                                                                                                }
                                                                                                dispatch(FetchTodaysBillingList(params, 'today_billing_sheet'))
                                                                                            }}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}

                                                            </div>
                                                        </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />

            <Common.Footer />
        </>

    )
}

export default BillingList