import Axios from "../../../networking/intraceptor";
import { pharmacy_wall_post_delete } from "../../../networking/urlEndPoints";
import { fetchPharmacyWallLists } from "./pharmacyWallPostListsActions";
import { fetchUserWallLists } from "./userWallListActions";

export async function deletePharmacyWallLists(postID, setDeleteWallListLoading, dispatch, setModalOpen) {
	try {
		const response = await Axios.post(`${pharmacy_wall_post_delete}/${postID}`);
		if (response) {
			dispatch(fetchPharmacyWallLists())
		}
		if (typeof setDeleteWallListLoading === 'function') {
			setDeleteWallListLoading(false)
			setModalOpen(false)
		}
		return response
	} catch (error) {
		if (error?.response) {
			if (typeof setDeleteWallListLoading === 'function') {
				setDeleteWallListLoading(false)
				setModalOpen(false)

			}
		}
	}
}

export async function deleteUserWallLists(postID, setDeleteWallListLoading, dispatch, setModalOpen) {
	try {
		const response = await Axios.post(`${pharmacy_wall_post_delete}/${postID}`);
		if (response) {
			dispatch(fetchPharmacyWallLists())
			dispatch(fetchUserWallLists())
		}
		if (typeof setDeleteWallListLoading === 'function') {
			setDeleteWallListLoading(false)
			setModalOpen(false)
		}
		return response
	} catch (error) {
		if (error?.response) {
			if (typeof setDeleteWallListLoading === 'function') {
				setDeleteWallListLoading(false)
				setModalOpen(false)

			}
		}
	}
}