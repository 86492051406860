import React, {
	useState,
	useRef,
	useEffect
} from 'react';

import { ModalCloseIcon } from '../../assets/svg/icons';
import { handleHideModal } from '../../assets/js/custome';

import {
	useCustomLocation,
	useCustomDispatch
} from '../../hooks';

import { initalAddNewDeliveryFormData } from '../../utils/constants/formInitialStates';
import { onhandleDispenseAddDeliveryFormDataChange } from '../../utils/data/handleFormDataChange';
import { handleDispenseAddDriveForm, handleUpdateDispenseAddDriveForm } from '../../utils/validations/dispenseValidations';

import { Errors } from '../../components/error/alertMessages';
import { useDispenseSelectors } from "../../services/selectors/dispenseSelectors";
import { RouteList } from '../../components/pharmacy/dispense/dispense';

import Select from 'react-select'

import * as routeNames from '../../routes/routeName'
import * as path from '../../networking/urlEndPoints'
import * as dispense from '../../services/actions/pharmacy/dispense'
import { LoadingView } from '../../components/common';

export const AddDriver = ({ isEdit, driverData, setShowModal, showModal, date }) => {

	const modalRef = useRef(null);
	const location = useCustomLocation()

	const dispatch = useCustomDispatch()

	const [selectedFile, setSelectedFile] = useState(null);
	const [formData, setFormData] = useState(initalAddNewDeliveryFormData)

	const [errorMessages, setErrorMessages] = useState(initalAddNewDeliveryFormData)
	const [isChecked, setIsChecked] = useState(false);
	const [isCheckedInstore, setIsCheckedInstore] = useState(false)

	const [driverList, setDriverList] = useState([]);

	const { addDispenseDataResponse, searchDeliverySupervisorListResponse,
		searchDeliverySupervisorList, } = useDispenseSelectors();

	const addDriverFeilds = [
		{ id: "name", label: "Name", name: "name", value: formData.name, errorMessage: errorMessages.name },
		{ id: "email", label: "Email", name: "email", value: formData.email, errorMessage: errorMessages.email },
		{ id: "phone", label: "Phone", name: "phone", value: formData.phone.slice(0, 10), errorMessage: errorMessages.phone, fullWidth: true },
		{ id: "password", label: "Password", name: "password", value: formData.password, errorMessage: errorMessages.password },
		{ id: "passcode", label: "Passcode", name: "passcode", value: formData.passcode, errorMessage: errorMessages.passcode },
	];

	const handleFormDataChange = (e) => {
		onhandleDispenseAddDeliveryFormDataChange(e, setFormData, formData, setErrorMessages, errorMessages)
	}

	useEffect(() => {
		if (driverData !== null) {
			setFormData({ ...formData, name: driverData?.name, email: driverData?.email, image: driverData?.driver_photo })
		}
	}, [driverData])

	useEffect(() => {
		if (location.pathname === routeNames.pharmacy_driver) {
			if (addDispenseDataResponse?.error !== null && addDispenseDataResponse?.type === 'pharmacy-add-driver' && addDispenseDataResponse?.error?.status === 400) {
				setErrorMessages({ ...errorMessages, passcode: addDispenseDataResponse?.error?.data?.data?.error?.passcode?.[0] })
			}
		}

	}, [addDispenseDataResponse])

	useEffect(() => {
		const list = searchDeliverySupervisorList || [];
		const formattedDeliveryDerivers = list?.map((value) => ({
			label: value?.text,
			value: value.id,

		}));
		setDriverList(formattedDeliveryDerivers);
	}, [searchDeliverySupervisorList]);

	const handleGetActivePatientList = () => {
		if (location.pathname === routeNames.pharmacy_driver) {
			if (searchDeliverySupervisorListResponse?.data === null) {
				dispatch(dispense.searchDeliverySupervisorListData(path.pharmacy_supervisior_search))
			}
		}
	}

	const handleSaveDriverList = () => {
		const requestedData = {
			driver_id: formData?.supervisorId?.value
		}
		if (formData?.supervisorId !== null) {
			dispatch(dispense.addDispenseData(path.pharmacy_addnew_sdelivery_driver, requestedData, setShowModal, 'pharmacy_add_new_delivery_deriver'))
		} else {
			dispatch(dispense.pharmacyAddDispenseDataFailure('Please select the Delivery Driver', 'pharmacy_add_new_delivery_deriver'))
		}
	}


	return (
		<>

			{showModal.addDriverModal &&
				<div className={`fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center 
                				justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100 w-full 
								md:inset-0 h-[calc(100%-1rem)] max-h-full`}>

					<div ref={modalRef} className="relative w-full max-w-3xl ">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

							<button onClick={() => handleHideModal(setShowModal, showModal, 'addDriverModal')} type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
								<ModalCloseIcon />
							</button>

							{location.pathname === routeNames.pharmacy_deliveries_supervisor ?
								<h1 className='rubik-500 pl-4 pt-4 mb-2' style={{ fontSize: 20 }}>{isEdit ? 'Edit Details' : 'Add Driver'} </h1>
								:
								<h1 className='rubik-500 pl-4 pt-4 mb-2' style={{ fontSize: 20 }}>{isEdit ? 'Edit Details' : 'Add Driver'} </h1>}

							{!isEdit &&
								<label className="inline-flex items-center cursor-pointer ml-4" >
									<input type="checkbox" value="" className="sr-only peer" onChange={(e) => setIsChecked(e.target.checked)} />
									<div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
									<span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 ml-2">Global/Local</span>
								</label>}

							<hr />

							{!isChecked && <>
								<div className="flex items-center justify-center gap-4  px-4 pt-4">
									<div className="relative text-center">
										<div onClick={handleGetActivePatientList}>
											<Select isMulti={false}
												onChange={(selectedValue) => {
													setFormData({
														...formData,
														supervisorId: selectedValue
													})
												}}
												value={formData?.supervisorId}
												options={driverList}
												classNamePrefix="react-select"
												placeholder="Select Delivery Supervisor"
												isSearchable={true}
												noOptionsMessage={() =>
													searchDeliverySupervisorListResponse?.loading ? "Loading..." : "No Options"
												}
												className="basic-multi-select z-20" />
										</div>
									</div>
								</div>
							</>
							}

							{isChecked &&
								<div className='px-4 mt-4'>
									<div className="flex flex-wrap gap-4 gap-y-3" >
										{
											addDriverFeilds?.map((field, index) => {
												if (!field.fullWidth && field.name === 'password' && !isEdit) {
													return null;
												}

												return (
													<div className={`relative flex-0 w-full md:flex-${field.fullWidth ? 0 : 1}`} key={index}>
														<input
															onKeyPress={(e) => {
																if (isNaN(Number(e.key)) && (field.name === 'phone' || field.name === 'passcode')) {
																	e.preventDefault();
																}
															}}
															type="text"
															id={field.name[index]}
															onChange={(e) => handleFormDataChange(e)}
															name={field.name}
															value={field.value}
															placeholder=""
															className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 
            														appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 
            														focus:border-blue-600 peer cursor-text bg-white ${field.errorMessage && 'border-red-600'}`} />

														<label htmlFor={field.name[index]}
															className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 
            														scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
            														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
            														peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 
            														rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
															{!field.errorMessage && field.label}
														</label>
														{field.errorMessage && (
															<p className="mt-2 text-xs text-red-600 dark:text-red-400">
																{field.errorMessage}
															</p>
														)}
													</div>
												);
											})
										}
									</div>
									<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-4">
										<div className='custom-file flex'>
											<input accept='image/*' id="file_input" type="file" placeholder=""
												className={`block form-control custom-file-input pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 		border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 
                            							focus:border-blue-600 peer cursor-text bg-white`}
												onChange={(event) => setSelectedFile(event.target.files[0])} />

											<label className='custom-file-label text-gray-500  fs-14' >
												{selectedFile ? selectedFile?.name : "Upload Profile Image"}
											</label>

											<button onClick={() => document.getElementById('file_input').click()}>
												Browser file
											</button>
										</div>

										<div>

											{selectedFile !== null && selectedFile !== undefined && typeof selectedFile === 'object' ?
												<img style={{ width: 150 }} src={URL.createObjectURL(selectedFile)} /> :
												<img style={{ width: 150 }} src={formData.image} />
											}

										</div>
									</div>

									<label className="inline-flex items-center cursor-pointer" >
										<input type="checkbox" value="" className="sr-only peer" onChange={(e) => setIsCheckedInstore(e.target.checked)} />
										<div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 
											rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
											after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
											after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
										<span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 ml-2">In Store</span>
									</label>

									{isCheckedInstore &&
										<div className='grid grid-cols-3 mt-2'>
											<div>
												<h1 className='ms-3 text-sm font-medium text-gray-900 dark:text-gray-300'>Default Route</h1>
												<RouteList formData={formData}
													setFormData={setFormData} />
											</div>
										</div>}

								</div>}

							{isChecked &&
								<div className='px-4 pt-2'>
									{location.pathname === routeNames.pharmacy_driver && <>
										{addDispenseDataResponse?.error !== null && addDispenseDataResponse.type === 'pharmacy-add-driver' ?
											addDispenseDataResponse?.error?.status === 400 ?
												<Errors error={addDispenseDataResponse?.error?.data?.data?.error?.email?.[0]} /> :
												<Errors error={`Something went wrong when adding the Driver Details please try to add again.`} />
											: null}
									</>}

									{location.pathname === routeNames?.pharmacy_driver && <>
										{addDispenseDataResponse?.error !== null && addDispenseDataResponse?.type === 'pharmacy-update-driver' ?
											addDispenseDataResponse?.error?.status === 400 ?
												<Errors error={addDispenseDataResponse?.error?.data?.data?.error?.passcode?.[0]} /> :
												<Errors error={`Something went wrong when adding the Driver Details please try to add again.`} />
											: null}
									</>}
								</div>}

							{!isChecked &&
								<div className='px-4 py-4'>
									{location.pathname === routeNames.pharmacy_driver && <>
										{addDispenseDataResponse?.error !== null && addDispenseDataResponse.type === 'pharmacy_add_new_delivery_deriver' ?
											addDispenseDataResponse?.error?.status === 400 ?
												<Errors error={addDispenseDataResponse?.error?.data?.data?.error} /> :
												addDispenseDataResponse?.error === 'Please select the Delivery Driver' ?
													<Errors error={addDispenseDataResponse?.error} /> :
													<Errors error={`Something went wrong when saving the Delivery Driver please try to save again `} />
											: null}
									</>}
								</div>}

							<div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
									<div className="col-span-12">

										{location.pathname === routeNames.pharmacy_deliveries_supervisor ?
											<button type="submit"
												className={`rubik-500 flex justify-center items-center text-white blueBg-color  w-full text-sm px-5 py-2.5 text-center`}
												onClick={() => isEdit ? null : handleDispenseAddDriveForm(formData, setErrorMessages, errorMessages, setShowModal, dispatch)}>

												{isEdit ? 'Update' : 'Submit'}
											</button>
											:
											<button type="submit"
												className={`rubik-500 flex justify-center items-center text-white blueBg-color  w-full text-sm px-5 py-2.5 text-center`}
												onClick={() => !isChecked ? handleSaveDriverList() :
													handleDispenseAddDriveForm(formData, setErrorMessages, errorMessages, setShowModal, dispatch, selectedFile, isCheckedInstore, date)}
											>
												{addDispenseDataResponse?.loading ? 'Adding...' : 'Submit'}
											</button>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div >}
		</>
	)
}


export const EditDriverDetails = ({ isEdit, driverData, setShowModal, showModal, date }) => {

	const modalRef = useRef(null);
	const location = useCustomLocation()

	const dispatch = useCustomDispatch()

	const [selectedFile, setSelectedFile] = useState(null);
	const [formData, setFormData] = useState(initalAddNewDeliveryFormData)

	const [errorMessages, setErrorMessages] = useState(initalAddNewDeliveryFormData)
	const [isCheckedInstore, setIsCheckedInstore] = useState(false)

	const { addDispenseDataResponse } = useDispenseSelectors();

	const addDriverFeilds = [
		{ id: "name", label: "Name", name: "name", value: formData.name, errorMessage: errorMessages.name },
		{ id: "email", label: "Email", name: "email", value: formData.email, errorMessage: errorMessages.email },
		{ id: "phone", label: "Phone", name: "phone", value: formData.phone, errorMessage: errorMessages.phone, fullWidth: true },
		{ id: "password", label: "Password", name: "password", value: formData.password, errorMessage: errorMessages.password },
		{ id: "passcode", label: "Passcode", name: "passcode", value: formData.passcode, errorMessage: errorMessages.passcode },
	];

	const handleFormDataChange = (e) => {
		onhandleDispenseAddDeliveryFormDataChange(e, setFormData, formData, setErrorMessages, errorMessages)
	}

	useEffect(() => {
		if (driverData !== null) {
			setFormData({ ...formData, name: driverData?.name, email: driverData?.email, image: driverData?.driver_photo, phone: driverData?.phone, patientRoutes: driverData?.default_route })
			setIsCheckedInstore(driverData?.in_store == 1 ? true : false)
		}
	}, [driverData])

	return (
		<>

			{showModal.editDriver &&
				<div className={`fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center 
                				justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100 w-full 
								md:inset-0 h-[calc(100%-1rem)] max-h-full`}>

					<div ref={modalRef} className="relative w-full max-w-3xl ">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

							<button onClick={() => handleHideModal(setShowModal, showModal, 'editDriver')} type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
								<ModalCloseIcon />
							</button>
							<h1 className='rubik-500 pl-4 pt-4 mb-2' style={{ fontSize: 20 }}>

								{driverData?.global === 1 ? 'View Details' : 'Edit Details'}</h1>

							<hr />

							<div className='px-4 mt-4'>
								<div className="flex flex-wrap gap-4 gap-y-3" >
									{
										addDriverFeilds?.map((field, index) => {
											if (!field.fullWidth && field.name === 'password' && !isEdit) {
												return null;
											}

											return (
												<div className={`relative flex-0 w-full md:flex-${field.fullWidth ? 0 : 1}`} key={index}>
													<input
														onKeyPress={(e) => {
															if (isNaN(Number(e.key)) && (field.name === 'phone' || field.name === 'passcode')) {
																e.preventDefault();
															}
														}}
														type="text"
														id={field.name[index]}
														onChange={(e) => handleFormDataChange(e)}
														name={field.name}
														value={field.value}
														placeholder=""
														className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 
            														appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 
            														focus:border-blue-600 peer cursor-text bg-white ${field.errorMessage && 'border-red-600'}`} />

													<label htmlFor={field.name[index]}
														className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 
            														scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
            														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
            														peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 
            														rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
														{!field.errorMessage && field.label}
													</label>
													{field.errorMessage && (
														<p className="mt-2 text-xs text-red-600 dark:text-red-400">
															{field.errorMessage}
														</p>
													)}
												</div>
											);
										})
									}
								</div>

								<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-4">
									<div className='custom-file flex'>
										<input accept='image/*' id="file_input" type="file" placeholder=""
											className={`block form-control custom-file-input pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 		border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 
                            							focus:border-blue-600 peer cursor-text bg-white`}
											onChange={(event) => setSelectedFile(event.target.files[0])} />

										<label className='custom-file-label text-gray-500  fs-14' >
											{selectedFile ? selectedFile?.name : "Upload Profile Image"}
										</label>

										<button onClick={() => document.getElementById('file_input').click()}>
											Browser file
										</button>
									</div>
									<div>

										{selectedFile !== null && selectedFile !== undefined && typeof selectedFile === 'object' ?
											<img style={{ width: 150 }} src={URL.createObjectURL(selectedFile)} /> :
											<img style={{ width: 150 }} src={formData.image} />
										}

									</div>
								</div>


								<label className="inline-flex items-center cursor-pointer mt-2" >
									<input type="checkbox" value={isCheckedInstore} defaultChecked={driverData?.in_store == 1 ? true : false} className="sr-only peer" onChange={(e) => setIsCheckedInstore(e.target.checked)} />
									<div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 
											rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
											after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
											after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
									<span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 ml-2">In Store</span>
								</label>

								{isCheckedInstore &&
									<div className='grid grid-cols-3 mt-2 mb-4'>
										<div>
											<h1 className='ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 mb-1'>Default Route {driverData?.default_route && <>: {driverData?.default_route}</>}</h1>
											<RouteList formData={formData}
												setFormData={setFormData} />
										</div>
									</div>}

							</div>

							<div className='px-4 pt-2'>
								{location.pathname === routeNames?.pharmacy_driver && <>
									{addDispenseDataResponse?.error !== null && addDispenseDataResponse?.type === 'pharmacy-update-driver' ?
										addDispenseDataResponse?.error?.status === 400 ?
											<Errors error={(addDispenseDataResponse?.error?.data?.data?.error?.passcode?.[0] || addDispenseDataResponse?.error?.data?.data?.error?.email?.[0])} /> :
											<Errors error={`Something went wrong when adding the Driver Details please try to add again.`} />
										: null}
								</>}
							</div>

							{driverData?.global === 1 ? null :
								<div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
									<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
										<div className="col-span-12">
											<button type="submit"
												className={`rubik-500 flex justify-center items-center text-white blueBg-color  w-full text-sm px-5 py-2.5 text-center`}
												onClick={() => handleUpdateDispenseAddDriveForm(formData, setErrorMessages, errorMessages, setShowModal, dispatch, selectedFile, driverData?.id, isCheckedInstore, date)}>

												{addDispenseDataResponse?.loading ? 'Updating...' : 'Update'}
											</button>
										</div>
									</div>
								</div>}
						</div>
					</div>
				</div >}
		</>
	)
}


export const GetAllDriversSigns = ({ setShowModal, showModal, getDriverSigns }) => {

	return (
		<>
			<div className={`fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center 
                				justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100 w-full 
								md:inset-0 h-[calc(100%-1rem)] max-h-full`}>

				<div className="relative w-full max-w-5xl scroll-box" style={{ overflowX: 'auto', maxHeight: '800px' }}>
					<div className="relative bg-white  shadow dark:bg-gray-700">

						<button onClick={() => handleHideModal(setShowModal, showModal, 'driverSign')} type="button"
							className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
							<ModalCloseIcon />
						</button>

						<h1 className='rubik-500 pl-4 pt-4 mb-2' style={{ fontSize: 20 }}>
							Signatures
						</h1>

						<hr />

						<div className='px-4 mt-4'>



							{getDriverSigns?.loading ?
								<div className='flex items-center justify-center' style={{ height: '600px' }}>
									<LoadingView />
								</div>
								:
								getDriverSigns.error !== null ?

									<div className='flex items-center justify-center' style={{ height: '600px' }}>
										<p className='text-gray-900'>Oops! Got an issue while Fetching Data.Please try again later.</p>
									</div>
									:

									<div className="grid grid-cols-3 gap-4 gap-y-3">
										{getDriverSigns?.data?.map((value, idx) => {
											return (
												<div className='border border-gray-300 rounded-lg shadow-sm p-3' key={idx}>
													<div className='border border-gray-300 rounded-lg shadow-sm'>

														<img src={value.sign} alt={`${idx}-img`} className='w-full h-full object-contain' />
													</div>

													<p className='text-gray-900'>{value.last_name} {value.first_name}</p>
													<p className='text-gray-900'>{value.phn}</p>
												</div>
											)
										})}

									</div>}
						</div>
					</div>

				</div>
			</div >
		</>
	)
}
