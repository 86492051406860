import {
    ADD_BLISTER_PACK_REQUEST,
    ADD_BLISTER_PACK_SUCCESS,
    ADD_BLISTER_PACK_FAILURE
} from "../../../constants"

import { pharmacy_travel_records } from "../../../../networking/urlEndPoints"
import { FetchTravelLogs } from "./travelList"

import Axios from "../../../../networking/intraceptor"

// -----------------------Add request action ------------------------
export const addTravelPackDataRequest = (types) => ({
    type: ADD_BLISTER_PACK_REQUEST,
    types: types
})

// -----------------------Success action ------------------------

export const addTravelPackDataSuccess = (data, types) => ({
    type: ADD_BLISTER_PACK_SUCCESS,
    payload: data,
    types: types
})

// -----------------------Failure action ------------------------

export const addTravelPackDataFailure = (error, types) => ({
    type: ADD_BLISTER_PACK_FAILURE,
    payload: error,
    types: types
})

// ------------------------Add Blister Pack----------------------------

export const AddTravelRecordsData = (type, requestData, setShowModal, setShowToast) => {

    return async (dispatch) => {
        dispatch(addTravelPackDataRequest(type))
        try {

            const response = await Axios.post(`${pharmacy_travel_records}`, requestData)

            if (response?.status === 200) {

                dispatch(addTravelPackDataSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, addBlisterPackModal: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addTravelPackDataFailure(error?.response, type))
            }
        }
    }
}


export const UpdateTravelRecordsData = (type, requestData, setShowModal, setShowToast, id,params) => {

    return async (dispatch) => {
        dispatch(addTravelPackDataRequest(type))
        try {

            const response = await Axios.put(`${pharmacy_travel_records}/${id}`, requestData)
          
            if (response?.status === 200) {

                dispatch(addTravelPackDataSuccess(response?.data, type))
                dispatch(FetchTravelLogs('travel_patients_list', params))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, addBlisterPackModal: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addTravelPackDataFailure(error?.response, type))
            }
        }
    }
}