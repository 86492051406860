import {
	POST_COMMENTS_REPLY_LISTS_FAILURE,
	POST_COMMENTS_REPLY_LISTS_REQUEST,
	POST_COMMENTS_REPLY_LISTS_SUCCESS
} from "../../constants";

const initialState = {
	commentReplyList: [],
	loading: false,
	error: null,
};

const userCommentReplyListsReducer = (state = initialState, action) => {
	switch (action.type) {
		case POST_COMMENTS_REPLY_LISTS_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};

		case POST_COMMENTS_REPLY_LISTS_SUCCESS:
			return {
				...state,
				commentReplyList: action.payload,
				loading: false,
				error: null,
			};

		case POST_COMMENTS_REPLY_LISTS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				commentReplyList: []
			};

		default:
			return state;
	}
}

export default userCommentReplyListsReducer;