import React, { useEffect } from 'react'
import { useCustomNavigate } from '../../hooks'
import { removeWorkSpaceTab } from '../../utils/helper/helper'

import * as images from '../../assets/images'
import * as routesNames from '../../routes/routeName'

export const NotFound = () => {

	const navigate = useCustomNavigate()

	useEffect(() => {
		removeWorkSpaceTab()
	}, [])

	return (
		<div className="flex flex-col h-full ot_errorPageBg" style={{ minHeight: '100vh' }}>
			<div className="container mx-auto px-4 pt-5 pb-5">
				<div className="space-x-full flex items-center gap-8">
					<div className="inline-block mx-auto md:mx-0">
						<img src={images.oatrx_logo} className="text-left" alt="" />
					</div>
				</div>
			</div>
			<div className="text-center grid flex-1 content-center">
				<h1 className="text-xl flex-1 fs-48 rubik-700 blue-color mb-0">404</h1>
				<p className="fs-30 rubik-400 uppercase">Page Not Found</p>
				<div className="pt-4">
					<button onClick={() => navigate(routesNames.DASHBOARD_ROUTE)}
						type="button"
						className="ti_heightScheduleInnerDateBtn blueBg-color sm:text-center md:text-start lg:text-end rubik-400 fs-16 text-white rounded-lg text-sm px-5 py-3"
						data-ripple-light="true"
					>
						Go To Dashboard
					</button>
				</div>
			</div>
			<div className="container mx-auto pt-2 pb-2">
				<div className="grid grid-cols-1 gap-4">
					<div className="lg:col-span-3 md:col-span-12 sm:col-span-12 col-span-12 ">
						<div className="text-left5ot_footerTxt text-center">
							<p className="rubik-400 fs-18 grey-color">
								Powered by <span className="blue-color rubik-500">OATRx</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}
