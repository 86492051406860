import {
	PHARMACY_DELIVERY_DRIVERS_LIST_REQUEST,
	PHARMACY_DELIVERY_DRIVERS_LIST_SUCCESS,
	PHARMACY_DELIVERY_DRIVERS_LIST_FAILURE
} from '../../../constants';

import Axios from '../../../../networking/intraceptor'
import * as path from '../../../../networking/urlEndPoints'

export const pharmacyDeliveryDriversListRequest = () => ({
	type: PHARMACY_DELIVERY_DRIVERS_LIST_REQUEST
});

export const pharmacyDeliveryDriversListSuccess = (user, types) => ({
	type: PHARMACY_DELIVERY_DRIVERS_LIST_SUCCESS,
	payload: user,
});

export const pharmacyDeliveryDriversListFailure = (error, types) => ({
	type: PHARMACY_DELIVERY_DRIVERS_LIST_FAILURE,
	payload: error
})

export const fetchAllDeliveryDriversList = () => {
	return async (dispatch) => {
		dispatch(pharmacyDeliveryDriversListRequest());
		try {
			const response = await Axios.get(`${path.pharmacy_driver_list}`)

			if (response?.status === 200) {
				dispatch(pharmacyDeliveryDriversListSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyDeliveryDriversListFailure(error?.response));
			}
		}
	}
}

