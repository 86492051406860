import { useSelector } from 'react-redux'

export const useAppSelector = () => {

    const appListResponse = useSelector(state => state.appList)

    return {
        appListResponse,
    }
}
