import {
	FETCH_HOLIDAYS_REQUEST,
	FETCH_HOLIDAYS_SUCCESS,
	FETCH_HOLIDAYS_FAILURE
} from "../../constants";

import Axios from '../../../networking/intraceptor'
import { pharmacy_stat_holiday } from "../../../networking/urlEndPoints";

export const fetchHolidaysRequest = () => ({
	type: FETCH_HOLIDAYS_REQUEST,
});

export const fetchHolidaysSuccess = (holidays) => ({
	type: FETCH_HOLIDAYS_SUCCESS,
	payload: holidays,
});

export const fetchHolidaysFailure = (error) => ({
	type: FETCH_HOLIDAYS_FAILURE,
	payload: error,
});

export const fetchStatHolidays = () => {
	return async (dispatch) => {
		dispatch(fetchHolidaysRequest())
		try {
			const response = await Axios.get(`${pharmacy_stat_holiday}`)
			if (response?.status === 200) {
				dispatch(fetchHolidaysSuccess(response?.data));
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchHolidaysFailure(error?.response))
			}
		}
	};
};