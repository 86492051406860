import { useEffect } from 'react';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick';

function ModalSlider() {

	useEffect(() => {
		const openModal = () => {
			const modal = $('#myModal');
			const slider = $('.slider');

			if (modal.length > 0 && slider.length > 0) {
				modal.fadeIn();

				if (!slider.hasClass('slick-initialized')) {
					slider.slick({
						dots: false,
						infinite: true,
						speed: 500,
						fade: true,
						cssEase: 'linear',
						prevArrow: '<button class="slick-prev">❮</button>',
						nextArrow: '<button class="slick-next">❯</button>'
					});
				}
			}
		}

		const closeModal = () => {
			const modal = $('#myModal');
			const slider = $('.slider');

			if (modal.length > 0 && slider.length > 0) {
				modal.fadeOut(() => {
					$('body').removeClass('modal-open');

					if (slider.hasClass('slick-initialized')) {
						slider.slick('unslick');
					}
				});
			}
		}

		$(document).ready(() => {
			$(document).on('click', '[id^="openModalBtn_"]', openModal);
			$('.close').on('click', closeModal);
			$('#prevBtn').on('click', () => $('.slider').slick('slickPrev'));
			$('#nextBtn').on('click', () => $('.slider').slick('slickNext'));

			$('#myModal').on('click', (event) => {
				event.stopPropagation();
			});
		});

		return () => {
			$(document).off('click', '[id^="openModalBtn_"]', openModal);
			$('.close').off('click', closeModal);
			$('#prevBtn').off('click');
			$('#nextBtn').off('click');
			$('#myModal').off('click');
		};
	}, []);


	return (null)

}

export default ModalSlider;


