import React, { useState } from 'react'

import { Footer, LoadingView } from '../../components/common'
import { useCustomDispatch } from '../../hooks'
import { SuccessToastMessage } from '../../components/loaders/toastMessage'
import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors'
import { onhandleValidateBreakSettingForm } from '../../utils/validations/adminModuleValidations'

import ChatBox from '../../components/chat/chatbox'
import Button from '../../components/common/button'

export const AssignTaskSelect = ({ formData, setFormData, errorMessage, employeeList, placeholder = 'Break Hours', padding = 'pb-2.5 pt-4' }) => {

    return (
        <div className=" w-full">
            <label
                className={`text-gray-600 mb-1`} style={{ fontSize: '14px' }}>
                Hours:
            </label>
            <select
                id="breakin_diff_hours"
                name="breakin_diff_hours"
                value={formData.breakin_diff_hours}
                placeholder="Break Hours"
                onChange={(e) => setFormData({ ...formData, breakin_diff_hours: e.target.value })}
                className={`block ${errorMessage.breakin_diff_hours && 'border-red-500'} scroll-box pl-4 w-full px-2.5 ${padding}  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
                bg-white `}>
                <option value="" disabled>{placeholder}</option>
                {employeeList?.map((option, index) => (
                    <React.Fragment key={index}>
                        <option value={option.value}>
                            {option.label}
                        </option>
                    </React.Fragment>
                ))}
            </select>
        </div>
    );
};

export const AssignbreadcheckControl = ({ formData, setFormData, errorMessage, employeeList, placeholder = 'Breakin diff minutes', padding = 'pb-2.5 pt-4' }) => {

    return (
        <div className=" w-full">
            <label
                className={`text-gray-600 mb-1`} style={{ fontSize: '14px' }}>
              Minutes
            </label>
            <select
                id="breakin_diff_minutes"
                name="breakin_diff_minutes"
                value={formData.breakin_diff_minutes}
                placeholder="Breakin diff minutes"
                onChange={(e) => setFormData({ ...formData, breakin_diff_minutes: e.target.value })}
                className={`block ${errorMessage.breakin_diff_minutes && 'border-red-500'} scroll-box pl-4 w-full px-2.5 ${padding}  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
          dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
          bg-white `}
            >
                <option value="" disabled>{placeholder}</option>
                {employeeList?.map((option, index) => (
                    <React.Fragment key={index}>
                        <option value={option.value}>
                            {option.label}
                        </option>
                    </React.Fragment>
                ))}
            </select>
        </div>
    );
};


const BreakSetting = ({ data }) => {

    const dispatch = useCustomDispatch()
    const [formData, setFormData] = useState({
        break_lock: false,
        break_time: "",
        break_time_diff: ""
    })
    const [errorMessage, setErrorMessages] = useState({
        break_time: '',
        breakin_diff_hours: '',
        breakin_diff_minutes: ''
    })
    const [showToast, setShowToast] = useState()
    const { addTeamDataResponse, TeamListResponse } = useAdminSelectors()


    const handleCheckboxChange = (e) => {
        setFormData({
            ...formData,
            break_lock: e.target.checked
        });
    };

    React.useEffect(() => {
        const timeParts = data && data?.break_time_diff?.split(':');
        let totalMinutes;
        if (timeParts?.length === 3) {
            const [hours, minutes, seconds] = timeParts.map(Number);

            totalMinutes = hours * 60 + minutes + seconds / 60;
        } else {
            // console.error("Invalid time string format");
        }
        setFormData({
            breakin_diff_minutes: data?.minutes,
            breakin_diff_hours: data?.hours,
            break_time: data?.break_time,
            break_lock: data?.break_lock === 1 ? true : false
        })
    }, [data])


    const handleValidateTaskMangerForm = () => {
        setShowToast('')
        onhandleValidateBreakSettingForm(formData, setErrorMessages, errorMessage, dispatch, setShowToast)
    }

    const numberList = [];
    const numberList1 = [];

    for (let i = 0; i < 60; i++) {
        const paddedValue = i.toString().padStart(2, '0');
        if (i < 8) {
            numberList.push({ value: paddedValue, label: paddedValue });
        }
        numberList1.push({ value: paddedValue, label: paddedValue });
    }

    return (
        <>
            <div className="container">
                <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">


                    {TeamListResponse.loading && TeamListResponse.type === 'break_payroll_data_initial' ?
                        <div className='flex justify-center items-center mt-60 mb-60'>
                            <LoadingView />
                        </div>
                        :
                        <>
                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                                <div>
                                    <div className=" w-full">
                                        <label
                                            className={`text-gray-600 mb-1`} style={{ fontSize: '14px' }}>
                                            Minimum Break Time in Minutes
                                        </label>
                                        <input type={'number'} id={'break_time'} autoComplete="off"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                if (value < 0) value = 0;
                                                if (value > 59) value = 59;
                                                setFormData({ ...formData, break_time: value })
                                            }}
                                            name={'break_time'}
                                            value={formData.break_time}
                                            className={`block pl-4 ${errorMessage.break_time && 'border-red-500'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                                    dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                    bg-white `}
                                            placeholder=""
                                        />

                                    </div>
                                </div>
                                <AssignTaskSelect
                                    formData={formData}
                                    setFormData={setFormData}
                                    errorMessage={errorMessage}
                                    employeeList={numberList}
                                />
                                <AssignbreadcheckControl
                                    formData={formData}
                                    setFormData={setFormData}
                                    errorMessage={errorMessage}
                                    employeeList={numberList1}
                                />
                            </div>

                            <div className="flex items-center pl-1">
                                <input
                                    id="check1"
                                    type="checkbox"
                                    name="break_lock"
                                    className='w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                    defaultChecked={data?.break_lock === 1 ? true : false}
                                    checked={formData.break_lock}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="check1" className="cursor-pointer text-gray-500 w-full py-3 ml-2 text-sm font-medium  dark:text-gray-300 fs-16 rubik-400">
                                    Check if Minimum Break is Mandatory.
                                </label>
                            </div>

                            <div className='mt-2'>
                                <Button color='white' width='100px'
                                    text={addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_break_setting' ? 'Processing...' : 'Submit'}
                                    handleFilter={() => addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_break_setting' ? null : handleValidateTaskMangerForm()} />
                            </div>

                        </>}
                </div>
            </div>

            <ChatBox />
            <Footer />
            {showToast && <SuccessToastMessage status={showToast} />}

        </>
    )
}

export default BreakSetting