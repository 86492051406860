import {
    INVENTORY_DATA_LIST_REQUEST,
    INVENTORY_DATA_LIST_SUCCESS,
    INVENTORY_DATA_LIST_FAILURE
} from "../../../constants"

import { pharmacy_api_inventory_list, pharmacy_reverse_rx } from './../../../../networking/urlEndPoints'
import Axios from "../../../../networking/intraceptor"

export const pharmacyInventoryListRequest = (fetchType) => ({
    type: INVENTORY_DATA_LIST_REQUEST,
    fetch_type: fetchType
})

export const pharmacyInventoryListSuccess = (data, fetchType) => ({
    type: INVENTORY_DATA_LIST_SUCCESS,
    payload: data,
    fetch_type: fetchType
})

export const pharmacyInventoryListFailure = (error, fetchType) => ({
    type: INVENTORY_DATA_LIST_FAILURE,
    payload: error,
    fetch_type: fetchType
})

export const fetchInventoryList = (type, params) => {
    return async (dispatch) => {
        dispatch(pharmacyInventoryListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_api_inventory_list}`, { params })
            if (response?.status === 200) {
                dispatch(pharmacyInventoryListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyInventoryListFailure(error?.response?.data, type))
            }
        }
    }
}

export const fetchTotalReversedList = (type, params) => {
    return async (dispatch) => {
        dispatch(pharmacyInventoryListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_reverse_rx}`, { params })
            if (response?.status === 200) {
                dispatch(pharmacyInventoryListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyInventoryListFailure(error?.response?.data, type))
            }
        }
    }
}