import {
	UPDATE_PHARMACY_USER_PROFILE_COVER_REQUEST,
	UPDATE_PHARMACY_USER_PROFILE_COVER_SUCCESS,
	UPDATE_PHARMACY_USER_PROFILE_COVER_FAILURE
} from '../../constants';

import { authToken } from '../../../storage/authToken';
import { pharmacy_uprofilecover_update } from '../../../networking/urlEndPoints';
import { persistor } from '../../../services/store/store'
import { fetchUserProfile } from './userProfileActions';

import Cookies from 'js-cookie';
import axios from 'axios';


export const updatePharmacyUserProfileCoverRequest = () => ({
	type: UPDATE_PHARMACY_USER_PROFILE_COVER_REQUEST
});

export const updatePharmacyUserProfileCoverSuccess = (coverImageUrl) => ({
	type: UPDATE_PHARMACY_USER_PROFILE_COVER_SUCCESS,
	payload: coverImageUrl
});

export const updatePharmacyUserProfileCoverFailure = (error) => ({
	type: UPDATE_PHARMACY_USER_PROFILE_COVER_FAILURE,
	payload: error
});

export const updateCoverPicture = (selectedFile) => {
	return async (dispatch) => {
		dispatch(updatePharmacyUserProfileCoverRequest());
		try {
			const formData = new FormData();
			formData.append('type', 'cover');

			if (selectedFile) {
				formData.append('cover_photo', selectedFile);
			}

			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_uprofilecover_update}`, formData, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${authToken()}`,
					'Content-Type': 'multipart/form-data',
				},
			});

			if (response?.status === 200) {
				dispatch(updatePharmacyUserProfileCoverSuccess(response?.data));
				dispatch(fetchUserProfile())
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response?.status === 401) {
				Cookies.remove('authToken')
				persistor.purge()
				window.location.href = '/'
			}
			else {
				dispatch(updatePharmacyUserProfileCoverFailure(error?.response));
			}
		}
	};
}
