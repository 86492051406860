export const TEAM_REQUEST_LIST_REQUEST = 'TEAM_REQUEST_LIST_REQUEST';
export const TEAM_REQUEST_LIST_SUCCESS = 'TEAM_REQUEST_LIST_SUCCESS';
export const TEAM_REQUEST_LIST_FAILURE = 'TEAM_REQUEST_LIST_FAILURE';

export const ADD_TEAM_DATA_REQUEST = 'ADD_TEAM_DATA_REQUEST';
export const ADD_TEAM_DATA_SUCCESS = 'ADD_TEAM_DATA_SUCCESS';
export const ADD_TEAM_DATA_FAILURE = 'ADD_TEAM_DATA_FAILURE';

export const COMMENT_LIST_REQUEST = 'COMMENT_LIST_REQUEST';
export const COMMENT_LIST_SUCCESS = 'COMMENT_LIST_SUCCESS';
export const COMMENT_LIST_FAILURE = 'COMMENT_LIST_FAILURE';

