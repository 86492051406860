import React, {
    useEffect,
    useState
} from 'react'

import { Loader } from "../../../components/common/loadingView";
import { TextInput} from "../../../components/pharmacy/dispense/dispense";

import { appListFailure, fetchTbSkinTestLogs } from '../../../services/actions/pharmacy/app/appCommon'

import { pharmacy_tb_log_pdf } from '../../../networking/urlEndPoints'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'

import { useAppSelector } from '../../../services/selectors/appSelector'
import { pharmacyPreviewInvoicePdf } from '../../../services/actions/pharmacy/forms/previewPdf';

import Button from '../../../components/common/button'
import ChatBox from '../../../components/chat/chatbox'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'

export const appUSerListHeading = [
    { text: "DATE", classNames: ['p-3'] },
    { text: "PATIENT NAME", classNames: ['p-3'] },
    { text: "PHN", classNames: ['p-3'] },
    { text: "LOT NUMBER", classNames: ['p-3'] },
];

export const TableRowData = ({ value, className1 = 'p-3' }) => {
    return (<td className={className1}>
        <p>{value}</p>
    </td>)
}

const TbSkinTestLogs = () => {

    const location = Hooks.useCustomLocation()
    const navigate = Hooks.useCustomNavigate()
    const dispatch = Hooks.useCustomDispatch()

    const { appListResponse } = useAppSelector()
    const appUserListResponse = appListResponse

    const [loadingStates, setLoadingStates] = useState({});
    const appUserList = appUserListResponse?.data?.data

    const searchParams = new URLSearchParams();
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    
    const [pageNumber, setPageNumber] = useState(initialPage)
    const [formData, setFormData] = useState({ search: '', status: '' })
    const [reset, setReset] = useState(false);
    const fetchType = 'tbskintype'

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        dispatch(appListFailure(null, null))
    }, [])

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: 10,
        }
        dispatch(fetchTbSkinTestLogs(fetchType, params))
        return () => { }
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: 10,

        };

        // if (formData.status) params.status = formData.status;
        if (formData.search) params.search = formData.search;

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
            dispatch(fetchTbSkinTestLogs(fetchType, params))
        }
        if (formData.search) dispatch(fetchTbSkinTestLogs(fetchType, params))

    };

    const handleSaveInvoicePdf = async (id) => {
        setLoadingStates((prevState) => ({ ...prevState, [id]: true }));
        let path = `${pharmacy_tb_log_pdf}?log_id=${id}`;
        await dispatch(pharmacyPreviewInvoicePdf(path));
        setLoadingStates((prevState) => ({ ...prevState, [id]: false }));
    }

    const handleReset = () => {
        searchParams.delete('page');
        setReset(true)
        setFormData({ ...formData, search: '' })
        setTimeout(() => {
            setReset(false)
        }, 500);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);

        const params = {
            page: pageNumber,
            no_of_records: 10,
        }

        dispatch(fetchTbSkinTestLogs(fetchType, params))
    }
    return (
        <React.Fragment>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">

                            <div className="content">

                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">
                                            <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-12/12' >
                                                <TextInput label='Search' name='search' formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex gap-2'>
                                                <Button bgColor='#3B82F6' width='70px' handleFilter={handlePagination} color='white' />
                                                <Button bgColor='White' width='70px' handleFilter={handleReset} text='Reset' />
                                            </div>

                                        </div>
                                        {appUserListResponse?.loading && appUserListResponse?.type === fetchType ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            appUserListResponse?.error !== null && appUserListResponse?.type === fetchType ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                appUserList?.data?.length === 0 && appUserListResponse?.type === fetchType ?
                                                    <div className="bg-white rounded-md mt-4">
                                                        <p className="text-gray-500">No TB Skin test log found.</p>
                                                    </div>
                                                    : appUserListResponse?.type === fetchType ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {appUSerListHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                            <th style={{ width: '200px' }}>VIEW PDF</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            appUserList?.data?.map((value, idx) => {
                                                                                return (<React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: '1px solid lightgray' }}>

                                                                                        <TableRowData value={value.date} />
                                                                                        <TableRowData value={value.patientname} />
                                                                                        <TableRowData value={value.patientphn} />
                                                                                        <TableRowData value={value.lot_number} />
                                                                                        <td className="p-3 flex gap-0 pl-1 items-center r" >  <button
                                                                                            style={{ height: "20px", width: '60px' }}
                                                                                            type="button"
                                                                                            onClick={() => handleSaveInvoicePdf(value.id)}
                                                                                            className=" sm:text-center md:text-start 
												 			                                lg:text-start rubik-400 fs-10 text-white rounded-md text-sm px-1 bg-red-500 py-1 flex justify-center items-center"
                                                                                        >
                                                                                            {loadingStates[value.id] ? <Loader /> : 'View PDF'}
                                                                                        </button></td>



                                                                                    </tr>
                                                                                </React.Fragment>)
                                                                            })}
                                                                    </tbody>
                                                                </table>

                                                                {appUserListResponse?.error !== null ? null : <>
                                                                    {appUserList?.data?.length !== 0 && <>
                                                                        {appUserList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={appUserList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <ChatBox />
            <Common.Footer />
        </React.Fragment>
    )
}

export default TbSkinTestLogs