import {
    ADD_INVENTORY_DATA_REQUEST,
    ADD_INVENTORY_DATA_SUCCESS,
    ADD_INVENTORY_DATA_FAILURE,
} from "../../../constants"

import {
    pharmacy_add_inventory,
    pharmacy_api_get_drug_by_din,
    pharmacy_api_sync_invertory,
    pharmacy_api_clear_inventory,
    pharmacy_authanticate_route,
    pharmacy_delete_inventory, update_inventory,
    pharmacy_api_update_inventory,
    pharmacy_travel_records
} from './../../../../networking/urlEndPoints'

import { fetchInventoryList } from "./inventoryList"

import {
    addRevenueDataFailure,
    addRevenueDataRequest,
    addRevenueDataSuccess,
} from "../revenue/addRevenueData"

import Axios from "../../../../networking/intraceptor"
import * as routeNames from '../../../../routes/routeName'
import { FetchTravelLogs, FetchTravelPatientList } from "../travel/travelList"

export const addInventoryRequest = (fetchType) => ({
    type: ADD_INVENTORY_DATA_REQUEST,
    fetch_type: fetchType
})

export const addInventorySuccess = (data, fetchType) => ({
    type: ADD_INVENTORY_DATA_SUCCESS,
    payload: data,
    fetch_type: fetchType
})

export const addInventoryFailure = (error, fetchType) => ({
    type: ADD_INVENTORY_DATA_FAILURE,
    payload: error,
    fetch_type: fetchType
})

export const AddInventoryData = (type, requestedData, setShowToast, setShowModal) => {
    return async (dispatch) => {

        dispatch(addInventoryRequest(type))
        try {
            const response = await Axios.post(`${pharmacy_add_inventory}`, requestedData)
            if (response?.status === 200) {
                dispatch(addInventorySuccess(response?.data, type))
                if (typeof setShowModal === 'function') {
                    setShowModal((prevstat) => ({ ...prevstat, addInventory: false }))
                    setShowToast((prevstat) => ({ ...prevstat, sucessToast: true }))

                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, sucessToast: false }))
                    }, 2000);

                    document.body.style.overflow = 'auto'
                }
                dispatch(fetchInventoryList('inventory_list'))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addInventoryFailure(error?.response?.data, type))
            }
        }
    }
}

export const FetchDrugListByDIN = (type, din, setFormData) => {
    return async (dispatch) => {

        dispatch(addInventoryRequest(type))
        try {
            const requestedData = {
                din: din
            }
            const response = await Axios.post(`${pharmacy_api_get_drug_by_din}`, requestedData)

            if (response?.status === 200) {
                dispatch(addInventorySuccess(response?.data, type))
                if (typeof setFormData === 'function') {
                    setFormData((prevstat) => ({ ...prevstat, drugName: (response?.data?.data?.drugName || '') }))
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addInventoryFailure(error?.response?.data, type))
            }
        }
    }
}

export const SyncInventoryData = (type, setShowToast) => {
    return async (dispatch) => {

        dispatch(addInventoryRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_api_sync_invertory}`)
            if (response?.status === 200) {
                dispatch(addInventorySuccess(response?.data, type))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, sucessToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, sucessToast: false }))
                    }, 2000);
                }
                dispatch(fetchInventoryList('inventory_list'))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addInventoryFailure(error?.response?.data, type))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}

export const ClearInventryData = (type, setShowToast) => {
    return async (dispatch) => {

        dispatch(addInventoryRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_api_clear_inventory}`)
            if (response?.status === 200) {
                dispatch(addInventorySuccess(response?.data, type))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, sucessToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, sucessToast: false }))
                    }, 2000);
                }
                dispatch(fetchInventoryList('inventory_list'))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addInventoryFailure(error?.response?.data, type))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}

export const VerifyPassocde = (type, requestedData, navigate, setShowToast, setErrorMessage) => {
    return async (dispatch) => {

        dispatch(addInventoryRequest(type))
        try {
            const response = await Axios.post(`${pharmacy_authanticate_route}`, requestedData)
            if (response?.status === 200) {
                dispatch(addInventorySuccess(response?.data, type))
                localStorage.setItem('@oat_p$(sDe)', true)
                navigate(routeNames.pharmacy_inventory_list)
                dispatch(fetchInventoryList('inventory_list'))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addInventoryFailure(error?.response?.data, type))
                if (typeof setShowToast === 'function') {
                    setErrorMessage('Authentication failed')
                    setShowToast(true)
                    setTimeout(() => {
                        setShowToast(false)
                    }, 2000);
                }
            }
        }
    }
}

export const DeleteInventroyList = (type, id, setShowToast, setShowModal, pageNumber, formData) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${pharmacy_delete_inventory}/${id}`)

            if (response?.status === 200) {

                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, deleteRevenueList: false }))
                    document.body.style.overflow = 'auto'
                }

                const params = {
                    currentsheet: formData.type,
                    page: pageNumber,
                    ...(formData.type === 'previous_sheet' && { searchdate: formData.date })
                };

                dispatch(fetchInventoryList("inventory_list", params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}


export const UpdateInventory = (type, requestedData, setShowToast, formData, pageNumber) => {
    return async (dispatch) => {

        dispatch(addInventoryRequest(type))
        try {
            const response = await Axios.post(`${pharmacy_api_update_inventory}`, requestedData)

            if (response?.status === 200) {
                dispatch(addInventorySuccess(response?.data, type))
                const params = {
                    currentsheet: formData.type,
                    page: pageNumber,
                    ...(formData.type === 'previous_sheet' && { searchdate: formData.date })
                };

                dispatch(fetchInventoryList("inventory_list", params))
            }

            else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addInventoryFailure(error?.response?.data, type))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
                const params = {
                    currentsheet: formData.type,
                    page: pageNumber,
                    ...(formData.type === 'previous_sheet' && { searchdate: formData.date })
                };

                dispatch(fetchInventoryList("inventory_list", params))
            }
        }
    }
}

// ----------------------Delete Travel logs list------------------------

export const DeleteTravelLogsList = (type, id, setShowToast, setShowModal, pageNumber, formData) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.delete(`${pharmacy_travel_records}/${id}`)

            if (response?.status === 200) {

                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, deleteRevenueList: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, sucessToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, sucessToast: false }))
                    }, 2000);
                }

                const params = {
                    list_type: 'travel_list',
                    page: pageNumber,
                    start_date: formData?.startDate,
                    end_date: formData?.endDate,
                }

                dispatch(FetchTravelLogs('travel_patients_list', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}

export const DeleteTravelPatients = (type, id, setShowToast, setShowModal, pageNumber) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${pharmacy_travel_records}/removeTpatient`, {
                patient_id: id
            })

            if (response?.status === 200) {

                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, deleteRevenueList: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, sucessToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, sucessToast: false }))
                    }, 2000);
                }
                dispatch(FetchTravelPatientList('travel_patients_list', pageNumber))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}