import {
	POST_COMMENTS_REPLY_LISTS_FAILURE,
	POST_COMMENTS_REPLY_LISTS_REQUEST,
	POST_COMMENTS_REPLY_LISTS_SUCCESS
} from "../../constants";

import { pharmacy_reply_list } from "../../../networking/urlEndPoints";

import Cookies from "js-cookie";
import Axios from "../../../networking/intraceptor";

export const fetchUserCommentsReplyListsRequest = () => ({
	type: POST_COMMENTS_REPLY_LISTS_REQUEST,
});

export const fetchUserCommentsReplyListsSuccess = (comments) => ({
	type: POST_COMMENTS_REPLY_LISTS_SUCCESS,
	payload: comments,
});

export const fetchUserCommentsReplyListsFailure = (error) => ({
	type: POST_COMMENTS_REPLY_LISTS_FAILURE,
	payload: error,
});

export const fetchAllCommentReplyLists = (setCommentReplyLoader, setAppendedCommentReplyValue, commentId, setCommentReplyListLoading) => {
	return async (dispatch) => {
		dispatch(fetchUserCommentsReplyListsRequest());
		try {
			const response = await Axios.post(`${pharmacy_reply_list}/${commentId}`);
			if (response?.status === 200) {
				dispatch(fetchUserCommentsReplyListsSuccess(response?.data));
				if (typeof setCommentReplyLoader === 'function') {
					setCommentReplyLoader(false)
					setAppendedCommentReplyValue([])
				}

				if (typeof setCommentReplyListLoading === 'function') {
					setCommentReplyListLoading(false)
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchUserCommentsReplyListsFailure(error?.response))
			}
			if (typeof setCommentReplyLoader === 'function') {
				setCommentReplyLoader(false)
				setAppendedCommentReplyValue([])
			}

			if (typeof setCommentReplyListLoading === 'function') {
				setCommentReplyListLoading(false)
			}

		}
	}
}

export const fetchPreviousCommentsReplyData = async (commentId, pageNumber, setCommentsReplyData, commentsReplyData, setPreviousCommentsReplies, previousCommentsReplies) => {
	try {
		const response = await Axios.post(`${pharmacy_reply_list}/${commentId}?no_of_records=${10}&page=${pageNumber}`);

		if (pageNumber === response?.data?.data?.pagination?.last_page) {
			Cookies.set('previous', 0)
		}
		setPreviousCommentsReplies({ ...previousCommentsReplies, loading: false });
		setCommentsReplyData([...response?.data?.data?.data, ...commentsReplyData])

	} catch (error) {
		setPreviousCommentsReplies({ ...previousCommentsReplies, loading: false });
	}
}