import React from 'react'

import { EyeIcon, PrintIcon } from '../../../assets/svg/icons'
import { currentDate, initalExpiringRxFormData } from '../../../utils/constants/formInitialStates'
import { ExpiringRxHeader } from '../../../constants/dispense'
import { fetchExpiringRxList } from '../../../services/actions/pharmacy/dispense/labelList'

import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { pharmacySearchRxNumberListSuccess } from '../../../services/actions/pharmacy/dispense/searchByRxNumberArchivedList'

import * as Common from '../../../components/common'
import * as Dispense from '../../../components/pharmacy/dispense/dispense'
import * as Hooks from '../../../hooks'
import * as routesNames from '../../../routes/routeName'
import * as routeNames from '../../../routes/routeName'

import ChatBox from '../../../components/chat/chatbox'
import Button from '../../../components/common/button'

const TableRowData = ({ value, className1 = 'p-3', className2 = 'rubik-400 fs-14' }) => {
	return (<td className={className1}>
		<p className={className2}>{value}</p>
	</td>)
}

const ExpiringRx = () => {

	const location = Hooks.useCustomLocation()
	const dispatch = Hooks.useCustomDispatch()
	const navigate = Hooks.useCustomNavigate()

	const [formData, setFormData] = React.useState(initalExpiringRxFormData);
	const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
	const from_date = (new URLSearchParams(location.search).get('date'))
	const [pageNumber, setPageNumber] = React.useState(initialPage)

	const [index, setIndex] = React.useState(null)

	const { dispenseLabelListsResponse } = useDispenseSelectors()
	const expiringRxList = dispenseLabelListsResponse?.data?.data

	React.useEffect(() => {
		setPageNumber(initialPage)
		if (from_date) {
			setFormData({ ...formData, date: from_date })
		}
	}, [initialPage, from_date])

	React.useEffect(() => {
		if (formData?.date) {
			const params = {
				search_date: from_date ? from_date : formData?.date,
				page: pageNumber,
			}
			dispatch(fetchExpiringRxList(params, 'expiring-rxsheet'))
		}
		return () => { }

	}, [from_date])

	const handleFilterData = (event, value) => {
		setIndex(null)
		const params = {
			search_date: formData?.date,
			page: value,
		}

		const searchParams = new URLSearchParams()

		searchParams.append('date', formData?.date)

		if (value !== undefined) {
			searchParams.append('page', value);
			setPageNumber(value)
		}

		if(formData.search) params.search = formData.search

		dispatch(fetchExpiringRxList(params, 'expiring-rxsheet'))
		const newUrl = `${routesNames?.pharmacy_expiring_rx}?${searchParams.toString()}`
		navigate(newUrl);
	}

	const handleReset = () => {
		setIndex(null)

		const searchParams = new URLSearchParams()
		searchParams.delete('date')

		searchParams.delete('page')
		setFormData({ ...formData, date: currentDate, search: '' })
		setPageNumber(1)
		const params = {
			search_date: currentDate,
			page: 1,
		}
		dispatch(fetchExpiringRxList(params, 'expiring-rxsheet'))
		const newUrl = `${routesNames?.pharmacy_expiring_rx}?${searchParams.toString()}`
		navigate(newUrl);
	}

	const handleDownLoadRxSheet = (number) => {
		dispatch(pharmacySearchRxNumberListSuccess(null))
		const url = `${routeNames.pharmacy_archived_deliveries}?active=searchrxsheet&rxnumber=${number}`;
		window.open(url, '_blank');
	}


	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">

					<div className="grid grid-cols-12 gap-4">
						{/* <Common.Sidebar /> */}
						<div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content">
								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
													sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<Common.BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className='font-semibold mb-4 text-xl'>
											<h1> Expiring Rx	</h1>
										</div>
										<div className="flex flex-wrap lg:flex-nowrap gap-3">
											<div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 w-full'>
												<Dispense.TextInput label='Search' name='search' formData={formData}
													setFormData={setFormData} />
											</div>

											<div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 w-full'>
												<Dispense.StartDate formData={formData}
													setFormData={setFormData} />
											</div>

											<div className='flex-0 flex lg:w-1/12 md:w-2/12 sm:w-4/12 w-full gap-2'>

												<Button color='white' text='Filter' handleFilter={handleFilterData} />

												<Button bgColor='white' color='gray' text='Reset' handleFilter={handleReset} />
											</div>
										</div>

										{dispenseLabelListsResponse?.loading && dispenseLabelListsResponse?.type === 'expiring-rxsheet' ?
											<div className="flex justify-center items-center mt-60 mb-60">
												<Common.LoadingView />
											</div> :
											dispenseLabelListsResponse?.error !== null && dispenseLabelListsResponse?.type === 'expiring-rxsheet' ?
												<div className="flex flex-col items-center justify-center mt-60 mb-60">
													<h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
													<p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
													<button onClick={() => window.location.reload()}
														className="px-4  bg-red-600 text-white rounded hover:bg-red-700 
																	focus:outline-none focus:ring-2 focus:ring-red-500">
														Please Try Again.
													</button>
												</div> :
												expiringRxList?.data?.length === 0 ?
													<div className="bg-white pt-4 rounded-md">
														<p className="text-gray-500">No Record Available.</p>
													</div>
													:
													dispenseLabelListsResponse?.type === 'expiring-rxsheet' ?
														<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
															<div className="shadow-md border rounded-lg relative overflow-x-auto">
																<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
																	<thead className="text-xs text-gray-900 uppercase dark:bg-gray-700 dark:text-gray-400" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
																		<tr>
																			{ExpiringRxHeader?.map((header, index) => (
																				<th key={index} scope="col" className={header?.classNames?.join(" ")}>
																					<p className="rubik-500 fs-14">{header?.text}</p>
																				</th>
																			))}
																		</tr>
																	</thead>

																	<tbody>
																		{
																			expiringRxList?.data?.map((expiringList, idx) => (
																				<React.Fragment key={idx}>
																					<tr style={{ borderBottom: `${'1px solid lightgray'}` }}>
																						<TableRowData value={expiringList?.route} />

																						<td className={'p-3'}>
																							<div className={'rubik-400 fs-14 flex cursor-pointer'} onClick={() => setIndex(idx)}>
																								<EyeIcon /> &nbsp;
																								{expiringList?.patient_name}
																							</div>
																						</td>

																						<TableRowData value={expiringList?.phn} />
																						<TableRowData value={expiringList?.phone} />
																						<TableRowData value={expiringList?.rx_count} />
																					</tr>

																					{idx === index &&
																						<tr style={{ borderBottom: `${'1px solid lightgray'}` }}>
																							<td colSpan='5' className='p-1'>
																								<div className='flex p-3 items-center shadow-lg  w-full ' key={index}>
																									{expiringList?.rx_data.map((value, index) => {
																										return (
																											<div key={index} className='flex items-center p-2 border border-b border-gray-200 ml-2'>
																												<div className='rubik-500 fs-12 font-bold flex'>

																													<span className='font-semibold ml-2'>
																														{value.rxnumber}
																													</span>

																													<span className='font-bold ml-2'>
																														{value.drug}
																													</span>

																													<span className='bg-red-100 btnnn p-1 rounded-md ml-4 -mt-1' onClick={() => handleDownLoadRxSheet(value.rxnumber)}>
																														<PrintIcon />
																													</span>
																												</div>

																											</div>
																										)
																									})}
																								</div>
																							</td>
																						</tr>}

																				</React.Fragment>
																			))
																		}
																	</tbody>
																</table>

																{dispenseLabelListsResponse?.error !== null ? null : <>
																	{expiringRxList?.data?.length !== 0 && <>
																		{expiringRxList?.pagination?.last_page !== 1 &&
																			<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center 
																			md:justify-center justify-center mt-5">
																				<nav aria-label="Page navigation example">
																					<ul className="inline-flex -space-x-px text-base h-10">
																						<CustomPagination
																							count={expiringRxList?.pagination?.last_page}
																							page={pageNumber}
																							onChange={(event, value) => {
																								handleFilterData(event, value)
																							}}
																						/>
																					</ul>
																				</nav>
																			</div>
																		}
																	</>}
																</>}

															</div>
														</div> : null
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ChatBox />
		</>

	)
}

export default ExpiringRx