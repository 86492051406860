import {
    ANNOUNCE_DELETE_REQUEST,
    ANNOUNCE_DELETE_SUCCESS,
    ANNOUNCE_DELETE_FAILURE

} from '../../../constants';


import Axios from '../../../../networking/intraceptor'
import * as path from '../../../../networking/urlEndPoints'


import { fetchAppAnnounce } from './appCommon';

export const deleteDataRequest = () => ({
    type: ANNOUNCE_DELETE_REQUEST
});

export const deleteDataSuccess = (user, types) => ({
    type: ANNOUNCE_DELETE_SUCCESS,
    payload: user,
    types: types
});

export const deleteDataFailure = (error, types) => ({
    type: ANNOUNCE_DELETE_FAILURE,
    payload: error,
    types: types
})



export const handleDeleteAnnouncements = (avrID, type, formData, pageNumber, setShowModal, showModal) => {
    return async (dispatch) => {
        dispatch(deleteDataRequest());

        try {
            const response = await Axios.post(`${path.delete_announcement}/${avrID}`,
                {
                    id: avrID
                })

            if (response?.status === 200) {
                dispatch(deleteDataSuccess(response?.data, type));

                if (typeof setShowModal === "function" && (type === 'DeleteAnnounceModal')) {
                    setShowModal(prevState => ({
                        ...prevState,
                        DeleteAnnounceModal: false,
                        showToast: true
                    }));

                    setTimeout(() => {
                        setShowModal(prevState => ({
                            ...prevState,
                            showToast: false
                        }));
                    }, 2000);

                    document.body.style.overflow = 'auto'

                    const params = {
                        no_of_records: 10,
                        page: pageNumber,

                    }
                    dispatch(fetchAppAnnounce('appAccouncements', params))

                }
            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error?.response) {
                dispatch(deleteDataFailure(error?.response, type));
            }
        }
    }
}

