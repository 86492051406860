
import Swal from 'sweetalert2';
import Axios from '../../networking/intraceptor';
import { fetchTimeOffRequestList } from '../../services/actions/admin/team/fetchTeamsList';

export const UpdateTimeOffRequestStatus = (text, id, url, type, dispatch, pageNumber) => {
    const swalWithTailwindButtons = Swal.mixin({
        customClass: {
            cancelButton: 'bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded',
            confirmButton: 'm-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded',
        },
        buttonsStyling: false
    });

    swalWithTailwindButtons.fire({
        title: `${text?.charAt(0)?.toUpperCase() + text?.slice(1)} Request`,
        text: `Are you sure you want to ${text} this request?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true,
        preConfirm: async () => {
            const confirmButton = Swal.getConfirmButton();
            confirmButton.textContent = 'Processing...';
            confirmButton.disabled = true;

            try {
                const response = await Axios.post(url, {
                    log_id: id,
                    type: type
                });
                if (response?.status === 200) {
                    swalWithTailwindButtons.fire({
                        title: 'Success',
                        text: 'The request has been processed successfully.',
                        icon: 'success'
                    });
                    const params = {
                        page: pageNumber
                    }
                    dispatch(fetchTimeOffRequestList('time_off_request_list', params))

                    setTimeout(() => {
                        Swal.close()
                    }, 1000);
                } else {
                    throw new Error(response);
                }
            } catch (error) {
                swalWithTailwindButtons.fire({
                    title: 'Error',
                    text: 'Oops! Got an issue while updating status. Please try again later.',
                    icon: 'error'
                })
            }
        }
    });
}
