import {
	PHARMACY_TEAM_NOTES_MESSAGE_REQUEST,
	PHARMACY_TEAM_NOTES_MESSAGE_SUCCESS,
	PHARMACY_TEAM_NOTES_MESSAGE_FAILURE
} from '../../../constants';
import { pharmacy_team_notes } from '../../../../networking/urlEndPoints';

import Axios from '../../../../networking/intraceptor'

export const teamNotesMessageRequest = () => ({
	type: PHARMACY_TEAM_NOTES_MESSAGE_REQUEST,
});

export const teamNotesMessageSuccess = (user) => ({
	type: PHARMACY_TEAM_NOTES_MESSAGE_SUCCESS,
	payload: user,
});

export const teamNotesMessageFailure = (error) => ({
	type: PHARMACY_TEAM_NOTES_MESSAGE_FAILURE,
	payload: error,
});

export const fetchTeamNotesMessage = () => {
	return async (dispatch) => {
		dispatch(teamNotesMessageRequest());
		try {
			const response = await Axios.get(`${pharmacy_team_notes}`);

			if (response?.status === 200) {
				dispatch(teamNotesMessageSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(teamNotesMessageFailure(error?.response));
			}
		}
	}
}

