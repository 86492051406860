import {
	ADD_COMMENTS_LIKES_REQUEST,
	ADD_COMMENTS_LIKES_SUCCESS,
	ADD_COMMENTS_LIKES_FAILURE
} from "../../constants";

import { pharmacy_user_comment_likes } from "../../../networking/urlEndPoints";
import Axios from "../../../networking/intraceptor";

export const addUserCommentLikesRequest = () => ({
	type: ADD_COMMENTS_LIKES_REQUEST,
});

export const addUserCommentLikesSuccess = (comments) => ({
	type: ADD_COMMENTS_LIKES_SUCCESS,
	payload: comments
});

export const addUserCommentLikesFailure = (error) => ({
	type: ADD_COMMENTS_LIKES_FAILURE,
	payload: error,
});

export const addPostCommentsLikes = (id, like_unlike) => {
	return async (dispatch) => {
		dispatch(addUserCommentLikesRequest());
		try {
			const response = await Axios.post(`${pharmacy_user_comment_likes}`, {
				comment_id: id,
				like_unlike: like_unlike,
			});
			if (response?.status === 200) {
				dispatch(addUserCommentLikesSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(addUserCommentLikesFailure(error?.response))
			}
		}
	}
}
