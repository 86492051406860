import React, { useState} from 'react'

import {
    Footer,
    LoadingView
} from '../../components/common'

import { useCustomDispatch } from '../../hooks'

import ChatBox from '../../components/chat/chatbox'
import { SuccessToastMessage } from '../../components/loaders/toastMessage'
import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors'
import Button from '../../components/common/button'
import { onhandleValidatePharmacyTimeSettingForm } from '../../utils/validations/adminModuleValidations'
import { convertTo24HourFormat } from '../../models/admin/teamTimeSheet'

export const AssignTaskSelect = ({ formData, setFormData, errorMessage, employeeList, placeholder = 'Select day', padding = 'pb-2.5 pt-4' }) => {
    const dispatch = useCustomDispatch()

    return (
        <div className="relative w-full">
            <select
                id="breakin_diff_hours"
                name="Select day"
                value={formData.schedule_day}
                placeholder="Break Hours"
                onChange={(e) => setFormData({ ...formData, schedule_day: e.target.value })}
                className={`block ${errorMessage.schedule_day && 'border-red-500'} scroll-box pl-4 w-full px-2.5 ${padding}  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
          dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
          bg-white `}
            >
                <option value="" disabled>{placeholder}</option>
                {employeeList?.map((option, index) => (
                    <React.Fragment key={index}>
                            <option value={option.value}>
                                {option.label}
                            </option>
                    </React.Fragment>
                ))}
            </select>
        </div>
    );
};

const TextInput = ({ formData,
    setFormData,
    label,
    errorMessage,
    dVal,
    name = "" }) => {

    return (<div className="relative w-full">
        <input type={'time'} id={name} autoComplete="off"
            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
            name={name}
            value={formData[name]}
            className={`block ${errorMessage && 'border-red-500'} pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
            bg-white `}
            placeholder=""
        />
        <label htmlFor={name}
            className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                        origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                        peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
            {errorMessage ? '' : label}
        </label>
        {errorMessage && <p className='text-red-500 fs-12 rubik-400'>{errorMessage}</p>}
    </div>)
}


const PharmacyTimeSetting = ({data}) => {

    const dispatch = useCustomDispatch()
    const [formData, setFormData] = useState({
        schedule_day: data?.start_of_week_work,
        end_time: data?.end_time ? convertTo24HourFormat(data?.end_time) : '',
        start_time: data?.startTime ? convertTo24HourFormat(data?.startTime) : ''
    })
    const [errorMessage, setErrorMessages] = useState({
        schedule_day: '',
        end_time: '',
        start_time: ''
    })
    const [showToast, setShowToast] = useState()
    const { addTeamDataResponse,TeamListResponse } = useAdminSelectors()

    React.useEffect(()=>{
        setFormData({schedule_day: data?.start_of_week_work,
        end_time: data?.end_time ? convertTo24HourFormat(data?.end_time) : '',
        start_time: data?.startTime ? convertTo24HourFormat(data?.startTime) : ''})
    },[data])

    
    const handleValidateTaskMangerForm = () => {
        setShowToast('')
        let newErrorMessages = { ...errorMessage };
        let isValid = true;

        // Check if start time and end time are provided
        if (!formData.start_time) {
            newErrorMessages.start_time = 'Start time is required';
            isValid = false;
        } else {
            newErrorMessages.start_time = '';
        }

        if (!formData.end_time) {
            newErrorMessages.end_time = 'End time is required';
            isValid = false;
        } else {
            newErrorMessages.end_time = '';
        }

        // Check if end time is later than start time
        if (formData.start_time && formData.end_time) {
            if (formData.start_time >= formData.end_time) {
                newErrorMessages.end_time = 'End time must be later than start time';
                isValid = false;
            }
        }

        setErrorMessages(newErrorMessages);

        if (isValid) {
            onhandleValidatePharmacyTimeSettingForm(formData, setErrorMessages, newErrorMessages, dispatch, setShowToast);
        }
    }
    
    return (
        <>
            <div className="container">
                <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                {TeamListResponse.loading && TeamListResponse.type === 'break_payroll_data_initial' ?
                        <div className='flex justify-center items-center mt-60 mb-60'>
                            <LoadingView />
                        </div>
                        :
                        <>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">

                    <AssignTaskSelect
                            formData={formData}
                            setFormData={setFormData}
                            errorMessage={errorMessage}
                            employeeList={[
                                { value: "1", label: "Monday" },
                                { value: "2", label: "Tuesday" },
                                { value: "3", label: "Wednesday" },
                                { value: "4", label: "Thursday" },
                                { value: "5", label: "Friday" },
                                { value: "6", label: "Saturday" },
                                { value: "7", label: "Sunday" }
                            ]}
                        />
                       
                            <div>
                                <TextInput formData={formData} setFormData={setFormData} dVal={formData.start_time} errorMessage={errorMessage.start_time} name='start_time' label='START TIME' textType='time' />
                            </div>
                            <div>
                                <TextInput formData={formData} setFormData={setFormData} dVal={formData?.end_time} errorMessage={errorMessage.end_time} name='end_time' label='END TIME' textType='time' />
                            </div>
                        </div>
                        </>}
                    </div>
                   
                    <div className='mt-2'>
                        <Button color='white' width='100px'
                            text={addTeamDataResponse?.loading && addTeamDataResponse.type === 'enforcement_schedule_setting' ? 'Processing...' : 'Submit'}
                            handleFilter={() => addTeamDataResponse?.loading && addTeamDataResponse.type === 'enforcement_schedule_setting' ? null : handleValidateTaskMangerForm()} />
                    </div>
                  
                </div>
               
            <ChatBox />
            <Footer />
            {showToast && <SuccessToastMessage status={showToast} />}

        </>
    )
}

export default PharmacyTimeSetting