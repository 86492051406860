
export function pharmacyMembershipAccessList() {
	return {
		pharmacy_patient: 'Patient Management',
		pharmacy_settings: 'Account Settings',
		pharmacy_oat: 'OAT Logs',
		pharmacy_oat_reminder: 'OAT Log Reminders',
		pharmacy_forms: 'Custom Forms',
		pharmacy_winrx_integration: 'Winrx Integration',
		pharmacy_fax_integration: 'Fax Integration',
		pharmacy_text_message: 'Text Message',
		pharmacy_daily_dispense: 'Daily Dispense',
		pharmacy_doctor_integration: 'Doctor Appointment',
		pharmacy_compounds: 'Compounds',
		pharmacy_blister: 'Blister',
		pharmacy_arv: 'ARV',
		pharmacy_vials: 'VIALS',
		pharmacy_fda: "FDA's",
		pharmacy_minor_ailment: 'Minor Ailment',
		pharmacy_speciality_medication: 'Speciality Medication',
		pharmacy_revinue_management: 'Revinue',
		pharmacy_team_management: 'Team',
		pharmacy_compound_price_list: 'Compound Price List',
		app_mode: 1
	}
}