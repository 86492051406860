import {
    ADD_REVENUE_DATA_REQUEST,
    ADD_REVENUE_DATA_SUCCESS,
    ADD_REVENUE_DATA_FAILURE,
} from "../../constants"

import {
    update_frequent_deductible,
    frequent_dispensing_view_pdf
} from '../../../networking/urlEndPoints'

import Axios from "../../../networking/intraceptor"
import { PreviewPdf } from "../../../utils/helper/helper"
import { fetchFrequestDispensingAuthList } from "./fdaFetch"

export const addFDAFormDataRequest = (fetchType) => ({
    type: ADD_REVENUE_DATA_REQUEST,
    fetch_type: fetchType
})

export const addFDAFormDataSuccess = (data, fetchType) => ({
    type: ADD_REVENUE_DATA_SUCCESS,
    payload: data,
    fetch_type: fetchType
})

export const addFDAFormDataFailure = (error, fetchType) => ({
    type: ADD_REVENUE_DATA_FAILURE,
    payload: error,
    fetch_type: fetchType
})

export const UpdateAllGeneratedFDAList = (type, setShowToast, pack_id, inputType, deductible) => {
    return async (dispatch) => {

        dispatch(addFDAFormDataRequest(type))
        try {
            const requestedData = {
                id: pack_id,
                col: inputType,
                value: deductible
            }

            const response = await Axios.post(`${update_frequent_deductible}`, requestedData)
            if (response?.status === 200) {

                dispatch(addFDAFormDataSuccess(response?.data, type))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, successToast: false }))
                    }, 1000);
                }
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addFDAFormDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}

export const UpdatePrescriberList = (type, requestedData, setShowModal, pageNumber,setShowToast) => {
    return async (dispatch) => {

        dispatch(addFDAFormDataRequest(type))
        try {
            const response = await Axios.post(`${update_frequent_deductible}`, requestedData)
            if (response?.status === 200) {

                dispatch(addFDAFormDataSuccess(response?.data, type))

                // if (typeof setShowModal === 'function') {
                setShowModal((prevStat) => ({ ...prevStat, updatePrescriberList: false }))
                // }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, successToast: true }))
                    document.body.style.overflow = 'auto'

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, successToast: false }))
                    }, 2000);
                }

                const params = {
                    page: pageNumber,
                    no_of_records: 10,
                };

                dispatch(fetchFrequestDispensingAuthList("all_generated_fda_form_list", params));

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addFDAFormDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}


export const PrintGenratedPdfView = (type, id, setShowToast) => {
    return async (dispatch) => {

        dispatch(addFDAFormDataRequest(type))
        try {
            const response = await Axios.post(`${frequent_dispensing_view_pdf}/${id}`)

            if (response?.status === 200) {
                dispatch(addFDAFormDataSuccess(response?.data, type))
                PreviewPdf(response?.data?.data)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addFDAFormDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}

