import React, { useState } from 'react'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'
import * as routeNames from '../../../routes/routeName'

import ChatBox from '../../../components/chat/chatbox'
import UploadedTriplicates from './uploadedTriplicates'
import UploadPrescription from './uploadePrescription'

const OnGoingPrescriptions = () => {

    const navigate = Hooks.useCustomNavigate()

    const [activeTab, setActiveTab] = useState('uploaded_prescriptions');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`${routeNames.pharmacy_ongoing_prescritiptions}?active=${tab}`);
    };

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const activeParam = params.get('active');

        if (activeParam === 'uploaded_triplicates') {
            setActiveTab('uploaded_triplicates');
        }
    }, []);

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
													sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        
                                        <div className="mb-4 border-b border-gray-200 dark:border-gray-700 flex">
                                            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="filter-tab" role="tablist">

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block border-b-1 rounded-t-sm p-4 ${activeTab === 'uploaded_prescriptions' ? 'bg-gray-100' : ''}`}
                                                        id="filter-row-tab" onClick={() => handleTabChange('uploaded_prescriptions')}
                                                        type="button" role="tab" aria-controls="filter-row"
                                                        aria-selected={activeTab === 'uploaded_prescriptions' ? 'true' : 'false'}>
                                                        Uploaded Prescriptions
                                                    </button>
                                                </li>

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'uploaded_triplicates' ? 'bg-gray-100' : ''}`}
                                                        id="export-row-tab" onClick={() => {
                                                            return handleTabChange('uploaded_triplicates')

                                                        }}
                                                        type="button" role="tab" aria-controls="export-row"
                                                        aria-selected={activeTab === 'uploaded_triplicates' ? 'true' : 'false'}>
                                                        Uploaded Triplicates
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div id="filter-tab-content">
                                            <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'uploaded_prescriptions' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">
                                                {activeTab === 'uploaded_prescriptions' && <>
                                                    <div className="flex flex-wrap gap-y-3">
                                                        <UploadPrescription activeTab={activeTab} />
                                                    </div>
                                                </>}
                                            </div>

                                            <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'uploaded_triplicates' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">
                                                {activeTab === 'uploaded_triplicates' && <>
                                                    <UploadedTriplicates activeTab={activeTab} />
                                                </>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />
        </>
    )
}

export default OnGoingPrescriptions