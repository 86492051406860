import { useSelector } from 'react-redux'

export const useDispenseSelectors = () => {

	const addDispenseDataResponse = useSelector(state => state.addDispenseData)
	const searchDeliverySupervisorListResponse = useSelector(state => state.searchDeliverySupervisorList)
	const searchDeliverySupervisorList = searchDeliverySupervisorListResponse?.data?.data

	const dispenseListDataResponse = useSelector(state => state.dispenseListData)
	const deleteDispenseDataResponse = useSelector(state => state.deleteDispenseData)
	const deliveryDriversListResponse = useSelector(state => state.deliveryDriversList)
	const deliveryDriversLists = deliveryDriversListResponse?.data?.data?.data

	const archivedDelveriesListResponse = useSelector(state => state.archivedDelveriesList)
	const pharmacyPatientRouteListResponse = useSelector(state => state.pharmacyPatientRouteList)
	const patientPrescriptionDetailsResponse = useSelector(state => state.patientPrescriptionDetails)
	const searchByRxNumberArchivedListResponse = useSelector(state => state.searchByRxNumberArchivedList)

	const dispenseLabelListsResponse = useSelector(state => state.dispenseLabelLists)
	const addLabelsDataResponse = useSelector(state => state.addLabelsData)

	const syncTodaysDelveriesResponse = useSelector(state => state.syncTodaysDelveries)

	const pharmacyPatientUpdateRxWitnessDoseResponse = useSelector(state => state.pharmacyPatientUpdateRxWitnessDose)
	const validateLabelInputResponse = useSelector(state => state.validateLabelInput)
	const dispenseMemo = useSelector(state => state.dispenseMemo)
	const dispenseMemoUpdateReminderResponse = useSelector(state => state.reminderUpdateMemoState)
	
	return {
		addDispenseDataResponse,
		searchDeliverySupervisorListResponse,
		searchDeliverySupervisorList,
		dispenseListDataResponse,
		deleteDispenseDataResponse,
		deliveryDriversListResponse,
		deliveryDriversLists,
		archivedDelveriesListResponse,
		pharmacyPatientRouteListResponse,
		patientPrescriptionDetailsResponse,
		searchByRxNumberArchivedListResponse,
		dispenseLabelListsResponse,
		addLabelsDataResponse,
		syncTodaysDelveriesResponse,
		pharmacyPatientUpdateRxWitnessDoseResponse,
		validateLabelInputResponse,
		dispenseMemo,
		dispenseMemoUpdateReminderResponse,
	}
}
