import {
	FETCH_CHAT_USER_LIST_REQUEST,
	FETCH_CHAT_USER_LIST_SUCCESS,
	FETCH_CHAT_USER_LIST_FAILURE
} from "../../constants";

import { pharmacy_group_list } from "../../../networking/urlEndPoints";
import Axios from '../../../networking/intraceptor'

export const fetchChatUserListRequest = () => ({
	type: FETCH_CHAT_USER_LIST_REQUEST,
})

export const fetchChatUserListSuccess = (data) => ({
	type: FETCH_CHAT_USER_LIST_SUCCESS,
	payload: data,
})

export const fetchChatUserListFailure = (error) => ({
	type: FETCH_CHAT_USER_LIST_FAILURE,
	payload: error,
})

export const fetchAllChatUserList = (user_name, setLoading) => {
	return async (dispatch) => {
		dispatch(fetchChatUserListRequest())
		try {
			const response = await Axios.get(`${pharmacy_group_list}`, {
				params: {
					user_name: user_name
				}
			})
			if (response?.status === 200) {
				dispatch(fetchChatUserListSuccess(response?.data))
				if (typeof setLoading === 'function') {
					setLoading(false)
				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchChatUserListFailure(error?.response))
				if (typeof setLoading === 'function') {
					setLoading(false)
				}
			}
		}
	}
}
