import React, {
	useRef
} from 'react';

import { ModalCloseIcon } from '../../assets/svg/icons';
import { useDispenseSelectors } from '../../services/selectors/dispenseSelectors';
import { LoadingView } from '../../components/common';
import { ErrorMessages } from '../../components/error/alertMessages';

import * as useEffectHelper from '../../utils/helper/useEffectsHelper';

export const RXModal = ({ setShowModal, showModal, onClose = null }) => {

	const modalRef = useRef(null);

	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'rxModal', modalRef)

	const { dispenseListDataResponse } = useDispenseSelectors();
	const patientMedicineList = dispenseListDataResponse?.data?.data?.data

	const headers = [
		"RX#",
		"DRUG",
		"DOCTOR",
		"PREV (DAYS)",
		"QTY [PREV]",
		"REFFILS LEFT",
		"QTY LEFT",
		"SIG"
	]

	return (
		<React.Fragment>

			{showModal.rxModal && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100" style={{ zIndex: '99999999999999999999999999' }}>
					<div ref={modalRef} className="relative max-h-full" style={{ width: '80%' }}>
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">

								</h3>
								<button
									onClick={onClose ? onClose : () => { }}
									type="button"
									className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
								>
									<ModalCloseIcon />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							<div className="container mt-2">
								{dispenseListDataResponse?.loading && dispenseListDataResponse?.type === 'patient_pending_list' ?
									<div className="flex justify-center items-center  pt-40 pb-60">
										<LoadingView />
									</div>
									: dispenseListDataResponse?.error !== null && dispenseListDataResponse?.type === 'patient_pending_list' ?
										<div className='pb-40'>
											<ErrorMessages />
										</div> :
										patientMedicineList?.length === 0 && dispenseListDataResponse?.type === 'patient_pending_list' ?
											<div className='flex items-center justify-center  pb-20 pt-20'>
												<p className='rubik-400 text-gray-500'>No Record Available.</p>
											</div>
											:
											patientMedicineList?.length > 0 && dispenseListDataResponse?.type === 'patient_pending_list' ?
												<div className="shadow-md relative overflow-x-auto p-5">
													<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto" style={{ borderCollapse: 'collapse', border: '1px solid lightgray' }}>
														<thead className="text-xs text-white uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
															<tr>
																{headers?.map((header, index) => (
																	<th key={index} scope="col" className={`p-3${index === headers.length - 1 ? ' rounded-e-lg' : ''}`} style={{ borderRight: '1px solid lightgray', borderBottom: '1px solid lightgray' }}>
																		<p className="rubik-500 fs-14">{header}</p>
																	</th>
																))}
															</tr>
														</thead>
														<tbody>
															{
																patientMedicineList?.map((value, idx) => (
																	<tr style={{ borderBottom: '1px solid lightgray' }} key={idx}>
																		<td  className="p-3" style={{ borderRight: '1px solid lightgray' }}>
																			<p className="rubik-400 fs-14">{value.rxnumber}</p>
																		</td>
																		<td  className="p-3" style={{ borderRight: '1px solid lightgray' }}>
																			<p className="rubik-400 fs-14">{value.drug}</p>
																		</td>
																		<td  className="p-3" style={{ borderRight: '1px solid lightgray' }}>
																			<p className="rubik-400 fs-14">{value.doctor}</p>
																		</td>
																		<td  className="p-3" style={{ borderRight: '1px solid lightgray' }}>
																			<p className="rubik-400 fs-14">{value.prev_days}</p>
																		</td>
																		<td  className="p-3" style={{ borderRight: '1px solid lightgray' }}>
																			<p className="rubik-400 fs-14">{value.qty_prev}</p>
																		</td>
																		<td  className="p-3" style={{ borderRight: '1px solid lightgray' }}>
																			<p className="rubik-400 fs-14">{value.refills_left}</p>
																		</td>
																		<td  className="p-3" style={{ borderRight: '1px solid lightgray' }}>
																			<p className="rubik-400 fs-14">{value.qty_left}</p>
																		</td>
																		<td  className="p-3" style={{ borderRight: '1px solid lightgray' }}>
																			<p className="rubik-400 fs-14">{value.rxsig}</p>
																		</td>
																	</tr>
																))
															}
														</tbody>
													</table>
												</div>


												: null}
							</div>
						</div>
					</div>
				</div>
			)
			}
		</React.Fragment >
	)
}

