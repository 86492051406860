import {
	PATIENT_NOTES_LIST_REQUEST,
	PATIENT_NOTES_LIST_SUCCESS,
	PATIENT_NOTES_LIST_FAILURE
} from "../../constants";

import { pharmacy_get_patient_notes, p_reminder_nofication } from "../../../networking/urlEndPoints"
import Axios from "../../../networking/intraceptor"

export const patientNotesListRequest = (types) => ({
	type: PATIENT_NOTES_LIST_REQUEST,
	types: types
})

export const patientNotesListSuccess = (data,types) => ({
	type: PATIENT_NOTES_LIST_SUCCESS,
	payload: data,
	types: types
})

export const patientNotesListFailure = (error,types) => ({
	type: PATIENT_NOTES_LIST_FAILURE,
	payload: error,
	types: types
})

export const fetchPatientNotesList = (type = 'fetchPatientNotesList') => {
	return async (dispatch) => {
		dispatch(patientNotesListRequest(type))
		try {
			const response = await Axios.get(`${pharmacy_get_patient_notes}`)
			if (response?.status === 200) {
				dispatch(patientNotesListSuccess(response?.data,type))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(patientNotesListFailure(error?.response,type))
			}
		}
	}
}

export const FetchPatientReminderNotificationList = (params,type='FetchPatientReminderNotificationList') => {
	return async (dispatch) => {
		dispatch(patientNotesListRequest(type))
		try {
			const response = await Axios.get(`${p_reminder_nofication}`,{params})
			if (response?.status === 200) {
				dispatch(patientNotesListSuccess(response?.data,type))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(patientNotesListFailure(error?.response,type))
			}
		}
	}
}