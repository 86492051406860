import {
	ADD_WALL_POST_REQUEST,
	ADD_WALL_POST_SUCCESS,
	ADD_WALL_POST_FAILURE,
} from '../../constants';

import { authToken } from '../../../storage/authToken';
import { pharmacy_add_wall_post } from '../../../networking/urlEndPoints';
import { persistor } from '../../../services/store/store'

import axios from 'axios';
import Cookies from 'js-cookie';
import { fetchUserWallLists } from './userWallListActions';

export const addWallPostRequest = () => ({
	type: ADD_WALL_POST_REQUEST,
});

export const addWallPostSuccess = (data) => ({
	type: ADD_WALL_POST_SUCCESS,
	payload: data,
});

export const addWallPostFailure = (error) => ({
	type: ADD_WALL_POST_FAILURE,
	error: error,
});

export const createWallPost = (postWallContent, postWallImage, setOpenModal, postWallVideo, type) => {
	return async (dispatch) => {
		dispatch(addWallPostRequest());
		try {
			const formData = new FormData();
			if (postWallContent) {
				formData.append('post', postWallContent);
			}

			if (postWallImage) {
				postWallImage?.forEach((file, index) => {
					formData.append(`wall_post_images[${index}]`, file);
				});
			}

			if (postWallVideo?.length !== 0) {
				formData.append('wall_video', postWallVideo)
			}

			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_add_wall_post}`, formData, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${authToken()}`,
					'Content-Type': 'multipart/form-data',
				},
			})

			if (response?.status === 200) {
				dispatch(addWallPostSuccess(response?.data));

				if (type === 'my-profile') {
					dispatch(fetchUserWallLists())
				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response?.status === 401) {
				Cookies.remove('authToken')
				persistor.purge()
				window.location.href = '/'
			}
			else {
				dispatch(addWallPostFailure(error?.response));
			}
		}
	}
}