const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

const initialState = {
    sidebarOpen: false,
};


const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                sidebarOpen: action.payload,
            };
        default:
            return state;
    }
};

export default sidebarReducer