import React, { useEffect, useState } from 'react'

import {
	initalPharmacyInformationUpdateForm,
	initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import {
	BreadCrumb,
	Footer,
	Sidebar
} from '../../../components/common'

import { Loader } from '../../../components/common/loadingView'
import { Errors } from '../../../components/error/alertMessages'
import { useCustomDispatch } from '../../../hooks'

import { onhandlePharmacyFormDataChange } from '../../../utils/data/handleFormDataChange'
import { handleDoctorCommunicationForm } from '../../../utils/validations/formsValidations'

import { SuccessToastMessage } from '../../../components/loaders/toastMessage'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'

import { TextEditor } from '../../../components/pharmacy/form/textEditor'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

import ChatBox from '../../../components/chat/chatbox'

import * as Pharmacy from '../../../components/pharmacy/form/form'
import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'
import { faxFormFailure } from '../../../services/actions/pharmacy/forms/pharmacyUpdateNotificationsSrFaxForm'

const InputFeilds = ({ id, onChange, value, className, placeholder, label, errorMessage, name }) => {
	return (
		<div className="relative">
			<input
				type="text"
				id={id}
				onChange={onChange}
				name={name}
				value={value}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessage && 'border-red-600'
					} ${className}`}
				placeholder={placeholder}
			/>
			<label
				htmlFor={id}
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessage && label}
			</label>
			{errorMessage && (
				<p className="mt-2 text-xs text-red-600 dark:text-red-400">
					{errorMessage}
				</p>
			)}
		</div>
	);
};

const DoctorCommunicationForm = () => {

	const dispatch = useCustomDispatch()
	const { pharmacyLoginData } = useCustomSelectors()

	const { previewPdfResponse, sendFaxResponse } = useFormsSelectors()

	const [formData, setFormData] = useState(initalPharmacyInformationUpdateForm)
	const [errorMessages, setErrorMessages] = useState(initalPharmacyInformationUpdateForm)
	const [showToast, setShowToast] = useState(initalShowToastMessage)

	const handleFormDataChange = (event) => {
		onhandlePharmacyFormDataChange(event, setFormData, formData, setErrorMessages, errorMessages);
	}

	useEffect(() => {
		if (pharmacyLoginData) {
			setFormData({
				...formData,
				pharmacyManager: pharmacyLoginData.manager_name,
				pharmacyName: pharmacyLoginData.name
			})
		}
	}, [pharmacyLoginData])

	useEffect(() => {
		dispatch(previewPdfFailure(null))
		dispatch(faxFormFailure(null))
	}, [])

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">

								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
											<div className="relative">
												<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
													{[
														{ id: 1, label: 'With Patient' },
														{ id: 2, label: 'Without Patient' },
													]?.map((item, index) => (
														<li key={index} className={`ml-4 w-full border-b border-gray-200 sm:${index === 3 ? 'border-b-0' : 'border-b-0 sm:border-r'} dark:border-gray-600`}>
															<div className="flex items-center ps-3">
																<input id={item.id} name='doctorCommnicationForm' type="radio"
																	onChange={(e) => { return setFormData({ ...formData, doctorCommnicationForm: item.label }) }}
																	defaultValue={formData?.doctorCommnicationForm} defaultChecked={index === 0}
																	className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
																/>
																<label htmlFor={item.id} className="w-full rubik-400  py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
																	{item.label}
																</label>
															</div>
														</li>
													))}
												</ul>

											</div>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 mt-2">
											<Pharmacy.Date formData={formData}
												setFormData={setFormData} />
											{
												formData.doctorCommnicationForm === 'With Patient' ?
													<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
														<Pharmacy.FormWinRxPatients
															formData={formData}
															handleFormDataChange={handleFormDataChange} />
													</ul> : null
											}
										</div>

										{
											formData.doctorCommnicationForm === 'With Patient' ?
												<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
													<Pharmacy.PatientPhn
														handleFormDataChange={handleFormDataChange}
														formData={formData}
														errorMessages={errorMessages}
														setErrorMessages={setErrorMessages}
														setFormData={setFormData} />

													<Pharmacy.PatientName
														handleFormDataChange={handleFormDataChange}
														formData={formData}
														errorMessages={errorMessages}
														setFormData={setFormData}
														setErrorMessages={setErrorMessages} />

													<Pharmacy.PatientDob
														formData={formData}
														setFormData={setFormData}
														setErrorMessages={setErrorMessages}
														handleFormDataChange={handleFormDataChange}
														errorMessages={errorMessages} />
												</div> : null
										}

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-4">

											<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
												{[
													{ id: 4, label: 'clinic', name: 'Clinic', },
													{ id: 5, label: 'pharmacy', name: 'Pharmacy' },
													{ id: 6, label: 'custom', name: 'Custom' },
												]?.map((item, index) => (
													<li key={index} className={`btnnn ml-4 w-full border-b border-gray-200 sm:${index === 3 ? 'border-b-0' : 'border-b-0 sm:border-r'} dark:border-gray-600`}>
														<div className="flex items-center ps-3">
															<input id={item.id} name='doctorCommnicationFormType'
																type="radio"
																onChange={(e) => { return setFormData({ ...formData, doctorCommnicationFormType: item.label }) }}
																defaultValue={formData?.doctorCommnicationFormType} defaultChecked={index === 0}
																className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
															/>
															<label htmlFor={item.id} className="w-full rubik-400  py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
																{item.name}
															</label>
														</div>
													</li>
												))}
											</ul>

										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<div className="relative ot_addNoteText">
												<div className="relative did-floating-label-content">
													{formData.doctorCommnicationFormType === 'clinic' ? <>
														<Pharmacy.ClinicName
															formData={formData}
															errorMessages={errorMessages}
															setFormData={setFormData}
															setErrorMessages={setErrorMessages} />

														<Pharmacy.AddClinic /> </> : formData.doctorCommnicationFormType === 'pharmacy' ?
														<Pharmacy.ToPharmacy formData={formData}
															errorMessages={errorMessages}
															setFormData={setFormData}
															setErrorMessages={setErrorMessages} /> :

														<div className='mt-4'>
															<InputFeilds
																id="customClinicName"
																onChange={handleFormDataChange}
																name={'customClinicName'}
																value={formData.customClinicName}
																className={errorMessages.customClinicName && 'border-red-600'}
																placeholder=""
																label={!errorMessages.customClinicName && 'Name'}
																errorMessage={errorMessages.customClinicName}
															/>
														</div>
													}
												</div>
											</div>

											<div className="relative ot_addNoteText">
												<div className="relative did-floating-label-content">
													{formData.doctorCommnicationFormType !== 'custom' ? <>
														<Pharmacy.DoctorList
															formData={formData}
															errorMessages={errorMessages}
															setFormData={setFormData}
															setErrorMessages={setErrorMessages} />
														<Pharmacy.AddDoctor /></>
														:
														<div className='mt-4'>
															<InputFeilds
																id="customDoctor"
																name={'customDoctor'}
																onChange={handleFormDataChange}
																value={formData.customDoctor}
																className={errorMessages.customDoctor && 'border-red-600'}
																placeholder=""
																label={!errorMessages.customDoctor && 'Custom Doctor'}
																errorMessage={errorMessages.customDoctor}
															/>
														</div>
													}
												</div>
											</div>

											{formData.doctorCommnicationFormType === 'clinic' ?
												<Pharmacy.ClinicFaxNumber
													handleFormDataChange={handleFormDataChange}
													formData={formData}
													errorMessages={errorMessages} /> :
												formData.doctorCommnicationFormType === 'pharmacy' ?

													<Pharmacy.ToPharmacyFaxNumber handleFormDataChange={handleFormDataChange}
														errorMessages={errorMessages}
														formData={formData} /> :

													<div className="relative ot_addNoteText">
														<div className="relative did-floating-label-content mt-4">
															<InputFeilds
																id="customToFaxNumber"
																onChange={handleFormDataChange}
																name={'customToFaxNumber'}
																value={formData.customToFaxNumber}
																className={errorMessages.customToFaxNumber && 'border-red-600'}
																placeholder=""
																label={!errorMessages.customToFaxNumber && 'To Fax Number'}
																errorMessage={errorMessages.customToFaxNumber}
															/>
														</div>
													</div>
											}
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
											<div className={`relative ${formData.doctorCommnicationFormType === 'custom' ? '' : 'mt-8'}`}>
												<InputFeilds
													id="subjectOfFax"
													onChange={handleFormDataChange}
													value={formData.subjectOfFax}
													name={'subjectOfFax'}
													className={errorMessages.subjectOfFax && 'border-red-600'}
													placeholder=""
													label={!errorMessages.subjectOfFax && 'Subject of fax'}
													errorMessage={errorMessages.subjectOfFax} />
											</div>
										</div>

										<br />
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
											<div className='relative'>
												<TextEditor formData={formData} setFormData={setFormData} />
											</div>
										</div>

										<br /> <br />

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<Pharmacy.LoginPharmacyName
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
											/>

											<Pharmacy.LoginPharmacyManger handleFormDataChange={handleFormDataChange}
												formData={formData}
												setFormData={setFormData}
												errorMessages={errorMessages} />

											<Pharmacy.Designation
												handleFormDataChange={handleFormDataChange}
												setFormData={setFormData}
												formData={formData} errorMessages={errorMessages} />
										</div>


										{previewPdfResponse?.error !== null &&
											<>
												<br />
												<Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
											</>
										}

										{sendFaxResponse?.error !== null &&
											<>
												<br />
												<Errors error={sendFaxResponse?.error.message ?
													sendFaxResponse?.error?.message :
													'Something went wrong when sending Fax. Please try again'} />
											</>
										}
										<div className='flex'>
											<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
												<button style={{ height: '40px' }} type="button"

													onClick={() => handleDoctorCommunicationForm(
														formData,
														setErrorMessages,
														errorMessages,
														dispatch,
														'PDF')}

													className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

													{previewPdfResponse?.loading ? <>
														Genrating PDF
														&nbsp;<Loader /> </> : 'Preview PDF'}

												</button>
											</div>

											{pharmacyLoginData?.srFax_active === 1 && pharmacyLoginData?.srFax_status === 1 &&  formData.doctorCommnicationFormType === 'clinic' &&
												<div className="pl-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
													<button style={{ height: '40px', backgroundColor: 'lightseagreen' }} type="button"

														onClick={() => handleDoctorCommunicationForm(
															formData,
															setErrorMessages,
															errorMessages,
															dispatch,
															'FAX',
															showToast,
															setShowToast)}

														className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center" >

														{sendFaxResponse?.loading ? <>
															Sending
															&nbsp;<Loader /> </> : 'Send Fax'}

													</button>
												</div>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
			<ChatBox />
			<Footer />

			{showToast.showToast && <SuccessToastMessage status={'Fax sent'} />}

		</>
	)
}

export default DoctorCommunicationForm