import {
	FETCH_PHARMACY_PATIENT_DATA_REQUEST,
	FETCH_PHARMACY_PATIENT_DATA_SUCCESS,
	FETCH_PHARMACY_PATIENT_DATA_FAILURE
} from "../../constants"

import { pharmacy_patient_data } from "../../../networking/urlEndPoints"

import Axios from "../../../networking/intraceptor"

export const patientDataRequest = () => ({
	type: FETCH_PHARMACY_PATIENT_DATA_REQUEST,
})

export const patientDataSuccess = (data) => ({
	type: FETCH_PHARMACY_PATIENT_DATA_SUCCESS,
	payload: data,
})

export const patientDataFailure = (error) => ({
	type: FETCH_PHARMACY_PATIENT_DATA_FAILURE,
	payload: error,
})

export const fetchPatientData = (phn) => {
	return async (dispatch) => {
		dispatch(patientDataRequest())
		try {
			const response = await Axios.get(`${pharmacy_patient_data}?phn=${phn}`)
			if (response?.status === 200) {
				dispatch(patientDataSuccess(response?.data))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(patientDataFailure(error?.response))
			}
		}
	}
}
