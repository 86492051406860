import {
	PHARMACY_ALL_TABLE_LIST_DATA_REQUEST,
	PHARMACY_ALL_TABLE_LIST_DATA_SUCCESS,
	PHARMACY_ALL_TABLE_LIST_DATA_FAILURE
} from '../../../constants';

import Axios from '../../../../networking/intraceptor'

export const pharamcyDashboardListRequest = () => ({
	type: PHARMACY_ALL_TABLE_LIST_DATA_REQUEST
});

export const pharamcyDashboardListSuccess = (data) => ({
	type: PHARMACY_ALL_TABLE_LIST_DATA_SUCCESS,
	payload: data,
});

export const pharamcyDashboardListFailure = (error) => ({
	type: PHARMACY_ALL_TABLE_LIST_DATA_FAILURE,
	payload: error,
});

export const fetchAllTableListData = (path, search, page) => {
	return async (dispatch) => {
		dispatch(pharamcyDashboardListRequest());
		try {
			const params = {
				no_of_records: 10,
			}

			if (search) params.search = search
			if (page) params.page = page

			const response = await Axios.get(`${path}`, { params });
			if (response?.status === 200) {
				dispatch(pharamcyDashboardListSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharamcyDashboardListFailure(error?.response?.data));
			}
		}
	}
}

