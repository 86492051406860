import {
	PATIENT_UPDATE_STATUS_REQUEST,
	PATIENT_UPDATE_STATUS_SUCCESS,
	PATIENT_UPDATE_STATUS_FAILURE
} from "../../../constants"

const initialState = {
	loading: false,
	data: null,
	error: null,
};

const updatePatientStatusReducer = (state = initialState, action) => {
	switch (action.type) {
		case PATIENT_UPDATE_STATUS_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				data: null,
			};
		case PATIENT_UPDATE_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			}
		case PATIENT_UPDATE_STATUS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null
			}
		default:
			return state;
	}
}
export default updatePatientStatusReducer