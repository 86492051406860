export const handleAuthApiError = (error) => {
	let errorMessage = 'An error occurred.';
	if (error) {
		switch (error) {
			case 400:
				errorMessage = 'Bad Request';
				break;
			case 401:
				errorMessage = 'Unauthorized';
				break;
			case 403:
				errorMessage = 'Forbidden';
				break;
			case 404:
				errorMessage = 'Invalid User or Password !';
				break;
			case 408:
				errorMessage = 'Request Timeout Error';
				break;
			case 500:
				errorMessage = 'Internal Server Error';
				break;
			case 502:
				errorMessage = 'Bad Gateway Error';
				break;
			case 504:
				errorMessage = 'Gateway Timeout Error';
				break;
			default:
				errorMessage = 'An error occurred while processing your request.';
		}
	}
	return errorMessage;
};


export const handleUpdateApiError = (error) => {
	let errorMessage = 'An error occurred.';
	if (error) {
		switch (error) {
			case 400:
				errorMessage = 'Bad Request';
				break;
			case 401:
				errorMessage = 'Unauthorized';
				break;
			case 403:
				errorMessage = 'Forbidden';
				break;
			case 404:
				errorMessage = 'Something Went Wrong Please try again later..!!';
				break;
			case 408:
				errorMessage = 'Request Timeout Error';
				break;
			case 500:
				errorMessage = 'Internal Server Error';
				break;
			case 502:
				errorMessage = 'Bad Gateway Error';
				break;
			case 504:
				errorMessage = 'Gateway Timeout Error';
				break;
			default:
				errorMessage = 'An error occurred while processing your request.';
		}
	}
	return errorMessage;
};
