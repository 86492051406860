import {
    handleUpdatePharmacyFrontProfileLogo,
    HandleUpdatePharmacyHour,
    HandleUpdatePharmacyProfile,
    HandleUpdatePharmacyServiceProfile
} from "../../services/actions/admin/team/addTeamData";

import { useEffect, useState } from "react";
import { handleHideModal, onPlaceSetPateintAddresses } from "../../assets/js/custome";
import { AddressIcon, CityIcon, EnvelopIcon, LocationIcon, ModalCloseIcon, ProvinceIcon } from "../../assets/svg/icons";
import { useCustomDispatch } from "../../hooks";

import { useAdminSelectors } from "../../services/selectors/adminModuleSelectors";
import { TextEditor } from "../../components/pharmacy/form/textEditor";
import { TextInput } from "../../components/pharmacy/dispense/dispense";
import { initalPatientAddressForm } from "../../utils/constants/formInitialStates";
import { usePlacesWidget } from "react-google-autocomplete";
import { handleUpdatePatientAddress } from "../../utils/data/handleFormDataChange";
import { autoSearchKey } from "../../networking/urlEndPoints";
import { useCustomPatientSelectors } from "../../services/selectors/patientSelectors";
import { fetchPharmacyServiceDetails } from "../../services/actions/pharmacy/arv/patientARVList";
import { useARVSelectors } from "../../services/selectors/arvSelectors";
import { PharmacyServices } from "../../components/pharmacy/admin/form";

export const UpdatePharmacyLogo = ({ showModal, setShowModal, setShowToast }) => {

    const dispatch = useCustomDispatch()

    const { addTeamDataResponse } = useAdminSelectors()

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChanges = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleUpdateLogo = () => {
        dispatch(handleUpdatePharmacyFrontProfileLogo('update_logo', setShowModal, setShowToast, selectedFile))
    }

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Logo
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'pharmacyLogo')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4">
                        <div className="grid grid-cols-1 place-items-center">
                            <div className="p-6 md:p-5 space-y-4 flex items-center">
                                <div className="flex items-center">
                                    <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <div className="flex items-center justify-center">
                                            <div className="border-2 border-dashed border-gray-300 p-8 text-center">
                                                {selectedFile ? (
                                                    <div className="relative">
                                                        <img src={URL.createObjectURL(selectedFile)} alt="Selected Logo" className="h-32 w-auto" />
                                                        <button type='text' className='bg-blue-500 rounded-md text-white border-none py-0.5 fs-12 mt-5 rubik-500' onClick={handleUpdateLogo}>
                                                            {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_logo' ? 'Updating...' : 'Update'}
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleFileChanges}
                                                            className="hidden"
                                                            id="uploadLogo"
                                                        />
                                                        <label htmlFor="uploadLogo" className="cursor-pointer">
                                                            <p className="mb-1">Drag and drop file</p>
                                                            <p className="mb-3">OR</p>
                                                            <button className="bg-green-500 text-white px-4 py-2 rounded">SELECT</button>
                                                        </label>
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const UpdatePharmacyFrontProfile = ({ showModal, setShowModal, setShowToast, data }) => {

    const { addTeamDataResponse } = useAdminSelectors()
    const dispatch = useCustomDispatch()
    const [formData, setFormData] = useState({ textForFaxValue: '', pharmacyName: '' })

    const handleUpdateProfile = () => {
        const { textForFaxValue, pharmacyName } = formData
        const reqData = {
            from_type: 'profile_info',
            name: pharmacyName,
            about_pharmacy: textForFaxValue
        }
        if (pharmacyName && textForFaxValue) {
            dispatch(HandleUpdatePharmacyProfile('update_profile', setShowModal, setShowToast, reqData))
        }
    }

    useEffect(() => {
        setFormData((prevStat) => ({
            ...prevStat,
            pharmacyName: data?.pharmacyName,
        }))
    }, [data])

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Profile
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'updateProfile')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4" style={{ height: '350px' }}>
                        <div className='relative'>
                            <TextInput formData={formData} setFormData={setFormData} name="pharmacyName" label="PHARMACY NAME" />
                        </div>

                        <div className="grid grid-cols-1 place-items-center mt-2">
                            <div className='relative'>
                                <TextEditor formData={formData} setFormData={setFormData} className='w-64' placeholder="About The Pharmacy" height="h-36" />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 place-items-center mt-16">
                            <button type='text' className='bg-blue-500 rounded-md text-white w-full border-none py-0.5 fs-12 mt-5 rubik-500' onClick={handleUpdateProfile}>
                                {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_profile' ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export const AddMultilePhotosForPharmacy = ({ showModal, setShowModal, setShowToast }) => {
    const dispatch = useCustomDispatch();
    const { addTeamDataResponse } = useAdminSelectors();
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChanges = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleAddMultiplePhotos = () => {
        dispatch(handleUpdatePharmacyFrontProfileLogo('add_pharmacy_photos', setShowModal, setShowToast, selectedFiles, 'pharmacy-gallery-images'));
    };

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Logo
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'addMultiplePhotos')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
                                text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4">
                        <div className="grid grid-cols-1 place-items-center">
                            <div className="p-6 md:p-5 space-y-4 flex items-center">
                                <div className="flex items-center">
                                    <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <div className="flex items-center justify-center">
                                            <div className="border-2 border-dashed border-gray-300 p-8 text-center">
                                                {selectedFiles?.length > 0 ? (<>
                                                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                                                        {selectedFiles.map((file, index) => (
                                                            <div key={index} className="mb-4">
                                                                <img src={URL.createObjectURL(file)} alt={`Selected Logo ${index}`} className="h-32 w-auto" />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <button type='text' className='bg-blue-500  w-full rounded-md text-white border-none py-0.5 fs-12 mt-5 rubik-500' onClick={handleAddMultiplePhotos}>
                                                        {addTeamDataResponse?.loading && addTeamDataResponse.type === 'add_pharmacy_photos' ? 'Updating...' : 'Update'}
                                                    </button>
                                                </>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            onChange={handleFileChanges}
                                                            className="hidden"
                                                            id="uploadLogo"
                                                        />
                                                        <label htmlFor="uploadLogo" className="cursor-pointer">
                                                            <p className="mb-1">Drag and drop files</p>
                                                            <p className="mb-3">OR</p>
                                                            <button className="bg-green-500 text-white px-4 py-2 rounded">SELECT</button>
                                                        </label>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const UpdatePharmacyContactFrontProfile = ({ showModal, setShowModal, setShowToast, data, contactData, pharmacyLoginId }) => {

    const { addTeamDataResponse } = useAdminSelectors()
    const dispatch = useCustomDispatch()
    const { provinceList } = useCustomPatientSelectors()
    const [formData, setFormData] = useState({ manager_name: '', owner_name: '', fax: '', phone: '', website: '' })
    const [updateContactAddress, setUpdateontactAddress] = useState({
        fullAddress: "",
        address: "",
        city: "",
        postalCode: "",
        province: "BC",
        country: "",
        mapUrl: "",
        lat: "",
        long: "",
    })
    const pharmacyInputs = [
        { name: "manager_name", label: "Manager Name" },
        { name: "owner_name", label: "Owner Name" },
        { name: "fax", label: "Fax Number" },
        { name: "phone", label: "Phone Number" },
        // { name: "website", label: "Website" },
    ];

    const handleUpdateProfile = () => {
        const reqData = {
            from_type: 'contact_info',
            lat: updateContactAddress?.lat,
            long: updateContactAddress?.long,
            country: updateContactAddress?.country,
            full_address: updateContactAddress?.fullAddress,
            manager_name: formData?.manager_name,
            owner_name: formData?.owner_name,
            fax: formData?.fax,
            phone: formData?.phone,
            website: formData?.website,
            zip_code: updateContactAddress?.postalCode,
            city: updateContactAddress?.city,
            location: updateContactAddress?.address,
        }

        // if (formData?.manager_name) {
        dispatch(HandleUpdatePharmacyProfile('update_profile', setShowModal, setShowToast, reqData, pharmacyLoginId))
        // }
    }

    const { ref: ref1 } = usePlacesWidget({
        apiKey: autoSearchKey,
        onPlaceSelected: (place) => onPlaceSetPateintAddresses(place, setUpdateontactAddress, updateContactAddress),
        options: {
            types: ["address"],
            componentRestrictions: { country: "ca" },
            limit: 7,
            googleLogo: false
        },
    });

    useEffect(() => {
        setFormData((prevStat) => ({
            ...prevStat,
            manager_name: contactData?.manager_name,
            owner_name: contactData?.owner_name,
            fax: contactData?.fax,
            phone: contactData?.phone,
            website: contactData?.website
        }))
        // setFormData(contactData)

        setUpdateontactAddress({
            fullAddress: contactData?.full_address,
            address: contactData?.address,
            city: contactData?.city,
            postalCode: contactData?.zip_code,
            province: contactData?.province,
            country: contactData?.country,
            // mapUrl: contactData?.,
            lat: contactData?.lat,
            long: contactData?.long,
        })
    }, [data, contactData])

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-6 bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow-lg dark:bg-gray-700'>
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Contact Detail
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'updateContactProfile')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4" style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                        <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-1">

                            {pharmacyInputs.map(({ name, label, type }, index) => (
                                <div key={index} className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                    <TextInput formData={formData} setFormData={setFormData} name={name} label={label} />
                                </div>
                            ))}


                            {/* Address Input */}
                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">

                                    <input
                                        type="text"
                                        className={`block  px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => handleUpdatePatientAddress(e, setUpdateontactAddress, updateContactAddress)}
                                        ref={ref1}
                                        name='fullAddress'
                                        value={updateContactAddress.fullAddress}
                                        id="fullAddress"
                                        placeholder=""
                                    />
                                    <label htmlFor="fullAddress" className={` ml-4 absolute false text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
							origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
							peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
							peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                        Address
                                    </label>
                                </div>
                            </div>

                            {/* City Input */}
                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">

                                    <input
                                        type="text"
                                        className={`block  px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => handleUpdatePatientAddress(e, setUpdateontactAddress, updateContactAddress)}
                                        name='city'
                                        value={updateContactAddress.city}
                                        id="city"
                                        placeholder=""
                                    />
                                    <label htmlFor="city" className={`ml-4 absolute false text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
							origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
							peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
							peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                        City
                                    </label>
                                </div>
                            </div>

                            {/* Province Input */}
                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">

                                    <select
                                        style={{ height: '48px' }}
                                        className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => handleUpdatePatientAddress(e, setUpdateontactAddress, updateContactAddress)}
                                        value={updateContactAddress.province}
                                        name="province"
                                    >
                                        {provinceList?.data?.map((value, index) => (
                                            <option className="fs-14" value={value.short_name} key={index}>{value.long_name}</option>
                                        ))}
                                    </select>
                                    <label className="did-floating-label"
                                        style={{
                                            left: '18px',
                                            color: 'grey', position: 'absolute', paddingLeft: "5px", top: '-8px',
                                            backgroundColor: 'white', fontSize: '11px'
                                        }}>Province</label>
                                </div>
                            </div>

                            {/* Postal Code Input */}
                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">

                                    <input
                                        type="text"
                                        className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => handleUpdatePatientAddress(e, setUpdateontactAddress, updateContactAddress)}
                                        name='postalCode'
                                        value={updateContactAddress.postalCode}
                                        id="postalCode"
                                        placeholder=""
                                    />
                                    <label htmlFor="postalCode" className={`ml-4 absolute false text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
							origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
							peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
							peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                        Zip Code
                                    </label>
                                </div>
                            </div>


                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">

                                    <input
                                        type="text"
                                        className={`block  px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => { setFormData({ ...formData, website: e.target.value }) }}
                                        value={formData?.website}
                                        name='website'
                                        id="website"
                                        placeholder="Website"
                                    />
                                    <label htmlFor="website" className={`ml-4 absolute false text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
							origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
							peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
							peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                        Website
                                    </label>
                                </div>
                            </div>
                        </div>


                        {/* Update Button */}
                        <div className="grid grid-cols-1 place-items-center">
                            <button type='button' className='bg-blue-500 rounded-md text-white w-full border-none py-2 text-sm mt-5 transition duration-200 hover:bg-blue-600'
                                onClick={handleUpdateProfile}>
                                {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_profile' ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};





const UpdatePharmacyOpenHourFrontProfile = ({ showModal, setShowModal, hourData, setShowToast, pharmacyLoginId }) => {
    const { addTeamDataResponse } = useAdminSelectors();
    const dispatch = useCustomDispatch();

    const [availability, setAvailability] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const convertTo24HourFormat = (time12h) => {
        const [time, modifier] = time12h.trim().split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours);
        if (hours === 12) {
            hours = modifier === 'PM' ? 12 : 0;
        } else if (modifier === 'PM') {
            hours = hours + 12;
        }
        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };

    const convertTo12HourFormat = (time24h) => {
        const [hours, minutes] = time24h.split(':');
        const hour = parseInt(hours);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes} ${ampm}`;
    };

    const handleUpdateHours = () => {
        //console.log("handleUpdateHours called. Availability:", availability);
        const formData = new FormData();

        if (!availability || typeof availability !== 'object' || Object.keys(availability).length === 0) {
            //console.error("Availability data is missing or invalid:", availability);
            setShowToast({ message: "Error: Invalid availability data", type: "error" });
            return;
        }

        try {
            Object.entries(availability).forEach(([dayId, day]) => {
                if (!day || !day.day || !Array.isArray(day.workingdayhours)) {
                    console.error(`Invalid day data for dayId ${dayId}:`, day);
                    throw new Error(`Invalid day data for ${day.day || dayId}`);
                }

                formData.append(`${day.day}[day_status]`, day.workingdayhours.length > 0);

                day.workingdayhours.forEach((hour, index) => {
                    if (!hour || typeof hour.start !== 'string' || typeof hour.end !== 'string') {
                        console.error(`Invalid hour data for ${day.day}, index ${index}:`, hour);
                        throw new Error(`Invalid hour data for ${day.day}`);
                    }

                    formData.append(`${day.day}[hour][${index}][from]`, hour.start.trim());
                    formData.append(`${day.day}[hour][${index}][to]`, hour.end.trim());
                });
            });

            dispatch(HandleUpdatePharmacyHour('update_profile_hour', setShowModal, setShowToast, formData, pharmacyLoginId));
        } catch (error) {
            console.error("Error processing availability data:", error);
            setShowToast({ message: `Error: ${error.message}`, type: "error" });
        }
    };

    const handleToggleAvailability = (dayId) => {
        setAvailability((prev) => ({
            ...prev,
            [dayId]: {
                ...prev[dayId],
                workingdayhours: prev[dayId].workingdayhours.length ? [] : [{ start: "09:00 AM", end: "05:00 PM" }]
            }
        }));
    };

    const handleAddHour = (dayId) => {
        setAvailability((prev) => ({
            ...prev,
            [dayId]: {
                ...prev[dayId],
                workingdayhours: [...prev[dayId].workingdayhours, { start: "09:00 AM", end: "05:00 PM" }]
            }
        }));
    };

    const handleDeleteHour = (dayId, index) => {
        setAvailability((prev) => ({
            ...prev,
            [dayId]: {
                ...prev[dayId],
                workingdayhours: prev[dayId].workingdayhours.filter((_, i) => i !== index)
            }
        }));
    };

    const handleHourChange = (dayId, index, field, value) => {
        setAvailability((prev) => ({
            ...prev,
            [dayId]: {
                ...prev[dayId],
                workingdayhours: prev[dayId].workingdayhours.map((hour, i) =>
                    i === index ? { ...hour, [field]: convertTo12HourFormat(value) } : hour
                )
            }
        }));
    };

    useEffect(() => {

        if (hourData && Array.isArray(hourData) && hourData.length > 0) {
            const newAvailability = {};
            hourData.forEach(day => {
                newAvailability[day.id] = {
                    ...day,
                    workingdayhours: day.workingdayhours.map(hour => ({
                        ...hour,
                        start: hour.start.trim(),
                        end: hour.end.trim()
                    }))
                };
            });
            setAvailability(newAvailability);
        } else {
            console.error("Invalid hourData:", hourData);
            setShowToast({ message: "Error: Invalid hour data received", type: "error" });
        }
        setIsLoading(false);
    }, [hourData, setShowToast]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!hourData || Object.keys(availability).length === 0) {
        return <div>No data available</div>;
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-6 bg-black bg-opacity-50">
            <div className="relative p-4 w-full max-w-2xl max-h-full bg-white rounded-lg shadow-lg overflow-auto" style={{ maxWidth: "45rem" }}>
                <div className="flex items-center justify-between p-4 border-b">
                    <h3 className="text-xl font-semibold text-gray-900">Update Open Hour</h3>
                    <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1"
                    >
                        ✖️
                    </button>
                </div>

                <div className="container mx-auto p-4">
                    <form className="space-y-4">
                        <table className="min-w-full border-collapse border border-gray-300">
                            <tbody>
                                {Object.values(availability).map((day) => (
                                    <tr key={day.id} className="hover:bg-gray-100">
                                        <td className="p-2 text-center">{day.day}</td>
                                        <td className="p-2 text-center">
                                            <label className="flex items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={day.workingdayhours.length > 0}
                                                    onChange={() => handleToggleAvailability(day.id)}
                                                    className="hidden"
                                                />
                                                <div className={`w-14 h-8 rounded-full flex items-center p-1 transition duration-300 ease-in-out ${day.workingdayhours.length ? 'bg-blue-500' : 'bg-gray-300'}`}>
                                                    <div className={`bg-white w-6 h-6 rounded-full shadow-md transform transition duration-300 ease-in-out ${day.workingdayhours.length ? 'translate-x-6' : 'translate-x-0'}`} />
                                                </div>
                                                <span className="ml-2">{day.workingdayhours.length > 0 ? 'Open' : 'Closed'}</span>
                                            </label>
                                        </td>
                                        <td colSpan="2">
                                            {day.workingdayhours.map((hour, hourIndex) => (
                                                <div key={hourIndex} className="flex items-center mb-3 mt-2">
                                                    <input
                                                        type="time"
                                                        value={convertTo24HourFormat(hour.start)}
                                                        onChange={(e) =>
                                                            handleHourChange(day.id, hourIndex, 'start', e.target.value)
                                                        }
                                                        className="w-auto p-2 border rounded mr-2"
                                                    />
                                                    <input
                                                        type="time"
                                                        value={convertTo24HourFormat(hour.end)}
                                                        onChange={(e) =>
                                                            handleHourChange(day.id, hourIndex, 'end', e.target.value)
                                                        }
                                                        className="w-auto p-2 border rounded mr-2"
                                                    />

                                                    {hourIndex > 0 && (
                                                        <button
                                                            type="button"
                                                            onClick={() => handleDeleteHour(day.id, hourIndex)}
                                                            className="text-red-500 hover:text-red-700 ml-2"
                                                        >
                                                            🗑️
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                        </td>
                                        <td className="p-2 text-center">
                                            <button
                                                type="button"
                                                onClick={() => handleAddHour(day.id)}
                                                className="bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                                            >
                                                Add Hour
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="grid grid-cols-1 place-items-center">
                            <button
                                type='button'
                                className='bg-blue-500 rounded-md text-white w-full border-none py-2 text-sm mt-5 transition duration-200 hover:bg-blue-600'
                                onClick={handleUpdateHours}
                            >
                                {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_profile_hour' ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};



export default UpdatePharmacyOpenHourFrontProfile;


export const UpdatePharmacyServiceFrontProfile = ({ showModal, setShowModal, setShowToast, serviceData, pharmacyLoginId, servicesData }) => {
    const { addTeamDataResponse } = useAdminSelectors();
    const dispatch = useCustomDispatch();

    const [selectedServices, setSelectedServices] = useState([]);

    const handleUpdateProfile = () => {
        const reqData = {
            services: selectedServices.map(service => service.value)
        };

        dispatch(HandleUpdatePharmacyServiceProfile('update_service_profile', setShowModal, setShowToast, reqData, pharmacyLoginId));
    };

    useEffect(() => {
        const selectedServices = serviceData?.map(service => ({
            label: service.name,
            value: service.id,
        }));
        setSelectedServices(selectedServices);

    }, [serviceData, dispatch]);


    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-6 bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className=" bg-white rounded-lg shadow-lg dark:bg-gray-700 ">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Service Detail
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'UpdatePharmacyServiceFrontProfile')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4" >
                        <div className="flex flex-wrap gap-4 ">
                            <PharmacyServices
                                selectedServices={selectedServices}
                                setFormData={(formData) => setSelectedServices(formData.selectedServices)}
                                servicesData={servicesData}
                                onServiceChange={(selectedServices) => setSelectedServices(selectedServices)}
                            />
                        </div>

                        {/* Update Button */}
                        <div className="grid grid-cols-1 place-items-center">
                            <button
                                type='button'
                                className='bg-blue-500 rounded-md text-white w-full border-none py-2 text-sm mt-5 transition duration-200 hover:bg-blue-600 shadow-lg'
                                onClick={handleUpdateProfile}
                            >
                                {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_service_profile' ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};










