import React, { useEffect, useState, useCallback } from 'react';

import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors';
import { HandleUpdateDeliveryMails } from '../../services/actions/admin/team/addTeamData'
import { ErrorToastMessage, SuccessToastMessage } from '../../components/loaders/toastMessage';

import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { LoadingView } from '../../components/common';

const MismatchDeliveryMail = () => {

    const dispatch = useDispatch()

    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors()

    const [newEmail, setNewEmail] = useState('');
    const [error, setError] = useState('');
    const [time, setTime] = useState('')

    const [emailIds, setEmailsId] = useState(null)
    const [editingIndex, setEditingIndex] = useState(false);
    const [showToast, setShowToast] = useState({ successToast: false, errorToast: false })

    const mismatch_delivery_mail_time = TeamListResponse?.data?.data?.mismatch_delivery_mail_time
    const mismatch_delivery_mail_time_list = TeamListResponse?.data?.data?.mismatch_delivery_mail

    const delivery_access_code_mail_time = TeamListResponse?.data?.data?.delivery_access_code_mail_time
    const delivery_access_code_mail_list = TeamListResponse?.data?.data?.delivery_access_code_mail

    const params = new URLSearchParams(window.location.search);
    const activeParam = params.get('active');

    const handleAddEmail = (e) => {
        e.preventDefault()
        setEditingIndex(true)
    };

    const handleDeleteEmail = (id) => {
        setEmailsId(id)
        const reqData = {
            type: activeParam === 'mistmatch_delivery'
                ? 'mismatch_delivery_emails'
                : 'delivery_access_code_emails',
        }
        dispatch(HandleUpdateDeliveryMails('delete_emails', setShowToast, reqData, `delete-setting-email/${id}`));

    }

    const handleSaveEmail = (e) => {
        e.preventDefault();

        const emailList = activeParam === 'mistmatch_delivery' ? mismatch_delivery_mail_time_list : delivery_access_code_mail_list

        if (emailList?.map((value) => value.email).includes(newEmail)) {
            setError('The email has already been taken.');
        }

        else if (!newEmail) {
            setError('Please Enter Email');
        }

        else if (!newEmail.includes('@') || !newEmail.endsWith('.com')) {
            setError('Please Enter a valid Email');
        }

        else {

            const reqData = {
                type: activeParam === 'mistmatch_delivery'
                    ? 'mismatch_delivery_emails'
                    : 'delivery_access_code_emails',

                email: newEmail
            }

            dispatch(HandleUpdateDeliveryMails('save_emails', setShowToast, reqData, 'add-setting-email'));

            setError('');
        }
    };

    useEffect(() => {

        if (mismatch_delivery_mail_time && activeParam === 'mistmatch_delivery') {
            setTime(mismatch_delivery_mail_time)
        }

        else if (mismatch_delivery_mail_time && activeParam === 'delivery_access_code') {
            setTime(delivery_access_code_mail_time)
        }

        return () => { }

    }, [mismatch_delivery_mail_time, delivery_access_code_mail_time, activeParam])

    const handleUpdateSettingMailTime = (e) => {
        const result = e.target.value;
        setTime(result);

        const reqData = {
            type: activeParam === 'mistmatch_delivery'
                ? 'mismatch_delivery_email_time'
                : 'delivery_accesscode_email_time',

            time: `${result}:00`
        };

        debouncedDispatch(reqData);
    };


    const debouncedDispatch = useCallback(
        debounce((reqData) => {
            dispatch(HandleUpdateDeliveryMails('delivery_mail_time', setShowToast, reqData, 'update-setting-mail-time'));
        }, 2000),
        []
    );

    return (
        <div className="p-4">
            <div className="bg-white shadow-md border mb-80 border-gray-100 rounded-lg p-4">
                {TeamListResponse?.loading && TeamListResponse.type === 'winrx_settings' ?
                    <div className='flex justify-center items-center mt-60 mb-60'>
                        <LoadingView />
                    </div>
                    :
                    <>
                        <div className="mb-2">
                            <div className='grid grid-cols-3'>
                                <input defaultValue={time} onChange={handleUpdateSettingMailTime} type={'time'} id='name' autoComplete="off"
                                    className={`block  pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                        dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                        bg-white `}
                                    placeholder=""
                                />
                                <label htmlFor={'name'}
                                    className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                        origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                        peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                </label>
                            </div>
                        </div>
                        <form>
                            <table className="min-w-full border">
                                <thead>
                                    <tr className="bg-gray-100">
                                        <th className="text-left p-2 text-gray-700 rubik-500 border">Email</th>
                                        <th className="text-left p-2 border text-gray-700 rubik-500">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activeParam === 'mistmatch_delivery' ? <>
                                        {mismatch_delivery_mail_time_list?.map((value, index) => (
                                            <tr key={index} className="border-t w-64">
                                                <td className="p-2 border text-gray-500 rubik-400">{value.email}</td>
                                                <td className="p-2 border">
                                                    <button type='button'
                                                        className="bg-red-500 font-semibold fs-12 text-white px-3 py-1 rounded mr-2"
                                                        onClick={() => handleDeleteEmail(value.id)}
                                                    >
                                                        {addTeamDataResponse.type === 'delete_emails' && addTeamDataResponse.loading && emailIds === value.id ? 'Processing...' : 'Delete'}
                                                    </button>

                                                </td>
                                            </tr>
                                        ))}
                                        <tr className=''>
                                            <td className="p-2 border">
                                                {editingIndex ?
                                                    <div className=' relative'>
                                                        <input type={'email'} required id='name' autoComplete="off"
                                                            value={newEmail}
                                                            style={{ height: '38px' }}
                                                            onChange={(e) => setNewEmail(e.target.value)}
                                                            placeholder=''
                                                            className={`block  pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                bg-white `}
                                                        />
                                                        <label htmlFor={'name'}
                                                            className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                                        origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                                        peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                                            Email
                                                        </label>
                                                    </div>

                                                    :
                                                    mismatch_delivery_mail_time_list?.length === 0 ? <p className='text-gray-500 fs-14 rubik-500'> No Record Found</p>
                                                        : null
                                                }
                                                {error && (
                                                    <p className="text-red-500 fs-12 rubik-500">{error}</p>
                                                )}
                                            </td>

                                            <td className="p-2 border w-80">
                                                {!editingIndex ? (
                                                    <button type='submit'
                                                        className="bg-teal-500 font-semibold fs-12 text-white px-3 py-1 rounded"
                                                        onClick={handleAddEmail}
                                                    >
                                                        Add New
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button
                                                            className="bg-teal-500 font-semibold fs-12 text-white px-3 py-1 rounded mr-2"
                                                            onClick={handleSaveEmail}
                                                        >
                                                            {addTeamDataResponse.type === 'save_emails' && addTeamDataResponse.loading ? 'Saving...' : 'Save'}

                                                        </button>
                                                        <button
                                                            className="bg-red-500 font-semibold fs-12 text-white px-3 py-1 rounded"
                                                            onClick={() => {
                                                                setEditingIndex(false);
                                                                setNewEmail('');
                                                                setError('');
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>

                                    </>
                                        :


                                        activeParam === 'delivery_access_code' ? <>
                                            {delivery_access_code_mail_list?.map((value, index) => (
                                                <tr key={index} className="border-t w-64">
                                                    <td className="p-2 border text-gray-500 rubik-400">{value.email}</td>
                                                    <td className="p-2 border">
                                                        <button type='button'
                                                            className="bg-red-500 font-semibold fs-12 text-white px-3 py-1 rounded mr-2"
                                                            onClick={() => handleDeleteEmail(value.id)}
                                                        >
                                                            {addTeamDataResponse.type === 'delete_emails' && addTeamDataResponse.loading && emailIds === value.id ? 'Processing...' : 'Delete'}

                                                        </button>

                                                    </td>
                                                </tr>
                                            ))}
                                            <tr className=''>
                                                <td className="p-2 border">
                                                    {editingIndex ?
                                                        <div className=' relative'>
                                                            <input type={'email'} required id='name' autoComplete="off"
                                                                value={newEmail}
                                                                style={{ height: '38px' }}
                                                                onChange={(e) => setNewEmail(e.target.value)}
                                                                placeholder=''
                                                                className={`block  pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                bg-white `}
                                                            />
                                                            <label htmlFor={'name'}
                                                                className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                                                        origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                                                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                                                        peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                                                Email
                                                            </label>
                                                        </div>

                                                        :
                                                        delivery_access_code_mail_list?.length === 0 ? <p className='text-gray-500 fs-14 rubik-500'> No Record Found</p>
                                                            : null
                                                    }
                                                    {error && (
                                                        <p className="text-red-500 fs-12 rubik-500">{error}</p>
                                                    )}
                                                </td>

                                                <td className="p-2 border w-80">
                                                    {!editingIndex ? (
                                                        <button type='submit'
                                                            className="bg-teal-500 font-semibold fs-12 text-white px-3 py-1 rounded"
                                                            onClick={handleAddEmail}
                                                        >
                                                            Add New
                                                        </button>
                                                    ) : (
                                                        <>
                                                            <button
                                                                className="bg-teal-500 font-semibold fs-12 text-white px-3 py-1 rounded mr-2"
                                                                onClick={handleSaveEmail}
                                                            >
                                                                {addTeamDataResponse.type === 'save_emails' && addTeamDataResponse.loading ? 'Saving...' : 'Save'}
                                                            </button>
                                                            <button
                                                                className="bg-red-500 font-semibold fs-12 text-white px-3 py-1 rounded"
                                                                onClick={() => {
                                                                    setEditingIndex(false);
                                                                    setNewEmail('');
                                                                    setError('');
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>

                                        </>

                                            : null
                                    }
                                </tbody>
                            </table>
                        </form>
                    </>}

            </div>

            {showToast.successToast && addTeamDataResponse.type === 'delivery_mail_time' && <SuccessToastMessage status={'Updated Successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'delivery_mail_time' && <ErrorToastMessage status={'Oops!. Got an issue while updating please  try again.'} />}

        </div>
    );
};

export default MismatchDeliveryMail;

