import {
	ADD_RX_TRANSFER_REQUEST_REQUEST,
	ADD_RX_TRANSFER_REQUEST_SUCCESS,
	ADD_RX_TRANSFER_REQUEST_FAILURE
} from "../../../constants"

import { pharmacy_rx_transfer_form } from '../../../../networking/urlEndPoints'
import { PreviewPdf } from "../../../../utils/helper/helper"

import Axios from "../../../../networking/intraceptor"

export const addRxTransferPdfRequest = () => ({
	type: ADD_RX_TRANSFER_REQUEST_REQUEST,
})

export const addRxTransferPdfSuccess = (data) => ({
	type: ADD_RX_TRANSFER_REQUEST_SUCCESS,
	payload: data,
})

export const addRxTransferPdfFailure = (error) => ({
	type: ADD_RX_TRANSFER_REQUEST_FAILURE,
	payload: error,
})

export const pharmacyViewRxTransferPdf = (formData) => {
	return async (dispatch) => {
		dispatch(addRxTransferPdfRequest())

		const requestedData = {
			date: formData.date,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyuserdesignation: formData.desgination,
			topharmacy: formData.toPharmacy.value,
			topharmacyphone: formData.toPharmacyPhone,
			topharmacyfaxnumber: formData.toPharmacyFax,
			requestedprescription: formData.requested_Prescription,
			date_of_transfer: formData.transferDate
		}
		if (formData.fetchType === 'winrx') {
			requestedData.fetchtype = formData.fetchType
			requestedData.winrxpatientphn = formData.phn
			requestedData.winrxpatientname = formData.name
			requestedData.winrxpatientdob = formData.dob
		}

		if (formData.fetchType === 'local') {
			requestedData.fetchtype = formData.fetchType
			requestedData.patient = formData.patientName.value
			requestedData.patientphn = formData.phn
			requestedData.patientdob = formData.dob
		}

		try {
			const response = await Axios.post(`${pharmacy_rx_transfer_form}`, requestedData)
			if (response?.status === 200) {
				dispatch(addRxTransferPdfSuccess(response?.data))
				PreviewPdf(response?.data?.data)

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(addRxTransferPdfFailure(error?.response?.data))
			}
		}
	}
}