import {
	ADD_ARV_DATA_REQUEST,
	ADD_ARV_DATA_SUCCESS,
	ADD_ARV_DATA_FAILURE
} from "../../../constants"

const initialState = {
	loading: false,
	data: null,
	error: null,
};

const addARVReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_ARV_DATA_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				data: null,
			};
		case ADD_ARV_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			}
		case ADD_ARV_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null
			}
		default:
			return state;
	}
}
export default addARVReducer