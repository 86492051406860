import React, {
    useEffect,
    useState,
    useCallback
} from 'react'

import {
    ErrorToast,
    SuccessToastMessage
} from '../../../components/loaders/toastMessage'

import {
    initalModals,
    initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import {
    fetchMEdicationDailyPackList,
    fetchBlisterPacksList
} from '../../../services/actions/pharmacy/blisters/patientBlisterPackList'

import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors'

import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { initDropdowns } from 'flowbite'

import { TextInput } from '../../../components/pharmacy/dispense/dispense'
import { utils, writeFile } from 'xlsx';

import { BlisterDropDown, DateWithValidation } from '../../../components/pharmacy/form/form'
import { ArchivedDelveriesDateRangePicker } from '../../../components/pharmacy/form/dateRangePicker'
import { UpdateDailyMedicationPack } from '../../../services/actions/pharmacy/blisters/addBlisterPackData'

import UpdateBlisterlist from '../../../models/blisters/updateBlisterList'
import ChatBox from '../../../components/chat/chatbox'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'

import * as Modals from '../../../assets/js/custome'
import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'
import { fetchPatientDrugList } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { BlisterPackDrugList } from '../../../models/blisters/patientPrescritiptionsDetails'
import { EditIcons } from '../../../assets/svg/icons'
import { debounce } from 'lodash'

export const blisterListHeading = [
    { text: "PATIENT", classNames: ['p-3'] },
    { text: "ROUTE", classNames: ['p-3'] },
    { text: "DOB", classNames: ['p-3'] },
    { text: "PHONE", classNames: ['p-3'] },
    { text: "FACILITY", classNames: ['p-3'] },
    { text: "DUE DATE", classNames: ['p-3'] },
    { text: "NO OF RX NEED TO BILL", classNames: ['p-3'] },
    { text: "NO OF PRESCRIPTION BILLED", classNames: ['p-3'] },
    { text: "DEDUCTIBLE", classNames: ['p-3'] },
    { text: "NOTES", classNames: ['p-3'] },
    { text: "BILLED", classNames: ['p-3'] },
    // { text: "ACTION", classNames: ['p-3'] },
];

export const weekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export const TableRowData = ({ value, className1 = 'p-3', }) => {
    return (<td  className={className1}>
        <p>{value}</p>
    </td>)
}

// --------------------Generate Excel Sheet ------------------

export const exportToExcel = (data, fileName) => {
    const formdattedData = data.map(value => ({
        'PATIENT NAME': value.name,
        'ROUTE': value.blister_route,
        'FACILITY': value.facility,
        'DUE DATE': value.due_date,
        'NO OF RX NEED TO BILL': value.rx_bill,
        'NO OF PRESCRIPTION BILLED': value.pres_bill,
        'DEDUCTIBLE': value.deductible,
        'NOTES': value.notes,
        'BILLED': value.billed,
    }))

    const worksheet = utils.json_to_sheet(formdattedData);

    worksheet['!cols'] = [
        { wch: 15 },
        { wch: 25 },
        { wch: 20 },
        { wch: 35 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 }
    ];
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${fileName}.xlsx`);
};

const DailySpecialityMedicationList = () => {

    const location = Hooks.useCustomLocation()
    const navigate = Hooks.useCustomNavigate()
    const dispatch = Hooks.useCustomDispatch()

    const startDateRef = React.useRef()
    const endDateRef = React.useRef()

    const { patientBlisterPackListResponse, AddBlisterPackResponse } = useBlisterSelectors()
    const blisterDailyPackList = patientBlisterPackListResponse?.data?.data
    const routeList = patientBlisterPackListResponse?.data?.data?.routes
    const facilityList = patientBlisterPackListResponse?.data?.data?.facility
    const dayList = patientBlisterPackListResponse?.data?.data?.day
    const searchParams = new URLSearchParams();

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [showModal, setShowModal] = useState(initalModals)
    const [patientProfileData, setPatientProfileData] = useState(null)
    const [showToast, setShowToast] = useState(initalShowToastMessage)
    const [formData, setFormData] = useState({ search: '', route: '', facility: '', day: '', weekday: '', startDate: '', endDate: '', billed: '', billedType: 'all', datevalid: '' })
    const [reset, setReset] = useState(false);

    const billedType = formData.billedType === 'ALL' ? 'all' : formData.billedType === 'BILLED' ? '1' : formData.billedType === 'NON BILLED' ? '0' : 'all'

    const [filterParam, setFilterParam] = useState([])
    const [errorMessages, setErrorMessages] = useState({ search: "", type: "", datevalid: "" });

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: 25
        }
        dispatch(fetchMEdicationDailyPackList('medication_daily_pack_list', params))
        return () => { }
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: 25
        };

        if (formData.route) params.route = formData.route;
        if (formData.search) params.search = formData.search;
        if (formData.day) params.rec_day = formData.day.match(/\d+/)[0];
        if (formData.weekday) params.rec_weekday = formData.weekday;
        if (formData.facility) params.facility = formData.facility;
        if (formData.startDate) params.from_date = formData.startDate;
        if (formData.endDate) params.to_date = formData.endDate;
        if (billedType) params.billed = billedType;

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(fetchMEdicationDailyPackList('medication_daily_pack_list', params))
    };

    useEffect(() => {
        initDropdowns()
    }, [blisterDailyPackList?.data])

    const handleReset = () => {
        searchParams.delete('page');
        setReset(true)
        setFormData({ ...formData, search: '', route: '', facility: '', day: '', weekday: '', startDate: '', endDate: '', billedType: '' })
        setTimeout(() => {
            setReset(false)
        }, 500);
        setPageNumber(1)
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);

        const params = {
            page: 1,
            no_of_records: 25
        }

        dispatch(fetchMEdicationDailyPackList('medication_daily_pack_list', params))
    }

    useEffectHelper.useArchivedDelveriesDateRangePickerEffect(formData, setFormData, startDateRef, endDateRef)


    const handleInputChange = (index, e, id, type) => {
        const { value } = e.target;

        if (type === 'allbilled') {
            const value = e.target.checked
            const requestData = {
                pack_id: id,
                [type]: value ? 1 : 0
            }
            debounceUpdateBlisterDailyPack(requestData);
        } else {
            const requestData = {
                pack_id: id,
                [type]: value
            }
            debounceUpdateBlisterDailyPack(requestData);
        }
    }

    const debounceUpdateBlisterDailyPack = useCallback(debounce((requestData) => {
        dispatch(UpdateDailyMedicationPack('update_daily_medication_pack', requestData, setShowModal, setShowToast, pageNumber))
    }, 500), [formData, pageNumber]);

    return (
        <React.Fragment>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">

                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">
                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-12/12' style={{ width: '220px' }}>
                                                <TextInput label='Search' name='search' formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '104px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name="route"
                                                    value={formData.route}
                                                    filterList={routeList}
                                                    reset={reset}
                                                    placeholder='Route' />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '112px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name='facility'
                                                    value={formData.facility}
                                                    filterList={facilityList}
                                                    reset={reset}
                                                    placeholder='Facility' />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '110px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name='day'
                                                    value={formData.day}
                                                    filterList={dayList}
                                                    reset={reset}
                                                    placeholder='Day' />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '142px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name='weekday'
                                                    value={formData.weekday}
                                                    filterList={weekday}
                                                    reset={reset}
                                                    placeholder='Week Day' />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '112px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name='billedType'
                                                    value={formData.billedType}
                                                    filterList={['ALL', 'BILLED', 'NON BILLED']}
                                                    reset={reset}
                                                    placeholder='All' />
                                            </div>


                                            <div className='flex flex-0 lg:w-3/12 md:w-6/12 sm:w-8/12 w-full' style={{ gridTemplateColumns: '2fr 2fr 7px 2fr 2fr', width: '298px' }}>
                                                <ArchivedDelveriesDateRangePicker
                                                    startDateRef={startDateRef}
                                                    endDateRef={endDateRef}
                                                    formData={formData}
                                                    reset={reset}
                                                    setFormData={setFormData}

                                                />
                                            </div>

                                            <div className='flex  gap-2'>
                                                <button type="button" style={{ backgroundColor: '#3B82F6', width: '70px', }} onClick={handlePagination}
                                                    className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                                                </button>
                                                <button type="button" style={{ backgroundColor: 'White', width: '70px', }} onClick={handleReset}
                                                    className="flex w-full h-full items-center justify-center text-gray-500 bg-white 
																border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
												dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Reset
                                                </button>

                                                <button style={{}} type="button" onClick={() => exportToExcel(blisterDailyPackList?.data, 'OAT')}
                                                    className="flex  w-34 h-full items-center justify-center text-white bg-red-500
																border border-gray-300 focus:outline-none hover:bg-red-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-gray-800 ">Export Sheet
                                                </button>
                                            </div>

                                        </div>



                                        {patientBlisterPackListResponse?.loading && patientBlisterPackListResponse?.type === 'medication_daily_pack_list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            patientBlisterPackListResponse?.error !== null && patientBlisterPackListResponse?.type === 'medication_daily_pack_list' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                blisterDailyPackList?.data?.length === 0 && patientBlisterPackListResponse?.type === 'medication_daily_pack_list' ?
                                                    <div className="bg-white rounded-md mt-4">
                                                        <p className="text-gray-500">Speciality Medication Packs are not available.</p>
                                                    </div>
                                                    : patientBlisterPackListResponse?.type === 'medication_daily_pack_list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {blisterListHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            blisterDailyPackList?.data?.map((value, idx) => {

                                                                                return (<React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: '1px solid lightgray' }}>

                                                                                        <td  className={'p-3 btnnn'}
                                                                                            onClick={() => {
                                                                                                return setPatientProfileData(value), Modals.handleShowModal(setShowModal, showModal, 'rxModal'),
                                                                                                    dispatch(fetchPatientDrugList(value.patient_id, 'patient_drug_list'))
                                                                                            }}>
                                                                                            <p className='bg-gray-50 p-1 rounded-lg no-wrap'>{value.name}</p>
                                                                                        </td>

                                                                                        {/* <TableRowData value={value.medication_route} /> */}

                                                                                        <td  className="p-3">
                                                                                            <input type="text" id={`medication_route-${idx}`} autoComplete="off"
                                                                                                onChange={(e) => handleInputChange(idx, e, value.id, 'medication_route')}
                                                                                                name="medication_route"
                                                                                                style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                defaultValue={value.medication_route}
                                                                                                className="block w-16 text-sm text-gray-900 border-200-gray  bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                placeholder=""
                                                                                            />
                                                                                        </td>

                                                                                        <TableRowData value={value?.dob} />
                                                                                        <TableRowData value={value?.phn} />
                                                                                        {/* <TableRowData value={value.facility} /> */}

                                                                                        <td  className="p-3">
                                                                                            <input type="text" id={`facility-${idx}`} autoComplete="off"
                                                                                                onChange={(e) => handleInputChange(idx, e, value.id, 'facility')}
                                                                                                name="facility"
                                                                                                style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                defaultValue={value.facility}
                                                                                                className="block w-16 text-sm text-gray-900 border-200-gray  bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                placeholder=""
                                                                                            />
                                                                                        </td>

                                                                                        {/* <TableRowData value={value.due_date} /> */}

                                                                                        <td className={'p-3 btnnn'}>
                                                                                            <div className='w-24 pt-5'>
                                                                                                <span className="cursor-pointer underline">
                                                                                                    <DateWithValidation
                                                                                                        formData={value?.due_date}
                                                                                                        border='none'
                                                                                                        label=""
                                                                                                        setFormData={setFormData}
                                                                                                        errorMessages={errorMessages}
                                                                                                        setErrorMessages={setErrorMessages}
                                                                                                        setShowModal={setShowModal}
                                                                                                        memoID={value?.id}
                                                                                                        showModal={showModal}
                                                                                                        setShowToast={setShowToast}
                                                                                                        showToast={showToast}
                                                                                                        type='daily_medication'
                                                                                                        pageNumber={pageNumber}
                                                                                                        filterParam={filterParam}
                                                                                                    />
                                                                                                </span>
                                                                                            </div>
                                                                                        </td>

                                                                                        {/* <TableRowData value={value.rx_bill} />
                                                                                        <TableRowData value={value.pres_bill} /> */}

                                                                                        <td  className="p-3">
                                                                                            <input type="number" id={`rx_bill-${idx}`} autoComplete="off"
                                                                                                onChange={(e) => handleInputChange(idx, e, value.id, 'rx_need_to_bill')}
                                                                                                name="rx_bill"
                                                                                                style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                defaultValue={value.rx_bill}
                                                                                                className="block w-16 text-sm text-gray-900 border-200-gray  bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                placeholder=""
                                                                                            />
                                                                                        </td>

                                                                                        <td  className="p-3">
                                                                                            <input type="number" id={`pres_bill-${idx}`} autoComplete="off"
                                                                                                onChange={(e) => handleInputChange(idx, e, value.id, 'prescription_billed')}
                                                                                                name="pres_bill"
                                                                                                style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                defaultValue={value.pres_bill}
                                                                                                className="block w-16 text-sm text-gray-900 border-200-gray  bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                placeholder=""
                                                                                            />
                                                                                        </td>


                                                                                        {/* <TableRowData value={value.deductible} /> */}

                                                                                        <td  className="p-3">
                                                                                            <input type="number" id={`deductible-${idx}`} autoComplete="off"
                                                                                                onChange={(e) => handleInputChange(idx, e, value.id, 'deductible')}
                                                                                                name="deductible"
                                                                                                style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                defaultValue={value.deductible}
                                                                                                className="block w-16 text-sm text-gray-900 border-200-gray  bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                placeholder=""
                                                                                            />
                                                                                        </td>
                                                                                        {/* 
                                                                                        <TableRowData value={value.notes} />

                                                                                        <td  className={'p-3 btnnn'}>
                                                                                            <div className="flex items-center gap-3">
                                                                                                <input
                                                                                                    id={`billed-${idx}`}
                                                                                                    name="billed"
                                                                                                    value={formData.billed}
                                                                                                    type="checkbox"
                                                                                                    onChange={(e) => dispatch(UpdateDailyMedicationPack('all_billed', e.target.checked ? 1 : 0, value.id, setShowModal, setShowToast, pageNumber))}
                                                                                                    defaultChecked={value.billed === 1 ? true : false}
                                                                                                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                                                />
                                                                                            </div>
                                                                                        </td> */}

                                                                                        <td  className="p-3 w-64">
                                                                                            <textarea type="text" id={`notes-${idx}`} autoComplete="off"
                                                                                                onChange={(e) => handleInputChange(idx, e, value.id, 'note')}
                                                                                                name="notes"
                                                                                                style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                defaultValue={value.notes}
                                                                                                className="block text-sm scroll-box  text-gray-900 border-200-gray  bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                placeholder=""
                                                                                            />
                                                                                        </td>

                                                                                        <td  className={'p-3 btnnn'}>
                                                                                            <div className="flex items-center gap-3">
                                                                                                <input
                                                                                                    id={`billed-${idx}`}
                                                                                                    name="billed"
                                                                                                    value={formData.billed}
                                                                                                    type="checkbox"
                                                                                                    onChange={(e) => handleInputChange(idx, e, value.id, 'allbilled')}
                                                                                                    defaultChecked={value.billed === 1 ? true : false}
                                                                                                    className="w-5 h-5 -mt-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                                                />
                                                                                            </div>
                                                                                        </td>


                                                                                        {/* <td  className="p-3">
                                                                                            <td  className={'p-3 btnnn'}
                                                                                                onClick={() => { return setPatientProfileData(value), Modals.handleShowModal(setShowModal, showModal, 'updateBlisterlist') }}>
                                                                                                <EditIcons color='gray' />
                                                                                            </td>
                                                                                        </td> */}
                                                                                    </tr>
                                                                                </React.Fragment>)
                                                                            })}
                                                                    </tbody>
                                                                </table>

                                                                {patientBlisterPackListResponse?.error !== null ? null : <>
                                                                    {blisterDailyPackList?.data?.length !== 0 && <>
                                                                        {blisterDailyPackList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={blisterDailyPackList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />
            <Common.Footer />

            {showToast.showToast && AddBlisterPackResponse?.type === 'update_daily_medication_pack' && <SuccessToastMessage status='Updated sucessfully' />}
            {showToast.showToast && AddBlisterPackResponse?.type === 'all_billed' && <SuccessToastMessage status='Updated sucessfully' />}
            {showToast.errorToast && AddBlisterPackResponse?.type === 'update_daily_medication_pack' && <ErrorToast status={'Oops! Got an issue.please try to add again.'} />}

            <BlisterPackDrugList patientProfileData={patientProfileData}
                showModal={showModal}
                onClose={() => Modals.handleHideModal(setShowModal, showModal, 'rxModal')}
                setShowToast={setShowToast}
                setShowModal={setShowModal} />


            {
                showModal.updateBlisterlist &&
                <UpdateBlisterlist
                    showToast={showToast}
                    setShowToast={setShowToast}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    patientProfileData={patientProfileData}
                    paginationNumber={pageNumber}
                />
            }

        </React.Fragment>
    )
}

export default DailySpecialityMedicationList