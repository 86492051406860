import {
    PATIENT_BLISTER_PACK_LIST_REQUEST,
    PATIENT_BLISTER_PACK_LIST_SUCCESS,
    PATIENT_BLISTER_PACK_LIST_FAILURE
} from "../../../constants"

import {
    patient_blister_pack,
    blister_pack_patient,
    daily_blister_pack,
    patient_bpack_onshelf,
    vials_pateint_list,
    daily_vials_pack,
    sMedication_patient_list,
    sMedication_daily_log,
    pharmacy_previous_invoice
} from "../../../../networking/urlEndPoints"

import Axios from "../../../../networking/intraceptor"

export const patientBlisterPackListRequest = (types) => ({
    type: PATIENT_BLISTER_PACK_LIST_REQUEST,
    types: types
})

export const patientBlisterPackListSuccess = (data, types) => ({
    type: PATIENT_BLISTER_PACK_LIST_SUCCESS,
    payload: data,
    types: types
})

export const patientBlisterPackListFailure = (error, types) => ({
    type: PATIENT_BLISTER_PACK_LIST_FAILURE,
    payload: error,
    types: types
})


// -----------------------------Blister Patients list------------------------
export const fetchBlistersPatientList = (type, pageNumber, search) => {

    return async (dispatch) => {
        dispatch(patientBlisterPackListRequest(type))
        try {
            const response = await Axios.get(`${patient_blister_pack}`, {
                params: {
                    page: pageNumber,
                    search: search,
                    sortlist: 'latest'
                }
            })
            if (response?.status === 200) {
                dispatch(patientBlisterPackListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(patientBlisterPackListFailure(error?.response, type))
            }
        }
    }
}

// -----------------------------Blister Packs list------------------------

export const fetchBlisterPacksList = (type, params, urlEndPoints) => {

    return async (dispatch) => {
        dispatch(patientBlisterPackListRequest(type))
        try {
            const response = await Axios.get(`${urlEndPoints ? urlEndPoints : blister_pack_patient}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(patientBlisterPackListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(patientBlisterPackListFailure(error?.response, type))
            }
        }
    }
}

// -----------------------------Blister Daily Packs list------------------------

export const fetchBlisterDailyPackList = (type, params) => {

    return async (dispatch) => {
        dispatch(patientBlisterPackListRequest(type))
        try {
            const response = await Axios.get(`${daily_blister_pack}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(patientBlisterPackListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(patientBlisterPackListFailure(error?.response, type))
            }
        }
    }
}


// -----------------------------Blister Packs on Shelf ------------------------

export const fetchBlisterPacksOnShelfList = (type, params) => {

    return async (dispatch) => {
        dispatch(patientBlisterPackListRequest(type))
        try {
            const response = await Axios.get(`${patient_bpack_onshelf}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(patientBlisterPackListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(patientBlisterPackListFailure(error?.response, type))
            }
        }
    }
}


// --------------------------------Vials Patient List----------------------------

export const fetchVialsPatientsList = (type, pageNumber, search) => {

    return async (dispatch) => {
        dispatch(patientBlisterPackListRequest(type))
        try {
            const response = await Axios.get(`${vials_pateint_list}`, {
                params: {
                    page: pageNumber,
                    search: search,
                    sortlist: 'latest'
                }
            })
            if (response?.status === 200) {
                dispatch(patientBlisterPackListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(patientBlisterPackListFailure(error?.response, type))
            }
        }
    }
}

export const fetchVialsDailyPackList = (type, params) => {

    return async (dispatch) => {
        dispatch(patientBlisterPackListRequest(type))
        try {
            const response = await Axios.get(`${daily_vials_pack}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(patientBlisterPackListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(patientBlisterPackListFailure(error?.response, type))
            }
        }
    }
}

// --------------------------------Medication Patient List----------------------------

export const fetchMedicationPatientList = (type, pageNumber, search) => {

    return async (dispatch) => {
        dispatch(patientBlisterPackListRequest(type))
        try {
            const response = await Axios.get(`${sMedication_patient_list}`, {
                params: {
                    page: pageNumber,
                    search: search,
                    sortlist:'latest'
                }
            })
            if (response?.status === 200) {
                dispatch(patientBlisterPackListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(patientBlisterPackListFailure(error?.response, type))
            }
        }
    }
}

export const fetchMEdicationDailyPackList = (type, params) => {

    return async (dispatch) => {
        dispatch(patientBlisterPackListRequest(type))
        try {
            const response = await Axios.get(`${sMedication_daily_log}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(patientBlisterPackListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(patientBlisterPackListFailure(error?.response, type))
            }
        }
    }
}

// -----------------------------------Invoice Section List -------------------------

export const fetchPreviousInvoiceList = (type, params) => {

    return async (dispatch) => {
        dispatch(patientBlisterPackListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_previous_invoice}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(patientBlisterPackListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(patientBlisterPackListFailure(error?.response, type))
            }
        }
    }
}
