import React, { useEffect } from 'react'

import {
	userEmail,
	userPassword
} from '../constants/email-pasword'

import {
	encryptEmailData,
	decryptEmailData,
	encryptPasswordData,
	decryptPasswordData
} from '../utils/miscellaneous/encryption'

import { HandleVerifyOtp, authLogin } from '../services/actions/auth/authActions'
import { Errors } from '../components/error/alertMessages'

import { useCustomSelectors } from '../services/selectors/allSelectors'

import * as images from '../assets/images/index'
import * as Hooks from '../hooks'
import * as Icons from '../assets/svg/icons'

const SignIn = () => {

	const navigate = Hooks.useCustomNavigate()
	const dispatch = Hooks.useCustomDispatch()

	const { authData } = useCustomSelectors()
	const authResponse = authData

	const [email, setEmail] = Hooks.useCustomState('');
	const [password, setPassword] = Hooks.useCustomState('')
	const [otp, setOtp] = Hooks.useCustomState('')
	const [ipAddress, setIPAddress] = Hooks.useCustomState('')

	const [message, setMessage] = Hooks.useCustomState('')

	const [isChecked, setIsChecked] = Hooks.useCustomState(false);
	const [showPassword, setShowPassword] = Hooks.useCustomState(false);

	const onChangeValue = (event) => {
		const { name, value } = event.target;
		if (name === 'email') {
			setEmail(value);
			// setMessage('')
		} else if (name === 'password') {
			setPassword(value);
			// setMessage('')
		}
	};

	useEffect(() => {
		if (localStorage.checkbox && localStorage.email !== '') {
			setIsChecked(true);
			const emailValue = decryptEmailData()
			const passwordValue = decryptPasswordData()

			if (emailValue || passwordValue) {
				setEmail(emailValue)
				setPassword(passwordValue)
			}
		}
	}, [])


	const handleSubmit = async (event) => {
		event.preventDefault()
		if (!email) {
			setMessage('Email is required. Please enter your email address.')
		} else if (!password) {
			setMessage('Password is required. Please enter a valid password.')
		} else if (isChecked && email !== '' && password !== '') {
			encryptEmailData(email)
			encryptPasswordData(password)
			localStorage.setItem('checkbox', isChecked)
			if (message === 'OTP Required!' || message === 'Invalid OTP') {
				await dispatch(HandleVerifyOtp(email, password, navigate, otp))
			} else {
				await dispatch(authLogin(email, password, navigate, ipAddress))
			}

		} else if (isChecked === false) {
			localStorage.removeItem(userEmail)
			localStorage.removeItem(userPassword)
			localStorage.removeItem('checkbox')
			if (message === 'OTP Required!' || message === 'Invalid OTP') {
				await dispatch(HandleVerifyOtp(email, password, navigate, otp))
			} else {
				await dispatch(authLogin(email, password, navigate, ipAddress))
			}
		}
	}

	useEffect(() => {
		if (authResponse?.loading) {
			// setMessage('');
		} else if (authResponse?.error) {
			// const error = authResponse?.error?.status
			const error = authResponse?.error?.data?.message
			setMessage(error)
			// const errorMessages = handleAuthApiError(error)
		}
	}, [authResponse]);

	useEffect(() => {
		// handleGetMyIPAddress(setIPAddress)
		setMessage('')
	}, [])

	return (
		<>
			<div className="container-fluid">
				<div className="grid grid-cols-12">
					<div className="lg:col-span-3 md:col-span-12 sm:col-span-12 col-span-12 bg_light h-screen pt-10 grid place-content-center items-center relative">
						<img src={images.oatrx_logo} alt="" className="absolute top-5 left-0 md:left-20 lg:left-30" />
						
						<div className="bg_light p-6 absolute shadow-lg border border-gray-200 left-0 md:left-20 lg:left-30 w-full formBgShadow">
							<h3 className="rubik-100 fs-30">Login to continue</h3>
							<form onSubmit={handleSubmit}>

								<div className="mb-4 ">
									<div className="relative">
										<Icons.EnevelopIcon />
										<input required type="email" id="email" defaultValue={email} name='email' autoComplete='off'
											onChange={onChangeValue} className="pl-12 h-12 border lightBorder grey-color nunito-400 fs-16 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400" placeholder="Enter email" />
									</div>
								</div>

								<div className="mb-4">
									<div className="relative">
										<Icons.PasswordLockIcon />
										<input required defaultValue={password} type={showPassword ? 'text' : 'password'} onChange={onChangeValue} name='password'
											id="password" className="pl-12 h-12 border lightBorder grey-color nunito-400 fs-16 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400" placeholder="Enter password" />
										<div onClick={() => setShowPassword(!showPassword)}>
											{!showPassword ?
												<Icons.CloseEyeIcon /> :
												<Icons.EyeIcon className='absolute right-3 top-4 text-gray-400' />}
										</div>
									</div>
								</div>

								{(message === 'OTP Required!' || message === 'Invalid OTP') && authResponse?.error?.status === 404 &&
									<div className="mb-4">
										<div className="relative">
											<Icons.OTPIcon />
											<input required value={otp?.slice(0, 6)} type={'number'} onChange={(e) => setOtp(e.target.value)} name='otp'
												id="otp" className="pl-12 h-12 border lightBorder grey-color nunito-400 fs-16 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400" placeholder="Enter OTP" />
										</div>
									</div>
								}

								<div className="flex items-start mb-4">
									<div className="flex items-center h-5">
										<input id="remember" checked={isChecked} name="lsRememberMe" onChange={(event) => setIsChecked(event.target.checked)}
											type="checkbox" className="h4 w-4 border border-gray-300  bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" />
									</div>
									<div className="flex items-center w-full">
										<label htmlFor="remember" className="ml-2 grey-color nunito-400 fs-16">Remember me</label>
									</div>
								</div>

								{message === 'Invalid email or password' ?
									<Errors error={"Invalid Email or Password"} /> :
									message === 'Invalid OTP' ?
										<Errors error={"Invalid OTP"} /> :
										message === 'OTP Required!' ?
											<Errors error={"Please Enter OTP to continue the process."} /> :
											message !== '' ?
												<Errors error={"An error occurred while processing your request."} /> :
												null
								}

								{(message === 'OTP Required!' || message === 'Invalid OTP') && authResponse?.error?.status === 404 ?
									<button type="submit" className="h-12 nunito-700 fs-16 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 block">
										{authResponse?.loading ? 'Processing...' : 'Continue'}
									</button>
									:
									<button type="submit" className="h-12 nunito-700 fs-16 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 block">
										{authResponse?.loading ? 'Processing...' : 'Login'}
									</button>}

							</form>
						</div>
					</div>

					<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12 lightBgColorSignin grid place-content-center justify-center items-center">
						<div>
							<Icons.BtterManageMentIcon />
							<h4 className="rubik-500 fs-22">Better Management</h4>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SignIn