import React, {
	useEffect,
	useState,
	useRef
} from 'react'

import {
	Sidebar,
	Footer,
	BreadCrumb
} from '../../../components/common';

import {
	onPlaceSetPateintAddresses
} from '../../../assets/js/custome';

import {
	initialAddPateintDOBPicker,
} from '../../../assets/js/datepicker';

import {
	initialPatientFormState,
	intitalPatientFormErrors,
	initalPatientAddressForm,
	convertGenderValue
} from '../../../utils/constants/formInitialStates';

import {
	handleChangeAddPatientFormData,
	handleChangeAddPatientFormAddress
} from '../../../utils/data/handleFormDataChange';

import {
	useCustomDispatch,
	useCustomLocation,
	useCustomNavigate
} from '../../../hooks';

import {
	Errors,
	SuccessMessage
} from '../../../components/error/alertMessages';


import {
	handleGetPatientWinRxData,
	validateAddPatientForm
} from '../../../utils/validations/others';

import { autoSearchKey } from '../../../networking/urlEndPoints'
import { usePlacesWidget } from 'react-google-autocomplete';
import { addPatientDataSuccess } from '../../../services/actions/patients/addPatientDataActions';

import { initialPateintFormInputColor } from '../../../style';
import { Loader } from '../../../components/common/loadingView';
import { useCustomPatientSelectors } from '../../../services/selectors/patientSelectors';

import * as Patient from '../../../constants/workspace'
import * as Icons from '../../../assets/svg/icons'
import * as routeNames from '../../../routes/routeName'

import ChatBox from '../../../components/chat/chatbox'

const AddPatients = () => {

	const addPatients = 1
	const dobRef = useRef(null)
	const phnRef = useRef()

	const dispatch = useCustomDispatch()
	const navigate = useCustomNavigate()
	const location = useCustomLocation()

	const { provinceList,
		patientDataResponse,
		addPatientResponse,
		patientData
	} = useCustomPatientSelectors()

	const [patientFormData, setPatientFormData] = useState(initialPatientFormState)
	const [formDataBorderColor, setFormDataBorderColor] = useState(initialPateintFormInputColor)

	const [formFeildsErrors, setFormFieldErrors] = useState(intitalPatientFormErrors)
	const [pateintAddress, setPatientAddress] = useState(initalPatientAddressForm)

	const handleChangeFormData = (e) => {
		handleChangeAddPatientFormData(e, patientFormData,
			setPatientFormData,
			formDataBorderColor,
			setFormDataBorderColor,
			formFeildsErrors,
			setFormFieldErrors)
	}

	const handleChangeAddressFormData = (e) => {
		handleChangeAddPatientFormAddress(e, pateintAddress, setPatientAddress)
	}

	const { ref: ref1 } = usePlacesWidget({
		apiKey: autoSearchKey,
		onPlaceSelected: (place) => onPlaceSetPateintAddresses(place, setPatientAddress, pateintAddress),

		options: {
			types: ["address"],
			componentRestrictions: { country: "ca" },
			limit: 7,
			googleLogo: false
		},
	});

	useEffect(() => {
		const startDOBPickerInstance = initialAddPateintDOBPicker(dobRef, setPatientFormData, patientFormData);

		return () => {
			if (startDOBPickerInstance) {
				startDOBPickerInstance.destroy();
			}
		};
	}, [dobRef, setPatientFormData, patientFormData])

	useEffect(() => {
		if (patientDataResponse?.loading) {
			setPatientFormData({
				...patientFormData,
				firstName: '',
				lastName: '',
				gender: '',
				phone: '',
				dob: '',
				route: ''
			})
			setPatientAddress({
				...pateintAddress,
				fullAddress: '',
				address: '',
				city: '',
				postalCode: '',
				province: 'BC'
			})
			setFormFieldErrors({ ...formFeildsErrors, phn: '' })
			setFormDataBorderColor({ ...formDataBorderColor, phn: 'lightgrey' })
		}
		else if (patientData) {
			setPatientFormData({
				...patientFormData,
				firstName: patientData?.GIVEN,
				lastName: patientData?.SURNAME,
				gender: patientData?.SEX === 'M' ? 'Male' : patientData?.SEX === 'F' ? 'Female' : '',
				phone: `${patientData?.AREA}${patientData?.PHONE}`,
				dob: patientData?.BIRTHDATE,
				phn: patientData?.PHN,
				route: patientData?.ROUTE
			})
			setPatientAddress({
				...pateintAddress,
				fullAddress: `${patientData?.ADDR1} ${patientData?.ADDR2}`,
				city: patientData?.CITY,
				postalCode: patientData?.PC,
				province: patientData?.PROV
			})
		} else if (patientDataResponse?.error !== null) {
			if (patientDataResponse?.error?.status === 400 || patientDataResponse?.error?.status === 404) {
				setFormDataBorderColor({ ...formDataBorderColor, phn: '#e85347' })
				setFormFieldErrors({ ...formFeildsErrors, phn: patientDataResponse?.error?.data?.message })
			}
		}
	}, [patientDataResponse])

	useEffect(() => {
		setPatientFormData({
			...patientFormData,
			firstName: '',
			lastName: '',
			gender: '',
			phone: '',
			dob: '',
			route: ''
		})
		setPatientAddress({
			...pateintAddress,
			fullAddress: '',
			address: '',
			city: '',
			postalCode: '',
			province: 'BC'
		})
		
		phnRef.current.focus()
		return () => {
			phnRef?.current?.blur()
		}
	}, [])

	useEffect(() => {
		dispatch(addPatientDataSuccess(null))
	}, [])

	return (
		<React.Fragment>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar addPatients={addPatients} sidebarData={Patient.PateintData} />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" >
								<div className="grid grid-cols-12 gap-4 w-full">
									<div
										className="mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg px-5 py-5 md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white">
										{/* -----------------------------------------------------------------Column One Feilds Section Start------------------------------------------- */}

										<form>
											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">

												{((location.pathname === routeNames.pharmacy_patient_blister_pack)
													|| (location.pathname === routeNames.pharmacy_add_vials_patient)
													|| (location.pathname === routeNames.pharmacy_add_medication_patients)
													|| (location.pathname === routeNames.pharmacy_add_travel_patient)
													|| (location.pathname === routeNames.pharmacy_add_patient_route)) &&
													<div className="col-span-12 -mb-4">
														<button style={{ background: '#3B82F6' }} type="button"
															onClick={() => location.pathname === routeNames.pharmacy_patient_blister_pack ?
																navigate(routeNames.pharmacy_blister_patients) :
																location.pathname === routeNames.pharmacy_add_patient_route ?
																	navigate(routeNames.pharmacy_patient_route) :
																	location.pathname === routeNames.pharmacy_add_medication_patients ?
																		navigate(routeNames.pharmacy_speciality_pack) :
																		location.pathname === routeNames.pharmacy_add_travel_patient ?
																			navigate(routeNames.pharmacy_add_travel_pack) :
																			navigate(routeNames.pharmacy_patient_vials_pack)}
															className="rubik-400 rounded-md flex fs-14 h-6 text-white blueBg-color  focus:ring-4 
																		focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-1.5 text-center
													 					dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 justify-center items-center"
														>
															<span className="flex items-center" >
																Back
															</span>
														</button>
													</div>}

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-6">
													<div className="relative">
														<Icons.PHNIcon />
														<input type="text" id="Phn" ref={phnRef} style={{ borderColor: formDataBorderColor.phn }}
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeFormData} name='phn' value={patientFormData.phn}
															onKeyPress={(e) => {
																if (isNaN(String.fromCharCode(e.charCode)) || e.target.value.length >= 10) {
																	e.preventDefault();
																}
															}}
															placeholder=" " />
														<label htmlFor="Phn" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															{formDataBorderColor.phn == '#e85347' ? '' : 'PHN'}</label>
														<span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.phn}</span>
													</div>
												</div>

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-6">
													<div className="relative">
														<button
															type="button"
															onClick={() => handleGetPatientWinRxData(
																patientFormData,
																setFormDataBorderColor,
																formDataBorderColor,
																setFormFieldErrors,
																dispatch,
																formFeildsErrors
															)}
															className="rubik-700 rounded-md text-center fs-16 h-12 text-white blueBg-color w-full 
															focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5
															 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 
															 flex justify-center items-center">
															<span className='flex justify-content items-center text-center'>
																{patientDataResponse?.loading ? <>	Processing &nbsp;<Loader /></> : 'Get Patient Data'}
															</span>
														</button>
													</div>
												</div>
											</div>

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<Icons.UserIcon />
														<input type="text" id="lastName" style={{ borderColor: formDataBorderColor.lastName }}
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeFormData} name='lastName' value={patientFormData.lastName}
															placeholder=" " />

														<label htmlFor="lastName" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															{formDataBorderColor.lastName == '#e85347' ? '' : 'Last Name'}
														</label>
														<span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.lastName}</span>
													</div>
												</div>

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<Icons.UserIcon />
														<input type="text" id="firstName" style={{ borderColor: formDataBorderColor.firstName }}
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeFormData} name='firstName' value={patientFormData.firstName}
															placeholder=" " />

														<label htmlFor="firstName" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															{formDataBorderColor.firstName == '#e85347' ? '' : 'First Name'}
														</label>
														<span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.firstName}</span>
													</div>
												</div>

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
															<li className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
																<div className="flex items-center ps-3">
																	<input id="Male" onChange={handleChangeFormData} checked={patientFormData?.gender === 'Male'} name='gender' value="Male" type="radio" className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
																	<label htmlFor="Male" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Male </label>
																</div>
															</li>
															<li className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
																<div className="flex items-center ps-3">
																	<input id="Female"
																		onChange={handleChangeFormData} name='gender' checked={patientFormData?.gender === 'Female'} value="Female"
																		type="radio" className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
																	<label htmlFor="Female" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Female</label>
																</div>
															</li>
														</ul>
														<label htmlFor="Gender" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Gender
														</label>

														<span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.gender}</span>

													</div>
												</div>
											</div>

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<Icons.LocationIcon />

														<input type="text" id="route" style={{ borderColor: formDataBorderColor.route }}
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeFormData} name='route' value={patientFormData.route}
															placeholder=" " />

														<label htmlFor="route" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															{formDataBorderColor.route == '#e85347' ? '' : 'Route'}
														</label>
														<span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.route}</span>
													</div>
												</div>


												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<Icons.PHNIcon />
														<input type="text" id="phone"
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeFormData} name='phone' value={patientFormData.phone}
															placeholder=" " onKeyPress={(e) => {
																if (isNaN(String.fromCharCode(e.charCode)) || e.target.value.length >= 10) {
																	e.preventDefault();
																}
															}} />

														<label htmlFor="phone" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Phone
														</label>
													</div>
												</div>

												<div className="mb-2 sm:mb-4 md:mb-4 lg:mb-4 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<input type="text" id="dob" ref={dobRef}
															className="block  px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeFormData} name='dob' value={patientFormData.dob}
														/>

														<label htmlFor="dob" className="ml-6 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Date of Birth
														</label>

														<div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
															<Icons.CalenderIcon />
														</div>
													</div>
												</div>

											</div>

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<Icons.SearchIcon />
														<input type="text" id="fullAddress" ref={ref1}
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeAddressFormData} name='fullAddress' value={pateintAddress.fullAddress}
															placeholder=" " />

														<label htmlFor="fullAddress" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Search Address
														</label>
													</div>
												</div>

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<Icons.AddressIcon />
														<input type="text" id="address"
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeAddressFormData} name='address' value={pateintAddress.address}
															placeholder=" " />

														<label htmlFor="address" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Address
														</label>

													</div>
												</div>

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<Icons.CityIcon />
														<input type="text" id="city"
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeAddressFormData} name='city' value={pateintAddress.city}
															placeholder=" " />

														<label htmlFor="city" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															City
														</label>
													</div>
												</div>
											</div>

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">

												<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-5 lg:col-span-4">
													<div className="relative ot_addNoteText did-floating-label-content">
														<Icons.ProvinceIcon />
														<select style={{ height: '48px' }}
															className="fs-14 rounded-lg rubik-400 did-floating-select border border-gray-300 text-gray-900"
															onChange={handleChangeAddressFormData} defaultValue={pateintAddress.province} name="province"
														>
															{provinceList?.data?.map((value, index) => {
																return (<option className="fs-14" value={value.short_name} key={index}>{value.long_name}</option>)
															})}
														</select>
														<label className="did-floating-label" style={{ color: '#6B7280' }}>Province</label>
													</div>
												</div>

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<Icons.LocationIcon />
														<input type="text" id="postalCode"
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeAddressFormData} name='postalCode' value={pateintAddress.postalCode}
															placeholder=" " />

														<label htmlFor="postalCode" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Postal Code
														</label>
													</div>
												</div>

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<Icons.EnvelopIcon />
														<input type="text" id="email1"
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChangeFormData} name='email' value={patientFormData.email}
															placeholder=" " />

														<label htmlFor="email1" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Email (Optional)
														</label>
													</div>
												</div>

											</div>

											{addPatientResponse?.data?.success === true ?
												<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
													<div className="col-span-12">
														<SuccessMessage message='Patient Added successfully' />
													</div>
												</div>
												: addPatientResponse?.error !== null ?
													addPatientResponse?.error?.status === 400 ?
														<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
															<div className="col-span-12">
																<Errors error={addPatientResponse?.error?.data?.data?.error?.phn[0]} />
															</div>
														</div>
														:
														<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
															<div className="col-span-12">
																<Errors error='Something went wrong while processing your request please try again later..!!' />
															</div>
														</div>

													: null}

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="col-span-12">
													<button type="button"
														className="rubik-700 rounded-md flex fs-16 h-12 text-white blueBg-color  focus:ring-4 
													focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center
													 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 justify-center items-center"
														onClick={() => validateAddPatientForm(patientFormData,
															setFormDataBorderColor,
															formDataBorderColor,
															setFormFieldErrors,
															formFeildsErrors,
															pateintAddress,
															dispatch,
															navigate,
															location
														)}
													>
														<span className="flex items-center" >
															{addPatientResponse?.loading ? <>Processing &nbsp;<Loader /></> : 'Save Patient'}
														</span>
													</button>
												</div>
											</div>

										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
			<ChatBox />
		</React.Fragment>
	)
}

export default AddPatients