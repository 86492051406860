import Ably from 'ably';

import {
	activityStatusChannel,
	apiKey,
	channelName,
	chatNotifications,
	addTeamNoteKey
} from '../../networking/urlEndPoints'

import { fetchAllCommentLists } from '../../services/actions/posts/postCommentsListsActions';
import { fetchPharmacyWallLists } from '../../services/actions/posts/pharmacyWallPostListsActions';
import { fetchAllCommentReplyLists } from '../../services/actions/posts/postCommentReplyListActions';

import { chatChannelName } from '../../networking/urlEndPoints';
import { getAllChatMessages } from '../../services/actions/chat/getChatMessagesActions';
import { fetchAllRecentChats } from '../../services/actions/chat/recentChatsListsActions';

import { fetchTeamNotesMessage } from '../../services/actions/pharmacy/other';

import * as images from '../../assets/images/index'
import { authPharmacyPermissions } from '../../services/actions/auth/authPermissions';

export const subscribeToAblyChannels = (
	pharmacyId,
	dispatch,
	setLoading,
	setAppendedCommentsValue,
	setCommentReplyLoader,
	setAppendedCommentReplyValue,
	openPostId,
	commentReplyId) => {

	const ably = new Ably.Realtime({ key: apiKey });
	const channel = ably.channels.get(channelName);

	const postCommentChannel = `post-comment-${pharmacyId}`;
	const postCommentReplyChannel = `post-comment-reply-${pharmacyId}`;

	channel.subscribe(postCommentChannel, (message) => {
		if (message?.name === postCommentChannel) {
			dispatch(fetchAllCommentLists(setLoading, setAppendedCommentsValue, openPostId));
			dispatch(fetchPharmacyWallLists());
		}
	});

	channel.subscribe(postCommentReplyChannel, (message) => {
		if (message?.name === postCommentReplyChannel) {
			dispatch(fetchAllCommentReplyLists(setCommentReplyLoader, setAppendedCommentReplyValue, commentReplyId));
		}
	});

	return ably
}

export const subscribeGroupMessages = (group_id,
	setChatMessageList,
	setChatMessages,
	playMessageSound,
	profileData, dispatch) => {

	const ably = new Ably.Realtime({ key: apiKey });
	const channel = ably.channels.get(chatChannelName);
	const sendChatMessages = `message-group-${group_id}`;

	channel.subscribe(sendChatMessages, async (message) => {
		// if (message?.name === sendChatMessages && profileData?.user_id === message?.data?.user_id) {
		// 	await getAllChatMessages(message?.data?.message_id, setChatMessageList, setChatMessages, playMessageSound, profileData)
		// }
		if (message?.name === sendChatMessages) {
			await getAllChatMessages(message?.data?.message_id, setChatMessageList, setChatMessages, playMessageSound, profileData, dispatch)
		}
	})
	return ably
}

export const subscribeUserStatus = (pharmacyId, dispatch) => {

	const ably = new Ably.Realtime({ key: apiKey });
	const channel = ably.channels.get(activityStatusChannel);
	const activityStatusChannelss = `message-for-${pharmacyId}`;
	channel.subscribe(activityStatusChannelss, async (message) => {
		if (message?.name === activityStatusChannelss) {
			dispatch(fetchAllRecentChats())
		}
	})
	return ably
}

export const subscribePostLists = (pharmacyId,
	dispatch,
	setWallListLoading) => {

	const ably = new Ably.Realtime({ key: apiKey });
	const channel = ably.channels.get(channelName);
	channel.subscribe(`wall-post-${pharmacyId}`, (message) => {
		if (message?.name === `wall-post-${pharmacyId}`) {
			dispatch(fetchPharmacyWallLists(setWallListLoading))
		}
	})

	return ably
}

export const subScribeChatNotifications = (pharmacyId,
	userId,
	dispatch,
	playMessageSound) => {

	const ably = new Ably.Realtime({ key: apiKey });
	const channel = ably.channels.get(chatNotifications);
	const messageNotification = `message-notification-${pharmacyId}-${userId}`;
	channel.subscribe(messageNotification, (message) => {
		dispatch(fetchAllRecentChats())
		playMessageSound()
		enableNotifications(message?.data?.title, message?.data?.body)
	})

	return ably
}

const enableNotifications = (title, body) => {
	if (Notification.permission === 'granted') {
		new Notification(title, {
			body: body,
			icon: images.oatrx_logo,
		});
	}
}

export const subScribeTeamNotes = (pharmacyId,
	dispatch, setLoading, profileData
) => {

	const ably = new Ably.Realtime({ key: apiKey });
	const channel = ably.channels.get(addTeamNoteKey);
	const messageNotification = `message-team-notes-${pharmacyId}`;
	channel.subscribe(messageNotification, (message) => {
		if (message?.name === messageNotification) dispatch(fetchTeamNotesMessage())
		if (message.data.user_id === profileData.user_id) {
			setLoading(false)
		} else {
			setLoading(true)
		}
	})
	return ably
}

export const subscribeAddChatGroupNotification = (pharmacyId,
	dispatch,
) => {

	const ably = new Ably.Realtime({ key: apiKey });
	const channel = ably.channels.get('oat-pharmacy-common-group-channel');
	const messageNotification = `message-common-group-${pharmacyId}`
	channel.subscribe(messageNotification, (message) => {
		if (message && message.data && message.data.user_id) {
			dispatch(fetchAllRecentChats())
		}
	})
	return ably
}

// const chatGroupNotification = (title, body) => {
// 	if (Notification.permission === 'granted') {
// 		new Notification(title, {
// 			body: 'Added to a group',
// 			icon: images.oatrx_logo,
// 		});
// 	}
// }


export const subscribePharmacyRolesAndPermissions = (pharmacyId,
	dispatch,
) => {
	const ably = new Ably.Realtime({ key: apiKey });
	const channel = ably.channels.get('pharmacy-user-permission');
	const messageNotification = `message-pharmacy-permissions-${pharmacyId}`
	channel.subscribe(messageNotification, (message) => {
		dispatch(authPharmacyPermissions())
	})
	return ably
}
