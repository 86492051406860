import { pharmacyPreviewFormPdf } from "../../services/actions/pharmacy/forms";
import { frequent_dispensing_autorization_submit } from '../../networking/urlEndPoints'

const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

export const handleSubmitNewFDAForm = (formData, setErrorMessages, errorMessages, dispatch,type, prescriberData) => {

    const specificValidations = {
        name: formData.fetchType === 'winrx' ? 'Name is required' : '',
        patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
        phn: 'PHN is required',
        dob: 'DOB is required',
        deductible: 'Deductible is required',
        pharmacyManager: 'Manager is required',
        doctor: formData?.rational?.includes(11) && !formData?.doctor ? 'Doctor is required' : ''
    }
    const allValidations = { ...specificValidations }

    const newErrorMessages = Object.fromEntries(
        Object.entries(allValidations).map(([field, message]) => [
            field,
            validateField(field, formData[field], message),
        ]))

    setErrorMessages({ ...errorMessages, ...newErrorMessages, })

    const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');
    const initated_date = prescriberData?.map((value) => value.initiatedDate)
    const prescriber = prescriberData?.map((value) => value.doctorName.value)

    const requestedData = {
        date: formData.date,
        // initiated_date: [formData.doctDate],
        initiated_date: initated_date,
        pharmacyname: formData.pharmacyName,
        pharmacymanager: formData.pharmacyManager,
        pharmacyuserdesignation: formData.desgination,
        note: formData.note,
        topharmacyfax: formData.pharmacyfax
    }

    if (prescriber) {
        Object.assign(requestedData, {
            prescriber: prescriber,
        })
    }

    // if (formData.weekly === 'other') {
    if (formData.weekly) {
        Object.assign(requestedData, {
            other_name: formData.other_name ? formData.other_name : null,
            weekly: formData.weekly,
            deductible: formData.deductible,
        })
    }
    //  else {
    //     Object.assign(requestedData, {
    //         deductible: formData.deductible,
    //         weekly: formData.weekly,
    //     })
    // }

    // if (formData.fetchType === 'winrx') {
    //     Object.assign(requestedData, {
    //         fetchtype: formData.fetchType,
    //         winrxpatientphn: formData.phn,
    //         winrxpatientname: formData.name,
    //         winrxpatientdob: formData.dob,
    //         winrxpatientphone: formData.winrxPatientPhone,
    //         winrxpatientlastname: formData.winrxPatientLastName,
    //         winrxpatientfirstname: formData.winrxPatientFirstName
    //     });
    // }

    // if (formData.fetchType === 'local') {
    //     Object.assign(requestedData, {
    //         fetchtype: formData.fetchType,
    //         patient: formData?.patientName?.value,
    //         patientphn: formData.phn,
    //         patientdob: formData.dob,
    //     });
    // }

    if (formData.fetchType) {
        Object.assign(requestedData, {
            fetchtype: formData.fetchType,
            patient: formData?.fetchType === 'winrx' ? null : formData?.patientName?.value,
            patientphn: formData?.fetchType === 'local' ? formData.phn : null,

            patientdob: formData.dob,
            winrxpatientphn: formData?.fetchType === 'local' ? null : formData.phn,

            winrxpatientname: formData?.fetchType === 'local' ? null : formData.name,
            winrxpatientdob: formData?.fetchType === 'local' ? null : formData.dob,
            winrxpatientphone: formData?.fetchType === 'local' ? null : formData.winrxPatientPhone,

            winrxpatientlastname: formData?.fetchType === 'local' ? null : formData.winrxPatientLastName,
            winrxpatientfirstname: formData?.fetchType === 'local' ? null : formData.winrxPatientFirstName
        });

    }

    if (formData.rational) {
        const checkRationalValues = [1, 2, 3];
        const checkRationalsValues = [8, 9, 10];
        const checkRationalListValues = [4, 5, 6, 7];
        const checkFollowupKeys = [11];

        let rational = []
        let rationalList = []
        let followupkey = []
        let rationals = []

        for (let i = 0; i < formData.rational.length; i++) {
            if (checkRationalValues.includes(formData.rational[i])) {
                rational.push(formData.rational[i])
            }
            if (checkRationalListValues.includes(formData.rational[i])) {
                rationalList.push(formData.rational[i])
            }
            if (checkFollowupKeys.includes(formData.rational[i])) {
                followupkey.push(formData.rational[i])
            }
            if (checkRationalsValues.includes(formData.rational[i])) {
                rationals.push(formData.rational[i])
            }
        }

        if (rational?.length > 0) {
            Object.assign(requestedData, {
                rational: rational
            })
        }

        if (rationals?.length > 0) {
            Object.assign(requestedData, {
                rationals: rationals
            })
        }

        if (rationalList?.length > 0) {
            Object.assign(requestedData, {
                rationallist: rationalList
            })
        }

        if (followupkey?.length > 0) {
            Object.assign(requestedData, {
                followup: followupkey
            })
        }

        if (followupkey?.includes(11)) {
            Object.assign(requestedData, {
                doctor: formData?.doctor?.value
            });
        }
    }

    if (allDataPresent) {
        dispatch(pharmacyPreviewFormPdf(frequent_dispensing_autorization_submit, requestedData))
    }
}