import {
	SYNC_TODAYS_DELIVERIES_REQUEST,
	SYNC_TODAYS_DELIVERIES_SUCCESS,
	SYNC_TODAYS_DELIVERIES_FAILURE,

} from '../../../constants';
import { pharmacy_patient_syncdeliverires_winrx, send_delivery_access_code } from '../../../../networking/urlEndPoints'

import { fetchAccessCodeDetails, fetchAllDispenseArchivedDelveries } from './archivedDelveriesLists';
import { currentDate } from '../../../../utils/constants/formInitialStates';

import Axios from '../../../../networking/intraceptor'

export const pharmacySyncTodaysDelveriesRequest = (type) => ({
	type: SYNC_TODAYS_DELIVERIES_REQUEST,
	types: type
});

export const pharmacySyncTodaysDelveriesSuccess = (data, type) => ({
	type: SYNC_TODAYS_DELIVERIES_SUCCESS,
	payload: data,
	types: type
});

export const pharmacySyncTodaysDelveriesFailure = (error, type) => ({
	type: SYNC_TODAYS_DELIVERIES_FAILURE,
	payload: error,
	types: type
});

export const syncTodaysDelveries = (type, setShowModal) => {
	return async (dispatch) => {
		dispatch(pharmacySyncTodaysDelveriesRequest(type));
		try {
			const response = await Axios.post(`${pharmacy_patient_syncdeliverires_winrx}`)

			if (response?.status === 200) {
				dispatch(pharmacySyncTodaysDelveriesSuccess(response?.data, type));
				const formData = {
					date: currentDate,
				}

				if (typeof setShowModal === "function" && (type === 'sync_todays_delveries')) {
					setShowModal(prevState => ({
						...prevState,
						showToast: true
					}));

					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false
						}));
					}, 2000);
				}

				if (response?.data?.count !== 0) {
					dispatch(fetchAllDispenseArchivedDelveries(formData, 'pharmacy-archived-deliveries', null, null))
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacySyncTodaysDelveriesFailure(error?.response?.data, type));
			}
		}
	}
}

export const SendOTP_ToPharmacy = (type, setShowModal, setAccessCodeData) => {
	return async (dispatch) => {
		dispatch(pharmacySyncTodaysDelveriesRequest(type));
		try {
			const response = await Axios.post(send_delivery_access_code)

			if (response?.status === 200) {
				dispatch(pharmacySyncTodaysDelveriesSuccess(response?.data, type));
				const formData = {
					date: currentDate,
				}

				if (typeof setShowModal === "function" && (type === 'SendOTP_ToPharmacy')) {
					setShowModal(prevState => ({
						...prevState,
						showToast: true
					}));

					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false
						}));
					}, 2000);
				}

				if (typeof setAccessCodeData === 'function') {
					dispatch(fetchAccessCodeDetails(setAccessCodeData))
				}

				if (response?.data?.count !== 0) {
					dispatch(fetchAllDispenseArchivedDelveries(formData, 'pharmacy-archived-deliveries', null, null))
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacySyncTodaysDelveriesFailure(error?.response?.data, type));

				if (typeof setShowModal === "function" && (type === 'SendOTP_ToPharmacy')) {
					setShowModal(prevState => ({
						...prevState,
						errorToast: true
					}));

					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							errorToast: false
						}));
					}, 2000);
				}
				
			}
		}
	}
}
