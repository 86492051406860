import {
    UPDATE_RX_WITNESS_DOSE_REQUEST,
    UPDATE_RX_WITNESS_DOSE_SUCCESS,
    UPDATE_RX_WITNESS_DOSE_FAILURE,
} from '../../../constants';

const initialState = {
    data: null,
    loading: false,
    error: null,
    type: null
};

const pharmacyPatientUpdateRxWitnessDoseReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_RX_WITNESS_DOSE_REQUEST:
            return {
                ...state,
                loading: true,
                data: null,
                error: null,
                type: action.types
            };
        case UPDATE_RX_WITNESS_DOSE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                type: action.types,
                error: null,
            };
        case UPDATE_RX_WITNESS_DOSE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null,
                type: action.types
            };
        default:
            return state;
    }
};

export default pharmacyPatientUpdateRxWitnessDoseReducer;
