import {
	ADD_NEW_CLINIC_REQUEST,
	ADD_NEW_CLINIC_SUCCESS,
	ADD_NEW_CLINIC_FAILURE
} from "../../../constants"

import { pharmacy_new_add_clinic } from "../../../../networking/urlEndPoints"
import { hideAddClinicModal } from "../../../../assets/js/custome"

import Axios from "../../../../networking/intraceptor"
import { fetchAllClinics } from "./getClinics"

export const addNewClinicRequest = () => ({
	type: ADD_NEW_CLINIC_REQUEST,
})

export const addNewClinicSuccess = (data) => ({
	type: ADD_NEW_CLINIC_SUCCESS,
	payload: data,
})

export const addNewClinicFailure = (error) => ({
	type: ADD_NEW_CLINIC_FAILURE,
	payload: error,
})

export const addNewClinic = (formData, fullAddress, setShowModal, showModal) => {
	return async (dispatch) => {
		dispatch(addNewClinicRequest())
		try {
			const response = await Axios.post(`${pharmacy_new_add_clinic}`, {
				lat: formData.lat,
				lng: formData.long,
				country: formData.country,
				map_url: formData.mapUrl,
				full_address: fullAddress,
				name: formData.clinicName,
				address: formData.streetName,
				phone: formData.phone,
				phone_extension: formData.phoneExtension,
				fax: formData.fax,
				city: formData.city,
				province: formData.province,
				zip_code: formData.zip
			})

			if (response?.status === 200) {
				dispatch(addNewClinicSuccess(response?.data))
				dispatch(fetchAllClinics())
				setTimeout(() => {
					hideAddClinicModal(setShowModal, showModal)
				}, 2000)

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(addNewClinicFailure(error?.response))
			}
		}
	}
}