import { useEffect } from "react";
import { subScribeTeamNotes } from "../miscellaneous/ably";
import { topHeaderSearch } from "../../constants/workspace";
import { handleHideModal } from '../../assets/js/custome';

import * as DatePicker from '../../assets/js/datepicker'

export function useAblySubScribeTeamNotes(pharmacyId, dispatch, setLoading, profileData) {
	useEffect(() => {
		const ablyConnection = subScribeTeamNotes(pharmacyId, dispatch,setLoading, profileData);
		return () => {
			ablyConnection.close();
		};
	}, [pharmacyId, dispatch, profileData]);
}

export function useTeamNotesEffect(teamNotesMessage, teamNotesMessageResponse, setAddTeamNoteData, addTeamNoteData) {
	useEffect(() => {
		if (teamNotesMessageResponse?.loading) {
			setAddTeamNoteData({
				...addTeamNoteData,
				teamNotes: ''
			});
		} else if (teamNotesMessage) {
			setAddTeamNoteData({
				...addTeamNoteData,
				teamNotes: teamNotesMessage
			});
		}
	}, [teamNotesMessage, teamNotesMessageResponse, addTeamNoteData?.teamNotes])
}

export function useTopHeaderSearchEffect(inputRef, data, setSelectedOptions, setData) {
	const handleKeyPress = (e) => {
		const keyRoutes = {
			q: 'search'
		};
		const isCtrlPressed = e.ctrlKey || e.metaKey || e.shiftKey || e.altKey;
		if (!isCtrlPressed && keyRoutes[e.key]) {
			e.preventDefault();
			inputRef.current.focus()
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	useEffect(() => {
		if (data) {
			setSelectedOptions(data?.length > 0 ? data[0] : null)
		}
	}, [data])

	useEffect(() => {
		const data = topHeaderSearch || [];
		const formattedData = data?.map((value, index) => ({
			label: `${value.name}`,
			value: value.id,
			data: value.route
		}));
		setData(formattedData)
	}, [topHeaderSearch])
}

export function useHeaderEffect(setDropdownOpen, dropdownRef) {
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [])
}

export const useNotificationReplyScrollBarEffect = (textareaRef,
	addTeamNoteData,
	setAddTeamNoteData,
	patientListNotesData, patientNotificationsList
) => {

	useEffect(() => {
		const textareas = document.querySelectorAll('.scrollToTop');
		textareas.forEach(textarea => {
			const { top, bottom } = textarea.getBoundingClientRect();
			const isVisible = top >= 0 && bottom <= window.innerHeight;
			if (!isVisible) {
				textarea.scrollIntoView({ behavior: 'smooth', block: 'start' });
				textareaRef.current.focus();
			}
		});
	}, [addTeamNoteData.notificationIndex]);

	useEffect(() => {
		if (!Array.isArray(patientListNotesData)) return
		const notesData = patientListNotesData
		const formattedNotesData = notesData?.map((value, index) => ({
			label: value.selectednoteoption,
			value: index,
			data: value.notes
		}));

		setAddTeamNoteData({
			...addTeamNoteData,
			patientNotesData: formattedNotesData
		})
	}, [patientListNotesData, patientNotificationsList])
}

export const useDateRangePickerEffect = (addTeamNoteData, setAddTeamNoteData, startDateRef, endDateRef) => {
	const dateFields = [
		{ ref: startDateRef, key: 'startDate' },
		{ ref: endDateRef, key: 'endDate' },
	]
	useEffect(() => {
		const destroyDatePickers = dateFields?.map(({ ref, key }) => {
			const endDatePicker = DatePicker.dataRangePicker(ref, setAddTeamNoteData, addTeamNoteData, key);
			return () => {
				if (endDatePicker) {
					endDatePicker.destroy();
				}
			};
		});
		return () => {
			destroyDatePickers.forEach(destroy => destroy());
		};
	}, [addTeamNoteData, startDateRef, endDateRef])
}

export const useRemoveModalEffect = (setShowModal, showModal, key, modalRef) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				handleHideModal(setShowModal, showModal, key);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setShowModal, showModal]);
}

export const useArchivedDelveriesDateRangePickerEffect = (formData, setFormData, startDateRef, endDateRef) => {

	const dateFields = [
		{ ref: startDateRef, key: 'startDate' },
		{ ref: endDateRef, key: 'endDate' },
	]

	useEffect(() => {
		const destroyDatePickers = dateFields?.map(({ ref, key }) => {
			const endDatePicker = DatePicker.ArchivedDelveriesDatePicker(ref, setFormData, formData, key, formData.startDate);
			return () => {
				if (endDatePicker) {
					endDatePicker.destroy();
				}
			};
		});
		return () => {
			destroyDatePickers.forEach(destroy => destroy());
		};
	}, [formData, startDateRef, endDateRef])
}
