import {
	UPDATE_PHARMACY_USER_PROFILE_REQUEST,
	UPDATE_PHARMACY_USER_PROFILE_SUCCESS,
	UPDATE_PHARMACY_USER_PROFILE_FAILURE
} from '../../constants';

import { pharmacy_user_update_profile, update_break_setting } from '../../../networking/urlEndPoints';
import { fetchUserProfile } from './userProfileActions';
import { authToken } from '../../../storage/authToken'
import { persistor } from '../../../services/store/store'

import Cookies from 'js-cookie';
import axios from 'axios';

export const updatePharmacyUserProfileRequest = () => ({
	type: UPDATE_PHARMACY_USER_PROFILE_REQUEST
});

export const updatePharmacyUserProfileSuccess = (profileData) => ({
	type: UPDATE_PHARMACY_USER_PROFILE_SUCCESS,
	payload: profileData
});

export const updatePharmacyUserProfileFailure = (error) => ({
	type: UPDATE_PHARMACY_USER_PROFILE_FAILURE,
	payload: error
});


export const updateUserProfile = (formdata, selectedFile, dob, address1, city, province, zip_code, address2) => {
	return async (dispatch) => {
		dispatch(updatePharmacyUserProfileRequest());
		try {
			const formData = new FormData();
			formData.append('name', formdata.name);
			formData.append('email', formdata.email);
			formData.append('dob', dob);
			formData.append('phone', formdata.phoneNumber);

			if (address1 !== null) {
				formData.append('address1', address1);
			}
			if (address2 !== null) {
				formData.append('address2', address2);
			}

			if (city !== null) {
				formData.append('city', city);
			}

			if (province !== null) {
				formData.append('province', province);
			}

			if (zip_code !== null) {
				formData.append('zip_code', zip_code);
			}

			formData.append('sin_number', formdata.sinNumber);

			if (selectedFile !== null) {
				formData.append('photo', selectedFile);
			}

			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_user_update_profile}`, formData, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${authToken()}`,
					'Content-Type': 'multipart/form-data',
				},
			});

			if (response?.status === 200) {
				dispatch(updatePharmacyUserProfileSuccess(response?.data));
				dispatch(fetchUserProfile())
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response?.status === 401) {
				Cookies.remove('authToken')
				persistor.purge()
				window.location.href = '/'
			}
			else {
				dispatch(updatePharmacyUserProfileFailure(error?.response));
			}
		}
	};
};


export const updateProfilePicture = (profileImage, setProfilePicLoader) => {
	return async (dispatch) => {
		// dispatch(updatePharmacyUserProfileRequest());
		try {
			const formData = new FormData();
			if (profileImage) {
				formData.append('photo', profileImage);
			}

			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_user_update_profile}`, formData, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${authToken()}`,
					'Content-Type': 'multipart/form-data',
				},
			});

			if (response?.status === 200) {
				// dispatch(updatePharmacyUserProfileSuccess(response?.data));
				dispatch(fetchUserProfile())
				if (typeof setProfilePicLoader === 'function') {
					setProfilePicLoader(false)
				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response?.status === 401) {
				Cookies.remove('authToken')
				persistor.purge()
				window.location.href = '/'
			}
			else {
				// dispatch(updatePharmacyUserProfileFailure(error?.response));
				if (typeof setProfilePicLoader === 'function') {
					setProfilePicLoader(false)
				}
			}
		}
	};
};