

import React, { useState } from 'react';
import { currentDate } from '../../../utils/constants/formInitialStates';
import { useCustomDispatch } from '../../../hooks';
import { HandleCreatePillReminder } from '../../../services/actions/pharmacy/app/addAppData';

import { useCustomSelectors } from '../../../services/selectors/allSelectors';
import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors';
import { ErrorToastMessage, SuccessToastMessage } from '../../loaders/toastMessage';

const TextInput = ({ formData, setFormData, label = "No Label", name = "", index, height = "42px", textType = 'text', error }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        const updatedFormData = [...formData];
        updatedFormData[index][name] = value;
        setFormData(updatedFormData);
    };

    return (
        <div className="relative w-full">
            <input
                type={textType}
                id={`${name}-${index}`}
                autoComplete="off"
                onChange={handleChange}
                name={name}
                style={{ height: height }}
                value={formData[index][name] || ''}
                className={`block pl-4 ${error && 'border-red-500'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none
                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text
                bg-white `}
                placeholder=""
                step="any"
            />
            <label
                htmlFor={`${name}-${index}`}
                className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2
                origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2
                peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
            >
                {label}
            </label>
        </div>
    );
};

const DropdownSelect = ({ formData, setFormData, label = "No Label", name = "", index, options = [], height = "42px", error }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        const updatedFormData = [...formData];
        updatedFormData[index][name] = value;
        setFormData(updatedFormData);
    };

    return (
        <div className="relative w-full">
            <select
                style={{ height: height }}
                id={`${name}-${index}`}
                name={name}
                value={formData[index][name] || ''}
                onChange={handleChange}
                className={`block pl-4 px-2.5 ${error && 'border-red-500'} pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
                bg-white `}
            >
                {options.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <label
                htmlFor={`${name}-${index}`}
                className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
            >
                {label}
            </label>
        </div>
    );
};

const ScheduleRadioButtons = ({ formData, setFormData, label = "No Label", name = "", error, options = [], index, daysOfWeekError }) => {

    const handleChange = (e) => {
        const value = e.target.value;
        const updatedFormData = [...formData];
        updatedFormData[index][name] = value;
        setFormData(updatedFormData);
    };

    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedFormData = [...formData];
        updatedFormData[index][name] = value;
        setFormData(updatedFormData);
    };

    return (
        <div className="relative w-full">
            <ul className={`items-center w-full text-sm font-medium text-gray-900 bg-white ${error ? 'border-red-500' : ' border border-gray-200'}  rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white`}>
                {options.map((option, i) => (
                    <li key={i} className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                        <div className="flex items-center ps-3">
                            <input
                                id={`${name}-${option.value}-${index}`}
                                name={`${name}-${index}`}
                                value={option.value}
                                type="radio"
                                checked={formData[index][name] === option.value}
                                onChange={handleChange}
                                className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                                htmlFor={`${name}-${option.value}-${index}`}
                                className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                {option.label}
                            </label>
                        </div>
                    </li>
                ))}
            </ul>
            <label
                htmlFor={name}
                className="ml-3 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
                {label}
            </label>

            {(formData[index][name] === "weekly" || formData[index][name] === "alternate") && (
                <ScheduleCheckBoxGroup formData={formData} setFormData={setFormData} name="daysOfWeek" index={index} daysOfWeekError={daysOfWeekError} />
            )}

            {/* {(formData[index][name] === "monthly") &&(
                <input
                    id={formData[index][name]}
                    type="text"
                    value={formData.monthlyDate}
                    name={`monthlyDate${index}`}
                    // onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />)} */}

            {formData[index][name] === "monthly" && (
                <div className="mt-2">
                    <input id={`date-${index}`}
                        type="date"
                        name={`monthlyDate-${index}`}
                        defaultValue={formData[index].monthlyDate || ''}
                        onChange={(e) => handleInputChange(index, e)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    <label htmlFor={`date-${index}`}></label>
                </div>
            )}

        </div>
    );
};

const ScheduleCheckBoxGroup = ({ formData, setFormData, name, index, daysOfWeekError }) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const handleCheckboxChange = (day) => {
        const updatedFormData = [...formData];
        const currentDays = updatedFormData[index][name] || [];
        if (currentDays.includes(day)) {
            updatedFormData[index][name] = currentDays.filter(d => d !== day);
        } else {
            updatedFormData[index][name] = [...currentDays, day];
        }
        setFormData(updatedFormData);
    };

    return (
        <div className={`flex flex-wrap mt-2 bg-gray-100 p-2 rounded-md  ${daysOfWeekError && 'border border-red-500'}`}>
            {daysOfWeek?.map((day, i) => (
                <div key={i} className="flex items-center mr-4 mb-2">
                    <input
                        type="checkbox"
                        id={`${name}-${day}-${index}`}
                        name={name}
                        value={day}
                        checked={(formData[index][name] || []).includes(day)}
                        onChange={() => handleCheckboxChange(day)}
                        className="fs-14 w-4 pl-2  h-4 rounded-md rubik-400 border border-gray-300 text-blue-700"
                    />
                    <label
                        htmlFor={`${name}-${day}-${index}`}
                        className="text-sm pl-2 font-medium text-gray-900 dark:text-gray-300"
                    >
                        {day}
                    </label>
                </div>
            ))}
        </div>
    );
};

const CreatePillReminder = ({ orderDetails }) => {

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dispatch = useCustomDispatch()
    const { pharmacyId } = useCustomSelectors()
    const { AddBlisterPackResponse } = useBlisterSelectors()
    const [showToast, setShowToast] = useState({ showToast: false, errorToast: false })

    const [formData, setFormData] = useState([{
        pill_name: '', winrxqty: '', unit: 'Pills', dose: '', days: '', time: '', medicine_instr: '', schedule: 'daily', daysOfWeek: daysOfWeek, monthlyDate: currentDate, repeat_cycle: ''
    }]);

    const [errorMessage, setErrorMessage] = useState([{
        pill_name: '', winrxqty: '', unit: '', dose: '', days: '', time: '', medicine_instr: '', schedule: '', daysOfWeek: '', monthlyDate: '', repeat_cycle: ''
    }]);

    const addPillReminder = () => {
        setFormData([...formData, {
            pill_name: '', winrxqty: '', unit: 'Pills', dose: '', days: '', time: '', medicine_instr: '', schedule: 'daily', daysOfWeek: daysOfWeek,
            monthlyDate: currentDate, repeat_cycle: ''
        }]);

        setErrorMessage([...errorMessage, {
            pill_name: '', winrxqty: '', unit: '', dose: '', days: '', time: '', medicine_instr: '', schedule: '', daysOfWeek: '', monthlyDate: '', repeat_cycle: ''
        }]);
    };

    const deletePillReminder = (index) => {
        const updatedFormData = formData.filter((_, i) => i !== index);
        const updatedErrorMessage = errorMessage.filter((_, i) => i !== index);
        setErrorMessage(updatedErrorMessage);
        setFormData(updatedFormData);
    };

    const requiredFields = ['pill_name', 'winrxqty', 'unit', 'dose', 'days', 'time', 'medicine_instr'];

    const validateFormData = (data) => {
        let isValid = true;
        const updatedErrorMessages = [...errorMessage];
        data.forEach((entry, index) => {
            requiredFields.forEach((field) => {
                if (!entry[field]) {
                    updatedErrorMessages[index][field] = `Please fill out the ${field} field.`;
                    isValid = false;
                } else {
                    updatedErrorMessages[index][field] = '';
                }
            });

            if (entry.schedule === 'monthly' && !entry.monthlyDate) {
                updatedErrorMessages[index].monthlyDate = 'Please select a date for the monthly schedule.';
                alert('please select the date')
                isValid = false;
            } else {
                updatedErrorMessages[index].monthlyDate = '';
            }

            if (entry.schedule !== 'daily' && !entry.repeat_cycle) {
                updatedErrorMessages[index].repeat_cycle = 'please enter the cycle details';
                isValid = false;
            } else {
                updatedErrorMessages[index].repeat_cycle = '';
            }

            if ((entry.schedule === 'weekly' || entry.schedule === 'alternate') && (!entry.daysOfWeek || entry.daysOfWeek.length === 0)) {
                updatedErrorMessages[index].daysOfWeek = `Please select at least one day for the ${entry.schedule} schedule.`;
                isValid = false;
            } else {
                updatedErrorMessages[index].daysOfWeek = '';
            }
        });
        setErrorMessage(updatedErrorMessages);
        return isValid;
    };


    const transformFormData = (data) => {
        const formattedApiData = data?.map((value) => {
            return {
                unit: value.unit,
                pill_name: value.pill_name,
                medication_total_qty: value.winrxqty,
                dose: value.dose,
                reminder_type: value.schedule,
                note: value.medicine_instr,
                remind_at: value.time,
                days: value.days,
                repeat_cycle: value.schedule == 'daily' ? '' : value?.repeat_cycle,
                week_day: (value.schedule == 'alternate' || value.schedule == 'weekly') ? value?.daysOfWeek : '',
                monthly_date: value.schedule == 'monthly' ? value.monthlyDate : ''
            };
        });
        const reqData = {
            order_id: orderDetails.id,
            patient_id: orderDetails.patient_id,
            pillreminder: formattedApiData,
            pharmacy_id: pharmacyId
        }

        return reqData;
    };

    const handleSaveReminder = () => {
        if (validateFormData(formData)) {
            const data = transformFormData(formData)
            dispatch(HandleCreatePillReminder('create_pill_reminder', data, setShowToast))
        } else {
        }
    }

    return (
        <React.Fragment>
            <div className='mt-2 border p-4 shadow-md rounded-md'>
                <h1 className='rubik-500 fs-14 text-gray-700 mb-3'>Create Pill Reminder</h1>

                {formData?.map((entry, index) => (
                    <div key={index} className="mb-4 ">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 ">
                            <TextInput
                                label="Pill Name"
                                name="pill_name"
                                formData={formData}
                                setFormData={setFormData}
                                textType="text"
                                height="48px"
                                index={index}
                                error={errorMessage[index].pill_name}
                            />
                            <TextInput
                                label="WinRx Qty."
                                name="winrxqty"
                                formData={formData}
                                setFormData={setFormData}
                                textType="number"
                                height="48px"
                                index={index}
                                error={errorMessage[index].winrxqty}
                            />

                            <DropdownSelect
                                label="Unit"
                                name="unit"
                                formData={formData}
                                setFormData={setFormData}
                                index={index}
                                options={[
                                    { value: "Pills", label: "Pills" },
                                    { value: "Capsule", label: "Capsule" },
                                    { value: "Drops", label: "Drops" },
                                    { value: "Injections", label: "Injections" },
                                    { value: "Milligrams", label: "Milligrams" },
                                    { value: "Milliliter", label: "Milliliter" },
                                    { value: "Spray", label: "Spray" },
                                ]}
                                height="48px"
                                error={errorMessage[index].unit}
                            />

                            <TextInput
                                label="Dose"
                                name="dose"
                                formData={formData}
                                setFormData={setFormData}
                                textType="number"
                                height="48px"
                                index={index}
                                error={errorMessage[index].dose}
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-2">
                            <div>
                                <ScheduleRadioButtons
                                    label="Schedule"
                                    name="schedule"
                                    formData={formData}
                                    setFormData={setFormData}
                                    index={index}
                                    options={[
                                        { value: "daily", label: "Daily" },
                                        { value: "alternate", label: "Alternate" },
                                        { value: "weekly", label: "Weekly" },
                                        { value: "monthly", label: "Monthly" },
                                    ]}
                                    error={errorMessage[index].schedule}
                                    daysOfWeekError={errorMessage[index].daysOfWeek}
                                />
                                {errorMessage[index].daysOfWeek && (
                                    <div className="text-red-500 text-sm">{errorMessage[index].daysOfWeek}</div>
                                )}

                            </div>
                            <div>

                                {formData[index].schedule === 'daily' ?
                                    <TextInput
                                        label="How many days ?"
                                        name="days"
                                        formData={formData}
                                        setFormData={setFormData}
                                        textType="number"
                                        height="48px"
                                        index={index}
                                        error={errorMessage[index].days}
                                    />
                                    :
                                    <TextInput
                                        label="Repeat Cycle"
                                        name="repeat_cycle"
                                        formData={formData}
                                        setFormData={setFormData}
                                        textType="number"
                                        height="48px"
                                        index={index}
                                        error={errorMessage[index].repeat_cycle}
                                    />}
                            </div>

                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-2">

                            <TextInput
                                label="Time"
                                name="time"
                                formData={formData}
                                setFormData={setFormData}
                                textType="time"
                                height="48px"
                                index={index}
                                error={errorMessage[index].time}
                            />
                            <TextInput
                                label="Medicine Instructions"
                                name="medicine_instr"
                                formData={formData}
                                setFormData={setFormData}
                                textType="text"
                                height="48px"
                                index={index}
                                error={errorMessage[index].medicine_instr}
                            />

                            <div className='gap-4 pt-1'>
                                {index !== 0 &&
                                    <button
                                        type="button"
                                        className=" bg-red-600 fs-14 rubik-400 text-white px-4 py-2 rounded-md "
                                        onClick={() => deletePillReminder(index)}
                                    >
                                        Delete
                                    </button>}

                                {index === formData?.length - 1 &&
                                    <button
                                        type="button"
                                        className={` bg-blue-600 fs-14 rubik-400 text-white px-4 py-2 rounded-md ${index === 0 ? null : 'ml-2'}`}
                                        onClick={addPillReminder}
                                    >
                                        Add More
                                    </button>}
                            </div>
                        </div>
                        <hr className='mt-4' />
                    </div>
                ))}

                <button type="button" onClick={handleSaveReminder} className={` bg-green-600 fs-14 rubik-400 text-white px-4 py-2 rounded-md`}>
                    {AddBlisterPackResponse?.loading && AddBlisterPackResponse.type === 'create_pill_reminder' ? 'Saving...' : 'Save'}
                </button>

            </div>

            {showToast.showToast && AddBlisterPackResponse.type === 'create_pill_reminder' && <SuccessToastMessage status={'Reminder added Successfully'} />}
            {showToast.errorToast && AddBlisterPackResponse.type === 'create_pill_reminder' && <ErrorToastMessage status={'Oops!. Got an issue while adding details.please  try again.'} />}

        </React.Fragment>
    );
};

export default CreatePillReminder;
