import {
	FETCH_USER_CHECKINOUT_REQUEST,
	FETCH_USER_CHECKINOUT_SUCCESS,
	FETCH_USER_CHECKINOUT_FAILURE,
} from '../../constants';

import { pharmacy_user_checkinout } from '../../../networking/urlEndPoints'
import Axios from '../../../networking/intraceptor'


export const fetchUserCheckInOutRequest = () => ({
	type: FETCH_USER_CHECKINOUT_REQUEST,
});

export const fetchUserCheckInOutSuccess = (data) => ({
	type: FETCH_USER_CHECKINOUT_SUCCESS,
	payload: data,
});

export const fetchUserCheckInOutFailure = (error) => ({
	type: FETCH_USER_CHECKINOUT_FAILURE,
	payload: error,
});

export const fetchUserCheckInOut = (start_date, end_date) => {
	return async (dispatch) => {
		dispatch(fetchUserCheckInOutRequest());
		try {
			const response = await Axios.post(`${pharmacy_user_checkinout}`, {
				start_date: start_date,
				end_date: end_date
			});
			if (response?.status === 200) {
				dispatch(fetchUserCheckInOutSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchUserCheckInOutFailure(error?.response))
			}
		}
	};
};