import React, { useEffect, useState } from "react";

import { useDispenseSelectors } from "../../../services/selectors/dispenseSelectors";
import { fetchSignAPI } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";

import * as Common from "../../../components/common";
import * as Hooks from "../../../hooks";

import * as routeNames from "../../../routes/routeName";
import ChatBox from "../../../components/chat/chatbox";

const apiListHeading = [
    { text: "NAME", classNames: ['p-3'] },
    { text: "PHN", classNames: ['p-3'] },
    { text: "RX", classNames: ['p-3'] },
    { text: "RX SIG", classNames: ['p-3'] },
]

const TableRowData = ({ value, className1 = "p-3" }) => {
    return (
        <td className={className1}>
            {value}
        </td>
    );
};

const no_of_records = 10;

const SignCheckAI = () => {
    
    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();
    const dispatch = Hooks.useCustomDispatch();

    const searchParams = new URLSearchParams();
    const initialPage = parseInt(new URLSearchParams(location.search).get("page")) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedData, setSelectedData] = useState([]);

    const { dispenseMemo } = useDispenseSelectors();
    const memoList = dispenseMemo?.data?.data;

    useEffect(() => {
        setPageNumber(initialPage);
    }, [initialPage]);

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: no_of_records,
        };
        dispatch(fetchSignAPI("api_sign_list", params));
        return () => { };
    }, []);

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: no_of_records,
        };

        const newUrl = `${routeNames.pharmacy_sig_check_ai}?${searchParams.toString()}`
        navigate(newUrl);
        setPageNumber(value);
        setSelectedRow(null)
        dispatch(fetchSignAPI("api_sign_list", params));
    };

    return (
        <>
            <div className="BodyBgColor ">
                <div className="container mx-auto px-4 pt-5 pb-5 ">
                    <div className="grid grid-cols-12 gap-4 w-full">
                        <div
                            className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left"
                        >
                            <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                <Common.BreadCrumb />
                            </h2>
                        </div>
                    </div>
                    <div className="flex bg-white mt-2">
                        <div className="grid grid-cols-12 gap-2 w-full" >
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                                <div className="content">

                                    <div className="container mt-2">
                                        <div className=" rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white pt-0">


                                            {dispenseMemo?.loading &&
                                                dispenseMemo?.type === "api_sign_list" ? (
                                                <div className="flex justify-center items-center mt-60 mb-80">
                                                    <Common.LoadingView />
                                                </div>
                                            ) : dispenseMemo?.error !== null &&
                                                dispenseMemo?.type === "api_sign_list" ? (
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">
                                                        Oops!
                                                    </h1>
                                                    <p className="text-lg text-gray-800 mb-2">
                                                        Something went wrong while fetching the data.
                                                    </p>
                                                    <button
                                                        onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500"
                                                    >
                                                        Please Try Again.
                                                    </button>
                                                </div>
                                            ) : memoList?.data?.length === 0 &&
                                                dispenseMemo?.type === "api_sign_list" ? (
                                                <div className="bg-white rounded-md">
                                                    <p className="text-gray-500">
                                                        No Record available
                                                    </p>
                                                </div>
                                            ) : dispenseMemo?.type === "api_sign_list" ? (
                                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-0">
                                                    <div className="shadow-md border rounded-lg  relative overflow-x-auto">
                                                        <table className="bl_oatLogHistory w-full text-sm text-left overflow-auto rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                            <thead
                                                                className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900"
                                                                style={{
                                                                    borderBottom: "1px solid lightgray",
                                                                    background: "#f6f4f4",
                                                                }}
                                                            >
                                                                <tr>
                                                                    {apiListHeading?.map((header, index) => (
                                                                        <th
                                                                            key={index}
                                                                            scope="col"
                                                                            className={header?.classNames?.join(" ")}
                                                                        >
                                                                            <p className="rubik-500 fs-14">
                                                                                {header?.text}
                                                                            </p>
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {memoList.data?.map((value, idx) => (
                                                                    <React.Fragment key={idx} >
                                                                        <tr onClick={() => {
                                                                            setSelectedData({ system: value?.system_data, ai: value?.ai_data })
                                                                            setSelectedRow(value.rx_number_id);
                                                                        }} className={`cursor-pointer hover:bg-gray-100 border-b ${selectedRow === value.rx_number_id ? 'bg-blue-100' : ''}`}>
                                                                            <TableRowData value={value?.patient_name} />
                                                                            <TableRowData value={value?.phn} />
                                                                            <TableRowData value={value?.rx} />
                                                                            <TableRowData className1="pl-4" value={value?.rxsig} />
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                        {dispenseMemo?.error !== null ? null : (
                                                            <>
                                                                {memoList.data?.length !== 0 && (
                                                                    <>
                                                                        {memoList.pagination?.last_page !== 1 && (
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={
                                                                                                memoList.pagination?.last_page
                                                                                            }
                                                                                            page={pageNumber}
                                                                                            onChange={(event, value) => {
                                                                                                handlePagination(event, value);
                                                                                            }}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}

                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {dispenseMemo?.loading ? null : (
                            <div className="p-4 pt-0 " style={{ width: "35%" }}>
                                {selectedRow ? (
                                    <div className='sticky' style={{ top: '220px' }}>
                                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                            <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400 bg-white">
                                                <thead className="text-xs text-gray-700 uppercase dark:text-gray-400" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">
                                                            COMPARISON
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-center">
                                                            SYSTEM GENERATED
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-center">
                                                            AI GENERATED
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="border-b border-gray-200 dark:border-gray-700">
                                                        <th scope="row" className="px-6 py-2 text-sm text-gray-600 whitespace-nowrap bg-gray-50 dark:text-white" style={{ background: '#f6f4f4' }}>
                                                            START DATE
                                                        </th>
                                                        <td className="px-6 text-center py-2">
                                                            {selectedData?.system?.start_date}
                                                        </td>
                                                        <td className="px-6 text-center py-2">
                                                            {selectedData?.ai?.start_date}
                                                        </td>
                                                    </tr>
                                                    <tr className="border-b border-gray-200 dark:border-gray-700">
                                                        <th style={{ background: '#f6f4f4' }} scope="row" className="px-6 py-2 text-sm text-gray-600 whitespace-nowrap bg-gray-50 dark:text-white">
                                                            END DATE
                                                        </th>
                                                        <td className="px-6 text-center py-2">
                                                            {selectedData?.system?.end_date}
                                                        </td>
                                                        <td className="px-6 text-center py-2">
                                                            {selectedData?.ai?.end_date}
                                                        </td>
                                                    </tr>
                                                    <tr className="border-b border-gray-200 dark:border-gray-700">
                                                        <th style={{ background: '#f6f4f4' }} scope="row" className="px-6 py-2 text-sm text-gray-600 whitespace-nowrap bg-gray-50 dark:text-white">
                                                            DWI
                                                        </th>
                                                        <td className="px-6 text-center py-2">
                                                            {selectedData?.system?.dwi}
                                                        </td>
                                                        <td className="px-6 text-center py-2">
                                                            {selectedData?.ai?.dwi}
                                                        </td>
                                                    </tr>
                                                    <tr className="border-b border-gray-200 dark:border-gray-700">
                                                        <th style={{ background: '#f6f4f4' }} scope="row" className="px-6 py-2 text-sm text-gray-600 whitespace-nowrap bg-gray-50 dark:text-white">
                                                            DOSE
                                                        </th>
                                                        <td className="px-6 text-center py-2">
                                                            {selectedData?.system?.dose}
                                                        </td>
                                                        <td className="px-6 text-center py-2">
                                                            {selectedData?.ai?.dose}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : memoList?.data?.length !== 0 && dispenseMemo?.type === "api_sign_list" ? (
                                    <div className="text-gray-700 text-center mt-14">
                                        Select an Sign from left side to see comparison here.
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ChatBox />

        </>
    );
}

export default SignCheckAI;