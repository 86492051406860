import {
	PHARMACY_PATIENT_ADD_NOTE_REPLY_REQUEST,
	PHARMACY_PATIENT_ADD_NOTE_REPLY_SUCCESS,
	PHARMACY_PATIENT_ADD_NOTE_REPLY_FAILURE
} from '../../../constants';

import { pharmacy_patient_notes_reply } from '../../../../networking/urlEndPoints'
import { fetchPatientNotifications } from './pharmacyPatientNotifications';

import Axios from '../../../../networking/intraceptor'

export const addPatientNoteReplyRequest = () => ({
	type: PHARMACY_PATIENT_ADD_NOTE_REPLY_REQUEST,
});

export const addPatientNoteReplySuccess = (data) => ({
	type: PHARMACY_PATIENT_ADD_NOTE_REPLY_SUCCESS,
	payload: data,
});

export const addPatientNoteReplyFailure = (error) => ({
	type: PHARMACY_PATIENT_ADD_NOTE_REPLY_FAILURE,
	payload: error,
});

export const addPatientNoteReply = (id, addnotes, page, addTeamNoteData, setAddTeamNoteData, value) => {
	return async (dispatch) => {
		dispatch(addPatientNoteReplyRequest());
		try {
			const response = await Axios.post(`${pharmacy_patient_notes_reply}`, {
				note: addnotes,
				note_id: id
			})

			if (response?.status === 200) {

				dispatch(addPatientNoteReplySuccess(response?.data));
				dispatch(fetchPatientNotifications(page, setAddTeamNoteData, addTeamNoteData, value));

				if (typeof setAddTeamNoteData === "function") {
					setAddTeamNoteData({
						...addTeamNoteData,
						showToast: true,
					})
					setTimeout(() => {
						setAddTeamNoteData({
							...addTeamNoteData,
							showToast: false,
						})
					}, 2000);
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(addPatientNoteReplyFailure(error?.response));
			}
		}
	}
}

