import {
    COMMENT_LIST_REQUEST,
    COMMENT_LIST_SUCCESS,
    COMMENT_LIST_FAILURE
} from "../../../actions/admin/constants"

const initialState = {
    data: [],
    loading: false,
    error: null,
    type: null,
    currentPage: 1,
    totalPages: null
};

const TeamsCommentListReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMMENT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                type: action.fetchType,
            };
        case COMMENT_LIST_SUCCESS:
            const existingIds = new Set(state?.data?.map(item => item.id));
            // const newData = action?.payload?.data?.conversations?.filter(item => !existingIds.has(item.id));
            const newData = action?.payload?.data?.conversations
            ? action.payload.data.conversations.filter(item => !existingIds.has(item.id))
            : []
            return {
                ...state,
                loading: false,
                // data: action?.payload?.data?.conversations,
                data: [...state.data, ...newData],
                currentPage: action?.payload?.data?.pagination?.current_page,
                totalPages: action?.payload?.data?.pagination?.last_page,

                type: action.fetchType,
                error: null,
            };
        case COMMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload,
                type: action.fetchType,
                data: [],
            };
        default:
            return state;
    }
};

export default TeamsCommentListReducer;
