import React, {
	useEffect,
	useState,
	useRef
} from 'react'

import PublicHolidays from '../../components/publicHolidays/publicHolidays'
import ScheduleView from '../../components/scheduleView/ScheduleView'
import PostMedia from '../../components/postMedia/main/postMedia'

import {
	Sidebar,
	Footer,
	LoadingView
} from '../../components/common'

import { useCustomDispatch } from '../../hooks'

import { authToken } from '../../storage/authToken'
import { fetchStatHolidays } from '../../services/actions/main/getAllStatHolidayActions'
import { getEmployeeWorkSchedule } from '../../services/actions/main/userTeamScheduleActions'
import { subscribePostLists } from '../../utils/miscellaneous/ably'

import { createWallPost } from '../../services/actions/posts/pharmacyAddWallPostActions'
import { useCustomSelectors } from '../../services/selectors/allSelectors'
import MyAllPosts from './myProfileWallList/myAllPosts'

import ChatBox from '../../components/chat/chatbox'

const MyProfile = () => {

	const dispatch = useCustomDispatch()
	const token = authToken()
	const fileInputRef = useRef(null);

	const { userProfileResponse, pharmacyId } = useCustomSelectors()

	const [postWallContent, setPostWallContent] = useState('')
	const [showDropdown, setShowDropDown] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [apiCallMade, setApiCallMade] = useState(false)
	const [wallListLoading, setWallListLoading] = useState(false)

	const [postImage, setPostImage] = useState([])
	const [postWallImage, setPostWallImage] = useState([])
	const [postWallVideo, setPostWallVideo] = useState([])

	useEffect(() => {
		if (token && !apiCallMade) {
			dispatch(fetchStatHolidays())
			dispatch(getEmployeeWorkSchedule())
			setApiCallMade(true)
		}
		return () => { }
	}, [])

	const handleButtonClick = () => {
		fileInputRef.current.click();
	}

	const handleFileChange = (e) => {
		const selectedFiles = e.target.files;
		if (selectedFiles) {
			const filesArray = Array?.from(selectedFiles);
			setPostWallImage((prevImages) => [...prevImages, ...filesArray]);
			setPostImage(selectedFiles)
		}
	};

	const handleVideoChange = (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			const maxSize = 10 * 1024 * 1024;
			if (selectedFile?.size > maxSize) {
				alert('File size should not exceed 10 MB.');
			} else {
				setPostWallVideo(selectedFile);
			}
		}
	}

	function handleEmojies(emojiData) {
		setPostWallContent((inputValue) => inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji))
	}

	const sendMessage = () => {
		if (postWallContent || postWallImage || postWallVideo?.length !== 0) {
			setWallListLoading(true)
			dispatch(createWallPost(postWallContent, postWallImage, setOpenModal, postWallVideo, 'my-profile'));
			setOpenModal(false)
		}
	}

	useEffect(() => {

		const ablyConnection = subscribePostLists(pharmacyId, dispatch, setWallListLoading);

		return () => {
			ablyConnection.close();
		};
	}, [])

	return (
		<>
			<div className={`${userProfileResponse?.loading ? 'BodyBgColor' : 'BodyBgColor'}`}>

				{userProfileResponse?.loading ?
					<div className="flex flex-col items-center justify-center mt-80" >
						<LoadingView />
					</div>
					:
					userProfileResponse?.error !== null ?
						<div className="flex flex-col items-center justify-center mt-80">
							<div className="p-4 mb-4 text-center text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
								<span className="font-medium text-xl">Oops! Unable to fetch data</span><br />
								<p className="text-gray-600 dark:text-gray-300 mt-2">It seems we couldn't retrieve the information you requested.</p>
								<button className="text-blue-500 hover:underline focus:outline-none mt-4">Click here to try again</button>
							</div>
						</div>
						:
						<>
							<div className="container mx-auto px-4 pt-5 pb-5">
								<div className="grid grid-cols-12 gap-4">
									<Sidebar />
									<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
										<div id="dashboard" className="content">
											<div className="grid grid-cols-12 gap-4">
												<div className="lg:col-span-8 md:col-span-12 sm:col-span-12 col-span-12">
													<PostMedia
														handleFileChange={handleFileChange}
														handleButtonClick={handleButtonClick}
														handleEmojies={handleEmojies}
														postWallContent={postWallContent}
														postImage={postImage}
														setPostWallContent={setPostWallContent}
														setPostWallImage={setPostWallImage}
														postWallImage={postWallImage}
														setPostImage={setPostImage}
														sendMessage={sendMessage}
														showDropdown={showDropdown}
														setShowDropDown={setShowDropDown}
														openModal={openModal}
														setOpenModal={setOpenModal}
														handleVideoChange={handleVideoChange}
														postWallVideo={postWallVideo}
														setPostWallVideo={setPostWallVideo}
													/>
													<MyAllPosts
														postWallContent={postWallContent}
														postWallImage={postWallImage}
														postWallVideo={postWallVideo}
														wallListLoading={wallListLoading} />
												</div>
												<div className="scroll-box top-5 h-auto sm:h-auto md:h-auto lg:h-screen relative sm:relative md:relative lg:sticky overflow-auto lg:col-span-4 md:col-span-12 sm:col-span-12 col-span-12">
													<ScheduleView />
													<PublicHolidays />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
				}

				<ChatBox />

				{userProfileResponse?.loading ? null : userProfileResponse?.error !== null ? null : <Footer />}

			</div>
		</>
	)
}

export default MyProfile