import React, {
	useState,
	useRef,
	useEffect
} from 'react'

import {
	Sidebar,
	Footer,
	ProfilePicLoader,
} from '../../components/common'

import {
	Errors,
	SuccessMessage
} from '../../components/error/alertMessages'

import {
	useCustomDispatch,
} from '../../hooks'

import {
	updateProfilePicture,
	updateUserProfile
} from '../../services/actions/auth/updateUserProfileActions'

import {
	onPlaceSelected111,
	useInputHandlers
} from '../../assets/js/custome'

import { handleUpdateApiError } from '../../components/error/handleApiErros'
import { usePlacesWidget } from 'react-google-autocomplete';
import { useCustomSelectors } from '../../services/selectors/allSelectors'
import { autoSearchKey } from '../../networking/urlEndPoints'

import ChatBox from '../../components/chat/chatbox'
import UpdatingCoverPicture from './updating-cover-picture'

import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

const Settings = () => {

	const dispatch = useCustomDispatch()

	const { profileData, updateProfileResponse } = useCustomSelectors()
	const { inputRefs, handleInputFocus, handleInputBlur } = useInputHandlers();

	const profile = profileData
	const fileInputRef = useRef(null);
	const imageRef = useRef(null);
	const endDatePickerRef = useRef()

	const [dob, setDob] = useState(null)
	const [profilePicLoader, setProfilePicLoader] = useState(false)

	const [message, setMessage] = useState('')
	const [updateProfileMsg, setUpdateProfileMsg] = useState('')
	const [borderColor, setBorderColor] = useState('white')

	const [address1, setAddress1] = useState('')
	const [city, setCity] = useState('')
	const [province, setProvince] = useState('')
	const [zip_code, setZipCode] = useState('')
	const [address2, setAddress2] = useState('')
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		phoneNumber: '',
		sinNumber: '',
		password: '',
		dob: '',
	})

	const handleInputChange = (type, e) => {
		if (type === 'name') {
			setFormData({
				...formData,
				name: e.target.value,
			});
			setBorderColor('white')

		} else if (type === 'email') {
			setFormData({
				...formData,
				email: e.target.value,
			});
		} else if (type === 'phoneNumber') {
			setFormData({
				...formData,
				phoneNumber: e.target.value,
			});
			setBorderColor('white')

		} else if (type === 'sinNumber') {
			setFormData({
				...formData,
				sinNumber: e.target.value,
			});
			setBorderColor('white')

		} else if (type === 'password') {
			setFormData({
				...formData,
				password: e.target.value,
			});
			setBorderColor('white')

		} else if (type === 'dob') {
			setFormData({
				...formData,
				dob: e.target.value,
			});
			setBorderColor('white')
		}
	};

	const changeImage = () => {
		fileInputRef.current.click();
	};



	const onFileInputChange = async (e) => {
		setProfilePicLoader(true)

		const file = e.target.files[0];
		if (file) {

			const reader = new FileReader();

			reader.onload = (event) => {
				imageRef.current.src = event.target.result;
			};
			reader.readAsDataURL(file);
			await dispatch(updateProfilePicture(file, setProfilePicLoader))

		}
	};

	useEffect(() => {
		if (profile) {
			setFormData({
				...formData,
				name: profile.name,
				email: profile.email,
				phoneNumber: profile.phone,
				sinNumber: profile.sin_number,
				dob: profile.dob
			})
			setDob(profile.dob)
			setAddress1(profile.address1)
			setCity(profile.city)
			setProvince(profile.province)
			setZipCode(profile.zip_code)
			setAddress2(profile?.address2)
		}
	}, [profile])

	const handleSaveChanges = () => {
		if (!formData.name || !formData.email || !dob || !formData.phoneNumber || !formData.sinNumber) {
			setBorderColor('red')
			setUpdateProfileMsg('')

		} else if (formData.phoneNumber.length < 10) {
			setMessage('Phone number should be 10 digits');
			setBorderColor('red')
			setUpdateProfileMsg('')
		} else if (formData.sinNumber.length < 9) {
			setMessage('SIN Number should be 9 digits');
			setBorderColor('red')
			setUpdateProfileMsg('')

		} else {
			setBorderColor('white')
			dispatch(updateUserProfile(formData, null, dob, address1, city, province, zip_code, address2));
			setMessage('');
		}
	}

	useEffect(() => {
		if (updateProfileResponse?.loading) {
			setMessage('')
			setUpdateProfileMsg('')
		} else if (updateProfileResponse?.profile?.success === true) {
			setUpdateProfileMsg('Profile Updated Successfully')
		} else if (updateProfileResponse?.error) {
			const error = updateProfileResponse?.error?.status
			const errorMessages = handleUpdateApiError(error)
			setMessage(errorMessages)
		}
	}, [updateProfileResponse])

	useEffect(() => {
		if (endDatePickerRef.current) {
			let endDatepickerInstance

			endDatepickerInstance = flatpickr(endDatePickerRef.current, {
				dateFormat: 'Y-m-d',
				maxDate: 'today',
				defaultDate: dob,

				onChange: function (selectedDates, dateStr, instance) {
					setDob(dateStr)
					setBorderColor('white')
				},
			})
		}
	}, [dob])

	useEffect(() => {
		setMessage('')
	}, [])

	const handlePlaceSelected = (place) => {
		onPlaceSelected111(
			place,
			setCity,
			setProvince,
			setFormData,
			setAddress1,
			setAddress2,
			setZipCode
		);
	};

	const { ref: ref1 } = usePlacesWidget({
		apiKey: autoSearchKey,
		onPlaceSelected: handlePlaceSelected,
		options: {
			types: ["address"],
			componentRestrictions: { country: "ca" },
			location: { lat: 49.0869607, lng: -122.4077515 },
			limit: 7,
			googleLogo: false
		},
	});

	const handleInputFocusAddress1 = () => {
		ref1.current.style.color = 'black';
	}

	const handleInputBlurAddress1 = () => {
		ref1.current.style.color = '#757575';
	}

	const handleInputDobFocus = () => {
		endDatePickerRef.current.style.color = 'black';
	}

	const handleInputDobBlur = () => {
		endDatePickerRef.current.style.color = '#757575';
	}

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content border border-gray-200 bg-white p-6 rounded-md">
								<div className="container mt-0 sm:mt-5 md:mt-5 lg:mt-0">
									{/* <h2 className="rubik-500 fs-30">Settings</h2> */}
									<p className="nunito-500 fs-18 lightBlue-color">Update your profile photo and personal details here</p>
								</div>
								<div className="relative mt-4">
									<input type="file" accept="image/*" onChange={onFileInputChange} ref={fileInputRef} id="fileInput" className="hidden" />
									<UpdatingCoverPicture />

									<div className="relative sm:relative md:absolute lg:absolute bottom-0 left-0 sm:left-0 md:left-14 lg:left-14 -mt-10 sm:-mb-24 md:-mb-16 lg:-mb-16 text-center sm:text-center md:text-left lg:text-left">
										<div className="grid sm:grid md:flex lg:flex items-end gap-5 justify-center">
											<div className="uploadImage rounded-lg">
												<div className="ot_takePictureMain">
													{profilePicLoader ?
														<div style={{ position: 'absolute', top: '54px', left: '57px' }}>
															<ProfilePicLoader />
														</div> : null}

													<img id="profileImage" ref={imageRef} className="rounded-lg mx-auto sm:mx-auto md:mx-0 lg:mx-0 w-8 h-8" src={profile?.thumbnail} alt="" />

													<div className="ot_takePictureBg rounded-lg rounded-t-none cursor-pointer" onClick={changeImage}>
														<svg className="mx-auto" width="30" height="15" viewBox="0 0 52 37" fill="none" xmlns="http://www.w3.org/2000/svg">
															<g clipPath="url(#clip0_107_17-025926)">
																<path d="M42.9951 1.60986H45.3376C46.0276 1.60986 46.5868 2.16811 46.5868 2.85662V6.85462H41.7457V2.8548C41.7468 2.16702 42.3058 1.60986 42.9951 1.60986Z" fill="#575B6D"></path>
																<path d="M33.2172 1.16614e-07H18.7827C18.0899 -0.000121507 17.4536 0.38129 17.1283 0.991717L14.002 6.85481H37.9981L34.8711 0.989162C34.545 0.380073 33.9091 -0.00024313 33.2172 1.16614e-07Z" fill="#DBD8DD"></path>
																<path d="M3.12315 5.05176H48.8769C50.6017 5.05176 52 6.44726 52 8.16872V33.8831C52 35.6045 50.6017 37 48.8769 37H3.12315C1.39827 37.0001 0 35.6046 0 33.8832V8.16872C0 6.44726 1.39827 5.05176 3.12315 5.05176Z" fill="#DBD8DD"></path>
																<path d="M0 12.5586H52V29.4942H0V12.5586Z" fill="#575B6D"></path>
																<path d="M26 33.7405C33.0361 33.7405 38.74 28.0479 38.74 21.0258C38.74 14.0036 33.0361 8.31104 26 8.31104C18.9639 8.31104 13.26 14.0036 13.26 21.0258C13.26 28.0479 18.9639 33.7405 26 33.7405Z" fill="#686D82"></path>
																<path d="M35.6162 21.0266C35.6265 26.3396 31.3193 30.6549 25.9958 30.6651C20.6723 30.6755 16.3484 26.3768 16.3382 21.0638C16.3278 15.7508 20.635 11.4355 25.9585 11.4253C27.9171 11.4215 29.8303 12.0132 31.4435 13.1218C34.0522 14.9116 35.6123 17.8672 35.6162 21.0266Z" fill="#419BE9"></path>
																<path d="M33.1239 18.5392C33.1316 23.8335 28.8373 28.1317 23.5324 28.1394C21.5794 28.1422 19.6719 27.5507 18.0642 26.4439C15.0596 22.0775 16.1706 16.1071 20.5456 13.1084C23.8295 10.8577 28.1645 10.8623 31.4435 13.1204C32.5391 14.7158 33.1249 16.605 33.1239 18.5392Z" fill="#69B0EE"></path>
																<path d="M24.754 17.9224C25.8176 17.9224 26.6799 17.0618 26.6799 16.0003C26.6799 14.9387 25.8176 14.0781 24.754 14.0781C23.6903 14.0781 22.828 14.9387 22.828 16.0003C22.828 17.0618 23.6903 17.9224 24.754 17.9224Z" fill="#EEEFEE"></path>
																<path d="M21.5756 20.6399C22.0498 20.6399 22.4342 20.2563 22.4342 19.783C22.4342 19.3097 22.0498 18.926 21.5756 18.926C21.1014 18.926 20.7169 19.3097 20.7169 19.783C20.7169 20.2563 21.1014 20.6399 21.5756 20.6399Z" fill="#EEEFEE"></path>
																<path d="M6.65081 10.8488C7.55369 10.8488 8.28562 10.1184 8.28562 9.21727C8.28562 8.31618 7.55369 7.58569 6.65081 7.58569C5.74792 7.58569 5.01599 8.31618 5.01599 9.21727C5.01599 10.1184 5.74792 10.8488 6.65081 10.8488Z" fill="#EF5261"></path>
																<path d="M39.7305 22.014C39.7341 24.7032 38.8789 27.3235 37.2891 29.4948H35.5009C39.8216 24.6728 39.8216 17.3806 35.5009 12.5586C38.1961 14.965 39.7349 18.4047 39.7305 22.014Z" fill="#464956"></path>
																<path d="M37.2877 29.4948C33.1514 35.171 25.1877 36.4262 19.5 32.298C18.7646 31.7642 18.0885 31.1533 17.4833 30.4756C22.7041 35.1713 30.7506 34.754 35.4555 29.5435C35.4701 29.5272 35.4848 29.5109 35.4994 29.4946L37.2877 29.4948Z" fill="#BBBBCD"></path>
															</g>
															<defs>
																<clipPath id="clip0_107_17-025926">
																	<rect width="52" height="37" fill="white"></rect>
																</clipPath>
															</defs>
														</svg>
													</div>
												</div>
											</div>

											<div className="leading-6 pt-0">
												<h3 className="rubik-500 fs-22 mt-10" style={{width:'100%'}}>{profile?.name?.charAt(0)?.toUpperCase() + profile?.name?.slice(1)}</h3>
												{/* {profile?.department_name?.map((value, index) => {
													return (<p className="rubik-400 fs-18 lightBlue-color" key={index} style={{width:'100%'}}>
														{value}	</p>)
												})} */}
												<p className="rubik-400 fs-12 text-white pl-2 pr-2 text-white rounded-md bg-blue-900 text-center" style={{width:'100%'}}>{profile?.type?.charAt(0)?.toUpperCase() + profile?.type?.slice(1)}</p>
											</div>
										</div>
									</div>

								</div>

								<div className="relative overflow-x-auto mt-5 sm:mt-5 md:mt-32 lg:mt-24 shadow-lg mb-8 border border-gray-100 rounded-md">
									<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
										<tbody style={{ border: `2px solid ${borderColor}` }}>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">Name</h4>
												</td>
												<td className="pl-0 text-left ">
													<input type="text" style={{ color: '#757575' }} onFocus={() => handleInputFocus('name')}
														onBlur={() => handleInputBlur('name')} ref={inputRefs.name} value={formData.name} onChange={(e) => handleInputChange('name', e)} className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">Date of Birth</h4>
												</td>
												<td className="pl-0 text-left relative">
													<input name="end" type="text" readOnly
														style={{ color: '#757575' }} onFocus={handleInputDobFocus}
														onBlur={handleInputDobBlur}
														ref={endDatePickerRef} value={dob} className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0" placeholder="YYYY-MM-DD" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">Email Address</h4>
												</td>
												<td className="pl-0 text-left">
													<input type="text"
														disabled
														style={{ color: '#757575' }} onFocus={() => handleInputFocus('email')}
														onBlur={() => handleInputBlur('email')} ref={inputRefs.email}
														value={formData.email} onChange={(e) => handleInputChange('email', e)} className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">Phone Number</h4>
												</td>
												<td className="pl-0 text-left">
													<input type="number" value={formData.phoneNumber} onChange={(e) => handleInputChange('phoneNumber', e)}
														style={{ color: '#757575' }} onFocus={() => handleInputFocus('phone')}
														onBlur={() => handleInputBlur('phone')} ref={inputRefs.phone}
														onKeyPress={(e) => {
															if (isNaN(String.fromCharCode(e.charCode)) || e.target.value.length >= 10) {
																e.preventDefault();
															}
														}}
														className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">Street Name</h4>
												</td>
												<td className="pl-0 text-left">
													<input type="text" value={address1} onChange={(e) => setAddress1(e.target.value)}
														style={{ color: '#757575' }}
														onFocus={handleInputFocusAddress1}
														onBlur={handleInputBlurAddress1}
														ref={ref1}
														placeholder=''
														className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0 form-control" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">Apartment, suite, unit</h4>
												</td>
												<td className="pl-0 text-left">
													<input type="text" value={address2} onChange={(e) => setAddress2(e.target.value)}
														style={{ color: '#757575' }} onFocus={() => handleInputFocus('address_2')}
														onBlur={() => handleInputBlur('address_2')} ref={inputRefs.address_2}
														className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0 form-control" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">City</h4>
												</td>
												<td className="pl-0 text-left">
													<input type="text" value={city}
														style={{ color: '#757575' }} onFocus={() => handleInputFocus('city')}
														onBlur={() => handleInputBlur('city')} ref={inputRefs.city}
														onChange={(e) => setCity(e.target.value)}
														className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0 form-control" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">Province</h4>
												</td>
												<td className="pl-0 text-left">
													<input type="text" value={province} onChange={(e) => setProvince(e.target.value)}
														style={{ color: '#757575' }} onFocus={() => handleInputFocus('province')}
														onBlur={() => handleInputBlur('province')} ref={inputRefs.province}
														className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0 form-control" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">Zip Code</h4>
												</td>
												<td className="pl-0 text-left">
													<input type="text"
														style={{ color: '#757575' }} onFocus={() => handleInputFocus('zipcode')}
														onBlur={() => handleInputBlur('zipcode')} ref={inputRefs.zipcode}
														value={zip_code} onChange={(e) => setZipCode(e.target.value)}
														className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0 form-control" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>

											<tr className="bg-white border-b h-12 dark:bg-gray-800 dark:border-gray-700">
												<td className="whitespace-nowrap text-left ot_tblTitleWidth pl-4">
													<h4 className="rubik-500 fs-14 dark-color">SIN Number</h4>
												</td>
												<td className="pl-0 text-left">
													<input type="text" value={formData.sinNumber} onChange={(e) => handleInputChange('sinNumber', e)}
														style={{ color: '#757575' }} onFocus={() => handleInputFocus('sinNumber')}
														onBlur={() => handleInputBlur('sinNumber')} ref={inputRefs.sinNumber}
														onKeyPress={(e) => {
															if (isNaN(String.fromCharCode(e.charCode)) || e.target.value.length >= 9) {
																e.preventDefault();
															}
														}}
														className="pl-0 rubik-400 fs-16 dark-color box-shadowNone edit-input w-full border-0 p-0 outline-0" />
												</td>
												<td className="px-6 py-5 text-right">
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								{
									message !== '' ?
										<div className="mt-5 text-center">
											<Errors error={message} />
										</div> : null
								}

								{
									updateProfileMsg !== '' ?
										<div className="mt-5 text-center">
											<SuccessMessage message={updateProfileMsg} />
										</div>
										: null
								}

								<div className="">
									<button type="button" className="light-color nunito-700 fs-16 blueBg-color focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={handleSaveChanges}>
										{updateProfileResponse?.loading ? 'Processing...' : 'Save Changes'}
									</button>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div >

			<ChatBox />
			<Footer />
		</>
	)
}

export default Settings