import React, {
	useEffect,
	useState,
	useRef
} from 'react'

import {
	useCustomDispatch,
} from '../../../hooks'

import {
	CommentsListError,
	WallListErrors,
	AddWallResponseError
} from '../../../components/error/alertMessages'

import {
	CaptureCommentData,
	CatpureCommentReplyListData
} from './captureCommentData'

import {
	CommentListLoader,
	CommentReplyListLoading
} from '../../../components/loaders/commentListLoader'

import {
	initializeCommentLikes,
	initializePostLikes,
	toggleWallPostCommentLikes,
	toggleWallPostLikes
} from '../../../utils/helper/wallListHelper'

import {
	useCustomDataStoringStates,
	useCustomLoadingStates,
	useCustomNullStates
} from '../../../hooks/customStates'

import {
	FiveImageGallery,
	FourImageGallery,
	SingleImageGallery,
	SixImageGallery,
	ThreeImageGallery,
	TwoImageGallery
} from '../../../components/postMedia/main/postMuliplesImages'

import {
	fetchAllCommentReplyLists,
	fetchPreviousCommentsReplyData
} from '../../../services/actions/posts/postCommentReplyListActions'

import {
	fetchAllCommentLists,
	fetchPaginationComments
} from '../../../services/actions/posts/postCommentsListsActions'

import {
	fetchUserWallList,
	fetchUserWallLists
} from '../../../services/actions/posts/userWallListActions'

import { addPostComments } from '../../../services/actions/posts/addWallPostUserCommentsActions'
import { addPostCommentsReply } from '../../../services/actions/posts/addWallPostCommentReplyActions'

import { addWallPostLikes } from '../../../services/actions/posts/addWallPostLikesActions'
import { postStyle } from '../../../style'
import { deleteUserWallLists } from '../../../services/actions/posts/delteWallPostLists'

import { subscribeToAblyChannels } from '../../../utils/miscellaneous/ably'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

import { WallPostLoader } from '../../../components/loaders/wallPostLoaders'
import { CapturePostData } from '../../../components/postMedia/main/capturePostData'
import { ProfilePicLoader } from '../../../components/common/loadingView'
import { previousComentsReplyData } from '../../../storage'

import InfiniteScroll from 'react-infinite-scroll-component';
import Cookies from 'js-cookie'

const MyAllPosts = ({
	postWallContent,
	postWallImage,
	wallListLoading,
	postWallVideo, }) => {

	const dispatch = useCustomDispatch()
	const divRef = useRef(null);
	const inputRef = useRef(null);

	const viewMore = Cookies.get('more')
	const previousReplyComments = previousComentsReplyData()

	const initialPreviousCommentsReply = {
		pageNumber: 1,
		loading: false,
	};

	const [previousCommentsReplies, setPreviousCommentsReplies] = useState(initialPreviousCommentsReply)

	const {
		profileData,
		pharmacyId,
		totalPagination,
		commentList,
		allCommentList,
		addCommentResponse,
		commentReplyResponse,
		commentReplyList,
		addWallPostResponse, userWallListResponse, userWallLists } = useCustomSelectors();

	const {
		wallList,
		setWallList,
		likes,
		setLikes,
		totalLikes,
		setTotalLikes,
		showFullContent,
		setShowFullContent,
		commentLikes,
		setCommentLikes,
		totalCommentLikes,
		setTotalCommentLikes,
		commentReplyLoading,
		setCommentReplyLoading,
		appenedCommentsValue,
		setAppendedCommentsValue,
		appenedCommentReplyValue,
		setAppendedCommentReplyValue,
		commentsData, setCommentsData,
		commentsReplyData,
		setCommentsReplyData } = useCustomDataStoringStates();

	const {
		commentReplyLoader,
		setCommentReplyLoader,
		apiCallMade,
		setApiCallMade,
		deleteWallListLoading,
		setDeleteWallListLoading,
		contentLoader,
		setContentLoader,
		loading,
		setLoading,
		commentLoader,
		setCommentLoader,
		commentReplyListLoading,
		setCommentReplyListLoading,
		viewMoreCommentLoader,
		setViewMoreCommentLoader } = useCustomLoadingStates();

	const { post_id,
		setPost_id,
		openPostId,
		setOpenPostId,
		commentReplyId,
		setCommentReplyId,
		commentListLoader,
		setCcommentListLoader
	} = useCustomNullStates();

	const [page, setPage] = useState(1);
	const [pageNumber, setPageNumber] = useState(1)
	const [commentsValue, setCommentsValue] = useState('')
	const [commentsReplyValue, setCommentsReplyValue] = useState('')

	const toggleShowFullContent = (index) => {
		const newShowFullContent = [...showFullContent];
		newShowFullContent[index] = !newShowFullContent[index];
		setShowFullContent(newShowFullContent);
	}


	const handleSubmitComments = (id) => {
		if (commentsValue && id) {
			setLoading(true)
			Cookies.remove('more')
			setPage(1)
			Cookies.remove('previous')
			setPreviousCommentsReplies({ ...previousCommentsReplies, pageNumber: 1 });
			dispatch(addPostComments(id, commentsValue, setLoading))
			setAppendedCommentsValue([{ commentText: commentsValue }, ...appenedCommentsValue,])
			setCommentsValue('')
		}
	}

	const handleSubmitCommentsReply = (id) => {
		if (commentsReplyValue && id) {
			setCommentReplyLoader(true)
			Cookies.remove('previous')
			setPreviousCommentsReplies({ ...previousCommentsReplies, pageNumber: 1 });
			dispatch(addPostCommentsReply(id, commentsReplyValue))
			setAppendedCommentReplyValue([{ commentReplyText: commentsReplyValue }, ...appenedCommentReplyValue,])
			setCommentsReplyValue('')
		}
	}

	const toggleCommentsInput = (postId, totalComments) => {
		const commentLoader = Array.from({ length: totalComments }, (_, index) => index);
		setCommentsData([])
		Cookies.remove('more')
		setPage(1)

		setOpenPostId((prevPostId) => (prevPostId === postId ? null : postId));
		setCcommentListLoader(commentLoader)
		setCommentReplyId(null)
		if (totalComments !== 0 && postId !== openPostId) {
			setCommentLoader(true)
			dispatch(fetchAllCommentLists(null, null, postId, setCommentLoader))
		}
	}

	useEffect(() => {
		initializePostLikes(wallList, setLikes, setTotalLikes);
	}, [wallList]);

	useEffect(() => {

		initializeCommentLikes(commentsData, setCommentLikes, setTotalCommentLikes);
	}, [commentsData]);

	const toggleLike = (index, id) => {
		toggleWallPostLikes(index, id, likes, totalLikes, setLikes, setTotalLikes, dispatch, addWallPostLikes);
	};

	const toggleCommentLikes = (index, id) => {
		toggleWallPostCommentLikes(index, id, commentLikes, totalCommentLikes, setCommentLikes, setTotalCommentLikes, dispatch)
	}

	const toggleCommentsReply = (commentId, totalReplyComments) => {
		Cookies.remove('previous')
		setPreviousCommentsReplies({ ...previousCommentsReplies, pageNumber: 1 });
		const commentReplyLoader = Array.from({ length: 3 }, (_, index) => index);
		setCommentReplyId((prevPostId) => (prevPostId === commentId ? null : commentId));
		setCommentReplyLoading(commentReplyLoader)

		if (totalReplyComments !== 0 && commentId !== commentReplyId) {
			setCommentReplyListLoading(true)
			dispatch(fetchAllCommentReplyLists(null, null, commentId, setCommentReplyListLoading))
		}

	}

	const handleEnterKeyPress = (e, post_id) => {
		if (e.key === 'Enter') {
			handleSubmitComments(post_id);

			if (divRef.current) {
				divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
			}
		}
	}

	useEffect(() => {
		setWallList(userWallLists)
	}, [userWallListResponse])


	const fetchApiData = async () => {
		fetchUserWallList(pageNumber, totalPagination, wallList, setWallList, setPageNumber);
	};


	const confirmDeletion = (post_id) => {
		if (post_id) {
			setPost_id(post_id)
			setDeleteWallListLoading(true)
			deleteUserWallLists(post_id, setDeleteWallListLoading, dispatch)
		}
	}

	useEffect(() => {
		if (!apiCallMade) {
			setContentLoader(true)
			dispatch(fetchUserWallLists(setContentLoader));
			setApiCallMade(true)
		}
	}, [])


	useEffect(() => {
		const ablyConnection = subscribeToAblyChannels(pharmacyId,
			dispatch,
			setLoading,
			setAppendedCommentsValue,
			setCommentReplyLoader,
			setAppendedCommentReplyValue,
			openPostId,
			commentReplyId);

		return () => {
			ablyConnection.close();
		};
	}, [openPostId, commentReplyId]);


	useEffect(() => {
		if (allCommentList) {
			setCommentsData(allCommentList?.data)
		}
	}, [allCommentList, commentList])

	const handleViewMoreClick = async (postID) => {
		const nextPage = page + 1;
		if (nextPage <= allCommentList?.pagination?.last_page) {
			setViewMoreCommentLoader(true);
			await fetchPaginationComments(postID, nextPage, setCommentsData, commentsData, setViewMoreCommentLoader);
			setPage(nextPage)
		}
	};

	useEffect(() => {
		if (commentReplyList) {
			setCommentsReplyData(commentReplyList?.data)
		}
	}, [commentReplyList])

	const handleViewPreviousCommentsReply = async (commentReplyId) => {
		const nextPage = previousCommentsReplies?.pageNumber + 1
		if (nextPage <= commentReplyList?.pagination?.last_page) {
			setPreviousCommentsReplies({ ...previousCommentsReplies, loading: true });
			await fetchPreviousCommentsReplyData(commentReplyId, nextPage, setCommentsReplyData, commentsReplyData, setPreviousCommentsReplies, previousCommentsReplies)
			setPreviousCommentsReplies({ ...previousCommentsReplies, pageNumber: nextPage });
		}
	}


	return (
		<>

			{post_id !== null ? null : <>
				{(addWallPostResponse?.loading || wallListLoading) ? null : contentLoader ?
					<WallPostLoader />
					: null}</>}

			{addWallPostResponse?.error !== null ?
				<AddWallResponseError /> : <>

					{
						(addWallPostResponse?.loading || wallListLoading) &&

						<CapturePostData
							postWallContent={postWallContent}
							postWallImage={postWallImage}
							postWallVideo={postWallVideo}
						/>
					}
				</>}

			{
				wallList?.length > 10 &&
				<InfiniteScroll
					dataLength={wallList?.length}
					next={fetchApiData}
					hasMore={pageNumber < totalPagination}
				>
				</InfiniteScroll>
			}


			<>
				{(addWallPostResponse?.loading || wallListLoading) ? null :
					wallList?.length === 0 &&
					<div className="mt-2 mb-4 w-full border rounded-lg bg-white shadow">
						<div className="flex gap-3 p-4">
							<div style={{ textAlign: 'center' }}>
								<h3 className="rubik-500 text-2xl dark-color">
									Be the First to Share Your Thoughts
								</h3>
								<p className="rubik-400 text-lg grey-color">
									Join the conversation and be the first to post on this page. Share your ideas, experiences, or stories with the community.
								</p>
							</div>
						</div>
					</div>
				}

				{(addWallPostResponse?.loading || wallListLoading) ? null :
					userWallListResponse?.error !== null ?
						<WallListErrors />
						:
						<>
							{/* -----------------------------Wall List Section Start--------------------------- */}

							{
								wallList?.map((value, index) => {
									const isCommentsOpen = openPostId === value.post_id;
									return (
										<React.Fragment key={index}>

											<div
												className="mt-2 mb-4 w-full border rounded-lg bg-white shadow">
												<div className="flex gap-3 p-4">
													<img className="w-10 h-10 rounded-full border" src={profileData.thumbnail} alt="Rounded avatar" />

													<div>
														<h3 className="rubik-500 fs-18 dark-color">{profileData.name}</h3>
														<p className="items-center flex gap-2 rubik-400 fs-14 grey-color">{value.time_format}</p>
													</div>

													{
														profileData?.user_id && <>

															<button onClick={() => confirmDeletion(value.post_id)} className="ml-auto leading-nonenline-flex p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-full hover:bg-gray-100 dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 self-start"
																type="button">
																{deleteWallListLoading ? <>
																	{post_id === value.post_id ?
																		<ProfilePicLoader />
																		:
																		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																			<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
																		</svg>

																	}
																</>
																	:
																	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																		<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
																	</svg>
																}
															</button>
														</>
													}

												</div>

												<div className="flex items-end p-4 pt-0">
													<p className="rubik-400 fs-18 dark-color" dangerouslySetInnerHTML={{
														__html:
															showFullContent[index] ? value?.post : value?.post?.split(' ')?.slice(0, 30)?.join(' ')
													}} style={postStyle}>
													</p>

													{value?.post?.split(' ')?.length > 30 && (
														<span className="w-8/12 text-right rubik-400 fs-18 grey-color btnnn">
															<a onClick={() => toggleShowFullContent(index)}>
																{showFullContent[index] ? '...see less' : '...see more'}
															</a>
														</span>
													)}
												</div>

												{
													value?.wall_video &&
													<div className="p-4 pt-0 pb-2">
														<video controls className='w-full rounded-sm' style={{ height: '474px' }} disablePictureInPicture controlsList="nodownload noplaybackrate" onContextMenu={(e) => e.preventDefault()}>
															<source src={value.wall_video} type="video/mp4" />
														</video>
													</div>
												}

												{
													value?.wall_images?.length >= 1 &&
													<div className={`px-4 pb-8`}>
														<div className="max-w-screen-2xl mx-auto relative">
															<div className="flex flex-col md:flex-row gap-2">
																{
																	value?.wall_images?.length >= 6 && value?.wall_images?.length <= 50 ? <>
																		<SixImageGallery value={value} />
																	</>

																		: value?.wall_images.length === 5 ?
																			<>
																				<FiveImageGallery value={value} />
																			</>

																			: value?.wall_images.length === 4 ?
																				<>
																					<FourImageGallery value={value} />
																				</>

																				: value?.wall_images.length === 3 ?
																					<>
																						<ThreeImageGallery value={value} />
																					</>

																					: value?.wall_images.length === 2 ?
																						<>
																							<TwoImageGallery value={value} />
																						</>

																						: value?.wall_images?.length === 1 ?
																							<>
																								<SingleImageGallery value={value} />
																							</>
																							: null
																}
															</div>
														</div>
													</div>
												}

												<hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />

												<div className="p-4 flex items-center gap-6" >
													<div className="">
														<a onClick={() => toggleLike(index, value?.post_id)} className='btnnn'>
															{likes[index] === 1
																?
																<p className="flex items-center gap-2 fs-16 rubik-400 grey-color">
																	<svg width="20" height="21" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<g clipPath="url(#clip0_16_4-043522)">
																			<path d="M0.811268 8.21562H4.95211C5.44225 7.88006 5.83099 7.30963 6.10141 6.62175C6.49014 5.68221 6.65915 4.54134 6.64225 3.48435C6.60845 2.76292 6.62535 2.07504 6.89577 1.50461C7.2507 0.783172 7.87606 0.380511 9.05915 0.531509C10.1408 0.665729 10.9014 1.38716 11.3915 2.36026C11.7634 3.13203 11.9662 4.08834 12 4.97755H14.4507C15.4986 4.97755 16.3775 5.44732 17.0197 6.20231C17.6451 6.92375 18 7.9304 18 9.03771V11.6718C18 13.5173 17.5099 15.2118 16.5803 16.4366C15.6507 17.6278 14.3493 18.366 12.6761 18.366H5.17183H0.811268C0.371831 18.366 0 17.9969 0 17.5607V9.03771C0 8.58472 0.371831 8.21562 0.811268 8.21562ZM4.36056 9.84303H1.63944V16.7386H4.36056V9.84303Z" fill="#3A52BB" />
																		</g>
																		<defs>
																			<clipPath id="clip0_16_4-043522">
																				<rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
																			</clipPath>
																		</defs>
																	</svg>Like {totalLikes[index]}
																</p>
																:
																<p className="flex items-center gap-2 fs-16 rubik-400 grey-color">
																	<svg width="20" height="21" viewBox="0 0 20 21" fill="none"
																		xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M0.901408 9.07291H5.50235C6.04695 8.70007 6.47887 8.06625 6.77934 7.30194C7.21127 6.25801 7.39906 4.99037 7.38028 3.81595C7.34272 3.01436 7.3615 2.25005 7.66197 1.61623C8.05634 0.814635 8.75117 0.367235 10.0657 0.53501C11.2676 0.684143 12.1127 1.48574 12.6573 2.56695C13.0704 3.42447 13.2958 4.48705 13.3333 5.47506H16.0563C17.2207 5.47506 18.1972 5.99703 18.9108 6.8359C19.6056 7.6375 20 8.756 20 9.98635V12.9131C20 14.9637 19.4554 16.8465 18.4225 18.2073C17.3897 19.5309 15.9437 20.3511 14.0845 20.3511H5.74648H0.901408C0.413146 20.3511 0 19.941 0 19.4563V9.98635C0 9.48302 0.413146 9.07291 0.901408 9.07291ZM11.0235 3.36855C10.7418 2.79065 10.3474 2.38054 9.8216 2.32461C9.48357 2.26869 9.33333 2.30597 9.29577 2.39918C9.16432 2.66016 9.16432 3.20077 9.1831 3.76002C9.22066 5.17679 8.99531 6.70541 8.46948 7.99169C8.05634 8.99834 7.4554 9.8745 6.64789 10.471V18.5429H14.0845C15.3239 18.5429 16.3005 18.0023 16.9765 17.1261C17.7653 16.0822 18.1784 14.5908 18.1784 12.9131V9.98635C18.1784 9.18476 17.9343 8.47637 17.5211 7.99169C17.1455 7.56293 16.6385 7.2833 16.0563 7.2833H12.4131H12.338C11.831 7.24602 11.4742 6.79862 11.5117 6.31393C11.5869 5.34457 11.4366 4.20742 11.0235 3.36855ZM4.84507 10.8811H1.8216V18.5429H4.84507V10.8811Z"
																			fill='#757575' />
																	</svg>
																	Like {totalLikes[index] === 0 ? null : totalLikes[index]}
																</p>
															}
														</a>
													</div>

													<div>
														<a className='btnnn' onClick={() => { return toggleCommentsInput(value.post_id, value.total_comments) }} >
															<p className="flex items-center gap-2 fs-16 rubik-400 grey-color">
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
																	xmlns="http://www.w3.org/2000/svg">
																	<path fillRule="evenodd" clipRule="evenodd"
																		d="M7.0009 0.5H12.9927C21.1288 0.5 22.2641 12.6727 15.957 15.6371L11.6682 19.1691C11.2267 19.5475 10.9744 19.2952 10.9744 18.9168V16.2678H7.0009C-2.33363 16.2678 -2.33363 0.5 7.0009 0.5ZM6.5594 7.5009C7.0009 7.5009 7.4424 7.87932 7.4424 8.38389C7.4424 8.82539 7.0009 9.26689 6.5594 9.26689C6.05483 9.26689 5.6764 8.82539 5.6764 8.38389C5.6764 7.87932 6.05483 7.5009 6.5594 7.5009ZM13.4342 7.5009C13.9387 7.5009 14.3172 7.87932 14.3172 8.38389C14.3172 8.82539 13.9387 9.26689 13.4342 9.26689C12.9927 9.26689 12.6142 8.82539 12.6142 8.38389C12.6142 7.87932 12.9927 7.5009 13.4342 7.5009ZM10.0283 7.5009C10.4698 7.5009 10.8482 7.87932 10.8482 8.38389C10.8482 8.82539 10.4698 9.26689 10.0283 9.26689C9.52374 9.26689 9.14532 8.82539 9.14532 8.38389C9.14532 7.87932 9.52374 7.5009 10.0283 7.5009ZM12.3619 16.8354L15.2001 14.4387C20.5612 11.9159 19.489 1.88757 12.9927 1.88757H7.0009C-0.441501 1.88757 -0.441501 14.8802 7.0009 14.8802H12.3619V16.8354Z"
																		fill="#757575" />
																</svg>Comment{value.total_comments === 0 ? null :
																	<span className="ml-auto rubik-400 fs-16 grey-color">{value.total_comments}</span>}
															</p>
														</a>
													</div>
												</div>


												{
													isCommentsOpen && (<>

														{
															addCommentResponse?.isLoading || loading ? (<>
																<CaptureCommentData commentsValue={appenedCommentsValue} />
															</>
															) :
																null
														}

														{/* -----------------------------Add a Comment Section Start--------------------------- */}

														{
															commentLoader ? <>
																<CommentListLoader commentListLoader={commentListLoader} />
															</>
																:
																commentList?.error !== null ?
																	<CommentsListError />
																	:
																	<>
																		{
																			commentsData?.map((list, index) => {
																				const isCommentsReplyOpen = commentReplyId === list?.comment_id;
																				return (
																					<React.Fragment key={index}>
																						{
																							list?.post_id === value?.post_id && <>
																								<div className="p-4 pb-1 pt-0 flex items-center gap-6" ref={divRef}>
																									<div className="block w-full p-2 bg-light border border-gray-200 rounded  dark:bg-gray-800 dark:border-gray-700">
																										<div className="flex gap-3">
																											<div className="flex gap-3">
																												<img className="w-8 h-8 rounded-full" src={list.photo}
																													alt="Rounded avatar" />
																												<div>
																													<h3 className="rubik-500 fs-14 dark-color">{list.name}</h3>
																													<p className="rubik-400 fs-14  dark-color">{list.comment}</p>
																												</div>
																											</div>
																											<div className="ml-auto flex items-top gap-4">
																												<span className="leading-4 fs-14 rubik-400 grey-color"><span className="pr-2">

																													<a className="fs-14 rubik-400 grey-color btnnn" onClick={() => toggleCommentsReply(list.comment_id, list.total_replies)}>
																														{list?.post_id === value?.post_id && <>
																															{
																																list.total_replies === 0 ? null :
																																	<span className="rubik-400 grey-color btnnn" style={{ fontSize: '13px', textDecoration: 'underline' }}>
																																		See all replies({list.total_replies})
																																	</span>
																															}
																														</>
																														}
																													</a>

																													{totalCommentLikes[index] === 0 ? null :

																														totalCommentLikes[index] <= 1 ? <>&nbsp;&nbsp;{totalCommentLikes[index]} Like
																														</>
																															:

																															<>
																																{
																																	commentLikes[index] === 1 ?
																																		(
																																			<>
																																				&nbsp;&nbsp;{totalCommentLikes[index]} Likes
																																			</>
																																		) : (
																																			<>
																																				&nbsp;&nbsp;{totalCommentLikes[index] === 0 ? null : totalCommentLikes[index]} Likes
																																			</>
																																		)
																																}</>}
																												</span>{list.time_format} </span>

																											</div>
																										</div>
																									</div>
																								</div>

																								<div className="px-4 py-1 pt-0 pt-0">
																									<div className="flex items-center gap-2">
																										{
																											commentReplyListLoading ? null :
																												list?.total_replies > 10 && <>
																													{commentReplyId === list?.comment_id && <>
																														{previousReplyComments == 0 ? null :

																															<div className="pl-11">
																																<a style={{ color: '#00000099' }}
																																	onClick={() => handleViewPreviousCommentsReply(list?.comment_id)} className="btnnn relative block z-10 fs-12 rubik-500 underline blue-color mr-auto flex">
																																	Load previous replies&nbsp;&nbsp;
																																	{previousCommentsReplies?.loading && <ProfilePicLoader />}
																																</a>
																															</div>}
																													</>
																													}
																												</>
																										}

																										<div className="ml-auto flex items-top gap-2">
																											<a onClick={() => toggleCommentLikes(index, list?.comment_id)} className='btnnn'>
																												<p className="fs-14 rubik-400 grey-color">Like</p>
																											</a>
																											<a className="fs-14 rubik-400 grey-color btnnn"
																												onClick={() => toggleCommentsReply(list.comment_id, list.total_replies)} >Reply
																											</a>
																										</div>
																									</div>
																								</div>


																								{/* -----------------------------Comment Reply Section Start--------------------------- */}

																								{commentReplyListLoading && isCommentsReplyOpen ? <>
																									<CommentReplyListLoading commentReplyLoading={commentReplyLoading} />
																								</> :

																									<div className="px-4  pt-0">
																										{
																											commentsReplyData?.map((replyList, index) => {
																												return (
																													<React.Fragment key={index}>
																														{commentReplyId === list?.comment_id && <>
																															{list?.comment_id === replyList?.comment_id && <>
																																<div className="ot_allPostReply">
																																	<div className="borderBefore">
																																	</div>
																																	<div className="bottomBorder">
																																	</div>
																																	<div style={{ position: 'relative', bottom: '-70px' }}>
																																		<div className="block w-full p-1 mb-1 bg-light border border-gray-200 rounded  dark:bg-gray-800 dark:border-gray-700">
																																			<div className="flex gap-3">
																																				<div className="flex gap-3">
																																					<img className="w-6 h-6 rounded-full" src={replyList.photo}
																																						alt="Rounded avatar" />
																																					<div>
																																						<h3 className="rubik-500 fs-14 dark-color">{replyList.name}</h3>
																																						<p className="rubik-400 fs-14 dark-color">{replyList.reply}</p>
																																					</div>
																																				</div>
																																				<div className="ml-auto flex items-top gap-4">
																																					<span className="leading-4 fs-14 rubik-400 grey-color">
																																						{replyList.time_format}
																																					</span>

																																				</div>
																																			</div>
																																		</div>


																																	</div>
																																</div>
																															</>}
																														</>}

																													</React.Fragment>)
																											})}
																									</div>
																								}

																								{/* ---------------------Comment Reply Loading States Section Start------------------- */}

																								{
																									(list?.comment_id === commentReplyId) && <>
																										{(commentReplyResponse?.loading || commentReplyLoader) &&
																											<>
																												<CatpureCommentReplyListData commentsReplyValue={appenedCommentReplyValue} />
																											</>
																										}
																									</>
																								}

																								{/* -------------------------Comment Reply Loading States Section End --------------- */}


																								{/* -----------------------------Open Box to add a Reply Comment--------------------------- */}

																								{
																									isCommentsReplyOpen &&

																									<div className="p-4 pt-0 flex items-center mt-2 gap-6 lg:ml-10 md:ml-0" >
																										<div className="block w-full">
																											<div className="flex gap-3 relative ot_textBoxBg">
																												<img className="w-8 h-8 rounded-full" src={profileData.photo} alt="Rounded avatar" />
																												<input type="text" id="emails" onChange={(e) => setCommentsReplyValue(e.target.value)}
																													onKeyPress={(e) => {
																														if (e.key === 'Enter') {
																															handleSubmitCommentsReply(list.comment_id);
																														}
																													}}
																													ref={inputRef} value={commentsReplyValue} style={{ height: '33px' }} className="pr-10 grey-color fs-16 rubik-400 rounded-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={`Reply to ${list.name}`} required="" />

																												{commentsReplyValue ?
																													<a className="absolute right-4 top-3 btnnn" onClick={() => handleSubmitCommentsReply(list.comment_id)}>
																														<svg className="h-6 w-8 text-gray-600" viewBox="0 0 28 28" style={{ position: 'relative', bottom: '5px' }} fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <line x1="22" y1="2" x2="11" y2="13" />  <polygon points="22 2 15 22 11 13 2 9 22 2" /></svg>
																													</a> :
																													<a className="absolute right-4 top-3 btnnn">
																														<svg className="h-6 w-8 text-gray-300" viewBox="0 0 28 28" style={{ position: 'relative', bottom: '5px' }} fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <line x1="22" y1="2" x2="11" y2="13" />  <polygon points="22 2 15 22 11 13 2 9 22 2" /></svg>
																													</a>
																												}
																											</div>
																										</div>
																									</div>
																								}

																								{/* -----------------------------Open Box to add a Reply Comment--------------------------- */}

																							</>}
																					</React.Fragment>)
																			})}

																		{
																			value?.total_comments === 0 ? null :
																				allCommentList?.data?.length !== 0 ? (
																					allCommentList?.pagination?.from < allCommentList?.pagination?.last_page && (
																						viewMore == 0 ? null : (
																							<a className="btnnn fs-14 rubik-500 underline blue-color mr-auto ml-6 flex" onClick={() => handleViewMoreClick(value?.post_id)}>
																								View More Comments&nbsp;
																								{viewMoreCommentLoader && <ProfilePicLoader />}
																							</a>
																						)
																					)
																				) : null
																		}

																	</>}

														{/* -----------------------------Comment Reply Section End--------------------------- */}

														< div className="p-4 pt-0 flex items-center gap-6 lg:ml-0 md:ml-0" >
															<div className="block w-full">
																<div className="flex gap-3 relative ot_textBoxBg">
																	<img className="w-8 h-8 rounded-full mt-1" src={profileData.photo} alt="Rounded avatar" />

																	<input type="text" id="emails" onChange={(e) => { return setCommentsValue(e.target.value) }} onKeyPress={(e) => handleEnterKeyPress(e, value.post_id)} value={commentsValue}
																		className="pr-10 grey-color fs-16 rubik-400 rounded-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
																		placeholder="Write a comment..." required="" />

																	{
																		commentsValue ?
																			<a className="absolute right-4 top-3 btnnn" onClick={() => handleSubmitComments(value.post_id)}>
																				<svg className="h-6 w-8 text-gray-600" viewBox="0 0 26 26" style={{ position: 'relative', bottom: '3px' }} fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <line x1="22" y1="2" x2="11" y2="13" />  <polygon points="22 2 15 22 11 13 2 9 22 2" /></svg>
																			</a> :
																			<a className="absolute right-4 top-3 btnnn">
																				<svg className="h-6 w-8 text-gray-300" viewBox="0 0 26 26" style={{ position: 'relative', bottom: '3px' }} fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <line x1="22" y1="2" x2="11" y2="13" />  <polygon points="22 2 15 22 11 13 2 9 22 2" /></svg>
																			</a>
																	}

																</div>
															</div>
														</div>

														{/* -----------------------------Add a Comment Section End--------------------------- */}

													</>)
												}
											</div>
										</React.Fragment>
									)
								})}

							{/* -----------------------------Wall List Section End--------------------------- */}

						</>

				}
			</>


		</>
	)
}

export default MyAllPosts;