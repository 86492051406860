import React from 'react'

import ALLGeneratedFDAformList from './allGeneratedFDAForms'

const ExpiredFDAList = () => {

    return (
        <>
            <ALLGeneratedFDAformList />
        </>
    )
}

export default ExpiredFDAList