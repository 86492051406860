import React from 'react';
import TextPanel from './TextPanel/TextPanel';
import ImagePanel from './Images/ImagePanel';
import classNames from 'classnames';
import { useTabContext } from '../hooks/TabContext';
import TemplatesPanel from './Templates/TemplatesPanel';
import PharmacyPanel from './Pharmacy/PharmacyPanel';

function PanelItem({ panelOpen }) {
    const { activeTab } = useTabContext();
    const className = classNames({
        'panel-item-container': true,
        open: panelOpen,
    });

    return (
        <div className={className}>
            <div className="panel-item" style={{ minWidth: '250px', background: '#3a52bb', height: '100%', overflow: "auto" }}>
                <div>
                    {(activeTab === 'templates' ) && <TemplatesPanel type='templates' />}
                    {(activeTab === 'text' ) && <TextPanel  type='text'/>}
                    {activeTab === 'images' && <ImagePanel />}
                    {activeTab === 'employee' && <TextPanel type='employee'/>}
                    {activeTab === 'date' && <TextPanel type='date'/>}
                    {activeTab === 'pharmacy' && <TextPanel type='pharmacy'/>}
                </div>
            </div>
        </div>
    );

}

export default PanelItem;
