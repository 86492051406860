import {
	userProfile,
	auth,
	pharmacyTimeOffLists,
	chatUserLists,
	pharmacyWallPostsList,
	userCommentReplyList,
	userWallList,
	pharmacyTeamNotesMessage,
	recentChatLists,
	authPharmacyPermissions
} from '../../constants/rootReduceName';

import { useSelector } from 'react-redux';

export const useCustomSelectors = () => {

	const authData = useSelector(state => state[auth])
	const pharmacyId = authData?.data?.pharmacy?.[0]?.id
	// const pharmacyLoginUser = authData?.data?.pharmacyloginuser?.[0]?.role
	const pharmacyLoginData = authData?.data?.pharmacy?.[0]
	const adminModule = authData?.data?.pharmacyloginuser?.[0]?.admin_module

	const pharmacyTabsPermssion = useSelector(state => state[authPharmacyPermissions])
	const pharmacyLoginUser = pharmacyTabsPermssion?.data?.permissions

	const userProfileResponse = useSelector(state => state[userProfile])
	const profileData = userProfileResponse?.profileData?.data

	const getTotalHours = authData.data?.pharmacy?.[0]?.total_hours

	const pharmacyWallPostList = useSelector(state => state[pharmacyWallPostsList]);
	const wallPostLists = pharmacyWallPostList?.pharmacyWallPosts?.data?.data;
	const totalPagination = pharmacyWallPostList?.pharmacyWallPosts?.data?.pagination?.last_page;

	const commentList = useSelector(state => state?.allPostCommentLists);
	const allCommentList = commentList?.commentList?.data;

	const addCommentResponse = useSelector(state => state?.wallPostUserComment);
	const commentReplyResponse = useSelector(state => state?.userCommentReply);

	const commentReplyList = useSelector(state => state[userCommentReplyList]?.commentReplyList?.data);
	const addWallPostResponse = useSelector(state => state.addWallPosts);


	const allEmployeeData = useSelector(state => state?.allEmployeeData)
	const employeeData = allEmployeeData?.data?.data

	const addChatMessageResponse = useSelector(state => state.addChatMessages)

	const chatList = useSelector(state => state.chatMessageLists)
	const opponentChatMessages = useSelector(state => state.getChatMessages)
	const addMessagesToGroupResponse = useSelector(state => state.addChatMessages)


	const pharmacyIntiatedChatResponse = useSelector(state => state?.initiatedChatGroup)
	const initatedChatMessagesResponse = pharmacyIntiatedChatResponse?.data?.data?.messages

	const employeeAppreciationsResponse = useSelector(state => state.employeeAppreciations)

	const chatUserList = useSelector(state => state[chatUserLists])
	const userActivitiyStatus = chatUserList?.data?.data?.find((value) => value?.user_id === profileData?.user_id)

	const updateProfileResponse = useSelector(state => state.updateProfile)
	const timeOffRequestResponse = useSelector(state => state.timeOffReqest)

	const pharmacyTimeOffList = useSelector(state => state[pharmacyTimeOffLists])

	const recentChatList = useSelector(state => state[recentChatLists])
	const userWallListResponse = useSelector(state => state[userWallList])
	const userWallLists = userWallListResponse?.data?.data?.data

	const addClinicResponse = useSelector(state => state.addClinic)

	const teamNotesMessageResponse = useSelector(state => state[pharmacyTeamNotesMessage])

	const patientListNotificationsResponse = useSelector(state => state.pharmacyPatientNotifications)
	const patientNotificationsList = patientListNotificationsResponse?.data?.data

	const pharmacyPatientAddNoteReply = useSelector(state => state.pharmacyPatientAddNoteReply)
	const patientNoteMarkAsRead = useSelector(state => state.pharmacyPatientNoteMarkAsRead)

	const pharmacyAllTableListResponse = useSelector(state => state.pharmacyAllTableList)
	const pharmacyAddDataResponse = useSelector(state => state.pharmacyAddData)

	return {
		pharmacyWallPostList,
		wallPostLists,
		totalPagination,
		commentList,
		allCommentList,
		addCommentResponse,
		commentReplyResponse,
		commentReplyList,
		addWallPostResponse,
		profileData, allEmployeeData,
		employeeData,
		chatUserList,
		addChatMessageResponse,
		chatList,
		pharmacyIntiatedChatResponse,
		initatedChatMessagesResponse,
		opponentChatMessages,
		addMessagesToGroupResponse,
		authData,
		pharmacyId,
		employeeAppreciationsResponse,
		userActivitiyStatus,
		userProfileResponse,
		updateProfileResponse,
		getTotalHours,
		timeOffRequestResponse,
		pharmacyTimeOffList,
		recentChatList,
		userWallListResponse,
		userWallLists,
		pharmacyLoginUser,
		pharmacyLoginData,
		addClinicResponse,
		teamNotesMessageResponse,
		patientListNotificationsResponse,
		patientNotificationsList,
		pharmacyPatientAddNoteReply,
		patientNoteMarkAsRead,
		pharmacyAllTableListResponse,
		pharmacyAddDataResponse,
		adminModule
	};
};

