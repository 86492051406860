import React, { useRef, useEffect, useCallback } from 'react';
import { fabric } from 'fabric';
import { useCanvasContext } from '../../hooks/useCanvasContext';
import { AddImageToCanvas } from '../../utils/imageUtils';

const SignatureModal = ({ isOpen, onClose }) => {
    const canvasRef = useRef(null);
    const { canvas } = useCanvasContext();
    const localCanvasRef = useRef(null);


    useEffect(() => {
        if (isOpen) {
            const newCanvas = new fabric.Canvas(canvasRef.current, {
                backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
                isDrawingMode: true,
            });

            newCanvas.freeDrawingBrush.width = 2; // Set brush width call it as pencil/pen point width.
            localCanvasRef.current = newCanvas;

            // Cleanup function
            return () => {
                newCanvas.dispose();
            };
        }
    }, [isOpen]);

    // Save the signature as an image on the canvas
    const saveSignature = useCallback(() => {
        const dataURL = localCanvasRef.current.toDataURL({
            format: 'png',
            multiplier: 1, // we change it from 3 to 1 to get the signature in better quality at the time of drawing, its not overall image quality
            quality: 1,
        });

        AddImageToCanvas(canvas, dataURL, 70, 70)

        onClose(); // Close the signature modal after saving
    }, [canvas, onClose]);

    // Clear the canvas
    const clearCanvas = useCallback(() => {
        if (localCanvasRef.current) {
            localCanvasRef.current.clear();
        }
    }, []);

    return (
        <div style={{ zIndex: '999999999999' }} className={`modal ${isOpen ? 'block' : 'hidden'} fixed inset-0 overflow-y-auto`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-center justify-center min-h-screen">
                <div className="modal-overlay fixed inset-0 bg-black opacity-50"></div>

                <div style={{ zIndex: '999999999999' }} className="modal-content bg-white rounded-lg shadow-lg p-4 w-full max-w-md">
                    <div className="modal-header flex justify-between">
                        <h5 className="modal-title text-lg font-medium">Sign Here</h5>
                        <button type="button" className="close" onClick={onClose} style={{
                            display: 'flex', justifyContent: 'center', alignItems:
                                'center', marginTop: '10px'
                        }}>
                            <span style={{ marginTop: '-10px' }}>&times;</span>
                        </button>
                    </div>
                    <div style={{ zIndex: '999999999999', background: "#e2e2e2" }} className="modal-body mt-7">
                        <canvas ref={canvasRef} width={400} height={200}></canvas>
                    </div>
                    <div className="modal- mt-0 flex gap-4 justify-end p-0">
                        <button type="button" className="btn text-black btn-secondary mr-2" onClick={clearCanvas}>
                            Clear
                        </button>
                        <button type="button" className="btn text-blue btn-primary" style={{ color: "blue" }} onClick={saveSignature}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignatureModal;
