import React, { useState } from 'react';

import {
	chatUserListLoader,
} from '../../utils/helper/helper';

import { useCustomNavigate } from '../../hooks';
import { useCustomSelectors } from '../../services/selectors/allSelectors';

import Cookies from 'js-cookie';
import InitatedChatBox from './initatedChatBox';

import * as routNames from '../../routes/routeName';


export const ContactList = ({
	openChatbox,
	employeesOnlineStatus,
	selectedEmployeeId,
	setMyInputMessages,
	setChatAppenedMessages,
	setChatInput,
	setAllGroupMessages,
	allGroupMessages,
	chatInput,
	myInputMessages,
	chatAppenedMessages,
	openOverlay,
	closeOverlay,
	chatLoading,
	setSelectedEmployeeId
}) => {

	const { profileData, chatUserList } = useCustomSelectors()
	const [chatValue, setChatValue] = useState(null);

	return (
		<>
			<ul className="overflow-y-auto m-3">
				{
					chatLoading ? <>
						{
							chatUserListLoader?.map((item) => (
								<li key={item} className="py-2 border-b btnnn z-10">
									<div className="flex gap-3 items-start">
										<div className="animate-pulse flex gap-2 w-full">
											<svg className="w-12 h-12 text-gray-200 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
												<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
											</svg>
											<div>
												<div className="h-5 bg-gray-300 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
												<div className="w-48 h-3 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></div>
											</div>
										</div>
									</div>
								</li>
							))
						} </>
						:
						chatUserList?.data?.data?.length === 0 ?
							<div>
								<h4 className="rubik-500 fs-16 dark-color">
									No results found.
								</h4>
							</div>
							:
							chatUserList?.error !== null ?
								<div>
									<h4 className="rubik-500 fs-16 dark-color">
										Something Went wrong please try again later.
									</h4>
								</div>
								:
								chatUserList?.data?.data?.map((value, index) => {
									return (
										<React.Fragment key={index}>
											{profileData?.user_id === value?.user_id ? null :
												<>
													<li className="py-2 border-b btnnn" >
														<div className="flex gap-3 items-start" onClick={() => {
															setChatValue(value);
															openChatbox(value, 'contact_list')
														}}>
															<div className="relative">
																<img src={value.thumbnail} alt="" className='w-12 h-12 rounded-full' />
															</div>
															<div>
																<h4 className="rubik-500 fs-16 dark-color">{value.name}</h4>
															</div>
														</div>

													</li>
												</>}</React.Fragment>

									)
								})
				}
			</ul>
			<InitatedChatBox
				value={chatValue}
				selectedEmployeeId={selectedEmployeeId}
				profileData={profileData}
				employeesOnlineStatus={employeesOnlineStatus}
				setMyInputMessages={setMyInputMessages}
				setChatAppenedMessages={setChatAppenedMessages}
				setChatInput={setChatInput}
				setAllGroupMessages={setAllGroupMessages}
				allGroupMessages={allGroupMessages}
				chatInput={chatInput}
				myInputMessages={myInputMessages}
				chatAppenedMessages={chatAppenedMessages}
				openOverlay={openOverlay}
				closeOverlay={closeOverlay}
				setSelectedEmployeeId={setSelectedEmployeeId}
			/>
		</>
	)
}


export const ResponsiveContactList = ({
	chatLoading,
}) => {

	const { profileData, chatUserList } = useCustomSelectors()
	const navigate = useCustomNavigate()

	return (
		<>
			<ul className="overflow-y-auto m-3">
				{
					chatLoading ? <>
						{
							chatUserListLoader?.map((item) => (
								<li key={item} className="py-2 border-b btnnn z-10">
									<div className="flex gap-3 items-start">
										<div className="animate-pulse flex gap-2 w-full">
											<svg className="w-12 h-12 text-gray-200 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
												<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
											</svg>
											<div>
												<div className="h-5 bg-gray-300 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
												<div className="w-48 h-3 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></div>
											</div>
										</div>
									</div>
								</li>
							))
						} </>
						:
						chatUserList?.data?.data?.length === 0 ?
							<div>
								<h4 className="rubik-500 fs-16 dark-color">
									No results found.
								</h4>
							</div>
							:
							chatUserList?.error !== null ?
								<div>
									<h4 className="rubik-500 fs-16 dark-color">
										Something Went wrong please try again later.
									</h4>
								</div>
								:
								chatUserList?.data?.data?.map((value, index) => {
									return (
										<React.Fragment key={index}>
											{
												profileData?.user_id === value?.user_id ? null :
													<>
														<li className="py-2 border-b btnnn" >
															<div className="flex gap-3 items-start" onClick={() => { return navigate(`${routNames.CHAT_NOW}/${value.user_id}`), Cookies.remove('previous') }}>
																<div className="relative">
																	<img src={value.thumbnail} alt="" className='w-12 h-12 rounded-full' />
																</div>
																<div>
																	<h4 className="rubik-500 fs-16 dark-color">{value.name}</h4>
																</div>
															</div>
														</li>
													</>}
										</React.Fragment>

									)
								})}
			</ul>
		</>
	)
}
