import React, {
	useRef,
} from 'react';

import { handleHideModal } from '../../assets/js/custome';
import { ModalCloseIcon } from '../../assets/svg/icons';
import { useDispenseSelectors } from '../../services/selectors/dispenseSelectors';
import { useCustomDispatch } from '../../hooks';

import * as Dispense from '../../services/actions/pharmacy/dispense'
import * as useEffectHelper from '../../utils/helper/useEffectsHelper'
import * as path from '../../networking/urlEndPoints'

export const DeleteDispenseDriver = ({ text, setShowModal, showModal, driverData }) => {

	const modalRef = useRef(null);
	const dispatch = useCustomDispatch()
	const { deleteDispenseDataResponse } = useDispenseSelectors();

	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'deleteModal', modalRef)

	return (
		<>
			<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
				<div ref={modalRef} className="relative w-full max-w-md max-h-full">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<button onClick={() => handleHideModal(setShowModal, showModal, 'deleteModal')} type="button"
							className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              				text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
							<ModalCloseIcon />
						</button>
						<div className="p-6 text-center pt-12">
							<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{text}</h3>
							<button onClick={() => dispatch(Dispense.handleDeleteDispenseData(`${path.pharmacy_delete_driver_detail}/${driverData.id}`, setShowModal, 'driver_delete'))} style={{ backgroundColor: '#3A52BB' }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 
                    			dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">

								{deleteDispenseDataResponse?.loading ? 'Deleting...' : 'Yes'}

							</button>
							<button onClick={() => handleHideModal(setShowModal, showModal, 'deleteModal')} type="button"
								className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                				border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                				dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
