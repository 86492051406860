import React, {
	useState,
	useEffect
} from 'react'

import {
	DeleteForeverOutlined,
} from '@mui/icons-material'

import {
	BreadCrumb,
	Footer,
	LoadingView
} from '../../../components/common'

import { useCustomDispatch } from '../../../hooks'
import { ErrorMessages } from '../../../components/error/alertMessages';
import { AddDriver, EditDriverDetails, GetAllDriversSigns } from '../../../models/dispense/addDriver'
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";

import { handleShowModal } from '../../../assets/js/custome'
import { DeleteDispenseDriver } from '../../../models/dispense/deleteDriver'

import { SuccessToastMessage } from '../../../components/loaders/toastMessage'
import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'
import { Edit } from '@mui/icons-material';
import { EyeIcon, PlusIcon } from '../../../assets/svg/icons';
import { currentDate } from '../../../utils/constants/formInitialStates';
import { StartDate, TextInput } from '../../../components/pharmacy/dispense/dispense';
import { fetchGetDriverSignatures } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData';

import ThemeAvatar from '../../../components/common/avatar'
import ChatBox from '../../../components/chat/chatbox'

import * as Dispense from '../../../services/actions/pharmacy/dispense'
import * as dispense from '../../../services/actions/pharmacy/dispense'
import * as path from '../../../networking/urlEndPoints'

const DeliveryDeriverManagement = () => {

	const dispatch = useCustomDispatch()

	const [driverData, setDriverData] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [isEdit, setEdit] = useState(false)
	const [formData, setFormData] = useState({ search: '', date: currentDate })

	const [showModal, setShowModal] = useState({
		deleteModal: false,
		showToast: false,
		compoundModal: false,
		addDriverModal: false,
		editDriver: false,
		driverSign: false
	})

	const { addDispenseDataResponse, dispenseListDataResponse } = useDispenseSelectors();

	const [getDriverSigns, setGetDriverSigns] = useState({ loading: false, data: [], error: null })

	const deliveryDriversList = dispenseListDataResponse?.data?.data

	useEffect(() => {
		dispatch(dispense.fetchAllDispenseList(path.pharmacy_driver_list, 'pharmacy_driver_list', null, null, formData.date))
		return () => { }
	}, [])

	const handleFilter = () => {
		if (formData.date || formData.search) dispatch(dispense.fetchAllDispenseList(path.pharmacy_driver_list, 'pharmacy_driver_list', null, formData.search, formData.date))

	}

	const handleReset = () => {

		setFormData({ ...formData, search: '', date: currentDate })
		setPageNumber(1)

		if (formData.date || formData.search) dispatch(dispense.fetchAllDispenseList(path.pharmacy_driver_list, 'pharmacy_driver_list', null, null, currentDate))

	}

	return (
		<React.Fragment>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">

					<div className="grid grid-cols-12 gap-4">
						{/* <Sidebar /> */}

						<div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content">
								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
													sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

										<div className='col-span-12 flex'>
											<div className="flex gap-2">

												<div style={{ width: '250px' }}>
													<TextInput label='Search by name and email' name='search' formData={formData}
														setFormData={setFormData} />
												</div>


												<div style={{ width: '250px' }}>
													<StartDate formData={formData}
														setFormData={setFormData} />
												</div>

												<div style={{ width: '25px' }}>
													<button onClick={handleFilter} style={{ height: '42px' }} type="button" className="ml-auto bg-blue-500 hover:bg-blue-500 ml-2 h-12 flex py-4 items-center justify-center 
															text-white bg-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white">
														Filter
													</button>
												</div>

												<div style={{ marginLeft: '45px' }}>
													<button onClick={handleReset} style={{ height: '42px' }} type="button" className="ml-auto bg-white text-gray-500 hover:bg-white ml-2 h-12 flex py-4 items-center justify-center 
															text-white bg-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white">
														Reset
													</button>
												</div>

											</div>

											<div className="ml-auto">
												<button
													style={{ height: '42px' }}
													onClick={() => {
														return handleShowModal(setShowModal, showModal, "addDriverModal"),
															dispatch(dispense.pharmacyAddDispenseDataFailure(null, null))
													}}
													type="button" className="ml-auto blueBg-color hover:blueBg-color ml-2 h-12 flex py-4 items-center justify-center 
												text-white bg-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white">

													<PlusIcon color="white" style={{ paddingRight: 10 }} />
													Add New Delivery Driver
												</button>
											</div>
										</div>


										{dispenseListDataResponse?.loading ?
											<div className="flex justify-center items-center mt-60 mb-80">
												<LoadingView />
											</div>
											:
											dispenseListDataResponse?.error !== null && dispenseListDataResponse?.types === 'pharmacy_driver_list' ?
												<div className='mb-80'>
													<ErrorMessages />
												</div>
												:
												deliveryDriversList?.data?.length === 0 ? null :
													<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-4">
														<div className="shadow-md border rounded-lg relative overflow-x-auto">
															<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
																<thead className="text-xs text-white uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400">
																	<tr>
																		<th scope="col" className="px-6 py-3">
																			<p className="rubik-500 fs-14">IMAGE</p>
																		</th>

																		<th scope="col" className="px-6 py-3">
																			<p className="rubik-500 fs-14">DRIVER NAME</p>
																		</th>

																		<th scope="col" className="px-6 py-3">
																			<p className="rubik-500 fs-14">EMAIL</p>
																		</th>

																		<th scope="col" className="px-6 py-3">
																			<p className="rubik-500 fs-14">PHONE</p>
																		</th>

																		<th scope="col" className="px-6 py-3">
																			<p className="rubik-500 fs-14">In Store</p>
																		</th>

																		<th scope="col" className="px-6 py-3">
																			<p className="rubik-500 fs-14">DELVERIES</p>
																		</th>

																		<th scope="col" className="px-6 py-3">
																			<p className="rubik-500 fs-14">SIGNATURES</p>
																		</th>

																		<th scope="col" className="px-6 py-3 rounded-e-lg">
																			<p className="rubik-500 fs-14">ACTION</p>
																		</th>
																	</tr>
																</thead>

																<tbody>
																	{deliveryDriversList?.data?.map((driver, index) => {
																		return (
																			<tr className="bg-white dark:bg-gray-800 border-b dark:border-gray-700" key={index}>
																				<th  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
																					<ThemeAvatar name={driver.name} image={driver.driver_photo} size='30' />
																				</th>
																				<td className="px-6 py-4">
																					<p className="rubik-400 fs-14">{driver?.name}</p>
																				</td>

																				<td className="px-6 py-4">
																					<p className="rubik-400 fs-14">{driver?.email}</p>
																				</td>

																				<td className="px-6 py-4">
																					<p className="rubik-400 fs-14">{driver.phone}</p>
																				</td>


																				<td className="px-6 py-4">
																					{driver.in_store == 1 ?
																						<p className="rubik-400 fs-14 text-green-500">Yes</p>
																						:
																						<p className="rubik-400 fs-14 text-red-500">No</p>
																					}
																				</td>


																				<td className="px-6 py-4">
																					<p className="rubik-400 fs-14">{driver.deliveries}</p>
																				</td>

																				<td className="px-6 py-4">
																					{driver?.signatures == 0 ?
																						<p className="rubik-400 fs-14 cursor-pointer">
																							{driver.signatures}
																						</p> :
																						<p className="rubik-400 fs-14 cursor-pointer" onClick={() => {
																							return handleShowModal(setShowModal, showModal, 'driverSign'),
																								dispatch(fetchGetDriverSignatures(formData?.date, driver?.id, setGetDriverSigns))
																						}}>
																							{driver.signatures}
																						</p>}
																				</td>

																				<td className="px-6 py-4 flex items-center space-x-1">

																					<span onClick={() => {
																						return setDriverData(driver), setEdit(true),
																							dispatch(dispense.pharmacyAddDispenseDataFailure(null, null)),
																							setShowModal({ ...showModal, editDriver: true })
																					}}>
																						<button className='flex items-center justify-center'>
																							{driver?.global === 1 ?
																								<EyeIcon className='text-blue-500 mr-1' /> :
																								<Edit className='text-blue-500' />}
																						</button>
																					</span>

																					<button className='flex items-center justify-center text-red-400'
																						onClick={() => { return handleShowModal(setShowModal, showModal, 'deleteModal'), setDriverData(driver) }}>
																						<DeleteForeverOutlined />
																					</button>

																				</td>
																			</tr>
																		)
																	})}
																</tbody>
															</table>
														</div>

													</div>
										}

										{dispenseListDataResponse?.loading && dispenseListDataResponse.type === 'pharmacy_driver_list' ? null : dispenseListDataResponse?.error !== null && dispenseListDataResponse.type === 'pharmacy_driver_list' ? null : (
											<>
												{deliveryDriversList?.data?.length !== 0 && (
													<>
														{deliveryDriversList?.pagination?.last_page !== 1 && (
															<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end 
																			sm:justify-center md:justify-center justify-center mt-5">
																<nav aria-label="Page navigation example">
																	<ul className="inline-flex -space-x-px text-base h-10">
																		<CustomPagination
																			count={deliveryDriversList?.pagination?.last_page}
																			page={pageNumber}
																			onChange={(event, value) => {
																				setPageNumber(value)
																				dispatch(Dispense.fetchAllDispenseList(path.pharmacy_driver_list, 'pharmacy_driver_list', value))
																			}} />
																	</ul>
																</nav>
															</div>
														)}
													</>
												)}
											</>)}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{showModal.addDriverModal &&
				<AddDriver setShowModal={setShowModal} showModal={showModal} isEdit={isEdit} isEditTable={isEdit} driverData={driverData} date={formData.date} />}

			{showModal.editDriver &&
				<EditDriverDetails setShowModal={setShowModal} showModal={showModal} isEdit={isEdit} isEditTable={isEdit} driverData={driverData} date={formData.date} />}

			{showModal.deleteModal && <DeleteDispenseDriver text={`Are you sure you want to delete ${driverData?.name} ?`}
				setShowModal={setShowModal}
				showModal={showModal}
				driverData={driverData}
				date={formData.date}
			/>}

			{showModal.driverSign &&
				<GetAllDriversSigns setShowModal={setShowModal}
					showModal={showModal}
					getDriverSigns={getDriverSigns}
				/>}

			{showModal?.showToast && addDispenseDataResponse?.type === 'pharmacy_add_new_delivery_deriver' &&
				<SuccessToastMessage status='Delivery Deriver Has been Successfully updated' />}

			{showModal?.showToast && addDispenseDataResponse?.type === 'pharmacy-add-driver' &&
				<SuccessToastMessage status='Driver added Successfully' />}

			{showModal?.showToast && addDispenseDataResponse?.type === 'pharmacy-update-driver' &&
				<SuccessToastMessage status='Driver updated Successfully' />}


			<ChatBox />
			<Footer />

		</React.Fragment>
	)
}

export default DeliveryDeriverManagement