import {
    PATIENT_BLISTER_PACK_LIST_REQUEST,
    PATIENT_BLISTER_PACK_LIST_SUCCESS,
    PATIENT_BLISTER_PACK_LIST_FAILURE
} from "../../../constants"

import { pharmacy_transfer_prescription, pharmacy_order_details, pharmacy_sorder_notes } from "../../../../networking/urlEndPoints"
import { pharmacyInventoryListFailure, pharmacyInventoryListRequest, pharmacyInventoryListSuccess } from "../Inventory/inventoryList"

import Axios from "../../../../networking/intraceptor"

export const prescriptionListRequest = (types) => ({
    type: PATIENT_BLISTER_PACK_LIST_REQUEST,
    types: types
})

export const prescriptionListSuccess = (data, types) => ({
    type: PATIENT_BLISTER_PACK_LIST_SUCCESS,
    payload: data,
    types: types
})

export const prescriptionListFailure = (error, types) => ({
    type: PATIENT_BLISTER_PACK_LIST_FAILURE,
    payload: error,
    types: types
})

// -----------------------------Ftech Prescription list------------------------
export const fetchTransferPrescriptionList = (type, params) => {

    return async (dispatch) => {
        dispatch(prescriptionListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_transfer_prescription}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(prescriptionListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(prescriptionListFailure(error?.response, type))
            }
        }
    }
}


export const fetchOrdersDetails = (type, orderID) => {

    return async (dispatch) => {
        dispatch(prescriptionListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_order_details}/${orderID}`)
            if (response?.status === 200) {
                dispatch(prescriptionListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(prescriptionListFailure(error?.response, type))
            }
        }
    }
}

export const fetchAllOrdersNotesList = (type, orderID) => {
    return async (dispatch) => {
        dispatch(pharmacyInventoryListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_sorder_notes}`, { params: { order_id: orderID } })
            if (response?.status === 200) {
                dispatch(pharmacyInventoryListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyInventoryListFailure(error?.response?.data, type))
            }
        }
    }
}