import React, { useState } from 'react'
import { useCustomDispatch } from '../../hooks'
import { useCustomPatientSelectors } from '../../services/selectors/patientSelectors'

import { handleHideModal } from '../../assets/js/custome'
import { ModalCloseIcon } from '../../assets/svg/icons'
import { fetchAllPatientCoveragelist } from '../../services/actions/patients/coveragePatientList';
import { LoadingView } from '../../components/common/loadingView'

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const ViewAllCoverageLink = ({ setShowModal, showModal, handleDownload, patientProfileData }) => {

	const { coverageList, patientCoverageListResponse } = useCustomPatientSelectors()
	const [paginationNumber, setPaginationNumber] = useState(1)

	const dispatch = useCustomDispatch()

	const handlePagination = (event, value) => {
		setPaginationNumber(value);
		dispatch(fetchAllPatientCoveragelist(patientProfileData?.id, value))
	}

	return (<>
		<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center 
						justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
			<div className="relative w-full max-w-3xl max-h-3xl">
				<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
					<h1 className='ml-6 pt-4 rubik-500 fs-16'>Upload Logs</h1>
					<button onClick={() => handleHideModal(setShowModal, showModal, 'viewAllCoverageLink')} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
						<ModalCloseIcon />
					</button>

					{patientCoverageListResponse?.loading ?
						<div className="flex justify-center items-center pb-60 pt-60">
							<LoadingView />
						</div>
						: patientCoverageListResponse?.error !== null ?
							<div className="flex flex-col items-center justify-center pb-60 pt-60">
								<div className="p-4 mb-4 text-center text-red-800 rounded-lg bg-red-50 dark:bg-red-600 dark:text-white" role="alert">
									<span className="font-semibold text-2xl">Oops! Something went wrong</span><br />
									<p className="text-gray-600 dark:text-gray-300 mt-2 rubik-400 text-lg">We encountered an issue while processing your request. Please try again later.</p>
								</div>
							</div>
							:
							<div className="p-6 text-center">
								<div className="overflow-y-auto lg:overflow-y-visible sm:overflow-y-auto shadow-md sm:rounded-lg ">
									<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
										<thead className="text-xs uppercase blueBg-color light-color rubik-500">
											<tr>
												<th scope="col" className="px-6 py-3">
													<p>File</p>
												</th>
												<th scope="col" className="px-6 py-3">
													<p>Date & time Initiated	</p>
												</th>

												<th scope="col" className="px-6 py-3">
													<p>Upload date & Time</p>
												</th>
											</tr>
										</thead>
										<tbody>

											{coverageList?.data?.map((value, index) => {
												return (<React.Fragment key={index}>
													<tr className="bg-white border-b dark-color rubik-400 dark:border-gray-700">

														<td className="px-6 py-2 font-medium whitespace-nowrap">
															{value?.file?.startsWith('https://cloud.oatrx.ca/') ? (<>
																<p onClick={() => handleDownload(value?.file)} className="btnnn bg-green-100 fs-10 text-blue-800 text-xs font-medium inline-flex items-center rounded dark:bg-gray-700 dark:text-blue-400 border border-gray-400 flex items-center pr-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"><span className="pl-2">Download Latest</span></p>
																<br />
															</>) : <p className="bg-red-50 fs-10 text-blue-800 text-xs font-medium inline-flex items-center rounded dark:bg-gray-700 dark:text-blue-400 border  flex items-center pr-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"><span className="pl-2">
																{value.file}</span>
															</p>
															}
														</td>

														<td className="px-6 py-2">
															{value.date_time_initiated}
														</td>
														<td className="px-6 py-2">
															{value.upload_date_time}
														</td>
													</tr>

												</React.Fragment>)
											})}

										</tbody>
									</table>
								</div>

								{coverageList?.pagination?.last_page !== 1 &&
									<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
										<nav aria-label="Page navigation example">
											<ul className="inline-flex -space-x-px text-base h-10">
												<Stack spacing={2}>
													<Pagination count={coverageList?.pagination?.last_page} variant="outlined"
														page={paginationNumber}
														defaultPage={1}
														onChange={handlePagination}
														color="primary" />
												</Stack>
											</ul>
										</nav>
									</div>}

							</div>}
				</div>
			</div>
		</div>
	</>
	)
}

export default ViewAllCoverageLink 
