import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { validateLabelInput } from '../../services/actions/pharmacy/dispense/labelValidateUpdateInputs';
import { updateExpiry, updateProcessingForVials } from '../../services/actions/pharmacy/dispense/updateExpiry';
import { UpdateBlisterDailyPack, UpdateBlisterPack, UpdateDailyMedicationPack, UpdateDailyVailsPack, UpdateMedicationPack } from '../../services/actions/pharmacy/blisters/addBlisterPackData';

export const editTimeOffRequestStartDatePicker = (startDatePickerRef, setStartDateValue, startDateValue) => {
	let startDatepickerInstance;

	if (startDatePickerRef.current) {
		startDatepickerInstance = flatpickr(startDatePickerRef.current, {
			dateFormat: 'Y-m-d',
			defaultDate: startDateValue,
			onChange: function (selectedDates, dateStr, instance) {
				setStartDateValue(dateStr);
			},
		});
	}

	return startDatepickerInstance;
}

export const editTimeOffRequestEndDatePicker = (endDatePickerRef, setEndDateValue, endDateValue) => {
	let endDatepickerInstance;

	if (endDatePickerRef.current) {
		endDatepickerInstance = flatpickr(endDatePickerRef.current, {
			dateFormat: 'Y-m-d',
			defaultDate: endDateValue,
			onChange: function (selectedDates, dateStr, instance) {
				setEndDateValue(dateStr);
			},
		});
	}
	return endDatepickerInstance;
}

export const timeSheetStartDatePicker = (startDatePickerRef, setStartDateValue, setMessage, startDateValue, setEndDateValue) => {
	let startDatepickerInstance;

	if (startDatePickerRef.current) {
		startDatepickerInstance = flatpickr(startDatePickerRef.current, {
			defaultDate: startDateValue,
			dateFormat: 'Y-m-d',
			onChange: function (selectedDates, dateStr, instance) {
				setStartDateValue(dateStr);
				const endDate = new Date(dateStr);
				endDate.setDate(endDate.getDate() + 7);
				setEndDateValue(endDate.toISOString().split('T')[0]);
				setMessage('')
			},
		});
	}
	return startDatepickerInstance;
}

export const timeSheetEndDatePicker = (endDatePickerRef, setEndDateValue, setMessage, endDateValue) => {
	let endDatepickerInstance;
	if (endDatePickerRef.current) {
		endDatepickerInstance = flatpickr(endDatePickerRef.current, {
			defaultDate: endDateValue,
			dateFormat: 'Y-m-d',
			onChange: function (selectedDates, dateStr, instance) {
				setEndDateValue(dateStr);
				setMessage('');
			},
		});
	}

	return endDatepickerInstance;
};

export function endDateUpdate(delta, endDatePickerRef) {
	if (endDatePickerRef.current && endDatePickerRef.current._flatpickr) {
		const currentDate = endDatePickerRef.current._flatpickr.selectedDates[0];
		if (currentDate) {
			const newDate = new Date(currentDate);
			newDate.setDate(currentDate.getDate() + delta);
			endDatePickerRef.current._flatpickr.setDate(newDate, true);
		}
	}
}

export function startDateUpdate(delta, startDatePickerRef) {
	if (startDatePickerRef.current && startDatePickerRef.current._flatpickr) {
		const currentDate = startDatePickerRef.current._flatpickr.selectedDates[0];
		if (currentDate) {
			const newDate = new Date(currentDate);
			newDate.setDate(currentDate.getDate() + delta);
			startDatePickerRef.current._flatpickr.setDate(newDate, true);
		}
	}
}

export const initialPharmacyTodaysDate = (DateRef,
	setFormData,
	formData,
	type,
	dispatch,
	setShowModal,
	showModal,
	memoID,
	searchParam,
	setShowToast,
	showToast,
	fetchType = '',
	pageNumber,
	filterParam
) => {

	let todaysDate;

	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			minDate: 'today',

			onChange: function (selectedDates, dateStr, instance) {

				if (fetchType === 'blister_pack') {
					const requestData = {
						pack_id: memoID,
						'date': dateStr
					}

					dispatch(UpdateBlisterPack('update_blister_pack', requestData, setShowModal, setShowToast, pageNumber, filterParam))

				} else if (fetchType === 'daily_pack') {

					const requestData = {
						pack_id: memoID,
						'due_date': dateStr
					}

					dispatch(UpdateBlisterDailyPack('update_blister_daily_pack', requestData, setShowModal, setShowToast, pageNumber, 'due_date'))

				}

				else if (fetchType === 'daily_vials') {

					const requestData = {
						pack_id: memoID,
						'due_date': dateStr
					}

					dispatch(UpdateDailyVailsPack('update_daily_vials_pack', requestData, setShowModal, setShowToast, pageNumber, 'due_date'))
				}

				else if (fetchType === 'daily_medication') {
					const requestData = {
						pack_id: memoID,
						'due_date': dateStr
					}

					dispatch(UpdateDailyMedicationPack('update_daily_medication_pack', requestData, setShowModal, setShowToast, pageNumber, 'due_date'))
				}

				else if (fetchType === 'speciality_medication') {
					const requestData = {
						pack_id: memoID,
						'date': dateStr
					}

					dispatch(UpdateMedicationPack('update_medication_pack', requestData, setShowModal, setShowToast, pageNumber, 'processing_date'))
				}

				else {

					if (type === 'isvaliddate') {
						setFormData({
							...formData,
							datevalid: dateStr
						});

						dispatch(updateExpiry('update_reminder', {
							id: memoID,
							next_reminder: dateStr
						}, showToast, setShowToast, searchParam, setShowModal, showModal))

					} else {
						setFormData({
							...formData,
							date: dateStr,
						});
					}
				}
			},
		});
	}
	return todaysDate;
}

export const initialAddPateintDOBPicker = (dobRef, setPatientFormData, patientFormData) => {
	let startDOBPickerInstance;

	if (dobRef.current) {
		startDOBPickerInstance = flatpickr(dobRef.current, {
			dateFormat: 'Y-m-d',
			maxDate: 'today',
			defaultDate: patientFormData.dob,
			onChange: function (selectedDates, dateStr, instance) {
				setPatientFormData({
					...patientFormData,
					dob: dateStr
				});
			},
		});
	}
	return startDOBPickerInstance;
}

export const initialPharmacyTransferDate = (DateRef, setFormData, formData) => {
	let todaysDate;

	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			minDate: 'today',
			onChange: function (selectedDates, dateStr, instance) {
				setFormData({
					...formData,
					transferDate: dateStr,
					prescribingDate: dateStr,
				});
			},
		});
	}
	return todaysDate;
}

export const initialPharmacyPrescribingStartDate = (DateRef, setFormData, formData) => {
	let todaysDate;

	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			minDate: 'today',
			onChange: function (selectedDates, dateStr, instance) {
				setFormData({
					...formData,
					prescribingStartDate: dateStr,
				});
			},
		});
	}
	return todaysDate;
}

export const initialPharmacyDatePicker = (DateRef, setFormData, formData, key) => {
	let todaysDate;

	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			minDate: 'today',
			onChange: function (selectedDates, dateStr, instance) {
				setFormData({
					...formData,
					[key]: dateStr
				});
			},
		});
	}
	return todaysDate;
}

export const initialPharmacyPreviousDatePicker = (DateRef, setFormData, formData, key) => {
	let todaysDate;

	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			maxDate: 'today',
			onChange: function (selectedDates, dateStr, instance) {
				setFormData({
					...formData,
					[key]: dateStr
				});
			},
		});
	}
	return todaysDate;
}

export const dataRangePicker = (DateRef, setFormData, formData, key) => {
	let todaysDate;
	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			minDate: key === 'startDate' ? null : 'today',
			onChange: function (selectedDates, dateStr, instance) {
				setFormData({
					...formData,
					[key]: dateStr
				});
			}
		});
	}

	return todaysDate;
};



export const ArchivedDelveriesDatePicker = (DateRef, setFormData, formData, key, startDate) => {
	let todaysDate;
	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			minDate: key === 'endDate' && startDate,
			// maxDate: 'today',
			onChange: function (selectedDates, dateStr, instance) {
				setFormData({
					...formData,
					[key]: dateStr
				});
			}
		});
	}

	return todaysDate;
};

// export const ArchivedDelveriesDatePicker = (DateRef, setFormData, formData, key, startDate) => {
// 	let todaysDate;
// 	if (DateRef.current) {
// 		todaysDate = flatpickr(DateRef.current, {
// 			dateFormat: 'Y-m-d',
// 			minDate: key === 'endDate' && startDate,
// 			maxDate: new Date().fp_incr(4),
// 			onChange: function (selectedDates, dateStr, instance) {
// 				setFormData({
// 					...formData,
// 					[key]: dateStr
// 				});
// 			}
// 		});
// 	}

// 	return todaysDate;
// };

export const initialPharmacyDispenseTodaysDate = (DateRef, setFormData, formData, type = 'default', dispatch) => {
	let todaysDate;

	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			maxDate: type === 'update_label' ? '' : 'Today',

			onChange: function (selectedDates, dateStr, instance) {
				setFormData({
					...formData,
					date: dateStr
				});
				// if (type === 'update_label') {
				// 	const formType = formData.updateformtype === 'Update' ? 'update' : 'generate'

				// 	const requestedData = {
				// 		labelid: formData.labelid,
				// 		updateformtype: formType,
				// 		change_from_date: dateStr,
				// 		enter_dose: formData.enter_dose
				// 	};

				// 	if (formData.carries_day.length > 0) requestedData.carries_day = formData.carries_day 
				// 		// dispatch(validateLabelInput('update_label_input', requestedData))
				// }
			},
		});
	}
	return todaysDate;
}


export const initialPharmacBlisterTodaysDate = (DateRef, setDate, initialDate) => {
	let todaysDate;
	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			// maxDate: 'today',
			defaultDate: initialDate ? initialDate.date : null,
			onChange: function (selectedDates, dateStr, instance) {
				setDate(dateStr);
			},
		});
	}
	return todaysDate;
};



export const initialAddPatientFromDatePicker = (dobRef, setPatientFormData, patientFormData) => {
	let startDOBPickerInstance;

	if (dobRef.current) {
		startDOBPickerInstance = flatpickr(dobRef.current, {
			dateFormat: 'Y-m-d',
			maxDate: 'today',
			defaultDate: patientFormData.fromdate,
			onChange: function (selectedDates, dateStr, instance) {
				alert("dateStr")
				setPatientFormData({
					...patientFormData,
					fromdate: dateStr
				});
			},
		});
	}
	return startDOBPickerInstance;
}

export const initialPharmacyTodaysDateForVials = (DateRef, setFormData, formData, type, dispatch,
	setShowModal, showModal, memoID, searchParam, setShowToast,
	showToast
) => {
	let todaysDate;

	if (DateRef.current) {
		todaysDate = flatpickr(DateRef.current, {
			dateFormat: 'Y-m-d',
			minDate: 'today',
			onChange: function (selectedDates, dateStr, instance) {
				if (type === 'isvaliddate') {
					setFormData({
						...formData,
						datevalid: dateStr
					});
					// dispatch()
					dispatch(updateProcessingForVials('update_processing_date', {
						pack_id: memoID,
						date: dateStr
					}, showToast, setShowToast, searchParam, setShowModal, showModal))

				} else {
					setFormData({
						...formData,
						date: dateStr,
					});
				}
			},
		});
	}
	return todaysDate;
}