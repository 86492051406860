import {
    UPDATE_TU_ONLINE_STATUS_REQUEST,
    UPDATE_TU_ONLINE_STATUS_SUCCESS,
    UPDATE_TU_ONLINE_STATUS_FAILURE
} from "../../constants"

import Axios from "../../../networking/intraceptor"
import { pharmacy_update_tu_online_status  } from "../../../networking/urlEndPoints"

export const addActivityStatusRequest = () => ({
    type: UPDATE_TU_ONLINE_STATUS_REQUEST,
})

export const addActivityStatusSuccess = (data) => ({
    type: UPDATE_TU_ONLINE_STATUS_SUCCESS,
    payload: data,
})

export const addActivityStatusFailure = (error) => ({
    type: UPDATE_TU_ONLINE_STATUS_FAILURE,
    payload: error,
})

export const addUserActivityStatus = (status) => {
	return async (dispatch) => {
		dispatch(addActivityStatusRequest())
		try {
			const response = await Axios.post(`${pharmacy_update_tu_online_status}`,
				{
					status:status 
				})
			if (response?.status === 200) {
				dispatch(addActivityStatusSuccess(response?.data))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(addActivityStatusFailure(error?.response))
			}
		}
	}
}
