import {
	PHARMACY_DISPENSE_LABELS_LIST_REQUEST,
	PHARMACY_DISPENSE_LABELS_LIST_SUCCESS,
	PHARMACY_DISPENSE_LABELS_LIST_FAILURE
} from '../../../constants';

import Axios from '../../../../networking/intraceptor'
import * as path from '../../../../networking/urlEndPoints'

export const patientPrescriptionDetailsRequest = (types) => ({
	type: PHARMACY_DISPENSE_LABELS_LIST_REQUEST,
	types: types
});

export const patientPrescriptionDetailsSuccess = (data, types) => ({
	type: PHARMACY_DISPENSE_LABELS_LIST_SUCCESS,
	payload: data,
	types: types
});

export const patientPrescriptionDetailsFailure = (error, types) => ({
	type: PHARMACY_DISPENSE_LABELS_LIST_FAILURE,
	payload: error,
	types: types
})
// -------------------------Labels List----------------------
export const fetchDispenseLabelsList = (params,type) => {
	return async (dispatch) => {
		dispatch(patientPrescriptionDetailsRequest(type))
		try {

			const response = await Axios.get(`${path.pharmacy_patient_dlabels}`, { params })

			if (response?.status === 200) {
				dispatch(patientPrescriptionDetailsSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(patientPrescriptionDetailsFailure(error?.response));
			}
		}
	}
}
// -------------------------Expiring List----------------------

export const fetchExpiringRxList = (params,type) => {
	return async (dispatch) => {
		dispatch(patientPrescriptionDetailsRequest(type))
		try {

			const response = await Axios.get(`${path.expiring_rxsheet}`, { params })

			if (response?.status === 200) {
				dispatch(patientPrescriptionDetailsSuccess(response?.data,type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(patientPrescriptionDetailsFailure(error?.response,type));
			}
		}
	}
}

// -------------------------Triplicates List----------------------

export const fetchUploadedTriplicatesList = (params,type) => {
	return async (dispatch) => {
		dispatch(patientPrescriptionDetailsRequest(type))
		try {

			const response = await Axios.get(`${path.upload_triplicate}`, { params })

			if (response?.status === 200) {
				dispatch(patientPrescriptionDetailsSuccess(response?.data,type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(patientPrescriptionDetailsFailure(error?.response,type));
			}
		}
	}
}

// -------------------------Triplicates List----------------------

export const fetchFentanylPatchesList = (params,type) => {
	return async (dispatch) => {
		dispatch(patientPrescriptionDetailsRequest(type))
		try {

			const response = await Axios.get(`${path.returned_fentanyl_patches}`, { params })

			if (response?.status === 200) {
				dispatch(patientPrescriptionDetailsSuccess(response?.data,type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(patientPrescriptionDetailsFailure(error?.response,type));
			}
		}
	}
}