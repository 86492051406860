import React, { useState } from 'react';
import { useCanvasContext } from '../hooks/useCanvasContext';
import { handleAddCertificateAsTemplate, handleUpdateCertificateAsTemplate } from '../../../../../services/actions/admin/team/addTeamData';
import { useCustomDispatch, useCustomNavigate } from "../../../../../hooks";
import {
  initalShowToastMessage
} from "../../../../../utils/constants/formInitialStates";
import { ErrorToastMessage, SuccessToastMessage } from "../../../../../components/loaders/toastMessage";
import { useAdminSelectors } from '../../../../../services/selectors/adminModuleSelectors';
import { pharmacy_certificate_template } from '../../../../../routes/routeName';
const calculateMultiplier = (canvas) => {
  const desiredWidth = 1200;
  const desiredHeight = 800;

  const currentWidth = canvas.width;
  const currentHeight = canvas.height;

  const widthMultiplier = desiredWidth / currentWidth;
  const heightMultiplier = desiredHeight / currentHeight;

  return Math.min(widthMultiplier, heightMultiplier);
};

const downloadAsPNG = (canvas, certificateName) => {
  if (canvas) {
    const multiplier = calculateMultiplier(canvas);

    const dataURL = canvas.toDataURL({
      format: 'png',
      multiplier: multiplier,
      quality: 1,
    });

    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `${certificateName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const returnCanvasAsJson = (canvas) => {
  if (canvas) {
    const json = JSON.stringify(canvas.toJSON());
    return json;
  }
  return ''
};

const downloadAsJSON = (canvas, certificateName) => {
  if (canvas) {
    const json = JSON.stringify(canvas.toJSON());
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${certificateName}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link)
  }
};

export const DownloadButtons = ({ certName = '', certToBeUpdated = false, id = '' }) => {
  const navigate = useCustomNavigate();
  const dispatch = useCustomDispatch();
  const { addTeamDataResponse } = useAdminSelectors()
  const { canvas } = useCanvasContext();
  const [certificateName, setCertificateName] = useState(certName);
  const [isNameValid, setIsNameValid] = useState(true);
  const [showToast, setShowToast] = useState(initalShowToastMessage);

  const handleDownloadClick = () => {
    if (certificateName.trim() === '') {
      setIsNameValid(false);
    } else {
      downloadAsPNG(canvas, certificateName);
    }
  };

  const HandleSaveClick = () => {

    if (certificateName.trim() === '') {
      setIsNameValid(false);
    } else {
      const canvasJson = returnCanvasAsJson(canvas);
      if (certToBeUpdated === false) {
        dispatch(handleAddCertificateAsTemplate('add_certificate_template', setShowToast, canvasJson, certificateName));
      } else {
        dispatch(handleUpdateCertificateAsTemplate('update_certificate_template', setShowToast, canvasJson, certificateName, id));
      }


    }
  };

  const handleInputChange = (event) => {
    setCertificateName(event.target.value);
    setIsNameValid(true);
  };

  return (
    <div className="download-buttons mt-5 flex gap-2" style={{ position: 'fixed', top: '0px', right: '20px', zIndex: '999' }}>
      <input
        type="text"
        placeholder="Template Name"
        value={certificateName}
        onChange={handleInputChange}
        className={`border-2 rounded-md px-4 mr-2 py-2 outline-none transition duration-300 ease-in-out w-full ${isNameValid ? 'border-gray-300 focus:border-blue-500' : 'border-red-500'}`}
        style={{ fontSize: '14px',height:'48px' }}
        required
      />
      {/* <button
        className="bg-black  text-white font-bold py-2 px-4 rounded flex gap-1 justify-center align-middle"
        style={{ fontSize: '14px' }}
        onClick={handleDownloadClick}
      >
        <span><svg xmlns="http://www.w3.org/2000/svg" width='24px' height='24px' viewBox="0 0 24 24" id="Download"><path d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z" fill="#fafeff" class="color000000 svgShape"></path></svg></span>
        <span>Download</span>
      </button> */}
      <button
        className="bg-blue-500  text-white font-bold py-2 px-4 rounded ml-2 flex gap-1 justify-center align-middle"
        style={{ fontSize: '14px' }}
        onClick={HandleSaveClick}
      >
        <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="save" width='24px' height='24px'><path d="m20.71 9.29-6-6a1 1 0 0 0-.32-.21A1.09 1.09 0 0 0 14 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-8a1 1 0 0 0-.29-.71ZM9 5h4v2H9Zm6 14H9v-3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1Zm4-1a1 1 0 0 1-1 1h-1v-3a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3v3H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V6.41l4 4Z" fill="#fafeff" class="color000000 svgShape"></path></svg></span>
        {
          certToBeUpdated === false ? (
            <span>
              {addTeamDataResponse.loading && addTeamDataResponse.type === 'add_certificate_template' ? 'Saving...' : 'Save'}
            </span>
          ) : (
            <span>
              {addTeamDataResponse.loading && addTeamDataResponse.type === 'update_certificate_template' ? 'Updating...' : 'Update'}
            </span>
          )
        }
      </button>
      
      <button onClick={() => navigate(pharmacy_certificate_template)} type="button" className="ml-2" style={{ fontSize: '36px', marginBottom: '-4px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}><span style={{ marginTop: "-20px" }}>×</span></button>

      {
        certToBeUpdated === false ? (
          <div>  {showToast.showToast && <SuccessToastMessage status={'Template created successfully.'} />}</div>

        ) : (
          <div>  {showToast.showToast && <SuccessToastMessage status={'Template updated successfully.'} />}</div>
        )
      }

      {showToast.errorToast && <ErrorToastMessage status={'Something went wrong. Please try again.'} />}
    </div>
  );
};
