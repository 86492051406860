import React from "react"
import { patientNotificationListLoader } from "../../utils/helper/helper"

export const NotificationsListLoader = () => {
	return (
		<React.Fragment>
			{
				patientNotificationListLoader.map((value, index) =>
					<div className="p-3 bg-white rounded-lg shadow mt-2" key={index}>
						<div className="flex items-center">
							<div className="max-w-sm animate-pulse">
								<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80 mb-3"></div>
								<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80 mb-3"></div>
								<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-60"></div>
								<span className="sr-only">Loading...</span>
							</div>
							<div className="max-w-sm animate-pulse ml-auto">
								<div className="bg-gray-200 rounded-md dark:bg-gray-700 h-10 w-10"></div>
							</div>
						</div>
					</div>
				)}

		</React.Fragment>
	)
}

export const PatientNotificationLoader = () => {
	const patientNotificationListLoader = Array.from({ length: 5 }, (_, index) => index);

	return (
		<React.Fragment>
			{
				patientNotificationListLoader?.map((value, index) =>
					<div className="px-4" key={index}>
						<div className="p-3 bg-white rounded-lg shadow  mb-6">
							<div className="flex items-center">
								<div className="max-w-sm animate-pulse">
									<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-60 mb-3"></div>
									<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-60 mb-3"></div>
									<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
									<span className="sr-only">Loading...</span>
								</div>
								<div className="max-w-sm animate-pulse ml-auto">
									<div className="bg-gray-200 rounded-md dark:bg-gray-700 h-10 w-10"></div>
								</div>
							</div>
						</div>
					</div>
				)}

		</React.Fragment>
	)
}

export const PatientListLoader = () => {
	const patientNotificationListLoader = Array.from({ length: 10 }, (_, index) => index);

	return (
		<React.Fragment>
			<div className=" bg-white rounded-lg shadow relative overflow-x-auto" >

				{
					patientNotificationListLoader?.map((value, index) =>
						<div className="p-3 flex border-b-2 items-center shadow" key={index}>
							<div className="max-w-sm animate-pulse">
								<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-3"></div>
								<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
								<span className="sr-only">Loading...</span>
							</div>

							<div className="max-w-sm animate-pulse lg:ml-16">
								<div className="h-6 bg-gray-200 rounded-md dark:bg-gray-700 w-20 mb-3"></div>
								<span className="sr-only">Loading...</span>
							</div>

							<div className="max-w-sm animate-pulse lg:ml-10">
								<div className="h-6 bg-gray-200 rounded-md dark:bg-gray-700 w-20 mb-3"></div>
								<span className="sr-only">Loading...</span>
							</div>

							<div className="max-w-sm animate-pulse lg:ml-12">
								<div className="h-6 bg-gray-200 rounded-md dark:bg-gray-700 w-16 mb-3"></div>
								<span className="sr-only">Loading...</span>
							</div>

							<div className="max-w-sm animate-pulse lg:ml-8">
								<div className="h-6 bg-gray-200 rounded-md dark:bg-gray-700 w-24 mb-3"></div>
								<span className="sr-only">Loading...</span>
							</div>

							<div className="max-w-sm animate-pulse lg:ml-20">
								<div className="h-6 bg-gray-200 rounded-md dark:bg-gray-700 w-14 mb-3"></div>
								<span className="sr-only">Loading...</span>
							</div>

							<div className="max-w-sm animate-pulse lg:ml-14">
								<div className="h-6 bg-gray-200 rounded-md dark:bg-gray-700 w-14 mb-3"></div>
								<span className="sr-only">Loading...</span>
							</div>

							<div className="max-w-sm animate-pulse lg:ml-14">
								<div className="h-6 bg-gray-200 rounded-md dark:bg-gray-700 w-10 mb-3"></div>
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
			</div>
		</React.Fragment>
	)
}