import CryptoJS from 'crypto-js';

import {
	userEmail,
	userPassword,
	encryptionKey
} from '../../constants/email-pasword';

// ------------------------------------------------- Email - Encryption && Decryption Section - Start-------------------------------

export const encryptEmailData = (email) => {
	const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(email), encryptionKey).toString();
	localStorage.setItem(userEmail, encryptedData);
};

export const decryptEmailData = () => {
	const cachedData = localStorage.getItem(userEmail);
	if (cachedData) {
		const decryptedData = CryptoJS.AES.decrypt(cachedData, encryptionKey).toString(CryptoJS.enc.Utf8);
		return JSON.parse(decryptedData);
	}
	return null;
};

// ------------------------------------------------- Email - Encryption && Decryption Section - End----------------------------------

// ------------------------------------------------- Password - Encryption && Decryption Section-Start-------------------------------

export const encryptPasswordData = (password) => {
	const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(password), encryptionKey).toString();
	localStorage.setItem(userPassword, encryptedData);
};

export const decryptPasswordData = () => {
	const cachedData = localStorage.getItem(userPassword)
	if (cachedData) {
		const decryptedData = CryptoJS.AES.decrypt(cachedData, encryptionKey).toString(CryptoJS.enc.Utf8);
		return JSON.parse(decryptedData);
	}
	return null;
};

// ------------------------------------------------- Password - Encryption && Decryption Section-End-------------------------------
