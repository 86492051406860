// ---------------------------------------------Storage keys --------------------------------

export const encryptionKey = '23/Bo3;}0Q(v2H9wS^9826£<'

export const userEmail = 'UEgNtTli3A_px-wail'

export const userPassword = 'PAgNtTli3A_ps-wrd'

// ---------------------------------------------Storage keys --------------------------------

export const LoginWithData = [{
	id: 1,
	name: 'Pharmacy',
}, {
	id: 2,
	name: 'Driver',
}]
