import React, {
	useState,
	useEffect,
	useRef
} from 'react'

import { Errors } from '../../../components/error/alertMessages'
import { useCustomDispatch } from '../../../hooks'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'
import { PatientNotificationLoader } from '../../../components/loaders/patientNotificationListLoader'

import { Loader } from '../../../components/common/loadingView'
import { initTooltips } from 'flowbite'
import { authToken } from '../../../storage/authToken'

import * as pharmacyTeamNotes from '../../../services/actions/pharmacy/other'
import * as images from '../../../assets/images/index'
import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'
import * as Helper from '../../../utils/helper/helper'

const PatinentNotesNotification = () => {

	const textareaRef = useRef(null);
	const token = authToken()
	const dispatch = useCustomDispatch();

	const [addTeamNoteData, setAddTeamNoteData] = useState({
		notificationIndex: null, pageNumber: 1, addReply: '', showToast: false,
		initalListLoading: true, notificationId: null, tooltipVisible: false,
		marknotes: true
	})

	const [apiCallMade, setApiCallMade] = useState(false)

	const { patientListNotificationsResponse,
		patientNotificationsList,
		pharmacyPatientAddNoteReply,
		patientNoteMarkAsRead } = useCustomSelectors();

	useEffect(() => {
		if (!apiCallMade && token) {
			dispatch(pharmacyTeamNotes.fetchPatientNotifications(null, setAddTeamNoteData, addTeamNoteData))
			setApiCallMade(true)
		}
		initTooltips()
		return () => {
		}
	}, [])

	useEffectHelper.useNotificationReplyScrollBarEffect(textareaRef, addTeamNoteData, setAddTeamNoteData)

	return (
		<React.Fragment>
		
			{patientListNotificationsResponse?.loading && addTeamNoteData?.initalListLoading ?
				<div className="mt-4 pt-4 block w-full bg-white border border-gray-200 rounded-lg border dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
					<PatientNotificationLoader />
				</div>
				: patientNotificationsList?.data?.length <= 0 ?
					<div className="mt-4 block w-full bg-white border border-gray-200 rounded-lg border dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
						<h2 className='px-3 py-0 pb-4 pt-4'>There are currently no notes available</h2>
					</div>
					:
					patientListNotificationsResponse?.error !== null ? null :
						<>
							<div className="mt-4 pt-4 block w-full bg-white border border-gray-200 rounded-lg border dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

								{patientNotificationsList?.data?.slice(0, 5)?.map((value, index) => {
									return (<React.Fragment key={index}>
										<div className='px-3 py-0' >
											<div className="borderLeftBg  p-3 bg-white rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-2 " style={{ background: value.color }}>
												<div className="flex items-center">
													<div>
														<p className="font-normal text-gray-700 dark:text-gray-400 rubik-500 fs-14">Note : {value.patient_name},
															{value.route && <>&nbsp; Route: {value.route}</>} &nbsp;
															PHN : {value.phn}</p>
														{/* <p className="rubik-400 fs-12">{value.notes}</p> */}
														<div className="rubik-400 fs-12" dangerouslySetInnerHTML={{ __html: value.notes }} />

														<p className="rubik-400 fs-12">Added By : {value.added_by}</p>
													</div>
													<div className="ml-auto flex items-center gap-2">
														<a className="relative  py-2.5 "
															onMouseLeave={() => setAddTeamNoteData({ ...addTeamNoteData, tooltipVisible: false, notificationId: null })}
															onMouseEnter={() => setAddTeamNoteData({ ...addTeamNoteData, tooltipVisible: true, notificationId: value.note_id })}
															onClick={() => {
																return setAddTeamNoteData({ ...addTeamNoteData, notificationId: value.note_id }),
																	dispatch(pharmacyTeamNotes.patientNoteMarkAsRead(value.note_id, addTeamNoteData.pageNumber))
															}}>

															{patientNoteMarkAsRead?.loading && addTeamNoteData?.notificationId === value.note_id ?
																<p className='blueBg-color h-8 w-8 flex items-center justify-center btnnn text-white p-1 rounded-md'><Loader /></p> :
																<div className='w-6'>
																	<img src={images.done_ic} className="blueBg-color btnnn text-white p-1 rounded-md " alt="" />
																</div>
															}

															{addTeamNoteData?.tooltipVisible && addTeamNoteData?.notificationId === value.note_id && (
																<div style={{ background: 'rgb(89 101 206)' }} className="absolute  items-center justify-center -mt-10 -ml-8  w-24 pl-3 fs-12 z-10 visible  px-1 py-2 ml-0 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-100 tooltip dark:bg-gray-700">
																	Mark as Read
																	<div className="tooltip-arrow ml-8" data-popper-arrow></div>
																</div>
															)}

														</a>

														<div className='w-6'>
															<a onClick={(() => setAddTeamNoteData({ ...addTeamNoteData, notificationIndex: index }))}>
																<img src={images.reply_ic} className="blueBg-color text-white p-1 btnnn rounded-md" alt="" /></a>
														</div>

													</div>
												</div>
											</div>

											{addTeamNoteData?.notificationIndex === index &&
												<>
													{value?.reply?.map((data, index) => {
														const dateTime = new Date(data?.date_time);
														const formattedDateTime = `@ ${dateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })} on ${dateTime.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}`;
														return (
															<div className="mt-2 mb-2 ml-6  p-3 bg-white rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" key={index}>
																<div>
																	<p className="font-normal text-gray-700 dark:text-gray-400 rubik-500 fs-14">{data?.notes}</p>
																	<p className="rubik-400 fs-12">{data?.added_by}&nbsp;{formattedDateTime}</p>
																</div>
															</div>
														)
													})}

													<div className="lightBgColor p-2 rounded-md mt-2 mb-2">
														<textarea key={index}
															id={`message_${index}`} ref={textareaRef} rows="5" value={addTeamNoteData?.addReply}
															onChange={(e) => setAddTeamNoteData({ ...addTeamNoteData, addReply: e.target.value })} className="scrollToTop rubik-400 fs-16 h-20 resize-none block p-2.5 w-full text-sm text-gray-900 bg-white rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Type your reply here..."></textarea>
														<div className="mt-2">
															{pharmacyPatientAddNoteReply?.error !== null &&
																<Errors error='Reply Not added please try to add again.' />
															}
															<button onClick={(() => Helper.handalPatientNotesreply(value,
																addTeamNoteData,
																setAddTeamNoteData({ ...addTeamNoteData, initalListLoading: false, addReply: '' }),
																dispatch))}

																type="button" className="rubik-500 text-white fs-14 blueBg-color rounded-md text-sm px-3 py-1.5 mr-1">
																{pharmacyPatientAddNoteReply?.loading ?
																	<p className='flex'>Adding...</p>
																	:
																	'Add Reply'
																}
															</button>

															<button onClick={() => setAddTeamNoteData({ addReply: '' })} type="button" className="rubik-500 text-white fs-14 redBg-color rounded-md text-sm px-3 py-1.5">Cancel</button>
														</div>
													</div>

												</>}
										</div>
									</React.Fragment>)
								})}
							</div>
						</>}
		</React.Fragment>
	)
}

export default PatinentNotesNotification