import {
	PHARMACY_ARCHIVED_DELVERIES_LIST_REQUEST,
	PHARMACY_ARCHIVED_DELVERIES_LIST_SUCCESS,
	PHARMACY_ARCHIVED_DELVERIES_LIST_FAILURE
} from '../../../constants';
import { patient_archieved_deliveries } from '../../../../networking/urlEndPoints';

import Axios from '../../../../networking/intraceptor'

export const pharmacyArchivedDelveriesRequest = (types) => ({
	type: PHARMACY_ARCHIVED_DELVERIES_LIST_REQUEST,
	types: types
});

export const pharmacyArchivedDelveriesSuccess = (data, types) => ({
	type: PHARMACY_ARCHIVED_DELVERIES_LIST_SUCCESS,
	payload: data,
	types: types
});

export const pharmacyArchivedDelveriesFailure = (error, types) => ({
	type: PHARMACY_ARCHIVED_DELVERIES_LIST_FAILURE,
	payload: error,
	types: types
})

export const fetchAllDispenseArchivedDelveries = (formData, type, page, from_date) => {
	return async (dispatch) => {
		dispatch(pharmacyArchivedDelveriesRequest(type));
		try {
			const params = {
				from_date: from_date ? from_date : formData?.date,
				page: page,
				status: formData?.deliveryStatus?.value,
				route: formData?.patientRoutes?.label,
				search: formData?.searchPatients,
				todayrx: formData?.todayRx
			}
			const response = await Axios.get(`${patient_archieved_deliveries}`, { params })

			if (response?.status === 200) {
				dispatch(pharmacyArchivedDelveriesSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyArchivedDelveriesFailure(error?.response, type));
			}
		}
	}
}

export const fetchAccessCodeDetails = (setAccessCodeData) => {
	return async (dispatch) => {

		try {

			setAccessCodeData((prevStat) => ({ ...prevStat, loading: true, error: null }))
			const response = await Axios.get(`get-delivery-access-code`)

			if (response?.status === 200) {
				setAccessCodeData((prevStat) => ({ ...prevStat, loading: false, data: response?.data, error: null }))
			}

			else {
				throw new Error(response);
			}
		}

		catch (error) {
			if (error?.response) {
				setAccessCodeData((prevStat) => ({ ...prevStat, loading: false, data: null, error: error.response?.data }))
			}
		}
	}
}

