import {
	PHARMACY_COVERAGE_PATIENT_LIST_REQUEST,
	PHARMACY_COVERAGE_PATIENT_LIST_SUCCESS,
	PHARMACY_COVERAGE_PATIENT_LIST_FAILURE
} from '../../constants'

import { pharmacy_coverage_patient_list } from "../../../networking/urlEndPoints"

import Axios from "../../../networking/intraceptor"

export const coveragePatientsListRequest = () => ({
	type: PHARMACY_COVERAGE_PATIENT_LIST_REQUEST,
})

export const coveragePatientsListSuccess = (data) => ({
	type: PHARMACY_COVERAGE_PATIENT_LIST_SUCCESS,
	payload: data,
})

export const coveragePatientsListFailure = (error) => ({
	type: PHARMACY_COVERAGE_PATIENT_LIST_FAILURE,
	payload: error,
})

export const fetchAllPatientCoveragelist = (id, value) => {
	return async (dispatch) => {
		dispatch(coveragePatientsListRequest())
		try {
			const response = await Axios.post(`${pharmacy_coverage_patient_list}`, {
				patient_id: id,
				no_of_records: 10,
				page: value
			})
			if (response?.status === 200) {
				dispatch(coveragePatientsListSuccess(response?.data))
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(coveragePatientsListFailure(error?.response))
			}
		}
	}
}