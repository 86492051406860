import React, { useEffect, useRef } from 'react';
import { useCanvasContext } from '../hooks/useCanvasContext';
import { fabric } from 'fabric';
import { renderDeleteIcon } from '../utils/textUtils';

function CanvasCertificate({ jsonData = '' }) {
    const containerRef = useRef(null);
    const canvasRef = useRef(null); // Reference to the canvas element
    const { setCanvas } = useCanvasContext();

    useEffect(() => {
        const container = containerRef.current;
        const canvasElement = canvasRef.current;
        const canvas = new fabric.Canvas(canvasElement, {
            backgroundColor: '#ecf0f1',
        });

        setCanvas(canvas);

        const resizeCanvas = () => {
            const containerWidth = container.clientWidth - 300; // Decrease by 300px from each side, will see it later current 150
            const containerHeight = container.clientHeight - 300; // Decrease by 300px from each side, will see it later current 150

            // Calculate size maintaining 918x649 aspect ratio
            let canvasWidth = containerWidth;
            let canvasHeight = containerWidth * (649 / 918);

            if (canvasHeight > containerHeight) {
                canvasHeight = containerHeight;
                canvasWidth = containerHeight * (918 / 649);
            }

            canvas.setDimensions({ width: canvasWidth, height: canvasHeight });

            // Set zoom based on original canvas size (918x649 assumed)
            const scaleFactor = canvasWidth / 910;
            canvas.setZoom(scaleFactor);

            canvas.renderAll();
        };

        const resizeObserver = new ResizeObserver(() => {
            resizeCanvas();
        });

        resizeObserver.observe(container);

        // Initial resize
        resizeCanvas();
        if (jsonData != '') {
            // Load canvas from JSON data
            const json = jsonData;
            canvas.loadFromJSON(json, () => {
                canvas.getObjects('textbox').forEach(obj => addDeleteControlToText(obj, canvas));
                canvas.getObjects('image').forEach(obj => addDeleteControlToText(obj, canvas));
                canvas.renderAll();
            });
        }
        return () => {
            resizeObserver.unobserve(container);
            canvas.dispose();
        };
    }, [setCanvas]);


    const addDeleteControlToText = (textObject, canvas) => {
        textObject.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: -20,
            offsetX: 0,
            cursorStyle: 'pointer',
            mouseUpHandler: deleteObject,
            render: renderDeleteIcon,
        });

        function deleteObject(eventData, transform) {
            const target = transform.target;
            canvas.remove(target);
            canvas.requestRenderAll();
            return true;
        }

       
    };
    return (
        <div className="editor-container" ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div className="editor-canvas" style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <canvas ref={canvasRef} id="canvas"></canvas>
            </div>
        </div>
    );
}

export default CanvasCertificate;