import React, { useState } from 'react'
import { useCustomDispatch } from '../../../hooks'
import { HandleTransferPrescriptionViewSendFaxPDF } from '../../../services/actions/pharmacy/app/addAppData'
import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors'

import { ErrorToastMessage } from '../../loaders/toastMessage'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

import { handleHideModal, handleShowModal } from '../../../assets/js/custome'
import { ModalCloseIcon } from '../../../assets/svg/icons'

import { LoadingView } from '../../common'
import { ErrorMessages } from '../../error/alertMessages'
import { PharmacySendFaxLog } from '../../../models/pharmacy/sendFaxLog'

import Axios from '../../../networking/intraceptor';

export const ViewFaxLogHistory = ({ setShowModal, showModal, onClose = null, formData, loading }) => {

    const faxHistoryList = formData?.data

    const faxHistoryTableHeading = [
        { text: "File Name", classNames: ['p-3'] },
        { text: "Sent Status", classNames: ['p-3'] },
        { text: "Date Queued", classNames: ['p-3'] },
        { text: "Date Sent", classNames: ['p-3'] },
        { text: "To Fax Number", classNames: ['p-3'] },
        { text: "Pages", classNames: ['p-3'] },
        { text: "Duration", classNames: ['p-3'] },
        { text: "RemoteID", classNames: ['p-3'] },
        { text: "Size", classNames: ['p-3'] }
    ]

    return (
        <React.Fragment>

            {showModal.faxHistory && (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100" style={{ zIndex: '9999999' }}>
                    <div className="relative max-h-full" style={{ width: '40%' }}>
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h1 className='text-gray-700 rubik-500 fs-16'>
                                    Fax History
                                </h1>
                                <button
                                    onClick={() => handleHideModal(setShowModal, showModal, 'faxHistory')}
                                    type="button"
                                    className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								    text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <ModalCloseIcon />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            <div className="container -mt-4">

                                <div className="shadow-md relative overflow-x-auto p-5 ">
                                    {loading ?
                                        <div className='flex items-center justify-center mt-40 mb-40'>
                                            <LoadingView />
                                        </div> :
                                        formData.error !== null ?
                                            <ErrorMessages /> :
                                            formData.data !== null ?
                                                <>
                                                    <div className="flex flex-col gap-4 p-4">
                                                        {faxHistoryTableHeading?.map((header, index) => (
                                                            <div key={index} className="flex justify-between">
                                                                <div className="font-medium text-base">{header.text}</div>
                                                                <div className="text-gray-700 text-base">
                                                                    {header.text === 'File Name' && faxHistoryList?.data?.filename}
                                                                    {header.text === 'Sent Status' && faxHistoryList?.data?.status}
                                                                    {header.text === 'Date Queued' && faxHistoryList?.data?.date}
                                                                    {header.text === 'Date Sent' && faxHistoryList?.data?.d_sent}
                                                                    {header.text === 'To Fax Number' && faxHistoryList?.data?.fax}
                                                                    {header.text === 'Pages' && faxHistoryList?.data?.pages}
                                                                    {header.text === 'Duration' && faxHistoryList?.data?.duration}
                                                                    {header.text === 'RemoteID' && faxHistoryList?.data?.remote}
                                                                    {header.text === 'Size' && faxHistoryList?.data?.size}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {formData?.data?.document_url &&
                                                        <div className='w-full flex items-center justify-center'>
                                                            <button className="mt-4 px-3 py-1.5 bg-blue-500 text-white font-semi rounded">
                                                                <a href={formData?.data?.document_url} target='_blank'>
                                                                    Open Fax Document
                                                                </a>
                                                            </button>
                                                        </div>}
                                                </>
                                                : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </React.Fragment >
    )
}

export const TransferPrescriptionSendFax = ({ order_id }) => {

    const [formData, setFormData] = useState({ nameOfMedication: '', faxHistory: false, loading: false, data: null, error: null })
    const [errorMessages, setErrorMessages] = useState({ nameOfMedication: '' })
    const [showToast, setShowToast] = useState({ showToast: false, errorToast: false })
    const [loading, setLoading] = useState(false)

    const dispatch = useCustomDispatch()
    // const { pharmacyLoginData } = useCustomSelectors()

    const { AddBlisterPackResponse } = useBlisterSelectors()
    const sendFaxPDFResponse = AddBlisterPackResponse

    const handleChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, nameOfMedication: value });
    };

    const handleSaveFax = () => {
        if (!formData.nameOfMedication) {
            setErrorMessages({ ...errorMessages, nameOfMedication: 'Name of the Medication is required' })
        } else {
            setErrorMessages({ ...errorMessages, nameOfMedication: '' })
            const reqData = {
                order_id: order_id.id,
                medication_name: formData.nameOfMedication
            }
            dispatch(HandleTransferPrescriptionViewSendFaxPDF('viewFax', reqData, setShowToast))
        }
    }

    const handleFaxHistoryPreview = async () => {
        setFormData({ ...formData, loading: true, data: null, error: null, faxHistory: true })
        try {
            setLoading(true)
            const response = await Axios.get(`${'order-fax-history'}`, { params: { order_id: order_id?.id } })
            if (response?.status === 200) {
                setFormData({ ...formData, loading: false, data: response?.data?.data, error: null, faxHistory: true })
                setLoading(false)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                setLoading(false)
                setFormData({ ...formData, loading: false, data: null, error: error?.response?.data, faxHistory: true })
            }
        }
    }

    const handleSendFax = (id) => {
        const reqData = {
            order_id: id,
        }
        if (id) PharmacySendFaxLog(reqData, 'send-transfer-fax')
    }

    return (<React.Fragment>
        {order_id?.order_medication && order_id?.order_fax_history ? null : <>
            <div className="relative w-full mt-2">
                <input
                    type={'text'}
                    id={'nameOfTheMedication'}
                    autoComplete="off"
                    onChange={handleChange}
                    name={'nameOfMedication'}
                    value={formData.nameOfMedication}
                    className={`block pl-4 ${errorMessages.nameOfMedication && 'border-red-500'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none
                    dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text
                    bg-white `}
                    placeholder=""
                />
                <label
                    htmlFor={'nameOfTheMedication'}
                    className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2
                    origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2
                    peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
                >
                    {errorMessages.nameOfMedication ? '' : 'Name of the Medication'}
                </label>
                {errorMessages.nameOfMedication && <p className='text-red-500 fs-12'>{errorMessages.nameOfMedication}</p>}
            </div>

            <button type="button" onClick={handleSaveFax} className={` mt-2 bg-green-600 fs-14 rubik-400 text-white px-4 py-2 rounded-md`}>
                {sendFaxPDFResponse?.loading && sendFaxPDFResponse?.type === 'viewFax' ? 'Processing...' : 'Save & Fax Preview'}
            </button></>}

        {order_id?.order_medication &&
            // pharmacyLoginData?.srFax_active === 1 && pharmacyLoginData?.srFax_status === 1 && order_id?.orderMedication &&
            <button type="button" onClick={() => handleSendFax(order_id?.id)} className={`ml-2 mt-2 bg-blue-600 fs-14 rubik-400 text-white px-4 py-2 rounded-md`}>
                Send FAX
            </button>
        }

        {order_id?.order_medication && order_id?.order_fax_history &&
            <button onClick={() => { return handleFaxHistoryPreview(), handleShowModal(setFormData, formData, 'faxHistory') }} type="button" className={`ml-2 mt-2 bg-red-600 fs-14 rubik-400 text-white px-4 py-2 rounded-md`}>
                Fax History
            </button>
        }

        {showToast.errorToast && sendFaxPDFResponse.type === 'viewFax' && <ErrorToastMessage status={'Oops!. Got an issue while generating PDF.please try again.'} />}

        {formData.faxHistory &&
            <ViewFaxLogHistory showModal={formData} setShowModal={setFormData} formData={formData} loading={loading} />}

    </React.Fragment>
    );
}
