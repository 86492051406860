import React, {
    useEffect,
    useState,
    useCallback
} from "react";

import {
    BreadCrumb,
    Footer,
    LoadingView,
    Sidebar
} from "../../../components/common";

import { TextInput } from '../../../components/pharmacy/dispense/dispense'
import { ArchivedDelveriesDateRangePicker } from '../../../components/pharmacy/form/dateRangePicker'
import { ErrorMessages } from "../../../components/error/alertMessages";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";

import { SuccessToastMessage } from "../../../components/loaders/toastMessage";
import { BlisterDropDown } from "../../../components/pharmacy/form/form";

import { DeleteReminderModalPopUp } from "../../../models/notifications/notifications";
import { useCustomSelectors } from "../../../services/selectors/allSelectors";

import { FetchPatientReminderNotificationList } from '../../../services/actions/pharmacy/other/pharmacyPatientNotifications';
import { isDarkColor } from "./allNotifications";
import { UpdateNotes } from "../../../services/actions/patients/savePatientNote";

import ChatBox from "../../../components/chat/chatbox";

import * as Icons from "../../../assets/svg/icons";
import * as Hooks from '../../../hooks';
import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'
import * as Modals from "../../../assets/js/custome";

const ReminderNotifications = () => {

    const dispatch = Hooks.useCustomDispatch();
    const navigate = Hooks.useCustomNavigate();
    const location = Hooks.useCustomLocation();

    const startDateRef = React.useRef()
    const endDateRef = React.useRef()

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [reset, setReset] = useState(false);

    const { patientListNotificationsResponse } = useCustomSelectors()

    const searchParams = new URLSearchParams();
    const reminderList = patientListNotificationsResponse?.data?.data
    const notesList = patientListNotificationsResponse?.data?.data?.note

    const [showModal, setShowModal] = useState({ DeleteReminderModal: false, });
    const [formData, setFormData] = useState({ search: '', startDate: '', endDate: '', noteoption: '' })

    const [remId, setremId] = useState(null);

    const [addTeamNoteData, setAddTeamNoteData] = useState({
        notificationIndex: null,
        pageNumber: initialPage,
        addReply: '',
        showToast: false,
        initalListLoading: false,
        notificationId: null,
        tooltipVisible: false,
        startDate: null,
        endDate: null,
        selectedNoteOptions: null,
        patientNotesData: [],
        search: '',
        notes: ''
    });

    useEffect(() => {
        setAddTeamNoteData({ ...addTeamNoteData, pageNumber: initialPage })
    }, [initialPage])

    const handleReset = () => {
        searchParams.delete('page');
        setReset(true)
        setFormData({ ...formData, search: '', noteoption: '', startDate: '', endDate: '' })
        setTimeout(() => {
            setReset(false)
        }, 500);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);

        const params = {
            page: pageNumber,
            no_of_records: 10
        }

        dispatch(FetchPatientReminderNotificationList(params))

    }

    const handleFilterNotesList = (event, value) => {

        const params = {
            page: value || pageNumber,
            no_of_records: 10
        }

        if (formData.search) params.search = formData.search;
        if (formData.startDate) params.from_date = formData.startDate;
        if (formData.endDate) params.to_date = formData.endDate;
        if (formData.noteoption) params.noteoption = formData.noteoption;

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(FetchPatientReminderNotificationList(params))

    }

    useEffect(() => {
        const params = {
            page: addTeamNoteData.pageNumber
        }
        dispatch(FetchPatientReminderNotificationList(params))
        return () => { }
    }, [])


    useEffectHelper.useArchivedDelveriesDateRangePickerEffect(formData, setFormData, startDateRef, endDateRef)


    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedDispatch = useCallback(
        debounce((patientid, value) => {
            dispatch(UpdateNotes(patientid,
                addTeamNoteData.pageNumber,
                value,
                'reminder'))
        }, 2000),
        []
    );

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="timeSheets">
                                <div className="grid grid-cols-12 gap-4 w-full">

                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex gap-4 allNotificationSearchBg">
                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-12/12' style={{ width: '280px' }}>
                                                <TextInput label='Search' name='search' formData={formData}
                                                    setFormData={setFormData} />
                                            </div>
                                            <div className='flex flex-0' style={{ gridTemplateColumns: '2fr 2fr 7px 2fr 2fr' }}>
                                                <ArchivedDelveriesDateRangePicker
                                                    startDateRef={startDateRef}
                                                    endDateRef={endDateRef}
                                                    formData={formData}
                                                    // reset={reset}
                                                    setFormData={setFormData}

                                                />
                                            </div>
                                            <div className="relative z-10">
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name='noteoption'
                                                    value={formData.noteoption}
                                                    filterList={notesList}
                                                    reset={reset}
                                                    placeholder='Notes' />

                                            </div>

                                            <div className="relative  flex">
                                                <div className="flex justify-end">
                                                    <button type="button" onClick={handleFilterNotesList} style={{ backgroundColor: "#3B82F6", }}
                                                        className="flex py-4 items-center justify-center text-white bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                        Filter
                                                    </button>
                                                </div>

                                                <div className="flex justify-end">
                                                    <button type="button" style={{ backgroundColor: 'White', width: '70px', height: '43px', marginLeft: "13px" }} onClick={handleReset}
                                                        className="flex w-full h-full items-center justify-center text-gray-500 bg-white 
																border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
												                dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mt-2">
                                            <div className="">
                                                {patientListNotificationsResponse?.loading && patientListNotificationsResponse.type === 'FetchPatientReminderNotificationList' ?
                                                    <div className="flex items-center justify-center  mt-60 mb-80">
                                                        <LoadingView />
                                                    </div>
                                                    : patientListNotificationsResponse?.error && patientListNotificationsResponse.type === 'FetchPatientReminderNotificationList' !== null ?
                                                        <ErrorMessages />
                                                        : reminderList?.data?.length <= 0 ?
                                                            <h2>There are currently no notes available</h2> :
                                                            patientListNotificationsResponse.type === 'FetchPatientReminderNotificationList' ?
                                                                <div className="shadow-md border rounded-lg relative overflow-x-auto">
                                                                    <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
                                                                        <thead className="text-xs text-white uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400">
                                                                            <tr>
                                                                                <th scope="col" className="px-6 py-3 rounded-s-lg">
                                                                                    <p className="rubik-500 fs-14">Patient</p>
                                                                                </th>
                                                                                <th scope="col" className="px-6 py-3">
                                                                                    <p className="rubik-500 fs-14">Note</p>
                                                                                </th>

                                                                                <th scope="col" className="px-6 py-3">
                                                                                    <p className="rubik-500 fs-14">
                                                                                        Next Execeution Date</p>
                                                                                </th>

                                                                                <th scope="col" className="px-6 py-3 rounded-e-lg">
                                                                                    <p className="rubik-500 fs-14">Added By</p>
                                                                                </th>
                                                                                <th scope="col" className="px-6 py-3 rounded-e-lg">
                                                                                    <p className="rubik-500 fs-14"></p>
                                                                                </th>

                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            {reminderList?.data?.map((value, index) => {
                                                                                const textColor = value.color && isDarkColor(value.color) ? 'white' : 'black';
                                                                                return (<React.Fragment key={index}>

                                                                                    <tr className="bg-white dark:bg-gray-800 border-b dark:border-gray-700" style={{ background: value.color ? value.color : '#fff' , color: textColor }}>

                                                                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" style={{ color: textColor }}>
                                                                                            <p className="rubik-500 fs-14">{value?.patient}</p>
                                                                                            {value.route && <p className="rubik-500 fs-14">Route:{value?.route}</p>}
                                                                                            <p className="rubik-400 fs-14">PHN:{value?.phn}</p>
                                                                                        </th>

                                                                                        <td className="px-6 py-4">
                                                                                            <p className="rubik-400 fs-14">{value?.note}</p>
                                                                                        </td>

                                                                                        <td className="px-6 py-4">
                                                                                            <p className="rubik-400 fs-14">{value?.next_execution_time}</p>
                                                                                        </td>

                                                                                        <td className="px-6 py-4">
                                                                                            <p className="rubik-400 fs-14">{value?.added_by}</p>
                                                                                        </td>

                                                                                        <td className="px-6 py-4 flex">
                                                                                            <div className='bg-red-100 p-1 rounded-md btnnn' onClick={() => {
                                                                                                return (
                                                                                                    setremId(value.id),
                                                                                                    Modals.handleShowModal(
                                                                                                        setShowModal,
                                                                                                        showModal,
                                                                                                        "DeleteReminderModal"
                                                                                                    )
                                                                                                );
                                                                                            }}>
                                                                                                <Icons.DeleteIcon style={{ Width: '25px' }} color="red" />

                                                                                        
                                                                                            </div>

                                                                                            <label className="ml-2">
                                                                                                    <input
                                                                                                        type="color"
                                                                                                        defaultValue={value.color ? value.color : '#FFFFFF'}
                                                                                                        // onChange={(event) => )}
                                                                                                        className="w-6"
                                                                                                        onChange={(e) => {
                                                                                                            debouncedDispatch(value.id,
                                                                                                                e.target.value,
                                                                                                                null);
                                                                                                        }}
                                                                                                    />
                                                                                                </label>

                                                                                        </td>
                                                                                    </tr>

                                                                                </React.Fragment>)
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div> : null}

                                                {patientListNotificationsResponse?.loading ? null :
                                                    patientListNotificationsResponse?.error !== null ? null : <>
                                                        {reminderList?.data?.length !== 0 && <>
                                                            {reminderList?.pagination?.last_page !== 1 &&
                                                                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                    <nav aria-label="Page navigation example">
                                                                        <ul className="inline-flex -space-x-px text-base h-10">
                                                                            <CustomPagination
                                                                                count={reminderList?.pagination?.last_page}
                                                                                page={addTeamNoteData?.pageNumber}
                                                                                onChange={handleFilterNotesList}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            }
                                                        </>}
                                                    </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />
            <Footer />
            <DeleteReminderModalPopUp
                showModal={showModal}
                remID={remId}
                onClose={() =>
                    Modals.handleHideModal(setShowModal, showModal, "DeleteReminderModal")
                }
                setShowModal={setShowModal}
                formData={formData}
                pageNumber={pageNumber}
            />
            {showModal?.showToast && <SuccessToastMessage status='Deleted sucessfully' />}
            {addTeamNoteData.showToast && <SuccessToastMessage status={'Note Reply added successfully'} />}
        </>
    )
}

export default ReminderNotifications;