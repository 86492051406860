import {
    ADD_ARV_DATA_REQUEST,
    ADD_ARV_DATA_SUCCESS,
    ADD_ARV_DATA_FAILURE,
} from "../../../constants";

import {
    showToastMessage,
    hideToastMessage,
} from "../../../../assets/js/custome";

import { add_arv_details } from "../../../../networking/urlEndPoints";

import Axios from "../../../../networking/intraceptor";

export const addARVDataRequest = () => ({
    type: ADD_ARV_DATA_REQUEST,
});

export const addARVDataSuccess = (data) => ({
    type: ADD_ARV_DATA_SUCCESS,
    payload: data,
});

export const addARVDataFailure = (error) => ({
    type: ADD_ARV_DATA_FAILURE,
    payload: error,
});

export const addARVFormData = (ARVFormData, showToast, setShowToast, formattedDrugsData) => {
    return async (dispatch) => {
        dispatch(addARVDataRequest());
        let dataTobeSent = "";

        if (ARVFormData.fetchType === "local") {
            dataTobeSent = {
                fetchtype: "local",
                patient: ARVFormData.patientName.value,
                patientphn: ARVFormData.phn,
                patientdob: ARVFormData.dob,
                date: ARVFormData.date,
                drug: formattedDrugsData,
            };
        } else {
            dataTobeSent = {
                fetchtype: "winrx",
                winrxpatientfirstname: ARVFormData.winrxPatientFirstName,
                winrxpatientlastname: ARVFormData.winrxPatientLastName,
                winrxpatientdob: ARVFormData.dob,
                date: ARVFormData.date,
                drug: formattedDrugsData,
                winrxpatientphn: ARVFormData.phn,
                winrxpatientname: ARVFormData.name,
            };
        }

        try {
            const response = await Axios.post(`${add_arv_details}`, JSON.stringify(dataTobeSent));

            if (response?.status === 200) {
                dispatch(addARVDataSuccess(response?.data));

                showToastMessage(showToast, setShowToast);

                setTimeout(() => {
                    hideToastMessage(showToast, setShowToast);
                }, 2000);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addARVDataFailure(error?.response));
            }
        }
    };
};
