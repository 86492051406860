import React, {
	useEffect,
	useState
} from 'react'

import { useDispatch } from 'react-redux'
import { useCustomLocation } from '../../../hooks'

import { fetchPatientsList } from '../../../services/actions/patients/patientsList'
import { useCustomLoadingStates } from '../../../hooks/customStates'

import AllPatients from './allPatientsInfo'

const AllPatientsList = () => {

	const location = useCustomLocation()
	const dispatch = useDispatch()

	const { apiCallMade, setApiCallMade } = useCustomLoadingStates()
	const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

	const [paginationNumber, setPaginationNumber] = useState(initialPage)

	useEffect(() => {
		if (initialPage) {
			setPaginationNumber(initialPage)
		}
	}, [initialPage])

	useEffect(() => {
		const queryStatus = new URLSearchParams(location.search).get('status');
		const querySearch = new URLSearchParams(location.search).get('search');
		
		if (initialPage && queryStatus && !apiCallMade) {
			dispatch(fetchPatientsList(querySearch, queryStatus, initialPage));
		} else {
			dispatch(fetchPatientsList(querySearch, null, initialPage));
		}
	}, [location]);

	return (
		<React.Fragment>
			<AllPatients paginationNumber={paginationNumber} setPaginationNumber={setPaginationNumber} />
		</React.Fragment>
	)
}

export default AllPatientsList