import React, { useEffect, useState } from 'react'

import {
    ErrorToastMessage,
    SuccessToastMessage
} from '../../../components/loaders/toastMessage'

import { useCustomDispatch } from '../../../hooks'

import { DropdownSelect } from '../../../components/pharmacy/dispense/dispense'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

import { useAdminSelectors } from '../../../services/selectors/adminModuleSelectors'
import { fetchAllChatUserList } from '../../../services/actions/chat/allUserListActions'
import { onhandleValidateTaskMangerForm } from '../../../utils/validations/adminModuleValidations'

import * as Common from '../../../components/common'
import ChatBox from '../../../components/chat/chatbox'
import Button from '../../../components/common/button'
import TeamSchedule from './teamSchedule'

export const AssignTaskSelect = ({ formData, setFormData, errorMessage, employeeList, profileData, placeholder = 'Assign To', padding = 'pb-2.5 pt-4' }) => {
    const dispatch = useCustomDispatch()

    useEffect(() => {
        if (employeeList?.length === 0 || employeeList === undefined) {
            dispatch(fetchAllChatUserList())
        }
    }, [])

    return (
        <div className="relative w-full">
            <select
                id="assignTask"
                name="assignTask"
                value={formData.assignTask}
                placeholder="Assign Task"
                onChange={(e) => setFormData({ ...formData, assignTask: e.target.value })}
                className={`block ${errorMessage.assignTask && 'border-red-500'} scroll-box pl-4 w-full px-2.5 ${padding}  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
          dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
          bg-white `}
            >
                <option value="" disabled>{placeholder}</option>
                {employeeList?.map((option, index) => (
                    <React.Fragment key={index}>
                        {profileData?.user_id !== option.user_id && (
                            <option value={option.user_id}>
                                {option.name}
                            </option>
                        )}
                    </React.Fragment>
                ))}
            </select>
        </div>
    );
};

const CreateTaskManger = () => {

    const dispatch = useCustomDispatch()

    const { chatUserList, profileData } = useCustomSelectors()
    const { addTeamDataResponse } = useAdminSelectors()

    const employeeList = chatUserList?.data?.data

    const [formData, setFormData] = useState({ title: '', descripition: '', taskStatus: 'pending', assignTask: '' })
    const [errorMessage, setErrorMessages] = useState({ title: '', descripition: '', assignTask: '' })
    const [showToast, setShowToast] = useState({ successToast: '', errorToast: '' })

    const handleValidateTaskMangerForm = () => {
        onhandleValidateTaskMangerForm(formData, setErrorMessages, errorMessage, dispatch, setShowToast)
    }

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="allRequest">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className='grid grid-cols-3 gap-2'>
                                            <div className="relative w-full">
                                                <input type={'text'} id={'title'} autoComplete="off"
                                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                                    name={'title'}
                                                    value={formData.title}
                                                    className={`block pl-4 ${errorMessage.title && 'border-red-500'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                                    dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                    bg-white `}
                                                    placeholder=""
                                                />
                                                <label htmlFor={'title'}
                                                    className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                                                    origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                                                    peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                                    Title
                                                </label>
                                            </div>

                                            <DropdownSelect
                                                label=""
                                                name="taskStatus"
                                                formData={formData}
                                                setFormData={setFormData}
                                                options={[
                                                    { value: "pending", label: "Pending" },
                                                    { value: "ongoing", label: "Ongoing" },
                                                    // { value: "completed", label: "Completed" },
                                                    { value: "priority", label: "Priority" },
                                                    { value: "discussion", label: "Discussion" },
                                                ]}
                                            />

                                            <AssignTaskSelect
                                                formData={formData}
                                                setFormData={setFormData}
                                                errorMessage={errorMessage}
                                                employeeList={employeeList}
                                                profileData={profileData}
                                            />
                                        </div>

                                        <div className='grid grid-cols-1'>
                                            <div className='mt-2'>
                                                <textarea
                                                    defaultValue={formData.descripition}
                                                    style={{ border: errorMessage.descripition }}
                                                    name='descripition'
                                                    placeholder='Description'
                                                    onChange={(e) => setFormData({ ...formData, descripition: e.target.value })}
                                                    className={`fs-16 ${errorMessage.descripition && 'border-red-500'} w-full pl-2 h-96 rounded-md rubik-400 border border-gray-300 text-gray-700`}
                                                    id="descripition"
                                                />
                                            </div>
                                        </div>

                                        <div className='mt-2'>
                                            <Button color='white' width='100px'
                                                text={addTeamDataResponse?.loading && addTeamDataResponse.type === 'add_new_task' ? 'Processing...' : 'Submit'}
                                                handleFilter={() => addTeamDataResponse?.loading && addTeamDataResponse.type === 'add_new_task' ? null : handleValidateTaskMangerForm()} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <TeamSchedule /> */}

            {showToast.successToast && addTeamDataResponse.type === 'add_new_task' && <SuccessToastMessage status={'Task added successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'add_new_task' && <ErrorToastMessage status={'Got an issue while adding task.please try again.'} />}

            <ChatBox />
        </>
    )
}

export default CreateTaskManger