export const mediCationsData = [{
	id: 1,
	name: 'Kadian'
}, {
	id: 2,
	name: 'Metadol-D'
}, {
	id: 3,
	name: 'Methadone (Sterinova)'
}, {
	id: 4,
	name: 'Methadose'
}, {
	id: 5,
	name: 'DILAUDID'
}, {
	id: 6,
	name: 'Other'
}]