import {
	PHARMACY_ADD_TEAM_NOTES_REQUEST,
	PHARMACY_ADD_TEAM_NOTES_SUCCESS,
	PHARMACY_ADD_TEAM_NOTES_FAILURE,
} from "../../../constants";

const initialState = {
	data: null,
	loading: false,
	error: null,
};

const pharmacyAddTeamNotesReducer = (state = initialState, action) => {
	switch (action.type) {
		case PHARMACY_ADD_TEAM_NOTES_REQUEST:
		return {
			...state,
			loading: true,
			data: null,
			error: null,
		};
		case PHARMACY_ADD_TEAM_NOTES_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
			case PHARMACY_ADD_TEAM_NOTES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null,
			};
		default:
			return state;
	}
};

export default pharmacyAddTeamNotesReducer;
