import React, { useState } from 'react'
import { ModalCloseIcon } from '../../assets/svg/icons'
import { hideAddClinicModal } from '../../assets/js/custome'
import { onPlaceAddClinic } from '../../assets/js/maps'

import { autoSearchKey } from '../../networking/urlEndPoints'
import { usePlacesWidget } from 'react-google-autocomplete';
import { useCustomPatientSelectors } from '../../services/selectors/patientSelectors'
import { handleChangeAddClinicFormData } from '../../utils/data/handleFormDataChange'
import { initalClinicFormData } from '../../utils/constants/formInitialStates'
import { handleSaveClinicData } from '../../utils/validations/others'
import { useCustomDispatch } from '../../hooks'
import { Errors, SuccessMessage } from '../../components/error/alertMessages'
import { useCustomSelectors } from '../../services/selectors/allSelectors'

const AddClinic = ({ setShowModal, showModal }) => {

	const { provinceList } = useCustomPatientSelectors()
	const { addClinicResponse } = useCustomSelectors()

	const dispatch = useCustomDispatch()

	const [formData, setFormData] = useState(initalClinicFormData)
	const [errorMessages, setErrorMessages] = useState(initalClinicFormData)

	const { ref: ref1 } = usePlacesWidget({
		apiKey: autoSearchKey,
		onPlaceSelected: (place) => onPlaceAddClinic(place, formData, setFormData),
		options: {
			types: ["hospital", "health", "point_of_interest"],
			componentRestrictions: { country: "ca" },
			limit: 7,
			googleLogo: false
		},
	})

	return (
		<React.Fragment>
			<div className="overflow-y-auto overflow-x-hidden fixed top-0 left-0 right-0 bottom-0 z-50 flex  items-center 
							justify-center w-full bg-black bg-opacity-50 dark:bg-opacity-100 max-h-full">
				<div className="relative p-4 w-full max-w-2xl max-h-full">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
							<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
								Add Clinic
							</h3>
							<button type="button" onClick={() => hideAddClinicModal(setShowModal, showModal)}
								className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
								<ModalCloseIcon />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="p-4 md:p-5 space-y-4">
							<form>
								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<input ref={ref1}
												type="text"
												id="fullAddress"
												// onChange={(e) => handleChangeAddClinicFormData(e, formData, setFormData, setErrorMessages, errorMessages)}
												// name="fullAddress"
												// value={formData.fullAddress}
												className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
												placeholder=""
											/>
											<label
												htmlFor="fullAddress"
												className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
												Search Address
											</label>
										</div>
									</div>

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<input
												type="text"
												id="clinicName"
												onChange={(e) => handleChangeAddClinicFormData(e, formData, setFormData, setErrorMessages, errorMessages)}
												name="clinicName"
												value={formData.clinicName}
												className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.clinicName && 'border-red-600'
													}`}
												placeholder=""
											/>
											<label
												htmlFor="clinicName"
												className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
												{!errorMessages.clinicName && 'Clinic Name'}
											</label>
											{errorMessages.clinicName && (
												<p className="mt-2 text-xs text-red-600 dark:text-red-400">
													{errorMessages.clinicName}
												</p>
											)}
										</div>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<input
												type="text"
												id="lastName"
												onChange={(e) => handleChangeAddClinicFormData(e, formData, setFormData, setErrorMessages, errorMessages)}
												name="streetName"
												value={formData.streetName}
												className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
												placeholder=""
											/>
											<label
												htmlFor="lastName"
												className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
												Street
											</label>
										</div>
									</div>

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<div className="relative">

												<div className=" flex">
													<input type="text"
														onChange={(e) => {
															const formattedValue = e?.target?.value?.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
															setFormData({ ...formData, phone: formattedValue });
															setErrorMessages({ ...errorMessages, phone: '' })

														}}
														name="phone"
														value={formData.phone.slice(0, 12)

														}

														style={{ height: '48px' }} className={`focus:ring-0 rounded-l-lg outline-0 focus:border-gray-300 border border-gray-300 text-gray-900 text-sm block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white border-r-0 ${errorMessages.phone && 'border-red-600'}`} placeholder="XXX-XXX-XXXX" pattern="\d{3}-\d{3}-\d{4}"
													/>

													<label htmlFor="phone" className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
														{!errorMessages.phone && 'Phone'}
													</label>

													<input type="number" style={{ height: '48px' }}
														onChange={(e) => handleChangeAddClinicFormData(e, formData, setFormData, setErrorMessages, errorMessages)}
														name="phoneExtension"
														value={formData.phoneExtension}
														className={`focus:ring-0  rounded-r-lg outline-0 focus:border-gray-300 border text-center border-gray-300 text-gray-900 text-sm block w-20 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${errorMessages.phoneExtension && 'border-red-600'}`} placeholder="Extension" />
												</div>
												{(errorMessages.phone) || (errorMessages.phoneExtension) ? (
													<p className="mt-2 text-xs text-red-600 dark:text-red-400">
														{errorMessages.phone}
													</p>
												) : null}

											</div>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<input
												type="text"
												id="fax"
												onChange={(e) => {
													const formattedValue = e?.target?.value?.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
													setFormData({ ...formData, fax: formattedValue });
													setErrorMessages({ ...errorMessages, fax: '' })

												}}
												name="fax"
												value={formData.fax.slice(0, 12)}
												className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.fax && 'border-red-600'
													}`}
												placeholder=""
											/>
											<label
												htmlFor="fax"
												className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
												{!errorMessages.fax && 'Fax'}
											</label>
											{errorMessages.fax && (
												<p className="mt-2 text-xs text-red-600 dark:text-red-400">
													{errorMessages.fax}
												</p>
											)}
										</div>
									</div>

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<input
												type="text"
												id="city"

												onChange={(e) => handleChangeAddClinicFormData(e, formData, setFormData, setErrorMessages, errorMessages)}

												name="city"
												value={formData.city}
												className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.city && 'border-red-600'
													}`}
												placeholder=""
											/>
											<label
												htmlFor="city"
												className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
												{!errorMessages.city && 'City'}
											</label>
											{errorMessages.city && (
												<p className="mt-2 text-xs text-red-600 dark:text-red-400">
													{errorMessages.city}
												</p>
											)}
										</div>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<select style={{ height: '48px', paddingLeft: '20px' }}
												className="fs-14 rounded-lg rubik-400 did-floating-select border border-gray-300 text-gray-900"
												onChange={(e) => handleChangeAddClinicFormData(e, formData, setFormData, setErrorMessages, errorMessages)}
												defaultValue={formData.province} name="province"
											>
												{provinceList?.data?.map((value, index) => {
													return (<option className="fs-14" value={value.short_name} key={index}>{value.long_name}</option>)
												})}
											</select>

										</div>
									</div>

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<input
												type="text"
												onChange={(e) => handleChangeAddClinicFormData(e, formData, setFormData, setErrorMessages, errorMessages)}
												name="zip"
												value={formData.zip}
												id="zip"
												className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
												placeholder=""
											/>
											<label
												htmlFor="lastName"
												className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
												Zip
											</label>
										</div>
									</div>
								</div>

								{addClinicResponse?.data !== null ?
									<SuccessMessage message='Clinic Added Sucessfully' /> :
									addClinicResponse?.error !== null ?
										<Errors error='Something went wrong when adding the clinic please try again later' /> : null}

								<div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
									<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
										<div className="col-span-12">
											<button type="button" data-ripple-light="true" onClick={() => handleSaveClinicData(formData,
												setErrorMessages, errorMessages, dispatch,
												ref1?.current?.value,
												setShowModal, showModal)}
												className={`rubik-500 flex justify-center items-center text-white blueBg-color   w-full text-sm px-5 py-2.5 text-center`}>
												{addClinicResponse?.loading ? 'Saving' : 'Submit'}
											</button>
										</div>
									</div>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>

		</React.Fragment>
	)
}

export default AddClinic