import {
	UPDATE_PATIENTS_PROFILE_REQUEST,
	UPDATE_PATIENTS_PROFILE_SUCCESS,
	UPDATE_PATIENTS_PROFILE_FAILURE
} from "../../../constants";

const initialState = {
	loading: false,
	data: null,
	error: null,
}

const updatePatientProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_PATIENTS_PROFILE_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				data: null,
			};
		case UPDATE_PATIENTS_PROFILE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			}
		case UPDATE_PATIENTS_PROFILE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null
			}
		default:
			return state;
	}
}
export default updatePatientProfileReducer