import {
	PHARMACY_ALL_DISPENSE_LIST_DATA_REQUEST,
	PHARMACY_ALL_DISPENSE_LIST_DATA_SUCCESS,
	PHARMACY_ALL_DISPENSE_LIST_DATA_FAILURE

} from '../../../constants';

const initialState = {
	data: null,
	loading: false,
	error: null,
	type: null
};

const dispenseListDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case PHARMACY_ALL_DISPENSE_LIST_DATA_REQUEST:
			return {
				...state,
				loading: true,
				data: null,
				error: null,
				type: action.types
			};
		case PHARMACY_ALL_DISPENSE_LIST_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
				type: action.types
			};
		case PHARMACY_ALL_DISPENSE_LIST_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null,
				type: action.types
			};
		default:
			return state;
	}
};

export default dispenseListDataReducer;
