import React, { useEffect, useState } from 'react'

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'

import { addInventoryFailure } from '../../services/actions/pharmacy/Inventory/addInventoryData';
import { TextInput } from '../../components/pharmacy/dispense/dispense';

import { handleUpdateShipingBillingAddress } from '../../services/actions/pharmacy/app/addAppData';
import { useBlisterSelectors } from '../../services/selectors/blisterSelectors';
import { ErrorToastMessage, SuccessToastMessage } from '../../components/loaders/toastMessage';

import * as Hooks from '../../hooks'

const UpdateShipping_Billing_AddressModal = ({ setShowModal, showModal, viewOrderDetail, orderDetails }) => {

    const validateField = (fieldName, value, requiredMessage) =>
        !value || value.length === 0 ? requiredMessage : "";

    const dispatch = Hooks.useCustomDispatch()
    const initalData = {
        address1: '',
        address2: '',
        city: '',
        postal_code: '',
        state: '',
        country: ''
    }

    const { AddBlisterPackResponse } = useBlisterSelectors()
    const updateAddressResponse = AddBlisterPackResponse

    const [formData, setFormData] = useState(initalData);
    const [errorMessages, setErrorMessages] = useState(initalData)
    const [showToast, setShowToast] = useState({ showToast: false, errorToast: false })

    const [isChecked, setIsChecked] = useState(0)

    useEffect(() => {

        const isBilling = viewOrderDetail.addressType === 'Billing';

        if (isBilling || viewOrderDetail.addressType === 'Shipping') {
            const { address_1, address_2, city, postcode, state, country } = viewOrderDetail?.detail?.billing_address
            const shippingAddress = viewOrderDetail?.detail?.shipping_address
            setFormData({
                ...formData,
                address1: isBilling ? address_1 : shippingAddress.address_1,
                address2: isBilling ? address_2 : shippingAddress.address_2,
                city: isBilling ? city : shippingAddress.city,
                postal_code: isBilling ? postcode : shippingAddress.postcode,
                state: isBilling ? state : shippingAddress.state,
                country: isBilling ? country : shippingAddress.country,
            })
        }
    }, [viewOrderDetail])

    const handleUpdateAddress = () => {
        const specificValidations = {
            address1: 'Address 1 is required',
            city: 'City is required',
            postal_code: 'Postal Code is required',
            state: 'State is required',
            country: 'Country is required'
        };

        const allValidations = { ...specificValidations };

        const newErrorMessages = Object.fromEntries(
            Object.entries(allValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        );
        setErrorMessages({ ...errorMessages, ...newErrorMessages, });
        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

        if (allDataPresent) {

            const { addressType } = viewOrderDetail;

            const reqData = {
                type: addressType === 'Billing' ? 'billing' : 'shipping',
                // order_id: orderDetails?.id,
                address_1: formData.address1,
                address_2: formData.address2,
                city: formData.city,
                postcode: formData.postal_code,
                country: formData.country,
                state: formData.state,
                address_id: addressType === 'Billing' ? viewOrderDetail?.detail?.billing_address?.id : viewOrderDetail?.detail?.shipping_address?.id,
                // same_type: addressType === 'Billing' ? '' : isChecked,
                // billing_id: addressType === 'Billing' ? '' : billing_add_id
            };

            dispatch(handleUpdateShipingBillingAddress('update_address', reqData, setShowToast, setShowModal,orderDetails?.id));
        }
    }

    return (
        <React.Fragment>
            <div className="overflow-y-auto overflow-x-hidden fixed top-0 left-0 right-0 bottom-0 z-50 flex  items-center 
						justify-center w-full bg-black bg-opacity-50 dark:bg-opacity-100 max-h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Update {viewOrderDetail.addressType} Address
                            </h3>
                            <button type="button" onClick={() => { return dispatch(addInventoryFailure(null, null)), handleHideModal(setShowModal, showModal, 'addressModal') }}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <>
                                <form>

                                    <div className='grid grid-cols-2 gap-2'>
                                        <div>
                                            <TextInput formData={formData} setFormData={setFormData} label='Address 1' height='48px' name='address1' />
                                            {errorMessages.address1 && <p className='text-red-500 fs-12'>{errorMessages.address1}</p>}
                                        </div>
                                        <div>
                                            <TextInput formData={formData} setFormData={setFormData} label='Address 2' height='48px' name='address2' />
                                        </div>
                                    </div>

                                    <div className='grid grid-cols-2 gap-2 mt-2'>
                                        <div>
                                            <TextInput formData={formData} setFormData={setFormData} label='City' height='48px' name='city' />
                                            {errorMessages.city && <p className='text-red-500 fs-12'>{errorMessages.city}</p>}
                                        </div>
                                        <div>

                                            <TextInput formData={formData} setFormData={setFormData} enable={true} label='Postal Code' height='48px' name='postal_code' />
                                            {errorMessages.postal_code && <p className='text-red-500 fs-12'>{errorMessages.postal_code}</p>}

                                        </div>
                                    </div>

                                    <div className='grid grid-cols-2 gap-2 mt-2'>
                                        <div>
                                            <TextInput formData={formData} setFormData={setFormData} enable={true} label='State' height='48px' name='state' />
                                            {errorMessages.state && <p className='text-red-500 fs-12'>{errorMessages.state}</p>}
                                        </div>
                                        <div>
                                            <TextInput formData={formData} setFormData={setFormData} enable={true} label='Country' height='48px' name='country' />
                                            {errorMessages.country && <p className='text-red-500 fs-12'>{errorMessages.country}</p>}
                                        </div>
                                    </div>

                                    {/* {viewOrderDetail?.addressType === 'Shipping' &&
                                        <div className='grid grid-cols-2 gap-2 mt-2'>
                                            <label className="inline-flex items-center cursor-pointer">
                                                <input type="checkbox" value={isChecked} checked={isChecked === 1} onChange={(event) => setIsChecked(event.target.checked ? 1 : 0)} className="sr-only peer" />
                                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 text-gray-700 pl-2">Use This Address For Billing </span>
                                            </label>
                                        </div>} */}

                                    <div className='grid grid-cols-1 gap-2 mt-2'>
                                        <button type="button" onClick={handleUpdateAddress} className={` mt-2 bg-blue-500 fs-14 rubik-400 text-white px-4 py-2 rounded-md`}>
                                            {updateAddressResponse?.loading && updateAddressResponse?.type === 'update_address' ? 'Updating...' : 'Update'}
                                        </button>
                                    </div>
                                </form>
                            </>
                        </div>
                    </div>
                </div>
            </div>

            {showToast.showToast && updateAddressResponse.type === 'update_address' && <SuccessToastMessage status={'Updated Successfully'} />}
            {showToast.errorToast && updateAddressResponse.type === 'update_address' && <ErrorToastMessage status={'Oops!. Got an issue while updating address details.please try to update again.'} />}
        </React.Fragment>
    )
}

export default UpdateShipping_Billing_AddressModal