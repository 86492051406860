import { useSelector } from "react-redux";

export const useOATSelector = () => {
  const oatListResponse = useSelector(
    (state) => state.oatList
  );


  return {
    oatListResponse,
  };
};
