import {
	PHARMACY_PATIENT_NOTE_MARKSREAD_REQUEST,
	PHARMACY_PATIENT_NOTE_MARKSREAD_SUCCESS,
	PHARMACY_PATIENT_NOTE_MARKSREAD_FAILURE
} from '../../../constants';

const initialState = {
	data: null,
	loading: false,
	error: null,
};

const pharmacyPatientNoteMarkAsReadReducer = (state = initialState, action) => {
	switch (action.type) {
		case PHARMACY_PATIENT_NOTE_MARKSREAD_REQUEST:
		return {
			...state,
			loading: true,
			data: null,
			error: null,
		};
		case PHARMACY_PATIENT_NOTE_MARKSREAD_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
			case PHARMACY_PATIENT_NOTE_MARKSREAD_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null,
			};
		default:
			return state;
	}
};

export default pharmacyPatientNoteMarkAsReadReducer;
