import {
    LABEL_VALIDATE_INPUTS_REQUEST,
    LABEL_VALIDATE_INPUTS_SUCCESS,
    LABEL_VALIDATE_INPUTS_FAILURE
} from '../../../constants';

const initialState = {
    data: null,
    loading: false,
    error: null,
    type: null
};

const validateLabelInputReducer = (state = initialState, action) => {
    switch (action.type) {
        case LABEL_VALIDATE_INPUTS_REQUEST:
            return {
                ...state,
                loading: true,
                // data: null,
                error: null,
                type: action.types
            }
        case LABEL_VALIDATE_INPUTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                type: action.types,
                error: null
            };
        case LABEL_VALIDATE_INPUTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                type: action.types,
                data: null,
            };
        default:
            return state;
    }
};

export default validateLabelInputReducer;
