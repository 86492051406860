import {
	POST_COMMENTS_LISTS_REQUEST,
	POST_COMMENTS_LISTS_SUCCESS,
	POST_COMMENTS_LISTS_FAILURE
} from "../../constants";

import { pharmacy_user_comments_list } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/intraceptor";
import Cookies from "js-cookie";

export const fetchUserCommentsListsRequest = () => ({
	type: POST_COMMENTS_LISTS_REQUEST,
});

export const fetchUserCommentsListsSuccess = (comments) => ({
	type: POST_COMMENTS_LISTS_SUCCESS,
	payload: comments,
});

export const fetchUserCommentsListsFailure = (error) => ({
	type: POST_COMMENTS_LISTS_FAILURE,
	payload: error,
});

export const fetchAllCommentLists = (setLoading, setAppendedCommentsValue, postId, setCommentLoader) => {
	return async (dispatch) => {
		dispatch(fetchUserCommentsListsRequest());
		try {
			const response = await Axios.post(`${pharmacy_user_comments_list}/${postId}`);
			if (response?.status === 200) {
				dispatch(fetchUserCommentsListsSuccess(response?.data));
				if ((typeof setLoading === 'function')) {
					setLoading(false);
					setAppendedCommentsValue([])
				}
				if ((typeof setCommentLoader === 'function')) {
					setCommentLoader(false)
				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchUserCommentsListsFailure(error?.response));
			}
			if ((typeof setLoading === 'function')) {
				setLoading(false);
				setAppendedCommentsValue([])
			}
			if ((typeof setCommentLoader === 'function')) {
				setCommentLoader(false)
			}
		}
	}
}

export const fetchPaginationComments = async (postId, page, setCommentsData, comments, setViewMoreCommentLoader) => {
	try {
		const response = await Axios.post(`${pharmacy_user_comments_list}/${postId}?no_of_records=${10}&page=${page}`);

		if (page === response?.data?.data?.pagination?.last_page) {
			Cookies.set('more', 0)
		}

		setCommentsData([...comments, ...response?.data?.data?.data])

		if (typeof setViewMoreCommentLoader === 'function') {
			setViewMoreCommentLoader(false)
		}

	} catch (error) {
		if (typeof setViewMoreCommentLoader === 'function') {
			setViewMoreCommentLoader(false)
		}
	}
}

