import { useSelector } from 'react-redux'

export const useAdminSelectors = () => {
    
    const TeamListResponse = useSelector(state => state.adminTeamList)
    const addTeamDataResponse = useSelector(state => state.adminAddTeamData)
    const taskCommentsResponse = useSelector(state => state.adminTaskComments)

    return {
        TeamListResponse,
        addTeamDataResponse,
        taskCommentsResponse
    }
}
