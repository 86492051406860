import { combineReducers } from 'redux';

import * as encryptedConstant from '../../constants/rootReduceName'
import * as Auth from './auth'
import * as Chats from './chat'
import * as DefaultMain from './main'
import * as WallPosts from './posts'

import * as forms from './pharmacy/forms'
import * as PharmacyPatients from './pharmacy/patients'
import * as Clinic from './pharmacy/clinic'
import * as Doctors from './pharmacy/doctors'
import * as TeamNotes from './pharmacy/other'
import * as pharmacy from './pharmacy/other'
import * as dispense from './pharmacy/dispense'
import * as Blister from './pharmacy/blisters'
import * as Revenue from './pharmacy/revenue'
import * as Fda from './pharmacy/other/fda'
import * as Inventory from './pharmacy/Inventory'

import * as adminModule from './admin'

import * as OAT from './pharmacy/other/oat'
export const rootReducer = combineReducers({

	[encryptedConstant.auth]: Auth.authReducers,
	[encryptedConstant.authPharmacyPermissions]: Auth.authPermissionReducer,
	[encryptedConstant.userProfile]: Auth.userProfileReducer,
	updateProfile: Auth.UpdatePharmacyUserProfileReducer,
	updateCoverPicture: Auth.pharmacyUserProfileCoverReducer,

	statHolidays: DefaultMain.holidayReducer,
	userCheckInOut: DefaultMain.userCheckInOutReducer,
	timeOffReqest: DefaultMain.timeOffRequestReducer,

	[encryptedConstant.pharmacyTimeOffLists]: DefaultMain.pharmacyTimeOffListsReducer,
	allEmployeeData: DefaultMain.allEmployeeDataReducer,
	employeeSheudule: DefaultMain.employeeSheuduleReducer,

	editTimeOffRequest: DefaultMain.editTimeOffRequestReducer,
	deleteTimeOffRequest: DefaultMain.deleteTimeOffRequestReducer,
	[encryptedConstant.timesheetDates]: DefaultMain.timesheetDatesReducer,
	employeeAppreciations: DefaultMain.userAppreciationsReducer,

	addWallPosts: WallPosts.addWallPostsReducer,
	[encryptedConstant.pharmacyWallPostsList]: WallPosts.pharmacyWallPostsListsReducer,
	[encryptedConstant.userWallList]: WallPosts.userWallPostListReducer,
	wallPostUserComment: WallPosts.userCommentsReducer,
	allPostCommentLists: WallPosts.userCommentListsReducer,
	userCommentReply: WallPosts.userCommentsReplyReducer,
	[encryptedConstant.userCommentReplyList]: WallPosts.userCommentReplyListsReducer,

	addWallPostLikes: WallPosts.addWallPostLikesReducer,
	wallPostlikeLists: WallPosts.wallPostlikeListReducer,
	addwallPostCommentLikes: WallPosts.addWallPostCommentLikes,

	[encryptedConstant.chatUserLists]: Chats.chatUserListReducers,
	addChatMessages: Chats.addMessageReducer,
	initiatedChatGroup: Chats.initiatedChatGroupReducer,
	addUserActivity: Chats.addUserActivityStatusReducer,
	[encryptedConstant.recentChatLists]: Chats.recentChatListReducer,

	[encryptedConstant.patientProvinceList]: PharmacyPatients.provinceListReducer,
	patientData: PharmacyPatients.patientDataReducer,
	addPatients: PharmacyPatients.addPatientReducer,
	patientsList: PharmacyPatients.patientListsReducer,
	updatePatientStatus: PharmacyPatients.updatePatientStatusReducer,
	updatePatientsProfile: PharmacyPatients.updatePatientProfileReducer,
	linkPatientsToClinic: PharmacyPatients.linkPatientsToClinicReducer,
	patientNotesList: PharmacyPatients.patientNotesListReducer,
	savePatientNote: PharmacyPatients.savePatientNoteReducer,
	oatFormPref: PharmacyPatients.oatFormPrefReducer,
	activePatients: PharmacyPatients.patientListActiveReducer,
	coverageList: PharmacyPatients.coverageListReducer,

	updateNotificationsClinics: forms.updateNotificationForClinicsReducer,
	updateNoficationSRFax: forms.pharmacyUpdateNoficationSRFaxReducer,
	pharmaciesList: forms.pharmacyListReducer,
	RxTransferPdf: forms.RxTransferFormReducer,
	sendFax: forms.sendFaxRequest,
	pharmacyPreviewFormPdf: forms.pharmacyPreviewFormPdfReducer,

	addClinic: Clinic.addNewClinicReducer,
	getAllClinics: Clinic.getClinicsReducer,

	doctorsList: Doctors.doctorsListReducer,
	addNewDoctor: Doctors.addNewDoctorReducer,

	pharmacyAddTeamNotes: TeamNotes.pharmacyAddTeamNotesReducer,
	[encryptedConstant.pharmacyTeamNotesMessage]: TeamNotes.pharmacyTeamNotesMessageReducer,
	pharmacyPatientNotifications: TeamNotes.pharmacyPatientNotificationsReducer,
	pharmacyPatientAddNoteReply: TeamNotes.pharmacyPatientAddNoteReplyReducer,
	pharmacyPatientNoteMarkAsRead: TeamNotes.pharmacyPatientNoteMarkAsReadReducer,

	pharmacySearch: pharmacy.pharmacySearchModule,
	pharmacyCreateFolder: pharmacy.pharmacyManagerCreateFolder,
	[encryptedConstant.pharmacyFileManagerGetAllFiles]: pharmacy.pharmacyFileManagerGetFiles,
	pharmacyAllTableList: pharmacy.pharmacyTableDataReducer,
	pharmacyAddData: pharmacy.pharmacyAddDataReducer,

	addDispenseData: dispense.addDispenseDataReducer,
	searchDeliverySupervisorList: dispense.searchDeliverySupervisorListReducer,
	dispenseListData: dispense.dispenseListDataReducer,
	deleteDispenseData: dispense.deleteDispenseDataReducer,
	deliveryDriversList: dispense.deliveryDriversListReducer,
	archivedDelveriesList: dispense.archivedDelveriesListReducer,
	pharmacyPatientRouteList: dispense.pharmacyPatientRouteListReducer,
	patientPrescriptionDetails: dispense.patientPrescriptionDetailsReducer,
	searchByRxNumberArchivedList: dispense.searchByRxNumberArchivedListReducer,
	dispenseLabelLists: dispense.dispenseLabelListReducer,
	addLabelsData: dispense.addLabelsDataReducer,

	syncTodaysDelveries: dispense.syncTodaysDelveriesReducer,
	pharmacyPatientUpdateRxWitnessDose: dispense.pharmacyPatientUpdateRxWitnessDoseReducer,
	validateLabelInput: dispense.validateLabelInputReducer,


	patientBlisterPackList: Blister.patientBlisterPackList,
	AddBlisterPack: Blister.AddBlisterPackReducer,
	addARVState: pharmacy.addARVReducer,
	arvList: pharmacy.arvListFetcherReducer,
	// arvDelete:pharmacy.deleteARVReducer,
	deleteARVState: pharmacy.deleteARVReducer,
	dispenseMemo: dispense.dispenseListDataReducer,
	reminderUpdateMemoState: dispense.arvReminderUpdaterReducer,

	RevenueList: Revenue.RevenueListReducer,
	addRevenueData: Revenue.addRevenueReducer,
	fdaList: Fda.fdaListFetcherReducer,

	addInventory: Inventory.addInventoryDataReducer,
	InventoryList: Inventory.InventoryListReducer,
	oatList: OAT.OATListFetcherReducer,
	oatUpdateData: OAT.addOATReducer,
	uploadedPrescription: dispense.dispenseListDataReducer,
	uploadPresState: dispense.dispenseImageReducer,
	sidebar: DefaultMain.sidebarReducer,
	appList: pharmacy.appListReducer,
	deleteAppState: pharmacy.deleteAppReducer,

	
	adminTeamList: adminModule.TeamListReducer,
	adminAddTeamData: adminModule.addTeamDataReducer,
	adminTaskComments: adminModule.TeamsCommentListReducer,
})
