import { useEffect } from 'react'
import { fetchPatientsList } from '../../../services/actions/patients/patientsList';
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination';

import * as routeNames from '../../../routes/routeName'
import * as Hooks from '../../../hooks'

export const Pagination = ({ setPaginationNumber,
	queryStatus,
	querySearch,
	paginationNumber,
	patientListData
}) => {

	const navigate = Hooks.useCustomNavigate()
	const dispatch = Hooks.useCustomDispatch()
	const location = Hooks.useCustomLocation()

	const handlePagination = (event, value) => {
		setPaginationNumber(value);

		if (location.pathname === routeNames.ALL_PATIENTS) {
			const searchParams = new URLSearchParams();

			if (queryStatus !== null) {
				searchParams.append('status', queryStatus);
			}

			if (querySearch) {
				searchParams.append('search', querySearch);
			}
			searchParams.append('page', value);
			const newUrl = `${location.pathname}?${searchParams.toString()}`;
			navigate(newUrl);
			dispatch(fetchPatientsList(querySearch, queryStatus, value));
		} else {
			dispatch(fetchPatientsList(querySearch, null, value));
		}
	};


	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === 'ArrowLeft' && paginationNumber > 1) {
				handlePagination(null, paginationNumber - 1);
			} else if (
				e.key === 'ArrowRight' &&
				paginationNumber < patientListData?.pagination?.last_page
			) {
				handlePagination(null, paginationNumber + 1);
			}
		}
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [paginationNumber, patientListData, handlePagination]);

	return (
		<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
			<nav aria-label="Page navigation example">
				<ul className="inline-flex -space-x-px text-base h-10">
					<CustomPagination
						count={patientListData?.pagination?.last_page}
						page={paginationNumber}
						onChange={handlePagination}
					/>
				</ul>
			</nav>
		</div>
	)
}
