import {
	INITIATE_CHAT_GROUP_FAILURE,
	INITIATE_CHAT_GROUP_REQUEST,
	INITIATE_CHAT_GROUP_SUCCESS
} from "../../constants";

import { closeChatboxOne } from "../../../assets/js/custome";
import { pharmacy_intiate_chat } from "../../../networking/urlEndPoints";
import { addReadMessages } from "./chatReadUnReadMessages";

import { fetchAllRecentChats } from "./recentChatsListsActions";
import { authToken } from "../../../storage/authToken";

import Cookies from "js-cookie";
import axios from "axios";

export const initiateGroupRequest = () => ({
	type: INITIATE_CHAT_GROUP_REQUEST,
})

export const initiateGroupSuccess = (data) => ({
	type: INITIATE_CHAT_GROUP_SUCCESS,
	payload: data,
})

export const initiateGroupFailure = (error) => ({
	type: INITIATE_CHAT_GROUP_FAILURE,
	payload: error,
})

export const initiatedChatGroup = (group_type,
	user_id,
	value,
	name,
	create_group,
	groupID,
	groupIcon,
	setProfileIds,
	setGroupData) => {

	return async (dispatch) => {
		dispatch(initiateGroupRequest())

		const requestedData = {
			group_type: group_type,
			user_id: user_id,
			create_group: create_group
		}

		if (group_type === 'one_to_many' && name !== null) {
			requestedData.group_name = name
		}

		if (group_type === 'one_to_many' && groupIcon !== null) {
			requestedData.group_icon = groupIcon
		}

		if (groupID) {
			requestedData.group_id = groupID
		}

		try {

			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_intiate_chat}`, requestedData, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${authToken()}`,
					'Content-Type': 'multipart/form-data',
				},
			})

			if (response?.status === 200) {
				Cookies.set('initated', response?.data?.data?.group_id)
				dispatch(initiateGroupSuccess(response?.data))

				if (value?.unread_count && value?.unread_count !== 0 && value !== null) {
					dispatch(addReadMessages(response?.data?.data?.group_id, dispatch))
				}

				if (group_type === 'one_to_many' && create_group === 1) {
					dispatch(fetchAllRecentChats())
					closeChatboxOne()

					if (typeof setProfileIds === 'function' && typeof setGroupData === 'function') {
						setGroupData(prevdata => ({ ...prevdata, groupName: '', errorMessage: '' }));
						setProfileIds([]);
					}
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(initiateGroupFailure(error?.response))
			}
		}
	}
}

