import { useState } from 'react';

function useCustomState(initialValue) {

	const [state, setState] = useState(initialValue);

	const customSetState = (value) => {
		setState(value);
	};

	return [state, customSetState];
}

export default useCustomState 