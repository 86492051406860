import React, { useEffect, useState } from 'react'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

export const CaptureCommentData = ({ commentsValue }) => {

	const { profileData } = useCustomSelectors()
	const [dots, setDots] = useState('.')

	useEffect(() => {
		const timer = setInterval(() => {
			setDots((prevDots) => (prevDots.length < 4 ? prevDots + '.' : '.'));
		}, 100);

		return () => {
			clearInterval(timer);
		};
	}, [])

	return (
		<React.Fragment>
			{commentsValue?.map((value, index) => {
				return (
					<React.Fragment key={index}>
						<div className="p-4 pt-0 flex items-center gap-6">
							<div className="block w-full p-2 bg-light border border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
								<div className="flex gap-3">
									<div className="flex gap-3">
										<img className="w-8 h-8 rounded-full" src={profileData.photo}
											alt="Rounded avatar" />
										<div>
											<h3 className="rubik-500 fs-14 dark-color">{profileData.name}</h3>
											<p className="rubik-400 fs-14  dark-color">{value.commentText}</p>
										</div>
									</div>
									<div className="ml-auto flex items-top gap-4">
										<span className="leading-4 fs-18 rubik-400 grey-color"></span>
									</div>
								</div>
							</div>
						</div>
						<div className="rubik-400 fs-14 pt-2 dark-color" style={{ position: 'relative', left: '20px', top: '-20px', }}> Posting{dots}</div>
					</React.Fragment>
				)
			})}
		</React.Fragment>
	)
}

export const CatpureCommentReplyListData = ({ commentsReplyValue }) => {

	const { profileData } = useCustomSelectors()
	const [dots, setDots] = useState('.')

	useEffect(() => {
		const timer = setInterval(() => {
			setDots((prevDots) => (prevDots.length < 4 ? prevDots + '.' : '.'));
		}, 100);

		return () => {
			clearInterval(timer);
		};
	}, [])

	return (<>
		{commentsReplyValue?.map((value, index) => {
			return (
				<div className="px-4 pb-1 pt-0" key={index}>
					<div className="ot_allPostReply">
						<div className="borderBefore">
						</div>
						<div className="bottomBorder">
						</div>
						<div style={{ position: 'relative', bottom: '-70px' }}>
							<div className="block w-full p-1 mb-1 bg-light border border-gray-200 rounded  dark:bg-gray-800 dark:border-gray-700">
								<div className="flex gap-3">
									<div className="flex gap-3">
										<img className="w-6 h-6 rounded-full" src={profileData.photo}
											alt="Rounded avatar" />
										<div>
											<h3 className="rubik-500 fs-14 dark-color">{profileData.name}</h3>
											<p className="rubik-400 fs-14  dark-color">{value.commentReplyText}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="rubik-400 fs-12 grey-color" style={{ position: 'relative', left: '45px', top: '-7px' }}> Posting{dots}</div>
				</div>
			)
		})}
	</>)
}
