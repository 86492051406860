import {
	ADD_WALL_POST_USER_COMMENTS_REPLY_REQUEST,
	ADD_WALL_POST__USER_COMMENTS_REPLY_SUCCESS,
	ADD_WALL_POST__USER_COMMENTS_REPLY_FAILURE
} from "../../constants";

import { pharmacy_user_comments_reply } from "../../../networking/urlEndPoints";
import Axios from "../../../networking/intraceptor";

export const fetchUserCommentsReplyRequest = () => ({
	type: ADD_WALL_POST_USER_COMMENTS_REPLY_REQUEST,
});

export const fetchUserCommentsReplySuccess = (comments) => ({
	type: ADD_WALL_POST__USER_COMMENTS_REPLY_SUCCESS,
	payload: comments
});

export const fetchUserCommentsReplyFailure = (error) => ({
	type: ADD_WALL_POST__USER_COMMENTS_REPLY_FAILURE,
	payload: error,
});

export const addPostCommentsReply = (id, commentsReply) => {
	return async (dispatch) => {
		dispatch(fetchUserCommentsReplyRequest());
		try {
			const response = await Axios.post(`${pharmacy_user_comments_reply}/${id}`, {
				comment_id: id,
				reply: commentsReply,
			});
			if (response?.status === 200) {
				dispatch(fetchUserCommentsReplySuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchUserCommentsReplySuccess(error?.response))
			}
		}
	}
}
