import {
	SAVE_PATIENT_NOTE_SUCCESS,
	SAVE_PATIENT_NOTE_REQUEST,
	SAVE_PATIENT_NOTE_FAILURE
} from "../../constants"


import { pharmacy_save_patient_notes } from "../../../networking/urlEndPoints"
import { fetchPatientsList } from "./patientsList"

import Axios from "../../../networking/intraceptor"

import * as routesNames from '../../../routes/routeName'
import * as pharmacyTeamNotes from '../pharmacy/other'
import { FetchPatientReminderNotificationList } from "../pharmacy/other/pharmacyPatientNotifications"

export const savePatientNoteRequest = () => ({
	type: SAVE_PATIENT_NOTE_SUCCESS,
})

export const savePatientNoteSuccess = (data) => ({
	type: SAVE_PATIENT_NOTE_REQUEST,
	payload: data,
})

export const savePatientNoteFailure = (error) => ({
	type: SAVE_PATIENT_NOTE_FAILURE,
	payload: error,
})

export const savePatientNotes = (patient_id, notesData,
	showToast,
	setShowToast,
	paginationNumber,
	location,
	showModal,
	setShowModal,
	queryStatus,
	querySearch,
	color
) => {

	return async (dispatch) => {
		dispatch(savePatientNoteRequest())
		try {
			const response = await Axios.post(`${pharmacy_save_patient_notes}`, {
				patient_id: patient_id,
				notes: notesData.notes,
				selectednoteoption: notesData.selectedNoteOptions,
				recurrence: notesData.recurrence,
				remind_days: notesData.remind,
				color: color ? color : '#FFFFFF'
			})
			if (response?.status === 200) {
				dispatch(savePatientNoteSuccess(response?.data))
				setShowModal({
					...showModal,
					patientNoteModal: false
				})

				if (typeof setShowToast === 'function') {
					setShowToast({
						...showToast,
						patientNoteToast: true
					})

					setTimeout(() => {
						setShowToast({
							...showToast,
							patientNoteToast: false
						})
					}, 3000);
				}

				if (location.pathname === routesNames.ALL_PATIENTS) {
					dispatch(fetchPatientsList(querySearch, queryStatus, paginationNumber))
				}

				if (location.pathname === routesNames.pharmacy_reminder_notes) {
					dispatch(FetchPatientReminderNotificationList())
				} else {
					dispatch(pharmacyTeamNotes.fetchPatientNotifications(null, null, null))
				}

			} else {
				throw new Error(response)
			}

		} catch (error) {

			if (error?.response) {
				dispatch(savePatientNoteFailure(error?.response))
			}

			if (typeof setShowToast === 'function') {
				setShowToast({
					...showToast,
					patientNoteToastError: true
				})

				setTimeout(() => {
					setShowToast({
						...showToast,
						patientNoteToastError: false
					})
				}, 3000);
			}
		}
	}
}


export const UpdateNotes = (patient_id,
	paginationNumber,
	color,
	type
) => {

	return async (dispatch) => {
		dispatch(savePatientNoteRequest())
		try {
			const reqData = {
				patient_id: patient_id,
				color: color ? color : '#FFFFFF',
			}

			if (type) reqData.ntype = type

			const response = await Axios.post(`${'pharmacy-notes-update-color'}`, reqData)
			if (response?.status === 200) {
				dispatch(savePatientNoteSuccess(response?.data))
				const params = {
					page: paginationNumber
				}

				if (type === 'reminder') {
					dispatch(FetchPatientReminderNotificationList(params))
				} else {
					dispatch(pharmacyTeamNotes.fetchPatientNotifications(paginationNumber, null, null, null, "fetchPatientNotifications",))
				}

			} else {
				throw new Error(response)
			}

		} catch (error) {

			if (error?.response) {
				dispatch(savePatientNoteFailure(error?.response))
			}
		}
	}
}