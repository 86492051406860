import React, { useEffect, useCallback, useState } from 'react';
import { useCanvasContext } from '../../hooks/useCanvasContext';
import { fabric } from 'fabric';
import PanelIcons from '../../Pannels/PanelItemIcon';
import WebFont from 'webfontloader';
import { fonts } from '../../utils/fonts';
import { useTabContext } from '../../hooks/TabContext';
import { useCustomSelectors } from "../../../../../../services/selectors/allSelectors";
import {
  addText,
  updateTextControl,
  clearTextControl,
  handleFontFamilyChange,
  handleTextAlignment,
  handleFontSizeChange,
  handleLineHeightChange,
  handleColorChange,
  handleUppercaseToggle,
  handleBoldToggle,
  handleUnderlineToggle,
  handleLinethroughToggle, handleFontWeightChange, handleBoldCaseToggle,handleItalicToggle
} from '../../utils/textUtils';
import { AddImageToCanvas, DeleteObject, renderDeleteIcon, DeleteActiveObject } from '../../utils/imageUtils';
function TextPanel({ type = 'text' }) {
  const { activeTab, setActiveTab } = useTabContext();
  const handleClick = (type) => {
    setActiveTab(type); 
  };
  const { canvas } = useCanvasContext();
  const { pharmacyLoginData } = useCustomSelectors();
  const [fontSizeInput, setFontSizeInput] = useState('');
  const [lineHeightInput, setLineHeightInput] = useState('');
  const [colorInput, setColorInput] = useState('');
  const [isUppercase, setIsUppercase] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isLinethrough, setIsLinethrough] = useState(false);
  const [activeAlignment, setActiveAlignment] = useState('left'); // Default to left alignment, we can change it later based on sir req
  const [fontWeight, setFontWeight] = useState('normal'); // New state for font weight same as alignment
  const [selectedFont, setSelectedFont] = useState('Roboto');
  const [isItalic, setisItalic] = useState(false);
  const addLogoToCanvas = () => {
    if (canvas && pharmacyLoginData) {
      const base64Image = pharmacyLoginData?.logo;
      AddImageToCanvas(canvas, base64Image);
    }
  };


  useEffect(() => {
    if (canvas) {
      // Event listeners for object selection and deselection
      canvas.on('selection:created', () => updateTextControl(canvas, {
        setFontSizeInput,
        setLineHeightInput,
        setColorInput,
        setIsUppercase,
        setIsBold,
        setisItalic,
        setIsUnderline,
        setIsLinethrough,
        setActiveAlignment,
        setFontWeight,
        setSelectedFont
      }));
      canvas.on('selection:updated', () => updateTextControl(canvas, {
        setFontSizeInput,
        setLineHeightInput,
        setColorInput,
        setIsUppercase,
        setIsBold,
        setisItalic,
        setIsUnderline,
        setIsLinethrough,
        setActiveAlignment,
        setFontWeight,
        setSelectedFont
      }));

      //this happen when we click anywhere on the canvas. its very important to have it as it resets the text options
      canvas.on('selection:cleared', () => clearTextControl({
        setFontSizeInput,
        setLineHeightInput,
        setColorInput,
        setIsUppercase,
        setIsBold,
        setisItalic,
        setIsUnderline,
        setIsLinethrough,
        setActiveAlignment,
        setSelectedFont
      }));

      // Cleanup event listeners
      return () => {
        canvas.off('selection:created');
        canvas.off('selection:updated');
        canvas.off('selection:cleared');
      };
    }
  }, [canvas]);

  const handleFontFamilyChangeWrapper = (event) => handleFontFamilyChange(canvas, event.target.value, setSelectedFont);
  const handleTextAlignmentWrapper = (alignment) => handleTextAlignment(canvas, alignment, setActiveAlignment);
  const handleFontSizeChangeWrapper = (event) => handleFontSizeChange(canvas, event, setFontSizeInput);
  const handleLineHeightChangeWrapper = (event) => handleLineHeightChange(canvas, event, setLineHeightInput);
  const handleColorChangeWrapper = (event) => handleColorChange(canvas, event, setColorInput);
  const handleUppercaseToggleWrapper = () => handleUppercaseToggle(canvas, isUppercase, setIsUppercase);
  const handleBoldToggleWrapper = () => handleBoldToggle(canvas, isBold, setIsBold);
  const handleUnderlineToggleWrapper = () => handleUnderlineToggle(canvas, isUnderline, setIsUnderline);
  const handleLinethroughToggleWrapper = () => handleLinethroughToggle(canvas, isLinethrough, setIsLinethrough);
  const handleFontWeightChangeWrapper = (event) => handleFontWeightChange(canvas, event, setFontWeight);
  const handleBoldCaseToggleWrapper = () => handleBoldCaseToggle(canvas, isBold, setIsBold);
  const handleItlaicToggleWrapper = () => handleItalicToggle(canvas, isItalic, setisItalic);
  
  const addHeading = () => {
    addText(canvas, {
      text: 'Add a heading',
      fontSize: 32,
      lineHeight: 1.2,
      fill: '#000000',
      width: 320,
      fontWeight: 700,
      fontFamily: 'Roboto',
      textAlign: 'center',
    });
  };

  const addSubheading = () => {
    addText(canvas, {
      text: 'Add a sub heading',
      fontSize: 24,
      lineHeight: 1.2,
      fill: '#000000',
      width: 320,
      fontWeight: 700,
      
      fontFamily: 'Roboto',
      textAlign: 'center',
    });
  };

  const addBodyText = () => {
    addText(canvas, {
      text: 'Add a little bit of body text',
      fontSize: 16,
      lineHeight: 1.2,
      fill: '#000000',
      width: 320,
      fontWeight: 400,
      fontFamily: 'Roboto',
      textAlign: 'center',
    });
  };

  const addCustomData = (text) => {
    addText(canvas, {
      text: text,
      fontSize: 16,
      lineHeight: 1.2,
      fill: '#000000',
      width: 320,
      fontWeight: 400,
      fontFamily: 'Roboto',
      textAlign: 'center',
    });
  };

  return (
    <div className="panel-text" style={{ padding: '10px' }}>
      {type === 'text' && (
        <div>

          <div className="add-text-items">
            <div onClick={addHeading} className="add-text-item add-heading">
              Add a heading
            </div>
            <div onClick={addSubheading} className="add-text-item add-subheading">
              Add a subheading
            </div>
            <div onClick={addBodyText} className="add-text-item add-body-text">
              Add body text
            </div>
          </div>
        </div>
      )}

      {type === 'pharmacy' && (
        <div>
          <div className='panel-text mt-2'>
            <div className="add-text-items">
              <button
                type="button"
                className="py-2.5 px-5  me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={addLogoToCanvas}
              >
              <div className='flex gap-1 justify-center'><span><img style={{width:'20px',borderRadius:'4px'}} src={pharmacyLoginData?.logo} /></span> <span>Add Logo</span></div>
              
                
              </button>
              <button onClick={() => addCustomData(pharmacyLoginData?.address)} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Add Address</button>
              <button onClick={() => addCustomData(pharmacyLoginData?.email)} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Add Email</button>
              <button onClick={() => addCustomData(pharmacyLoginData?.phone)} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Add Phone</button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-5" style={{ borderTop: '1px solid #4562e2' }}>
        <h1 className="text-white mt-5 mb-5" style={{ fontSize: '20px' }}>
          Typography
        </h1>
        <div className="flex items-center gap-2 mb-4">
          <div className="text-white w-full">Font Family:</div>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={selectedFont}
            onChange={handleFontFamilyChangeWrapper}
          >
            {fonts.map((fontName) => (
              <option key={fontName} value={fontName}>
                {fontName}
              </option>
            ))}
          </select>


        </div>
        <div className="flex items-center gap-2 mb-4">
          <div className="text-white w-full">Font Weight:</div>
          <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={fontWeight}
            onChange={handleFontWeightChangeWrapper} name='fontweight'>
            <option value="normal">Normal</option>
            <option value="bold">Bold</option>
            <option value="bolder">Bolder</option>
            <option value="lighter">Lighter</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="300">300</option>
            <option value="400">400</option>
            <option value="500">500</option>
            <option value="600">600</option>
            <option value="700">700</option>
            <option value="800">800</option>
            <option value="900">900</option>
          </select>
        </div>

        <div className="flex items-center gap-2 mb-4">
          <div className="text-white w-full">Font Size:</div>
          <input
            type="text"
            placeholder="Font size"
            value={fontSizeInput}
            onChange={handleFontSizeChangeWrapper}
            className="border-2 border-gray-300 rounded-md px-3 py-2 outline-none focus:border-blue-500 transition duration-300 ease-in-out w-full"
          />
        </div>
        <div className="flex items-center gap-2 mb-4">
          <div className="text-white w-full">Line Height:</div>
          <input
            type="number"
            placeholder="Line height"
            value={lineHeightInput}
            onChange={handleLineHeightChangeWrapper}
            className="border-2 border-gray-300 rounded-md px-3 py-2 outline-none focus:border-blue-500 transition duration-300 ease-in-out w-full"
          />
        </div>
        <div className="flex items-center gap-2 mb-4">
          <div className="text-white w-full">Color:</div>
          <input
            style={{ backgroundColor: colorInput }}
            type="color"
            value={colorInput}
            onChange={handleColorChangeWrapper}
            className="border-2 border-gray-300 rounded-md px-3 py-2 outline-none focus:border-blue-500 transition duration-300 ease-in-out"
          />
        </div>
        <div className="flex items-center gap-2 mb-4">
          <div className="text-white w-full">Align:</div>
          <div className="flex gap-3">
            <div
              onClick={() => handleTextAlignmentWrapper('left')}

              style={{
                width: '40px',
                color: 'rgb(255, 255, 255)',
                fontSize: '20px',
                border: activeAlignment === 'left' ? '1px solid gray' : 'none',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              {PanelIcons['left'].render()}
            </div>
            <div
              onClick={() => handleTextAlignmentWrapper('center')}

              style={{
                width: '40px',
                color: 'rgb(255, 255, 255)',
                fontSize: '20px',
                border: activeAlignment === 'center' ? '1px solid gray' : 'none',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              {PanelIcons['center'].render()}
            </div>
            <div
              onClick={() => handleTextAlignmentWrapper('right')}

              style={{
                width: '40px',
                color: 'rgb(255, 255, 255)',
                fontSize: '20px',
                border: activeAlignment === 'right' ? '1px solid gray' : 'none',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              {PanelIcons['right'].render()}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5" style={{ borderTop: '1px solid #4562e2' }}>
        <h1 className="text-white mt-5 mb-5" style={{ fontSize: '20px' }}>
          More Styling
        </h1>
        <div className="flex gap-2">
          <div>
            <span
              onClick={handleUppercaseToggleWrapper}

              style={{
                width: '40px',
                color: 'rgb(255, 255, 255)',
                fontSize: '20px',
                border: isUppercase ? '1px solid gray' : 'none',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              {PanelIcons['uppercase'].render()}
            </span>
          </div>
          <div>
            <span
              onClick={handleBoldCaseToggleWrapper}

              style={{
                width: '40px',
                color: 'rgb(255, 255, 255)',
                fontSize: '20px',
                border: isBold ? '1px solid gray' : 'none',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              {PanelIcons['bold'].render()}
            </span>
          </div>
          <div>
            <span
              onClick={handleUnderlineToggleWrapper}

              style={{
                width: '40px',
                color: 'rgb(255, 255, 255)',
                fontSize: '20px',
                borderBottom: isUnderline ? '1px solid gray' : 'none',
                height: '40px',
                border: isUnderline ? '1px solid gray' : 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              {PanelIcons['underline'].render()}
            </span>
          </div>
          <div>
            <span
              onClick={handleLinethroughToggleWrapper}

              style={{
                width: '40px',
                color: 'rgb(255, 255, 255)',
                fontSize: '20px',
                textDecoration: isLinethrough ? 'line-through' : 'none',
                border: isLinethrough ? '1px solid gray' : 'none',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              {PanelIcons['linethrough'].render()}
            </span>
          </div>
          <div>
            <span
              onClick={handleItlaicToggleWrapper}

              style={{
                width: '40px',
                color: 'rgb(255, 255, 255)',
                fontSize: '20px',
                textDecoration: isItalic ? 'oblique' : 'italic',
                border: isItalic ? '1px solid gray' : 'none',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              {PanelIcons['italic'].render()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextPanel;
