import {
	PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_REQUEST,
	PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_SUCCESS,
	PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_FAILURE
} from "../../../constants"

const initialState = {
	loading: false,
	data: null,
	error: null,
};

const pharmacyUpdateNoficationSRFaxReducer = (state = initialState, action) => {
	switch (action.type) {
		case PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				data: null,
			};
		case PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			}
		case PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null
			}
		default:
			return state;
	}
}
export default pharmacyUpdateNoficationSRFaxReducer