import {
    LIST_OAT_DATA_REQUEST,
    LIST_OAT_DATA_SUCCESS,
    LIST_OAT_DATA_FAILURE
} from "../../constants"

import { pharmacy_route_patient } from "../../../networking/urlEndPoints"

import Axios from "../../../networking/intraceptor"

export const OATRequest = (types) => ({
    type: LIST_OAT_DATA_REQUEST,
    types: types
})

export const OATSuccess = (data, types) => ({
    type: LIST_OAT_DATA_SUCCESS,
    payload: data,
    types: types
})

export const OATFailure = (error, types) => ({
    type: LIST_OAT_DATA_FAILURE,
    payload: error,
    types: types
})

export const oatFetchList = (type,  params) => {

    return async (dispatch) => {
        
        dispatch(OATRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_route_patient}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(OATSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(OATFailure(error?.response, type))
            }
        }
    }
}


export const oatFetchMedicine = (type,  params) => {

    return async (dispatch) => {
        
        dispatch(OATRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_route_patient}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(OATSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(OATFailure(error?.response, type))
            }
        }
    }
}