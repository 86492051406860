import React, {
    useState,
    useRef,
    useEffect
} from 'react'

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'
import { BlisterDropDown, Date } from '../../components/pharmacy/form/form'

import { useRevenueSelector } from '../../services/selectors/revenueSelectors'
import { useCustomDispatch, useCustomLocation } from '../../hooks'

import {
    AddMultipleRevenueData,
    AddRevenueSingleEntryData,
    DeleteAllGenerateFdaList,
    DeleteInHandCashList,
    DeletePharmacyDepositInfoList,
    DeleteRevenuelistData,
    UpdateDailyRevenueData
} from '../../services/actions/pharmacy/revenue/addRevenueData'

import * as routesName from '../../routes/routeName'
import { DeleteInventroyList, DeleteTravelLogsList, DeleteTravelPatients } from '../../services/actions/pharmacy/Inventory/addInventoryData'
import { revenueTypeMapping } from '../../screens/pharmacy/revenue/dailyRevenue'

export const AddMultipleRevenueModal = ({ setShowModal, showModal, setFormData, formData, setShowToast }) => {

    const modalRef = useRef()
    const dispatch = useCustomDispatch()

    const { addRevenueDataResponse } = useRevenueSelector();

    const inputFields = [
        { id: 'cash', label: 'Cash', name: 'cash', amount: '0', note: '' },
        { id: 'debit_card', label: 'Debit Card', name: 'debitCard', amount: '0', note: '' },
        { id: 'visa_card', label: 'Visa Card', name: 'visaCard', amount: '0', note: '' },
        { id: 'master_card', label: 'Master Card', name: 'masterCard', amount: '0', note: '' },
        { id: 'cheque', label: 'Cheque', name: 'cheque', amount: '0', note: '' },
        { id: 'auto_debit', label: 'Auto Debit', name: 'autoDebit', amount: '0', note: '' },
        { id: 'cash_left_over_previous_month', label: 'Cash Left Over Previous Month', name: 'cashLeftOver', amount: '0', note: '' },
        { id: 'cash_depoist_in_bank_or_payout', label: 'Cash Deposit in Bank or Payout', name: 'cashDeposit', amount: '0', note: '' },
    ];

    const [inputData, setInputData] = useState(inputFields);

    const handleChange = (index, key, value) => {
        const newFormData = [...inputData];
        newFormData[index][key] = value;
        setInputData(newFormData);
    };

    const handleAddMultipleEntry = () => {
        const reqData = {
            revinue_date: formData.date,
            revinue: {}
        };

        inputData.forEach(field => {
            reqData.revinue[field.id] = {
                amount: field.amount,
                note: field.note
            };
        });

        dispatch(AddMultipleRevenueData('add_multiple_entry', reqData, setShowToast, setShowModal))

    };


    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" ref={modalRef}>
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Add Multiple Revenue
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'multipleRevenue')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <form>
                                <div className='flex-0 lg:w-6/12 md:w-4/12 sm:w-6/12 md:pr-0'>
                                    <Date setFormData={setFormData} formData={formData} />
                                </div>

                                <div className="grid grid-cols-1 gap-4 mt-2">
                                    {inputData?.map((field, index) => (
                                        <div key={index} className='grid grid-cols-1 lg:grid-cols-3 gap-4 items-center'>
                                            <div>
                                                <label htmlFor={field.id} className="block text-gray-700 font-medium">
                                                    {field.label}
                                                </label>
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    id={`${field.id}_amount`}
                                                    name={`${field.name}_amount`}
                                                    value={field.amount}
                                                    placeholder="Amount"
                                                    className={`block w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 px-2.5 py-2.5 focus:outline-none focus:border-blue-600 ${field.errorMessage && 'border-red-600'}`}
                                                    onChange={(e) => handleChange(index, 'amount', e.target.value)}
                                                    onKeyPress={(e) => { if (isNaN(Number(e.key))) { e.preventDefault(); } }}
                                                />
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    id={`${field.id}_note`}
                                                    name={`${field.name}_note`}
                                                    value={field.note}
                                                    placeholder="Note"
                                                    className={`block w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 px-2.5 py-2.5 focus:outline-none focus:border-blue-600 ${field.errorMessage && 'border-red-600'}`}
                                                    onChange={(e) => handleChange(index, 'note', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <button type="button" onClick={() => addRevenueDataResponse?.loading ? null : handleAddMultipleEntry()} style={{ backgroundColor: '#3B82F6' }}
                                    className="flex w-full items-center justify-center text-white bg-red-500 mt-4
										border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-1.5  dark:bg-gray-800">
                                    {addRevenueDataResponse?.loading && addRevenueDataResponse?.type === 'add_multiple_entry' ? 'Saving...' : 'Save'}

                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export const AddSingleRevenueModal = ({ setShowModal, showModal, setFormData, formData, setShowToast }) => {

    const modalRef = useRef()
    const dispatch = useCustomDispatch()

    const { RevenueListResponse, addRevenueDataResponse } = useRevenueSelector();

    const revenuetype = RevenueListResponse?.data?.data?.revenuetype
    const [errorMessage, setErrorMessage] = useState({ amount: '', type: '', note: '' })

    const handleSumitSingleEntry = () => {

        const { date, amount, note, type } = formData;
        const errors = {
            amount: !amount ? 'Amount is required' : '',
            note: !note ? 'Note is required' : '',
            type: !type ? 'Type is required' : '',
            date: !date ? 'Date is required' : '',
        };

        setErrorMessage(errors);

        if (!errors.amount && !errors.note && !errors.type && !errors.date) {
            const reqData = { date, amount, note, };
            if (type) reqData.type = revenueTypeMapping[type]
            dispatch(AddRevenueSingleEntryData('add_single_entry', reqData, setShowToast, setShowModal))
        }
    }

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" ref={modalRef}>
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Add Single Revenue
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'singleRevenue')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <form>
                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 items-center'>
                                    <div>
                                        <Date setFormData={setFormData} formData={formData} />
                                        {errorMessage.date && <p className='text-red-500 fs-12 rubik-400'>{errorMessage.date}</p>}
                                    </div>
                                    <div>
                                        <BlisterDropDown formData={formData}
                                            setFormData={setFormData}
                                            name="type"
                                            value={formData.type}
                                            filterList={revenuetype}
                                            // reset={reset}
                                            placeholder='Type' />
                                        {errorMessage.type && <p className='text-red-500 fs-12 rubik-400'>{errorMessage.type}</p>}
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 gap-4 mt-2">
                                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 items-center'>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id={`amount`}
                                                name={`amount`}
                                                value={formData.amount}
                                                placeholder="Amount"
                                                className={`block w-full text-sm  text-gray-900 bg-white rounded-lg border border-gray-300 px-2.5 py-2.5 focus:outline-none focus:border-blue-600`}
                                                onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                                                onKeyPress={(e) => { if (isNaN(Number(e.key))) { e.preventDefault(); } }}
                                            />
                                            {errorMessage.amount && <p className='text-red-500 fs-12 rubik-400'>{errorMessage.amount}</p>}
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id={`note`}
                                                name={`note`}
                                                value={formData.note}
                                                onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                                                placeholder="Note"
                                                className={`block w-full  text-sm text-gray-900 bg-white rounded-lg border border-gray-300 px-2.5 py-2.5 focus:outline-none focus:border-blue-600`}
                                            />
                                            {errorMessage.note && <p className='text-red-500 fs-12 rubik-400'>{errorMessage.note}</p>}

                                        </div>
                                    </div>
                                </div>

                                <button onClick={() => addRevenueDataResponse?.loading ? null : handleSumitSingleEntry()} type="button" style={{ backgroundColor: '#3B82F6' }}
                                    className="flex w-full items-center justify-center text-white bg-red-500 mt-4
										border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-1.5  dark:bg-gray-800">
                                    {addRevenueDataResponse?.loading && addRevenueDataResponse?.type === 'add_single_entry' ? 'Saving...' : 'Save'}
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export const UpdateDailyRevenueModal = ({ setShowModal, showModal, setFormData, formData, setShowToast, logData }) => {

    const modalRef = useRef()
    const dispatch = useCustomDispatch()

    const { addRevenueDataResponse } = useRevenueSelector();

    const [errorMessage, setErrorMessage] = useState({ amount: '', type: '', note: '' })

    const handleSumitSingleEntry = () => {

        const { date, amount, note, type } = formData;
        const { id } = logData

        const errors = {
            amount: !amount ? 'Amount is required' : '',
            note: !note ? 'Note is required' : '',
            date: !date ? 'Date is required' : '',
        };

        setErrorMessage(errors);

        if (!errors.amount && !errors.note && !errors.type && !errors.date) {
            const reqData = { date, amount, note, type, log_id: id };
            dispatch(UpdateDailyRevenueData('update_revenue_list', reqData, setShowToast, setShowModal))
        }
    }

    useEffect(() => {
        const { amount, note, date } = logData
        setFormData({ ...formData, amount: amount ? amount : '', note: note ? note : '', date: date })
    }, [logData])

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" ref={modalRef}>
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Update
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'updateRevenueList')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <form>
                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 items-center'>
                                    <div>
                                        <Date setFormData={setFormData} formData={formData} />
                                        {errorMessage.date && <p className='text-red-500 fs-12 rubik-400'>{errorMessage.date}</p>}
                                    </div>

                                    <div className="relative">
                                        <input
                                            type="text"
                                            id={`amount`}
                                            name={`amount`}
                                            value={formData.amount}
                                            placeholder="Amount"
                                            className={`block w-full text-sm  text-gray-900 bg-white rounded-lg border border-gray-300 px-2.5 py-2.5 focus:outline-none focus:border-blue-600`}
                                            onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                                            onKeyPress={(e) => { if (isNaN(Number(e.key))) { e.preventDefault(); } }}
                                        />
                                        {errorMessage.amount && <p className='text-red-500 fs-12 rubik-400'>{errorMessage.amount}</p>}
                                    </div>

                                </div>

                                <div className="grid grid-cols-1 gap-4 mt-2">
                                    <div className='grid grid-cols-1 lg:grid-cols-1 gap-4 items-center'>

                                        <div className="relative">
                                            <textarea
                                                type="text"
                                                id={`note`}
                                                name={`note`}
                                                value={formData.note}
                                                onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                                                placeholder="Note"
                                                className={`block w-full  text-sm text-gray-900 bg-white rounded-lg border border-gray-300 px-2.5 py-2.5 focus:outline-none focus:border-blue-600`}
                                            />
                                            {errorMessage.note && <p className='text-red-500 fs-12 rubik-400'>{errorMessage.note}</p>}

                                        </div>
                                    </div>
                                </div>

                                <button onClick={() => addRevenueDataResponse?.loading ? null : handleSumitSingleEntry()} type="button" style={{ backgroundColor: '#3B82F6' }}
                                    className="flex w-full items-center justify-center text-white bg-red-500 mt-4
										border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-1.5  dark:bg-gray-800">
                                    {addRevenueDataResponse?.loading && addRevenueDataResponse?.type === 'update_revenue_list' ? 'Updating...' : 'Update'}
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export const DeleteDailyRevenueModal = ({ setShowModal, showModal, setShowToast, logData, text, isEdit, pageNumber, formData }) => {

    const dispatch = useCustomDispatch()
    const location = useCustomLocation()
    const { addRevenueDataResponse } = useRevenueSelector();
    // console.log(logData)
    const handleDelete = () => {
        const { id } = logData
        if (location.pathname === routesName.pharmacy_hand_cash) {
            dispatch(DeleteInHandCashList('delete_in_hand_cash_list', id, setShowToast, setShowModal, pageNumber))
        }

        else if (location.pathname === routesName.all_generated_fda_form_list) {
            dispatch(DeleteAllGenerateFdaList('delete_deposit_info', id, setShowToast, setShowModal, pageNumber))
        }

        else if (location.pathname === routesName.pharmacy_inventory_list) {
            dispatch(DeleteInventroyList('delete_inventrory', id, setShowToast, setShowModal, pageNumber, formData))
        }

        else if (location.pathname === routesName.pharmacy_travel_logs) {
            dispatch(DeleteTravelLogsList('delete_travel_logs', id, setShowToast, setShowModal, pageNumber, formData))
        }

        else if (location.pathname === routesName.pharmacy_add_travel_pack) {
            const { patient_id } = logData
            dispatch(DeleteTravelPatients('delete_travel_patients', patient_id, setShowToast, setShowModal, pageNumber, formData))
        }

        else if (isEdit) {
            dispatch(DeletePharmacyDepositInfoList('delete_deposit_info', id, setShowToast, setShowModal, pageNumber))
        }

        else {
            dispatch(DeleteRevenuelistData('delete_revenue_list', id, setShowToast, setShowModal))
        }
    }

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button onClick={() => handleHideModal(setShowModal, showModal, 'deleteRevenueList')} type="button"
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              				text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                        </button>
                        <div className="p-6 text-center pt-12">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{text}</h3>
                            <button
                                onClick={handleDelete}
                                style={{ backgroundColor: '#3A52BB' }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 
                    			dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">

                                {addRevenueDataResponse?.loading ? 'Deleting...' : 'Yes'}

                            </button>
                            <button onClick={() => handleHideModal(setShowModal, showModal, 'deleteRevenueList')} type="button"
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                				border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                				dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}