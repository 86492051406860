import {
	FETCH_PHARMACY_LIST_REQUEST,
	FETCH_PHARMACY_LIST_SUCCESS,
	FETCH_PHARMACY_LIST_FAILURE
} from "../../../constants"

import { get_pharmacies_list } from './../../../../networking/urlEndPoints'
import Axios from "../../../../networking/intraceptor"

export const pharmacyListRequest = () => ({
	type: FETCH_PHARMACY_LIST_REQUEST,
})

export const pharmacyListSuccess = (data) => ({
	type: FETCH_PHARMACY_LIST_SUCCESS,
	payload: data,
})

export const pharmacyListFailure = (error) => ({
	type: FETCH_PHARMACY_LIST_FAILURE,
	payload: error,
})

export const fetchAllPharmacyLists = (search) => {
	return async (dispatch) => {
		dispatch(pharmacyListRequest())
		const params = {
			search: search
		}
		try {
			const response = await Axios.get(`${get_pharmacies_list}`, { params })
			if (response?.status === 200) {
				dispatch(pharmacyListSuccess(response?.data))
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyListFailure(error?.response?.data))
			}
		}
	}
}