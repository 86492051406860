
import React, {
    useState,
    useRef,
    useEffect
} from 'react'

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'
import { BlisterDropDown, Date } from '../../components/pharmacy/form/form'

import { useRevenueSelector } from '../../services/selectors/revenueSelectors'
import { useCustomDispatch } from '../../hooks'
import { UpdatePrescriberList } from '../../services/actions/FDA/addFDAformData'

import * as routesName from '../../routes/routeName'
import { initalFdaFormData } from '../../utils/constants/formInitialStates'
import * as Pharmacy from '../../components/pharmacy/form/form'

export const UpdatePrescribers = ({ setShowModal, showModal, setFormData, formData, logData,pageNumber,setShowToast }) => {

    const modalRef = useRef()
    const dispatch = useCustomDispatch()

    const { addRevenueDataResponse } = useRevenueSelector();

    const createInitialFormDataArray = (numItems) => Array.from({ length: numItems }, () => ({ ...initalFdaFormData }));

    const prescriberLength = logData?.prescriber?.length
    const [prescriberData, setprescriberData] = useState(createInitialFormDataArray(6 - prescriberLength));
    const [errorMessagesArray, setErrorMessagesArray] = useState(createInitialFormDataArray(6 - prescriberLength));

    const handleChangePrescriberData = (index, newData) => {
        const newFormDataArray = [...prescriberData];
        newFormDataArray[index] = { ...newFormDataArray[index], ...newData };
        setprescriberData(newFormDataArray);
    };

    const handleErrorMessagesChange = (index, newErrors) => {
        const newErrorMessagesArray = [...errorMessagesArray];
        newErrorMessagesArray[index] = { ...newErrorMessagesArray[index], ...newErrors };
        setErrorMessagesArray(newErrorMessagesArray);
    };

    const handleUpdatePRescriber = () => {
        const prescriberIds = prescriberData?.map((value) => value.doctorName.value)
        const requestedData = {
            prescriber: prescriberIds,
            date: formData.date,
            frequent_log_id: logData.id
        }

        dispatch(UpdatePrescriberList('update_prescriber', requestedData, setShowModal,pageNumber,setShowToast))

    }

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" ref={modalRef}>
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">

                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'updatePrescriberList')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <form>
                                <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2'>
                                    <Date setFormData={setFormData} formData={formData} />
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
                                    {prescriberData?.map((formData, index) => (
                                        <div key={index} className='flex gap-4'>
                                            <div style={{ width: '280px' }} className='mb-4'>
                                                <Pharmacy.DoctorList
                                                    formData={formData}
                                                    errorMessages={errorMessagesArray[index]}
                                                    setFormData={(newData) => handleChangePrescriberData(index, newData)}
                                                    setErrorMessages={(newErrors) => handleErrorMessagesChange(index, newErrors)}
                                                    placeholder='Select Prescriber'
                                                />
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                <button type="button" onClick={handleUpdatePRescriber} style={{ backgroundColor: '#3B82F6' }}
                                    className="flex w-full items-center justify-center text-white bg-red-500 mt-4
										border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-1.5  dark:bg-gray-800">
                                    {addRevenueDataResponse?.loading && addRevenueDataResponse?.type === 'update_prescriber' ? 'Saving...' : 'Save'}

                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}