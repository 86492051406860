import {
	ADD_WALL_POST_USER_COMMENTS_REPLY_REQUEST,
	ADD_WALL_POST__USER_COMMENTS_REPLY_SUCCESS,
	ADD_WALL_POST__USER_COMMENTS_REPLY_FAILURE
} from "../../constants";

const initialState = {
	commentsReply: null,
	loading: false,
	error: null,
};

const userCommentsReplyReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_WALL_POST_USER_COMMENTS_REPLY_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				commentsReply: null

			};

		case ADD_WALL_POST__USER_COMMENTS_REPLY_SUCCESS:
			return {
				...state,
				commentsReply: action.payload,
				loading: false,
				error: null,
			};

		case ADD_WALL_POST__USER_COMMENTS_REPLY_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				commentsReply: null
			};

		default:
			return state;
	}
};

export default userCommentsReplyReducer;
