import React from 'react'

export const Errors = ({ error }) => {

	return (
		<div className="p-4 mb-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 text-center" role="alert">
			<span className="font-medium">{error}</span>
		</div>
	)
}

export const SuccessMessage = ({ message }) => {
	return (<div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
		<span className="font-medium">{message}</span>
	</div>)
}

export const ErrorMessages = () => {
	return (
		<div className="flex flex-col items-center justify-center mt-60">
			<div className="p-4 mb-4 text-center text-red-800 rounded-lg bg-red-50 dark:bg-red-600 dark:text-white" role="alert">
				<span className="font-semibold text-2xl">Oops! Something went wrong</span><br />
				<p className="text-gray-600 dark:text-gray-300 mt-2 rubik-400 text-lg">We encountered an issue while processing your request. Please try again later.</p>
			</div>
		</div>
	)
}

export const WallListErrors = () => {
	return (
		<React.Fragment>
			<div className="mt-60 mb-4 w-full border rounded-lg bg-red-100 border-red-400 text-red-700 shadow-md p-4">
				<div style={{ textAlign: 'center' }}>
					<h3 className="rubik-500 text-2xl">
						Oops! Something Went Wrong
					</h3>
					<p className="rubik-400 text-lg">
						We apologize, but it seems like there's been an issue. Please try again later.
					</p>
				</div>
			</div>
		</React.Fragment>
	)
}


export const CommentsError = ({ addCommentResponseMessage }) => {
	return (
		<div className="p-4 pt-0 flex items-center gap-6">
			<div className="block w-full p-2 bg-light border border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 error-container">
				<div className="flex gap-3">
					<div className="flex gap-3">
						<span className="material-icons-outlined error-icon text-red-500 fs-16">{addCommentResponseMessage}</span>
						<div>
							<p className="rubik-400 fs-14 dark-color"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


export const CommentsListError = () => {
	return (
		<div className="p-4 pt-0 flex items-center gap-6">
			<div className="block w-full p-2 bg-light border border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 error-container">
				<div className="flex gap-3">
					<div className="flex gap-3">
						<span className="material-icons-outlined error-icon text-red-500 fs-16">Something Went Wrong</span>
						<div>
							<p className="rubik-400 fs-14 dark-color"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export const AddWallResponseError = () => {
	return (
		<div className="mt-2 mb-4 w-full border rounded-lg bg-white shadow">
			<div className="flex gap-3 p-4">
				<div style={{ textAlign: 'center' }}>
					<span className="material-icons-outlined error-icon text-red-500 fs-16">Oops..!! Something Went Wrong While Adding Your Post</span>
				</div>
			</div>
		</div>
	)
} 
