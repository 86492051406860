import {
	FETCH_PHARMACY_WALL_POSTS_LISTS_REQUEST,
	FETCH_PHARMACY_WALL_POSTS_LISTS_SUCCESS,
	FETCH_PHARMACY_WALL_POSTS_LISTS_FAILURE
} from "../../constants";

import { pharmacy_wall_post_list } from "../../../networking/urlEndPoints";
import Axios from '../../../networking/intraceptor'

export const fetchPharmacyWallPostsRequest = () => ({
	type: FETCH_PHARMACY_WALL_POSTS_LISTS_REQUEST,
});

export const fetchPharmacyWallPostsSuccess = (data) => ({
	type: FETCH_PHARMACY_WALL_POSTS_LISTS_SUCCESS,
	payload: data,
});

export const fetchPharmacyWallPostsFailure = (error) => ({
	type: FETCH_PHARMACY_WALL_POSTS_LISTS_FAILURE,
	payload: error,
})

export const fetchPharmacyWallLists = (setWallListLoading, setContentLoader) => {
	return async (dispatch) => {
		dispatch(fetchPharmacyWallPostsRequest())

		const params = {
			no_of_records: '15',
		}

		try {
			const response = await Axios.get(`${pharmacy_wall_post_list}`, { params });
			if (response?.status === 200) {
				dispatch(fetchPharmacyWallPostsSuccess(response?.data));
				if (typeof setWallListLoading === 'function') {
					setWallListLoading(false)
				}

				if (typeof setContentLoader === 'function') {
					setContentLoader(false)
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchPharmacyWallPostsFailure(error?.response))
				if (typeof setWallListLoading === 'function') {
					setWallListLoading(false)
				}

				if (typeof setContentLoader === 'function') {
					setContentLoader(false)
				}
			}
		}
	};
}


export async function fetchWallList(pageNumber, totalPagination, wallList, setWallList, setPageNumber) {
	if (pageNumber < totalPagination) {
		const nextPage = pageNumber + 1;
		const params = {
			no_of_records: '15',
			page: nextPage,
		};
		try {
			const response = await Axios.get(`${pharmacy_wall_post_list}`, { params });
			if (response?.status === 200) {
				setWallList([...wallList, ...response?.data?.data?.data]);
				setPageNumber(nextPage);
			}
		} catch (error) {
			if (error?.response) {
			}
		}
	}
}