import {
    LABEL_VALIDATE_INPUTS_REQUEST,
    LABEL_VALIDATE_INPUTS_SUCCESS,
    LABEL_VALIDATE_INPUTS_FAILURE
} from '../../../constants';

import { abel_validate_update_inputs, } from '../../../../networking/urlEndPoints'

import Axios from '../../../../networking/intraceptor'

export const validateLabelInputsDataRequest = (types) => ({
    type: LABEL_VALIDATE_INPUTS_REQUEST,
    types: types
});

export const validateLabelInputsDataSuccess = (data, types) => ({
    type: LABEL_VALIDATE_INPUTS_SUCCESS,
    payload: data,
    types: types
});

export const validateLabelInputsDataFailure = (error, types) => ({
    type: LABEL_VALIDATE_INPUTS_FAILURE,
    payload: error,
    types: types
});

export const validateLabelInput = (type, requestedData) => {
    return async (dispatch) => {
        dispatch(validateLabelInputsDataRequest(type))
        try {

            const response = await Axios.post(`${abel_validate_update_inputs}`, requestedData)

            if (response?.status === 200) {
                dispatch(validateLabelInputsDataSuccess(response?.data, type));

            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error?.response) {
                dispatch(validateLabelInputsDataFailure(error?.response, type));
            }
        }
    }
}



