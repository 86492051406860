import { pharmacy_get_previous_messages } from "../../../networking/urlEndPoints";
import Axios from "../../../networking/intraceptor";
import Cookies from "js-cookie";

export async function fecthAllGroupPreviousMessages(group_id, last_message_id, setGroupMessages, groupMessages, setLoadGroupMessages, chatBodyRef) {
	try {
		setLoadGroupMessages(true)

		const chatBody = chatBodyRef.current;
		const previousScrollTop = chatBody.scrollTop;

		const response = await Axios.post(`${pharmacy_get_previous_messages}`, {
			group_id: group_id,
			last_message_id: last_message_id
		});

		if (response?.status === 200) {
			const newMessages = response?.data?.data || [];
			const mergedMessages = [...newMessages, ...groupMessages];

			setGroupMessages(mergedMessages);
			setLoadGroupMessages(false)

			if (response?.data?.data?.length === 0) {
				Cookies.set('previous', 'empty')
			}
			const newMessagesCount = newMessages.length;
			const lineHeight = 60
			const newScrollHeight = newMessagesCount * lineHeight;
			chatBody.scrollTo(0, previousScrollTop + newScrollHeight);
		}
	} catch (error) {
		if (error?.response) {
			setLoadGroupMessages(false)

		}
	}
}