export const BlackListData = ['updateProfile', 'timeOffReqest', 'userCheckInOut', 'editTimeOffRequest', 'addWallPosts',
	'initiatedChatGroup', 'addChatMessages', 'addWallPostLikes', 'wallPostlikeLists', 'addwallPostCommentLikes',
	'userCommentReply', 'wallPostUserComment', 'deleteTimeOffRequest', 'allPostCommentLists', 'addUserActivity',
	'employeeAppreciations',

	"allEmployeeData", "updateCoverPicture", "employeeSheudule", "statHolidays",

	"patientData", "addPatients", "patientsList", "updatePatientStatus", "updatePatientsProfile", "getAllClinics",
	"linkPatientsToClinic", "patientNotesList", "savePatientNote", "doctorsList", "oatFormPref", "addNewDoctor",
	"activePatients", "updateNotificationsClinics", "updateNoficationSRFax", "pharmaciesList", "RxTransferPdf", "addClinic",
	"sendFax", "pharmacyPreviewFormPdf", "pharmacyAddTeamNotes", "coverageList", "pharmacyPatientNotifications", "pharmacyPatientAddNoteReply", "pharmacyPatientNoteMarkAsRead",
	"pharmacySearch", "pharmacyCreateFolder", "pharmacyAllTableList", "pharmacyAddData",
	"addDispenseData", "searchDeliverySupervisorList", "dispenseListData", "deleteDispenseData", "deliveryDriversList",
	"archivedDelveriesList",
	"pharmacyPatientRouteList", "patientPrescriptionDetails", "searchByRxNumberArchivedList", "dispenseLabelLists", "addLabelsData", "syncTodaysDelveries", "pharmacyPatientUpdateRxWitnessDose", "patientBlisterPackList", "AddBlisterPack", "validateLabelInput", "addARVState", "arvList", "arvDelete", "deleteARVState", "dispenseMemo", "reminderUpdateMemoState", "RevenueList", "addRevenueData", "fdaList","oatList","oatUpdateData","uploadedPrescription","deleteAppState"
	, "addInventory","InventoryList","uploadPresState","appList"

	,"adminTeamList","adminAddTeamData","adminTaskComments"
]