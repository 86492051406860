import {
	FETCH_TIMESHEET_DATES_REQUEST,
	FETCH_TIMESHEET_DATES_SUCCESS,
	FETCH_TIMESHEET_DATES_FAILURE,
} from '../../constants';

const initialState = {
	timesheetDates: null,
	loading: false,
	error: null,
};

const timesheetDatesReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_TIMESHEET_DATES_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				timesheetDates: null
			};
		case FETCH_TIMESHEET_DATES_SUCCESS:
			return {
				...state,
				timesheetDates: action.payload,
				loading: false,
				error: null,
			};
		case FETCH_TIMESHEET_DATES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				timesheetDates: null,
			};
		default:
			return state;
	}
};

export default timesheetDatesReducer;
