import React, { useEffect, useRef, useState } from 'react'

import { closeChatboxOne, fileSelect } from '../../assets/js/custome'
import { Loader } from '../../components/common/loadingView'
import { useCustomDispatch } from '../../hooks'
import { useCustomDataStoringStates } from '../../hooks/customStates'
import { initiatedChatGroup } from '../../services/actions/chat/initiateChatGroupActions'
import { useCustomSelectors } from '../../services/selectors/allSelectors'
import { subscribeAddChatGroupNotification } from '../../utils/miscellaneous/ably'

const CreateChatGroup = ({ chatUsers }) => {

	const textRef = useRef()
	const dispatch = useCustomDispatch()

	const { chatUserList, profileData, pharmacyIntiatedChatResponse, pharmacyId } = useCustomSelectors()
	const { profileIds, setProfileIds } = useCustomDataStoringStates()
	const [groupData, setGroupData] = useState({ groupName: '', errorMessage: '', groupIcon: null })

	const handleProfileCheckBox = (id) => {
		if (profileIds?.includes(id)) {
			setProfileIds((prevIds) => prevIds?.filter((profileId) => profileId !== id));
		} else {
			setProfileIds((prevIds) => [...prevIds, id])
		}
		setGroupData({
			...groupData,
			errorMessage: ''
		})
	}

	const removeUser = (id) => {
		const result = profileIds.filter((value, i) => value.user_id !== id)
		setProfileIds(result)
	}

	const handleCreateGroup = () => {
		const ids = profileIds.map((value) => value.user_id)
		if (!groupData.groupName) {
			textRef.current.focus()
		} else if (profileIds?.length === 0) {
			setGroupData({
				...groupData,
				errorMessage: 'At least one contact is required*'
			})
		} else {
			dispatch(initiatedChatGroup('one_to_many', ids, null, groupData.groupName, 1, null, groupData.groupIcon, setProfileIds, setGroupData))
		}
	}

	useEffect(() => {
		if (pharmacyIntiatedChatResponse?.error !== null && pharmacyIntiatedChatResponse?.error?.status === 400) {
			setGroupData({
				...groupData,
				errorMessage: pharmacyIntiatedChatResponse?.error?.data?.error
			})
		}
	}, [pharmacyIntiatedChatResponse])

	useEffect(() => {
		const ablyConnection = subscribeAddChatGroupNotification(pharmacyId, dispatch)

		return () => {
			ablyConnection.close();
		};
	}, [pharmacyId, dispatch])

	return (
		<React.Fragment>
			<div className="sticky bottom-0 p-3">
				<div className="relative" >
					<div className="fixed-chatbox" id="fixedChatboxOne" style={{ border: '1px solid lightgrey' }}>
						<div className='flex flex-col h-full'>
							<div className="border-b py-2 px-3 flex flex-wrap items-center justify-between">
								<h4 className="fs-18 rubik-500 dark-color">Create Group</h4>
								<button
									type="button" className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => closeChatboxOne()}>
									<svg className="w-3 h-3" onClick={() => { return setProfileIds([]), setGroupData({ ...groupData, groupName: '', errorMessage: '' }) }} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
										<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
									</svg>
								</button>
							</div>

							<div className="py-2 px-3">
								<form>
									<div className="flex items-center">
										<a className='btnnn' onClick={() => fileSelect('groupIcon', setGroupData)}>
											<div id="groupIconPreview" className='w-10 h-10 rounded-full overflow-hidden'>
												<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect width="36" height="36" rx="18" fill="#E7E7E7" />
													<path d="M18 22.875C18.9375 22.875 19.7344 22.5469 20.3906 21.8906C21.0469 21.2344 21.375 20.4375 21.375 19.5C21.375 18.5625 21.0469 17.7656 20.3906 17.1094C19.7344 16.4531 18.9375 16.125 18 16.125C17.0625 16.125 16.2656 16.4531 15.6094 17.1094C14.9531 17.7656 14.625 18.5625 14.625 19.5C14.625 20.4375 14.9531 21.2344 15.6094 21.8906C16.2656 22.5469 17.0625 22.875 18 22.875ZM18 21.375C17.475 21.375 17.0313 21.1938 16.6688 20.8313C16.3063 20.4688 16.125 20.025 16.125 19.5C16.125 18.975 16.3063 18.5313 16.6688 18.1688C17.0313 17.8063 17.475 17.625 18 17.625C18.525 17.625 18.9688 17.8063 19.3313 18.1688C19.6938 18.5313 19.875 18.975 19.875 19.5C19.875 20.025 19.6938 20.4688 19.3313 20.8313C18.9688 21.1938 18.525 21.375 18 21.375ZM12 25.5C11.5875 25.5 11.2344 25.3531 10.9406 25.0594C10.6469 24.7656 10.5 24.4125 10.5 24V15C10.5 14.5875 10.6469 14.2344 10.9406 13.9406C11.2344 13.6469 11.5875 13.5 12 13.5H14.3625L15.75 12H20.25L21.6375 13.5H24C24.4125 13.5 24.7656 13.6469 25.0594 13.9406C25.3531 14.2344 25.5 14.5875 25.5 15V24C25.5 24.4125 25.3531 24.7656 25.0594 25.0594C24.7656 25.3531 24.4125 25.5 24 25.5H12ZM12 24H24V15H20.9625L19.5938 13.5H16.4062L15.0375 15H12V24Z" fill="#757575" />
												</svg>
											</div>
											<input id="groupIcon" accept="image/*" type="file" style={{ display: 'none' }} />
										</a>
										<input ref={textRef} type="text" value={groupData.groupName} onChange={(e) => { return setGroupData({ ...groupData, groupName: e.target.value }) }} className="fs-16 rubik-400 ml-2 text-gray-900 text-sm border-0 border-b block w-full p-2.5 ot_createGroupBox" placeholder="Enter group name" required />
									</div>
								</form>

							</div>
							<h1 className='flex items-center justify-center fs-14 rubik-500 text-red-500'>{groupData?.errorMessage}</h1>

							<div className="py-1 px-3">
								<div className="flex scroll-box_x overflow-auto gap-2 pb-2" style={{ maxWidth: '600px' }}>
									{profileIds?.map((value, index) => {
										return (
											<div className="relative flex-shrink-0 w-10 h-10" id={`image${index + 1}-container`} key={index}>
												<a className='block w-full h-full rounded-full overflow-hidden ' onClick={() => fileSelect()}>

													<img className='w-full h-full' id={`image${index + 1}`} src={value.photo} alt="" />
												</a>
												<svg width="18" height="18" onClick={() => removeUser(value.user_id)} className="absolute bottom-0 right-0 cursor-pointer" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<circle cx="9" cy="9" r="9" fill="white" />
													<path d="M9.00005 16.2C7.00805 16.2 5.31005 15.498 3.90605 14.094C2.50205 12.69 1.80005 10.992 1.80005 9.00005C1.80005 7.00805 2.50205 5.31005 3.90605 3.90605C5.31005 2.50205 7.00805 1.80005 9.00005 1.80005C10.992 1.80005 12.69 2.50205 14.094 3.90605C15.498 5.31005 16.2 7.00805 16.2 9.00005C16.2 10.992 15.498 12.69 14.094 14.094C12.69 15.498 10.992 16.2 9.00005 16.2ZM6.44405 12.564L9.00005 10.008L11.52 12.564L12.564 11.5561L10.008 9.00005L12.564 6.44405L11.556 5.43605L9.00005 7.99205L6.44405 5.43605L5.43605 6.44405L7.99205 9.00005L5.43605 11.5561L6.44405 12.564Z" fill="#757575" />
												</svg>
											</div>
										);
									})}
								</div>
							</div>


							<ul className="bg-white py-2 px-3 border-t flex-1 scroll-box list_chatbox__display" id="checkboxContainer">
								{chatUserList?.data?.data?.map((value, index) => {
									return (
										<React.Fragment key={index}>
											{profileData?.user_id !== value?.user_id &&
												<li className="py-2" key={index} >
													<div className="flex gap-3 items-center">
														<img src={value.photo} style={{ width: '40px' }} alt="" />
														<div className="w-full" >
															<label className="rubik-500 fs-16 dark-color w-full flex items-center cursor-pointer" > <p className="mr-auto">{value.name}</p>
																<input type="checkbox" checked={profileIds.includes(value)} onChange={() => handleProfileCheckBox(value)} id={`checkbox${index + 1}`} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-auto" />
															</label>
														</div>
													</div>
												</li>}
										</React.Fragment>)
								})}
							</ul>

							<div className="text-center w-full" onClick={handleCreateGroup}>
								<button type="button" className="rubik-400 fs-16 bg-blue-700 hover:bg-blue-800 fs-18 close_btn w-full px-5 py-2.5" data-ripple-light="true">
									{pharmacyIntiatedChatResponse?.loading ?
										<div className='flex items-center justify-center'>
											<Loader />
										</div> :
										<>
											Group <span id="selected-count"></span> Contacts
										</>}
								</button>
							</div>
						</div>

					</div>
				</div>
			</div>

		</React.Fragment>
	)
}

export default CreateChatGroup