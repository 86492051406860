import React from 'react'
import { wallListLoader } from '../../utils/helper/helper'

export const WallPostLoader = () => {

	return (<>
		{wallListLoader?.map((value, index) => {

			return (<React.Fragment key={index}>
				<div className="mt-2 mb-4 w-full border rounded-lg bg-white shadow">
					<div className="relative rounded-lg h-full top-0 bg-light w-full p-4 flex items-start space-x-3">
						<div className="animate-pulse flex gap-2 w-full">
							<svg className="w-10 h-10 text-gray-200 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
								<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
							</svg>
							<div>
								<div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
								<div className="w-48 h-3 bg-gray-200 rounded-full dark-bg-gray-700 mb-3"></div>
								<br />
								<div className="h-10  sm:w-full md:w-full lg:w-5/6 bg-gray-200 rounded-lg dark-bg-gray-700 mb-3" ></div>
								<div style={{ display: 'flex' }} className=''>
									<div className="w-full sm:w-full md:w-full lg:w-40 h-4 bg-gray-200 rounded-full dark-bg-gray-700 mb-3 mt-80"></div>
									<div className="w-full sm:w-full md:w-full lg:w-40 bg-gray-200 rounded-full dark-bg-gray-700 mb-3 mt-80 ml-10"></div>
									<div className="w-full sm:w-full md:w-full lg:w-40 h-4 bg-gray-200 rounded-full dark-bg-gray-700 mb-3 mt-80  ml-10"></div>
								</div>
							</div>
							<div className="h-4 bg-gray-300 ml-auto rounded-full dark:bg-gray-700 w-12"></div>
						</div>
					</div>
				</div>

				<div className="mt-2 mb-4 w-full border rounded-lg bg-white shadow">
					<div className="relative rounded-lg h-full top-0 bg-light w-full p-4 flex items-start space-x-3">
						<div className="animate-pulse flex gap-2 w-full">
							<svg
								className="w-10 h-10 text-gray-200 text-gray-300 dark:text-gray-700"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
							</svg>
							<div>
								<div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
								<div className="w-48 h-3 bg-gray-200 rounded-full dark-bg-gray-700 mb-3"></div>
								<br />
								<div className="h-10  sm:w-full md:w-full lg:w-5/6 bg-gray-200 rounded-lg dark-bg-gray-700 mb-3"></div>
								<div style={{ display: 'flex' }}>
									<div className="w-full sm:w-full md:w-full lg:w-40 h-4 bg-gray-200 rounded-full dark-bg-gray-700 mt-5 mb-3"></div>
									<div className="w-full sm:w-full md:w-full lg:w-40 h-4 bg-gray-200 rounded-full dark-bg-gray-700 mb-3 mt-5 ml-10"></div>
									<div className="w-full sm:w-full md:w-full lg:w-40 h-4 bg-gray-200 rounded-full dark-bg-gray-700 mb-3 mt-5  ml-10"></div>
								</div>
							</div>
							<div className="h-4 bg-gray-300 ml-auto rounded-full dark:bg-gray-700 w-12"></div>
						</div>
					</div>
				</div>
			</React.Fragment>
			)
		})}
	</>

	)
}



