import React, { useEffect, useState } from "react";

import {
    ErrorToastMessage,
    SuccessToastMessage
} from "../../../components/loaders/toastMessage";

import { fetchPharmacyEmployeesTaskList } from "../../../services/actions/admin/team/fetchTeamsList";
import { useAdminSelectors } from "../../../services/selectors/adminModuleSelectors";

import { ErrorMessages } from "../../../components/error/alertMessages";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";

import { BreadCrumb, LoadingView, Sidebar } from "../../../components/common";
import { DropdownSelect, TextInput } from "../../../components/pharmacy/dispense/dispense";
import { HandleUpdateTaskStatus } from "../../../services/actions/admin/team/addTeamData";

import * as Hooks from '../../../hooks';

import ChatBox from "../../../components/chat/chatbox";
import Button from "../../../components/common/button";
import { AddCommentsSectionModal } from "../../../models/admin/editTaskList";
import { handleShowModal } from "../../../assets/js/custome";

const PharmacyEmployeesTaskList = () => {

    const searchParams = new URLSearchParams();

    const dispatch = Hooks.useCustomDispatch();
    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();

    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors()
    const taskList = TeamListResponse?.data?.data

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [formData, setFormData] = useState({ taskStatus: '', assignTask: '', search: '' })

    const [showToast, setShowToast] = useState({ successToast: '', errorToast: '', showModal: false })
    const [statuses, setStatuses] = useState([]);
    const [logData, setLogData] = useState(null)

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        dispatch(fetchPharmacyEmployeesTaskList('task_list'))
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
        };

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(fetchPharmacyEmployeesTaskList('task_list', params))
    }

    const handleFilter = () => {
        const { taskStatus, search } = formData
        if (taskStatus || search) {
            const params = {
                status: taskStatus,
                search: search
            }
            dispatch(fetchPharmacyEmployeesTaskList('task_list', params))
        }
    }

    useEffect(() => {
        if (taskList && taskList?.tasks) {
            setStatuses(taskList?.tasks?.map(task => task.status));
        }
    }, [taskList]);

    const handleStatusChange = (index, newStatus, id) => {
        const updatedStatuses = [...statuses];
        updatedStatuses[index] = newStatus;
        setStatuses(updatedStatuses);
        dispatch(HandleUpdateTaskStatus('update_task_status', setShowToast, id, newStatus))
    };

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">

                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className='grid grid-cols-5 gap-2'>
                                            <TextInput formData={formData} setFormData={setFormData} label="Search" name='search' height="48px" />
                                            <DropdownSelect
                                                label=""
                                                name="taskStatus"
                                                formData={formData}
                                                setFormData={setFormData}
                                                options={[
                                                    { value: "", label: "Status" },
                                                    { value: "pending", label: "Pending" },
                                                    { value: "ongoing", label: "Ongoing" },
                                                    { value: "completed", label: "Completed" },
                                                    { value: "priority", label: "Priority" },
                                                    { value: "discussion", label: "Discussion" },
                                                ]}
                                            />

                                            <Button text="Filter" color="white" handleFilter={handleFilter} />
                                        </div>

                                        {TeamListResponse?.loading && TeamListResponse.type === 'task_list' ?

                                            <div className="flex items-center justify-center  mt-60 mb-80">
                                                <LoadingView />
                                            </div>

                                            : TeamListResponse?.error !== null && TeamListResponse.type === 'task_list' ?
                                                <div className="flex items-center justify-center mb-80">
                                                    <ErrorMessages />
                                                </div>
                                                : taskList?.tasks?.length === 0 && TeamListResponse.type === 'task_list' ?
                                                    <h2 className="text-gray-500 mt-2">
                                                        No Record Available.
                                                    </h2> :

                                                    TeamListResponse?.type === 'task_list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6 mt-2">

                                                            {taskList?.tasks?.map((value, index) => {
                                                                return (<React.Fragment key={index}>
                                                                    <div style={{ maxHeight: '275px', overflowX: 'auto' }} className="scroll-box max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                                        <a>
                                                                            <h5 className="mb-2 text-md font-bold tracking-tight text-gray-700 dark:text-white">{value.title}</h5>
                                                                        </a>
                                                                        <p className="mb-3 font-normal text-sm text-gray-700 dark:text-gray-400">{value.description}</p>

                                                                        <div className='flex gap-4'>
                                                                            <select
                                                                                style={{ width: '135px' }}
                                                                                id="status"
                                                                                value={statuses[index]}
                                                                                onChange={(e) => handleStatusChange(index, e.target.value, value.id)}
                                                                                className="block mt-2 scroll-box pl-4 w-full px-2.5 pb-1.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer bg-white"
                                                                            >
                                                                                <option value="pending">Pending</option>
                                                                                <option value="ongoing">Ongoing</option>
                                                                                <option value="completed">Completed</option>
                                                                                <option value="priority">Priority</option>
                                                                                <option value="discussion">Discussion</option>
                                                                            </select>


                                                                            <p className="fs-12 btnnn bg-red-500 p-2 text-white flex items-center justify-center mt-2 rounded-md" style={{ height: '36px' }} onClick={() => { return setLogData(value), handleShowModal(setShowToast, showToast, 'showModal') }}>
                                                                                comments {value?.conversations?.length > 0 ? value?.conversations?.length : null}
                                                                            </p>
                                                                        </div>

                                                                        {/* <div className='h-32 mt-2'>
                                                                            {value?.conversations?.map((data, idx) => {
                                                                                return (<React.Fragment key={idx}>
                                                                                    <div className="mb-2 p-1 borderLeftBgGreen bg-white rounded-md pl-2">
                                                                                        <p className="rubik-500 fs-14 text-gray-700">{`${data?.message}`}</p>
                                                                                        <p className=""><span className='fs-10 text-gray-700 rubik-500'>Added by : </span><span className='fs-10 text-gray-700 rubik-500'>{data?.postby_user?.name}</span></p>
                                                                                        <p className="rubik-400 fs-8 -mt-2">{data?.created_at}</p>
                                                                                    </div>
                                                                                </React.Fragment>)
                                                                            })}
                                                                        </div> */}
                                                                    </div>
                                                                </React.Fragment>)
                                                            })}

                                                            {TeamListResponse?.error !== null ? null : <>
                                                                {taskList?.tasks?.length !== 0 && <>
                                                                    {taskList?.pagination?.last_page !== 1 &&
                                                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-start sm:justify-center md:justify-center justify-center">
                                                                            <nav aria-label="Page navigation example">
                                                                                <ul className="inline-flex -space-x-px text-base h-10">
                                                                                    <CustomPagination
                                                                                        count={taskList?.pagination?.last_page}
                                                                                        page={pageNumber}
                                                                                        onChange={handlePagination}
                                                                                    />
                                                                                </ul>
                                                                            </nav>
                                                                        </div>
                                                                    }
                                                                </>}
                                                            </>}

                                                        </div>
                                                        : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showToast.successToast && addTeamDataResponse.type === 'update_task_status' && <SuccessToastMessage status={'Status updated successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'update_task_status' && <ErrorToastMessage status={'Got an issue while updating status.please try again.'} />}

            {showToast.showModal &&
                <AddCommentsSectionModal setShowToast={setShowToast} showToast={showToast} logData={logData} />}
            <ChatBox />

        </>
    )
}

export default PharmacyEmployeesTaskList