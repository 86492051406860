import { pharmacy_read_messages } from "../../../networking/urlEndPoints";
import { fetchAllRecentChats } from "./recentChatsListsActions";

import Axios from '../../../networking/intraceptor'

export async function addReadMessages(groupId, dispatch) {
	try {
		const response = await Axios.post(`${pharmacy_read_messages}`, {
			group_id: groupId,
			mark_as_read: 0
		});
		if (response?.status === 200) {
			dispatch(fetchAllRecentChats())
		}
	} catch (error) {
		if (error?.response) {
		}
	}
}
