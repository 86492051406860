import {
	PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_REQUEST,
	PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_SUCCESS,
	PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_FAILURE,

} from '../../../constants';

import Axios from '../../../../networking/intraceptor'

export const pharmacySearchDeliverySupervisorListRequest = () => ({
	type: PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_REQUEST
});

export const pharmacySearchDeliverySupervisorListSuccess = (user) => ({
	type: PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_SUCCESS,
	payload: user,
});

export const pharmacySearchDeliverySupervisorListFailure = (error) => ({
	type: PHARMACY_SEARCH_DELIVERY_SUPERVISOR_LIST_FAILURE,
	payload: error,
});

export const searchDeliverySupervisorListData = (url) => {
	return async (dispatch) => {
		dispatch(pharmacySearchDeliverySupervisorListRequest());
		try {
			const response = await Axios.get(`${url}`)

			if (response?.status === 200) {
				dispatch(pharmacySearchDeliverySupervisorListSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacySearchDeliverySupervisorListFailure(error?.response?.data));
			}
		}
	}
}

