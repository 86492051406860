import {
	DISPENSE_CUSTOM_DELETE_REQUEST,
	DISPENSE_CUSTOM_DELETE_SUCCESS,
	DISPENSE_CUSTOM_DELETE_FAILURE

} from '../../../constants';


import Axios from '../../../../networking/intraceptor'
import * as path from '../../../../networking/urlEndPoints'
import { fetchUploadPrescription } from './fetchAllDispenseListData';

// import { fetchARVList } from '../patientARVList';

export const deleteDataRequest = () => ({
	type: DISPENSE_CUSTOM_DELETE_REQUEST
});

export const deleteDataSuccess = (user, types) => ({
	type: DISPENSE_CUSTOM_DELETE_SUCCESS,
	payload: user,
	types: types
});

export const deleteDataFailure = (error, types) => ({
	type: DISPENSE_CUSTOM_DELETE_FAILURE,
	payload: error,
	types: types
})



export const handleReuploadPrescription = (avrID, type, formData, pageNumber, setShowModal, showModal, imgSrcBlob, setImgSrc, setShowPopUp,setPages) => {
	return async (dispatch) => {
		dispatch(deleteDataRequest());

		try {
			const response = await Axios.post(`${path.upload_image_prescription}`,
				{
					image_log_id: avrID,
					croppedImageData: imgSrcBlob
				})

			if (response?.status === 200) {
				dispatch(deleteDataSuccess(response?.data, type));

				if (typeof setShowModal === "function" && (type === 'ReUploadPrescriptionModal')) {
					setShowModal(prevState => ({
						...prevState,
						ReUploadPrescriptionModal: false,
						showToast: true
					}));

					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false
						}));
					}, 2000);

					document.body.style.overflow = 'auto'

					const params = {
						search: formData.search,
						page: pageNumber,
						search_date: formData.date,
						no_of_records: 10,
						route: formData.route,
						type: formData.type
					}

					dispatch(fetchUploadPrescription('prescrition_lists', params))

				}
				if (typeof setImgSrc === "function") setImgSrc('')
				if (typeof setShowPopUp === "function") setShowPopUp(false)
				if (typeof setPages === "function") setPages([])
				

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchUploadPrescription(error?.response, type));
			}
		}
	}
}

