import React, {
	useEffect,
	useRef,
	useState
} from 'react'

import {
	startDateUpdate,
	endDateUpdate,
	timeSheetStartDatePicker,
	timeSheetEndDatePicker
} from '../../assets/js/datepicker';

import {
	useCustomDispatch,
	useCustomSelector
} from '../../hooks';

import {
	Footer,
	LoadingView,
	Sidebar
} from '../../components/common'

import {
	displayScheduleForMonth,
	getTimeSchedule
} from '../../utils/helper/helper';

import { Errors } from '../../components/error/alertMessages';
import { authToken } from '../../storage/authToken';
import { fetchUserCheckInOut } from '../../services/actions/main/userCheckInOutActions';
import { timesheetDates } from '../../constants/rootReduceName';

import ChatBox from '../../components/chat/chatbox';
import Timesheet from './timeSheet';

const getMonday = (d) => {
	d = new Date(d);
	const day = d.getDay();
	const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
	return new Date(d.setDate(diff));
};

const getSunday = (monday) => {
	return new Date(monday.getTime() + 6 * 24 * 60 * 60 * 1000); // add 6 days to Monday
};

const formatDate = (date) => {
	const year = date.getFullYear().toString().slice(2);
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return `${year}-${month}-${day}`;
};


const TimeSchedule = () => {

	const today = new Date();
	const initialStartDate = getMonday(today);

	const dispatch = useCustomDispatch()
	const token = authToken()

	const checkInOutResponse = useCustomSelector(state => state.userCheckInOut)
	const checkInOutlist = checkInOutResponse?.userCheckInOut?.data
	const dates = useCustomSelector(state => state[timesheetDates]?.timesheetDates)

	const initialEndDate = getSunday(initialStartDate);

	const endDatePickerRef = useRef(null);
	const startDatePickerRef = useRef(null)
	const year = new Date()?.getFullYear()

	const [startDateValue, setStartDateValue] = useState(dates?.start_date)
	// const [startDateValue, setStartDateValue] = useState(formatDate(initialStartDate))
	const [endDateValue, setEndDateValue] = useState(formatDate(initialEndDate))

	// const [endDateValue, setEndDateValue] = useState(formatDate(initialEndDate))

	const [message, setMessage] = useState('')
	const [apiCallMade, setApiCallMade] = useState(false)

	const [startMonth, setStartMonth] = useState('')
	const [endMonth, setEndMonth] = useState('')


	useEffect(() => {
		const startDatepickerInstance = timeSheetStartDatePicker(startDatePickerRef, setStartDateValue, setMessage, startDateValue, setEndDateValue);
		return () => {
			if (startDatepickerInstance) {
				startDatepickerInstance.destroy();
			}
		}
	}, [])

	useEffect(() => {
		const endDatepickerInstance = timeSheetEndDatePicker(endDatePickerRef, setEndDateValue, setMessage, endDateValue);

		return () => {
			if (endDatepickerInstance) {
				endDatepickerInstance.destroy();
			}
		};
	}, [endDateValue]);

	const handleGetTimeSchedule = () => {
		getTimeSchedule(startDateValue, endDateValue, setMessage, dispatch)
	};

	useEffect(() => {
		if (startDateValue && endDateValue && !apiCallMade && token) {
			dispatch(fetchUserCheckInOut(startDateValue, endDateValue))
			setApiCallMade(true)
		}
	}, [])

	useEffect(() => {
		displayScheduleForMonth(startDateValue, endDateValue, setStartMonth, setEndMonth)
	}, [startDateValue, endDateValue])

	return (
		<>
			<div className="BodyBgColor">
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							{/* <!-- Time Sheets Start --> */}
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="fs-30 rubik-500 dark-color">Time Sheet</h2>
										{
											startMonth ?
												<p className="fs-18 rubik-400 lightBlue-color"> {startMonth} {year} </p>
												: null
										}
									</div>

									<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8">
										<div className="relative content-between ti_heightSchedule justify-center sm:justify-center md:justify-end lg:justify-end" >
											<div className="ti_heightScheduleInnerDate flex items-end justify-center sm:justify-center md:justify-end lg:justify-end gap-2">
												<div>
													<label htmlFor="" className="rubik-400 fs-18  w-full block text-start sm:text-start md:text-start lg:text-start lightBlue-color" style={{ fontSize: '14px' }}> Start Date</label>
													<div className=' flex justify-center sm:justify-center md:justify-end lg:justify-end  datepicker'>
														<button onClick={() => startDateUpdate(-7, startDatePickerRef)} type="button" id="prevDate"
															className="flex justify-center leftArrow inline-flex items-center px-3 text-sm text-gray-900 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
															<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_30_2-851315)">
																	<path opacity="0.5" d="M0.792893 8.70711C0.402369 8.31658 0.402369 7.68342 0.792892 7.29289L7.15685 0.928933C7.54738 0.538409 8.18054 0.538409 8.57107 0.928933C8.96159 1.31946 8.96159 1.95262 8.57107 2.34315L2.91421 8L8.57107 13.6569C8.96159 14.0474 8.96159 14.6805 8.57107 15.0711C8.18054 15.4616 7.54738 15.4616 7.15685 15.0711L0.792893 8.70711ZM16.5 9H1.5V7H16.5V9Z" fill="#212121" />
																</g>
																<defs>
																	<clipPath id="clip0_30_2-851315">
																		<rect width="17" height="16" fill="white" />
																	</clipPath>
																</defs>
															</svg>
														</button>

														<div className="relative">
															<svg className="absolute top-4 left-3" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_30_6-142245)">
																	<path d="M7.16667 10.6667V9.00002H8.83333V10.6667H7.16667ZM3.83333 10.6667V9.00002H5.5V10.6667H3.83333ZM10.5 10.6667V9.00002H12.1667V10.6667H10.5ZM7.16667 14V12.3334H8.83333V14H7.16667ZM3.83333 14V12.3334H5.5V14H3.83333ZM10.5 14V12.3334H12.1667V14H10.5ZM0.5 17.3334V1.91669H3.10417V0.666687H4.45833V1.91669H11.5417V0.666687H12.8958V1.91669H15.5V17.3334H0.5ZM1.75 16.0834H14.25V7.12502H1.75V16.0834ZM1.75 5.87502H14.25V3.16669H1.75V5.87502Z" fill="#121F3E" />
																</g>
																<defs>
																	<clipPath id="clip0_30_6-142245">
																		<rect width="16" height="18" fill="white" />
																	</clipPath>
																</defs>
															</svg>
															<input id="datepicker" readOnly ref={startDatePickerRef} value={startDateValue} type="text" height="50" className="w-full rubik-400 fs-16 bg-white border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block  pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Start date" />
														</div>

														<button onClick={() => startDateUpdate(7, startDatePickerRef)} id="nextDate" type="button" className="flex justify-center  rightArrow rubik-500 fs-16 inline-flex items-center px-3 text-sm text-gray-900 border border-l-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
															<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_30_4-840848)">
																	<path opacity="0.5" d="M16.2071 8.70711C16.5976 8.31658 16.5976 7.68342 16.2071 7.29289L9.84315 0.928933C9.45262 0.538409 8.81946 0.538409 8.42893 0.928933C8.03841 1.31946 8.03841 1.95262 8.42893 2.34315L14.0858 8L8.42893 13.6569C8.03841 14.0474 8.03841 14.6805 8.42893 15.0711C8.81946 15.4616 9.45262 15.4616 9.84315 15.0711L16.2071 8.70711ZM0.5 9H15.5V7H0.5V9Z" fill="#212121" />
																</g>
																<defs>
																	<clipPath id="clip0_30_4-840848">
																		<rect width="17" height="16" fill="white" />
																	</clipPath>
																</defs>
															</svg>
														</button>
													</div>
												</div>

												<div className="ti_heightScheduleInnerEndDate">
													<label htmlFor="" className="rubik-400 fs-18 w-full block text-start sm:text-start md:text-start lg:text-start lightBlue-color" style={{ fontSize: '14px' }}> End Date</label>
													<div className="flex justify-center sm:justify-center md:justify-end lg:justify-end  datepicker">
														<button onClick={() => endDateUpdate(-7, endDatePickerRef)} type="button" id="prevDate" className="flex justify-center leftArrow inline-flex items-center px-3 text-sm text-gray-900 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
															<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_30_2-851315)">
																	<path opacity="0.5" d="M0.792893 8.70711C0.402369 8.31658 0.402369 7.68342 0.792892 7.29289L7.15685 0.928933C7.54738 0.538409 8.18054 0.538409 8.57107 0.928933C8.96159 1.31946 8.96159 1.95262 8.57107 2.34315L2.91421 8L8.57107 13.6569C8.96159 14.0474 8.96159 14.6805 8.57107 15.0711C8.18054 15.4616 7.54738 15.4616 7.15685 15.0711L0.792893 8.70711ZM16.5 9H1.5V7H16.5V9Z" fill="#212121" />
																</g>
																<defs>
																	<clipPath id="clip0_30_2-851315">
																		<rect width="17" height="16" fill="white" />
																	</clipPath>
																</defs>
															</svg>
														</button>

														<div className="relative">
															<svg className="absolute top-4 left-3" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_30_6-142245)">
																	<path d="M7.16667 10.6667V9.00002H8.83333V10.6667H7.16667ZM3.83333 10.6667V9.00002H5.5V10.6667H3.83333ZM10.5 10.6667V9.00002H12.1667V10.6667H10.5ZM7.16667 14V12.3334H8.83333V14H7.16667ZM3.83333 14V12.3334H5.5V14H3.83333ZM10.5 14V12.3334H12.1667V14H10.5ZM0.5 17.3334V1.91669H3.10417V0.666687H4.45833V1.91669H11.5417V0.666687H12.8958V1.91669H15.5V17.3334H0.5ZM1.75 16.0834H14.25V7.12502H1.75V16.0834ZM1.75 5.87502H14.25V3.16669H1.75V5.87502Z" fill="#121F3E" />
																</g>
																<defs>
																	<clipPath id="clip0_30_6-142245">
																		<rect width="16" height="18" fill="white" />
																	</clipPath>
																</defs>
															</svg>
															<input id="datepicker" readOnly value={endDateValue} ref={endDatePickerRef} type="text" height="50" className="rubik-400 fs-16 bg-white border w-full border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="End date" />
														</div>

														<button onClick={() => endDateUpdate(7, endDatePickerRef)} id="nextDate" type="button" className="flex justify-center  rightArrow rubik-500 fs-16 inline-flex items-center px-3 text-sm text-gray-900 border border-l-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
															<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_30_4-840848)">
																	<path opacity="0.5" d="M16.2071 8.70711C16.5976 8.31658 16.5976 7.68342 16.2071 7.29289L9.84315 0.928933C9.45262 0.538409 8.81946 0.538409 8.42893 0.928933C8.03841 1.31946 8.03841 1.95262 8.42893 2.34315L14.0858 8L8.42893 13.6569C8.03841 14.0474 8.03841 14.6805 8.42893 15.0711C8.81946 15.4616 9.45262 15.4616 9.84315 15.0711L16.2071 8.70711ZM0.5 9H15.5V7H0.5V9Z" fill="#212121" />
																</g>
																<defs>
																	<clipPath id="clip0_30_4-840848">
																		<rect width="17" height="16" fill="white" />
																	</clipPath>
																</defs>
															</svg>
														</button>
													</div>
												</div>

												<div className="text-center">
													<button type="button" className="ti_heightScheduleInnerDateBtn blueBg-color mx-auto sm:text-center md:text-start lg:text-start rubik-400 fs-16 text-white rounded-lg text-sm px-5 py-3 mt-3 mb-3 sm:mt-3 sm:mb-3 md:mt-0 md:mb-0 lg:mt-0 lg:mb-0" onClick={handleGetTimeSchedule} style={{ height: '50px' }}>Apply</button>
												</div>
											</div>

											{message !== '' ?
												<div className='mt-2'>
													<Errors error={message} />
												</div> : null}
										</div>
									</div>
								</div>

								{
									checkInOutResponse?.loading ?
										<div className="flex justify-center items-center mt-60">
											<LoadingView />
										</div>
										:

										checkInOutlist?.length === 0 ?

											<div className="flex flex-col items-center justify-center mt-60">
												<div className="p-4 mb-4 text-center text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
													<span className="font-medium text-xl">Oops!</span><br />
													<p className="text-gray-600 dark:text-gray-300 mt-2 rubik-400">It seems that there are no check-in/check-out records found.</p>
												</div>
											</div>

											:

											checkInOutResponse?.error !== null ?
												<div className="flex flex-col items-center justify-center mt-60">
													<div className="p-4 mb-4 text-center text-red-800 rounded-lg bg-red-50 dark:bg-red-600 dark:text-white" role="alert">
														<span className="font-semibold text-2xl">Oops! Something went wrong</span><br />
														<p className="text-gray-600 dark:text-gray-300 mt-2 rubik-400 text-lg">We encountered an issue while processing your request. Please try again later.</p>
													</div>
												</div>

												:

												<div className="relative overflow-x-auto rounded-lg border mt-2">
													<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-nowrap lg:whitespace-normal">
														<thead className="text-xs text-gray-700 tableBg-color dark:text-gray-400">
															<tr>
																<th scope="col" className="px-6 py-3 border border-t-0 text-center">
																	<h3 className="rubik-300 text-sm text-left">Day</h3>
																</th>
																<th scope="col" className="px-6 py-3 border border-t-0 text-center">
																	<h3 className="rubik-300 text-sm">Check-in</h3>
																</th>
																<th scope="col" className="px-6 py-3 border border-t-0 text-center">
																	<h3 className="rubik-300 text-sm">Break-in</h3>
																</th>
																<th scope="col" className="px-6 py-3 border border-t-0 text-center">
																	<h3 className="rubik-300 text-sm">Break-out</h3>
																</th>
																<th scope="col" className="px-6 py-3 border border-t-0 text-center">
																	<h3 className="rubik-300 text-sm">Check-out</h3>
																</th>
																<th scope="col" className="px-6 py-3 border border-t-0 border-r-0 text-center">
																	<h3 className="rubik-300 text-sm">Total Hours</h3>
																</th>
															</tr>
														</thead>

														<tbody>
															<Timesheet />
														</tbody>
													</table>
												</div>
								}
							</div>
							<ChatBox />
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</>
	)
}

export default TimeSchedule