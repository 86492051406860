import { addNewDoctor } from "../../services/actions/pharmacy/doctors/addDoctors";
import { addNewClinic } from "../../services/actions/pharmacy/clinic/addNewClinic";
import { updateOatPref } from "../../services/actions/patients/pharmacyOatFormPrefUpdate";
import { savePatientNotes } from "../../services/actions/patients/savePatientNote";
import { fetchPatientData } from "../../services/actions/patients/patientDataPhnAction";
import { sendTimeOffRequest } from "../../services/actions/main/timeOffRequestActions";
import { addPatientData } from "../../services/actions/patients/addPatientDataActions";
import { updatePatientsProfileData } from "../../services/actions/patients/updatePatientsProfile";

export const submitPatientNote = (
	selectedNoteOptions,
	setNotesData,
	notesData,
	patientProfileData,
	setShowToast,
	showToast,
	paginationNumber,
	location,
	showModal,
	setShowModal,
	dispatch,
	queryStatus,
	querySearch,
	color
) => {
	if (selectedNoteOptions?.length === 0) {
		setNotesData({
			...notesData,
			message: 'Please select from note option'
		})

	} else if (!notesData.notes) {
		setNotesData({
			...notesData,
			message: 'Note is Required'
		})
	} else {
		setNotesData({
			...notesData,
			message: null
		})

		dispatch(savePatientNotes(patientProfileData?.patient_id ? patientProfileData?.patient_id : patientProfileData?.id,
			notesData,
			showToast,
			setShowToast,
			paginationNumber,
			location,
			showModal,
			setShowModal,
			queryStatus,
			querySearch,
			color
		))
	}
}

export const handleUpdatePrescribingDoctors = (patient_id,
	selectedOptions,
	setMessage,
	dispatch,
	defaultId,
	showModal,
	setShowModal) => {
	if (patient_id) {
		if (selectedOptions?.length === 0) {
			setMessage('Please Select')
		} else {
			setMessage('')
			dispatch(updateOatPref('singledoctor', patient_id, selectedOptions?.value, defaultId,
				showModal,
				setShowModal,
			))
		}
	}
}

export const handleUpdateClinics = (patient_id, selectedOptions, setMessage, dispatch, defaultId, showModal, setShowModal) => {
	if (patient_id) {
		if (selectedOptions?.length === 0) {
			setMessage('Please Select')
		} else {
			setMessage('')
			dispatch(updateOatPref('singleclinic', patient_id, selectedOptions?.value, defaultId,
				showModal,
				setShowModal,
			))
		}
	}
}

export const handleSaveDoctors = (formData, setErrorMessages, errorMessages, dispatch, setShowModal, showModal) => {
	let newErrorMessages = {};
	let allDataPresent = true;

	if (!formData.lastName) {
		newErrorMessages = {
			...newErrorMessages,
			lastName: 'Last Name is required.',
		};
		allDataPresent = false;
	}

	if (!formData.firstName) {
		newErrorMessages = {
			...newErrorMessages,
			firstName: 'First Name is required.',
		};
		allDataPresent = false;
	}

	if (!formData.cps_id) {
		newErrorMessages = {
			...newErrorMessages,
			cps_id: 'CPS ID Number is required.',
		};
		allDataPresent = false;
	}

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	if (allDataPresent) {
		dispatch(addNewDoctor(formData, setShowModal, showModal))
	}
};

export const handleSaveClinicData = (formData, setErrorMessages, errorMessages, dispatch, fullAddress, setShowModal, showModal) => {
	let newErrorMessages = {};
	let allDataPresent = true;

	if (!formData.clinicName) {
		newErrorMessages = {
			...newErrorMessages,
			clinicName: 'Clinic is required.',
		};
		allDataPresent = false;
	} else {
		newErrorMessages = {
			...newErrorMessages,
			clinicName: '',
		};
	}

	if (!formData.phone) {
		newErrorMessages = {
			...newErrorMessages,
			phone: 'Phone is required.',
		};
		allDataPresent = false;
	} else {
		newErrorMessages = {
			...newErrorMessages,
			phone: '',
		};
	}

	if (!formData.phoneExtension) {
		newErrorMessages = {
			...newErrorMessages,
			phoneExtension: 'Phone is required.',
		};
		allDataPresent = false;
	} else {
		newErrorMessages = {
			...newErrorMessages,
			phoneExtension: '',
		};
	}

	if (!formData.city) {
		newErrorMessages = {
			...newErrorMessages,
			city: 'City is required.',
		};
		allDataPresent = false;
	} else {
		newErrorMessages = {
			...newErrorMessages,
			city: '',
		};
	}

	if (!formData.fax) {
		newErrorMessages = {
			...newErrorMessages,
			fax: 'fax is required.',
		};
		allDataPresent = false;
	} else {
		newErrorMessages = {
			...newErrorMessages,
			fax: '',
		};
	}

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	if (allDataPresent) {
		dispatch(addNewClinic(formData, fullAddress, setShowModal, showModal))
	}
};

export const handleGetPatientWinRxData = (patientFormData,
	setFormDataBorderColor,
	formDataBorderColor,
	setFormFieldErrors,
	dispatch,
	formFeildsErrors) => {
	if (!patientFormData?.phn) {
		setFormDataBorderColor({ ...formDataBorderColor, phn: '#e85347' })
		setFormFieldErrors({ ...formFeildsErrors, phn: 'PHN is required' })
	} else {
		dispatch(fetchPatientData(patientFormData?.phn))
	}
}


export const validateAddPatientForm = (
	patientFormData,
	setFormDataBorderColor,
	formDataBorderColor,
	setFormFieldErrors,
	intitalFormErrors,
	pateintAddress,
	dispatch,
	navigate,
	location
) => {
	let newBorderColor = { ...formDataBorderColor };
	let newFieldErrors = { ...intitalFormErrors };
	let allDataPresent = true;
	if (!patientFormData.phn) {
		newBorderColor = {
			...newBorderColor,
			phn: '#e85347'
		};
		newFieldErrors = {
			...newFieldErrors,
			phn: 'PHN is required'
		};
		allDataPresent = false;
	} else {
		newBorderColor = {
			...newBorderColor,
			phn: 'lightgrey'
		}
		newFieldErrors = {
			...newFieldErrors,
			phn: ''
		};
	}

	if (!patientFormData.firstName) {
		newBorderColor = {
			...newBorderColor,
			firstName: '#e85347'
		};
		newFieldErrors = {
			...newFieldErrors,
			firstName: 'First Name is required'
		};
		allDataPresent = false;
	} else {
		newBorderColor = {
			...newBorderColor,
			firstName: 'lightgrey'
		};

		newFieldErrors = {
			...newFieldErrors,
			firstName: ''
		};
	}

	if (!patientFormData.lastName) {
		newBorderColor = {
			...newBorderColor,
			lastName: '#e85347'
		};
		newFieldErrors = {
			...newFieldErrors,
			lastName: 'Last Name is required'
		}
		allDataPresent = false;
	} else {
		newBorderColor = {
			...newBorderColor,
			lastName: 'lightgrey'
		};
		newFieldErrors = {
			...newFieldErrors,
			lastName: ''
		}
	}

	if (!patientFormData.route) {
		newBorderColor = {
			...newBorderColor,
			route: '#e85347'
		};
		newFieldErrors = {
			...newFieldErrors,
			route: 'Route is required'
		};
		allDataPresent = false;
	} else {
		newBorderColor = {
			...newBorderColor,
			route: 'lightgrey'
		};
		newFieldErrors = {
			...newFieldErrors,
			route: ''
		};
	}

	if (!patientFormData.gender) {
		newBorderColor = {
			...newBorderColor,
			gender: '#e85347'
		};
		newFieldErrors = {
			...newFieldErrors,
			gender: 'Gender is required'
		}
		allDataPresent = false;
	} else {
		newBorderColor = {
			...newBorderColor,
			gender: 'lightgrey'
		};
		newFieldErrors = {
			...newFieldErrors,
			gender: ''
		}
	}

	setFormDataBorderColor(newBorderColor);
	setFormFieldErrors(newFieldErrors);
	if (allDataPresent) {
		dispatch(addPatientData(patientFormData, pateintAddress, navigate, location))
	}
}


export function validateNewTypeoFFRequestForm(formData, isChecked, endDateValue, totalHours, dispatch, navigate, startDateValue) {
	if (!formData.timeOfType) {
		return 'Time Type is required';
	} else if (isChecked === true) {
		if (!endDateValue) {
			return 'End Date is required';
		} else if (!totalHours) {
			return 'Total Hours is required';
		} else {
			dispatch(sendTimeOffRequest(formData, startDateValue, endDateValue, isChecked, totalHours, navigate));
			return null;
		}
	} else if (isChecked === false) {
		if (!formData.startTime) {
			return 'Start Time is required';
		} else if (!formData.endTime) {
			return 'End Time is required';
		} else {
			dispatch(sendTimeOffRequest(formData, startDateValue, endDateValue, isChecked, '', navigate));
			return null;
		}
	} else {
		return null
	}
}



export const validatePatientUpdateProfileForm = (
	updatePatientFormData,
	setFormDataBorderColor,
	formDataBorderColor,
	setFormFieldErrors,
	intitalFormErrors,
	updatePatientAddress,
	dispatch,
	navigate,
	patient_id,
	paginationNumber,
	hideModal,
	location,
	queryStatus,
	querySearch,
	setShowModal,
	showModal
) => {
	let newBorderColor = { ...formDataBorderColor };
	let newFieldErrors = { ...intitalFormErrors };
	let allDataPresent = true;
	if (!updatePatientFormData.phn) {
		newBorderColor = {
			...newBorderColor,
			phn: '#e85347'
		};
		newFieldErrors = {
			...newFieldErrors,
			phn: 'PHN is required'
		};
		allDataPresent = false;
	} else {
		newBorderColor = {
			...newBorderColor,
			phn: 'lightgrey'
		};
	}

	if (!updatePatientFormData.firstName) {
		newBorderColor = {
			...newBorderColor,
			firstName: '#e85347'
		};
		newFieldErrors = {
			...newFieldErrors,
			firstName: 'First Name is required'
		};
		allDataPresent = false;
	} else {
		newBorderColor = {
			...newBorderColor,
			firstName: 'lightgrey'
		};
	}

	if (!updatePatientFormData.lastName) {
		newBorderColor = {
			...newBorderColor,
			lastName: '#e85347'
		};
		newFieldErrors = {
			...newFieldErrors,
			lastName: 'Last Name is required'
		};
		allDataPresent = false;
	} else {
		newBorderColor = {
			...newBorderColor,
			lastName: 'lightgrey'
		};
	}

	if (!updatePatientFormData.route) {
		newBorderColor = {
			...newBorderColor,
			route: '#e85347'
		};
		newFieldErrors = {
			...newFieldErrors,
			route: 'Route is required'
		};
		allDataPresent = false;
	} else {
		newBorderColor = {
			...newBorderColor,
			route: 'lightgrey'
		};
	}

	setFormDataBorderColor(newBorderColor);
	setFormFieldErrors(newFieldErrors);

	if (allDataPresent) {
		dispatch(updatePatientsProfileData(updatePatientFormData,
			updatePatientAddress,
			patient_id,
			paginationNumber,
			hideModal,
			location,
			queryStatus,
			querySearch,
			setShowModal,
			showModal
		))
	}
}
