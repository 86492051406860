import {
	DELETE_TIMEOFF_REQUEST,
	DELETE_TIMEOFF_REQUEST_SUCCESS,
	DELETE_TIMEOFF_REQUEST_FAILURE
} from '../../constants/index';

import Axios from '../../../networking/intraceptor';
import { pharmacy_timeoff_list_delete } from '../../../networking/urlEndPoints';
import { fetchTimeoOffLists } from './pharmacyTimeOffListActions';

export const deleteTimeOffRequest = () => ({
	type: DELETE_TIMEOFF_REQUEST,
});

export const deleteTimeOffSuccess = (data) => ({
	type: DELETE_TIMEOFF_REQUEST_SUCCESS,
	payload: data,
});

export const deleteTimeOffFailure = (error) => ({
	type: DELETE_TIMEOFF_REQUEST_FAILURE,
	payload: error,
})

export const deleteTimeOffRequestList = (deleteId) => {
	return async (dispatch) => {
		dispatch(deleteTimeOffRequest())
		try {
			const response = await Axios.post(`${pharmacy_timeoff_list_delete}/${deleteId}`)
			if (response?.status === 200) {
				dispatch(deleteTimeOffSuccess(response?.data));
				dispatch(fetchTimeoOffLists())
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(deleteTimeOffFailure(error?.response))
			}
		}
	};
};