import {
	FETCH_PATIENT_ACTIVE_LIST_REQUEST,
	FETCH_PATIENT_ACTIVE_LIST_SUCCESS,
	FETCH_PATIENT_ACTIVE_LIST_FAILURE
} from "../../constants"

import { pharmacy_active_patient_list } from "../../../networking/urlEndPoints"

import Axios from "../../../networking/intraceptor"

export const activePatientsListRequest = () => ({
	type: FETCH_PATIENT_ACTIVE_LIST_REQUEST,
})

export const activePatientsListSuccess = (data) => ({
	type: FETCH_PATIENT_ACTIVE_LIST_SUCCESS,
	payload: data,
})

export const activePatientsListFailure = (error) => ({
	type: FETCH_PATIENT_ACTIVE_LIST_FAILURE,
	payload: error,
})

export const fetchAllActivePatientList = () => {
	return async (dispatch) => {
		dispatch(activePatientsListRequest())
		try {
			const response = await Axios.get(`${pharmacy_active_patient_list}`)
			if (response?.status === 200) {
				dispatch(activePatientsListSuccess(response?.data))
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(activePatientsListFailure(error?.response))
			}
		}
	}
}
