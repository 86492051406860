import { fabric } from 'fabric';
import WebFont from 'webfontloader';

export const addText = (canvas, options) => {
    const textbox = new fabric.Textbox(options.text, {
        ...options,
        left: canvas.getWidth() / 2,
        top: canvas.getHeight() / 2,
        originX: 'center',
        originY: 'center',
    });

    textbox.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -20,
        offsetX: 0,
        cursorStyle: 'pointer',
        mouseUpHandler: function (eventData, target) {
            deleteActiveObject(canvas);
        },
        render: function (ctx, left, top, styleOverride, fabricObject) {
            renderDeleteIcon(ctx, left, top, styleOverride, fabricObject);
        },
    });

    canvas.add(textbox);
    canvas.setActiveObject(textbox);

    canvas.renderAll();
};

export const renderDeleteIcon = (ctx, left, top, styleOverride, fabricObject) => {
    const size = 20;
    ctx.save();
    ctx.translate(left, top);

    if (fabricObject && fabricObject.angle !== undefined) {
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    }
    ctx.fillStyle = '#FF0000';
    ctx.fillRect(-size / 2, -size / 2, size, size);
    ctx.strokeStyle = '#FFFFFF';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(-size / 2 + 4, -size / 2 + 4);
    ctx.lineTo(size / 2 - 4, size / 2 - 4);
    ctx.moveTo(-size / 2 + 4, size / 2 - 4);
    ctx.lineTo(size / 2 - 4, -size / 2 + 4);
    ctx.stroke();
    ctx.restore();
};

export const deleteActiveObject = (canvas) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
        canvas.remove(activeObject);
        canvas.renderAll();
    }
};

export const updateTextControl = (canvas, setStateFunctions) => {
    const {
        setFontSizeInput,
        setLineHeightInput,
        setColorInput,
        setIsUppercase,
        setIsBold,
        setisItalic,
        setIsUnderline,
        setIsLinethrough,
        setActiveAlignment,
        setFontWeight,
        setSelectedFont
    } = setStateFunctions;

    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        setFontSizeInput(activeObject.fontSize.toString());
        setLineHeightInput(activeObject.lineHeight.toString());
        setColorInput(activeObject.fill);
        setIsUppercase(activeObject.text === activeObject.text.toUpperCase());
        setIsBold(activeObject.fontWeight === 'bold');
        setisItalic(activeObject.fontStyle === 'italic');
        setIsUnderline(activeObject.underline);
        setIsLinethrough(activeObject.linethrough);
        setActiveAlignment(activeObject.textAlign || 'left'); // Update active alignment state
        setFontWeight(activeObject.fontWeight); // Update font weight state
        setSelectedFont(activeObject.fontFamily)
    }
};

export const clearTextControl = (setStateFunctions) => {
    const {
        setFontSizeInput,
        setLineHeightInput,
        setColorInput,
        setIsUppercase,
        setIsBold,
        setisItalic,
        setIsUnderline,
        setIsLinethrough,
        setActiveAlignment,
    } = setStateFunctions;

    setFontSizeInput('');
    setLineHeightInput('');
    setColorInput('');
    setIsUppercase(false);
    setIsBold(false);
    setisItalic(false)
    setIsUnderline(false);
    setIsLinethrough(false);
    setActiveAlignment(false); // Reset active alignment state
};

export const handleFontFamilyChange = (canvas, selectedFont, setSelectedFont) => {
    setSelectedFont(selectedFont);

    // Load selected Google font using WebFont loader
    WebFont.load({
        google: {
            families: [selectedFont],
        },
        active: () => {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                activeObject.set('fontFamily', selectedFont);
                canvas.renderAll();
            }
        },
    });
};

export const handleTextAlignment = (canvas, alignment, setActiveAlignment) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        activeObject.set('textAlign', alignment);
        canvas.renderAll();
        setActiveAlignment(alignment); // Update active alignment state
    }
};

export const handleFontSizeChange = (canvas, event, setFontSizeInput) => {
    const newSize = parseInt(event.target.value);
    if (!isNaN(newSize)) {
        setFontSizeInput(newSize.toString());
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'textbox') {
            activeObject.set('fontSize', newSize);
            canvas.renderAll();
        }
    }
};

export const handleLineHeightChange = (canvas, event, setLineHeightInput) => {
    const newLineHeight = parseFloat(event.target.value);

    if (!isNaN(newLineHeight) && newLineHeight >= 0.1) {
        setLineHeightInput(newLineHeight.toString());
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'textbox') {
            activeObject.set('lineHeight', newLineHeight);
            canvas.renderAll();
        }
    } else {
       setLineHeightInput('0.1');
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'textbox') {
            activeObject.set('lineHeight', 0.1);
            canvas.renderAll();
        }
    }
};

export const handleColorChange = (canvas, event, setColorInput) => {
    setColorInput(event.target.value);
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        activeObject.set('fill', event.target.value);
        canvas.renderAll();
    }
};

export const handleUppercaseToggle = (canvas, isUppercase, setIsUppercase) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        const newText = isUppercase
            ? activeObject.text.toLowerCase()
            : activeObject.text.toUpperCase();
        activeObject.set('text', newText);
        canvas.renderAll();
        setIsUppercase(!isUppercase);
    }
};

export const handleBoldToggle = (canvas, isBold, setIsBold) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        const newFontWeight = isBold ? 'normal' : 'bold';
        activeObject.set('fontWeight', newFontWeight);
        canvas.renderAll();
        setIsBold(!isBold);
    }
};

export const handleUnderlineToggle = (canvas, isUnderline, setIsUnderline) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        const newUnderline = !isUnderline;
        activeObject.set('underline', newUnderline);
        canvas.renderAll();
        setIsUnderline(newUnderline);
    }
};

export const handleItalicToggle = (canvas, isItalic, setisItalic) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        const newItalic = !isItalic ? 'italic' : 'normal';
        activeObject.set('fontStyle', newItalic);
        canvas.renderAll();
        setisItalic(!isItalic);
    }
};

export const handleLinethroughToggle = (canvas, isLinethrough, setIsLinethrough) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        const newLinethrough = !isLinethrough;
        activeObject.set('linethrough', newLinethrough);
        canvas.renderAll();
        setIsLinethrough(newLinethrough);
    }
};

export const handleFontWeightChange = (canvas, event, setFontWeight) => {
    const newFontWeight = event.target.value;
    setFontWeight(newFontWeight);
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        activeObject.set('fontWeight', newFontWeight);
        canvas.renderAll();
    }
};

export const handleBoldCaseToggle = (canvas, isBold, setIsBold) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
        const newFontWeight = isBold ? 'normal' : 'bold';
        activeObject.set('fontWeight', newFontWeight);
        canvas.renderAll();
        setIsBold(!isBold);
    }
};

export const addNewDataToCanvas = (canvas, text = '{{employee_name}}') => {
    if (canvas) {
        const textbox = new fabric.Textbox(text, {
            fontSize: 20,
            lineHeight: 1.2,
            fill: '#000000',
            width: 200,
            fontWeight: 500,
            textAlign: 'center',
            left: canvas.getWidth() / 2,
            top: canvas.getHeight() / 2,
            originX: 'center',
            originY: 'center',
        });
        canvas.add(textbox);
        canvas.setActiveObject(textbox);
        canvas.renderAll();
    }
};