import { removeWorkSpaceTab } from '../utils/helper/helper'

import * as routesNames from '../routes/routeName'
import * as Icons from '../assets/svg/icons'

export const token = 'authToken'

export const moreComments = 'more'

export const previousCommentsReply = 'previous'

export const createNavigationButtonsWithTooltips = [
	{ route: routesNames.pharmacy_tasks_list, icon: <Icons.TaskAssignIcon />, tooltipsValue: 'Tasks Assignments' },
	{ route: routesNames.ALLREQUEST_ROUTE, icon: <Icons.HeaderTimeOffRequestIcon />, tooltipsValue: 'Time Off Request' },
	{ route: routesNames.APPRECIATIONS_ROUTE, icon: <Icons.HeaderAppreciationsIcons />, tooltipsValue: 'Appreciations' },
	{ route: routesNames.ALLEMPLOYEES_ROUTE, icon: <Icons.HeaderTeamsIcon />, tooltipsValue: 'Team' },
	{ route: routesNames.SETTINGS_ROUTE, icon: <Icons.HeaderSettingIcons />, tooltipsValue: 'Settings' },
	{ route: routesNames.pharmacy_all_notifications, icon: <Icons.BellIcon />, tooltipsValue: 'All Notifications' }
];

export const HeaderdropDownItems = (navigate, setDropdownOpen, setModalOpen, isModalOpen) => [
	{
		label: "My Profile",
		icon: <Icons.ProfileIcon />,
		onClick: () => { navigate(routesNames.MY_PROFILE); removeWorkSpaceTab(); }
	},
	{
		label: "Time Sheets",
		icon: <Icons.TimeSheetIcon />,
		onClick: () => { navigate(routesNames.TIMESHEET_ROUTE); removeWorkSpaceTab(); }
	},
	{
		label: "Settings",
		icon: <Icons.SettingIcon />,
		onClick: () => { navigate(routesNames.SETTINGS_ROUTE); removeWorkSpaceTab(); }
	},
	{
		label: "Logout",
		icon: <Icons.LogOutIcon />,
		onClick: () => { setModalOpen(!isModalOpen); setDropdownOpen(false); }
	}
]

export const dispenseRecurrenceValues = [{ name: 'Daily' }, { name: 'Every Monday' },
{ name: 'Every Tuesday' }, { name: 'Every Wednesday' }, { name: 'Every Thursday' },
{ name: 'Every Friday' }, { name: 'Every Saturday' }, { name: 'Every Sunday' }]
