import React from 'react'

const Footer = () => {
	return (<>
		{/* <div className="container mx-auto pt-2  pb-4">
			<div className="grid grid-cols-12 gap-4">
				<div className="lg:col-span-3 md:col-span-12 sm:col-span-12 col-span-12 ">
					<div className="text-left ot_footerTxt">
						<p className="nunito-500 fs-18">Powered by <span>OATRx</span></p>
					</div>
				</div>
			</div>
		</div> */}
		</>
	)
}

export default Footer