import { useSelector } from 'react-redux'

export const useBlisterSelectors = () => {

    const patientBlisterPackListResponse = useSelector(state => state.patientBlisterPackList)
    const AddBlisterPackResponse = useSelector(state => state.AddBlisterPack)

    return {
        patientBlisterPackListResponse,
        AddBlisterPackResponse
    }
}
