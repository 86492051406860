import { useSelector } from "react-redux";

export const useARVSelectors = () => {
  const arvListListResponse = useSelector(
    (state) => state.arvList
  );


  return {
    arvListListResponse,
  };
};
