import React, {
	useState,
	useEffect
} from 'react'

import {
	BreadCrumb,
	Footer,
	Sidebar
} from '../../../components/common'

import {
	initalCompoundsFormData,
	initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import { useCustomSelectors } from '../../../services/selectors/allSelectors'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'
import { onhandlePharmacyCompoundFormDataChange } from '../../../utils/data/handleFormDataChange'
import { Loader } from '../../../components/common/loadingView'

import { handlePharmacyCompoundAggrementForm } from '../../../utils/validations/compoundValidations'
import { useCustomDispatch } from '../../../hooks'
import { Errors } from '../../../components/error/alertMessages'
import { SuccessToastMessage } from '../../../components/loaders/toastMessage'

import ChatBox from '../../../components/chat/chatbox'

import * as Pharmacy from '../../../components/pharmacy/form/form'
import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'
import { faxFormFailure } from '../../../services/actions/pharmacy/forms/pharmacyUpdateNotificationsSrFaxForm'

const CompoundAggrementForm = () => {

	const dispatch = useCustomDispatch()

	const [formData, setFormData] = useState(initalCompoundsFormData)
	const [errorMessages, setErrorMessages] = useState(initalCompoundsFormData)
	const [showToast, setShowToast] = useState(initalShowToastMessage)

	const { pharmacyLoginData } = useCustomSelectors()
	const { previewPdfResponse, sendFaxResponse } = useFormsSelectors()

	useEffect(() => {
		if (pharmacyLoginData) {
			setFormData({
				...formData,
				pharmacyManager: pharmacyLoginData.manager_name,
				pharmacyName: pharmacyLoginData.name,
				pharmacyManager: pharmacyLoginData.manager_name,
				pharmacyFax: pharmacyLoginData.fax,
				pharmacyEmail: pharmacyLoginData.email,
			})
		}
	}, [pharmacyLoginData])

	const toPharmacyFeilds = [
		{ id: 'toPharmacyManager', label: 'Manager', name: 'toPharmacyManager', value: formData.toPharmacyManager, errorMessage: errorMessages.toPharmacyManager }]

	const handleFormDataChange = (e) => {
		onhandlePharmacyCompoundFormDataChange(e, setFormData, formData, setErrorMessages, errorMessages)
	}

	useEffect(() => {
		dispatch(previewPdfFailure(null))
		dispatch(faxFormFailure(null))
	}, [])

	return (<React.Fragment>
		<div className='BodyBgColor'>
			<div className="container mx-auto px-4 pt-5 pb-5">
				<div className="grid grid-cols-12 gap-4">
					<Sidebar />
					<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
						<div className="content" id="timeSheets">
							<div className="grid grid-cols-12 gap-4 w-full">
								<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
									<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
										<BreadCrumb />
									</h2>
								</div>
							</div>

							<div className="container mt-2">
								<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
									<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
										<Pharmacy.Date formData={formData}
											setFormData={setFormData} />
									</div>

									<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-4">
										<Pharmacy.ToPharmacy
											formData={formData}
											errorMessages={errorMessages}
											setFormData={setFormData}
											setErrorMessages={setErrorMessages} />

										<Pharmacy.ToPharmacyPhone
											handleFormDataChange={handleFormDataChange}
											formData={formData} errorMessages={errorMessages} />

										<Pharmacy.ToPharmacyFaxNumber handleFormDataChange={handleFormDataChange}
											formData={formData}
											errorMessages={errorMessages} />

										{toPharmacyFeilds?.map((field) => (
											<div className="relative mt-4" key={field.id}>
												<input type="text" id={field.id} onChange={handleFormDataChange} name={field.name} value={field.value} placeholder=""
													className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${field.errorMessage && 'border-red-600'}`} />
												<label htmlFor={field.id}
													className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 
														scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
														peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 
														rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
													{!field.errorMessage && field.label}
												</label>
												{field?.errorMessage && (
													<p className="mt-2 text-xs text-red-600 dark:text-red-400">
														{field?.errorMessage}
													</p>
												)}
											</div>
										))}
									</div>

									<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-4">

										<Pharmacy.LoginPharmacyName
											handleFormDataChange={handleFormDataChange}
											formData={formData} errorMessages={errorMessages} />

										<Pharmacy.LoginPharmacyManger
											handleFormDataChange={handleFormDataChange}
											formData={formData} errorMessages={errorMessages} />

										<Pharmacy.Designation
											setFormData={setFormData}
											handleFormDataChange={handleFormDataChange}
											formData={formData} errorMessages={errorMessages} />

										<Pharmacy.LoginPharmacyProvider
											setFormData={setFormData}
											handleFormDataChange={handleFormDataChange}
											formData={formData} errorMessages={errorMessages} />

									</div>

									{previewPdfResponse?.error !== null &&
										<>
											<br />
											<Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
										</>
									}

									{sendFaxResponse?.error !== null &&
										<>
											<br />
											<Errors error={sendFaxResponse?.error.message ?
												sendFaxResponse?.error?.message :
												'Something went wrong when sending Fax. Please try again'} />
										</>
									}

									<div className='flex mt-5'>
										<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
											<button style={{ height: '40px' }} type="button"

												onClick={() => handlePharmacyCompoundAggrementForm(
													formData,
													setErrorMessages,
													errorMessages,
													dispatch,
													'PDF')}

												className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 			lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

												{previewPdfResponse?.loading ? <>
													Genrating PDF
													&nbsp;<Loader /> </> : 'Preview PDF'}

											</button>
										</div>

										{pharmacyLoginData?.srFax_active === 1 && pharmacyLoginData?.srFax_status === 1 && 
											<div className="pl-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
												<button style={{ height: '40px', backgroundColor: 'lightseagreen' }} type="button"

													onClick={() => handlePharmacyCompoundAggrementForm(
														formData,
														setErrorMessages,
														errorMessages,
														dispatch,
														'FAX',
														showToast,
														setShowToast
													)}

													className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center" >

													{sendFaxResponse?.loading ? <>
														Sending
														&nbsp;<Loader /> </> : 'Send Fax'}

												</button>
											</div>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{showToast.showToast && <SuccessToastMessage status={'Fax sent'} />}

		<ChatBox />
		<Footer />
	</React.Fragment>)
}

export default CompoundAggrementForm