import React, { createContext, useState, useContext } from 'react';

export const TabContext = createContext();

export const TabProvider = ({ children,currentActiveTab }) => {
  const [activeTab, setActiveTab] = useState(currentActiveTab);

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </TabContext.Provider>
  );
};


export const useTabContext = () => {
  return useContext(TabContext);
};
