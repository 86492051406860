import React from 'react'
import ALLGeneratedFDAformList from './allGeneratedFDAForms'

const ExpiringFDAList = () => {

    return (
        <>
            <ALLGeneratedFDAformList />
        </>
    )
}

export default ExpiringFDAList