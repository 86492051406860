import {
	createStore,
	applyMiddleware
} from "redux";

import {
	persistReducer,
	persistStore
} from 'redux-persist';

import { rootReducer } from '../reducers/index';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { BlackListData } from "../constants/store";

import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage';

const persistConfig = {
	key: 'root',
	storage,
	transforms: [
		encryptTransform({
			secretKey: 'x898R)_q>2f_iV3Q5.,(8DF',
		}),
	],
	blacklist: BlackListData
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

persistor.persist();

export { store, persistor }


