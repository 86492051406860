import React, { useEffect, useState } from 'react';
import './App.css';

import {
	HelmetProvider,
	Helmet
} from 'react-helmet-async'

import {
	useCustomNavigate,
	useCustomLocation,
	useCustomDispatch
} from './hooks';

import { isReduxStoredCleared } from './storage';
// import { AddShortCutKeys } from './assets/js/addShortCutKeys';
import { authToken } from './storage/authToken';

import { removeWorkSpaceTab } from './utils/helper/helper';
import { useCustomSelectors } from './services/selectors/allSelectors';
import { subscribePharmacyRolesAndPermissions } from './utils/miscellaneous/ably';
import { persistor } from './services/store/store'

import ActivityStatus from './assets/js/userActivityStatus';
import Cookies from 'js-cookie';
import AppRoutes from './routes/appRoutes';

import * as routesName from './routes/routeName'

import './assets/css/style.css'
import 'flowbite';

const App = () => {

	const navigate = useCustomNavigate()
	const token = authToken()
	const location = useCustomLocation()
	const dispatch = useCustomDispatch()

	const { pharmacyLoginData, pharmacyId } = useCustomSelectors()

	const [title, setTitle] = useState('OATRx - Pharmacy Management System');

	useEffect(() => {
		if (isReduxStoredCleared) {
			Cookies.remove('authToken')
			removeWorkSpaceTab()
			navigate('/')
		}
	}, [isReduxStoredCleared])

	useEffect(() => {
		if (token) {
			setTitle(pharmacyLoginData?.name ? `${pharmacyLoginData.name} - OATRX` : '');
		} else {
			setTitle('OATRx - Pharmacy Management System');
			persistor.purge();
			removeWorkSpaceTab();
		}
	}, [token, pharmacyLoginData]);

	const enableNotifications = () => {
		if (Notification.permission !== 'denied') {
			Notification.requestPermission().then(permission => {
				if (permission === 'denied') {
				} else {

				}
			});
		}
	}

	useEffect(() => {
		{ process.env.NODE_ENV === 'production' && enableNotifications() }
	}, [])

	useEffect(() => {
		if (location.pathname === routesName.pharmacy_fax_log ||
			location.pathname === routesName.pharmacy_file_manager ||
			location.pathname === routesName.pharmacy_front_profile ||
			location.pathname === routesName.SETTINGS_ROUTE
		) {
			removeWorkSpaceTab()
		}
	}, [location])

	useEffect(() => {
		const ablyConnection = subscribePharmacyRolesAndPermissions(pharmacyId, dispatch)

		return () => {
			ablyConnection.close();
		};
	}, [pharmacyId, dispatch])


	return (
		<>
			{/* <ActivityStatus /> */}

			{process.env.NODE_ENV === 'production' && <ActivityStatus />}

			<AppRoutes />


			{/* {token &&
				<AddShortCutKeys />} */}

			<HelmetProvider>
				<Helmet>
					<link href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css" rel="stylesheet"></link>
					<script src="https://cdn.jsdelivr.net/npm/flatpickr"></script>
					<script src="https://unpkg.com/@material-tailwind/html@latest/scripts/ripple.js"></script>
					<title>{title}</title>
				</Helmet>
			</HelmetProvider>
		</>
	)
}

export default App

