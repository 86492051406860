import {
	EDIT_TIMEOFF_REQUEST,
	EDIT_TIMEOFF_REQUEST_SUCCESS,
	EDIT_TIMEOFF_REQUEST_FAILURE
} from '../../constants/index';

import { edit_pharmacy_timeoff_list } from '../../../networking/urlEndPoints';
import Axios from '../../../networking/intraceptor';
import * as routesNames from '../../../routes/routeName'

export const editTimeOffRequest = () => ({
	type: EDIT_TIMEOFF_REQUEST,
});

export const editTimeOffSuccess = (data) => ({
	type: EDIT_TIMEOFF_REQUEST_SUCCESS,
	payload: data,
});

export const editTimeOffFailure = (error) => ({
	type: EDIT_TIMEOFF_REQUEST_FAILURE,
	payload: error,
})

export const editTimeOffRequestList = (editId, formData, startDateValue, endDateValue, isChecked, totalHours, navigate) => {
	return async (dispatch) => {
		dispatch(editTimeOffRequest())

		const requestData = {
			time_of_type: formData.timeOfType,
			description: formData.discription,
			shift_covered_by_name: formData.shiftCoveredByName,
			region: formData.reason
		};

		if (isChecked === true) {
			requestData.whole_day = 1
			requestData.start_date = startDateValue
			requestData.end_date = endDateValue
			requestData.total_hours = totalHours
		}
		if (isChecked === false) {
			requestData.whole_day = 0
			requestData.start_date = startDateValue
			requestData.start_time = formData.startTime
			requestData.end_time = formData.endTime
		}

		try {
			const response = await Axios.post(`${edit_pharmacy_timeoff_list}/${editId}`, requestData)
			if (response?.status === 200) {
				dispatch(editTimeOffSuccess(response?.data));
				setTimeout(() => {
					navigate(routesNames.ALLREQUEST_ROUTE)
				}, 1000);
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(editTimeOffFailure(error?.response))
			}
		}
	};
};