import {
	TIMEOFF_REQUEST,
	TIMEOFF_REQUEST_SUCCESS,
	TIMEOFF_REQUEST_FAILURE
} from '../../constants/index';

import { pharmacy_timeoff_request } from '../../../networking/urlEndPoints';

import Axios from '../../../networking/intraceptor';
import * as routesNames from '../../../routes/routeName'

export const fetchTimeOffRequest = () => ({
	type: TIMEOFF_REQUEST,
});

export const fetchTimeOffSuccess = (data) => ({
	type: TIMEOFF_REQUEST_SUCCESS,
	payload: data,
});

export const fetchTimeOffFailure = (error) => ({
	type: TIMEOFF_REQUEST_FAILURE,
	payload: error,
})

export const sendTimeOffRequest = (formData, startDateValue, endDateValue, isChecked, totalHours, navigate) => {
	return async (dispatch) => {
		dispatch(fetchTimeOffRequest())

		const requestData = {
			time_of_type: formData.timeOfType,
			description: formData.discription,
			shift_covered_by_name: formData.shiftCoveredByName,
			region: formData.reason
		};

		if (isChecked === true) {
			requestData.whole_day = 1
			requestData.start_date = startDateValue
			requestData.end_date = endDateValue
			requestData.total_hours = totalHours
		}
		if (isChecked === false) {
			requestData.whole_day = 0
			requestData.start_date = startDateValue
			requestData.start_time = formData.startTime
			requestData.end_time = formData.endTime
		}

		try {
			const response = await Axios.post(`${pharmacy_timeoff_request}`, requestData)
			if (response?.status === 200) {
				dispatch(fetchTimeOffSuccess(response?.data));
				setTimeout(() => {
					navigate(routesNames.ALLREQUEST_ROUTE)
				}, 1000);
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchTimeOffFailure(error?.response))
			}
		}
	};
};