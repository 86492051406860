import {
	ADD_RECENT_CHATS_REQUEST,
	ADD_RECENT_CHATS_SUCCESS,
	ADD_RECENT_CHATS_FAILURE
} from "../../constants";

const initialState = {
	loading: false,
	data: [],
	error: null,
};

const recentChatListReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_RECENT_CHATS_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case ADD_RECENT_CHATS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case ADD_RECENT_CHATS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: []
			};
		default:
			return state;
	}
}
export default recentChatListReducer 