import React from "react";

import {
	PDFReceiverMessages,
	PdfSenderMessages,
	ReceiverTextMessages,
	RecevicerImageMessage,
	SenderImageMessage,
	SenderTextMessages
} from '../../components/chat/messages';

const formatDate = (dateString) => {
	const messageDate = new Date(dateString);
	const currentDate = new Date();
	const diffInDays = Math.floor((currentDate - messageDate) / (1000 * 60 * 60 * 24));

	if (diffInDays === 0) {
		return 'Today';
	} else if (diffInDays === 1) {
		return 'Yesterday';
	} else {
		const day = messageDate.getDate();
		const monthNames = [
			'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
			'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
		];
		const monthIndex = messageDate.getMonth();
		const year = messageDate.getFullYear();

		return `${day} ${monthNames[monthIndex]}, ${year}`;
	}
};


export const renderMessagesByDate = (allGroupMessages, profileData, value, openOverlay, scrolling, isVisible, chatBodyRef, selectedData) => {

	const groupedMessages = {};
	allGroupMessages?.forEach((response) => {
		const currentDate = response?.date_and_time?.slice(0, 10);
		const formattedDate = formatDate(currentDate);

		if (!groupedMessages[formattedDate]) {
			groupedMessages[formattedDate] = [];
		}

		groupedMessages[formattedDate]?.push(response);
	});

	return Object?.keys(groupedMessages)?.map((formattedDate, index) => (
		<div key={index}>
			<div className={scrolling && isVisible ? 'rubik-400 text-center  visible' : 'hidden'}>{formattedDate}</div>
			{groupedMessages[formattedDate]?.map((message, messageIndex) => (
				<React.Fragment key={messageIndex}>
					{(profileData?.user_id !== value?.user_id) && (
						<>
							{message?.user_id === profileData?.user_id && (
								<>
									{message.message_text !== null && (
										<SenderTextMessages
											profileData={profileData}
											textMessage={message.message_text}
											time={message.date_and_time?.slice(12)}
										/>
									)}

									{message?.message_media?.length > 0 && (
										<>
											{message?.message_media?.map((responseImage, imgIndex) => (
												<SenderImageMessage
													key={imgIndex}
													profileData={profileData}
													image={responseImage}
													openOverlay={openOverlay}
													time={message.date_and_time?.slice(12)}
												/>
											))}
										</>
									)}

									{message?.media_pdf?.length > 0 && (
										<>
											{message?.media_pdf?.map((pdf, pdfIndex) => (
												<PdfSenderMessages
													key={pdfIndex}
													profileData={profileData}
													pdf={pdf}
													time={message.date_and_time?.slice(12)}
												/>
											))}
										</>
									)}
								</>
							)}

							{(message?.user_id !== profileData?.user_id) && (
								<>
									{message.message_text !== null && (
										<ReceiverTextMessages
											chatUser={value}
											textMessage={message.message_text}
											time={message.date_and_time?.slice(12)}
											data={message}
											selectedData={selectedData}
										/>
									)}

									{message?.message_media?.length > 0 && (
										<>
											{message?.message_media?.map((resImg, imgIndex) => (
												<RecevicerImageMessage
													key={imgIndex}
													chatUser={value}
													time={message.date_and_time?.slice(12)}
													openOverlay={openOverlay}
													image={resImg}
													data={message}
													selectedData={selectedData}
												/>
											))}
										</>
									)}

									{message?.media_pdf?.length > 0 && (
										<>
											{message?.media_pdf?.map((pdf, pdfIndex) => (
												<PDFReceiverMessages
													key={pdfIndex}
													chatUser={value}
													pdf={pdf}
													time={message.date_and_time?.slice(12)}
													data={message}
													selectedData={selectedData}
												/>
											))}
										</>
									)}
								</>
							)}
						</>
					)}
				</React.Fragment>
			))}
		</div>
	));
};

