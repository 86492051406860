import {
	FETCH_USER_APPRECIATION_REQUEST,
	FETCH_USER_APPRECIATION_SUCCESS,
	FETCH_USER_APPRECIATION_FAILURE,
} from '../../constants';

import { pharmacy_user_appreciation } from '../../../networking/urlEndPoints';
import { authToken } from '../../../storage/authToken';

import Axios from '../../../networking/intraceptor';
import axios from 'axios';

export const fetchPharmacyUserAppreciationRequest = () => ({
	type: FETCH_USER_APPRECIATION_REQUEST,
})

export const fetchPharmacyUserAppreciationSuccess = (data) => ({
	type: FETCH_USER_APPRECIATION_SUCCESS,
	payload: data,
})

export const fetchPharmacyUserAppreciationFailure = (error) => ({
	type: FETCH_USER_APPRECIATION_FAILURE,
	payload: error,
})

export const fetchUserAppreciations = () => {
	return async (dispatch) => {
		dispatch(fetchPharmacyUserAppreciationRequest());
		try {
			const response = await Axios.get(`${pharmacy_user_appreciation}`);

			if (response?.status === 200) {
				dispatch(fetchPharmacyUserAppreciationSuccess(response?.data))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchPharmacyUserAppreciationFailure(error?.response));
			}
		}
	}
}

export const downloadPdf = (pdfFile, username, setLoadingPdf) => {
	return async (dispatch) => {

		await axios.get(pdfFile,
			{
				responseType: 'blob',
				headers: {
					'Accept': 'application/pdf',
					'Authorization': `Bearer ${authToken()}`,
				}
			}).then(response => {

				var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
				var fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.setAttribute('download', username + "_appreciation" + ".pdf");
				document.body.appendChild(fileLink);
				fileLink.click();
				if (typeof setLoadingPdf === 'function') {
					setLoadingPdf(false)
				}
			}).catch(err => {
				if (typeof setLoadingPdf === 'function') {
					setLoadingPdf(false)
				}
			})
	}
}