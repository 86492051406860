import {
    PATIENT_BLISTER_PACK_LIST_REQUEST,
    PATIENT_BLISTER_PACK_LIST_SUCCESS,
    PATIENT_BLISTER_PACK_LIST_FAILURE
} from "../../../constants"

import { pharmacy_travel_records } from "../../../../networking/urlEndPoints"
import Axios from "../../../../networking/intraceptor"

export const pharmacyTravelListRequest = (types) => ({
    type: PATIENT_BLISTER_PACK_LIST_REQUEST,
    types: types
})

export const pharmacyTravelListSuccess = (data, types) => ({
    type: PATIENT_BLISTER_PACK_LIST_SUCCESS,
    payload: data,
    types: types
})

export const pharmacyTravelListFailure = (error, types) => ({
    type: PATIENT_BLISTER_PACK_LIST_FAILURE,
    payload: error,
    types: types
})


export const FetchTravelPatientList = (type, pageNumber) => {

    return async (dispatch) => {
        dispatch(pharmacyTravelListRequest(type))
        try {
            const params = {
                page: pageNumber
            }

            const response = await Axios.get(`${pharmacy_travel_records}/patients`, { params })

            if (response?.status === 200) {
                dispatch(pharmacyTravelListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyTravelListFailure(error?.response, type))
            }
        }
    }
}

export const FetchTravelLogs = (type, params) => {

    return async (dispatch) => {
        dispatch(pharmacyTravelListRequest(type))
        try {
        
            const response = await Axios.get(`${pharmacy_travel_records}`, { params })

            if (response?.status === 200) {
                dispatch(pharmacyTravelListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyTravelListFailure(error?.response, type))
            }
        }
    }
}