import { useState } from 'react';
import { initalModals, initalShowToastMessage } from '../utils/constants/formInitialStates';

export const useCustomLoadingStates = () => {

	const [commentReplyLoader, setCommentReplyLoader] = useState(false);
	const [apiCallMade, setApiCallMade] = useState(false);
	const [deleteWallListLoading, setDeleteWallListLoading] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [contentLoader, setContentLoader] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isChatboxVisible, setIsChatboxVisible] = useState(false);
	const [commentLoader, setCommentLoader] = useState(false)
	const [commentReplyListLoading, setCommentReplyListLoading] = useState(false)
	const [viewMoreCommentLoader, setViewMoreCommentLoader] = useState(false)


	const [chatLoading, setChatLoading] = useState(false)
	const [recentChatLoading, setRecenetChatLoading] = useState(false)
	const [isChatOpen, setIsChatOpen] = useState(false);

	const [loadGroupMessages, setLoadGroupMessages] = useState(false)
	const [showEmojiLoader, setShowEmojiLoader] = useState(false)

	const [showToastMessage, setShowToastMessage] = useState(false)
	const [showErrorToastMessage, setShowErrorToastMessage] = useState(false)
	const [showModal, setShowModal] = useState(initalModals)
	const [showToast, setShowToast] = useState(initalShowToastMessage)

	return {
		commentReplyLoader,
		setCommentReplyLoader,
		apiCallMade,
		setApiCallMade,
		deleteWallListLoading,
		setDeleteWallListLoading,
		isModalOpen,
		setModalOpen,
		contentLoader,
		setContentLoader,
		isDropdownOpen,
		setIsDropdownOpen,
		loading,
		setLoading,
		isChatboxVisible,
		setIsChatboxVisible,
		commentLoader, setCommentLoader, commentReplyListLoading, setCommentReplyListLoading, viewMoreCommentLoader, setViewMoreCommentLoader,
		chatLoading, setChatLoading, recentChatLoading, setRecenetChatLoading, isChatOpen, setIsChatOpen,

		apiCallMade, setApiCallMade, showEmojiLoader, setShowEmojiLoader, setLoadGroupMessages, loadGroupMessages,

		showToastMessage, setShowToastMessage, showErrorToastMessage, setShowErrorToastMessage,
		showModal, setShowModal,
		showToast, setShowToast
	};
};

export const useCustomNullStates = () => {

	const [post_id, setPost_id] = useState(null);
	const [openPostId, setOpenPostId] = useState(null);
	const [commentReplyId, setCommentReplyId] = useState(null);
	const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
	const [addCommentResponseMessage, setAddCommentResponseMessage] = useState(null)
	const [commentListLoader, setCcommentListLoader] = useState(null)


	const [employeesOnlineStatus, setEmployeesOnlineStatus] = useState(null)
	const [overlayImage, setOverlayImage] = useState(null)
	const [errors, setErros] = useState(null)
	const [message, setMessage] = useState(null)

	return {
		post_id,
		setPost_id,
		openPostId,
		setOpenPostId,
		commentReplyId,
		setCommentReplyId,
		selectedEmployeeId, setSelectedEmployeeId,
		addCommentResponseMessage, setAddCommentResponseMessage,
		commentListLoader, setCcommentListLoader,

		employeesOnlineStatus, setEmployeesOnlineStatus,
		overlayImage, setOverlayImage,
		errors, setErros,
		message, setMessage
	};
};

export const useCustomDataStoringStates = () => {

	const [wallList, setWallList] = useState([]);
	const [likes, setLikes] = useState([]);
	const [totalLikes, setTotalLikes] = useState([]);
	const [showFullContent, setShowFullContent] = useState([]);
	const [commentLikes, setCommentLikes] = useState([]);
	const [totalCommentLikes, setTotalCommentLikes] = useState([]);
	const [profileIds, setProfileIds] = useState([])

	const [commentReplyLoading, setCommentReplyLoading] = useState([])
	const [appenedCommentsValue, setAppendedCommentsValue] = useState([])
	const [appenedCommentReplyValue, setAppendedCommentReplyValue] = useState([])

	const [commentsData, setCommentsData] = useState([])
	const [commentsReplyData, setCommentsReplyData] = useState([])


	const [chatAppenedMessages, setChatAppenedMessages] = useState([]);
	const [myInputMessages, setMyInputMessages] = useState([]);
	const [allGroupMessages, setAllGroupMessages] = useState([])

	const [mediaFile, setMediaFile] = useState([])
	const [mediaPdf, setMediaPdf] = useState([])

	return {
		wallList,
		setWallList,
		likes,
		setLikes,
		totalLikes,
		setTotalLikes,
		showFullContent,
		setShowFullContent,
		commentLikes,
		setCommentLikes,
		totalCommentLikes,
		setTotalCommentLikes,
		profileIds, setProfileIds,

		commentReplyLoading, setCommentReplyLoading,
		commentReplyLoading, setCommentReplyLoading,
		appenedCommentsValue, setAppendedCommentsValue,
		appenedCommentReplyValue, setAppendedCommentReplyValue,
		commentsData, setCommentsData,
		commentsReplyData, setCommentsReplyData,

		chatAppenedMessages, setChatAppenedMessages,
		myInputMessages, setMyInputMessages,
		allGroupMessages, setAllGroupMessages,

		mediaFile, setMediaFile, mediaPdf, setMediaPdf
	}
};

