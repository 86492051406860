import React, {
	useState,
	useEffect
} from 'react';

import {
	useCustomDispatch,
	useCustomNavigate,
	useCustomLocation
} from '../../../hooks';

import {
	ErrorToastMessage,
	SuccessToastMessage
} from '../../../components/loaders/toastMessage';

import {
	Footer,
	Header,
	LoadingView,
	Sidebar
} from '../../../components/common';

import { AddNewCompound } from '../../../models/compounds/addNewCompound';
import { useCustomSelectors } from '../../../services/selectors/allSelectors';
import { useParams } from 'react-router-dom';

import { ErrorMessages } from "../../../components/error/alertMessages";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import { DeleteCompound } from '../../../models/compounds/compoundDelete';

import * as compounds from '../../../services/actions/pharmacy/other';
import * as path from '../../../networking/urlEndPoints';
import ChatBox from '../../../components/chat/chatbox';
import WorkspaceTabs from '../../../components/pharmacy/workSpace/workspaceTabs';

const EditCompoundPriceList = () => {

	const dispatch = useCustomDispatch()
	const location = useCustomLocation()
	const navigate = useCustomNavigate()

	const { pharmacyAllTableListResponse } = useCustomSelectors();
	const [showModal, setShowModal] = useState({ deleteModal: false, id: null, url: null, showToast: false, errorShow: false })

	const { id } = useParams()
	const compound_id = parseInt(id)

	const EditCompoundPriceList = pharmacyAllTableListResponse?.data?.data
	const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1

	const [tableList, setTableList] = useState({
		pageNumber: initialPage,
	})

	useEffect(() => {
		if (compound_id) {
			dispatch(compounds.fetchAllTableListData(`${path.pharmacy_price_compound_list}/${compound_id}`, null, null))
		}
		return () => { }
	}, [])

	useEffect(() => {
		setTableList({
			...tableList,
			pageNumber: initialPage
		})
	}, [initialPage])

	const handleShowModel = (id) => {
		setShowModal({
			...showModal,
			deleteModal: true,
			id: compound_id,
			url: `${path.pharmacy_price_list_delete}/${id}`
		})
	}

	return (
		<React.Fragment>
			<Header />
			<WorkspaceTabs />
			
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">

									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">

										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<div className="grid grid-cols-12 gap-4 pl-10">
												<div className="mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">
													<nav className="flex" aria-label="Breadcrumb">
														<ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
															<li className="inline-flex items-center">
																<a className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
																	Compound
																</a>
															</li>

															<li>
																<div className="flex items-center">
																	<svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
																		<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
																	</svg>
																	<a className="ms-1 text-sm font-medium text-blue-600 text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
																		Price List
																	</a>
																</div>
															</li>
														</ol>
													</nav>
												</div>
											</div>

										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 gap-4 allNotificationSearchBg">
											<AddNewCompound />
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mt-2">
											<div className="">

												{pharmacyAllTableListResponse?.loading ?
													<div className='relative flex justify-center items-center mt-60 mb-80'>
														<LoadingView />
													</div>
													: pharmacyAllTableListResponse?.error !== null ?
														<div className='relative flex justify-center items-center mt-60 mb-80'>
															<ErrorMessages />
														</div>
														: EditCompoundPriceList?.data?.length <= 0 ?
															<h2>Compounds are not available.</h2> :
															<>
																<div className='shadow-md border relative overflow-x-auto rounded-lg'>
																	<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
																		<thead className="text-xs text-white uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400">
																			<tr>
																				<th scope="col" className="px-6 py-3 rounded-s-lg">
																					<p className="rubik-500 fs-14">Pharmacy Name</p>
																				</th>
																				<th scope="col" className="px-6 py-3">
																					<p className="rubik-500 fs-14">Qunatity</p>
																				</th>
																				<th scope="col" className="px-6 py-3">
																					<p className="rubik-500 fs-14">Price</p>
																				</th>
																				<th scope="col" className="px-6 py-3 rounded-e-lg">
																					<p className="rubik-500 fs-14">Day</p>
																				</th>
																				<th scope="col" className="px-6 py-3 rounded-e-lg">
																					<p className="rubik-500 fs-14">Action</p>
																				</th>
																			</tr>
																		</thead>

																		<tbody>
																			{EditCompoundPriceList?.data?.map((value, index) => {
																				return (<React.Fragment key={index}>
																					<tr className="bg-white dark:bg-gray-800 border-b dark:border-gray-700">
																						<th  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
																							<p className="rubik-500 fs-14">{value?.pharmacy_name}</p>
																						</th>
																						<td className="px-6 py-4">
																							<p className="rubik-400 fs-14">{value?.quantity}</p>
																						</td>

																						<td className="px-6 py-4">
																							<p className="rubik-400 fs-14">{value?.price}</p>
																						</td>

																						<td className="px-6 py-4">
																							<p className="rubik-400 fs-14">{value?.days}</p>
																						</td>
																						<td className="px-6 py-4">
																							<a className="btnnn bg-red-100 flex justify-center items-center text-blue-800 text-xs font-medium inline-flex items-center px-1.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-red-400 flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
																								onClick={() => handleShowModel(value?.id)}>
																								Delete
																							</a>
																						</td>
																					</tr>
																				</React.Fragment>)
																			})}
																		</tbody>
																	</table>
																</div>

																{pharmacyAllTableListResponse?.error !== null ? null : <>
																	{EditCompoundPriceList?.data?.length !== 0 && <>
																		{EditCompoundPriceList?.pagination?.last_page !== 1 &&
																			<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
																				<nav aria-label="Page navigation example">
																					<ul className="inline-flex -space-x-px text-base h-10">
																						<CustomPagination
																							count={EditCompoundPriceList?.pagination?.last_page}
																							page={tableList?.pageNumber}
																							onChange={(event, value) => {
																								setTableList({
																									...tableList,
																									pageNumber: initialPage,
																								})
																								dispatch(compounds.fetchAllTableListData(`${path.pharmacy_price_compound_list}/${compound_id}`, null, value))
																								const searchParams = new URLSearchParams();
																								searchParams.append('page', value);
																								const newUrl = `${location?.pathname}?${searchParams.toString()}`;
																								navigate(newUrl);
																							}}
																						/>
																					</ul>
																				</nav>
																			</div>
																		}</>}
																</>}
															</>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >

			{
				showModal?.deleteModal &&
				<DeleteCompound id={showModal?.id}
					setShowModal={setShowModal} showModal={showModal}
					urlEndPoint={showModal?.url}
				/>
			}

			{showModal?.showToast && <SuccessToastMessage status='Deleted Successfully' />}
			{showModal?.errorShow && <ErrorToastMessage />}


			<ChatBox />
			<Footer />

		</React.Fragment >
	)
}

export default EditCompoundPriceList