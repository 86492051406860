import React, { useState } from 'react'

import {
    BreadCrumb,
    Sidebar
} from '../../components/common'
import { fetchBreakPayrollValues } from '../../services/actions/admin/team/fetchTeamsList'

import * as Hooks from '../../hooks'
import * as routeNames from '../../routes/routeName'

import ChatBox from '../../components/chat/chatbox'
import BreakSetting from '../settings/breakSetting'
import PayrollSetting from './payrollSetting'
import PharmacyTimeSetting from './pharmacyTimeSetting'
import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors'

const BreakPayrollSetting = () => {
    const { TeamListResponse } = useAdminSelectors()
    const dispatch = Hooks.useCustomDispatch()
    const navigate = Hooks.useCustomNavigate()
    const [activeTab, setActiveTab] = useState('break_in_out');
    const params = new URLSearchParams(window.location.search);
    const activeParam = params.get('active');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`${routeNames.pharmacy_break_parroll_settings}?active=${tab}`);

        const types = {
            payroll: 'payroll',
            pharmacy_time: 'enforcement',
            break_in_out: 'break'
        };

        if (types[tab]) {
            dispatch(fetchBreakPayrollValues('break_payroll_data_initial', { type: types[tab] }));
        }
    };

    React.useEffect(() => {
        handleTabChange(activeParam || activeTab)
    }, []);

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const activeParam = params.get('active');

        if (activeParam === 'payroll') {
            setActiveTab('payroll');
        }

        if (activeParam === 'pharmacy_time') {
            setActiveTab('pharmacy_time');
        }

        if (activeParam === 'break_in_out') {
            setActiveTab('break_in_out');
        }
    }, []);

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="timeSheets">
                                <div className="grid grid-cols-12 gap-4 w-full">

                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>

                                </div>


                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                        <div className="mb-4 border-b border-gray-200 dark:border-gray-700 flex">
                                            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="filter-tab" role="tablist">

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block border-b-1 rounded-t-sm p-4 ${activeTab === 'break_in_out' ? 'bg-gray-100' : ''}`}
                                                        id="filter-row-tab" onClick={() => handleTabChange('break_in_out')}
                                                        type="button" role="tab" aria-controls="filter-row"
                                                        aria-selected={activeTab === 'break_in_out' ? 'true' : 'false'}>
                                                        Break In Out Logs
                                                    </button>
                                                </li>

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'payroll' ? 'bg-gray-100' : ''}`}
                                                        id="export-row-tab" onClick={() => {
                                                            return handleTabChange('payroll')

                                                        }}
                                                        type="button" role="tab" aria-controls="export-row"
                                                        aria-selected={activeTab === 'payroll' ? 'true' : 'false'}>
                                                        Payroll Setting
                                                    </button>
                                                </li>

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'pharmacy_time' ? 'bg-gray-100' : ''}`}
                                                        id="export-row-tab" onClick={() => {
                                                            return handleTabChange('pharmacy_time')

                                                        }}
                                                        type="button" role="tab" aria-controls="export-row"
                                                        aria-selected={activeTab === 'pharmacy_time' ? 'true' : 'false'}>
                                                        Schedule Enforcement Settings
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div id="filter-tab-content">
                                            <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'break_in_out' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">
                                                {activeTab === 'break_in_out' && <>
                                                    <div className="flex flex-wrap gap-y-3">
                                                        <BreakSetting activeTab={activeTab} data={TeamListResponse?.data?.data?.break_setting} />
                                                    </div>
                                                </>}
                                            </div>

                                            <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'payroll' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">
                                                {activeTab === 'payroll' && <>
                                                    <PayrollSetting activeTab={activeTab} data={TeamListResponse?.data?.data?.payroll_setting} />
                                                </>}
                                            </div>

                                            <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'pharmacy_time' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">
                                                {activeTab === 'pharmacy_time' && <>
                                                    <PharmacyTimeSetting activeTab={activeTab} data={TeamListResponse?.data?.data?.enforcement_setting} />
                                                </>}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />
        </>
    )
}

export default BreakPayrollSetting