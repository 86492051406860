import {
	PATIENT_UPDATE_STATUS_REQUEST,
	PATIENT_UPDATE_STATUS_SUCCESS,
	PATIENT_UPDATE_STATUS_FAILURE
} from "../../constants"

import { pharmacy_patient_update_status } from "../../../networking/urlEndPoints"
import { fetchPatientsList } from "./patientsList"

import Axios from "../../../networking/intraceptor"
import * as routesNames from '../../../routes/routeName'

export const patientUpdateStatusRequest = () => ({
	type: PATIENT_UPDATE_STATUS_REQUEST,
})

export const patientUpdateStatusSuccess = (data) => ({
	type: PATIENT_UPDATE_STATUS_SUCCESS,
	payload: data,
})

export const patientUpdateStatusFailure = (error) => ({
	type: PATIENT_UPDATE_STATUS_FAILURE,
	payload: error,
})

export const updatePatientStatus = (updatePatientDetail,
	setShowToastMessage,
	setShowErrorToastMessage,
	paginationNumber,
	location,
	showModal,
	setShowModal,
	queryStatus,
	querySearch
) => {

	return async (dispatch) => {
		dispatch(patientUpdateStatusRequest())
		try {
			const response = await Axios.post(`${pharmacy_patient_update_status}`, {
				patient_id: updatePatientDetail.id,
				status: updatePatientDetail.active
			})
			if (response?.status === 200) {
				dispatch(patientUpdateStatusSuccess(response?.data))

				setShowModal({
					...showModal,
					statusUpdateModal: false
				})

				if (typeof setShowToastMessage === 'function') {
					setShowToastMessage(true)

					setTimeout(() => {
						setShowToastMessage(false);
					}, 2000)
				}

				if ((location.pathname === routesNames.ALL_PATIENTS)) {
					await dispatch(fetchPatientsList(querySearch, queryStatus, paginationNumber))
				}

			} else {
				throw new Error(response)
			}

		} catch (error) {

			if (error?.response) {
				dispatch(patientUpdateStatusFailure(error?.response))
			}
			setShowErrorToastMessage(true)
			if (typeof setShowToastMessage === 'function' || typeof setShowErrorToastMessage === 'function') {
				setTimeout(() => {
					setShowToastMessage(false);
				}, 3000)

				setTimeout(() => {
					setShowErrorToastMessage(false);
				}, 3000)
			}
		}
	}
}
