import React, { useState } from 'react';
import { useCustomDispatch } from '../../../hooks';
import { handleUpdateCreateShipment } from '../../../services/actions/pharmacy/app/addAppData';
import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors';
import { ErrorToastMessage, SuccessToastMessage } from '../../loaders/toastMessage';

const TextInput = ({ formData, setFormData, label = "No Label", name = "", enable = false, height = "42px", textType = 'text', errorMessages }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="relative w-full">
            <input
                type={textType}
                id={name}
                autoComplete="off"
                onChange={handleChange}
                name={name}
                style={{ height: height }}
                value={formData[name] || ''}
                className={`block pl-4 ${errorMessages[name] && 'border-red-500'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none
                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text
                bg-white `}
                placeholder=""
                disabled={enable}
                step="any"
            />
            <label
                htmlFor={name}
                className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2
                origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2
                peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
            >
                {errorMessages[name] ? '' : label}
            </label>
            {errorMessages[name] && <p className='text-red-500 fs-12'>{errorMessages[name]}</p>}
        </div>
    );
};

const CreateShipment = ({ order_id }) => {

    const dispatch = useCustomDispatch()

    const { AddBlisterPackResponse } = useBlisterSelectors()
    const UpdateOrderStatusResponse = AddBlisterPackResponse

    const [formData, setFormData] = useState({ length: '', width: '', height: '', weight: '' });
    const [errorMessages, setErrorMessages] = useState({ length: '', width: '', height: '', weight: '' })
    const [showToast, setShowToast] = useState({ showToast: false, errorToast: false })

    const handleCreateShipmentValidation = () => {

        const specificValidations = {
            length: 'Length is required',
            width: 'Width is required',
            height: 'Height is required',
            weight: "Weight is required"
        }

        const allValidations = { ...specificValidations }
        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(allValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        )

        setErrorMessages({
            ...errorMessages,
            ...newErrorMessages,
        })

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')
        if (allDataPresent) {
            const reqData = {
                order_id: order_id,
                weight: formData.weight,
                length: formData.length,
                width: formData.width,
                height: formData.height,
            }
            dispatch(handleUpdateCreateShipment('create_shipment', reqData, setShowToast))
        }
    }

    return (
        <React.Fragment>
            <div className='mt-2 border p-4 shadow-md rounded-md'>
                <h1 className='rubik-500 fs-14 text-gray-700'>Create Shipment</h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 ">

                    <TextInput
                        label="Length (in cm)"
                        name="length"
                        formData={formData}
                        setFormData={setFormData}
                        textType="number"
                        height="48px"
                        errorMessages={errorMessages}
                    />
                    <TextInput
                        label="Width (in cm)"
                        name="width"
                        formData={formData}
                        setFormData={setFormData}
                        textType="number"
                        height="48px"
                        errorMessages={errorMessages}
                    />
                    <TextInput
                        label="Height (in cm)"
                        name="height"
                        formData={formData}
                        setFormData={setFormData}
                        textType="number"
                        height="48px"
                        errorMessages={errorMessages}
                    />
                    <TextInput
                        label="Weight (in kg)"
                        name="weight"
                        formData={formData}
                        setFormData={setFormData}
                        textType="number"
                        height="48px"
                        errorMessages={errorMessages}
                    />
                </div>
                <button type="button" onClick={handleCreateShipmentValidation}
                    className="flex  h-full pl-6 py-4 items-center mt-2 bg-green-500 w-32 text-white justify-center text-white bg-white 
                                border border-gray-300 focus:outline-none hover:bg-green-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    Create
                </button>
            </div>

            {showToast.showToast && UpdateOrderStatusResponse.type === 'create_shipment' && <SuccessToastMessage status={'Added Successfully'} />}
            {showToast.errorToast && UpdateOrderStatusResponse.type === 'create_shipment' && <ErrorToastMessage status={'Oops!. Got an issue while adding details.please  try again.'} />}

        </React.Fragment>
    );
};

export default CreateShipment;
