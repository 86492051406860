import {
    DAILY_REVENUE_LIST_REQUEST,
    DAILY_REVENUE_LIST_SUCCESS,
    DAILY_REVENUE_LIST_FAILURE
} from "../../../constants"

import {
    pharmacy_revenue_list,
    cash_change_list,
    pharmacy_deposit_list,
    pharmacy_handcash
} from './../../../../networking/urlEndPoints'

import Axios from "../../../../networking/intraceptor"

export const pharmacyRevenueListRequest = (fetchType) => ({
    type: DAILY_REVENUE_LIST_REQUEST,
    fetch_type: fetchType
})

export const pharmacyRevenueListSuccess = (data, fetchType) => ({
    type: DAILY_REVENUE_LIST_SUCCESS,
    payload: data,
    fetch_type: fetchType
})

export const pharmacyRevenueListFailure = (error, fetchType) => ({
    type: DAILY_REVENUE_LIST_FAILURE,
    payload: error,
    fetch_type: fetchType
})

export const fetchDailyRevenueList = (type, params) => {
    return async (dispatch) => {
        dispatch(pharmacyRevenueListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_revenue_list}`, { params })
            if (response?.status === 200) {
                dispatch(pharmacyRevenueListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyRevenueListFailure(error?.response?.data, type))
            }
        }
    }
}

export const fetchCashChangeList = (type) => {

    return async (dispatch) => {
        dispatch(pharmacyRevenueListRequest(type))
        try {
            const response = await Axios.get(`${cash_change_list}`)
            if (response?.status === 200) {
                dispatch(pharmacyRevenueListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyRevenueListFailure(error?.response?.data, type))
            }
        }
    }
}

export const FetchPharmacyDepositInfo = (type, params) => {

    return async (dispatch) => {
        dispatch(pharmacyRevenueListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_deposit_list}`, { params })
            if (response?.status === 200) {
                dispatch(pharmacyRevenueListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyRevenueListFailure(error?.response?.data, type))
            }
        }
    }
}

export const FetchPharmacyInCashHandList = (type, params) => {

    return async (dispatch) => {
        dispatch(pharmacyRevenueListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_handcash}`, { params })
            if (response?.status === 200) {
                dispatch(pharmacyRevenueListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacyRevenueListFailure(error?.response?.data, type))
            }
        }
    }
}