import React, { useState, useEffect } from 'react'

import {
    BreadCrumb,
    Footer,
    Sidebar
} from '../../../components/common'

import {
    pharmacyPreviewFormPdf,
    previewPdfFailure
} from '../../../services/actions/pharmacy/forms/previewPdf'

import { useCustomDispatch } from '../../../hooks'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'

import { RemoveIcon } from "../../../assets/svg/icons";
import { TextInput } from '../../../components/pharmacy/dispense/dispense'
import { pharmacy_generate_invoice } from '../../../networking/urlEndPoints'
import { Errors } from '../../../components/error/alertMessages'
import { currentDate } from '../../../utils/constants/formInitialStates'

import * as Pharmacy from '../../../components/pharmacy/form/form'
import ChatBox from '../../../components/chat/chatbox'

const GenerateInvoice = () => {

    const dispatch = useCustomDispatch()

    const initalInvoiceFormData = {
        date: currentDate,
        fetchType: 'winrx',
        phn: '',
        name: '',
        phone: '',
        item_details: '',
        quantity: '',
        price: '',
        total: '',
        grand_total: '',
        p_address: '',
        winrxpatientphn: '',
        winrxpatientname: '',
        winrxpatientphone: ''
    }

    const { previewPdfResponse } = useFormsSelectors()

    const [formData, setFormData] = useState(initalInvoiceFormData)
    const [errorMessages, setErrorMessages] = useState(initalInvoiceFormData)

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const addInputFeilds = [
        {
            id: 1,
            label: "Item Details",
            name: "item_details",
            value: formData.item_details,
            errorMessage: errorMessages.item_details,
        },
        {
            id: 2,
            label: "Quantity",
            name: "quantity",
            value: formData.quantity,
            errorMessage: errorMessages.quantity,
        },
        {
            id: 3,
            label: "Price",
            name: "price",
            value: formData.price,
            errorMessage: errorMessages.price,
        }, {
            id: 4,
            label: "Total",
            name: "total",
            value: formData.total,
            errorMessage: errorMessages.total,
        },
    ];


    const [addNewItems, setAddNewItems] = useState([
        { selectComponents: addInputFeilds },
    ]);

    const handleAddNew = () => {
        setAddNewItems([
            ...addNewItems,
            { selectComponents: addInputFeilds },
        ]);
    };

    const handleFormDataChanges = (e, i, index) => {
        const { name, value } = e.target;
        setAddNewItems((prevState) => {
            const updatedItems = [...prevState];
            if (name === 'quantity' || name === 'price') {
                updatedItems[i].selectComponents[index].value = value;
                const quantity = parseFloat(updatedItems[i].selectComponents.find(component => component.name === 'quantity').value) || 0;
                const price = parseFloat(updatedItems[i].selectComponents.find(component => component.name === 'price').value) || 0;
                const total = (quantity * price).toFixed(2);
                updatedItems[i].selectComponents.find(component => component.name === 'total').value = total;

                const grandTotal = updatedItems.reduce((acc, item) => acc + parseFloat(item.selectComponents.find(component => component.name === 'total').value), 0).toFixed(2);
                setFormData(prevFormData => ({ ...prevFormData, grand_total: grandTotal }));

            } else {
                updatedItems[i].selectComponents[index].value = value;
            }
            return updatedItems;
        });
    };


    useEffect(() => {
        setAddNewItems([{ selectComponents: addInputFeilds }]);
    }, []);

    const handleDelete = (index) => {
        setAddNewItems((prevCompounds) => {
            const updatedCompounds = [...prevCompounds];
            updatedCompounds.splice(index, 1);
            return updatedCompounds;
        });
    };


    const handleGenerateInvoicePDFValdiate = () => {

        const specificValidations = {
            name: formData.fetchType === 'winrx' ? 'Customer Name is required' : '',
            patientName: formData.fetchType === 'local' ? 'Name is required' : '',
            phone: 'Phone is required',
            phn: 'Customer PHN is required',
            p_address: 'Address is required',
        }
        const allValidations = { ...specificValidations }
        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(allValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        )

        setErrorMessages({
            ...errorMessages,
            ...newErrorMessages,
        })

        let isValid = true;

        const updatedCompounds = addNewItems?.map((compound) => {
            const updatedSelectComponents = compound.selectComponents.map((field) => {
                if (!field.value) {
                    isValid = false;
                    return { ...field, errorMessage: `${field.label} is required` };
                }
                // isValid = true
                return { ...field, errorMessage: '' };
            });
            // isValid = true

            return { ...compound, selectComponents: updatedSelectComponents };
        });

        // if (!isValid) {
        setAddNewItems(updatedCompounds);
        // }

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

        const formattedItemDetails = addNewItems?.map((value) => {
            const itemDetails = value.selectComponents.reduce((acc, input) => {
                acc[input.name] = input.value;
                return acc;
            }, {});
            return {
                price: itemDetails.price,
                item: itemDetails.item_details,
                total: itemDetails.total,
                qty: itemDetails.quantity
            };
        });

        if (allDataPresent && isValid) {
            const requestedData = {
                date: formData.date,
                invoice: formattedItemDetails,
                sub_total: formData.grand_total,
            }
            if (formData.fetchType === 'winrx') {
                requestedData.fetchtype = formData.fetchType
                requestedData.winrxpatientphn = formData.phn
                requestedData.winrxpatientname = formData.name
                requestedData.winrxpatientphone = formData.phone
                requestedData.winrxpatientaddress = formData.p_address
            }

            if (formData.fetchType === 'local') {
                requestedData.fetchtype = formData.fetchType
                requestedData.patient = formData.patientName.value
                requestedData.patientphn = formData.phn
                requestedData.patientphone = formData.phone
                requestedData.address = formData.p_address
            }

            dispatch(pharmacyPreviewFormPdf(pharmacy_generate_invoice, requestedData))
        }
        return isValid;

    }

    const handleSaveInvoicePdf = () => {
        handleGenerateInvoicePDFValdiate()
    }

    useEffect(() => {
        dispatch(previewPdfFailure(null))
    }, [])

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="timeSheets">

                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
                                            <Pharmacy.Date formData={formData}
                                                setFormData={setFormData} />
                                            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                <Pharmacy.FormWinRxPatients
                                                    formData={formData}
                                                    handleFormDataChange={handleFormDataChange} />
                                            </ul>
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
                                            <Pharmacy.PatientPhn
                                                handleFormDataChange={handleFormDataChange}
                                                formData={formData}
                                                errorMessages={errorMessages}
                                                setErrorMessages={setErrorMessages}
                                                setFormData={setFormData}
                                                text='Customer PHN'
                                            />

                                            <Pharmacy.PatientName
                                                handleFormDataChange={handleFormDataChange}
                                                formData={formData}
                                                errorMessages={errorMessages}
                                                setFormData={setFormData}
                                                setErrorMessages={setErrorMessages}
                                                text='Customer Name'
                                            />


                                            <Pharmacy.PatientPhone
                                                handleFormDataChange={handleFormDataChange}
                                                formData={formData}
                                                errorMessages={errorMessages}
                                                setFormData={setFormData}
                                                setErrorMessages={setErrorMessages}
                                                text='Customer Phone'
                                            />

                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-4">
                                            <div className="did-floating-label-content">
                                                <div id="cancellationNotesContainer" className="form-group full">
                                                    <textarea maxLength="255" placeholder='Customer Address'
                                                        name='p_address' value={formData.p_address}
                                                        onChange={handleFormDataChange}
                                                        className={`fs-14 rounded-md ${errorMessages.p_address ? 'border-red-500' : 'border-gray-300'} rubik-400 border border-gray-300 text-gray-500`}>
                                                    </textarea>
                                                    {errorMessages.p_address && (
                                                        <p className="text-xs text-red-600 dark:text-red-400">
                                                            {errorMessages.p_address}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>



                                        <div className="flex flex-wrap gap-x-4 gap-y-1 mt-4 ">

                                            {addNewItems?.map((value, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        {value?.selectComponents?.map((field, index) => (
                                                            <div className="relative flex-0 w-full md:flex-1" key={index}>
                                                                <input
                                                                    type={((field.name === 'quantity') || (field.name === 'price')) ? 'number' : 'text'}
                                                                    id={field.name[index]}
                                                                    onChange={(e) =>
                                                                        handleFormDataChanges(e, i, index)
                                                                    }
                                                                    name={field.name}
                                                                    defaultValue={field.value}
                                                                    disabled={field.name === 'total'}
                                                                    placeholder=""
                                                                    className={`block ${field.name === 'total' && 'bg-gray-100'} ${field.errorMessage ? `border-red-500` : 'border-gray-300'} pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 
                            									appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 
                            									focus:border-blue-600 peer cursor-text bg-white`}
                                                                />
                                                                <label
                                                                    htmlFor={field.name[index]}
                                                                    className={`ml-4 ${field.name === 'total' && 'bg-gray-100'} absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 
                            											scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
                            											peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                            											peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 
                            											rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
                                                                >
                                                                    {!field.errorMessage && field.label}
                                                                </label>

                                                                {field.errorMessage && (
                                                                    <p className="text-xs text-red-600 dark:text-red-400">
                                                                        {field.errorMessage}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        ))}

                                                        {i === 0 && (
                                                            <button
                                                                style={{ height: '48px' }}
                                                                onClick={handleAddNew}
                                                                type="button"
                                                                className="flex py-4 px-4 rounded-md items-center w-54 justify-center text-white bg-red-500 border border-gray-300 focus:outline-none 
                                                                white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                                Add
                                                            </button>)}

                                                        {i > 0 && (
                                                            <button
                                                                style={{ height: '48px' }}
                                                                onClick={() => handleDelete(i)}
                                                                type="button"
                                                                className="flex w-auto flex-0 py-4 items-center w-38 justify-center text-red-900 bg-white border border-red-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                                                            >
                                                                <RemoveIcon color="red" />
                                                            </button>
                                                        )}
                                                        <div className="w-full"></div>
                                                    </React.Fragment>
                                                );
                                            })}

                                        </div>

                                        {/* <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-6 gap-4  ">
                                            <button
                                                onClick={handleAddNew}
                                                type="button"
                                                className="flex py-4 items-center w-54 justify-center text-white bg-red-500 border border-gray-300 focus:outline-none 
                                                hover:bg-red-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                Add More Items
                                            </button>
                                        </div> */}

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-4 mt-4 ">
                                            <TextInput label='Grand Total' name='grand_total' formData={formData}
                                                setFormData={setFormData} enable={true} />
                                        </div>

                                        {previewPdfResponse?.error !== null &&
                                            <>
                                                <br />
                                                <Errors error={'Something went wrong when Generating PDF. Please try again'} />
                                            </>
                                        }

                                        <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
                                            <button style={{ height: '40px' }} type="button" onClick={handleSaveInvoicePdf}
                                                className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex
                                                                 justify-center items-center">

                                                {previewPdfResponse?.loading ? 'Generating PDF...' : 'Generate PDF'}
                                            </button>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChatBox />
            <Footer />

        </>
    )
}

export default GenerateInvoice