import React, {
	useState,
	useEffect,
	useRef
} from 'react'

import {
	useCustomDispatch,
	useCustomSelector,
	useCustomParams,
	useCustomNavigate
} from '../../hooks';

import {
	Sidebar,
	Footer,
	Header
} from '../../components/common'

import {
	editTimeOffRequestStartDatePicker,
	editTimeOffRequestEndDatePicker
} from '../../assets/js/datepicker'

import {
	Errors,
	SuccessMessage
} from '../../components/error/alertMessages';

import { editTimeOffRequestList } from '../../services/actions/main/editTimeOffRequestActions';
import { handleUpdateApiError } from '../../components/error/handleApiErros';
import { calculateTotalHours } from '../../utils/helper/helper';

import { auth } from '../../constants/rootReduceName';
import { useCustomSelectors } from '../../services/selectors/allSelectors';
import WorkspaceTabs from '../../components/pharmacy/workSpace/workspaceTabs'

import * as routesNames from '../../routes/routeName'

const EditRequest = () => {

	const { id } = useCustomParams()
	const editId = parseInt(id)

	const navigate = useCustomNavigate()
	const dispatch = useCustomDispatch()

	const { pharmacyTimeOffList } = useCustomSelectors()

	const authResponse = useCustomSelector(state => state[auth]?.data)
	const reason = authResponse?.timeoff_region

	const editTimeOffRequest = useCustomSelector(state => state.editTimeOffRequest)
	const timeOffList = pharmacyTimeOffList?.data?.data

	const endDatePickerRef = useRef(null)
	const startDatePickerRef = useRef(null)

	const [startDateValue, setStartDateValue] = useState()
	const [dateValue, setdateValue] = useState(new Date()?.toISOString().split('T')[0])
	const [endDateValue, setEndDateValue] = useState()

	const [successMsg, setSuccessMsg] = useState(null)
	const [message, setMessage] = useState(null)
	const [isChecked, setIsChecked] = useState(true);
	const [showdate, setShowDate] = useState(true)

	const [totalHours, setTotalHours] = useState("8:00")

	const [formData, setFormData] = useState({
		timeOfType: 'Paid',
		duration: '',
		discription: '',
		shiftCoveredByName: '',
		signature: '',
		name: authResponse?.username,
		startTime: '',
		startTimeAmPm: '',
		endTime: '',
		endTimeAmPm: '',
		reason: reason.vacation_paid
	})

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const toggleCheckbox = () => {
		setIsChecked(!isChecked);
		setShowDate(!showdate)
	};

	const submitForm = (e) => {
		e.preventDefault();
		if (!formData.timeOfType) {
			setMessage('Time Type is required');
		} else if (isChecked === true) {
			if (!endDateValue) {
				setMessage('End Date is required')
			} else if (new Date(endDateValue) < new Date(startDateValue)) {
				setMessage('Ending date should be greater than the starting date.');
			} else if (!totalHours) {
				setMessage('Total Hours is Required')
			} else {
				setMessage(null)
				dispatch(editTimeOffRequestList(editId, formData, startDateValue, endDateValue, isChecked, totalHours, navigate))
			}
		} else if (isChecked === false) {
			if (!formData.startTime) {
				setMessage('Start Time is required')
			} else if (!formData.endTime) {
				setMessage('End Time is required')
			} else {
				setMessage(null)
				dispatch(editTimeOffRequestList(editId, formData, startDateValue, endDateValue, isChecked, '', navigate))
			}
		}
		else {
			setMessage('')
		}
	}

	useEffect(() => {
		if (editTimeOffRequest?.loading) {
			setMessage(null);
			setSuccessMsg(null)
		} else if (editTimeOffRequest?.data?.message === 'User Timeoff updated successfully') {
			setSuccessMsg('Updated Successfully')
		} else if (editTimeOffRequest?.error) {
			const error = editTimeOffRequest.error?.status
			const errorMessages = handleUpdateApiError(error)
			setMessage(errorMessages)
			setSuccessMsg(null)
		}
	}, [editTimeOffRequest]);


	useEffect(() => {
		setTotalHours(calculateTotalHours(startDateValue, endDateValue));
	}, [startDateValue, endDateValue]);


	useEffect(() => {
		const result = timeOffList?.find((value) => value.id === editId)

		if (result?.whole_day === 0) {
			setFormData({
				...formData,
				timeOfType: result.time_of_type,
				discription: result.description,
				shiftCoveredByName: result.shift_covered_by_name,
				name: authResponse?.username,
				startTime: result?.start_time?.slice(0, 5),
				startTimeAmPm: result?.start_time?.slice(6, 8),
				endTime: result?.end_time?.slice(0, 5),
				endTimeAmPm: result?.end_time?.slice(6, 8),
				reason: result.region
			})
			setStartDateValue(result.start_date)
			setIsChecked(false)
			setShowDate(false)
		} else if (result?.whole_day === 1) {
			setFormData({
				...formData,
				timeOfType: result.time_of_type,
				discription: result.description,
				shiftCoveredByName: result.shift_covered_by_name,
				name: authResponse?.username,
				reason: result.region
			})
			setStartDateValue(result.start_date)
			setEndDateValue(result.end_date)
			setIsChecked(true)
			setShowDate(true)
		}
	}, [pharmacyTimeOffList])

	useEffect(() => {
		const startDatepickerInstance = editTimeOffRequestStartDatePicker(startDatePickerRef, setStartDateValue, startDateValue);

		return () => {
			if (startDatepickerInstance) {
				startDatepickerInstance.destroy();
			}
		}
	}, [startDateValue])

	useEffect(() => {
		const startDatepickerInstance = editTimeOffRequestEndDatePicker(endDatePickerRef, setEndDateValue, endDateValue)

		return () => {
			if (startDatepickerInstance) {
				startDatepickerInstance.destroy();
			}
		}
	}, [endDateValue, isChecked])


	useEffect(() => {
		setMessage(null)
		setSuccessMsg(null)
	}, [])

	return (
		<>
			<div className='z-30 sticky'>
				<Header />
				<WorkspaceTabs />
			</div>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="newTimeOffRequest">
								<div className="container mt-5 sm:mt-5 md:mt-5 lg:mt-0" style={{ display: 'flex', }}>
									<svg onClick={() => navigate(routesNames.ALLREQUEST_ROUTE)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 btnnn" style={{ position: 'relative', top: '10px' }}>
										<path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
									</svg>
									<h2 className="rubik-500 fs-30">
										&nbsp;
										Edit Time Off Request
									</h2>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg px-5 py-5 md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-10 lg:px-20 bg-white">
										<form onSubmit={submitForm}>
											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18">Employee Name</label>
													<div className="relative">
														<svg className="absolute top-3 left-4" width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<g clipPath="url(#clip0_46_28-977712)">
																<path fillRule="evenodd" clipRule="evenodd" d="M14.5161 6.95161C14.5161 9.44581 12.4942 11.4677 10 11.4677C7.50581 11.4677 5.48387 9.44581 5.48387 6.95161C5.48387 4.45742 7.50581 2.43548 10 2.43548C12.4942 2.43548 14.5161 4.45742 14.5161 6.95161ZM16.4516 6.95161C16.4516 10.5147 13.5631 13.4032 10 13.4032C6.43688 13.4032 3.54839 10.5147 3.54839 6.95161C3.54839 3.38849 6.43688 0.5 10 0.5C13.5631 0.5 16.4516 3.38849 16.4516 6.95161ZM1.93548 22.4355C1.93548 20.1195 3.81299 18.2419 6.12903 18.2419H13.871C16.187 18.2419 18.0645 20.1195 18.0645 22.4355V23.7258C18.0645 24.2603 18.4978 24.6935 19.0323 24.6935C19.5667 24.6935 20 24.2603 20 23.7258V22.4355C20 19.0505 17.256 16.3065 13.871 16.3065H6.12903C2.74406 16.3065 0 19.0505 0 22.4355V23.7258C0 24.2603 0.433277 24.6935 0.967742 24.6935C1.50221 24.6935 1.93548 24.2603 1.93548 23.7258V22.4355Z" fill="#3A52BB" />
															</g>
															<defs>
																<clipPath id="clip0_46_28-977712">
																	<rect width="20" height="25" fill="white" transform="translate(0 0.5)" />
																</clipPath>
															</defs>
														</svg>
														<input type="text" defaultValue={formData.name} disabled id="email" className="pl-14 rubik-400 fs-18 h-12 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300" placeholder="Enter Name" />
													</div>
												</div>

												<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18">Time of Type</label>
													<div className="relative">
														<svg className="absolute top-3 left-4" width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
															<g clipPath="url(#clip0_46_20-526494)">
																<path d="M24.4963 17.8336C24.3885 18.1731 24.2086 18.4854 23.969 18.7491C23.7294 19.0128 23.4358 19.2218 23.1081 19.3617L9.60553 25.1355L9.30629 24.9932C3.22586 22.1063 2.41154 21.6697 2.22758 21.5275L3.28473 20.4507C4.11376 20.8824 7.39558 22.4571 9.63742 23.5216L22.5317 18.0077C22.6649 17.9509 22.7844 17.8659 22.8818 17.7587C22.9792 17.6514 23.0523 17.5244 23.0961 17.3863C23.1399 17.2482 23.1533 17.1023 23.1355 16.9585C23.1176 16.8147 23.069 16.6765 22.9928 16.5532C22.8678 16.3493 22.674 16.1967 22.4465 16.123C22.219 16.0492 21.9726 16.0592 21.7517 16.151L11.9332 20.1907L7.24105 17.7992L7.90821 16.4894L11.9994 18.5743L13.6992 17.8728C13.4324 17.3323 13.0841 16.836 12.6666 16.4011C9.87288 13.502 4.60677 13.2959 1.50402 15.9302L0.113281 15.2434C0.186867 15.1337 0.277061 15.0361 0.380634 14.954C4.0132 11.6992 10.3732 11.9028 13.7262 15.3783C14.2723 15.9469 14.7236 16.5995 15.063 17.3111L21.19 14.7897C21.6264 14.6097 22.1052 14.5582 22.57 14.6411C23.0347 14.724 23.4661 14.9379 23.8134 15.2577C24.1608 15.5774 24.4095 15.9897 24.5305 16.446C24.6515 16.9024 24.6396 17.3838 24.4963 17.8336Z" fill="#3A52BB" />
																<path d="M11.5485 12.1038L8.61475 9.17003L9.65521 8.12956L11.5485 10.0229L15.0454 6.52618L16.0859 7.56665L11.5485 12.1038Z" fill="#3A52BB" />
																<path d="M21.0581 9.31502C21.0607 11.0037 20.5687 12.6563 19.6428 14.0685H17.8032C18.5713 13.1874 19.1146 12.1334 19.3864 10.9965C19.6582 9.85966 19.6505 8.67388 19.3639 7.54066C19.0774 6.40743 18.5205 5.36051 17.741 4.48948C16.9615 3.61846 15.9826 2.94927 14.888 2.53919C13.7934 2.12911 12.6157 1.99036 11.4558 2.1348C10.2959 2.27924 9.18822 2.70258 8.22762 3.36858C7.26702 4.03459 6.4821 4.92342 5.94002 5.95902C5.39795 6.99461 5.11486 8.14613 5.115 9.31502C5.11394 10.0966 5.24156 10.8731 5.49272 11.6133H3.95238C3.74563 10.8647 3.64166 10.0916 3.64333 9.31502C3.64333 7.00569 4.56071 4.79094 6.19366 3.15799C7.8266 1.52505 10.0414 0.607666 12.3507 0.607666C14.66 0.607666 16.8748 1.52505 18.5077 3.15799C20.1407 4.79094 21.0581 7.00569 21.0581 9.31502Z" fill="#3A52BB" />
															</g>
															<defs>
																<clipPath id="clip0_46_20-526494">
																	<rect width="25" height="25" fill="white" transform="translate(0 0.5)" />
																</clipPath>
															</defs>
														</svg>
														<select id="countries" onChange={handleChange} name='timeOfType' value={formData.timeOfType} className="pl-14 rubik-400 fs-18 h-12 grey-color border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300">
															<option className="rubik-400 fs-18" value='Paid'>Paid</option>
															<option className="rubik-400 fs-18" value='UnPaid'>UnPaid</option>
														</select>
													</div>
												</div>

												<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18">Reason</label>
													<div className="relative">
														<select id="countries" onChange={handleChange} name='reason' value={formData?.reason} className=" rubik-400 fs-18 h-12 grey-color border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300">
															{reason?.vacation_paid && <option className="rubik-400 fs-18" value={reason.vacation_paid}>{reason.vacation_paid}</option>}
															{reason?.family_medical_emergency && <option className="rubik-400 fs-18" value={reason.family_medical_emergency}>{reason.family_medical_emergency}</option>}
															{reason?.holiday && <option className="rubik-400 fs-18" value={reason.holiday}>{reason.holiday}</option>}
															{reason?.jury_duty && <option className="rubik-400 fs-18" value={reason.jury_duty}>{reason.jury_duty}</option>}
															{reason?.personal_medical_emergency && <option className="rubik-400 fs-18" value={reason.personal_medical_emergency}>{reason.personal_medical_emergency}</option>}
															{reason?.sick_leave && <option className="rubik-400 fs-18" value={reason.sick_leave}>{reason.sick_leave}</option>}
															{reason?.vacation_paid && <option className="rubik-400 fs-18" value={reason.vacation_unpaid}>{reason.vacation_unpaid}</option>}
															{reason?.other && <option className="rubik-400 fs-18" value={reason.other}>{reason.other}</option>}
														</select>
													</div>
												</div>
											</div>

											{/* ----------------------------------------------------------------------------------------------- */}

											<label className="relative inline-flex items-center cursor-pointer">
												<input type="checkbox" value="" className="sr-only peer" checked={isChecked} onChange={toggleCheckbox} />
												<div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 ${isChecked ? 'dark:peer-focus:ring-blue-800 dark:bg-gray-700' : ''} peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`} ></div>
												<label htmlFor="password" className="block  text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18 ml-2">Whole Day</label>
											</label>

											<br /><br />

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="mb-2 sm:mb-4 md:mb-4 lg:mb-4 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18">Start Date</label>
													<div className="relative">
														<input name="start" ref={startDatePickerRef} value={startDateValue} type="text" className="h-12 rubik-400 fs-18 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300" placeholder="Select date start" />
														<div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
															<svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_46_30-708823)">
																	<path d="M25 2.5H22V1.5C22 1.23478 21.8946 0.98043 21.7071 0.792893C21.5196 0.605357 21.2652 0.5 21 0.5C20.7348 0.5 20.4804 0.605357 20.2929 0.792893C20.1054 0.98043 20 1.23478 20 1.5V2.5H15V1.5C15 1.23478 14.8946 0.98043 14.7071 0.792893C14.5196 0.605357 14.2652 0.5 14 0.5C13.7348 0.5 13.4804 0.605357 13.2929 0.792893C13.1054 0.98043 13 1.23478 13 1.5V2.5H8V1.5C8 1.23478 7.89464 0.98043 7.70711 0.792893C7.51957 0.605357 7.26522 0.5 7 0.5C6.73478 0.5 6.48043 0.605357 6.29289 0.792893C6.10536 0.98043 6 1.23478 6 1.5V2.5H3C2.20435 2.5 1.44129 2.81607 0.87868 3.37868C0.316071 3.94129 0 4.70435 0 5.5V23.5C0 24.2956 0.316071 25.0587 0.87868 25.6213C1.44129 26.1839 2.20435 26.5 3 26.5H25C25.7956 26.5 26.5587 26.1839 27.1213 25.6213C27.6839 25.0587 28 24.2956 28 23.5V5.5C28 4.70435 27.6839 3.94129 27.1213 3.37868C26.5587 2.81607 25.7956 2.5 25 2.5ZM2 5.5C2 5.23478 2.10536 4.98043 2.29289 4.79289C2.48043 4.60536 2.73478 4.5 3 4.5H6V5.5C6 5.76522 6.10536 6.01957 6.29289 6.20711C6.48043 6.39464 6.73478 6.5 7 6.5C7.26522 6.5 7.51957 6.39464 7.70711 6.20711C7.89464 6.01957 8 5.76522 8 5.5V4.5H13V5.5C13 5.76522 13.1054 6.01957 13.2929 6.20711C13.4804 6.39464 13.7348 6.5 14 6.5C14.2652 6.5 14.5196 6.39464 14.7071 6.20711C14.8946 6.01957 15 5.76522 15 5.5V4.5H20V5.5C20 5.76522 20.1054 6.01957 20.2929 6.20711C20.4804 6.39464 20.7348 6.5 21 6.5C21.2652 6.5 21.5196 6.39464 21.7071 6.20711C21.8946 6.01957 22 5.76522 22 5.5V4.5H25C25.2652 4.5 25.5196 4.60536 25.7071 4.79289C25.8946 4.98043 26 5.23478 26 5.5V8.5H2V5.5ZM26 23.5C26 23.7652 25.8946 24.0196 25.7071 24.2071C25.5196 24.3946 25.2652 24.5 25 24.5H3C2.73478 24.5 2.48043 24.3946 2.29289 24.2071C2.10536 24.0196 2 23.7652 2 23.5V10.5H26V23.5Z" fill="#3A52BB" />
																	<path d="M13 14.5H15C15.2652 14.5 15.5196 14.3946 15.7071 14.2071C15.8946 14.0196 16 13.7652 16 13.5C16 13.2348 15.8946 12.9804 15.7071 12.7929C15.5196 12.6054 15.2652 12.5 15 12.5H13C12.7348 12.5 12.4804 12.6054 12.2929 12.7929C12.1054 12.9804 12 13.2348 12 13.5C12 13.7652 12.1054 14.0196 12.2929 14.2071C12.4804 14.3946 12.7348 14.5 13 14.5Z" fill="#3A52BB" />
																	<path d="M6 14.5H8C8.26522 14.5 8.51957 14.3946 8.70711 14.2071C8.89464 14.0196 9 13.7652 9 13.5C9 13.2348 8.89464 12.9804 8.70711 12.7929C8.51957 12.6054 8.26522 12.5 8 12.5H6C5.73478 12.5 5.48043 12.6054 5.29289 12.7929C5.10536 12.9804 5 13.2348 5 13.5C5 13.7652 5.10536 14.0196 5.29289 14.2071C5.48043 14.3946 5.73478 14.5 6 14.5Z" fill="#3A52BB" />
																	<path d="M6 18.5H8C8.26522 18.5 8.51957 18.3946 8.70711 18.2071C8.89464 18.0196 9 17.7652 9 17.5C9 17.2348 8.89464 16.9804 8.70711 16.7929C8.51957 16.6054 8.26522 16.5 8 16.5H6C5.73478 16.5 5.48043 16.6054 5.29289 16.7929C5.10536 16.9804 5 17.2348 5 17.5C5 17.7652 5.10536 18.0196 5.29289 18.2071C5.48043 18.3946 5.73478 18.5 6 18.5Z" fill="#3A52BB" />
																	<path d="M13 18.5H15C15.2652 18.5 15.5196 18.3946 15.7071 18.2071C15.8946 18.0196 16 17.7652 16 17.5C16 17.2348 15.8946 16.9804 15.7071 16.7929C15.5196 16.6054 15.2652 16.5 15 16.5H13C12.7348 16.5 12.4804 16.6054 12.2929 16.7929C12.1054 16.9804 12 17.2348 12 17.5C12 17.7652 12.1054 18.0196 12.2929 18.2071C12.4804 18.3946 12.7348 18.5 13 18.5Z" fill="#3A52BB" />
																	<path d="M20 18.5H22C22.2652 18.5 22.5196 18.3946 22.7071 18.2071C22.8946 18.0196 23 17.7652 23 17.5C23 17.2348 22.8946 16.9804 22.7071 16.7929C22.5196 16.6054 22.2652 16.5 22 16.5H20C19.7348 16.5 19.4804 16.6054 19.2929 16.7929C19.1054 16.9804 19 17.2348 19 17.5C19 17.7652 19.1054 18.0196 19.2929 18.2071C19.4804 18.3946 19.7348 18.5 20 18.5Z" fill="#3A52BB" />
																	<path d="M20 14.5H22C22.2652 14.5 22.5196 14.3946 22.7071 14.2071C22.8946 14.0196 23 13.7652 23 13.5C23 13.2348 22.8946 12.9804 22.7071 12.7929C22.5196 12.6054 22.2652 12.5 22 12.5H20C19.7348 12.5 19.4804 12.6054 19.2929 12.7929C19.1054 12.9804 19 13.2348 19 13.5C19 13.7652 19.1054 14.0196 19.2929 14.2071C19.4804 14.3946 19.7348 14.5 20 14.5Z" fill="#3A52BB" />
																	<path d="M6 22.5H8C8.26522 22.5 8.51957 22.3946 8.70711 22.2071C8.89464 22.0196 9 21.7652 9 21.5C9 21.2348 8.89464 20.9804 8.70711 20.7929C8.51957 20.6054 8.26522 20.5 8 20.5H6C5.73478 20.5 5.48043 20.6054 5.29289 20.7929C5.10536 20.9804 5 21.2348 5 21.5C5 21.7652 5.10536 22.0196 5.29289 22.2071C5.48043 22.3946 5.73478 22.5 6 22.5Z" fill="#3A52BB" />
																	<path d="M13 22.5H15C15.2652 22.5 15.5196 22.3946 15.7071 22.2071C15.8946 22.0196 16 21.7652 16 21.5C16 21.2348 15.8946 20.9804 15.7071 20.7929C15.5196 20.6054 15.2652 20.5 15 20.5H13C12.7348 20.5 12.4804 20.6054 12.2929 20.7929C12.1054 20.9804 12 21.2348 12 21.5C12 21.7652 12.1054 22.0196 12.2929 22.2071C12.4804 22.3946 12.7348 22.5 13 22.5Z" fill="#3A52BB" />
																	<path d="M20 22.5H22C22.2652 22.5 22.5196 22.3946 22.7071 22.2071C22.8946 22.0196 23 21.7652 23 21.5C23 21.2348 22.8946 20.9804 22.7071 20.7929C22.5196 20.6054 22.2652 20.5 22 20.5H20C19.7348 20.5 19.4804 20.6054 19.2929 20.7929C19.1054 20.9804 19 21.2348 19 21.5C19 21.7652 19.1054 22.0196 19.2929 22.2071C19.4804 22.3946 19.7348 22.5 20 22.5Z" fill="#3A52BB" />
																</g>
																<defs>
																	<clipPath id="clip0_46_30-708823">
																		<rect width="28" height="26" fill="white" transform="translate(0 0.5)" />
																	</clipPath>
																</defs>
															</svg>
														</div>
													</div>
												</div>

												{showdate ? <>
													<div className="mb-4 sm:mb-4 md:mb-4 lg:mb-4 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
														<label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18">End Date</label>
														<div className="relative">
															<input name="end" type="text" readOnly ref={endDatePickerRef} value={endDateValue} className="h-12 rubik-400 fs-18 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300" placeholder="End Date" />
															<div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
																<svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<g clipPath="url(#clip0_46_30-708823)">
																		<path d="M25 2.5H22V1.5C22 1.23478 21.8946 0.98043 21.7071 0.792893C21.5196 0.605357 21.2652 0.5 21 0.5C20.7348 0.5 20.4804 0.605357 20.2929 0.792893C20.1054 0.98043 20 1.23478 20 1.5V2.5H15V1.5C15 1.23478 14.8946 0.98043 14.7071 0.792893C14.5196 0.605357 14.2652 0.5 14 0.5C13.7348 0.5 13.4804 0.605357 13.2929 0.792893C13.1054 0.98043 13 1.23478 13 1.5V2.5H8V1.5C8 1.23478 7.89464 0.98043 7.70711 0.792893C7.51957 0.605357 7.26522 0.5 7 0.5C6.73478 0.5 6.48043 0.605357 6.29289 0.792893C6.10536 0.98043 6 1.23478 6 1.5V2.5H3C2.20435 2.5 1.44129 2.81607 0.87868 3.37868C0.316071 3.94129 0 4.70435 0 5.5V23.5C0 24.2956 0.316071 25.0587 0.87868 25.6213C1.44129 26.1839 2.20435 26.5 3 26.5H25C25.7956 26.5 26.5587 26.1839 27.1213 25.6213C27.6839 25.0587 28 24.2956 28 23.5V5.5C28 4.70435 27.6839 3.94129 27.1213 3.37868C26.5587 2.81607 25.7956 2.5 25 2.5ZM2 5.5C2 5.23478 2.10536 4.98043 2.29289 4.79289C2.48043 4.60536 2.73478 4.5 3 4.5H6V5.5C6 5.76522 6.10536 6.01957 6.29289 6.20711C6.48043 6.39464 6.73478 6.5 7 6.5C7.26522 6.5 7.51957 6.39464 7.70711 6.20711C7.89464 6.01957 8 5.76522 8 5.5V4.5H13V5.5C13 5.76522 13.1054 6.01957 13.2929 6.20711C13.4804 6.39464 13.7348 6.5 14 6.5C14.2652 6.5 14.5196 6.39464 14.7071 6.20711C14.8946 6.01957 15 5.76522 15 5.5V4.5H20V5.5C20 5.76522 20.1054 6.01957 20.2929 6.20711C20.4804 6.39464 20.7348 6.5 21 6.5C21.2652 6.5 21.5196 6.39464 21.7071 6.20711C21.8946 6.01957 22 5.76522 22 5.5V4.5H25C25.2652 4.5 25.5196 4.60536 25.7071 4.79289C25.8946 4.98043 26 5.23478 26 5.5V8.5H2V5.5ZM26 23.5C26 23.7652 25.8946 24.0196 25.7071 24.2071C25.5196 24.3946 25.2652 24.5 25 24.5H3C2.73478 24.5 2.48043 24.3946 2.29289 24.2071C2.10536 24.0196 2 23.7652 2 23.5V10.5H26V23.5Z" fill="#3A52BB" />
																		<path d="M13 14.5H15C15.2652 14.5 15.5196 14.3946 15.7071 14.2071C15.8946 14.0196 16 13.7652 16 13.5C16 13.2348 15.8946 12.9804 15.7071 12.7929C15.5196 12.6054 15.2652 12.5 15 12.5H13C12.7348 12.5 12.4804 12.6054 12.2929 12.7929C12.1054 12.9804 12 13.2348 12 13.5C12 13.7652 12.1054 14.0196 12.2929 14.2071C12.4804 14.3946 12.7348 14.5 13 14.5Z" fill="#3A52BB" />
																		<path d="M6 14.5H8C8.26522 14.5 8.51957 14.3946 8.70711 14.2071C8.89464 14.0196 9 13.7652 9 13.5C9 13.2348 8.89464 12.9804 8.70711 12.7929C8.51957 12.6054 8.26522 12.5 8 12.5H6C5.73478 12.5 5.48043 12.6054 5.29289 12.7929C5.10536 12.9804 5 13.2348 5 13.5C5 13.7652 5.10536 14.0196 5.29289 14.2071C5.48043 14.3946 5.73478 14.5 6 14.5Z" fill="#3A52BB" />
																		<path d="M6 18.5H8C8.26522 18.5 8.51957 18.3946 8.70711 18.2071C8.89464 18.0196 9 17.7652 9 17.5C9 17.2348 8.89464 16.9804 8.70711 16.7929C8.51957 16.6054 8.26522 16.5 8 16.5H6C5.73478 16.5 5.48043 16.6054 5.29289 16.7929C5.10536 16.9804 5 17.2348 5 17.5C5 17.7652 5.10536 18.0196 5.29289 18.2071C5.48043 18.3946 5.73478 18.5 6 18.5Z" fill="#3A52BB" />
																		<path d="M13 18.5H15C15.2652 18.5 15.5196 18.3946 15.7071 18.2071C15.8946 18.0196 16 17.7652 16 17.5C16 17.2348 15.8946 16.9804 15.7071 16.7929C15.5196 16.6054 15.2652 16.5 15 16.5H13C12.7348 16.5 12.4804 16.6054 12.2929 16.7929C12.1054 16.9804 12 17.2348 12 17.5C12 17.7652 12.1054 18.0196 12.2929 18.2071C12.4804 18.3946 12.7348 18.5 13 18.5Z" fill="#3A52BB" />
																		<path d="M20 18.5H22C22.2652 18.5 22.5196 18.3946 22.7071 18.2071C22.8946 18.0196 23 17.7652 23 17.5C23 17.2348 22.8946 16.9804 22.7071 16.7929C22.5196 16.6054 22.2652 16.5 22 16.5H20C19.7348 16.5 19.4804 16.6054 19.2929 16.7929C19.1054 16.9804 19 17.2348 19 17.5C19 17.7652 19.1054 18.0196 19.2929 18.2071C19.4804 18.3946 19.7348 18.5 20 18.5Z" fill="#3A52BB" />
																		<path d="M20 14.5H22C22.2652 14.5 22.5196 14.3946 22.7071 14.2071C22.8946 14.0196 23 13.7652 23 13.5C23 13.2348 22.8946 12.9804 22.7071 12.7929C22.5196 12.6054 22.2652 12.5 22 12.5H20C19.7348 12.5 19.4804 12.6054 19.2929 12.7929C19.1054 12.9804 19 13.2348 19 13.5C19 13.7652 19.1054 14.0196 19.2929 14.2071C19.4804 14.3946 19.7348 14.5 20 14.5Z" fill="#3A52BB" />
																		<path d="M6 22.5H8C8.26522 22.5 8.51957 22.3946 8.70711 22.2071C8.89464 22.0196 9 21.7652 9 21.5C9 21.2348 8.89464 20.9804 8.70711 20.7929C8.51957 20.6054 8.26522 20.5 8 20.5H6C5.73478 20.5 5.48043 20.6054 5.29289 20.7929C5.10536 20.9804 5 21.2348 5 21.5C5 21.7652 5.10536 22.0196 5.29289 22.2071C5.48043 22.3946 5.73478 22.5 6 22.5Z" fill="#3A52BB" />
																		<path d="M13 22.5H15C15.2652 22.5 15.5196 22.3946 15.7071 22.2071C15.8946 22.0196 16 21.7652 16 21.5C16 21.2348 15.8946 20.9804 15.7071 20.7929C15.5196 20.6054 15.2652 20.5 15 20.5H13C12.7348 20.5 12.4804 20.6054 12.2929 20.7929C12.1054 20.9804 12 21.2348 12 21.5C12 21.7652 12.1054 22.0196 12.2929 22.2071C12.4804 22.3946 12.7348 22.5 13 22.5Z" fill="#3A52BB" />
																		<path d="M20 22.5H22C22.2652 22.5 22.5196 22.3946 22.7071 22.2071C22.8946 22.0196 23 21.7652 23 21.5C23 21.2348 22.8946 20.9804 22.7071 20.7929C22.5196 20.6054 22.2652 20.5 22 20.5H20C19.7348 20.5 19.4804 20.6054 19.2929 20.7929C19.1054 20.9804 19 21.2348 19 21.5C19 21.7652 19.1054 22.0196 19.2929 22.2071C19.4804 22.3946 19.7348 22.5 20 22.5Z" fill="#3A52BB" />
																	</g>
																	<defs>
																		<clipPath id="clip0_46_30-708823">
																			<rect width="28" height="26" fill="white" transform="translate(0 0.5)" />
																		</clipPath>
																	</defs>
																</svg>
															</div>
														</div>
													</div>

													<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
														<label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18">Total Hour</label>
														<div className="relative">
															<input name="totalHour" style={{ cursor: 'not-allowed' }} type="text" value={totalHours} readOnly className="h-12 rubik-400 fs-18 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300" placeholder="Total Hours" />
														</div>
													</div>
												</>
													:
													<>
														<input name="totalHour" type="time" className=" hidden h-12 rubik-400 fs-18 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300" placeholder="Total Hours" />

														<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
															<label htmlFor="startTime" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18">
																Start Time
															</label>
															<div className="relative btnnn">
																<input name="startTime" id="start-date" type="time"
																	className="btnnn h-12 rubik-400 fs-18 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300"
																	placeholder="Total Hours"
																	onChange={(e) => {
																		const value = e.target.value;
																		const isValidTime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
																		if (isValidTime) {
																			const [hours, minutes] = value.split(':');
																			const parsedHours = parseInt(hours, 10);
																			const period = parsedHours >= 12 ? 'PM' : 'AM';
																			setFormData({
																				...formData,
																				startTime: value,
																				startTimeAmPm: period,
																			});
																		}
																	}}
																	value={formData.startTime}
																/>
																<div class="close-icon" id="close-start-date" onClick={() => {
																	setFormData({
																		...formData,
																		startTime: '',
																		startTimeAmPm: '',
																	});
																}}>X</div>
															</div>
														</div>

														<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
															<label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18">End Time</label>
															<div className="relative">
																<input name="endTime" type="time" id="end-date" className="h-12 rubik-400 fs-18 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300" placeholder="Total Hours"
																	onChange={(e) => {
																		const value = e.target.value;
																		const isValidTime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
																		if (isValidTime) {
																			const [hours, minutes] = value.split(':');
																			const parsedHours = parseInt(hours, 10);
																			const period = parsedHours >= 12 ? 'PM' : 'AM';
																			setFormData({
																				...formData,
																				endTime: value,
																				endTimeAmPm: period,
																			});
																		}
																	}}
																	value={formData.endTime}
																/>
																<div class="close-icon" id="close-start-date">X</div>
															</div>
														</div>
													</>}
											</div>


											{/* ----------------------------------------------------------------------------------------------- */}

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="mb-6 col-span-12">
													<div className="flex gap-2">
														<textarea id="message" onChange={handleChange} defaultValue={formData.discription} name='discription' rows="4" className="resize-none rubik-400 fs-18  block p-2.5 w-full text-sm text-gray-900 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300" placeholder="Add a Description..."></textarea>
													</div>
												</div>
											</div>

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-2 lg:gap-x-7">
												<div className="col-span-12">
													<label htmlFor="email"
														className="block text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18">
														Shift Covered By (Name)
													</label>
												</div>

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
													<div className="relative">
														<input type="text" id="email"
															className="pl-4 rubik-400 fs-18 h-12 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300"
															placeholder="Enter Employee Name" onChange={handleChange} defaultValue={formData.shiftCoveredByName} name='shiftCoveredByName' />
													</div>
												</div>

												<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
													<label htmlFor="email"
														className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18 hidden"></label>
													<div className="relative">
														<div className="relative w-full">
															<input id="datePickerNewRequest" name="start" type="text"
																className="h-12 rubik-400 fs-18 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300"
																placeholder="Date(s)" defaultValue={dateValue} disabled />
															<div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
																<svg width="28" height="27" viewBox="0 0 28 27" fill="none"
																	xmlns="http://www.w3.org/2000/svg">
																	<g clipPath="url(#clip0_46_30-708823)">
																		<path d="M25 2.5H22V1.5C22 1.23478 21.8946 0.98043 21.7071 0.792893C21.5196 0.605357 21.2652 0.5 21 0.5C20.7348 0.5 20.4804 0.605357 20.2929 0.792893C20.1054 0.98043 20 1.23478 20 1.5V2.5H15V1.5C15 1.23478 14.8946 0.98043 14.7071 0.792893C14.5196 0.605357 14.2652 0.5 14 0.5C13.7348 0.5 13.4804 0.605357 13.2929 0.792893C13.1054 0.98043 13 1.23478 13 1.5V2.5H8V1.5C8 1.23478 7.89464 0.98043 7.70711 0.792893C7.51957 0.605357 7.26522 0.5 7 0.5C6.73478 0.5 6.48043 0.605357 6.29289 0.792893C6.10536 0.98043 6 1.23478 6 1.5V2.5H3C2.20435 2.5 1.44129 2.81607 0.87868 3.37868C0.316071 3.94129 0 4.70435 0 5.5V23.5C0 24.2956 0.316071 25.0587 0.87868 25.6213C1.44129 26.1839 2.20435 26.5 3 26.5H25C25.7956 26.5 26.5587 26.1839 27.1213 25.6213C27.6839 25.0587 28 24.2956 28 23.5V5.5C28 4.70435 27.6839 3.94129 27.1213 3.37868C26.5587 2.81607 25.7956 2.5 25 2.5ZM2 5.5C2 5.23478 2.10536 4.98043 2.29289 4.79289C2.48043 4.60536 2.73478 4.5 3 4.5H6V5.5C6 5.76522 6.10536 6.01957 6.29289 6.20711C6.48043 6.39464 6.73478 6.5 7 6.5C7.26522 6.5 7.51957 6.39464 7.70711 6.20711C7.89464 6.01957 8 5.76522 8 5.5V4.5H13V5.5C13 5.76522 13.1054 6.01957 13.2929 6.20711C13.4804 6.39464 13.7348 6.5 14 6.5C14.2652 6.5 14.5196 6.39464 14.7071 6.20711C14.8946 6.01957 15 5.76522 15 5.5V4.5H20V5.5C20 5.76522 20.1054 6.01957 20.2929 6.20711C20.4804 6.39464 20.7348 6.5 21 6.5C21.2652 6.5 21.5196 6.39464 21.7071 6.20711C21.8946 6.01957 22 5.76522 22 5.5V4.5H25C25.2652 4.5 25.5196 4.60536 25.7071 4.79289C25.8946 4.98043 26 5.23478 26 5.5V8.5H2V5.5ZM26 23.5C26 23.7652 25.8946 24.0196 25.7071 24.2071C25.5196 24.3946 25.2652 24.5 25 24.5H3C2.73478 24.5 2.48043 24.3946 2.29289 24.2071C2.10536 24.0196 2 23.7652 2 23.5V10.5H26V23.5Z" fill="#3A52BB" />
																		<path d="M13 14.5H15C15.2652 14.5 15.5196 14.3946 15.7071 14.2071C15.8946 14.0196 16 13.7652 16 13.5C16 13.2348 15.8946 12.9804 15.7071 12.7929C15.5196 12.6054 15.2652 12.5 15 12.5H13C12.7348 12.5 12.4804 12.6054 12.2929 12.7929C12.1054 12.9804 12 13.2348 12 13.5C12 13.7652 12.1054 14.0196 12.2929 14.2071C12.4804 14.3946 12.7348 14.5 13 14.5Z" fill="#3A52BB" />
																		<path d="M6 14.5H8C8.26522 14.5 8.51957 14.3946 8.70711 14.2071C8.89464 14.0196 9 13.7652 9 13.5C9 13.2348 8.89464 12.9804 8.70711 12.7929C8.51957 12.6054 8.26522 12.5 8 12.5H6C5.73478 12.5 5.48043 12.6054 5.29289 12.7929C5.10536 12.9804 5 13.2348 5 13.5C5 13.7652 5.10536 14.0196 5.29289 14.2071C5.48043 14.3946 5.73478 14.5 6 14.5Z" fill="#3A52BB" />
																		<path d="M6 18.5H8C8.26522 18.5 8.51957 18.3946 8.70711 18.2071C8.89464 18.0196 9 17.7652 9 17.5C9 17.2348 8.89464 16.9804 8.70711 16.7929C8.51957 16.6054 8.26522 16.5 8 16.5H6C5.73478 16.5 5.48043 16.6054 5.29289 16.7929C5.10536 16.9804 5 17.2348 5 17.5C5 17.7652 5.10536 18.0196 5.29289 18.2071C5.48043 18.3946 5.73478 18.5 6 18.5Z" fill="#3A52BB" />
																		<path d="M13 18.5H15C15.2652 18.5 15.5196 18.3946 15.7071 18.2071C15.8946 18.0196 16 17.7652 16 17.5C16 17.2348 15.8946 16.9804 15.7071 16.7929C15.5196 16.6054 15.2652 16.5 15 16.5H13C12.7348 16.5 12.4804 16.6054 12.2929 16.7929C12.1054 16.9804 12 17.2348 12 17.5C12 17.7652 12.1054 18.0196 12.2929 18.2071C12.4804 18.3946 12.7348 18.5 13 18.5Z" fill="#3A52BB" />
																		<path d="M20 18.5H22C22.2652 18.5 22.5196 18.3946 22.7071 18.2071C22.8946 18.0196 23 17.7652 23 17.5C23 17.2348 22.8946 16.9804 22.7071 16.7929C22.5196 16.6054 22.2652 16.5 22 16.5H20C19.7348 16.5 19.4804 16.6054 19.2929 16.7929C19.1054 16.9804 19 17.2348 19 17.5C19 17.7652 19.1054 18.0196 19.2929 18.2071C19.4804 18.3946 19.7348 18.5 20 18.5Z" fill="#3A52BB" />
																		<path d="M20 14.5H22C22.2652 14.5 22.5196 14.3946 22.7071 14.2071C22.8946 14.0196 23 13.7652 23 13.5C23 13.2348 22.8946 12.9804 22.7071 12.7929C22.5196 12.6054 22.2652 12.5 22 12.5H20C19.7348 12.5 19.4804 12.6054 19.2929 12.7929C19.1054 12.9804 19 13.2348 19 13.5C19 13.7652 19.1054 14.0196 19.2929 14.2071C19.4804 14.3946 19.7348 14.5 20 14.5Z" fill="#3A52BB" />
																		<path d="M6 22.5H8C8.26522 22.5 8.51957 22.3946 8.70711 22.2071C8.89464 22.0196 9 21.7652 9 21.5C9 21.2348 8.89464 20.9804 8.70711 20.7929C8.51957 20.6054 8.26522 20.5 8 20.5H6C5.73478 20.5 5.48043 20.6054 5.29289 20.7929C5.10536 20.9804 5 21.2348 5 21.5C5 21.7652 5.10536 22.0196 5.29289 22.2071C5.48043 22.3946 5.73478 22.5 6 22.5Z" fill="#3A52BB" />
																		<path d="M13 22.5H15C15.2652 22.5 15.5196 22.3946 15.7071 22.2071C15.8946 22.0196 16 21.7652 16 21.5C16 21.2348 15.8946 20.9804 15.7071 20.7929C15.5196 20.6054 15.2652 20.5 15 20.5H13C12.7348 20.5 12.4804 20.6054 12.2929 20.7929C12.1054 20.9804 12 21.2348 12 21.5C12 21.7652 12.1054 22.0196 12.2929 22.2071C12.4804 22.3946 12.7348 22.5 13 22.5Z" fill="#3A52BB" />
																		<path d="M20 22.5H22C22.2652 22.5 22.5196 22.3946 22.7071 22.2071C22.8946 22.0196 23 21.7652 23 21.5C23 21.2348 22.8946 20.9804 22.7071 20.7929C22.5196 20.6054 22.2652 20.5 22 20.5H20C19.7348 20.5 19.4804 20.6054 19.2929 20.7929C19.1054 20.9804 19 21.2348 19 21.5C19 21.7652 19.1054 22.0196 19.2929 22.2071C19.4804 22.3946 19.7348 22.5 20 22.5Z" fill="#3A52BB" />
																	</g>
																	<defs>
																		<clipPath id="clip0_46_30-708823">
																			<rect width="28" height="26" fill="white" transform="translate(0 0.5)" />
																		</clipPath>
																	</defs>
																</svg>
															</div>
														</div>
													</div>
												</div>
											</div>



											{
												message !== null ?
													<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
														<div className="col-span-12">
															<Errors error={message} />
														</div>
													</div>

													: null
											}

											{
												successMsg !== null ?
													<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
														<div className="col-span-12">
															<SuccessMessage message={successMsg} />
														</div>
													</div>

													: null
											}

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
												<div className="col-span-12">
													<button type="submit" className="rubik-700 fs-16 h-12 text-white blueBg-color w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
														{editTimeOffRequest.loading ? 'Processing...' : 'Save'}
													</button>
												</div>
											</div>

										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div >
				<Footer />
			</div>
			{/* <ChatBox /> */}
		</>
	)
}

export default EditRequest