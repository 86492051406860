import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useCustomSelector, useCustomDispatch } from '../../hooks';
import { fetchStatHolidays } from '../../services/actions/main/getAllStatHolidayActions';
import { LoadingView, Sidebar } from '../../components/common';

import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const PharmacyStatHolidays = () => {

    const dispatch = useCustomDispatch()

    const statHolidaysResponse = useCustomSelector(state => state.statHolidays)
    const holidays = statHolidaysResponse?.holidays?.data

    const [myEventsList, setMyEventsList] = useState([])

    useEffect(() => {
        dispatch(fetchStatHolidays())
    }, [])

    useEffect(() => {
        if (statHolidaysResponse?.loading) {
            setMyEventsList([])
        }
        else if (holidays && holidays?.length > 0) {
            const uniqueDates = new Set();
            const holidayEvents = holidays
                .map(holiday => ({
                    title: holiday.name,
                    start: new Date(holiday.date),
                    end: new Date(holiday.date)
                }))
                .filter(event => {
                    const dateString = event.start.toDateString();
                    if (!uniqueDates.has(dateString)) {
                        uniqueDates.add(dateString);
                        return true;
                    }
                    return false;
                });

            setMyEventsList(prevEvents => [...prevEvents, ...holidayEvents]);
        }
    }, [holidays, statHolidaysResponse]);

    return (
        <React.Fragment>
            <div className="BodyBgColor">
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="container mt-2">
                                    <div className=" border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        {statHolidaysResponse?.loading ?
                                            <div className='flex items-center justify-center mt-60 mb-80'>
                                                <LoadingView />
                                            </div>
                                            :
                                            <Calendar
                                                localizer={localizer}
                                                events={myEventsList}
                                                startAccessor="start"
                                                endAccessor="end"
                                                style={{ height: 700, borderRadius: '8px' }}
                                            />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PharmacyStatHolidays