import React, {
	useEffect,
	useRef,
	useState
} from 'react'

import {
	initalPharmacyInformationUpdateForm,
	initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import {
	BreadCrumb,
	Footer,
	Sidebar
} from '../../../components/common'

import { Loader } from '../../../components/common/loadingView'
import { Errors } from '../../../components/error/alertMessages'
import { useCustomDispatch } from '../../../hooks'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

import { onhandlePharmacyFormDataChange } from '../../../utils/data/handleFormDataChange'
import { handlePharmacistCommunicationForm } from '../../../utils/validations/formsValidations'

import { SuccessToastMessage } from '../../../components/loaders/toastMessage'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'

import ChatBox from '../../../components/chat/chatbox'

import * as PharmacyDatePicker from '../../../assets/js/datepicker'
import * as Pharmacy from '../../../components/pharmacy/form/form'
import * as handlePrescriber from '../../../utils/data/handleFormDataChange'

import ImageCanvas from '../../../components/pharmacy/form/canvaCopyPasteImage'
import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'
import { faxFormFailure } from '../../../services/actions/pharmacy/forms/pharmacyUpdateNotificationsSrFaxForm'

const PharmacistPrescriberCommunication = () => {

	const dispatch = useCustomDispatch()
	const prescibingDateRef = useRef()
	const startDateRef = useRef()
	const endDateRef = useRef()
	const methadoneDoseDateRef = useRef()
	const dailyDoseDateRef = useRef()

	const { pharmacyLoginData } = useCustomSelectors()
	const { previewPdfResponse, sendFaxResponse } = useFormsSelectors()

	const [canvaValue, setCanValue] = useState('')
	const [formData, setFormData] = useState(initalPharmacyInformationUpdateForm)
	const [errorMessages, setErrorMessages] = useState(initalPharmacyInformationUpdateForm)
	const [showToast, setShowToast] = useState(initalShowToastMessage)

	useEffect(() => {
		if (pharmacyLoginData) {
			setFormData({
				...formData,
				pharmacyManager: pharmacyLoginData.manager_name,
				pharmacyName: pharmacyLoginData.name,
				pharmacyFax: pharmacyLoginData.fax
			})
		}
	}, [pharmacyLoginData])

	const dateFields = [
		{ ref: prescibingDateRef, key: 'prescribingDate' },
		{ ref: startDateRef, key: 'prescribingStartDate' },
		{ ref: endDateRef, key: 'prescribingEndDate' },
		{ ref: methadoneDoseDateRef, key: 'methadoneDoseDate' },
		{ ref: dailyDoseDateRef, key: 'dailyDoseDate' }
	]

	useEffect(() => {
		const destroyDatePickers = dateFields?.map(({ ref, key }) => {
			const endDatePicker = PharmacyDatePicker.initialPharmacyDatePicker(ref, setFormData, formData, key);
			return () => {
				if (endDatePicker) {
					endDatePicker.destroy();
				}
			};
		});

		return () => {
			destroyDatePickers.forEach(destroy => destroy());
		};
	}, [formData, prescibingDateRef, startDateRef, endDateRef])


	const handleFormDataChange = (event) => {
		const { name, checked, value } = event.target;
		setFormData((prevValues) => ({
			...prevValues,
			returnForm: event.target.type === 'checkbox'
				? checked
					? [...(prevValues.returnForm || []), parseInt(value, 10)]
					: (prevValues.returnForm || []).filter(num => num !== parseInt(value, 10))
				: prevValues.returnForm,
		}))

		if (event.target.type !== 'checkbox') {
			onhandlePharmacyFormDataChange(event, setFormData, formData, setErrorMessages, errorMessages)
		}
	}

	useEffect(() => {
		dispatch(previewPdfFailure(null))
		dispatch(faxFormFailure(null))
	}, [])

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">

									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
											<Pharmacy.Date formData={formData}
												setFormData={setFormData} />


											<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
												<Pharmacy.FormWinRxPatients
													formData={formData}
													handleFormDataChange={handleFormDataChange} />
											</ul>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
											<Pharmacy.PatientPhn
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setErrorMessages={setErrorMessages}
												setFormData={setFormData} />

											<Pharmacy.PatientName
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
												setErrorMessages={setErrorMessages} />

											<Pharmacy.PatientDob
												setErrorMessages={setErrorMessages}
												formData={formData}
												setFormData={setFormData}
												handleFormDataChange={handleFormDataChange}
												errorMessages={errorMessages} />
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<div className="relative ot_addNoteText">
												<div className="relative did-floating-label-content">
													<Pharmacy.ClinicName
														formData={formData}
														errorMessages={errorMessages}
														setFormData={setFormData}
														setErrorMessages={setErrorMessages} />

													<Pharmacy.AddClinic />
												</div>
											</div>

											<Pharmacy.ClinicFaxNumber
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages} />


											<div className="relative">
												<Pharmacy.DoctorList
													formData={formData}
													errorMessages={errorMessages}
													setFormData={setFormData}
													setErrorMessages={setErrorMessages} />
												<Pharmacy.AddDoctor />
											</div>

										</div>
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 pb-2">
											<h1 className='rubik-500 fs-16 mt-14 sm:mt-14 md:mt-14 lg:mt-8'>For Prescriber’s Information and Patient Records</h1>
										</div>
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 ">
											<div className="relative lblock sm:block md:block lg:flex items-center  ">
												<input id="1" onChange={(e) => handlePrescriber.handlePrescriberPatientRecordCheckboxChange(e, setFormData)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
												<label htmlFor="1" className="py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">This patient missed their methadone dose for date  </label>

												<div>
													<input type="text" id="methadoneDoseDateRef" ref={methadoneDoseDateRef} className="focus:ring-0 rubik-400 fs-14 border-b-2 border-0 border-gray-300 text-gray-900 block w-full sm:w-18 md:w-18 lg:w-18 py-0 " placeholder="Enter value" />
												</div>
											</div>

											<div className="relative block sm:block md:block lg:flex items-center">
												<input id="2" onChange={(e) => handlePrescriber.handlePrescriberPatientRecordCheckboxChange(e, setFormData)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
												<label htmlFor="2" className="py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">This patient did not take their full daily dose for date  </label>

												<div className="block sm:block md:flex lg:flex items-center">
													<input type="text" id="dailyDoseDate" ref={dailyDoseDateRef} className="focus:ring-0 rubik-400 fs-14 border-b-2 border-0 border-gray-300 text-gray-900 block w-full sm:w-18 md:w-18 lg:w-18 py-0 " placeholder="Enter value" />
													<label className='w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer'>
														and consumed only
													</label>

													<input type="text" id="mg" onChange={handleFormDataChange} name='consumedDose' value={formData.consumedDose} className="focus:ring-0 rubik-400 fs-14 border-b-2 border-0 border-gray-300 text-gray-900 block w-full sm:w-18 md:w-18 lg:w-18 py-0 " placeholder="Enter value"
														onKeyPress={(e) => {
															if (isNaN(String.fromCharCode(e.charCode))) {
																e.preventDefault();
															}
														}} />
													<label className='w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer'>
														mg of the
													</label>

													<input type="text" onChange={handleFormDataChange} name='totalDose' value={formData.totalDose} id="prescribed_dose" className="focus:ring-0 rubik-400 fs-14 border-b-2 border-0 border-gray-300 text-gray-900 block w-full sm:w-18 md:w-18 lg:w-18 py-0 " placeholder="Enter value" onKeyPress={(e) => {
														if (isNaN(String.fromCharCode(e.charCode))) {
															e.preventDefault();
														}
													}} />
													<label className='w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer'>
														mg prescribed dose.
													</label>
												</div>
											</div>
										</div>
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 pb-2">
											<h1 className='rubik-500 fs-16 mt-4 sm:mt-4 md:mt-4 lg:mt-4'>For Prescriber’s Information and Patient Records</h1>
										</div>
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 ">
											{[
												'We require clarity regarding the ‘prescribing date’ and/or ‘start day’ for the attached Controlled Prescription Program form. Please indicate the actual ‘prescribing date’ (actual date theprescription was written) and dispensing ‘start date’ or range.',
												'We require clarification and/or a change to the ‘Directions for Use’ section of the attached Controlled Prescription Program form.',
											]?.map((label, index) => (
												<div key={index} className="flex items-center mb-0 gap-3">
													<input id={`checkbox-${index}`} name="returnForm" type="checkbox"
														value={index + 1} checked={formData?.returnForm?.includes(index + 1)}
														onChange={handleFormDataChange}
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
													<label htmlFor={`checkbox-${index}`} className="fs-14 rubik-400 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
														{label}
													</label>
												</div>
											))}
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
											<div className="relative">
												<input type="text" id="PrescribingDate" ref={prescibingDateRef} name='prescribingDate' value={formData.prescribingDate}
													onChange={handleFormDataChange}
													className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
												<label htmlFor="PrescribingDate" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Prescribing Date</label>
											</div>

											<div className="relative">
												<input type="text" id="PrescriptionFolio" name='prescriptionFolio' value={formData.prescriptionFolio} onChange={handleFormDataChange}
													className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.prescriptionFolio && 'border-red-600'
														}`} placeholder="" />
												<label htmlFor="PrescriptionFolio"
													className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
													{!errorMessages.prescriptionFolio && 'Prescription Folio'}
												</label>
												{errorMessages.prescriptionFolio && (
													<p className="mt-2 text-xs text-red-600 dark:text-red-400">
														{errorMessages.prescriptionFolio}
													</p>
												)}
											</div>

											<div className="relative">
												<input type="text" id="prescriptionCPID" name='prescriptionCPID' value={formData.prescriptionCPID} onChange={handleFormDataChange}
													className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.prescriptionCPID && 'border-red-600'
														}`} placeholder="" onKeyPress={(e) => {
															if (isNaN(String.fromCharCode(e.charCode))) {
																e.preventDefault();
															}
														}} />
												<label htmlFor="prescriptionCPID"
													className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
													{!errorMessages.prescriptionCPID && 'Prescriber CPID'}
												</label>
												{errorMessages.prescriptionCPID && (
													<p className="mt-2 text-xs text-red-600 dark:text-red-400">
														{errorMessages.prescriptionCPID}
													</p>
												)}
											</div>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-8">
											<div className="relative">
												<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
													<li className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
														<div className="flex items-center ps-3">
															<input id="start_date" value='single_date' onChange={(e) => setFormData({ ...formData, prescribingDateRange: e.target.value })} defaultChecked type="radio" name='prescribingDateRange' className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" onKeyPress={(e) => {
																if (isNaN(String.fromCharCode(e.charCode))) {
																	e.preventDefault();
																}
															}} />
															<label htmlFor="start_date" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Start Date</label>
														</div>
													</li>
													<li className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
														<div className="flex items-center ps-3">
															<input id="date_range" name='prescribingDateRange' onChange={(e) => setFormData({ ...formData, prescribingDateRange: e.target.value })} value='range_date' type="radio" className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
															<label htmlFor="date_range" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Date Range</label>
														</div>
													</li>
												</ul>
												<label htmlFor="Gender" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
													Despensing Date
												</label>
											</div>

											<div className="relative">
												<input type="text" id="startDateRef" ref={startDateRef} name='prescribingStartDate' value={formData.prescribingStartDate}
													onChange={handleFormDataChange}
													className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
												<label htmlFor="startDateRef" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Start Date</label>
											</div>
											{formData.prescribingDateRange === 'range_date' &&
												<div className="relative">
													<input type="text" id="prescribingEndDate" ref={endDateRef} name='prescribingEndDate' value={formData.prescribingEndDate}
														onChange={handleFormDataChange}
														className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
													<label htmlFor="prescribingEndDate" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">End Date</label>
												</div>}
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">
											<div className="did-floating-label-content">
												<div id="cancellationNotesContainer" className="form-group full">
													<textarea maxLength="255" placeholder='Description of authorized changes'
														name='descriptionAuthorized' value={formData.descriptionAuthorized}
														onChange={handleFormDataChange}
														className="fs-18 rounded-md rubik-400 border border-gray-300 text-gray-900" id="cancellationNotes">
													</textarea>
													{errorMessages.descriptionAuthorized && (
														<p className="mt-2 text-xs text-red-600 dark:text-red-400">
															{errorMessages.descriptionAuthorized}
														</p>
													)}
												</div>
											</div>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">
											<ImageCanvas setCanValue={setCanValue} canvaValue={canvaValue} />
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-4 mt-2">
											<Pharmacy.LoginPharmacyName
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
											/>

											<Pharmacy.LoginPharmacyManger handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
											/>

											<Pharmacy.LoginPharmacyFax handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
											/>

											<Pharmacy.Designation
												handleFormDataChange={handleFormDataChange}
												formData={formData} errorMessages={errorMessages}
												setFormData={setFormData}
											/>
										</div>

										{previewPdfResponse?.error !== null &&
											<>
												<br />
												<Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
											</>
										}

										{sendFaxResponse?.error !== null &&
											<>
												<br />
												<Errors error={sendFaxResponse?.error.message ?
													sendFaxResponse?.error?.message :
													'Something went wrong when sending Fax. Please try again'} />
											</>
										}

										<div className='flex'>
											<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
												<button style={{ height: '40px' }} type="button"

													onClick={() => handlePharmacistCommunicationForm(
														formData,
														setErrorMessages,
														errorMessages,
														dispatch,
														'PDF', null, null, canvaValue)}

													className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

													{previewPdfResponse?.loading ? <>
														Genrating PDF
														&nbsp;<Loader /> </> : 'Preview PDF'}

												</button>
											</div>

											{pharmacyLoginData?.srFax_active === 1 && pharmacyLoginData?.srFax_status === 1 && 
												<div className="pl-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
													<button style={{ height: '40px', backgroundColor: 'lightseagreen' }} type="button"

														onClick={() => handlePharmacistCommunicationForm(
															formData,
															setErrorMessages,
															errorMessages,
															dispatch,
															'FAX',
															showToast,
															setShowToast, canvaValue)}

														className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center" >

														{sendFaxResponse?.loading ? <>
															Sending
															&nbsp;<Loader /> </> : 'Send Fax'}


													</button>
												</div>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ChatBox />
			<Footer />

			{showToast.showToast && <SuccessToastMessage status={'Fax sent'} />}

		</>
	)
}

export default PharmacistPrescriberCommunication