import { pharmacy_patient_send_photo_link } from "../../networking/urlEndPoints"
import { fetchPatientsList } from "../../services/actions/patients/patientsList";

import Swal from 'sweetalert2';
import Axios from '../../networking/intraceptor';

export const sendCpLinkAlert = (value, paginationNumber, querySearch, queryStatus, dispatch) => {
	const swalWithTailwindButtons = Swal.mixin({
		customClass: {
			cancelButton: 'bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded',
			confirmButton: 'm-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded',
		},
		buttonsStyling: false
	});

	swalWithTailwindButtons
		.fire({
			title: 'Are you sure?',
			text: 'You want to send a photo upload link?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			reverseButtons: true
		})
		.then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await Axios.post(`${pharmacy_patient_send_photo_link}`, {
						patient_id: value.id,
					})
					if (response?.status === 200) {
						swalWithTailwindButtons.fire({
							title: 'Success',
							text: response?.data?.success,
							icon: 'success'
						});
						dispatch(fetchPatientsList(querySearch, queryStatus, paginationNumber))
					} else {
						throw new Error(response)
					}
				} catch (error) {
					if (error?.response) {
						swalWithTailwindButtons.fire({
							title: 'Error',
							text: `There was an error sending the photo upload link: ${error.message}`,
							icon: 'error'
						});
					}
				}
			}
			else if (result.dismiss === Swal.DismissReason.cancel) {
				swalWithTailwindButtons.fire({
					title: 'Process has been cancelled',
					icon: 'error'
				});
			}
		})
}



