import {
	FETCH_ALL_CLINICS_REQUEST,
	FETCH_ALL_CLINICS_SUCCESS,
	FETCH_ALL_CLINICS_FAILURE
} from "../../../constants"

import { get_clinic } from "../../../../networking/urlEndPoints"
import Axios from "../../../../networking/intraceptor"

export const getClinicsRequest = () => ({
	type: FETCH_ALL_CLINICS_REQUEST,
})

export const getClinicsSuccess = (data) => ({
	type: FETCH_ALL_CLINICS_SUCCESS,
	payload: data,
})

export const getClinicsFailure = (error) => ({
	type: FETCH_ALL_CLINICS_FAILURE,
	payload: error,
})

export const fetchAllClinics = (search) => {
	return async (dispatch) => {
		dispatch(getClinicsRequest())

		const params = {
			search: search //not removing this no idea for what its implemented
		}

		let url = `${get_clinic}`;
		if (search) {
			// url += search
			params.search = search
		}
		try {
			const response = await Axios.get(url, { params })
			
			if (response?.status === 200) {
				dispatch(getClinicsSuccess(response?.data))
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(getClinicsFailure(error?.response))
			}
		}
	}
}
