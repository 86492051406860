import {
    LIST_APP_DATA_REQUEST,
    LIST_APP_DATA_SUCCESS,
    LIST_APP_DATA_FAILURE
} from "../../../constants"

import { pharmacy_annoucement_list, p_app_users, p_custom_notification, tb_skin_test_logs } from "../../../../networking/urlEndPoints"
import Axios from "../../../../networking/intraceptor"

export const appListRequest = (types) => ({
    type: LIST_APP_DATA_REQUEST,
    types: types
})

export const appListSuccess = (data, types) => ({
    type: LIST_APP_DATA_SUCCESS,
    payload: data,
    types: types
})

export const appListFailure = (error, types) => ({
    type: LIST_APP_DATA_FAILURE,
    payload: error,
    types: types
})


export const fetchAppUsers = (type, params) => {

    return async (dispatch) => {
        dispatch(appListRequest(type))
        try {
            const response = await Axios.get(`${p_app_users}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(appListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(appListFailure(error?.response, type))
            }
        }
    }
}

export const fetchAppAnnounce = (type, params) => {

    return async (dispatch) => {
        dispatch(appListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_annoucement_list}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(appListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(appListFailure(error?.response, type))
            }
        }
    }
}

export const fetchAppNotifications = (type, params) => {

    return async (dispatch) => {
        dispatch(appListRequest(type))
        try {
            const response = await Axios.get(`${p_custom_notification}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(appListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(appListFailure(error?.response, type))
            }
        }
    }
}


export const fetchTbSkinTestLogs = (type, params) => {

    return async (dispatch) => {
        dispatch(appListRequest(type))
        try {
            const response = await Axios.get(`${tb_skin_test_logs}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(appListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(appListFailure(error?.response, type))
            }
        }
    }
}