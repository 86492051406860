import React, {
    useEffect,
    useState
} from 'react'

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'

import { currentDate } from '../../utils/constants/formInitialStates'
import { StartDate } from '../../components/pharmacy/dispense/dispense'

import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors'
import { onhandleAddWorkShift } from '../../utils/validations/adminModuleValidations'

import * as Hooks from '../../hooks'

import Button from '../../components/common/button'

export const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(' ');
    let [hours, minutes] = timePart.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
}

const TextInput = ({ formData,
    setFormData,
    label,
    errorMessage,
    dVal,
    name = "" }) => {

    return (<div className="relative w-full">
        <input type={'time'} id={name} autoComplete="off"
            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
            name={name}
            value={formData[name]}
            className={`block ${errorMessage && 'border-red-500'} pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
            bg-white `}
            placeholder=""
        />
        <label htmlFor={name}
            className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                        origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                        peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
            {errorMessage ? '' : label}
        </label>
        {errorMessage && <p className='text-red-500 fs-12 rubik-400'>{errorMessage}</p>}
    </div>)
}

export const convertTimeStringToMinutes = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 60 + minutes + seconds / 60;
};

const addMinutesToTime = (time, minutes) => {
    const [hours, mins] = time.split(':').map(Number);
    const date = new Date(0, 0, 0, hours, mins);
    date.setMinutes(date.getMinutes() + minutes);
    const newHours = date.getHours().toString().padStart(2, '0');
    const newMinutes = date.getMinutes().toString().padStart(2, '0');
    return `${newHours}:${newMinutes}`;
};

export const AddTeamWorkShift = ({ setShowModal, showModal, logData, formdata, setShowToast, isEdit, teamlistData }) => {

    const dispatch = Hooks.useCustomDispatch()

    const [formData, setFormData] = useState({
        date: currentDate,
        checkInTime: '',
        checkOutTime: '',
        breakInTime: '',
        breakOutTime: '',
        breakTime: '',
        timeDifference: ''
    })

    const [errorMessage, setErrorMessages] = useState({
        date: '',
        checkInTime: '',
        checkOutTime: '',
        breakInTime: '',
        breakOutTime: '',
    })

    const { addTeamDataResponse } = useAdminSelectors()

    const handleAddWorkShift = () => {
        onhandleAddWorkShift(formData, setErrorMessages, errorMessage, dispatch, setShowToast, formdata, setShowModal, isEdit, logData)
    }

    useEffect(() => {
        if (isEdit) {
            const { checkin_time, checkout_time, breakin_time, breakout_time, date } = logData
            setFormData({
                date: date,
                checkInTime: checkin_time ? convertTo24HourFormat(checkin_time) : '',
                checkOutTime: checkout_time ? convertTo24HourFormat(checkout_time) : '',
                breakInTime: breakin_time ? convertTo24HourFormat(breakin_time) : '',
                breakOutTime: breakout_time ? convertTo24HourFormat(breakout_time) : '',
            });
        }
    }, [logData, isEdit])

    const handleBreakTimeChange = (e) => {
        const checked = e.target.checked;
        const breakSettingMinutes = parseInt(logData.break_setting, 10);
        const breakTimeDifference = convertTimeStringToMinutes(logData.break_time_diffrense);

        let newBreakOutTime = '';
        let newBreakInTime = '';

        if (checked) {
            if (formData.checkInTime) {
                newBreakInTime = addMinutesToTime(formData.checkInTime, breakTimeDifference);
                newBreakOutTime = addMinutesToTime(newBreakInTime, breakSettingMinutes);
            }
        }

        setFormData({
            ...formData,
            breakTime: checked,
            breakInTime: newBreakInTime,
            breakOutTime: newBreakOutTime
        });
    };

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" >
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-lg font-semibold text-gray-700 dark:text-white">
                                {isEdit ? `Edit Time For - ${teamlistData?.name}` : `Add Tracking Time for - ${logData?.name}`}
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'addWorkShift')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-6 md:p-5 space-y-4">
                            <form>
                                <div className='grid grid-cols-1'>
                                    <StartDate formData={formData} setFormData={setFormData} />
                                    {errorMessage?.date && <p className='text-red-500 fs-12 rubik-400'>{errorMessage.date}</p>}
                                </div>

                                <div className='grid grid-cols-2 mt-3 gap-4'>
                                    <div>
                                        <TextInput formData={formData} setFormData={setFormData} dVal={formData.checkInTime} errorMessage={errorMessage.checkInTime} name='checkInTime' label='CHECK IN TIME' textType='time' />
                                    </div>
                                    <div>
                                        <TextInput formData={formData} setFormData={setFormData} errorMessage={errorMessage.checkOutTime} name='checkOutTime' label='CHECK OUT TIME' textType='time' />
                                    </div>
                                </div>

                                {formData.checkInTime &&
                                    <div className='grid grid-cols-2 mt-3 gap-4'>
                                        <div className="flex items-center bg-gray-100 p-2 rounded-md w-44">
                                            <input id="link-checkbox"
                                                onChange={handleBreakTimeChange}
                                                // onChange={(e) => setFormData({ ...formData, breakTime: e.target.checked })} 
                                                type="checkbox" value="on" className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600            dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor="link-checkbox" className="ms-2 ml-2 fs-14 font-medium text-gray-900 dark:text-gray-300">
                                                {logData?.break_setting} min break.</label>
                                        </div>
                                    </div>}

                                <div className='grid grid-cols-2 mt-3 gap-4'>
                                    <div>
                                        <TextInput formData={formData} setFormData={setFormData} errorMessage={errorMessage.breakInTime} name='breakInTime' label='BREAK IN TIME' textType='time' />
                                    </div>
                                    <div>
                                        <TextInput formData={formData} setFormData={setFormData} errorMessage={errorMessage.breakOutTime} name='breakOutTime' label='BREAK OUT TIME' textType='time' />
                                    </div>
                                </div>

                                <div className='grid grid-cols-1 mt-3 gap-4'>
                                    <Button text={addTeamDataResponse.loading && (addTeamDataResponse.type === 'add_team_work_shift' || addTeamDataResponse.type === 'update_team_time_shift') ? 'Saving...' : 'Saving'} color='white' width='100%'
                                        handleFilter={() => addTeamDataResponse?.loading && (addTeamDataResponse.type === 'add_team_work_shift' || addTeamDataResponse.type === 'update_team_time_shift') ? null : handleAddWorkShift()} />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}