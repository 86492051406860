import React, {
    useEffect,
    useState
} from "react";

import {
    DropdownSelect,
    TextInput,
} from "../../../components/pharmacy/dispense/dispense";

import {
    DeleteARVModalPopUp,
    ARVSendFaxPopUp
} from "../../../models/pharmacy/deleteARV";

import { fetchARVList } from "../../../services/actions/pharmacy/arv/patientARVList";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import { initDropdowns } from "flowbite";
import { useARVSelectors } from "../../../services/selectors/arvSelectors";

import * as Common from "../../../components/common";
import * as Hooks from "../../../hooks";
import * as Icons from "../../../assets/svg/icons";
import * as Modals from "../../../assets/js/custome";
import * as routeNames from "../../../routes/routeName";

import ChatBox from "../../../components/chat/chatbox";

const arvListHeading = [
    { text: "PATIENT", classNames: ["p-3"] },
    { text: "PHN", classNames: ["p-3"] },
    { text: "DATE", classNames: ["p-3"] },
    { text: "DUE DATE", classNames: ["p-3"] },
    { text: "DRUG", classNames: ["p-3"] },
    { text: "FAX STATUS", classNames: ["p-3"] },
    { text: "ACTION", classNames: ["p-3"] },
];

const TableRowData = ({ value, className1 = "p-3" }) => {
    return (
        <td  className={className1}>
            <p>{value}</p>
        </td>
    );
};

const ArvList = () => {

    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();
    const dispatch = Hooks.useCustomDispatch();

    const { arvListListResponse } = useARVSelectors();

    const arvLIST = arvListListResponse?.data?.data;

    const initialPage = parseInt(new URLSearchParams(location.search).get("page")) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage);
    const [patientProfileData, setPatientProfileData] = useState(null);
    const [formData, setFormData] = useState({ search: "", type: "" });
    const searchParams = new URLSearchParams();
    const [showModal, setShowModal] = React.useState({ DeleteARVModal: false, SendFaxModal: false });

    const [arvID, setarvID] = useState(null);
    const [patientType, setpatientType] = useState(null);

    useEffect(() => {
        setPageNumber(initialPage);
    }, [initialPage]);

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: 25,
        };

        dispatch(fetchARVList("arv_list", params));
        return () => { };
    }, []);

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: 25,
        };

        if (formData.type) params.type = formData.type;
        if (formData.search) params.search = formData.search;

        if (value !== undefined) {
            searchParams.append("page", value);
            setPageNumber(value);
        }

        dispatch(fetchARVList("arv_list", params));
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
    };

    const handleResetFilteredData = () => {
        const searchParams = new URLSearchParams();
        setFormData({ ...formData, search: '', type: 'all' })
        const newUrl = `${routeNames.ARV_LIST}?${searchParams.toString()}`;
        dispatch(fetchARVList("arv_list", searchParams));
        navigate(newUrl);
    }


    useEffect(() => {
        initDropdowns();
    }, [arvLIST?.data]);

    return (
        <>
            <div className="BodyBgColor">
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div
                                        className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left"
                                    >
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="col-span-12 flex">
                                            <div className="flex gap-2">
                                                <TextInput
                                                    label="Search"
                                                    name="search"
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    height="48px"
                                                />
                                                <DropdownSelect
                                                    label="Validity Status"
                                                    name="type"
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    options={[
                                                        { value: "all", label: "All" },
                                                        { value: "expiring", label: "Expiring" },
                                                        { value: "expired", label: "Expired" },
                                                    ]}
                                                />

                                                <button
                                                    type="button"
                                                    style={{ backgroundColor: "#3B82F6", width: "100px" }}
                                                    onClick={handlePagination}
                                                    className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 	focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                                                >
                                                    Filter
                                                </button>

                                                <button onClick={handleResetFilteredData} type="button" className=" ml-auto  h-full  py-4 items-center justify-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                    Reset
                                                </button>

                                            </div>
                                        </div>

                                        {arvListListResponse?.loading &&
                                            arvListListResponse?.type === "arv_list" ? (
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div>
                                        ) : arvListListResponse?.error !== null &&
                                            arvListListResponse?.type === "arv_list" ? (
                                            <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                <h1 className="text-4xl font-bold text-red-600 mb-2">
                                                    Oops!
                                                </h1>
                                                <p className="text-lg text-gray-800 mb-2">
                                                    Something went wrong while fetching the data.
                                                </p>
                                                <button
                                                    onClick={() => window.location.reload()}
                                                    className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500"
                                                >
                                                    Please Try Again.
                                                </button>
                                            </div>
                                        ) : arvLIST?.data?.length === 0 &&
                                            arvListListResponse?.type === "arv_list" ? (
                                            <div className="bg-white rounded-md">
                                                <p className="text-gray-500">
                                                    ARV lists are not available.
                                                </p>
                                            </div>
                                        ) : arvListListResponse?.type === "arv_list" ? (
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                <div className="shadow-md border rounded-lg  relative">
                                                    <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                        <thead
                                                            className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900"
                                                            style={{
                                                                borderBottom: "1px solid lightgray",
                                                                background: "#f6f4f4",
                                                            }}
                                                        >
                                                            <tr>
                                                                {arvListHeading?.map((header, index) => (
                                                                    <th
                                                                        key={index}
                                                                        scope="col"
                                                                        className={header?.classNames?.join(" ")}
                                                                    >
                                                                        <p className="rubik-500 fs-14">
                                                                            {header?.text}
                                                                        </p>
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {arvLIST?.data?.map((value, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    <tr
                                                                        style={{
                                                                            borderBottom: `${"1px solid lightgray"}`,
                                                                        }}
                                                                    >
                                                                        <TableRowData
                                                                            value={value.patient_name}
                                                                        />
                                                                        <TableRowData value={value.phn} />
                                                                        <TableRowData value={value.date} />
                                                                        <TableRowData value={value.due_date} />
                                                                        <TableRowData value={value.drug} />
                                                                        <TableRowData value={value.fax_status} />

                                                                        <td  className="p-3">
                                                                            <button
                                                                                id={`dropdownDefaultButton_${idx}`}
                                                                                data-dropdown-toggle={`dropdown_${idx}`}
                                                                                className="inline-flex items-center text-white p-2 text-sm font-medium text-center text-gray-900 	bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                                                                type="button"
                                                                            >
                                                                                <Icons.EditIcon />
                                                                            </button>

                                                                            <div
                                                                                id={`dropdown_${idx}`}
                                                                                className="z-10 relative border border-gray-100 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                                                                            >
                                                                                <ul
                                                                                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                                                                                    aria-labelledby={`dropdownDefaultButton_${idx}`}
                                                                                >
                                                                                    <li
                                                                                        onClick={() => {
                                                                                            return (setarvID(value.id), setpatientType(value.patient_type),
                                                                                                Modals.handleShowModal(
                                                                                                    setShowModal,
                                                                                                    showModal,
                                                                                                    "SendFaxModal"
                                                                                                ))
                                                                                        }}
                                                                                    >
                                                                                        <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                            <span className="pl-2">
                                                                                                Send Fax
                                                                                            </span>
                                                                                        </a>
                                                                                    </li>

                                                                                    <li
                                                                                        onClick={() => {
                                                                                            return (
                                                                                                setarvID(value.id),
                                                                                                Modals.handleShowModal(
                                                                                                    setShowModal,
                                                                                                    showModal,
                                                                                                    "DeleteARVModal"
                                                                                                )
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                            <span className="pl-2">
                                                                                                Delete
                                                                                            </span>
                                                                                        </a>
                                                                                    </li>

                                                                                    <li
                                                                                        onClick={() => {
                                                                                            return setPatientProfileData(
                                                                                                value
                                                                                            );
                                                                                        }}
                                                                                        className="btnnn"
                                                                                    ></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    {arvListListResponse?.error !== null ? null : (
                                                        <>
                                                            {arvLIST.data?.length !== 0 && (
                                                                <>
                                                                    {arvLIST.pagination?.last_page !== 1 && (
                                                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                            <nav aria-label="Page navigation example">
                                                                                <ul className="inline-flex -space-x-px text-base h-10">
                                                                                    <CustomPagination
                                                                                        count={
                                                                                            arvLIST.pagination?.last_page
                                                                                        }
                                                                                        page={pageNumber}
                                                                                        onChange={(event, value) => {
                                                                                            handlePagination(event, value);
                                                                                        }}
                                                                                    />
                                                                                </ul>
                                                                            </nav>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteARVModalPopUp
                showModal={showModal}
                arvID={arvID}
                onClose={() =>
                    Modals.handleHideModal(setShowModal, showModal, "DeleteARVModal")
                }
                setShowModal={setShowModal}
                formData={formData}
                pageNumber={pageNumber}
            />
            <ARVSendFaxPopUp
                patientType={patientType}
                arvID={arvID}
                onClose={() =>
                    Modals.handleHideModal(setShowModal, showModal, "SendFaxModal")
                }
                setShowModal={setShowModal}
                showModal={showModal}
            />
            <ChatBox />

            <Common.Footer />

        </>
    );
};

export default ArvList;
