import React, { useState } from 'react'

import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'

import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { FetchPrescriptionsList } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { currentDate } from '../../../utils/constants/formInitialStates'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'
import * as Dispense from '../../../components/pharmacy/dispense/dispense'

import ChatBox from '../../../components/chat/chatbox'

const PrescriptionsList = () => {

    const location = Hooks.useCustomLocation()
    const dispatch = Hooks.useCustomDispatch()

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    const [pageNumber, setPageNumber] = React.useState(initialPage)
    const [formData, setFormData] = useState({ date: currentDate })

    const { dispenseListDataResponse } = useDispenseSelectors()
    const billingDrugList = dispenseListDataResponse?.data?.data

    React.useEffect(() => {
        setPageNumber(initialPage)
    }, [initialPage])

    React.useEffect(() => {
        if (pageNumber && formData?.date) {
            const params = {
                page: pageNumber,
                searchdate: formData?.date,
            }
            dispatch(FetchPrescriptionsList(params, 'prescripitions_list'))
        }
        return () => { }
    }, [])

    const handleFilterData = () => {
        const params = {
            searchdate: formData?.date,
        }
        if (formData.date) dispatch(FetchPrescriptionsList(params, 'prescripitions_list'))
    }

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
													sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">
                                          
                                            <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 w-full'>
                                                <Dispense.StartDate formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex-0 lg:w-1/12 md:w-2/12 sm:w-4/12 w-full'>
                                                <button style={{ backgroundColor: '#3282F6' }} type="button" onClick={handleFilterData}
                                                    className="flex text-white ml-auto w-full h-full pl-6 py-4 items-center justify-center border border-gray-300 	
														focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 
														dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                                                </button>
                                            </div>
                                        </div>

                                        {dispenseListDataResponse?.loading && dispenseListDataResponse?.type === 'prescripitions_list' ?

                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :

                                            dispenseListDataResponse?.error !== null && dispenseListDataResponse?.type === 'prescripitions_list' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4  bg-red-600 text-white rounded hover:bg-red-700 
																	focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :

                                                billingDrugList?.data?.length === 0 ?
                                                    <div className="bg-white pt-4 rounded-md">
                                                        <p className="text-gray-500">No Record Available.</p>
                                                    </div>
                                                    :

                                                    dispenseListDataResponse?.type === 'prescripitions_list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-2">
                                                            <div className="relative overflow-x-auto">
                                                                <div className='flex'>
                                                                    {billingDrugList?.data?.map((value, index) => {
                                                                        return (<React.Fragment key={index}>
                                                                            <div className='flex p-3 m-3 bg-gray-200 rounded-md'>
                                                                                <img src={value.image} alt={`image-${index}`} className='w-44' />
                                                                            </div>
                                                                        </React.Fragment>)
                                                                    })}
                                                                </div>

                                                                {
                                                                    dispenseListDataResponse?.error !== null ? null : <>
                                                                        {billingDrugList?.data?.length !== 0 && <>
                                                                            {billingDrugList?.pagination?.last_page !== 1 &&
                                                                                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end 
                                                                                sm:justify-center md:justify-center justify-center mt-5">
                                                                                    <nav aria-label="Page navigation example">
                                                                                        <ul className="inline-flex -space-x-px text-base h-10">
                                                                                            <CustomPagination
                                                                                                count={billingDrugList?.pagination?.last_page}
                                                                                                page={pageNumber}
                                                                                                onChange={(event, value) => {
                                                                                                    const params = {
                                                                                                        page: value,
                                                                                                    }
                                                                                                    dispatch(FetchPrescriptionsList(params, 'prescripitions_list'))
                                                                                                }}
                                                                                            />
                                                                                        </ul>
                                                                                    </nav>
                                                                                </div>
                                                                            }
                                                                        </>}
                                                                    </>
                                                                }

                                                            </div>
                                                        </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />

            <Common.Footer />

            {/* <div id='overlay' style={{ zIndex: '9999999999' }}>
                <span id="close-btn" onClick={closeOverlay}>&times;</span>
                {overlayImage &&
                    <img src={overlayImage} className="rounded-full" alt="Large Image" />
                }
            </div> */}
        </>

    )
}

export default PrescriptionsList