import React, {
    useEffect,
    useState
} from "react";

import {
    ErrorToast,
    SuccessToastMessage
} from "../../../components/loaders/toastMessage";

import {
    PrintGenratedPdfView,
    UpdateAllGeneratedFDAList
} from "../../../services/actions/FDA/addFDAformData";

import { TextInput } from "../../../components/pharmacy/dispense/dispense";
import { Loader } from "../../../components/common/loadingView";

import {
    fetchFDAExpired_ExpiringList,
    fetchFrequestDispensingAuthList
} from "../../../services/actions/FDA/fdaFetch";

import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import { initDropdowns } from "flowbite";
import { useFDASelectors } from "../../../services/selectors/fdaSelector";

import { useRevenueSelector } from "../../../services/selectors/revenueSelectors";
import { DeleteDailyRevenueModal } from "../../../models/revenue/dailyRevenue";

import { UpdatePrescribers } from "../../../models/pharmacy/updateFDAFormsPrescribers";
import { currentDate } from "../../../utils/constants/formInitialStates";
import { utils, writeFile } from 'xlsx';

import * as Common from "../../../components/common";
import * as Hooks from "../../../hooks";
import * as Icons from "../../../assets/svg/icons";
import * as Modals from "../../../assets/js/custome";
import * as routeNames from "../../../routes/routeName";
import * as pathname from '../../../networking/urlEndPoints'

import ChatBox from "../../../components/chat/chatbox";

const fdaListHeading = [
    { text: "DATE", classNames: ["p-3"] },
    { text: "EXPIRY DATE", classNames: ["p-3"] },
    { text: "PATIENT NAME", classNames: ["p-3"] },
    { text: "PHN", classNames: ["p-3"] },
    { text: "DEDUCTIBLE", classNames: ["p-3"] },
    { text: "NOTE", classNames: ["p-3"] },
    { text: "DOCTOR", classNames: ["p-3"] },
    { text: "", classNames: ["p-3"] },
];

const TableRowData = ({ value, className1 = "p-3" }) => {
    return (
        <td  className={className1}>
            <p>{value}</p>
        </td>
    );
};

export const exportToExcel = (data, fileName) => {
    const formattedData = data?.map(value => ({
        'DATE': value.date,
        'EXPIRY DATE': value.expiry_date,
        'PATIENT NAME': value.patient_name,
        'PHN': value.phn,
        'DEDUCTIBLE': value.deductible,
        'NOTE': value.note,
        'DOCTOR': value?.prescriber?.join(', '),
    }));

    const worksheet = utils.json_to_sheet(formattedData);

    worksheet['!cols'] = [
        { wch: 15 }, // 'DATE'
        { wch: 15 }, // 'EXPIRY DATE'
        { wch: 20 }, // 'PATIENT NAME'
        { wch: 15 }, // 'PHN'
        { wch: 15 }, // 'DEDUCTIBLE'
        { wch: 30 }, // 'NOTE'
        { wch: 20 }  // 'DOCTOR'
    ];

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${fileName}.xlsx`);
};

const ALLGeneratedFDAformList = () => {

    const { addRevenueDataResponse } = useRevenueSelector()

    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();
    const dispatch = Hooks.useCustomDispatch();

    const { fdaListResponse } = useFDASelectors();

    const fdaList = fdaListResponse?.data?.data;

    const searchParams = new URLSearchParams();
    const initialPage = parseInt(new URLSearchParams(location.search).get("page")) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage);
    const [formData, setFormData] = useState({ search: "", type: "", date: currentDate });

    const [showModal, setShowModal] = useState({ deleteRevenueList: false, updatePrescriberList: false });
    const [showToast, setShowToast] = useState({ successToast: '', errorToast: '', });

    const [fdaID, setfdaID] = useState(null);
    const [logData, setLogData] = useState(null)

    const expiredlist = location.pathname === routeNames.pharmacy_expired_frequent_dispense
    const expiringList = location.pathname === routeNames.pharmacy_expiring_frequent_dispense

    useEffect(() => {
        setPageNumber(initialPage);
    }, [initialPage]);

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: 10,
            fda_type: expiredlist ? 'expired' : ''
        };

        if (expiredlist) {
            dispatch(fetchFDAExpired_ExpiringList("all_generated_fda_form_list", params, pathname.pfrequent_expired_dispensing));
        } else if (expiringList) {
            dispatch(fetchFDAExpired_ExpiringList("all_generated_fda_form_list", params, pathname.pfrequent_expired_dispensing));
        } else {

            const params = {
                page: pageNumber,
                no_of_records: 10,
            };
            dispatch(fetchFrequestDispensingAuthList("all_generated_fda_form_list", params));
        }
        return () => { };
    }, []);

    const handlePagination = (event, value) => {

        const params = {
            page: pageNumber,
            no_of_records: 10,
            fda_type: expiredlist ? 'expired' : ''
        };

        if (formData.search) params.search = formData.search;

        if (value !== undefined) {
            searchParams.append("page", value);

            setPageNumber(value);

            if (expiredlist) {
                dispatch(fetchFDAExpired_ExpiringList("all_generated_fda_form_list", params, pathname.pfrequent_expired_dispensing));
            }

            else if (expiringList) {
                dispatch(fetchFDAExpired_ExpiringList("all_generated_fda_form_list", params, pathname.pfrequent_expired_dispensing));
            }

            else {
                dispatch(fetchFrequestDispensingAuthList("all_generated_fda_form_list", params))
            }

            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }
        
        else {

            if (expiredlist && formData.search) {
                dispatch(fetchFDAExpired_ExpiringList("all_generated_fda_form_list", params, pathname.pfrequent_expired_dispensing));
            }

            else if (expiringList && formData.search) {
                dispatch(fetchFDAExpired_ExpiringList("all_generated_fda_form_list", params, pathname.pfrequent_expired_dispensing));
            }

            else if (formData.search) {
                dispatch(fetchFrequestDispensingAuthList("all_generated_fda_form_list", params))
            }
        }
    }

    const handleResetFilteredData = () => {
        const searchParams = new URLSearchParams();
        setFormData({ ...formData, search: '', type: 'all' })
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        const params = {
            page: pageNumber,
            no_of_records: 10,
            fda_type: expiredlist ? 'expired' : ''
        };

        if (pageNumber >= 1 && expiredlist) {
            dispatch(fetchFDAExpired_ExpiringList("all_generated_fda_form_list", params, pathname.pfrequent_expired_dispensing));
        } else if (pageNumber >= 1 && expiringList) {
            dispatch(fetchFDAExpired_ExpiringList("all_generated_fda_form_list", params, pathname.pfrequent_expired_dispensing));
        } else {
            if (pageNumber >= 1 || formData.search) dispatch(fetchFrequestDispensingAuthList("all_generated_fda_form_list", params))
        }

        navigate(newUrl);
    }

    useEffect(() => {
        initDropdowns();
    }, [fdaList?.data]);

    return (
        <>
            <div className="BodyBgColor">
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div
                                        className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">

                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-4 bg-white p-4">
                                        <div className="col-span-12 flex" style={{ height: '42px' }}>
                                            <div className="flex gap-2">
                                                <TextInput
                                                    label="Search"
                                                    name="search"
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    height="42px"
                                                />

                                                <button
                                                    type="button"
                                                    style={{ backgroundColor: "#3B82F6", width: "100px", height: '42px' }}
                                                    onClick={handlePagination}
                                                    className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 	focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                                                >
                                                    Filter
                                                </button>

                                                <button type="button" onClick={() => exportToExcel(fdaList?.data, 'Frequent-Dispensing')}
                                                    className="flex  w-64 h-full items-center justify-center text-white bg-red-500
																border border-gray-300 focus:outline-none hover:bg-red-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-gray-800 ">Export Sheet
                                                </button>

                                                <button onClick={handleResetFilteredData} type="button" className="h-full  py-4 items-center justify-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                    Reset
                                                </button>

                                            </div>
                                        </div>

                                        {fdaListResponse?.loading &&
                                            fdaListResponse?.type === "all_generated_fda_form_list" ? (
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div>
                                        ) : fdaListResponse?.error !== null &&
                                            fdaListResponse?.type === "all_generated_fda_form_list" ? (
                                            <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                <h1 className="text-4xl font-bold text-red-600 mb-2">
                                                    Oops!
                                                </h1>
                                                <p className="text-lg text-gray-800 mb-2">
                                                    Something went wrong while fetching the data.
                                                </p>
                                                <button
                                                    onClick={() => window.location.reload()}
                                                    className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500"
                                                >
                                                    Please Try Again.
                                                </button>
                                            </div>
                                        ) : fdaList?.data?.length === 0 &&
                                            fdaListResponse?.type === "all_generated_fda_form_list" ? (
                                            <div className="bg-white rounded-md">
                                                <p className="text-gray-500 mt-2">
                                                    No Records are Available.
                                                </p>
                                            </div>
                                        ) : fdaListResponse?.type === "all_generated_fda_form_list" ? (
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                <div className="shadow-md border rounded-lg  relative">
                                                    <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                        <thead
                                                            className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900"
                                                            style={{
                                                                borderBottom: "1px solid lightgray",
                                                                background: "#f6f4f4",
                                                            }}
                                                        >
                                                            <tr>
                                                                {fdaListHeading?.map((header, index) => (
                                                                    <th
                                                                        key={index}
                                                                        scope="col"
                                                                        className={header?.classNames?.join(" ")}
                                                                    >
                                                                        <p className="rubik-500 fs-14">
                                                                            {header?.text}
                                                                        </p>
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {fdaList?.data?.map((value, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    <tr style={{ borderBottom: `${"1px solid lightgray"}` }}>
                                                                        <TableRowData value={value.date} />

                                                                        <td  className="p-3">
                                                                            <p>{value.expiry_date}</p>
                                                                            {value?.has_expired && <p className="text-red-500">Expired</p>}
                                                                        </td>

                                                                        <TableRowData value={value.patient_name} />
                                                                        <TableRowData value={value.phn} />

                                                                        <td  className="p-3">
                                                                            <input type="text" autoComplete="off"

                                                                                defaultValue={value.deductible}
                                                                                onChange={(e) => setTimeout(() => {
                                                                                    dispatch(UpdateAllGeneratedFDAList('add_deductible', setShowToast, value.id, 'deductible', e.target.value))
                                                                                }, 1000)}
                                                                                style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', width: '100px' }}
                                                                                className={`block pl-2 px-2.5 pb-0.5 pt-4 w-full text-sm text-gray-900 bg-transparent border-b border-gray-300 
                                                                                                appearance-none 
                                                                                                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                                                                bg-white `}
                                                                                placeholder=""
                                                                            />
                                                                        </td>

                                                                        <td  className="p-3">
                                                                            <textarea type="text" autoComplete="off"
                                                                                defaultValue={value.note}
                                                                                onChange={(e) => setTimeout(() => {
                                                                                    dispatch(UpdateAllGeneratedFDAList('add_note', setShowToast, value.id, 'note', e.target.value))
                                                                                }, 1000)}

                                                                                // style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', width: '100px' }}
                                                                                className={`block pl-2 px-2.5 pb-0.5 -mt-1 w-full text-sm text-gray-900 bg-transparent border-b border-gray-300 
                                                                                                appearance-none 
                                                                                                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                                                                bg-white `}
                                                                                placeholder=""
                                                                            />
                                                                        </td>

                                                                        <td  className="p-3 w-56">
                                                                            <p className='flex gap-2'>  {value?.prescriber?.map((value, index) => <span className="fs-12 break-all" key={index}>{value},</span>)}
                                                                                <span onClick={() => { return setLogData(value), Modals.handleShowModal(setShowModal, showModal, 'updatePrescriberList') }}><Icons.EditIcons /></span></p>
                                                                        </td>

                                                                        <td  className="p-3 flex gap-4 items-center justify-center">
                                                                            <button style={{ height: "20px", width: '60px' }}
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    return setfdaID(value.id), dispatch(PrintGenratedPdfView('view_pdf', value.id, setShowToast))
                                                                                }}
                                                                                className="sm:text-center md:text-start 
												 			                                lg:text-start rubik-400 fs-10 text-white rounded-md text-sm px-1 bg-red-500 py-1 flex justify-center items-center">

                                                                                {((addRevenueDataResponse?.loading) && (addRevenueDataResponse?.type === 'view_pdf') && (fdaID === value.id)) ? (
                                                                                    <><Loader /></>) : ("View PDF")}
                                                                            </button>

                                                                            <div className='bg-red-100 p-1 rounded-md btnnn' onClick={() => { return setLogData(value), Modals.handleShowModal(setShowModal, showModal, 'deleteRevenueList') }}>
                                                                                <Icons.DeleteIcon color="red" />
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    {fdaListResponse?.error !== null ? null : (
                                                        <>
                                                            {fdaList?.data?.length !== 0 && (
                                                                <>
                                                                    {fdaList?.pagination?.last_page !== 1 && (
                                                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                            <nav aria-label="Page navigation example">
                                                                                <ul className="inline-flex -space-x-px text-base h-10">
                                                                                    <CustomPagination
                                                                                        count={
                                                                                            fdaList?.pagination?.last_page
                                                                                        }
                                                                                        page={pageNumber}
                                                                                        onChange={(event, value) => {
                                                                                            handlePagination(event, value);
                                                                                        }}
                                                                                    />
                                                                                </ul>
                                                                            </nav>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <ChatBox />
            <Common.Footer />

            {showModal.deleteRevenueList &&
                <DeleteDailyRevenueModal
                    text={'Are you sure you want to Delete ?'}
                    logData={logData}
                    pageNumber={pageNumber}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast}
                />
            }

            {showModal.updatePrescriberList &&
                <UpdatePrescribers
                    text={'Are you sure you want to Delete ?'}
                    logData={logData}
                    pageNumber={pageNumber}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast}
                    setFormData={setFormData}
                    formData={formData}
                />
            }



            {showToast.errorToast && (addRevenueDataResponse?.type === 'view_pdf') && (<ErrorToast status={"Something went wrong when generating the PDF"} />)}

            {showToast.successToast && ((addRevenueDataResponse.type === 'add_deductible') || (addRevenueDataResponse.type === 'add_note')) &&
                <SuccessToastMessage status={'Updated Sucessfully.'} />}

            {showToast.errorToast && ((addRevenueDataResponse?.type === 'add_deductible') || (addRevenueDataResponse?.type === 'add_note')) &&
                <ErrorToast status={'Oops! Got an issue while updating.please try to update again'} />}

            {showToast.errorToast && addRevenueDataResponse?.type === 'delete_deposit_info' && (<ErrorToast status={"Something went wrong when Deleting the log."} />)}

            {showToast.errorToast && addRevenueDataResponse?.type === 'update_prescriber' && (<ErrorToast status={"Something went wrong when updating prescriber.please try to update again."} />)}
            {showToast.successToast && addRevenueDataResponse?.type === 'update_prescriber' && <SuccessToastMessage status={'Updated Sucessfully.'} />}

        </>
    );
};

export default ALLGeneratedFDAformList;
