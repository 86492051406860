import React, {
    useState,
    useRef,
    useEffect
} from 'react'

import {
    DeleteIcon,
    EditIcons,
    ModalCloseIcon
} from '../../../assets/svg/icons';

import {
    PharmacyAddNewPharmacistSignatures,
    PharmacyDeletePharmacistSignatures,
    PharmacyPharmacistSignatureIgnored,
    PharmacyUpdatePharmacistSignatures
} from '../../../services/actions/pharmacy/dispense/addDispenseData';

import {
    ErrorToastMessage,
    SuccessToastMessage
} from '../../../components/loaders/toastMessage';

import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors';
import { FetchViewAllPharmacistSignatures } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData';
import { LoadingView } from '../../../components/common';
import { removeWorkSpaceTab } from '../../../utils/helper/helper';

import * as Hooks from '../../../hooks'
import * as routeNames from '../../../routes/routeName'
import SignatureCanvas from 'react-signature-canvas';

const DeletePharmacistSignature = ({ setShowModal, showModal, viewSignatureDetails, setShowToast }) => {

    const dispatch = Hooks.useCustomDispatch()
    const { addDispenseDataResponse } = useDispenseSelectors();

    const handleDeleteSignature = () => {
        if (viewSignatureDetails?.id) {
            dispatch(PharmacyDeletePharmacistSignatures(viewSignatureDetails?.id, 'delete-pharmacist-signature', viewSignatureDetails, setShowToast, setShowModal))
        }
    }

    return (
        <>
            <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={() => setShowModal(() => ({ ...showModal, deleteSignatureModal: false }))}
                        type="button"
                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              				text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <ModalCloseIcon />
                    </button>
                    <div className="p-6 text-center pt-12">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this signature?</h3>
                        <button
                            onClick={handleDeleteSignature}
                            style={{ backgroundColor: '#3A52BB' }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 
                    			dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            {addDispenseDataResponse?.loading ? 'Deleting...' : 'Yes'}
                        </button>
                        <button onClick={() => setShowModal(() => ({ ...showModal, deleteSignatureModal: false }))} type="button"
                            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                				border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                				dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
};

const EditPharmacistSignature = ({ setShowModal, showModal, viewSignatureDetails, setShowToast }) => {

    const dispatch = Hooks.useCustomDispatch()
    const [pharmacistSignature, setPharmacistSignature] = useState(null);

    const { addDispenseDataResponse } = useDispenseSelectors();
    const sigRef = useRef();

    const handlePharmacistSignatureEnd = () => {
        const signatureDataURL = sigRef?.current?.toDataURL();
        if (signatureDataURL) {
            setPharmacistSignature(signatureDataURL);
        }
    }

    const handleClearSignature = () => {
        sigRef?.current?.clear();
        setPharmacistSignature(null);
    }

    useEffect(() => {
        if (viewSignatureDetails) {
            sigRef?.current?.fromDataURL(viewSignatureDetails?.signature_img)
        }
    }, [viewSignatureDetails])

    const handleUpdateSignature = () => {
        if (viewSignatureDetails?.id && pharmacistSignature) {
            const requestedData = {
                signature_pad: pharmacistSignature,
                id: viewSignatureDetails?.id
            }
            dispatch(PharmacyUpdatePharmacistSignatures('update-pharmacist-signature', requestedData, setShowToast, setShowModal))
        }
    }

    return (
        <>
            <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Signature {viewSignatureDetails?.id}
                        </h3>
                        <button onClick={() => setShowModal(() => ({ ...showModal, editSignatureModal: false }))}
                            type="button"
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              				text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                        </button>
                    </div>

                    <div className="w-full relative  rounded-md p-6">
                        <SignatureCanvas
                            penColor="black"
                            clearOnResize={false}
                            canvasProps={{ className: 'pharmacist-signature' }}
                            ref={sigRef}
                            onEnd={handlePharmacistSignatureEnd}
                        />
                        <button onClick={handleClearSignature}
                            className="text-xs  bg-yellow-500 font-medium text-white mt-2 rounded-md px-3 py-1 flex items-center gap-2 uppercase">
                            Clear
                        </button>
                    </div>

                    <div className="pl-6 pb-6 flex gap-2">

                        <button onClick={() => setShowModal(() => ({ ...showModal, editSignatureModal: false }))} type="button"
                            className="text-gray-500 bg-white hover:bg-gray-100 focus:none shadow-none focus:ring-0 focus:outline-none  rounded-lg border 
                				border-gray-200 text-sm font-medium px-5 py-2.5">Cancel</button>
                        <button
                            onClick={handleUpdateSignature}
                            type="button" className="text-white bg-green-500 focus:none shadow-none focus:ring-0 focus:outline-none
                    			 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            {addDispenseDataResponse.loading && addDispenseDataResponse.type === 'update-pharmacist-signature' ? 'Updating...' : 'Update'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const AddNewPharmacistSignatures = () => {

    const dispatch = Hooks.useCustomDispatch()
    const navigate = Hooks.useCustomNavigate()

    const canvasRefs = useRef([])
    const { addDispenseDataResponse, dispenseListDataResponse } = useDispenseSelectors()

    const [showSign, setShowSign] = useState(false);
    const [showToast, setShowToast] = useState({ successToast: false, errorToast: false });
    const [modal, setModal] = useState({ editSignatureModal: false, deleteSignatureModal: false })
    const [viewSignatureDetails, setViewSignatureDetails] = useState(null)

    const [patientSignature, setPatientSignature] = useState([null]);
    const [activeTab, setActiveTab] = useState('add_signatures');
    const viewSignatureList = dispenseListDataResponse?.data?.data

    const handleClearPatientSignature = (index) => {
        const canvasRef = canvasRefs.current[index];
        if (canvasRef) {
            if (!showSign) {
                canvasRef.clear();
                const newSignatures = [...patientSignature];
                newSignatures[index] = null;
                setPatientSignature(newSignatures);
            }
        }
    };

    const handleAddPatientSignature = () => {
        setPatientSignature(prevSignatures => [...prevSignatures, null]);
    };

    const handlePatientSignatureEnd = (index) => {
        const signatureDataURL = canvasRefs.current[index]?.toDataURL();
        const newSignatures = [...patientSignature];
        newSignatures[index] = signatureDataURL;
        setPatientSignature(newSignatures);
        setShowSign(false);
    };


    const handleRemovePatientSignature = (index) => {
        const newSignatures = patientSignature?.filter((_, i) => i !== index);
        setPatientSignature(newSignatures);

        const canvasRef = canvasRefs.current[index];
        if (canvasRef) {
            canvasRef.clear();
        }

        canvasRefs.current = canvasRefs?.current.filter((_, i) => i !== index);

        canvasRefs?.current?.forEach((ref, i) => {
            if (i === index && ref) {
                ref.clear();
            } else {
                if (newSignatures && ref) {
                    ref.fromDataURL(newSignatures);
                }
            }
        });
    };

    useEffect(() => {
        setTimeout(() => {
            if (patientSignature) {
                patientSignature?.forEach((signature, index) => {
                    if (signature) {
                        const canvasRef = canvasRefs.current[index];
                        if (canvasRef) {
                            canvasRef.fromDataURL(signature);
                        }
                    }
                });
            }
        }, 0);
    }, [patientSignature])

    const handleSavePharmacistSignature = () => {
        const requestedData = {
            signature_pad: patientSignature
        }
        dispatch(PharmacyAddNewPharmacistSignatures('add-pharmacist-signature', requestedData, setShowToast))
    }


    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`${routeNames.pharmacy_pharmacist_signature_updation}?active=${tab}`);
        if (tab === 'view_signatures') {
            dispatch(FetchViewAllPharmacistSignatures('view-pharmacist-signature'))
            setPatientSignature([null])
        }
    };

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const activeParam = params.get('active');

        if (activeParam === 'view_signatures') {
            setActiveTab('view_signatures');
            dispatch(FetchViewAllPharmacistSignatures('view-pharmacist-signature'))
        }
        return () => { }
    }, []);


    const handleIgnoreChange = (checked, id) => {
        const newValue = checked ? 1 : 0;
        dispatch(PharmacyPharmacistSignatureIgnored('ignore-pharmacist-signature', { is_ignore: newValue, id: id }, setShowToast))
    }

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">

                            <div className="container mt-2">
                                <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                    <div className="flex justify-end items-end">
                                        <button onClick={() => { return removeWorkSpaceTab(), navigate(routeNames.DASHBOARD_ROUTE) }} className={`bg-yellow-500 px-8 py-1 text-white rounded-md border-b-1`}
                                            type="button">
                                            Back
                                        </button>
                                    </div>

                                    <div className="mb-4 border-b border-gray-200 dark:border-gray-700 flex mt-2">
                                        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="filter-tab" role="tablist">
                                            <li className="me-2" role="presentation">
                                                <button className={`inline-block border-b-1 rounded-t-sm p-4 ${activeTab === 'add_signatures' ? 'bg-gray-100' : ''}`}
                                                    id="filter-row-tab" onClick={() => handleTabChange('add_signatures')}
                                                    type="button" role="tab" aria-controls="filter-row"
                                                    aria-selected={activeTab === 'add_signatures' ? 'true' : 'false'}>
                                                    Add Signatures
                                                </button>
                                            </li>

                                            <li className="me-2" role="presentation">
                                                <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'view_signatures' ? 'bg-gray-100' : ''}`}
                                                    id="export-row-tab" onClick={() => {
                                                        return handleTabChange('view_signatures')

                                                    }}
                                                    type="button" role="tab" aria-controls="export-row"
                                                    aria-selected={activeTab === 'view_signatures' ? 'true' : 'false'}>
                                                    View Signatures
                                                </button>
                                            </li>
                                        </ul>
                                    </div>


                                    <div id="filter-tab-content">
                                        <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'add_signatures' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">
                                            {activeTab === 'add_signatures' && <>
                                                <div className="flex flex-wrap gap-y-3">
                                                    <div>
                                                        <div className='flex gap-2'>
                                                            <button
                                                                type='button'
                                                                onClick={handleAddPatientSignature}
                                                                className="text-md  font-medium mt-3 bg-blue-500 text-white rounded-md px-3 py-1 flex items-center gap-2">
                                                                Add Signature
                                                            </button>

                                                            {patientSignature?.some(signature => signature !== null) && (
                                                                <button
                                                                    type='button'
                                                                    onClick={handleSavePharmacistSignature}
                                                                    className="text-md  font-medium mt-3 bg-green-500 text-white rounded-md px-3 py-1 flex items-center gap-2">
                                                                    {addDispenseDataResponse.loading && addDispenseDataResponse.type === 'add-pharmacist-signature' ? 'Saving...' : 'Save Signature'}
                                                                </button>
                                                            )}
                                                        </div>

                                                        <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4 mt-4'>
                                                            {patientSignature?.map((signature, index) => (
                                                                <div key={index} >
                                                                    <div className="rounded-md">
                                                                        <div>
                                                                            <SignatureCanvas
                                                                                penColor="black"
                                                                                clearOnResize={false}
                                                                                canvasProps={{ className: 'pharmacist-signature' }}
                                                                                ref={(ref) => (canvasRefs.current[index] = ref)}
                                                                                onEnd={() => handlePatientSignatureEnd(index)}
                                                                            />
                                                                            <div className='flex items-center gap-2 mt-2'>
                                                                                <button
                                                                                    type='button'
                                                                                    onClick={() => handleClearPatientSignature(index)}
                                                                                    className="text-xs font-medium bg-yellow-500  text-white rounded-md px-3 py-1 flex items-center gap-2 uppercase">
                                                                                    Clear
                                                                                </button>
                                                                                {index !== 0 &&
                                                                                    patientSignature?.length > 1 && (
                                                                                        <button
                                                                                            type='button'
                                                                                            onClick={() => handleRemovePatientSignature(index)}
                                                                                            className="text-xs text-white font-medium px-3 bg-red-500 rounded-md p-1 flex items-center gap-2 uppercase">
                                                                                            Remove
                                                                                        </button>
                                                                                    )}

                                                                                {index === patientSignature?.length - 1 && patientSignature[index] && (
                                                                                    <button
                                                                                        type='button'
                                                                                        onClick={handleAddPatientSignature}
                                                                                        className="text-xs text-white font-medium px-3 bg-blue-500 rounded-md p-1 flex items-center gap-2 uppercase">
                                                                                        Add More
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>

                                        <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'view_signatures' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">

                                            {activeTab === 'view_signatures' && <>
                                                {dispenseListDataResponse?.loading && dispenseListDataResponse.type === 'view-pharmacist-signature' ?
                                                    <div className='flex items-center justify-center mt-60 mb-80'>
                                                        <LoadingView />
                                                    </div>
                                                    :
                                                    viewSignatureList?.data?.length > 0 ?
                                                        <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4'>
                                                            {viewSignatureList?.data?.map((signature, index) => (
                                                                <div key={index} className='border border-gray-200 rounded-md p-2'>
                                                                    <div className='border border-gray-100 flex justify-between rounded-md p-2'>
                                                                        <div className="flex btnnnn">
                                                                            <input
                                                                                defaultChecked={signature.ignore_sign === 1}
                                                                                onChange={(e) => handleIgnoreChange(e.target.checked, signature.id)}
                                                                                id={`checked-checkbox-${index}`} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                            <label htmlFor={`checked-checkbox-${index}`} className="ms-2 text-sm font-medium text-gray-500 ml-1">Ignore</label>
                                                                        </div>

                                                                        <div>
                                                                            <img src={signature.signature_img} alt={`Signature ${index}`} />
                                                                        </div>
                                                                        {!signature.exist_sign &&
                                                                            <div className='flex gap-1' onClick={() => setViewSignatureDetails(signature)}>

                                                                                <span className='cursor-pointer' onClick={() => setModal({ ...modal, editSignatureModal: true })}>
                                                                                    <EditIcons color='red' />
                                                                                </span>
                                                                                <span className='cursor-pointer' onClick={() => setModal({ ...modal, deleteSignatureModal: true })}>
                                                                                    <DeleteIcon color='blue' />
                                                                                </span>
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        viewSignatureList?.data?.length === 0 ?
                                                            <div className='flex'>
                                                                <p className='text-gray-500'>No signatures found</p>
                                                            </div>
                                                            : null
                                                }
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modal.deleteSignatureModal && <DeletePharmacistSignature setShowModal={setModal} showModal={modal} viewSignatureDetails={viewSignatureDetails} setShowToast={setShowToast} />}
            {modal.editSignatureModal && <EditPharmacistSignature setShowModal={setModal} showModal={modal} viewSignatureDetails={viewSignatureDetails} setShowToast={setShowToast} />}

            {showToast.successToast && addDispenseDataResponse.type === 'add-pharmacist-signature' && <SuccessToastMessage status='Signature added successfully' />}
            {showToast.errorToast && addDispenseDataResponse.type === 'add-pharmacist-signature' && <ErrorToastMessage status='Oops! got an issue while adding signature please try again.' />}

            {showToast.successToast && addDispenseDataResponse.type === 'update-pharmacist-signature' && <SuccessToastMessage status='Signature updated successfully' />}
            {showToast.errorToast && addDispenseDataResponse.type === 'update-pharmacist-signature' && <ErrorToastMessage status='Oops! got an issue while updating signature please try again.' />}

            {showToast.successToast && addDispenseDataResponse.type === 'delete-pharmacist-signature' && <SuccessToastMessage status='Signature deleted successfully' />}
            {showToast.errorToast && addDispenseDataResponse.type === 'delete-pharmacist-signature' && <ErrorToastMessage status='Oops! got an issue while deleting signature please try again.' />}
            {showToast.successToast && addDispenseDataResponse.type === 'ignore-pharmacist-signature' && <SuccessToastMessage status='Updated successfully' />}
        </>
    )
}

export default AddNewPharmacistSignatures