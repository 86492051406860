import { pharmacy_get_chat_message } from "../../../networking/urlEndPoints";
import Axios from '../../../networking/intraceptor'
import { fetchAllRecentChats } from "./recentChatsListsActions";

export async function getAllChatMessages(id, setChatMessageList, setChatMessages, playMessageSound, profileData, dispatch) {
	try {
		const response = await Axios.post(`${pharmacy_get_chat_message}/${id}`);
		if (response?.status === 200) {
			setChatMessageList(prevChatMessages => [...prevChatMessages, response?.data?.data]);
			setChatMessages([])
			dispatch(fetchAllRecentChats())
		}
	} catch (error) {
		if (error?.response) {
		}
	}
}
