import React from "react";
import { RotatingLines } from "react-loader-spinner";

export const LoadingView = () => {
  return (
    <RotatingLines
      strokeColor="#3A52BB"
      strokeWidth="5"
      animationDuration="1.75"
      width="66"
      visible={true}
    />
  );
};

export const ProfilePicLoader = ({ color = "black" }) => {
  return (
    <RotatingLines
      strokeColor={color}
      strokeWidth="5"
      animationDuration="1.75"
      width="20"
      visible={true}
    />
  );
};

export const Loader = () => {
  return (
    <RotatingLines
      strokeColor="white"
      strokeWidth="5"
      animationDuration="1.75"
      width="20"
      visible={true}
    />
  );
};
