import { useSelector } from 'react-redux'

export const useRevenueSelector = () => {

    const RevenueListResponse = useSelector(state => state.RevenueList)
    const addRevenueDataResponse = useSelector(state => state.addRevenueData)
    
    return {
        RevenueListResponse,
        addRevenueDataResponse
    }
}
