import {
	POST_COMMENTS_LISTS_REQUEST,
	POST_COMMENTS_LISTS_SUCCESS,
	POST_COMMENTS_LISTS_FAILURE
} from "../../constants";

const initialState = {
	commentList: [],
	isLoading: false,
	error: null,
};

const userCommentListsReducer = (state = initialState, action) => {
	switch (action.type) {
		case POST_COMMENTS_LISTS_REQUEST:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case POST_COMMENTS_LISTS_SUCCESS:
			return {
				...state,
				commentList: action.payload,
				isLoading: false,
				error: null,
			};

		case POST_COMMENTS_LISTS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				commentList: []
			};

		default:
			return state;
	}
};

export default userCommentListsReducer;