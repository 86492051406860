import * as routesNames from '../routes/routeName'

export const FormPlaceHolder = 'Search all forms'
export const PatientsDataPlaceHolder = 'Search Patients'

export const PateintData = [{
	id: 1,
	name: 'Add Patients',
	route: routesNames.ADD_PATIENTS
}, {
	id: 2,
	name: 'Active Patients',
	route: `${routesNames.ALL_PATIENTS}?status=1`
},
{
	id: 3,
	name: 'Inactive Patients',
	route: `${routesNames.ALL_PATIENTS}?status=0`
},
{
	id: 4,
	name: 'Route',
	route: routesNames.pharmacy_patient_route
},
]

export const FormsData = [{
	id: 1,
	name: 'Pharmacy Information Update Notification for Clinics',
	route: routesNames.PHARMACY_INFORMATION_UPDATE_FORM
}, {
	id: 2,
	name: 'Rx Transfer Request',
	route: routesNames.PHARMACY_PRESCRIPTION_TRASNSFER_FORM
}, {
	id: 12,
	name: 'Request Copy of Original Rx for OAT/DD',
	route: routesNames.pharmacy_original_prescription_form
}, {
	id: 3,
	name: 'Dr Notification for Emergency Supply',
	route: routesNames.pharmacy_Emergency_Supply_OAT_medications_form
}, {
	id: 4,
	name: 'Anti-Viral Request Form',
	route: routesNames.pharmacy_medication_form
}, {
	id: 5,
	name: 'Missing Rx Requirements',
	route: routesNames.missing_Rx_Requirements
}, {
	id: 6,
	name: 'Emergency Supply for Continuity of Care',
	route: routesNames.pharmacy_emergency_supply_continuity_form
}, {
	id: 7,
	name: 'Patient Agreement For Take Home Dosing',
	route: routesNames.patient_agreement_for_take_home_dosing
}, {
	id: 8,
	name: 'Plan G Form',
	route: routesNames.pharmacy_Plan_G_Form
}, {
	id: 9,
	name: 'Plan Z Form',
	route: routesNames.pharmacy_Plan_Z_Form
}, {
	id: 10,
	name: 'Doctor Communication',
	route: routesNames.pharmacy_doctor_communication_form
},
{
	id: 11,
	name: 'Pharmacist Prescriber Communication.',
	route: routesNames.pharmacy_Pharmacist_Prescriber_Communication
},
{
	id: 12,
	name: 'Doctor Note',
	route: routesNames.pharmacy_doctor_note_form
}, {
	id: 13,
	name: 'TB Skin Test (Mantoux Test) Report Form',
	route: routesNames.pharmacy_tb_skin_test_mantoux_form
},
{
	id: 14,
	name: 'TB Skin Test Logs',
	route: routesNames.pharmacy_tb_skin_test_logs
},
]

export const topHeaderSearch = [{
	id: 1,
	name: 'Patient',
}, {
	id: 2,
	name: 'Clinic',
},
{
	id: 4,
	name: 'Pharmacy',
}]

export const menuItems = [
	{
		id: 1,
		name: 'PATIENTS',
		route: `${routesNames.ALL_PATIENTS}?status=1`,
		PermissionStatus: 'Patient Management',
	},
	{
		id: 2,
		name: 'FORMS',
		route: routesNames.PHARMACY_INFORMATION_UPDATE_FORM,
		PermissionStatus: 'Custom Forms',
	},
	{
		id: 5,
		name: 'COMPOUND',
		PermissionStatus: 'Compounds',
		route: routesNames.pharmacy_order_compounding_form
	},
	{
		id: 6,
		name: 'DISPENSE',
		PermissionStatus: 'Daily Dispense',
		route: routesNames.pharmacy_archived_deliveries
	}, {
		id: 7,
		name: 'BLISTERS',
		PermissionStatus: 'Blister',
		route: routesNames.pharmacy_blister_patients
	},
	{
		id: 8,
		name: 'VIALS',
		PermissionStatus: 'VIALS',
		route: routesNames.pharmacy_patient_vials_pack
	},
	{
		id: 9,
		name: 'SPECIALITY MEDICATION',
		PermissionStatus: 'Speciality Medication',
		route: routesNames.pharmacy_speciality_pack
	},
	{
		id: 10,
		name: 'INVOICE',
		PermissionStatus: 'INVOICE',
		route: routesNames.pharmacy_generate_invoice
	},
	{
		id: 11,
		name: 'ARV',
		PermissionStatus: 'ARV',
		route: routesNames.ADD_ARV
	},
	{
		id: 12,
		name: 'REVENUE',
		PermissionStatus: 'Revinue',
		route: routesNames.pharmacy_daily_revenue
	},
	{
		id: 13,
		name: 'FDA FORM',
		PermissionStatus: "FDA's",
		route: routesNames.create_new_fda_form
	},
	{
		id: 14,
		name: 'INVENTORY',
		PermissionStatus: "INVENTORY",
		route: routesNames.pharmacy_inventory_list
	},
	{
		id: 15,
		name: 'TRAVEL',
		PermissionStatus: "TRAVEL",
		route: routesNames.pharmacy_add_travel_pack
	},
	{
		id: 15,
		name: 'APP',
		PermissionStatus: 'APP',
		route: routesNames.pharmacy_order_transfer
	},
	{
		id: 15,
		name: 'TEAM',
		PermissionStatus: 'TEAM',
		route: routesNames.pharmacy_order_transfer
	}
]

export const DashboardItems = [
	{
		id: 1,
		name: 'TEAM INTERACTIONS',
		route: routesNames.DASHBOARD_ROUTE
	},
	{
		id: 2,
		name: 'TEAM NOTES',
		// route: routesNames.PHARMACY_INFORMATION_UPDATE_FORM
	},
	// {
	// 	id: 4,
	// 	name: 'FILE MANAGER	',
	// 	route: routesNames.pharmacy_file_manager
	// },
	// {
	// 	id: 5,
	// 	name: 'BOOKMARKS',
	// 	// route: routesNames.FDA_ROUTE
	// },
	{
		id: 5,
		name: 'REMINDER NOTIFICATIONS',
		route: routesNames.pharmacy_reminder_notes
	}
]

export const SettingItems = [{
	id: 2,
	name: 'FRONT PROFILE',
	route: routesNames.pharmacy_front_profile
}, {
	id: 3,
	name: 'MANAGE PROFILE',
	route: routesNames.SETTINGS_ROUTE
},
{
	id: 5,
	name: 'SENT FAX LOGS',
	route: routesNames.pharmacy_fax_log
},
{
	id: 7,
	name: 'Break/PAYROll Settings',
	route: routesNames.pharmacy_break_parroll_settings
}
	, {
	id: 8,
	name: 'WINRX',
	route: routesNames.pharmacy_settings
}, {
	id: 9,
	name: 'STAT Holidays',
	route: routesNames.pharmacy_holidays_calander
}]

export const compoundItems = [
	{
		id: 1,
		name: 'Order Compound',
		route: routesNames.pharmacy_order_compounding_form,
		PermissionStatus: "Compounds",
	}, {
		id: 2,
		name: 'PRICE LIST',
		route: routesNames.pharmacy_compounds,
		PermissionStatus: "Compound Price List",
	}, {
		id: 3,
		name: 'Compound Order Form',
		route: routesNames.pharmacy_order_compound_form,
		PermissionStatus: "Compounds",
	}, {
		id: 4,
		name: 'Controlled Substances Order Request Form',
		route: routesNames.pharmacy_controlled_order_request,
		PermissionStatus: "Compounds",
	}, {
		id: 5,
		name: 'Compounding Agreement',
		route: routesNames.pharmacy_compound_agreement_form,
		PermissionStatus: "Compounds",
	}]

export const DispenseItems = [
	// {
	// 	id: 1,
	// 	name: 'Delivery',
	// 	route: routesNames.pharmacy_route_delivery,
	// },
	{
		id: 2,
		name: 'Delivery Drivers',
		route: routesNames.pharmacy_driver,
	},
	// {
	// 	id: 3,
	// 	name: 'Delivery Supervisor',
	// 	route: routesNames.pharmacy_deliveries_supervisor,
	// },
	// {
	// 	id: 4,
	// 	name: 'Daily Dispense',
	// 	route: routesNames.pharmacy_delivery,
	// },
	// {
	// 	id: 5,
	// 	name: 'Missed Dispense',
	// 	route: routesNames.pharmacy_missed_deliveries,
	// },
	// {
	// 	id: 6,
	// 	name: 'Add New Custom Delivery',
	// 	route: routesNames.pharmacy_create_manual_delivery,
	// }, {
	// 	id: 6,
	// 	name: 'Recurrence Deliveries',
	// 	route: routesNames.pharmacy_recurrence_delivery,
	// },
	{
		id: 8,
		name: 'Archived Deliveries',
		route: routesNames.pharmacy_archived_deliveries,
	},
	{
		id: 7,
		name: 'Labels',
		route: routesNames.pharmacy_delivery_lables,
	}, {
		id: 8,
		name: 'Counselling',
		route: routesNames.pharmacy_patient_counselling,
	},
	{
		id: 9,
		name: 'Expiring Rx',
		route: routesNames.pharmacy_expiring_rx
	},
	{
		id: 10,
		name: 'Consent Submissions',
		route: routesNames.pharmacy_consent_form_logs
	},
	{
		id: 11,
		name: 'Billing',
		route: routesNames.pharmacy_drugs_billing
	}, {
		id: 12,
		name: 'Prescriptions',
		route: routesNames.pharmacy_oat_prescriptions
	},
	{
		id: 13,
		name: 'Memo',
		route: routesNames.pharmacy_memo
	},
	// {
	// 	id: 14,
	// 	name: 'uploaded Prescription',
	// 	route: routesNames.upload_prescription
	// },
	{
		id: 15,
		name: 'Ongoing Prescriptions',
		route: routesNames.pharmacy_ongoing_prescritiptions
	},
	{
		id: 16,
		name: 'Returned Fentanyl Patches',
		route: routesNames.pharmacy_returned_patches
	},
	{
		id: 17,
		name: 'RU/RR/RE Deliveries ',
		route: routesNames.pharmacy_reversed_deliveries
	}, {
		id: 18,
		name: 'Transfer Consents',
		route: routesNames.pharmacy_tranfer_patient_concent
	},
	{
		id: 19,
		name: 'Sig Check - AI Alpha',
		route: routesNames.pharmacy_sig_check_ai
	},
	{
		id: 20,
		name: 'Accountability',
		route: routesNames.pharmacy_accountability
	}
]

export const BlistersItems = [
	{
		id: 1,
		name: 'Add Blister Pack',
		route: routesNames.pharmacy_blister_patients
	}, {
		id: 2,
		name: 'Blister Packs',
		route: routesNames.pharmacy_blister_packs
	},
	{
		id: 3,
		name: 'Blister Daily Packs',
		route: routesNames.pharmacy_blister_daily_packs
	},
	{
		id: 4,
		name: 'Blister Packs on Shelf',
		route: routesNames.pharmacy_blister_pack_on_shelf
	}]

export const AddVialsItems = [
	{
		id: 1,
		name: 'Add Vials Pack',
		route: routesNames.pharmacy_patient_vials_pack
	},
	{
		id: 2,
		name: 'Vials Packs',
		route: routesNames.pharmacy_vials_packs
	},
	{
		id: 3,
		name: 'Daily Vials',
		route: routesNames.pharmacy_dailyvials_packs
	},
]

export const MedicationItems = [
	{
		id: 1,
		name: 'Add Speciality Medication Pack',
		route: routesNames.pharmacy_speciality_pack
	},
	{
		id: 2,
		name: 'Speciality Medication Pack',
		route: routesNames.pharmacy_speciality_medication_packs
	},
	{
		id: 3,
		name: 'Daily Speciality Medication',
		route: routesNames.pharmacy_dailymedication_packs
	},
]

export const InvoiceItems = [
	{
		id: 1,
		name: 'Generate Invoice',
		route: routesNames.pharmacy_generate_invoice
	}, {
		id: 2,
		name: 'Previous Invoice',
		route: routesNames.pharmacy_previous_invoices
	}
]

export const ARVItems = [
	{
		id: 1,
		name: 'Add ARV',
		route: routesNames.ADD_ARV
	},
	{
		id: 2,
		name: 'All ARV',
		route: routesNames.ARV_LIST
	}
]

export const RevenueItems = [
	{
		id: 1,
		name: 'DAILY REVENUE',
		route: routesNames.pharmacy_daily_revenue
	},
	{
		id: 2,
		name: 'CASH CHANGE',
		route: routesNames.pharmacy_cash_change
	},
	{
		id: 3,
		name: 'Deposit Info',
		route: routesNames.pharmacy_deposit_info
	}, {
		id: 4,
		name: 'In Hand Cash',
		route: routesNames.pharmacy_hand_cash
	}
]

export const FdaFormsItems = [
	{
		id: 1,
		name: 'Create New FDA',
		route: routesNames.create_new_fda_form
	},
	{
		id: 2,
		name: "All Generated FDA'S",
		route: routesNames.all_generated_fda_form_list
	},
	{
		id: 3,
		name: "EXPIRING FDA'S",
		route: routesNames.pharmacy_expiring_frequent_dispense
	},
	{
		id: 4,
		name: "EXPIRED FDA'S",
		route: routesNames.pharmacy_expired_frequent_dispense
	}
]

export const InventoryItems = [
	{
		id: 1,
		name: 'Inventory',
		route: routesNames.pharmacy_inventory_list
	}, {
		id: 2,
		name: 'Total Reversed',
		route: routesNames.pharmacy_total_reversed
	}
]

export const AddTravelItems = [
	{
		id: 1,
		name: 'TRAVEL PATIENTS',
		route: routesNames.pharmacy_add_travel_pack
	}, {
		id: 2,
		name: 'TRAVEL LOGS',
		route: routesNames.pharmacy_travel_logs
	}, {
		id: 3,
		name: 'BILLING DUE',
		route: routesNames.pharmacy_billing_due
	}, {
		id: 4,
		name: 'RETURNING PATIENTS',
		route: routesNames.pharmacy_travel_returning_patients
	}
]

export const AppItems = [
	{
		id: 1,
		name: 'Transfer Prescription',
		route: routesNames.pharmacy_order_transfer
	},
	{
		id: 2,
		name: 'New Prescription',
		route: routesNames.pharmacy_order_new
	},
	{
		id: 3,
		name: 'App Users',
		route: routesNames.pharmacy_app_user_list
	},
	{
		id: 4,
		name: 'App Announcements',
		route: routesNames.pharmacy_app_announcements
	},
	{
		id: 5,
		name: 'Custom Notification',
		route: routesNames.pharmacy_app_notification_list
	}
]

export const TeamItems = [{
	id: 1,
	name: 'TIME OFF REQUEST',
	route: routesNames.pharmacy_timeoff
}, {
	id: 2,
	name: 'CREATE NEW TASK',
	route: routesNames.pharmacy_create_new_task
},
{
	id: 3,
	name: 'ALL TASKS',
	route: routesNames.pharmacy_tasks
},
{
	id: 4,
	name: 'TEAM TIMESHEET',
	route: routesNames.pharmacy_team_attendance
},
{
	id: 5,
	name: 'CERTIFICATE TEMPLATE',
	route: routesNames.pharmacy_certificate_template
}
	,
{

	id: 6,
	name: 'ASSIGN TEMPLATE',
	route: routesNames.pharmacy_assign_certificate
}, {

	id: 7,
	name: 'YOUR TEAM',
	route: routesNames.pharmacy_user
},
{
	id: 8,
	name: 'Checkin/Checkout Logs',
	route: routesNames.pharmacy_check_out_in,
},
{
	id: 9,
	name: 'DEPARTMENTS',
	route: routesNames.pharmacy_department
},
{
	id: 10,
	name: 'Team Schedule',
	route: routesNames.pharmacy_team_avalibility
}
]