import React, { useEffect, useState } from "react";

import {
    BreadCrumb,
    LoadingView,
    Sidebar
} from "../../../components/common";

import {
    ErrorToastMessage,
    SuccessToastMessage
} from "../../../components/loaders/toastMessage";

import {
    AddNewUser,
    NoPharmacyDepartMentsFoundModal,
    SendOnBoardingRequest,
    UpdatePasscode,
    UpdateTeamProfile,
    UpdateUserPassword
} from "../../../models/admin/manageTeamList";

import { fetchPharmacyTeamsList } from "../../../services/actions/admin/team/fetchTeamsList";
import { useAdminSelectors } from "../../../services/selectors/adminModuleSelectors";

import { ErrorMessages } from "../../../components/error/alertMessages";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import { useCustomSelectors } from "../../../services/selectors/allSelectors";

import { handleShowModal } from "../../../assets/js/custome";
import { EditIcon } from "../../../assets/svg/icons";
import { initDropdowns } from "flowbite";
import { TextInput } from "../../../components/pharmacy/dispense/dispense";

import * as Hooks from '../../../hooks';

import ChatBox from "../../../components/chat/chatbox";
import Button from "../../../components/common/button";
import ThemeAvatar from "../../../components/common/avatar";

const usersListTableHeading = [
    { text: "USER", classNames: ['p-3'] },
    { text: "EMAIL", classNames: ['p-3'] },
    { text: "PERMISSIONS", classNames: ['p-3'] },
    { text: "DEPARTMENTS", classNames: ['p-3'] },
    { text: "LAST LOGIN", classNames: ['p-3'] },
    { text: "ACTION", classNames: ['p-3'] },
];

const ManageTeamList = () => {

    const searchParams = new URLSearchParams();

    const dispatch = Hooks.useCustomDispatch();
    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();

    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors()
    const { adminModule, profileData } = useCustomSelectors()

    const usersList = TeamListResponse?.data?.data

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [formData, setFormData] = useState({ taskStatus: '', assignTask: '', search: '' })

    const [showModal, setShowModal] = useState({ add_new_user: false, update_passcode: false, boarding: false })
    const [logData, setLogData] = useState(null)

    const [showToast, setShowToast] = useState({ successToast: '', errorToast: '' })
    const [apiCallMade, setApiCallMade] = useState(false)

    const [editLog, setEditLog] = useState(false)

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        if (!apiCallMade && adminModule === 1) {
            const params = {
                page: pageNumber
            }
            dispatch(fetchPharmacyTeamsList('manage_users_list', params))
            setApiCallMade(true)
        }
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
        };

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(fetchPharmacyTeamsList('manage_users_list', params))
    }

    const handleFilter = () => {
        const { search } = formData
        if (search) {
            const params = {
                search: search,
            }
            dispatch(fetchPharmacyTeamsList('manage_users_list', params))
        }
    }

    const handleReset = () => {
        setFormData({ ...formData, search: '' })
        dispatch(fetchPharmacyTeamsList('manage_users_list',))
    }

    useEffect(() => {
        initDropdowns()
    }, [usersList?.user])

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">

                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                        <div className="col-span-12 flex">
                                            <div className="flex w-full flex-wrap gap-y-3">

                                                <div className='w-44'>
                                                    <TextInput formData={formData} setFormData={setFormData} name='search' label='SEARCH' />
                                                </div>

                                                <div className='flex-0 ml-2 w-18'>
                                                    <Button text="Filter" color="white" handleFilter={handleFilter} />
                                                </div>

                                                <div className='flex-0 ml-2 w-24'>
                                                    <Button text="Reset" bgColor="white" color="gray" handleFilter={handleReset} />
                                                </div>

                                                <div className="ml-auto w-54">
                                                    <Button text=" + Add New User" color="white" handleFilter={() => { return setEditLog(false), handleShowModal(setShowModal, showModal, 'add_new_user') }} width="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mt-2">
                                            <div className="">

                                                {TeamListResponse?.loading && TeamListResponse.type === 'manage_users_list' ?
                                                    <div className="flex items-center justify-center  mt-60 mb-80">
                                                        <LoadingView />
                                                    </div>

                                                    : TeamListResponse?.error !== null && TeamListResponse.type === 'manage_users_list' ?
                                                        <div className="flex items-center justify-center mb-80">
                                                            <ErrorMessages />
                                                        </div>
                                                        : usersList?.tasks?.length === 0 && TeamListResponse.type === 'manage_users_list' ?
                                                            <h2 className="text-gray-500">
                                                                No Record Available.
                                                            </h2> :

                                                            TeamListResponse?.type === 'manage_users_list' ?

                                                                <div className="shadow-md border rounded-lg relative overflow-x-auto">
                                                                    <table className=" w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
                                                                        <thead className="text-xs text-white uppercase text-gray-900 dark:bg-gray-700 dark:text-gray-400" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                            <tr>
                                                                                {usersListTableHeading?.map((header, index) => (
                                                                                    <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                        <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                    </th>
                                                                                ))}
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            {usersList?.user?.map((value, index) => {
                                                                                return (<React.Fragment key={index}>
                                                                                    {profileData?.user_id === value.id ? null :

                                                                                        <tr className="bg-white dark:bg-gray-800 border-b dark:border-gray-700">
                                                                                            <td className='pt-3 pb-3'>
                                                                                                <div className='flex items-center'>

                                                                                                    {value.status === 1 ?
                                                                                                        <span style={{ marginTop: '16px', left: '44px' }} className="bottom-0 relative left-10 mt-2 profile-activity-indicator w-3 h-3 bg-green-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
                                                                                                        : value.status === 0 ?
                                                                                                            <span style={{ marginTop: '16px', left: '44px' }} className="bottom-0 relative left-10 mt-2 profile-activity-indicator w-3 h-3 bg-red-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
                                                                                                            : null}

                                                                                                    <ThemeAvatar name={value.name} size="40" fontSize={'12px'} image={value?.photo} />

                                                                                                    <div className="ml-2">
                                                                                                        <span className="rubik-500 block  whitespace-nowrap overflow-hidden text-ellipsis">
                                                                                                            {value.name.charAt(0).toUpperCase() + value.name.slice(1)}
                                                                                                        </span>
                                                                                                        <span className="rubik-500 block pl-2 pr-2 rounded-md bg-blue-900 text-white fs-10">
                                                                                                            {value?.type.charAt(0)?.toUpperCase() + value?.type?.slice(1)}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    
                                                                                                </div>
                                                                                            </td>

                                                                                            <td className='p-3'>
                                                                                                <p className="">{value.email}</p>
                                                                                            </td>

                                                                                            <td className='p-3'>
                                                                                                {value?.permissions?.length > 0 &&
                                                                                                    <div style={{ border: '1px solid white' }}
                                                                                                        className="items-center justify-center bg-gray-50 text-gray-400 -ml-8 pl-4 pr-4 fs-12  z-10 visible px-0 py-1 ml-0 text-sm font-medium text-white rounded-lg">
                                                                                                        {value?.permissions?.map((value, idx) => <React.Fragment key={idx}> {value.name} , </React.Fragment>)}
                                                                                                    </div>}
                                                                                            </td>

                                                                                            <td className='p-3'>
                                                                                                {value?.departments?.map((value, idx) => {
                                                                                                    return (<React.Fragment key={idx}>
                                                                                                        <p className={`bg-gray-50 pl-2 text-gray-500 rounded-md mb-2`}>{value?.name}</p>
                                                                                                    </React.Fragment>)
                                                                                                })}
                                                                                            </td>
                                                                                            <td className='p-3'>
                                                                                                <p>{value.last_login}</p>
                                                                                                <p>{value.last_login_ip}</p>
                                                                                            </td>

                                                                                            <td>
                                                                                                <button id={`dropdownDefaultButton_${index}`} data-dropdown-toggle={`dropdown_${index}`} className="inline-flex items-center text-white p-2 text-sm font-medium text-center text-gray-900 	bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
                                                                                                    <EditIcon />
                                                                                                </button>

                                                                                                <div id={`dropdown_${index}`} className="z-10 relative border border-gray-100 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                                                                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby={`dropdownDefaultButton_${index}`}>

                                                                                                        <li className='btnnn' onClick={() => { return setLogData(value), setEditLog(true), handleShowModal(setShowModal, showToast, 'add_new_user') }}                                                                                                     >
                                                                                                            <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                                <span className="pl-2">View/Edit Details</span></a>
                                                                                                        </li>

                                                                                                        <li className='btnnn' onClick={() => { return setLogData(value), handleShowModal(setShowModal, showToast, 'update_passcode') }}                                                                                                     >
                                                                                                            <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                                <span className="pl-2">Update Passcode</span></a>
                                                                                                        </li>

                                                                                                        <li className='btnnn' onClick={() => { return setLogData(value), handleShowModal(setShowModal, showToast, 'update_password') }}                                                                                                     >
                                                                                                            <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                                <span className="pl-2">Update Password</span></a>
                                                                                                        </li>

                                                                                                        <li className='btnnn' onClick={() => { return setLogData(value), handleShowModal(setShowModal, showToast, 'boarding') }}                                                                                                     >
                                                                                                            <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                                <span className="pl-2">Send Onboarding Request</span></a>
                                                                                                        </li>

                                                                                                        <li className='btnnn' onClick={() => { return setLogData(value), handleShowModal(setShowModal, showToast, 'user_profile') }}                                                                                                     >
                                                                                                            <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                                <span className="pl-2">User Profile</span></a>
                                                                                                        </li>

                                                                                                    </ul>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>}
                                                                                </React.Fragment>)
                                                                            })}
                                                                        </tbody>
                                                                    </table>

                                                                    {TeamListResponse && TeamListResponse?.error !== null ? null : <>
                                                                        {usersList?.tasks?.length !== 0 && <>
                                                                            {usersList?.pagination?.last_page !== 1 &&
                                                                                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                    <nav aria-label="Page navigation example">
                                                                                        <ul className="inline-flex -space-x-px text-base h-10">
                                                                                            <CustomPagination
                                                                                                count={usersList?.pagination?.last_page}
                                                                                                page={pageNumber}
                                                                                                onChange={handlePagination}
                                                                                            />
                                                                                        </ul>
                                                                                    </nav>
                                                                                </div>
                                                                            }
                                                                        </>}
                                                                    </>}

                                                                </div> : null}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                usersList?.department_count === 0 ?
                    showModal.add_new_user &&
                    <NoPharmacyDepartMentsFoundModal
                        setShowModal={setShowModal}
                        setShowToast={setShowToast} />
                    :
                    showModal.add_new_user &&
                    <AddNewUser logData={logData}
                        setShowModal={setShowModal}
                        setShowToast={setShowToast}
                        editLog={editLog}
                        pageNumber={pageNumber}
                    />
            }

            {
                showModal.update_passcode &&
                <UpdatePasscode logData={logData}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast}
                    pageNumber={pageNumber} />
            }

            {
                showModal.update_password &&
                <UpdateUserPassword logData={logData}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast}
                    pageNumber={pageNumber} />
            }

            {
                showModal.boarding &&
                <SendOnBoardingRequest logData={logData}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast}
                    pageNumber={pageNumber} />
            }

            {
                showModal.user_profile &&
                <UpdateTeamProfile logData={logData}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast}
                    pageNumber={pageNumber} />
            }


            {showToast.successToast && addTeamDataResponse.type === 'add_new_user' && <SuccessToastMessage status={'New user added successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'add_new_user' && <ErrorToastMessage status={'Got an issue while adding new user.please try again.'} />}

            {showToast.successToast && addTeamDataResponse.type === 'update_passcode' && <SuccessToastMessage status={'Passcode updated successfully'} />}
            {showToast.successToast && addTeamDataResponse.type === 'update_password' && <SuccessToastMessage status={'Password updated successfully'} />}

            {showToast.successToast && addTeamDataResponse.type === 'Onboarding_request' && <SuccessToastMessage status={'Mail sent to user successfully'} />}
            {showToast.successToast && addTeamDataResponse.type === 'update_user_details' && <SuccessToastMessage status={'User updated successfully'} />}

            <ChatBox />

        </>
    )
}

export default ManageTeamList