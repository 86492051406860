import React, {
	useEffect,
	useState
} from 'react'

import {
	useCustomDispatch,
	useCustomLocation
} from '../../hooks';

import {
	ClinicIcon,
	ModalCloseIcon
} from '../../assets/svg/icons';

import { Errors } from '../../components/error/alertMessages';
import { linkPatientsToClinic } from '../../services/actions/patients/linkPatientsToClinic';
import { hideLinkClinicModal } from '../../assets/js/custome';

import { useCustomPatientSelectors } from '../../services/selectors/patientSelectors';
import { fetchAllClinics } from '../../services/actions/pharmacy/clinic/getClinics';
import { useCustomLoadingStates } from '../../hooks/customStates';

import Select from "react-select";

const LinkClinicModal = ({
	patientProfileData,
	selectedOptions,
	setSelectedOptions,
	showToast,
	setShowToast,
	setShowModal,
	showModal,
	paginationNumber,
	queryStatus,
	querySearch
}) => {

	const [clinicsData, setClinicsData] = useState([]);
	const { apiCallMade, setApiCallMade } = useCustomLoadingStates()

	const {
		savePatientsToClinicResponse,
		getAllClinicsResponse,
		getClinicsData
	} = useCustomPatientSelectors()

	const dispatch = useCustomDispatch()
	const location = useCustomLocation()

	const handleSelectChange = (selectedValues) => {
		setSelectedOptions(selectedValues)
	};

	useEffect(() => {
		const clinics = getAllClinicsResponse?.data?.data || [];
		const formattedClinics = clinics?.map((clinic) => ({
			label: clinic.name,
			value: clinic.id,
		}));

		setClinicsData(formattedClinics);
	}, [getClinicsData])

	const savePatientsClinicDetails = async () => {
		const selectedClinicIds = selectedOptions?.map(option => option.value)
		if (selectedClinicIds?.length !== 0 && patientProfileData?.id) {
			await dispatch(linkPatientsToClinic(patientProfileData?.id,
				selectedClinicIds,
				showToast,
				setShowToast,
				setShowModal,
				showModal,
				paginationNumber,
				location,
				queryStatus,
				querySearch
			))
		}
	}

	useEffect(() => {
		const defaultClinic = clinicsData?.filter(
			(clinic) => patientProfileData?.clinic_ids?.includes(clinic.value)
		);

		if (defaultClinic) {
			setSelectedOptions(defaultClinic)
		}
	}, [patientProfileData, clinicsData]);

	const handleGetClinicList = () => {
		if (!apiCallMade) {
			dispatch(fetchAllClinics())
			setApiCallMade(true)
		}
	}

	return (
		<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center 
		justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
			<div className="relative p-4 w-full max-w-2xl max-h-full">
				<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
					<div className="flex items-center justify-between p-4 md:p-5  rounded-t dark:border-gray-600">
						<button type="button" className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => hideLinkClinicModal(setShowModal, showModal)}>
							<ModalCloseIcon />
							<span className="sr-only">Close modal</span>
						</button>
					</div>

					<div className="p-4 md:p-5 space-y-4">
						<div className='flex items-center justify-center'>
							<ClinicIcon />
						</div>

						<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
							<div className=" items-center justify-center mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-12">
								<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18 text-center">Search Clinic</label>
								<div className="relative" onClick={handleGetClinicList}>
									<Select
										isMulti
										options={clinicsData}
										classNamePrefix="react-select"
										onChange={handleSelectChange}
										value={selectedOptions}
										isSearchable={true}
										className="basic-multi-select"
										placeholder='Select...'
										noOptionsMessage={() => getAllClinicsResponse.loading ? 'Loading...' : 'No options'}
									/>
								</div>

								{getAllClinicsResponse?.error !== null &&
									<div className='mt-2'>
										<Errors error='Something went wrong when fetching the clinics. Please try again or reload the page.' />
									</div>
								}

								<div className="flex items-center justify-center p-4 md:p-5  border-gray-200 rounded-b dark:border-gray-600">
									<button onClick={savePatientsClinicDetails} type="button"
										className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none 
									focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 
									dark:focus:ring-blue-800">
										{savePatientsToClinicResponse?.loading ? 'Saving...' : 'Save'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default LinkClinicModal