import {
	FETCH_WALL_POST_LIKES_LIST_REQUEST,
	FETCH_WALL_POST_LIKES_LIST_SUCCESS,
	FETCH_WALL_POST_LIKES_LIST_FAILURE,
} from "../../constants"

const initialState = {
	data: [],
	loading: false,
	error: null
}

const wallPostlikeListReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_WALL_POST_LIKES_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: null
			};
		case FETCH_WALL_POST_LIKES_LIST_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case FETCH_WALL_POST_LIKES_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: []
			};
		default:
			return state;
	}
}

export default wallPostlikeListReducer
