import React, {
    useState,
    useEffect,
    useRef
} from 'react'

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'
import { AssignTaskSelect } from '../../screens/admin/team/createNewtask'

import { useCustomSelectors } from '../../services/selectors/allSelectors'
import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors'
import { DropdownSelect } from '../../components/pharmacy/dispense/dispense'
import { onhandleValidateTaskMangerForm } from '../../utils/validations/adminModuleValidations'

import {
    HandleDeleteTeamTimeShift,
    handleAddComments,
    handleDeleteDepartment,
    handleDeleteTasksList,
    handleDeleteTeamScheduleShift
} from '../../services/actions/admin/team/addTeamData'

import { Errors } from '../../components/error/alertMessages'

import * as Hooks from '../../hooks'
import * as useEffectHelper from '../../utils/helper/useEffectsHelper'
import * as routeName from '../../routes/routeName'

import Button from '../../components/common/button'

export const UpdateTaskList = ({ setShowModal, showModal, logData, setShowToast, paginationNumber }) => {

    const dispatch = Hooks.useCustomDispatch()

    const [formData, setFormData] = useState({ title: '', descripition: '', taskStatus: 'pending', assignTask: '' })
    const [errorMessage, setErrorMessages] = useState({ title: '', descripition: '', assignTask: '' })

    const { chatUserList, profileData } = useCustomSelectors()
    const { addTeamDataResponse } = useAdminSelectors()

    const employeeList = chatUserList?.data?.data

    useEffect(() => {
        setFormData({
            ...formData, title: logData?.title,
            descripition: logData?.description,
            taskStatus: logData?.status,
            assignTask: logData?.login_user?.id
        })
    }, [logData])

    const handleValidateTaskMangerForm = () => {
        onhandleValidateTaskMangerForm(formData, setErrorMessages, errorMessage, dispatch, setShowToast, 'edit', logData?.id, setShowModal, paginationNumber)
    }

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" >
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Update
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'editTask')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-6 md:p-5 space-y-4">
                            <form>
                                <div className='grid grid-cols-3 gap-2'>
                                    <div className="relative w-full">
                                        <input type={'text'} id={'title'} autoComplete="off"
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                            name={'title'}
                                            value={formData.title}
                                            className={`block pl-4 ${errorMessage.title && 'border-red-500'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                                    dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                    bg-white `}
                                            placeholder=""
                                        />
                                        <label htmlFor={'title'}
                                            className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                                                    origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                                                    peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                            Title
                                        </label>
                                    </div>

                                    <DropdownSelect
                                        label=""
                                        name="taskStatus"
                                        formData={formData}
                                        setFormData={setFormData}
                                        options={[
                                            { value: "pending", label: "Pending" },
                                            { value: "ongoing", label: "Ongoing" },
                                            { value: "completed", label: "Completed" },
                                            { value: "priority", label: "Priority" },
                                            { value: "discussion", label: "Discussion" },
                                        ]}
                                    />

                                    <AssignTaskSelect
                                        formData={formData}
                                        setFormData={setFormData}
                                        errorMessage={errorMessage}
                                        employeeList={employeeList}
                                        profileData={profileData}
                                    />

                                </div>

                                <div className='grid grid-cols-1'>
                                    <div className='mt-2'>
                                        <textarea
                                            value={formData.descripition}
                                            style={{ border: errorMessage.descripition }}
                                            name='descripition'
                                            placeholder='Description'
                                            onChange={(e) => setFormData({ ...formData, descripition: e.target.value })}
                                            className={`fs-16 ${errorMessage.descripition && 'border-red-500'} w-full pl-2 h-96 rounded-md rubik-400 border border-gray-300 text-gray-700`}
                                            id="descripition"
                                        />
                                    </div>
                                </div>

                                <div className='mt-2'>
                                    <Button color='white' width='100%'
                                        text={addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_new_task' ? 'Updating...' : 'Update'}
                                        handleFilter={() => addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_new_task' ? null : handleValidateTaskMangerForm()} />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}


export const DeleteList = ({ setShowModal, showModal, logData, setShowToast, paginationNumber, text, formData, teamlistData }) => {

    const modalRef = useRef(null);

    useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'deleteTask', modalRef)

    const { addTeamDataResponse } = useAdminSelectors()

    const dispatch = Hooks.useCustomDispatch()
    const location = Hooks.useCustomLocation()


    const handleDelete = () => {

        if (location.pathname === routeName.pharmacy_tasks) {
            dispatch(handleDeleteTasksList('delete_task', setShowToast, logData?.id, setShowModal, paginationNumber))
        }

        else if (location.pathname === routeName.pharmacy_team_attendance) {
            const { startDate, endDate } = formData
            const reqData = {
                user_id: logData.user_id,
                record_id: logData.id
            }
            const params = {
                from_date: startDate,
                to_date: endDate,
            }
            dispatch(HandleDeleteTeamTimeShift('delete_task', setShowToast, setShowModal, params, reqData))
        }
    }

    return (
        <>
            <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div ref={modalRef} className="relative w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button onClick={() => handleHideModal(setShowModal, showModal, 'deleteTask')} type="button"
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              				text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                        </button>
                        <div className="p-6 text-center pt-12">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{text}</h3>
                            <button
                                onClick={handleDelete}
                                style={{ backgroundColor: '#3A52BB' }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 
                    			dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">

                                {addTeamDataResponse?.loading ? 'Deleting...' : 'Yes'}

                            </button>
                            <button onClick={() => handleHideModal(setShowModal, showModal, 'deleteModal')} type="button"
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                				border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                				dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export const AddCommentsSectionModal = ({ setShowToast, showToast, logData }) => {

    const { addTeamDataResponse } = useAdminSelectors()
    const dispatch = Hooks.useCustomDispatch()
    const commentsEndRef = useRef()

    const [commentsData, setCommentsData] = useState({ id: null, index: null, reply: '', loading: false })
    const [addCommentsData, setAddCommentsData] = useState([])

    const handleAddCommentsReply = () => {
        const reqdata = {
            message: commentsData.reply,
        }
        if (commentsData.reply) {
            dispatch(handleAddComments('update_coomments', setShowToast, reqdata, commentsData.id, setCommentsData, commentsEndRef))
        }
    }

    useEffect(() => {
        if (addTeamDataResponse?.data?.data) {
            setAddCommentsData(prevData => [...prevData, addTeamDataResponse?.data?.data]);
        }
    }, [addTeamDataResponse]);

    return (
        <>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" >
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <button onClick={() => { return handleHideModal(setShowToast, showToast, 'showModal'), setAddCommentsData([]) }} type="button"
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              				text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                        </button>
                        <div className="p-6  pt-12">

                            <div className='mt-2 scroll-box' style={{ maxHeight: '350px', overflowX: 'auto' }}>
                                {logData?.conversations?.map((data, idx) => {
                                    return (<React.Fragment key={idx}>
                                        <div className="mb-2 p-1 borderLeftBgGreen bg-white rounded-md pl-2">
                                            <p className="rubik-500 fs-14 text-gray-700">{`${data?.message}`}</p>
                                            <p className=""><span className='fs-10 text-gray-700 rubik-500'>Added by : </span><span className='fs-10 text-gray-700 rubik-500'>{data?.postby_user?.name}</span></p>
                                            <p className="rubik-400 fs-8">{data?.created_at}</p>
                                        </div>
                                    </React.Fragment>)
                                })}

                                {addCommentsData?.length > 0 && addCommentsData?.map((data, idx) => {
                                    return (<React.Fragment key={idx}>
                                        <div className="mb-2 p-1 borderLeftBgGreen bg-white rounded-md pl-2">
                                            <p className="rubik-500 fs-14 text-gray-700">{`${data?.message}`}</p>
                                            <p className=""><span className='fs-10 text-gray-700 rubik-500'>Added by : </span><span className='fs-10 text-gray-700 rubik-500'>{data?.postby_user?.name}</span></p>
                                            <p className="rubik-400 fs-8">{data?.created_at}</p>
                                        </div>
                                    </React.Fragment>)
                                })}
                            </div>

                            <div className="mb-2 mt-4">
                                <textarea value={commentsData?.reply}
                                    onChange={(e) => setCommentsData({ ...commentsData, reply: e.target.value, id: logData.id, loading: false })}
                                    className="scrollToTop rubik-400 fs-16 resize-none block p-2.5 w-full text-sm text-gray-900 bg-white  
											    border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 
												dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Type your reply here...">
                                </textarea>

                                <div className="p-2">
                                    {addTeamDataResponse?.error !== null && addTeamDataResponse.type === 'update_coomments' &&
                                        <Errors error='Comment not added please try again.' />
                                    }
                                    <button
                                        type="button" onClick={handleAddCommentsReply}
                                        className="rubik-500 text-white fs-14 blueBg-color rounded-md text-sm px-3 py-1.5 mr-1">
                                        {addTeamDataResponse.loading && addTeamDataResponse.type === 'update_coomments' ? 'Processing...' : 'Add Reply'}
                                    </button>
                                    <button onClick={() => { return handleHideModal(setShowToast, showToast, 'showModal'), setCommentsData({ ...commentsData, index: null, reply: '' }, setAddCommentsData([])) }}
                                        type="button"
                                        className="rubik-500 text-white fs-14 redBg-color rounded-md text-sm px-3 py-1.5">
                                        Cancel
                                    </button>
                                </div>
                            </div>

                            {/* <button onClick={() => handleHideModal(setShowToast, showToast, 'showModal')} type="button"
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                				border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                				dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel
                            </button> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const DeleteDepartment = ({ setShowModal, showModal, logData, setShowToast, text, setisLoaded ,startDate,endDate}) => {

    const modalRef = useRef(null);
    const location = Hooks.useCustomLocation()

    useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'deleteDept', modalRef)

    const { addTeamDataResponse } = useAdminSelectors()
    const dispatch = Hooks.useCustomDispatch()

    const handleDelete = () => {
        if (location.pathname === routeName.pharmacy_team_avalibility) {
            dispatch(handleDeleteTeamScheduleShift('delete_shift', setShowToast, setShowModal, logData.id,startDate,endDate))
        } else {
            dispatch(handleDeleteDepartment('deleteDept', setShowToast, setShowModal, logData, setisLoaded))
        }
    }

    return (
        <>
            <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div ref={modalRef} className="relative w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button onClick={() => handleHideModal(setShowModal, showModal, 'deleteDept')} type="button"
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              				text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                        </button>
                        <div className="p-6 text-center pt-12">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{text}</h3>
                            <button
                                onClick={handleDelete}
                                style={{ backgroundColor: '#3A52BB' }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 
                    			dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">

                                {addTeamDataResponse?.loading ? 'Deleting...' : 'Yes'}

                            </button>
                            <button onClick={() => handleHideModal(setShowModal, showModal, 'deleteModal')} type="button"
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                				border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                				dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};