import {
	UPDATE_PHARMACY_USER_PROFILE_REQUEST,
	UPDATE_PHARMACY_USER_PROFILE_SUCCESS,
	UPDATE_PHARMACY_USER_PROFILE_FAILURE
} from '../../constants/index';

const initialState = {
	profile: null,
	loading: false,
	error: null
};

const UpdatePharmacyUserProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_PHARMACY_USER_PROFILE_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				profile: null
			};
		case UPDATE_PHARMACY_USER_PROFILE_SUCCESS:
			return {
				...state,
				loading: false,
				profile: action.payload,
				error: null
			};
		case UPDATE_PHARMACY_USER_PROFILE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				profile: null
			};
		default:
			return state;
	}
};

export default UpdatePharmacyUserProfileReducer;
