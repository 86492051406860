import {
	EDIT_TIMEOFF_REQUEST,
	EDIT_TIMEOFF_REQUEST_SUCCESS,
	EDIT_TIMEOFF_REQUEST_FAILURE
} from '../../constants/index';

const initialState = {
	data: null,
	loading: false,
	error: null
};

const editTimeOffRequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case EDIT_TIMEOFF_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				data: null
			};
		case EDIT_TIMEOFF_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			};
		case EDIT_TIMEOFF_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null
			};
		default:
			return state;
	}
};

export default editTimeOffRequestReducer;
