import {
	FETCH_HOLIDAYS_REQUEST,
	FETCH_HOLIDAYS_SUCCESS,
	FETCH_HOLIDAYS_FAILURE,
} from '../../constants';

const initialState = {
	holidays: [],
	loading: false,
	error: null,
};

const holidayReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_HOLIDAYS_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};

		case FETCH_HOLIDAYS_SUCCESS:
			return {
				...state,
				holidays: action.payload,
				loading: false,
				error: null,
			};

		case FETCH_HOLIDAYS_FAILURE:
			return {
				...state,
				holidays: [],
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};

export default holidayReducer; 
