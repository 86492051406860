import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CertificateSidebar from './Components/Sidebar';
import CanvasCertificate from './Components/CanvasCertificate';
import { CanvasProvider } from './hooks/useCanvasContext';
import PanelItem from './Pannels/PannelItem';
import { DownloadButtons } from './actions/CommonDownload';
import { TabProvider } from './hooks/TabContext';
import { fetchSingleTemplate } from '../../../../services/actions/admin/team/fetchTeamsList';
import { useAdminSelectors } from '../../../../services/selectors/adminModuleSelectors';
import { useCustomDispatch, useCustomLocation, useCustomNavigate } from '../../../../hooks'
import { LoadingView } from '../../../../components/common'
import WebFont from 'webfontloader';
import Cookies from 'js-cookie';
const Certificate = () => {
    const dispatch = useCustomDispatch();
    // const { id } = useParams();
    const [panelOpen, setPanelOpen] = useState(true);
    const [templateSize, setTemplateSize] = useState(null);
    const [jsonData, setJsonData] = useState('');
    const [activeTab, setActiveTab] = useState('templates');
    const [dataLoaded, setDataLoaded] = useState(false);
    const { TeamListResponse } = useAdminSelectors();
    const [certificateName, setCertificateName] = useState('');
    const [certToBeUpdated, setcertToBeUpdated] = useState(false);
    const [id,setID] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            if (Cookies.get('pharmacyCertificateId')) {
                setID(Cookies.get('pharmacyCertificateId'))
                const tempid = Cookies.get('pharmacyCertificateId')
                await dispatch(fetchSingleTemplate('fetch_single_certificate_template_list', tempid));
                Cookies.set('pharmacyCertificateId', '', { expires: 1 }); 
            } else {
                setDataLoaded(true);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (TeamListResponse?.data && id) {
            setActiveTab('text');
            //console.log(TeamListResponse.data?.data?.certificate_template)
            setJsonData(TeamListResponse.data?.data?.certificate_template);
            setCertificateName(TeamListResponse.data?.data?.certificate_title);
            extractFontsFromJson(TeamListResponse.data?.data?.certificate_template);
            setcertToBeUpdated(true) //this to be set as true only when we are updating the existing certificate
            setDataLoaded(true);
        }

    }, [TeamListResponse]);

    //this method has been added to support preload of the font families that has been used previously in the certificate [use this during updation of template]
    const extractFontsFromJson = (jsonData) => {
        const fonts = new Set();
        try {
            const parsedData = JSON.parse(jsonData);
            parsedData.objects.forEach(obj => {
                if (obj.type === 'textbox' && obj.fontFamily) {
                    fonts.add(obj.fontFamily);
                    WebFont.load({
                        google: {
                            families: [obj.fontFamily],
                        },
                    });
                }
            });
        } catch (error) {
            console.error('Error parsing JSON data:', error);
        }
    };
   

    return (
        <div>
            {dataLoaded ? (
                <CanvasProvider>
                    <DownloadButtons certName={certificateName} certToBeUpdated={certToBeUpdated} id={id} />
                    <div className='flex gap-0 w-full' style={{ height: '100vh', position: 'relative', background: "#ecf0f1" }}>
                        <TabProvider currentActiveTab={activeTab}>
                            <CertificateSidebar />
                            <PanelItem panelOpen={panelOpen} />
                            <CanvasCertificate jsonData={jsonData} />
                        </TabProvider>
                    </div>
                </CanvasProvider>
            ): (
                    <div style={{ height: '100vh' }} className="flex justify-center items-center ">
                        <LoadingView />
                    </div>
                )}
        </div>
    );
};

export default Certificate;
