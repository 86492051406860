import { fabric } from 'fabric';

export const AddImageToCanvas = (canvas, imageUrl,width = '200', height = '200') => {
    fabric.Image.fromURL(imageUrl, function (img) {
        img.scaleToWidth(width);
        img.scaleToHeight(height);
        img.set({
            left: canvas.getWidth() / 2,
            top: canvas.getHeight() / 2,
            originX: 'center',
            originY: 'center',
        });

        // Add custom controls to the image object
        img.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: -20,
            offsetX: 0,
            cursorStyle: 'pointer',
            mouseUpHandler: function (eventData, target) {
                DeleteObject(canvas, eventData, target);
            },
            render: renderDeleteIcon,
        });

        canvas.add(img);
        canvas.setActiveObject(img);
        canvas.renderAll();
    });
};

export const DeleteObject = (canvas, eventData, transform) => {
    const target = transform ? transform.target : canvas.getActiveObject();
    if (target) {
        canvas.remove(target);
        canvas.requestRenderAll();
        return true;
    }
    return false;
};

export const renderDeleteIcon = (ctx, left, top, styleOverride, fabricObject) => {
    const size = 20;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.fillStyle = '#FF0000';
    ctx.fillRect(-size / 2, -size / 2, size, size);
    ctx.strokeStyle = '#FFFFFF';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(-size / 2 + 4, -size / 2 + 4);
    ctx.lineTo(size / 2 - 4, size / 2 - 4);
    ctx.moveTo(-size / 2 + 4, size / 2 - 4);
    ctx.lineTo(size / 2 - 4, -size / 2 + 4);
    ctx.stroke();
    ctx.restore();
};

export const DeleteActiveObject = (canvas) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
        canvas.remove(activeObject);
        canvas.renderAll();
    }
};
