import {
	PHARMACY_DELETE_DISPENSE_DATA_REQUEST,
	PHARMACY_DELETE_DISPENSE_DATA_SUCCESS,
	PHARMACY_DELETE_DISPENSE_DATA_FAILURE

} from '../../../constants';

import { fetchAllDispenseList } from './fetchAllDispenseListData';

import Axios from '../../../../networking/intraceptor'
import * as path from '../../../../networking/urlEndPoints'
import { pharmacyAddDispenseDataFailure, pharmacyAddDispenseDataSuccess } from './addDispenseData';
import { fetchAllDeliveryDriversList } from './deliveryDeriversLists';
import { fetchDispenseLabelsList } from './labelList';

export const deleteDispenseDataRequest = () => ({
	type: PHARMACY_DELETE_DISPENSE_DATA_REQUEST
});

export const deleteDispenseDataSuccess = (user, types) => ({
	type: PHARMACY_DELETE_DISPENSE_DATA_SUCCESS,
	payload: user,
	types: types
});

export const deleteDispenseDataFailure = (error, types) => ({
	type: PHARMACY_DELETE_DISPENSE_DATA_FAILURE,
	payload: error,
	types: types
})

export const handleDeleteDispenseData = (url, setShowModal, type) => {
	return async (dispatch) => {
		dispatch(deleteDispenseDataRequest());
		dispatch(pharmacyAddDispenseDataSuccess(null, null))
		dispatch(pharmacyAddDispenseDataFailure(null, null))
		try {
			const response = await Axios.post(`${url}`, type)

			if (response?.status === 200) {
				dispatch(deleteDispenseDataSuccess(response?.data, type));

				if (typeof setShowModal === "function" && (type === 'driver_delete')) {
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						deleteModal: false,
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000);
					document.body.style.overflow = 'auto'
					dispatch(fetchAllDispenseList(path.pharmacy_driver_list, 'pharmacy_driver_list'))
					dispatch(fetchAllDeliveryDriversList(path.pharmacy_driver_list))

				}

				if (typeof setShowModal === "function" && (type === 'Delete_Recurrence_Delivery_List')) {
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						deleteModal: false,
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000);
					document.body.style.overflow = 'auto'

					dispatch(fetchAllDispenseList(path.pharmacy_patient_recurrence_delivery, 'pharmacy-recurrence-delivery'))
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(deleteDispenseDataFailure(error?.response, type));
			}
		}
	}
}

export const handleLabelDelete = (labelId, type, formData, pageNumber, setShowModal, showModal) => {
	return async (dispatch) => {
		dispatch(deleteDispenseDataRequest());
		dispatch(pharmacyAddDispenseDataSuccess(null, null))
		dispatch(pharmacyAddDispenseDataFailure(null, null))
		try {
			const response = await Axios.post(`${path.delete_rx_labels}`,
				{
					labelid: labelId
				})

			if (response?.status === 200) {
				dispatch(deleteDispenseDataSuccess(response?.data, type));

				if (typeof setShowModal === "function" && (type === 'DeleteRxModal')) {
					setShowModal(prevState => ({
						...prevState,
						DeleteRxModal: false,
						showToast: true
					}));

					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false
						}));
					}, 2000);

					document.body.style.overflow = 'auto'

					const params = {
						from_date: formData?.startDate,
						to_date: formData?.endDate,
						route: formData?.patientRoutes ? formData?.patientRoutes?.label : 'default',
						page: pageNumber,
						muteunmute: !formData?.muteValue ? 0 : formData?.muteValue?.value
					}
					dispatch(fetchDispenseLabelsList(params))

				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(deleteDispenseDataFailure(error?.response, type));
			}
		}
	}
}

