import React, { useState } from 'react'

import {
	initalAddDoctorForm,
	initalAddDoctorFormError
} from '../../utils/constants/formInitialStates'

import {
	SuccessMessage,
	Errors
} from '../../components/error/alertMessages'

import {
	useCustomDispatch,
	useCustomSelector
} from '../../hooks'

import { hideAddDoctorModal } from '../../assets/js/custome'
import { ModalCloseIcon } from '../../assets/svg/icons'

import { handleSaveDoctors } from '../../utils/validations/others'
import { handleAddDoctorsFormChange } from '../../utils/data/handleFormDataChange'

const AddDoctors = ({ setShowModal,
	showModal }) => {

	const dispatch = useCustomDispatch()
	const addDoctorResponse = useCustomSelector(state => state.addNewDoctor)

	const [formData, setFormData] = useState(initalAddDoctorForm)
	const [errorMessages, setErrorMessages] = useState(initalAddDoctorFormError)

	return (
		<React.Fragment>
			<div className="overflow-y-auto overflow-x-hidden fixed top-0 left-0 right-0 bottom-0 z-50 flex  items-center 
							justify-center w-full bg-black bg-opacity-50 dark:bg-opacity-100 max-h-full">
				<div className="relative p-4 w-full max-w-2xl max-h-full">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
							<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
								Add Doctor
							</h3>
							<button onClick={() => hideAddDoctorModal(setShowModal, showModal)} type="button"
								className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
								<ModalCloseIcon />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="p-4 md:p-5 space-y-4">
							<form>
								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<input
												type="text"
												id="lastName"
												onChange={(e) => handleAddDoctorsFormChange(e, setFormData, setErrorMessages, formData, errorMessages)}
												name="lastName"
												value={formData.lastName}
												className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.lastName && 'border-red-600'
													}`}
												placeholder=""
											/>
											<label
												htmlFor="lastName"
												className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
												{!errorMessages.lastName && 'Last Name'}
											</label>
											{errorMessages.lastName && (
												<p className="mt-2 text-xs text-red-600 dark:text-red-400">
													{errorMessages.lastName}
												</p>
											)}
										</div>
									</div>

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<input
												type="text"
												id="firstName"
												onChange={(e) => handleAddDoctorsFormChange(e, setFormData, setErrorMessages, formData, errorMessages)}
												name="firstName"
												value={formData.firstName}
												className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.firstName && 'border-red-600'
													}`}
												placeholder=""
											/>
											<label
												htmlFor="firstName"
												className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
											>
												{!errorMessages.firstName && 'First Name'}
											</label>
											{errorMessages.firstName && (
												<p className="mt-2 text-xs text-red-600 dark:text-red-400">
													{errorMessages.firstName}
												</p>
											)}
										</div>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-12">
										<div className="relative">
											<input
												type="text"
												id="cps_id"
												onChange={(e) => handleAddDoctorsFormChange(e, setFormData, setErrorMessages, formData, errorMessages)}
												name="cps_id"
												value={formData.cps_id}
												className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.cps_id && 'border-red-600'
													}`}
												placeholder=""
												onKeyPress={(e) => {
													if (isNaN(String.fromCharCode(e.charCode))) {
														e.preventDefault();
													}
												}}
											/>
											<label htmlFor="cps_id"
												className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
												-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
												peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
												peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
												rtl:peer-focus:left-auto start-1`}>
												{!errorMessages.cps_id && 'CPS ID Number'}
											</label>
											{errorMessages.cps_id && (
												<p className="mt-2 text-xs text-red-600 dark:text-red-400">
													{errorMessages.cps_id}
												</p>
											)}
										</div>
									</div>
								</div>

								{addDoctorResponse?.data !== null &&
									<SuccessMessage message='New Doctor Added Successfully' />}

								{addDoctorResponse?.error?.data?.data?.error?.cps_id?.includes("Doctor already exist with this ID") ?
									<Errors error={addDoctorResponse?.error?.data?.data?.error?.cps_id[0]} /> :
									addDoctorResponse?.error !== null ?
										<Errors error='Something Went wrong While Adding the new doctor please try again later' /> : null
								}

								<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2  mb-0 block text-left">
									<button onClick={() => handleSaveDoctors(formData, setErrorMessages, errorMessages, dispatch, setShowModal, showModal)} type="button" className="blueBg-color mx-auto sm:text-center 
									md:text-start lg:text-start rubik-400 fs-16 text-white rounded-lg text-sm px-2 py-2" data-ripple-light="true">
										{addDoctorResponse?.loading ? 'Saving...' : 'Save Doctor'}</button>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default AddDoctors