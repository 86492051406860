import React, {
    useEffect,
    useState,
    useCallback
} from 'react'

import {
    ErrorToast,
    SuccessToastMessage
} from '../../../components/loaders/toastMessage'

import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { initDropdowns } from 'flowbite'

import { DropdownSelect } from '../../../components/pharmacy/dispense/dispense'
import { fetchInventoryList } from '../../../services/actions/pharmacy/Inventory/inventoryList'

import {
    ClearInventryData,
    SyncInventoryData,
    UpdateInventory,
    addInventoryFailure
} from '../../../services/actions/pharmacy/Inventory/addInventoryData'

import { DeleteIcon } from '../../../assets/svg/icons'
import { DeleteDailyRevenueModal } from '../../../models/revenue/dailyRevenue'
import { useInventorySelector } from '../../../services/selectors/inventorySelectors'
import { utils, writeFile } from 'xlsx';

import { currentDate } from '../../../utils/constants/formInitialStates'
import { PreviousDatePicker } from '../../../components/pharmacy/form/form'
import { debounce } from 'lodash';
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

import ChatBox from '../../../components/chat/chatbox'
import AddInventoryModal from '../../../models/pharmacy/addInventory'
import VerifyPassCode from './verifyPasscode'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'
import * as Modals from '../../../assets/js/custome'

const inventoryListHeading = [
    { text: "DIN", classNames: ['p-3'] },
    { text: "DRUG", classNames: ['p-3'] },
    { text: "BLACK SA", classNames: ['p-3'] },
    { text: "WHITE 1A", classNames: ['p-3'] },
    { text: "WHITE 1B", classNames: ['p-3'] },
    { text: "WHITE 2A, 2B", classNames: ['p-3'] },
    { text: "BTS", classNames: ['p-3'] },
    { text: "RTS", classNames: ['p-3'] },
    { text: "ON SHELF", classNames: ['p-3'] },
    { text: "COMPUTER", classNames: ['p-3'] },
    { text: "DIFFERENCE", classNames: ['p-3'] },
    { text: "", classNames: ['p-3'] },
];

const TableRowData = ({ value, className1 = 'p-3', }) => {
    return (<td className={className1}>
        <p>{value}</p>
    </td>)
}

export const exportToExcel = (data, fileName) => {
    const formattedData = data?.map(value => ({
        'DIN': value.din,
        'DRUG': value.drug,
        'BLACK SA': value.black_sa,
        'WHITE 1A': value.white_1a,
        'WHITE 2A ,2B': value.white_2a_2b,
        'BTS': value.bts_rts,
        'RTS': value?.rts,
        'ON SHELF': value?.onshell,
        'COMPUTER': value?.computer,
        'DIFFERENCE': value?.difference
    }))

    const worksheet = utils.json_to_sheet(formattedData);

    worksheet['!cols'] = [
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
    ];

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${fileName}.xlsx`);
};

const InventoryList = () => {

    const verifyPasscode = localStorage.getItem('@oat_p$(sDe)')

    const location = Hooks.useCustomLocation()
    const navigate = Hooks.useCustomNavigate()
    const dispatch = Hooks.useCustomDispatch()

    const { addInventoryResponse, InventoryListResponse } = useInventorySelector()
    const { profileData, adminModule } = useCustomSelectors()

    const inventoryList = InventoryListResponse?.data?.data
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [showModal, setShowModal] = useState({ addInventory: false, deleteRevenueList: false })
    const [logData, setLogData] = useState(null)

    const [showToast, setShowToast] = useState({ sucessToast: '', errorToast: '' })
    const [formDataArray, setFormDataArray] = useState([]);
    const [isEdit, setIsEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [formData, setFormData] = useState({
        search: '',
        type: 'current_sheet',
        date: currentDate,
        blackSa: '',
        white1A: '',
        white1B: '',
        White_2A_2B: '',
        bts: '',
        rts: '',
        onShelf: '',
        computer: '',
        difference: '',
    })

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        setIsLoading(true)
        dispatch(fetchInventoryList('inventory_list'))
        return () => { }
    }, [])

    const handlePagination = (event, value) => {
        if (!value) return;
        const params = {
            currentsheet: formData.type,
            page: value,
            ...(formData.type === 'previous_sheet' && { searchdate: formData.date })
        };

        setPageNumber(value);

        const searchParams = new URLSearchParams({ page: value });
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        setIsLoading(true)
        dispatch(fetchInventoryList('inventory_list', params));
        navigate(newUrl);
    };

    const handleFilter = () => {
        const params = {
            currentsheet: formData.type,
            page: pageNumber,
            ...(formData.type === 'previous_sheet' && { searchdate: formData.date })
        };
        setIsLoading(true)
        dispatch(fetchInventoryList('inventory_list', params));
    };

    useEffect(() => {
        initDropdowns()
    }, [inventoryList?.data])

    useEffect(() => {
        if (inventoryList?.data) {
            const initialData = inventoryList.data.map(item => {
                const blackSa = parseFloat(item.black_sa) || 0;
                const white1A = parseFloat(item.white_1a) || 0;
                const White_2A_2B = parseFloat(item.white_2a_2b) || 0;
                const bts = parseFloat(item.bts_rts) || 0;
                const rts = parseFloat(item.rts) || 0;
                const computer = parseFloat(item.computer) || 0;
                const white_1b = parseFloat(item.white_1b) || 0;
                const sum = blackSa + white1A + White_2A_2B + bts + rts + white_1b;
                const difference = sum - computer;

                return {
                    blackSa: blackSa.toString(),
                    white1A: white1A.toString(),
                    White_2A_2B: White_2A_2B.toString(),
                    bts: bts.toString(),
                    rts: rts.toString(),
                    white_1b: white_1b.toString(),
                    onShelf: sum.toString(),
                    computer: computer.toString(),
                    difference: difference.toString(),
                };
            });

            setFormDataArray(initialData);
        }
    }, [inventoryList]);

    const handleInputChange = (index, e, id) => {
        const { name, value } = e.target;
        const updatedFormDataArray = [...formDataArray];
        const updatedRow = { ...updatedFormDataArray[index], [name]: value };

        const sum = ['blackSa', 'white1A', 'White_2A_2B', 'bts', 'rts', 'white_1b']
            .map(key => parseFloat(updatedRow[key]) || 0)
            .reduce((a, b) => a + b, 0);

        updatedRow.onShelf = sum.toString();

        const computerValue = parseFloat(updatedRow.computer) || 0;
        updatedRow.difference = (sum - computerValue).toString();

        updatedFormDataArray[index] = updatedRow;
        setFormDataArray(updatedFormDataArray);

        const fieldToColumnMap = {
            blackSa: 'black_sa',
            white1A: 'white_1a',
            white1B: 'white_1b',
            white_1b: 'white_1b',
            White_2A_2B: 'white_2a_2b',
            bts: 'bts_rts',
            rts: 'rts',
            computer: 'computer'
        };

        if (fieldToColumnMap[name]) {
            const requestData = {
                value: value,
                col: fieldToColumnMap[name],
                id: id
            };
            debounceUpdateInventory(requestData);
        }
    }

    const debounceUpdateInventory = useCallback(debounce((requestData) => {
        setIsLoading(false)
        dispatch(UpdateInventory('update_inventory', requestData, setShowToast, formData, pageNumber));
    }, 500), [formData, pageNumber]);

    return (
        <React.Fragment>
            {verifyPasscode === 'true' ? <>
                <div className='BodyBgColor'>
                    <div className="container mx-auto px-4 pt-5 pb-5">

                        <div className="grid grid-cols-12 gap-4">
                            {/* <Common.Sidebar /> */}
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                                <div className="content">
                                    <div className="grid grid-cols-12 gap-4 w-full">
                                        <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                            <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                                <Common.BreadCrumb />
                                            </h2>
                                        </div>
                                    </div>

                                    <div className="container mt-2">
                                        <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                            <div className='col-span-12 flex'>
                                                <div className="flex gap-2">

                                                    <div className='w-44'>
                                                        <DropdownSelect
                                                            label=""
                                                            name="type"
                                                            formData={formData}
                                                            setFormData={setFormData}
                                                            options={
                                                                (profileData?.type === "pharmacist" || adminModule === 1) ?
                                                                    [{ value: "current_sheet", label: "Current Sheet" },
                                                                    { value: "previous_sheet", label: "Previous Sheet" }] :
                                                                    [{ value: "current_sheet", label: "Current Sheet" }]
                                                            }
                                                        />
                                                    </div>

                                                    {formData.type === 'previous_sheet' &&
                                                        <PreviousDatePicker setFormData={setFormData} formData={formData} />}

                                                    <button type="button" style={{ backgroundColor: '#3B82F6', width: '100px' }} onClick={handleFilter}
                                                        className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                                                    </button>

                                                    <button type="button" style={{ width: '180px' }} onClick={() => exportToExcel(inventoryList?.data, 'Inventroy-list')}
                                                        className="flex w-full bg-green-500 h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 	focus:outline-none hover:bg-green-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Export Inventory
                                                    </button>

                                                    {formData.type === 'current_sheet' && <>
                                                        <button type="button" style={{ width: '100px', background: 'orange' }} onClick={() => dispatch(SyncInventoryData('sync_inventory', setShowToast))}
                                                            className="flex w-full bg-orange-500 h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 	focus:outline-none hover:bg-red-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 
                                                            dark:focus:ring-gray-700">
                                                            {addInventoryResponse?.loading && addInventoryResponse?.type === 'sync_inventory' ? 'Processing...' : 'Sync'}
                                                        </button>

                                                        <button type="button" style={{ width: '100px' }} onClick={() => dispatch(ClearInventryData('clear_inventory', setShowToast))}
                                                            className="flex w-full bg-red-500 h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 	focus:outline-none hover:bg-red-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 
                                                            dark:focus:ring-gray-700">
                                                            {addInventoryResponse?.loading && addInventoryResponse?.type === 'clear_inventory' ? 'Processing...' : 'Clear'}
                                                        </button>
                                                    </>}

                                                </div>

                                                {(profileData?.type === "pharmacist" || adminModule === 1) &&
                                                    <div className="ml-auto">
                                                        <button type="button" onClick={() => { return setIsEdit(false), setLogData(null), dispatch(addInventoryFailure(null, null)), Modals.handleShowModal(setShowModal, showModal, 'addInventory') }}
                                                            className="rubik-500 rounded-md flex fs-14  h-full text-white blueBg-color  focus:ring-4 
													focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-1.5 text-center
													 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 justify-center items-center">
                                                            <span className="flex items-center" >
                                                                Add Inventory
                                                            </span>
                                                        </button>
                                                    </div>
                                                }
                                            </div>

                                            {InventoryListResponse?.loading && InventoryListResponse?.type === 'inventory_list' && isLoading ?
                                                <div className="flex justify-center items-center mt-60 mb-80">
                                                    <Common.LoadingView />
                                                </div> :
                                                InventoryListResponse?.error !== null && InventoryListResponse?.type === 'inventory_list' ?
                                                    <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                        <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                        <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                        <button onClick={() => window.location.reload()}
                                                            className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                            Please Try Again.
                                                        </button>
                                                    </div> :
                                                    inventoryList?.data?.length === 0 && InventoryListResponse?.type === 'inventory_list' ?
                                                        <div className="bg-white rounded-md mt-2">
                                                            <p className="text-gray-500">No record available.</p>
                                                        </div>
                                                        : InventoryListResponse?.type === 'inventory_list' ?
                                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                                <div className="shadow-md border rounded-lg  relative">
                                                                    <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                        <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                            <tr>
                                                                                {inventoryListHeading?.map((header, index) => (
                                                                                    <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                        <p className="rubik-500 fs-12">{header?.text}</p>
                                                                                    </th>
                                                                                ))}
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            {
                                                                                inventoryList?.data?.map((value, idx) => (
                                                                                    <React.Fragment key={idx}>
                                                                                        <tr style={{ borderBottom: `${'1px solid lightgray'}` }}>

                                                                                            <TableRowData value={value.din} />
                                                                                            <TableRowData value={value.drug} />

                                                                                            <td className="p-3">
                                                                                                <input type="number" id={`blackSa-${idx}`} autoComplete="off"
                                                                                                    onChange={(e) => handleInputChange(idx, e, value.id)}
                                                                                                    name="blackSa"
                                                                                                    style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                    defaultValue={value.black_sa}
                                                                                                    className="block w-16 text-sm text-gray-900 border-200-gray  bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                    placeholder=""
                                                                                                />
                                                                                            </td>

                                                                                            <td className="p-3">
                                                                                                <input type="number" id={`white1A-${idx}`} autoComplete="off"
                                                                                                    onChange={(e) => handleInputChange(idx, e, value.id)}
                                                                                                    name="white1A"
                                                                                                    style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                    defaultValue={value.white_1a}
                                                                                                    className="block w-16 text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                    placeholder=""
                                                                                                />
                                                                                            </td>

                                                                                            <td className="p-3">
                                                                                                {/* {(profileData?.type === "pharmacist" || adminModule === 1) ? */}
                                                                                                <input type="number" id={`white_1b-${idx}`} autoComplete="off"
                                                                                                    onChange={(e) => handleInputChange(idx, e, value.id)}
                                                                                                    name="white_1b"
                                                                                                    style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                    defaultValue={value.white_1b}
                                                                                                    className="block w-16 text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                    placeholder=""
                                                                                                />
                                                                                                {/* : <p>{value.white_1b}</p>} */}
                                                                                            </td>

                                                                                            <td className="p-3">
                                                                                                {(profileData?.type === "pharmacist" || adminModule === 1) ?
                                                                                                    <input type="number" id={`White_2A_2B-${idx}`} autoComplete="off"
                                                                                                        onChange={(e) => handleInputChange(idx, e, value.id)}
                                                                                                        name="White_2A_2B"
                                                                                                        style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                        defaultValue={value.white_2a_2b}
                                                                                                        className="block w-16 text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                        placeholder=""
                                                                                                    /> : <p>{value.white_2a_2b}</p>}
                                                                                            </td>

                                                                                            <td className="p-3">
                                                                                                <input type="number" id={`bts-${idx}`} autoComplete="off"
                                                                                                    onChange={(e) => handleInputChange(idx, e, value.id)}
                                                                                                    name="bts"
                                                                                                    style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                    defaultValue={value.bts_rts}
                                                                                                    className="block w-16 text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                    placeholder=""
                                                                                                />
                                                                                            </td>

                                                                                            <td className="p-3">
                                                                                                <input type="number" id={`rts-${idx}`} autoComplete="off"
                                                                                                    onChange={(e) => handleInputChange(idx, e, value.id)}
                                                                                                    name="rts"
                                                                                                    style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                    defaultValue={value.rts}
                                                                                                    className="block w-16 text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                    placeholder=""
                                                                                                />
                                                                                            </td>

                                                                                            <td className="p-3">
                                                                                                {/* <input type="number" id={`onShelf-${idx}`} autoComplete="off"
                                                                                                    readOnly
                                                                                                    style={{ border: 'none' }}
                                                                                                    value={formDataArray[idx]?.onShelf || ''}
                                                                                                    className="block w-16 text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                    placeholder=""
                                                                                                /> */}
                                                                                                {formDataArray[idx]?.onShelf || ''}

                                                                                            </td>

                                                                                            <td className="p-3">
                                                                                                <input type="number" id={`computer-${idx}`} autoComplete="off"
                                                                                                    onChange={(e) => handleInputChange(idx, e, value.id)}
                                                                                                    name="computer"
                                                                                                    style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                                                                                                    defaultValue={value.computer}
                                                                                                    className="block w-16 text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                    placeholder=""
                                                                                                />
                                                                                            </td>

                                                                                            <td className="p-3">
                                                                                                {/* <input type="number" id={`difference-${idx}`} autoComplete="off"
                                                                                                    readOnly
                                                                                                    style={{ border: 'none' }}
                                                                                                    value={formDataArray[idx]?.difference || ''}
                                                                                                    className="block w-16 text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
                                                                                                    placeholder=""
                                                                                                /> */}
                                                                                                {formDataArray[idx]?.difference || ''}
                                                                                            </td>

                                                                                            <td className="p-3 flex justify-center items-center">

                                                                                                {formData.type === 'current_sheet' &&
                                                                                                    <div className='rounded-md btnnn flex justify-center items-center mt-0 p-2 bg-red-200'
                                                                                                        onClick={() => { return setLogData(value), Modals.handleShowModal(setShowModal, showModal, 'deleteRevenueList') }}>
                                                                                                        <DeleteIcon color="red" />
                                                                                                    </div>}
                                                                                            </td>
                                                                                        </tr>

                                                                                    </React.Fragment>)

                                                                                )}
                                                                        </tbody>
                                                                    </table>


                                                                    {InventoryListResponse?.error !== null ? null : <>
                                                                        {inventoryList?.data?.length !== 0 && <>
                                                                            {inventoryList?.pagination?.last_page !== 1 &&
                                                                                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                    <nav aria-label="Page navigation example">
                                                                                        <ul className="inline-flex -space-x-px text-base h-10">
                                                                                            <CustomPagination
                                                                                                count={inventoryList?.pagination?.last_page}
                                                                                                page={pageNumber}
                                                                                                onChange={handlePagination}
                                                                                            />
                                                                                        </ul>
                                                                                    </nav>
                                                                                </div>
                                                                            }
                                                                        </>}
                                                                    </>}
                                                                </div>

                                                            </div> : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ChatBox />

                <Common.Footer />

                {showToast?.sucessToast && addInventoryResponse.type === 'add_inventory' && <SuccessToastMessage status='Added sucessfully' />}
                {showToast?.sucessToast && addInventoryResponse.type === 'sync_inventory' && <SuccessToastMessage status='Updated sucessfully' />}
                {showToast?.errorToast && addInventoryResponse.type === 'sync_inventory' && <ErrorToast status='Oops Got an issue while synchronizing Data.please try again' />}

                {showToast?.sucessToast && addInventoryResponse.type === 'clear_inventory' && <SuccessToastMessage status='Updated sucessfully' />}
                {showToast?.errorToast && addInventoryResponse.type === 'clear_inventory' && <ErrorToast status='Oops Got an issue while clearing Data.please try again' />}

                {showToast?.errorToast && addInventoryResponse.type === 'update_inventory' && <ErrorToast status='Oops! Not Updated.please try again' />}


                {
                    showModal?.addInventory &&
                    <AddInventoryModal
                        setShowModal={setShowModal}
                        showModal={showModal}
                        setShowToast={setShowToast}
                        isEdit={isEdit}
                        logData={logData}
                        data={formData}
                        pageNumber={pageNumber}
                    />
                }

                {showModal.deleteRevenueList &&
                    <DeleteDailyRevenueModal
                        formData={formData}
                        text={'Are you sure you want to Delete ?'}
                        logData={logData}
                        pageNumber={pageNumber}
                        setShowModal={setShowModal}
                        setShowToast={setShowToast}
                    />
                }

            </> :
                <VerifyPassCode />}

        </React.Fragment>
    )
}

export default InventoryList