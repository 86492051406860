import {
	ADD_WALL_POST_LIST_USER_COMMENTS_REQUEST,
	ADD_WALL_POST_LIST_USER_COMMENTS_SUCCESS,
	ADD_WALL_POST_LIST_USER_COMMENTS_FAILURE
} from "../../constants";

const initialState = {
	comments: null,
	isLoading: false,
	error: null,
};

const userCommentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_WALL_POST_LIST_USER_COMMENTS_REQUEST:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case ADD_WALL_POST_LIST_USER_COMMENTS_SUCCESS:
			return {
				...state,
				comments: action.payload,
				isLoading: false,
				error: null,
			};

		case ADD_WALL_POST_LIST_USER_COMMENTS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload,
				comments: null
			};

		default:
			return state;
	}
};

export default userCommentsReducer;
