import {
	PHARMACY_COVERAGE_PATIENT_LIST_REQUEST,
	PHARMACY_COVERAGE_PATIENT_LIST_SUCCESS,
	PHARMACY_COVERAGE_PATIENT_LIST_FAILURE
} from '../../../constants'

const initialState = {
	loading: false,
	data: null,
	error: null,
};

const coverageListReducer = (state = initialState, action) => {
	switch (action.type) {
		case PHARMACY_COVERAGE_PATIENT_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				data: null,
			};
		case PHARMACY_COVERAGE_PATIENT_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			}
		case PHARMACY_COVERAGE_PATIENT_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null
			}
		default:
			return state;
	}
}
export default coverageListReducer