import { pharmacy_delete_patient } from '../../../networking/urlEndPoints'
import { fetchPatientsList } from './patientsList'

import Axios from "../../../networking/intraceptor"
import * as routeNames from '../../../routes/routeName'

export const deletePatients = (patient_id,
	showModal,
	setLoading,
	setShowModal,
	location,
	paginationNumber,
	setMessage,
	queryStatus,
	querySearch) => {
	return async (dispatch) => {
		try {
			setLoading(true)
			const response = await Axios.post(`${pharmacy_delete_patient}/${patient_id}`)
			if (response?.status === 200) {

				setLoading(false)
				setMessage('Successfully deleted')
				
				if (typeof setShowModal === 'function') {
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						deleteModal: false
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000);
				}
				document.body.style.overflow = 'auto'


				if ((location.pathname === routeNames.ALL_PATIENTS)) {
					await dispatch(fetchPatientsList(querySearch, queryStatus, paginationNumber))
				}

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				setLoading(false)
			}
		}
	}
}
