import React, {
	useRef,
	useState
} from 'react'

import {
	useCustomDispatch,
	useCustomSelector
} from '../../hooks'

import { updateCoverPicture } from '../../services/actions/auth/updateUserProfileCoverPictureActions';
import { useCustomSelectors } from '../../services/selectors/allSelectors';

import AvatarEditor from 'react-avatar-editor';
import ReactLoading from 'react-loading';

import * as images from '../../assets/images/index'

const UpdatingCoverPicture = () => {

	const dispatch = useCustomDispatch()
	const CoverFileInputRef = useRef(null);

	const { userProfileResponse } = useCustomSelectors()

	const coverProfile = userProfileResponse?.profileData?.data?.cover_photo
	const updateCoverPic = useCustomSelector(state => state.updateCoverPicture)

	const [image, setImage] = useState(null);
	const [editor, setEditor] = useState(null);
	const [scale, setScale] = useState(1);
	const [croppedImage, setCroppedImage] = useState(null);

	const handleUpdateCoverClick = () => {
		CoverFileInputRef.current.click();
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		setImage(URL.createObjectURL(file));
	};

	const handleSaveImage = async () => {
		if (editor) {

			const canvas = editor.getImageScaledToCanvas();
			canvas.toBlob(async (blob) => {
				if (blob) {
					const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
					setCroppedImage(file);
					try {
						await dispatch(updateCoverPicture(file));
					} catch (error) {
						console.error('Error dispatching the action:', error);
					}
					setCroppedImage('')
					setImage(null)
				}
			}, 'image/jpeg');
		}
	}

	return (
		<>
			{
				(updateCoverPic?.loading || userProfileResponse?.loading) ?
					<div style={{ position: 'absolute', left: '490px', top: '20px' }}>
						<ReactLoading type={'bubbles'} color={'grey'} height={167} width={150} delay={1000} />
					</div> : null
			}


			<input type="file" accept="image/*" onChange={handleImageChange} ref={CoverFileInputRef} id="fileInputss" className="hidden" />

			{
				coverProfile && !image ?
					<img src={coverProfile} className="rounded-lg w-full lg" alt="" style={{ opacity: updateCoverPic.loading ? 0.1 : 1, height: '200px' }} />
					:
					!image && !croppedImage ? <>
						<img src={images.setting_bg} className="rounded-lg w-full lg" alt="" />
					</>
						: <>
							<div>
								{croppedImage ? <div>
									<img src={croppedImage && URL?.createObjectURL(croppedImage)} className="rounded-lg w-full lg" alt="" />
								</div> : <>
									{image && (
										<div className="scrollable-container">
											<AvatarEditor
												ref={(ref) => setEditor(ref)}
												image={image}
												width={1124}
												height={200}
												border={1}
												color={[255, 255, 255, 0.6]}
												scale={scale}
											/>
										</div>
									)}
								</>}
							</div>
						</>
			}

			{
				image && !croppedImage ?
					<button onClick={handleSaveImage} type="button"
						className="absolute right-4 top-4 light-color nunito-700 fs-16 blueBg-color font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700">
						{
							updateCoverPic.loading ? 'Updating...' : 'Save Image'
						}
					</button>
					:
					<>
						{
							updateCoverPic?.loading ?
								<button onClick={handleUpdateCoverClick} type="button"
									className="absolute right-4 top-4 light-color nunito-700 fs-16 blueBg-color font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700">
									Updating
								</button>
								:
								<button type="button" onClick={handleUpdateCoverClick} className="absolute right-4 top-4 light-color rubik-400 fs-16 camera_blueBgColor rounded-full p-2 mr-2 mb-2 h-12 w-12 flex items-center">
									<svg className="mx-auto" width="30" height="15" viewBox="0 0 52 37" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_107_17-025926)">
											<path d="M42.9951 1.60986H45.3376C46.0276 1.60986 46.5868 2.16811 46.5868 2.85662V6.85462H41.7457V2.8548C41.7468 2.16702 42.3058 1.60986 42.9951 1.60986Z" fill="#575B6D"></path>
											<path d="M33.2172 1.16614e-07H18.7827C18.0899 -0.000121507 17.4536 0.38129 17.1283 0.991717L14.002 6.85481H37.9981L34.8711 0.989162C34.545 0.380073 33.9091 -0.00024313 33.2172 1.16614e-07Z" fill="#DBD8DD"></path>
											<path d="M3.12315 5.05176H48.8769C50.6017 5.05176 52 6.44726 52 8.16872V33.8831C52 35.6045 50.6017 37 48.8769 37H3.12315C1.39827 37.0001 0 35.6046 0 33.8832V8.16872C0 6.44726 1.39827 5.05176 3.12315 5.05176Z" fill="#DBD8DD"></path>
											<path d="M0 12.5586H52V29.4942H0V12.5586Z" fill="#575B6D"></path>
											<path d="M26 33.7405C33.0361 33.7405 38.74 28.0479 38.74 21.0258C38.74 14.0036 33.0361 8.31104 26 8.31104C18.9639 8.31104 13.26 14.0036 13.26 21.0258C13.26 28.0479 18.9639 33.7405 26 33.7405Z" fill="#686D82"></path>
											<path d="M35.6162 21.0266C35.6265 26.3396 31.3193 30.6549 25.9958 30.6651C20.6723 30.6755 16.3484 26.3768 16.3382 21.0638C16.3278 15.7508 20.635 11.4355 25.9585 11.4253C27.9171 11.4215 29.8303 12.0132 31.4435 13.1218C34.0522 14.9116 35.6123 17.8672 35.6162 21.0266Z" fill="#419BE9"></path>
											<path d="M33.1239 18.5392C33.1316 23.8335 28.8373 28.1317 23.5324 28.1394C21.5794 28.1422 19.6719 27.5507 18.0642 26.4439C15.0596 22.0775 16.1706 16.1071 20.5456 13.1084C23.8295 10.8577 28.1645 10.8623 31.4435 13.1204C32.5391 14.7158 33.1249 16.605 33.1239 18.5392Z" fill="#69B0EE"></path>
											<path d="M24.754 17.9224C25.8176 17.9224 26.6799 17.0618 26.6799 16.0003C26.6799 14.9387 25.8176 14.0781 24.754 14.0781C23.6903 14.0781 22.828 14.9387 22.828 16.0003C22.828 17.0618 23.6903 17.9224 24.754 17.9224Z" fill="#EEEFEE"></path>
											<path d="M21.5756 20.6399C22.0498 20.6399 22.4342 20.2563 22.4342 19.783C22.4342 19.3097 22.0498 18.926 21.5756 18.926C21.1014 18.926 20.7169 19.3097 20.7169 19.783C20.7169 20.2563 21.1014 20.6399 21.5756 20.6399Z" fill="#EEEFEE"></path>
											<path d="M6.65081 10.8488C7.55369 10.8488 8.28562 10.1184 8.28562 9.21727C8.28562 8.31618 7.55369 7.58569 6.65081 7.58569C5.74792 7.58569 5.01599 8.31618 5.01599 9.21727C5.01599 10.1184 5.74792 10.8488 6.65081 10.8488Z" fill="#EF5261"></path>
											<path d="M39.7305 22.014C39.7341 24.7032 38.8789 27.3235 37.2891 29.4948H35.5009C39.8216 24.6728 39.8216 17.3806 35.5009 12.5586C38.1961 14.965 39.7349 18.4047 39.7305 22.014Z" fill="#464956"></path>
											<path d="M37.2877 29.4948C33.1514 35.171 25.1877 36.4262 19.5 32.298C18.7646 31.7642 18.0885 31.1533 17.4833 30.4756C22.7041 35.1713 30.7506 34.754 35.4555 29.5435C35.4701 29.5272 35.4848 29.5109 35.4994 29.4946L37.2877 29.4948Z" fill="#BBBBCD"></path>
										</g>
										<defs>
											<clipPath id="clip0_107_17-025926">
												<rect width="52" height="37" fill="white"></rect>
											</clipPath>
										</defs>
									</svg>
								</button>
						}
					</>
			}
		</>
	)
}

export default UpdatingCoverPicture