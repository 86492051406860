import {
	ADD_WALL_POST_REQUEST,
	ADD_WALL_POST_SUCCESS,
	ADD_WALL_POST_FAILURE,
} from '../../constants';

const initialState = {
	loading: false,
	error: null,
	wallPosts: null,
};

const addWallPostsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_WALL_POST_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				wallPosts: null
			};
		case ADD_WALL_POST_SUCCESS:
			return {
				...state,
				loading: false,
				wallPosts: action.payload,
				error: null,
			};
		case ADD_WALL_POST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				wallPosts: null
			};
		default:
			return state;
	}
};

export default addWallPostsReducer;
