import React, {
	useState,
	useEffect,
	useRef
} from 'react'

import {
	Sidebar,
	Footer
} from '../../components/common'

import {
	Errors,
	SuccessMessage
} from '../../components/error/alertMessages';

import {
	useCustomDispatch,
	useCustomNavigate
} from '../../hooks';

import {
	CalenderIcon,
	ClockIcon,
	TimeOffTypeIcon,
	UserIcon
} from '../../assets/svg/icons';

import { handleUpdateApiError } from '../../components/error/handleApiErros';
import { calculateTotalHours } from '../../utils/helper/helper';
import { validateNewTypeoFFRequestForm } from '../../utils/validations/others';
import { useCustomSelectors } from '../../services/selectors/allSelectors';

import flatpickr from 'flatpickr';
import ChatBox from '../../components/chat/chatbox'
import 'flatpickr/dist/flatpickr.min.css';

const NewRequest = () => {

	const newRequest = 0

	const dispatch = useCustomDispatch()
	const navigate = useCustomNavigate()

	const endDatePickerRef = useRef(null)
	const startDatePickerRef = useRef(null)

	const {
		authData,
		getTotalHours,
		timeOffRequestResponse } = useCustomSelectors()

	const reason = authData?.data?.timeoff_region

	const [startDateValue, setStartDateValue] = useState(new Date()?.toISOString().split('T')[0])
	const [dateValue, setdateValue] = useState(new Date()?.toISOString().split('T')[0])
	const [endDateValue, setEndDateValue] = useState(new Date()?.toISOString().split('T')[0])

	const [successMsg, setSuccessMsg] = useState(null)
	const [message, setMessage] = useState(null)

	const [isChecked, setIsChecked] = useState(true);
	const [showdate, setShowDate] = useState(true)

	const defaultTotalHours = '8.00';
	const [totalHours, setTotalHours] = useState(getTotalHours ? getTotalHours : defaultTotalHours);

	const [formData, setFormData] = useState({
		timeOfType: 'Paid',
		duration: '',
		discription: '',
		shiftCoveredByName: '',
		signature: '',
		name: authData?.data?.username,
		startTime: '',
		startTimeAmPm: '',
		endTime: '',
		endTimeAmPm: '',
		reason: reason.vacation_paid
	})

	useEffect(() => {
		let startDatepickerInstance;
		if (startDatePickerRef.current) {
			startDatepickerInstance = flatpickr(startDatePickerRef.current, {
				defaultDate: 'today',
				dateFormat: 'Y-m-d',
				minDate: 'today',
				onChange: function (selectedDates, dateStr, instance) {
					setStartDateValue(dateStr);
					setEndDateValue(dateStr)
				},
			});
		}

		return () => {
			if (startDatepickerInstance) {
				startDatepickerInstance.destroy();
			}
		}
	}, [])

	useEffect(() => {
		let endDatepickerInstance;

		if (endDatePickerRef.current) {
			endDatepickerInstance = flatpickr(endDatePickerRef.current, {
				defaultDate: startDateValue,
				dateFormat: 'Y-m-d',
				minDate: startDateValue,
				onChange: function (selectedDates, dateStr, instance) {
					setEndDateValue(dateStr);
				},
			});
		}
		return () => {
			if (endDatepickerInstance) {
				endDatepickerInstance.destroy();
			}
		}
	}, [isChecked, startDateValue])


	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const toggleCheckbox = () => {
		setIsChecked(!isChecked);
		setShowDate(!showdate)
	};


	useEffect(() => {
		if (timeOffRequestResponse?.loading) {
			setMessage(null);
			setSuccessMsg(null)
		} else if (timeOffRequestResponse?.data?.message === 'User Timeoff added successfully') {
			setSuccessMsg('Request sent Successfully')
		} else if (timeOffRequestResponse?.error) {
			const error = timeOffRequestResponse.error?.status
			const errorMessages = handleUpdateApiError(error)
			setMessage(errorMessages)
			setSuccessMsg(null)
		}
	}, [timeOffRequestResponse]);

	useEffect(() => {
		const calculatedHours = calculateTotalHours(startDateValue, endDateValue);
		setTotalHours(calculatedHours);
	}, [startDateValue, endDateValue]);

	const submitForm = (e) => {
		e.preventDefault();

		const validationMessage = validateNewTypeoFFRequestForm(
			formData,
			isChecked,
			endDateValue,
			totalHours,
			dispatch,
			navigate,
			startDateValue);

		if (validationMessage) {
			setMessage(validationMessage);
		} else {
			setMessage(null);
		}
	}

	useEffect(() => {
		setMessage(null)
		setSuccessMsg(null)
	}, [])

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar newRequest={newRequest} />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="newTimeOffRequest">

								<div className="container mt-5 sm:mt-5 md:mt-5 lg:mt-0">
									<h2 className="rubik-500 fs-30">New Time Off Request </h2>
									<p className="rubik-400 fs-18 lightBlue-color">This form must be complete by the employee and submitted for approval by the admin</p>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg px-5 py-5 md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-10 lg:px-20 bg-white">
										{/* -----------------------------------------------------------------Column One Feilds Section Start------------------------------------------- */}
										<form onSubmit={submitForm}>
											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<UserIcon />
														<input type="text" id="name"
															className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															name='name'
															defaultValue={formData.name}
															disabled
															placeholder=" " />
														<label htmlFor="name" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Name</label>
													</div>

												</div>

												<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-5 lg:col-span-4">
													<div className="relative ot_addNoteText did-floating-label-content">
														<TimeOffTypeIcon />
														<select style={{ height: '48px' }}
															className="fs-14 rounded-lg rubik-400 did-floating-select border border-gray-300 text-gray-900"
															onChange={handleChange} name='timeOfType' value={formData.timeOfType}
														>
															<option className="rubik-400 fs-18" value='Paid'>Paid</option>
															<option className="rubik-400 fs-18" value='UnPaid'>UnPaid</option>
														</select>
														<label className="did-floating-label" style={{ color: '#6B7280' }}>Time off Type</label>
													</div>
												</div>

												<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative  ot_addNoteText did-floating-label-content">
														<TimeOffTypeIcon />
														<select id="countries" style={{ height: '48px' }} onChange={handleChange} name='reason' defaultValue={formData?.reason} className="fs-14 rounded-lg rubik-400 did-floating-select border border-gray-300 text-gray-900">
															{reason?.vacation_paid && <option className="rubik-400 fs-18" value={reason.vacation_paid}>{reason.vacation_paid}</option>}
															{reason?.family_medical_emergency && <option className="rubik-400 fs-18" value={reason.family_medical_emergency}>{reason.family_medical_emergency}</option>}
															{reason?.holiday && <option className="rubik-400 fs-18" value={reason.holiday}>{reason.holiday}</option>}
															{reason?.jury_duty && <option className="rubik-400 fs-18" value={reason.jury_duty}>{reason.jury_duty}</option>}
															{reason?.personal_medical_emergency && <option className="rubik-400 fs-18" value={reason.personal_medical_emergency}>{reason.personal_medical_emergency}</option>}
															{reason?.sick_leave && <option className="rubik-400 fs-18" value={reason.sick_leave}>{reason.sick_leave}</option>}
															{reason?.vacation_paid && <option className="rubik-400 fs-18" value={reason.vacation_unpaid}>{reason.vacation_unpaid}</option>}
															{reason?.other && <option className="rubik-400 fs-18" value={reason.other}>{reason.other}</option>}
														</select>
														<label className="did-floating-label" style={{ color: '#6B7280' }}>Reason</label>
													</div>
												</div>
											</div>

											{/* -----------------------------------------------------------------Column One Feilds Section End------------------------------------------- */}

											{/* -----------------------------------------------------------------Column Two Feilds Section Start------------------------------------------- */}

											<label className="relative inline-flex items-center cursor-pointer">
												<input type="checkbox" value="" className="sr-only peer" checked={isChecked} onChange={toggleCheckbox} />
												<div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 ${isChecked ? 'dark:peer-focus:ring-blue-800 dark:bg-gray-700' : ''} peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`} ></div>
												<label htmlFor="password" className="block  text-sm font-medium text-gray-900 dark:text-white rubik-400 fs-14 ml-2">Whole Day</label>
											</label>

											<br /><br />

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="mb-2 sm:mb-4 md:mb-4 lg:mb-4 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
													<div className="relative">
														<input type="text" name="start" ref={startDatePickerRef} defaultValue={startDateValue}
															className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"

														/>
														<div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
															<CalenderIcon />
														</div>
														<label htmlFor="name" className="ml-4  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Start Date</label>
													</div>
												</div>

												{showdate ? <>
													<div className="mb-4 sm:mb-4 md:mb-4 lg:mb-4 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
														<div className="relative">
															<input type="text" readOnly ref={endDatePickerRef} value={endDateValue} name='end'
																className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"

															/>
															<div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
																<CalenderIcon />
															</div>
															<label htmlFor="name" className="ml-4  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
																End Date</label>

														</div>
													</div>

													<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">

														<div className="relative">
															<svg xmlns="http://www.w3.org/2000/svg" style={{ color: '#3A52BB' }} fill="none" strokeWidth={1.5} stroke="currentColor"
																className="absolute top-3 left-4" width="20" height="26" viewBox="0 0 24 26"
															>
																<path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
															</svg>

															<input
																className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
																name="totalHour" style={{ cursor: 'not-allowed' }} type="text" value={totalHours} readOnly
																placeholder=" " />
															<label htmlFor="name" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
																Total Hour</label>
														</div>

													</div>
												</>
													:
													<>
														<input name="totalHour" type="time" className="hidden h-12 rubik-400 fs-18 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300" placeholder="Total Hours" />
														<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
															<div className="relative btnnn">
																<ClockIcon />
																<input name="startTime" id="start-date" type="time"
																	className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
																	onChange={(e) => {
																		const value = e.target.value;
																		const isValidTime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
																		if (isValidTime) {
																			const [hours, minutes] = value.split(':');
																			const parsedHours = parseInt(hours, 10);
																			const period = parsedHours >= 12 ? 'PM' : 'AM';
																			setFormData({
																				...formData,
																				startTime: value,
																				startTimeAmPm: period,
																			});
																		}
																	}}
																	value={formData.startTime}
																	placeholder=" " />

																<div className="close-icon" id="close-start-date" onClick={() => {
																	setFormData({
																		...formData,
																		startTime: '',
																		startTimeAmPm: '',
																	});
																}}>X</div>

																<label htmlFor="name" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
																	Start Time</label>
															</div>

														</div>

														<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4">
															<div className="relative">
																<ClockIcon />
																<input name="endTime" type="time" id="end-date"
																	className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
																				rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
																				focus:outline-none focus:ring-0 focus:border-blue-600 peer"
																	onChange={(e) => {
																		const value = e.target.value;
																		const isValidTime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
																		if (isValidTime) {
																			const [hours, minutes] = value.split(':');
																			const parsedHours = parseInt(hours, 10);
																			const period = parsedHours >= 12 ? 'PM' : 'AM';
																			setFormData({
																				...formData,
																				endTime: value,
																				endTimeAmPm: period,
																			});
																		}
																	}}
																	value={formData.endTime}
																	placeholder=" " />

																<div className="close-icon" id="close-start-date">X</div>
																<label htmlFor="name" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
																	End Time</label>
															</div>
														</div>
													</>}
											</div>

											{/* -----------------------------------------------------------------Column Two Feilds Section End------------------------------------------- */}
											{/* -----------------------------------------------------------------Rest OF the Feilds Section Start------------------------------------------- */}

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="mb-6 col-span-12">
													<div className="flex gap-2">
														<textarea id="message" onChange={handleChange} defaultValue={formData.discription} name='discription' rows="4"
															className="resize-none rubik-400 fs-16  block p-2.5 rounded-lg w-full text-sm text-gray-900 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-300" placeholder="Add a Note..."></textarea>
													</div>
												</div>

											</div>

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-2 lg:gap-x-7">

												<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
													<div className="relative">

														<div className="relative">
															<UserIcon />
															<input type="text" id="email"
																className="block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
																onChange={handleChange} defaultValue={formData.shiftCoveredByName} name='shiftCoveredByName'
																placeholder=" " />
															<label htmlFor="name" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
																Shift Covered By (Name)
															</label>
														</div>
													</div>
												</div>

												<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
													<label htmlFor="email"
														className="block mb-2 text-sm font-medium text-gray-900 dark:text-white rubik-500 fs-18 hidden"></label>
													<div className="relative w-full">
														<input id="datePickerNewRequest" name="start" type="text"
															className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
																		rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
																		focus:outline-none focus:ring-0 focus:border-blue-600 peer"
															onChange={handleChange}
															defaultValue={dateValue} disabled
															placeholder=" " />
														<div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
															<CalenderIcon />
														</div>
														<label htmlFor="name" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
																				-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
																				peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
																				peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
															Date
														</label>
													</div>
												</div>
											</div>

											{/* -----------------------------------------------------------------Rest OF the Feilds Section End------------------------------------------- */}

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
												<div className="mb-6 col-span-12">
													<p className="rubik-400 fs-16 grey-color">I understand that time off request (both PAID and UNPAID) will be honored provided my work shift is adwquately staffed during the time i have requested off and that management reserves the right to change this request within a resonable time due to an emergency.</p>
												</div>
											</div>

											{
												message !== null ?
													<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
														<div className="col-span-12">
															<Errors error={message} />
														</div>
													</div>

													: null
											}

											{
												successMsg !== null ?
													<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
														<div className="col-span-12">
															<SuccessMessage message={successMsg} />
														</div>
													</div>

													: null
											}

											<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
												<div className="col-span-12">
													<button type="submit" className="rubik-700 fs-16 h-12 text-white blueBg-color w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
														{timeOffRequestResponse?.loading ? 'Processing...' : 'Submit'}
													</button>
												</div>
											</div>

										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div >
				<Footer />
			</div >
			<ChatBox />
		</>
	)
}

export default NewRequest