import { previousCommentsReply } from '../constants/index'
import Cookies from 'js-cookie'

export const isReduxStoredCleared = !localStorage.getItem('persist:root')

export function initatedGroupId() {
	const initiatedValue = Cookies.get('initated');
	if (initiatedValue) {
		const groupId = parseInt(initiatedValue);
		return groupId;
	}
	return null
}

export function previousComentsReplyData() {
	return Cookies.get(previousCommentsReply)
}
