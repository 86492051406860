import React, { useContext, useState } from 'react';

const CanvasContext = React.createContext();

export const CanvasProvider = ({ children }) => {
  const [canvas, setCanvas] = useState(null);

  return (
    <CanvasContext.Provider value={{ canvas, setCanvas }}>
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvasContext = () => {
  const context = useContext(CanvasContext);
  if (!context) {
    throw new Error('useCanvasContext must be used within a CanvasProvider');
  }
  return context;
};
