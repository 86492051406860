import React, { useState, useEffect } from 'react'

export const CandaTimeZone = () => {

    const getCurrentTimeCanada = () => {
        const currentTime = new Date();

        const options = {
            timeZone: 'Canada/Pacific',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }

        let canadaTime = currentTime.toLocaleString('en-US', options);
        canadaTime = canadaTime.replace(', ', ' ').replace(' at ', ' , ');

        return canadaTime;
    }

    const DisplayCurrentTime = () => {
        const currentCanadaTime = getCurrentTimeCanada();
        setCurrentDateAndTime(currentCanadaTime);
    }
    const [currentDateAndTime, setCurrentDateAndTime] = useState(getCurrentTimeCanada);

    useEffect(() => {
        const interval = setInterval(DisplayCurrentTime, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <React.Fragment>
            <button style={{ width: '170px' }} className="h-12 rounded-lg text-white font-medium rounded-sm text-sm text-center inline-flex items-center justify-center" type="button">
                {currentDateAndTime}
            </button>
        </React.Fragment>
    )
}
