import {
	ADD_PATIENT_DATA_REQUEST,
	ADD_PATIENT_DATA_SUCCESS,
	ADD_PATIENT_DATA_FAILURE
} from "../../constants"

import { pharmacy_add_patient } from "../../../networking/urlEndPoints"

import * as routesNames from '../../../routes/routeName'
import Axios from "../../../networking/intraceptor"

export const addPatientDataRequest = () => ({
	type: ADD_PATIENT_DATA_REQUEST,
})

export const addPatientDataSuccess = (data) => ({
	type: ADD_PATIENT_DATA_SUCCESS,
	payload: data,
})

export const addPatientDataFailure = (error) => ({
	type: ADD_PATIENT_DATA_FAILURE,
	payload: error,
})

export const addPatientData = (patientFormData, pateintAddress, navigate, location) => {
	return async (dispatch) => {
		dispatch(addPatientDataRequest())
		try {
			const response = await Axios.post(`${pharmacy_add_patient}`, {
				phn: patientFormData.phn,
				first_name: patientFormData.firstName,
				last_name: patientFormData.lastName,
				dob: patientFormData.dob,
				gender: patientFormData.gender,
				phone: patientFormData.phone,
				route: patientFormData.route,
				city: pateintAddress.city,
				post_code: pateintAddress.postalCode,
				province: pateintAddress.province,
				address: pateintAddress.address,
				email: patientFormData.email,
				delivey_note: null,
				full_address: pateintAddress.fullAddress,
				country: pateintAddress.country,
				map_url: pateintAddress.mapUrl,
				lat: pateintAddress.lat,
				lng: pateintAddress.long,
				role: location.pathname === routesNames.pharmacy_patient_blister_pack ? 'blister' :
					location.pathname === routesNames.pharmacy_add_vials_patient ? 'vials' :
						location.pathname === routesNames.pharmacy_add_medication_patients ? 'medication' :
							location.pathname === routesNames.pharmacy_add_travel_patient ? 'travel' :
								location.pathname === routesNames.pharmacy_add_patient_route ? 'oat' :
									null
			})
			if (response?.status === 200) {
				dispatch(addPatientDataSuccess(response?.data))

				if (location.pathname === routesNames.pharmacy_patient_blister_pack) {
					setTimeout(() => {
						navigate(routesNames.pharmacy_blister_patients)
					}, 1000)
				} else if (location.pathname === routesNames.pharmacy_add_vials_patient) {
					setTimeout(() => {
						navigate(routesNames.pharmacy_patient_vials_pack)
					}, 1000)
				} else if (location.pathname === routesNames.pharmacy_add_medication_patients) {
					setTimeout(() => {
						navigate(routesNames.pharmacy_speciality_pack)
					}, 1000)
				} else if (location.pathname === routesNames.pharmacy_add_patient_route) {
					setTimeout(() => {
						navigate(routesNames.pharmacy_patient_route)
					}, 1000)
				} else if (location.pathname === routesNames.pharmacy_add_travel_patient) {
					setTimeout(() => {
						navigate(routesNames.pharmacy_add_travel_pack)
					}, 1000)
				}
				else {
					setTimeout(() => {
						navigate(`${routesNames.ALL_PATIENTS}?status=1`)
					}, 1000)
				}
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(addPatientDataFailure(error?.response))
			}
		}
	}
}
