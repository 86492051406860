import React, { useState } from 'react'

import { initalExpiringRxFormData } from '../../../utils/constants/formInitialStates'
import { counsentSubmissionHeader } from '../../../constants/dispense'

import { FetchCounsentSubmissionList } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'

import { counsellingFetchImages, pharmacyConsentSubmissionViewPdf } from '../../../services/actions/pharmacy/dispense/addDispenseData'
import { ErrorToast } from '../../../components/loaders/toastMessage'

import * as Common from '../../../components/common'
import * as Dispense from '../../../components/pharmacy/dispense/dispense'
import * as Hooks from '../../../hooks'
import * as routesNames from '../../../routes/routeName'

import ChatBox from '../../../components/chat/chatbox'

const TableRowData = ({ value, className1 = 'p-3', }) => {
    return (<td  className={className1}>
        <p>{value}</p>
    </td>)
}

const ConsentSubmissions = () => {

    const location = Hooks.useCustomLocation()
    const dispatch = Hooks.useCustomDispatch()
    const navigate = Hooks.useCustomNavigate()

    const [formData, setFormData] = React.useState(initalExpiringRxFormData);
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    const [pageNumber, setPageNumber] = React.useState(initialPage)

    const [overlayImage, setOverlayImage] = useState(null)
    const [index, setIndex] = useState(null)
    const [documentImgIndex, setdocumentImgIndex] = useState(null)
    const [showToast, setShowToast] = useState(false)
    const [pdfindex, setpdfindex] = useState('')
    const { dispenseListDataResponse, addDispenseDataResponse } = useDispenseSelectors()
    const counsentSubmissionsLists = dispenseListDataResponse?.data?.data

    React.useEffect(() => {
        setPageNumber(initialPage)
    }, [initialPage])

    React.useEffect(() => {
        if (formData?.date) {
            const params = {
                date: formData?.date,
                search: formData?.search,
                page: pageNumber,
            }
            dispatch(FetchCounsentSubmissionList(params, 'counsent-submission-list'))
        }
        return () => { }

    }, [])


    const handleFilterData = (event, value) => {
        const params = {
            date: formData?.date,
            page: value,
            search: formData.search,
        }
        const searchParams = new URLSearchParams()

        if (value !== undefined) {
            searchParams.append('page', value);
            setPageNumber(value)
        }

        if (formData.search || value) dispatch(FetchCounsentSubmissionList(params, 'counsent-submission-list'))
        const newUrl = `${routesNames?.pharmacy_consent_form_logs}?${searchParams.toString()}`
        navigate(newUrl);
    }

    const handleShowImage = (id, type, idx) => {
        const requestData = {
            patientConsentFormId: id,
            imageType: type
        }
        if (type === 'profile_img') {
            setIndex(idx)
            setdocumentImgIndex(null)
        }

        if (type === 'document_img') {
            setdocumentImgIndex(idx)
            setIndex(null)
        }

        dispatch(counsellingFetchImages('counselling_fetch_image', requestData, setOverlayImage, setShowToast))
    }

    const ViewPdf = (url, idx) => {
        setIndex('')
        setpdfindex(idx)
        dispatch(pharmacyConsentSubmissionViewPdf(url, 'concent_pdf_view', setOverlayImage))
    }


    const closeOverlay = () => {
        document.getElementById('overlay').style.display = 'none';
        setOverlayImage(null)
    }

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
													sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">
                                            <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 w-full'>
                                                <Dispense.TextInput label='Search' name='search' formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex-0 lg:w-1/12 md:w-2/12 sm:w-4/12 w-full'>
                                                <button type="button" onClick={handleFilterData} style={{ backgroundColor: '#3B82F6' }} className="flex text-white ml-auto w-full h-full pl-6 py-4 items-center justify-center border border-gray-300 	
														focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                                                </button>
                                            </div>
                                        </div>

                                        {dispenseListDataResponse?.loading && dispenseListDataResponse?.type === 'counsent-submission-list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            dispenseListDataResponse?.error !== null && dispenseListDataResponse?.type === 'counsent-submission-list' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4  bg-red-600 text-white rounded hover:bg-red-700 
																	focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                counsentSubmissionsLists?.data?.length === 0 ?
                                                    <div className="bg-white pt-4 rounded-md">
                                                        <p className="text-gray-500">No Record Available.</p>
                                                    </div>
                                                    :
                                                    dispenseListDataResponse?.type === 'counsent-submission-list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative overflow-x-auto">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className="text-xs text-gray-900 uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400"
                                                                        style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {counsentSubmissionHeader?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            counsentSubmissionsLists?.data?.map((data, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: `${'1px solid lightgray'}` }}>

                                                                                        <TableRowData value={data.date} />
                                                                                        <TableRowData value={data.phn} />
                                                                                        <TableRowData value={data.patient} />
                                                                                        <TableRowData value={data.dob} />

                                                                                        <td  className="p-3 btnnn" >
                                                                                            {data?.profile_img ?
                                                                                                <p style={{ background: 'orange' }} className="rubik-400 fs-14 bg-red-500 w-24 fs-12 text-center text-white rounded-full" onClick={() => handleShowImage(data?.id, 'profile_img', idx)}>
                                                                                                    {addDispenseDataResponse?.loading && idx === index ? 'Fetching...' : 'View'}
                                                                                                </p> :
                                                                                                <p>Not Available</p>}
                                                                                        </td>

                                                                                        <td  className="p-3 btnnn" >
                                                                                            {data?.document_img ?
                                                                                                <p className="rubik-400 fs-14 bg-blue-500 w-24 fs-10 text-center text-white rounded-full" onClick={() => handleShowImage(data?.id, 'document_img', idx)}>
                                                                                                    {addDispenseDataResponse?.loading && idx === documentImgIndex ? 'Fetching...' : 'View'}
                                                                                                </p> : <p>Not Available</p>}
                                                                                        </td>

                                                                                        <td  className="p-3 btnnn" >
                                                                                            {data?.pdf_url ?
                                                                                                <p className="rubik-400 fs-14 bg-red-500 w-24 fs-10 text-center text-white rounded-full" onClick={() => ViewPdf(data?.pdf_url, idx)}>
                                                                                                    {addDispenseDataResponse?.loading && idx === pdfindex ? 'Processing...' : 'View PDF'}
                                                                                                </p> : <p>Not Available</p>}
                                                                                        </td>
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                                {dispenseListDataResponse?.error !== null ? null : <>
                                                                    {counsentSubmissionsLists?.data?.length !== 0 && <>
                                                                        {counsentSubmissionsLists?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={counsentSubmissionsLists?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={(event, value) => {
                                                                                                handleFilterData(event, value)
                                                                                            }}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}

                                                            </div>
                                                        </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id='overlay' style={{ zIndex: '9999999999' }}>
                <span id="close-btn" onClick={closeOverlay}>&times;</span>
                {overlayImage && <img src={overlayImage} className="rounded-full" alt="Large Image" />}
            </div>

            {showToast &&
                <ErrorToast status={'Oops! Got an issue while fetching Image.please try again'} />}

            <ChatBox />
            <Common.Footer />
        </>

    )
}

export default ConsentSubmissions