import React, {
    useState,
    useEffect
} from 'react'

import {
    UpdateBlisterPack,
    UpdateBlisterDailyPack,
    UpdateBlisterPAckOnShelf,
    UpdateVialsPack,
    UpdateDailyVailsPack,
    UpdateMedicationPack,
    UpdateDailyMedicationPack,
} from '../../services/actions/pharmacy/blisters/addBlisterPackData';

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'

import { TextInput } from '../../components/pharmacy/dispense/dispense';
import { useBlisterSelectors } from '../../services/selectors/blisterSelectors';

import * as Hooks from '../../hooks'
import * as routeName from '../../routes/routeName'

const UpdateBlisterlist = ({ setShowModal, showModal, patientProfileData, setShowToast, paginationNumber }) => {

    const { AddBlisterPackResponse } = useBlisterSelectors()
    const dispatch = Hooks.useCustomDispatch()

    const location = Hooks.useCustomLocation()
    const isBlisterDailyPacks = location.pathname === routeName.pharmacy_blister_daily_packs;
    const isBlisterPacksOnShelf = location.pathname === routeName.pharmacy_blister_pack_on_shelf;

    const isVialsPack = location.pathname === routeName.pharmacy_vials_packs
    const isDailyVialsPack = location.pathname === routeName.pharmacy_dailyvials_packs

    const isSpecialityMedicationPack = location.pathname === routeName.pharmacy_speciality_medication_packs

    const isDailyMedicationPack = location.pathname === routeName.pharmacy_dailymedication_packs

    const [formData, setFormData] = useState({
        route: '',
        facility: '',
        processingDate: '',
        deductible: '',
        no_of_rx_need_to_bill: '',
        note: '',
        prescription_billed: '',
        no_of_blister_pack: ''
    });

    useEffect(() => {
        setFormData({
            ...formData,
            route: isBlisterDailyPacks || isBlisterPacksOnShelf ? patientProfileData?.blister_route || '' : isDailyVialsPack ? patientProfileData?.vials_route :
                isDailyMedicationPack ? patientProfileData.medication_route : patientProfileData?.route || '',

            facility: patientProfileData?.facility || '',
            processingDate: patientProfileData?.p_date || '',
            deductible: patientProfileData?.deductible || '',
            no_of_rx_need_to_bill: isBlisterDailyPacks || isDailyVialsPack ? patientProfileData?.rx_bill || '' : patientProfileData?.bill || '',
            note: patientProfileData?.notes || '',
            prescription_billed: patientProfileData.pres_bill,
            no_of_blister_pack: patientProfileData?.no_of_blister_pack || ''
        });
    }, [patientProfileData]);

    const handleUpdatePackDetails = () => {

        if (isBlisterDailyPacks) {
            dispatch(UpdateBlisterDailyPack('update_blister_daily_pack', formData, patientProfileData.id, setShowModal, setShowToast, paginationNumber))
        }
        else if (isBlisterPacksOnShelf) {
            dispatch(UpdateBlisterPAckOnShelf('update_blister_packs_on_shelf', formData, patientProfileData.id, setShowModal, setShowToast, paginationNumber))
        }

        else if (isVialsPack) {
            dispatch(UpdateVialsPack('update_vials_pack', formData, patientProfileData.id, setShowModal, setShowToast, paginationNumber))
        }

        else if (isDailyVialsPack) {
            dispatch(UpdateDailyVailsPack('update_daily_vials_pack', formData, patientProfileData.id, setShowModal, setShowToast, paginationNumber))
        }

        else if (isSpecialityMedicationPack) {
            dispatch(UpdateMedicationPack('update_medication_pack', formData, patientProfileData.id, setShowModal, setShowToast, paginationNumber))
        }

        else if (isDailyMedicationPack) {
            dispatch(UpdateDailyMedicationPack('update_daily_medication_pack', formData, patientProfileData.id, setShowModal, setShowToast, paginationNumber))
        }

        else {
            dispatch(UpdateBlisterPack('update_blister_pack', formData, patientProfileData.id, setShowModal, setShowToast, paginationNumber))
        }
    }

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" >
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Update {((isVialsPack) || (isDailyVialsPack)) ? 'Vials' :
                                    ((isSpecialityMedicationPack) || (isDailyMedicationPack)) ? 'Speciality Medication' :

                                        'Blister'} Pack For : {patientProfileData?.patient_name || patientProfileData?.name}
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'updateBlisterlist')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <form>
                                <div className={`grid ${(isBlisterDailyPacks) || (isBlisterPacksOnShelf) || (isDailyVialsPack) || (isDailyMedicationPack) ? "grid-cols-2" : "grid-cols-3"} gap-2`}>
                                    <div className='w-34'>
                                        <TextInput label='ROUTE' name='route' formData={formData}
                                            setFormData={setFormData} />
                                    </div>

                                    <div className='w-34'>
                                        <TextInput label='FACILITY' name='facility' formData={formData}
                                            setFormData={setFormData} />
                                    </div>

                                    {(isBlisterDailyPacks) || (isBlisterPacksOnShelf || (isDailyVialsPack) || (isDailyMedicationPack)) ? null :
                                        <div className='w-34'>
                                            <div className="relative">
                                                <input
                                                    style={{ height: '42px' }}
                                                    type="date"
                                                    id="text1"
                                                    name="date"
                                                    onChange={(e) => setFormData({ ...formData, processingDate: e.target.value })}
                                                    value={formData?.processingDate}
                                                    className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                    placeholder=" "
                                                />
                                                <label
                                                    htmlFor="text1"
                                                    className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                                                >
                                                    Processing Date
                                                </label>
                                            </div>
                                        </div>}

                                </div>

                                {location.pathname === routeName.pharmacy_blister_pack_on_shelf ?
                                    <div className={`grid grid-cols-1 gap-2 mt-2 mb-2`}>
                                        <div className='w-full'>
                                            <TextInput label='NUMBER OF BLISTER PACK' name='no_of_blister_pack' formData={formData}
                                                setFormData={setFormData} />
                                        </div>
                                    </div>
                                    :
                                    <div className={`grid ${((isBlisterDailyPacks) || (isDailyVialsPack) || (isDailyMedicationPack)) ? "grid-cols-3" : "grid-cols-2"} gap-2 mt-2 mb-2`}>
                                        <div className='w-full'>
                                            <TextInput label='DEDUCTIBLE' name='deductible' formData={formData}
                                                setFormData={setFormData} />
                                        </div>

                                        <div className='w-full'>
                                            <TextInput label='NO OF RX NEED TO BILL' name='no_of_rx_need_to_bill' formData={formData}
                                                setFormData={setFormData} />
                                        </div>

                                        <div className='w-full'>
                                            {((isBlisterDailyPacks) || (isDailyVialsPack) || (isDailyMedicationPack)) && <div className='w-full'>
                                                <TextInput label='PRESCRIPTION BILLED' name='prescription_billed' formData={formData}
                                                    setFormData={setFormData} />
                                            </div>}
                                        </div>
                                    </div>}

                                <div className='grid grid-cols-1 gap-2 mt-2 mb-2'>
                                    <div className="relative" >
                                        <textarea
                                            type="text"
                                            id={"note"}
                                            name={"note"}
                                            value={formData.note}
                                            onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                                            className="block pl-4 px-2.5 pb-2.5 pt w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                            placeholder=""
                                        />
                                        <label
                                            htmlFor={'note'}
                                            className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                        >
                                            Note
                                        </label>
                                    </div>
                                </div>

                                <div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
                                    <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
                                        <div className="col-span-12">
                                            <button type="button" onClick={handleUpdatePackDetails}
                                                className={`rubik-500 flex justify-center items-center text-white blueBg-color   w-full text-sm px-5 py-2.5 text-center`}>
                                                {AddBlisterPackResponse?.loading ? 'Updating...' : 'Update'}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default UpdateBlisterlist