import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

const ChatLoader = () => {
	return (
		<RotatingLines
			strokeColor="#282828"
			strokeWidth="5"
			animationDuration="1.75"
			width="20"
			visible={true}
		/>
	)
}

export default ChatLoader