import React, { useEffect, useState } from 'react'

import {
    BreadCrumb,
    Footer,
    Sidebar
} from '../../../components/common'

import { Errors } from '../../../components/error/alertMessages'
import { useCustomDispatch } from '../../../hooks'
import { initalSkinTestFormData } from '../../../utils/constants/formInitialStates'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'

import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'
import { DropdownSelect, TextInput } from '../../../components/pharmacy/dispense/dispense'
import { onhandleTBSkinTestFOrmValidate } from '../../../utils/validations/formsValidations'

import ChatBox from '../../../components/chat/chatbox'

import * as Pharmacy from '../../../components/pharmacy/form/form'

const TBSkinMantouxForm = () => {

    const dispatch = useCustomDispatch()
    const { previewPdfResponse } = useFormsSelectors()

    const initialErrorSkinTestForm = {
        ...initalSkinTestFormData,
        date: '',
        test_information_date: '',
        date_of_interpretation: ''
    };

    const [formData, setFormData] = useState(initalSkinTestFormData)
    const [errorMessages, setErrorMessages] = useState(initialErrorSkinTestForm)

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        dispatch(previewPdfFailure(null))
    }, [])

    const handleTBSkinTestFOrmValidate = () => {
        onhandleTBSkinTestFOrmValidate(formData, errorMessages, setErrorMessages, dispatch)
    }

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="timeSheets">

                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="grid grid-cols-3 gap-4">
                                            <div>
                                                <Pharmacy.Date
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                />
                                                {errorMessages.date && <p className='text-red-500 fs-12'>{errorMessages.date}</p>}
                                            </div>
                                            <div className="flex">
                                                <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                    <Pharmacy.FormWinRxPatients
                                                        formData={formData}
                                                        errorMessages={errorMessages}
                                                        setErrorMessages={setErrorMessages}
                                                        setFormData={setFormData}
                                                        handleFormDataChange={handleFormDataChange}
                                                    />
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-4 mt-5 gap-4">
                                            <div>
                                                <Pharmacy.PatientPhn
                                                    handleFormDataChange={handleFormDataChange}
                                                    formData={formData}
                                                    errorMessages={errorMessages}
                                                    setErrorMessages={setErrorMessages}
                                                    setFormData={setFormData}
                                                />
                                            </div>
                                            <div>
                                                <Pharmacy.PatientName
                                                    handleFormDataChange={handleFormDataChange}
                                                    formData={formData}
                                                    errorMessages={errorMessages}
                                                    setFormData={setFormData}
                                                    setErrorMessages={setErrorMessages}
                                                />
                                            </div>
                                            <div>
                                                <Pharmacy.PatientDob
                                                    handleFormDataChange={handleFormDataChange}
                                                    formData={formData}
                                                    errorMessages={errorMessages}
                                                    setFormData={setFormData}
                                                    setErrorMessages={setErrorMessages}
                                                />
                                            </div>

                                            <div>
                                                <Pharmacy.PatientPhone
                                                    handleFormDataChange={handleFormDataChange}
                                                    formData={formData}
                                                    errorMessages={errorMessages}
                                                    setFormData={setFormData}
                                                    setErrorMessages={setErrorMessages}
                                                    label='Phone'
                                                />
                                            </div>
                                        </div>

                                        <div className='border p-4 rounded-md mt-4'>
                                            <h1 className='text-gray-500 rubik-500'>TEST INFORMATION</h1>
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 gap-4 mt-2">

                                                <div>
                                                    <Pharmacy.Date
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        name='test_information_date'
                                                        dValue={formData.test_information_date}
                                                    />
                                                    {errorMessages.test_information_date && <p className='text-red-500 fs-12'>{errorMessages.test_information_date}</p>}
                                                </div>

                                                <div className="relative">
                                                    <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                        <li className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                                            <div className="flex items-center ps-3">
                                                                <input id="right" onChange={handleFormDataChange} name='site' value="Right" type="radio" className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                                <label htmlFor="right" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Right </label>
                                                            </div>
                                                        </li>
                                                        <li className="ml-4 w-full">
                                                            <div className="flex items-center ps-3">
                                                                <input id="left"
                                                                    onChange={handleFormDataChange} name='site' value="Left"
                                                                    type="radio" className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                                <label htmlFor="left" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Left</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <label htmlFor="Gender" className="ml-3 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                                        SITE
                                                    </label>
                                                    {errorMessages.site && <p className='text-red-500 fs-12'>{errorMessages.site}</p>}
                                                </div>

                                                <div>
                                                    <TextInput label='Lot Number'
                                                        textType='text'
                                                        name='lot_number' formData={formData}
                                                        height="48px"
                                                        setFormData={setFormData} />

                                                    {errorMessages.lot_number && <p className='text-red-500 fs-12'>{errorMessages.lot_number}</p>}
                                                </div>

                                                <div>
                                                    <Pharmacy.Date
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        label='Expiration Date'
                                                        name='expiration_date'
                                                        dValue={formData.expiration_date}
                                                    />
                                                    {errorMessages.expiration_date && <p className='text-red-500 fs-12'>{errorMessages.expiration_date}</p>}
                                                </div>

                                                <DropdownSelect
                                                    label="Administered By"
                                                    name="administered_by"
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    options={[
                                                        { value: "RN", label: "RN" },
                                                        { value: "MD", label: "MD" },
                                                        { value: "Pharmacist", label: "PHARMACIST" },
                                                    ]}
                                                />
                                            </div>

                                        </div>


                                        <div className='border p-4 rounded-md mt-4'>
                                            <h1 className=' text-gray-500 rubik-500'>TEST RESULT</h1>
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-2">

                                                <div>
                                                    <div className=''>
                                                        <Pharmacy.Date
                                                            formData={formData}
                                                            setFormData={setFormData}
                                                            label='Date'
                                                            name='test_result_date'
                                                            dValue={formData.test_result_date}
                                                        />
                                                    </div>
                                                    {errorMessages.test_result_date && <p className='text-red-500 fs-12'>{errorMessages.test_result_date}</p>}
                                                </div>

                                                <div>
                                                    <TextInput label='Induration (in mm)' name='induration' formData={formData}
                                                        height="48px"
                                                        setFormData={setFormData}
                                                        textType='number' />
                                                    {errorMessages.induration && <p className='text-red-500 fs-12'>{errorMessages.induration}</p>}
                                                </div>

                                                <div className="relative">
                                                    <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                        <li className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                                            <div className="flex items-center ps-3">
                                                                <input id="negative" onChange={handleFormDataChange} name='ppd_mantoux_test_result' value="Negative" type="radio" className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                                <label htmlFor="negative" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Negative </label>
                                                            </div>
                                                        </li>
                                                        <li className="ml-4 w-full">
                                                            <div className="flex items-center ps-3">
                                                                <input id="positive"
                                                                    onChange={handleFormDataChange} name='ppd_mantoux_test_result' value="Positive"
                                                                    type="radio" className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                                <label htmlFor="positive" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Postive</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <label htmlFor="Gender" className="ml-3  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                                        PPD (MANTOUX) TEST RESULT
                                                    </label>
                                                    {errorMessages.ppd_mantoux_test_result && <p className='text-red-500 fs-12'>{errorMessages.ppd_mantoux_test_result}</p>}
                                                </div>


                                            </div>
                                        </div>

                                        <div className='border p-4 rounded-md mt-4'>
                                            <h1 className=' text-gray-500 rubik-500 uppercase'>Interpretation and Follow-up</h1>
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-2">

                                                <div>
                                                    <TextInput label='Interpreted by' name='interpreted_by' formData={formData}
                                                        height="48px"
                                                        setFormData={setFormData} />
                                                    {errorMessages.interpreted_by && <p className='text-red-500 fs-12'>{errorMessages.interpreted_by}</p>}
                                                </div>

                                                <div>
                                                    <Pharmacy.Date
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        label='Date of Interpretation'
                                                        name='date_of_interpretation'
                                                        dValue={formData.date_of_interpretation}
                                                    />
                                                    {errorMessages.date_of_interpretation && <p className='text-red-500 fs-12'>{errorMessages.date_of_interpretation}</p>}
                                                </div>

                                                <div className="relative">
                                                    <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                        <li className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                                            <div className="flex items-center ps-3">
                                                                <input id="yes" onChange={handleFormDataChange} name='follow_up' value="Yes" type="radio" className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                                <label htmlFor="yes" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">YES </label>
                                                            </div>
                                                        </li>
                                                        <li className="ml-4 w-full">
                                                            <div className="flex items-center ps-3">
                                                                <input id="no"
                                                                    onChange={handleFormDataChange} name='follow_up' value="No"
                                                                    type="radio" className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                                <label htmlFor="no" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">NO</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <label htmlFor="Gender" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                                        Follow up
                                                    </label>
                                                    {errorMessages.follow_up && <p className='text-red-500 fs-12'>{errorMessages.follow_up}</p>}

                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">

                                                <div className="relative" >
                                                    <textarea
                                                        type="text"
                                                        id={"comments"}
                                                        name={"comments"}
                                                        value={formData.comments}
                                                        onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
                                                        className="block pl-4 px-2.5 pb-2.5 pt w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                                        placeholder=""
                                                    />
                                                    <label
                                                        htmlFor={'comments'}
                                                        className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                    >
                                                        Comments
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                        {previewPdfResponse?.error !== null &&
                                            <>
                                                <br />
                                                <Errors error={'Something went wrong when Generating PDF. Please try again'} />
                                            </>
                                        }
                                        <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
                                            <button style={{ height: '40px' }} type="button" onClick={handleTBSkinTestFOrmValidate}
                                                className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex
                                                                 justify-center items-center">

                                                {previewPdfResponse?.loading ? 'Generating PDF...' : 'Generate PDF'}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ChatBox />
            <Footer />


        </>
    )
}

export default TBSkinMantouxForm