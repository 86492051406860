import React, {
	useState,
	useEffect
} from "react"

import { useCustomSelectors } from "../../../services/selectors/allSelectors"
import { postStyle } from "../../../style"

export const CapturePostData = ({ postWallContent, postWallImage, postWallVideo }) => {

	const { profileData } = useCustomSelectors()

	const [dots, setDots] = useState('.')

	useEffect(() => {
		const timer = setInterval(() => {
			setDots((prevDots) => (prevDots.length < 4 ? prevDots + '.' : '.'));
		}, 100);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<React.Fragment>
			<div className="mt-2 mb-4 w-full border rounded-lg bg-white shadow">
				<div className="flex gap-3 p-4">
					<img className="w-10 h-10 rounded-full border" src={profileData.photo} alt="Rounded avatar" />

					<div>
						<h3 className="rubik-500 fs-18 dark-color">{profileData.name} </h3>
						<p className="items-center flex gap-2 rubik-400 fs-14 grey-color">Posting{dots}</p>
					</div>
				</div>

				<div className="flex items-end p-4 pt-0">
					<p className="rubik-400 fs-18 dark-color"
						dangerouslySetInnerHTML={{
							__html:
								postWallContent ? postWallContent : null
						}} style={postStyle}
					></p>
				</div>

				<div className={`px-4 pb-8`}>
					<div className="max-w-screen-2xl mx-auto relative">
						<div className="flex flex-col md:flex-row gap-2">
							{postWallImage?.length >= 6 && postWallImage?.length <= 50 ? <>
								<div className="flex flex-1 flex-col gap-2">
									<div className="flex flex-1 flex-col">
										<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[0])} />
									</div>
									<div className="hidden md:flex flex-1 flex-row gap-2">
										<div className="flex flex-1 flex-col">
											<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[1])} />
										</div>
										<div className="hidden md:flex flex-1 flex-col">
											<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[2])} />
										</div>
									</div>
								</div>

								<div className="flex flex-1 flex-col gap-2">
									<div className="hidden md:flex flex-1 flex-row gap-2">
										<div className="flex flex-1 flex-col">
											<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[3])} />
										</div>
										<div className="hidden md:flex flex-1 flex-col">
											<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[4])} />
										</div>
									</div>
									<div className="flex flex-1 flex-col relative">
										<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[5])} />
										{postWallImage?.length > 6 &&
											<span className="absolute fs-30 dark-color rubik-500 grid place-items-center h-full w-full ">+{postWallImage?.length - 6}</span>}
									</div>
								</div>
							</>

								: postWallImage?.length === 5 ?

									<>
										<div className="flex flex-1 flex-col gap-2">
											<div className="flex flex-1 flex-col">
												<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[0])} />
											</div>
											<div className="hidden md:flex flex-1 flex-row gap-2">
												<div className="flex flex-1 flex-col">
													<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[1])} />
												</div>
												<div className="hidden md:flex flex-1 flex-col">
													<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[2])} />
												</div>
											</div>
										</div>

										<div className="flex flex-1 flex-col gap-2">
											<div className="hidden md:flex flex-1 flex-row gap-2">
												<div className="flex flex-1 flex-col">
													<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[3])} />
												</div>
												<div className="hidden md:flex flex-1 flex-col">
													<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[4])} />
												</div>
											</div>
										</div>
									</>

									: postWallImage?.length === 4 ?

										<>
											<div className="flex flex-1 flex-col gap-2">
												<div className="flex flex-1 flex-col">
													<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[0])} />
												</div>
												<div className="hidden md:flex flex-1 flex-row gap-2">
													<div className="flex flex-1 flex-col">
														<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[1])} />
													</div>
													<div className="hidden md:flex flex-1 flex-col">
														<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[2])} />
													</div>
												</div>
											</div>

											<div className="flex flex-1 flex-col gap-2">
												<div className="hidden md:flex flex-1 flex-row gap-2">
													<div className="flex flex-1 flex-col">
														<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[3])} />
													</div>
												</div>
											</div>
										</>

										: postWallImage?.length === 3 ?

											<>
												<div className="flex flex-1 flex-col gap-2">
													<div className="flex flex-1 flex-col">
														<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[0])} />
													</div>
													<div className="hidden md:flex flex-1 flex-row gap-2">
														<div className="flex flex-1 flex-col">
															<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[1])} />
														</div>
														<div className="hidden md:flex flex-1 flex-col">
															<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[2])} />
														</div>
													</div>
												</div>
											</>

											: postWallImage?.length === 2 ?
												<>
													<div className="flex flex-1 flex-col gap-2">
														<div className="flex flex-1 flex-col">
															<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[0])} />
														</div>
														<div className="hidden md:flex flex-1 flex-row gap-2">
															<div className="flex flex-1 flex-col">
																<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[1])} />
															</div>
														</div>
													</div>
												</>

												: postWallImage?.length === 1 ?
													<>
														<div className="flex flex-1 flex-col gap-2">
															<div className="flex flex-1 flex-col">
																<img className="object-cover h-full" src={URL.createObjectURL(postWallImage[0])} />
															</div>
														</div>
													</>
													: null}

						</div>
					</div>
				</div>

				{
					postWallVideo?.length === 0 ? null :
						<div className="p-4 pt-2">
							<video controls className='w-full rounded-md' style={{ height: '474px' }}>
								<source src={URL.createObjectURL(postWallVideo)} type="video/mp4" />
							</video>
						</div>
				}

			</div>
		</React.Fragment>)
}