import React, { useRef, useState } from "react";
import { useCustomDispatch } from "../../hooks";
import * as useEffectHelper from "../../utils/helper/useEffectsHelper";
import { handleHideModal } from "../../assets/js/custome";
import { ModalCloseIcon } from "../../assets/svg/icons";
import { handleDeleteARV } from "../../services/actions/pharmacy/arv/patientARVDelete";
import { useCustomPatientSelectors } from "../../services/selectors/patientSelectors";
import * as Pharmacy from "../../components/pharmacy/form/form";
import { onhandlePharmacyFormDataChange } from "../../utils/data/handleFormDataChange";
import { useFormsSelectors } from '../../services/selectors/formsSelector'
import { Loader } from '../../components/common/loadingView'
import { Errors } from '../../components/error/alertMessages'
import { SuccessToastMessage } from '../../components/loaders/toastMessage'
import {
    initalPharmacyInformationUpdateForm,
    initalShowToastMessage,
} from "../../utils/constants/formInitialStates";
import { TextInput } from "../../components/pharmacy/dispense/dispense";
import { handleARVFaxValidation } from "../../utils/validations/arvValidations";
import { useCustomSelectors } from "../../services/selectors/allSelectors";
export const DeleteARVModalPopUp = ({
    setShowModal,
    showModal,
    arvID,
    formData,
    pageNumber,
}) => {
    const modalRef = useRef(null);
    useEffectHelper.useRemoveModalEffect(
        setShowModal,
        showModal,
        "DeleteARVModal",
        modalRef
    );
    const dispatch = useCustomDispatch();
    const { deleteARVResponse } = useCustomPatientSelectors();

    return (
        <>
            {showModal.DeleteARVModal && (
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                >
                    <div ref={modalRef} className="relative w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                onClick={() =>
                                    handleHideModal(setShowModal, showModal, "DeleteARVModal")
                                }
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                <ModalCloseIcon />
                            </button>

                            <div className="p-6 text-center pt-12">
                                <p className="rubik-500 text-gray-500">Are you sure?</p>
                                <p className="rubik-400 text-gray-500">
                                    You want to delete this ARV?
                                </p>
                                <br />

                                <button
                                    onClick={() =>
                                        dispatch(
                                            handleDeleteARV(
                                                arvID,
                                                "DeleteARVModal",
                                                formData,
                                                pageNumber,
                                                setShowModal,
                                                showModal
                                            )
                                        )
                                    }
                                    style={{ backgroundColor: "#3A52BB" }}
                                    type="button"
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none 
												focus:ring-red-300 
                    							dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                >
                                    {deleteARVResponse?.loading ? "Processing..." : "Yes"}
                                </button>

                                <button
                                    onClick={() =>
                                        handleHideModal(setShowModal, showModal, "DeleteARVModal")
                                    }
                                    type="button"
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                								border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                								dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export const ARVSendFaxPopUp = ({ setShowModal, showModal, arvID, patientType }) => {
    
    const { previewPdfResponse, sendFaxResponse } = useFormsSelectors()
    const modalRef = useRef(null);
    const [formData, setFormData] = useState(initalPharmacyInformationUpdateForm);
    const [errorMessages, setErrorMessages] = useState(
        initalPharmacyInformationUpdateForm
    );
    const [showToast, setShowToast] = useState(initalShowToastMessage);
    const handleFormDataChange = (e) => {
        onhandlePharmacyFormDataChange(
            e,
            setFormData,
            formData,
            setErrorMessages,
            errorMessages
        );
    };
    useEffectHelper.useRemoveModalEffect(
        setShowModal,
        showModal,
        "SendFaxModal",
        modalRef
    );
    const dispatch = useCustomDispatch();

    const { pharmacyLoginData } = useCustomSelectors()


    const medicationInputFeilds = [
        {
            id: "numberOfDaysSupplyRequested",
            label: "No. Of days supply requested",
            name: "numberOfDaysSupplyRequested",
            value: formData.numberOfDaysSupplyRequested,
            errorMessage: errorMessages.numberOfDaysSupplyRequested,
        },
    ];

    return (
        <>
            <div>
                {showModal.SendFaxModal && (
                    <div
                        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                    >
                        <div ref={modalRef} className="relative w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className=" justify-center align-items-center p-5 mt-5">
                                    <div className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Send Fax
                                    </div>

                                    <div className="relative ot_addNoteText ">
                                        <div className="relative did-floating-label-content">
                                            <Pharmacy.ClinicName
                                                formData={formData}
                                                errorMessages={errorMessages}
                                                setFormData={setFormData}
                                                setErrorMessages={setErrorMessages}
                                            />

                                            <Pharmacy.AddClinic />
                                        </div>
                                    </div>
                                    <div className=" ot_addNoteText  mt-10">
                                        <div className="relative did-floating-label-content">
                                            <Pharmacy.DoctorList
                                                formData={formData}
                                                errorMessages={errorMessages}
                                                setFormData={setFormData}
                                                setErrorMessages={setErrorMessages}
                                            />

                                            <Pharmacy.AddDoctor />
                                        </div>
                                    </div>
                                    <div className="mt-14 ot_addNoteText">
                                        <div className="relative did-floating-label-content">
                                            <Pharmacy.ClinicFaxNumber
                                                formData={formData}
                                                errorMessages={errorMessages}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-5 ot_addNoteText">
                                        <div className="relative did-floating-label-content">
                                            {medicationInputFeilds?.map((field) => (
                                                <div className="relative mt-4" key={field.id}>
                                                    <input
                                                        type="text"
                                                        id={field.id}
                                                        onChange={handleFormDataChange}
                                                        name={field.name}
                                                        value={field.value}
                                                        placeholder=""
                                                        className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${field.errorMessage && "border-red-600"
                                                            }`}
                                                        onKeyPress={(e) => {
                                                            if (
                                                                isNaN(Number(e.key)) &&
                                                                (field.name === "medicationLeftFor" ||
                                                                    field.name === "numberOfDaysSupplyRequested")
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor={field.id}
                                                        className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
                                                    >
                                                        {!field.errorMessage && field.label}
                                                    </label>
                                                    {field.errorMessage && (
                                                        <p className="mt-2 text-xs text-red-600 dark:text-red-400">
                                                            {field.errorMessage}
                                                        </p>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {previewPdfResponse?.error !== null &&
                                        <>
                                            <br />
                                            <Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
                                        </>
                                    }

                                    {sendFaxResponse?.error !== null &&
                                        <>
                                            <br />
                                            <Errors error={sendFaxResponse?.error.message ?
                                                sendFaxResponse?.error?.message :
                                                'Something went wrong when sending Fax. Please try again'} />
                                        </>
                                    }
                                </div>
                                <div class="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
                                    <div class="flex gap-7 mx-auto">
                                        <div class="col-span-6">
                                            <button
                                                onClick={() =>
                                                    handleARVFaxValidation(
                                                        arvID,
                                                        formData,
                                                        setErrorMessages,
                                                        errorMessages,
                                                        dispatch,
                                                        "PDF"
                                                    )
                                                }
                                                class="rubik-500 flex justify-center items-center text-white rounded-lg blueBg-color bg-red-700 w-full text-sm px-5 py-2.5 text-center"
                                            >
                                                {previewPdfResponse?.loading ? <>
                                                    Genrating PDF
                                                    &nbsp;<Loader /> </> : 'Preview PDF'}
                                            </button>
                                        </div>

                                        {patientType === "winrx" && (
                                        // {pharmacyLoginData?.srFax_active === 1 && (
                                            <div class="col-span-6">
                                                <button
                                                    onClick={() =>
                                                        handleARVFaxValidation(
                                                            arvID,
                                                            formData,
                                                            setErrorMessages,
                                                            errorMessages,
                                                            dispatch,
                                                            "FAX"
                                                        )
                                                    }
                                                    class="rubik-500 flex justify-center items-center text-white  rounded-lg bg-green-600 w-full text-sm px-5 
                                                    py-2.5 text-center"
                                                >
                                                    {sendFaxResponse?.loading ? <>
                                                        Sending
                                                        &nbsp;<Loader /> </> : 'Send Fax'}

                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    {" "}
                                    <button
                                        onClick={() =>
                                            handleHideModal(setShowModal, showModal, "SendFaxModal")
                                        }
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                        <ModalCloseIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showToast.showToast && <SuccessToastMessage status={'Fax sent'} />}
        </>
    );
};
