import {
	UPDATE_PATIENTS_PROFILE_REQUEST,
	UPDATE_PATIENTS_PROFILE_SUCCESS,
	UPDATE_PATIENTS_PROFILE_FAILURE
} from "../../constants";

import { pharmacy_update_patient } from "../../../networking/urlEndPoints"
import { fetchPatientsList } from "./patientsList";

import Axios from "../../../networking/intraceptor"
import * as routeNames from '../../../routes/routeName'
import { fetchBlistersPatientList, fetchMedicationPatientList, fetchVialsPatientsList } from "../pharmacy/blisters/patientBlisterPackList";
import { FetchTravelPatientList } from "../pharmacy/travel/travelList";

export const updatePatientsRequest = () => ({
	type: UPDATE_PATIENTS_PROFILE_REQUEST,
})

export const updatePatientsSuccess = (data) => ({
	type: UPDATE_PATIENTS_PROFILE_SUCCESS,
	payload: data,
})

export const updatePatientsFailure = (error) => ({
	type: UPDATE_PATIENTS_PROFILE_FAILURE,
	payload: error,
})

export const updatePatientsProfileData = (patientFormData,
	pateintAddress,
	patient_id,
	paginationNumber,
	hideModal,
	location,
	queryStatus,
	querySearch,
	setShowModal,
	showModal
) => {

	return async (dispatch) => {
		dispatch(updatePatientsRequest())

		const requestData = {
			phn: patientFormData.phn,
			first_name: patientFormData.firstName,
			last_name: patientFormData.lastName,
			delivey_note: null,
		}

		if (patientFormData.dob) {
			requestData.dob = patientFormData.dob
		}

		if (patientFormData.gender) {
			requestData.gender = patientFormData.gender
		}

		if (patientFormData.phone) {
			requestData.phone = patientFormData.phone
		}

		if (location.pathname === routeNames.pharmacy_patient_vials_pack) {
			requestData.vials_route = patientFormData.route

		} else if (location.pathname === routeNames.pharmacy_blister_patients) {
			requestData.blister_route = patientFormData.route

		} else if (location.pathname === routeNames.pharmacy_speciality_pack) {
			requestData.medication_route = patientFormData.route

		} else if (patientFormData.route) {
			requestData.route = patientFormData.route
		}

		if (patientFormData.email) {
			requestData.email = patientFormData.email
		}

		if (pateintAddress.city) {
			requestData.city = pateintAddress.city
		}

		if (pateintAddress.postalCode) {
			requestData.postcode = pateintAddress.postalCode
		}

		if (pateintAddress.province) {
			requestData.province = pateintAddress.province
		}

		if (pateintAddress.address) {
			requestData.address = pateintAddress.address
		}

		if (pateintAddress.fullAddress) {
			requestData.full_address = pateintAddress.fullAddress
		}

		if (pateintAddress.country) {
			requestData.country = pateintAddress.country
		}

		if (pateintAddress.mapUrl) {
			requestData.map_url = pateintAddress.mapUrl
		}

		if (pateintAddress.lat) {
			requestData.lat = pateintAddress.lat
		}

		if (pateintAddress.long) {
			requestData.lng = pateintAddress.long
		}

		try {
			const response = await Axios.post(`${pharmacy_update_patient}/${patient_id}`, requestData)
			if (response?.status === 200) {
				dispatch(updatePatientsSuccess(response?.data))

				if (typeof setShowModal === "function") {
					setShowModal(prevState => ({
						...prevState,
						showPatientModle: false,
						showToastMessage: true
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToastMessage: false,
						}));
					}, 2000);
					document.body.style.overflow = 'auto'
				}

				if (location.pathname === routeNames.pharmacy_blister_patients) {
					dispatch(fetchBlistersPatientList('blister_pack_list', paginationNumber))
				}

				else if (location.pathname === routeNames.pharmacy_patient_vials_pack) {
					dispatch(fetchVialsPatientsList('vials_patient_list', paginationNumber))
				}

				else if (location.pathname === routeNames.pharmacy_speciality_pack) {
					dispatch(fetchMedicationPatientList('medication_patient_list', paginationNumber))
				}

				else if (location.pathname === routeNames.pharmacy_add_travel_pack) {
					dispatch(FetchTravelPatientList('travel_patients_list', paginationNumber))
				}

				else if (location.pathname === routeNames.ALL_PATIENTS) {
					dispatch(fetchPatientsList(querySearch, queryStatus, paginationNumber));
				}

				else {
					dispatch(fetchPatientsList(querySearch, null, paginationNumber));
				}

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(updatePatientsFailure(error?.response))
			}
		}
	}
}

