import {
	FETCH_DOCTORS_LIST_REQUEST,
	FETCH_DOCTORS_LIST_SUCCESS,
	FETCH_DOCTORS_LIST_FAILURE
} from "../../../constants"

import { get_doctors } from "../../../../networking/urlEndPoints"
import Axios from "../../../../networking/intraceptor"

export const getDoctorsListRequest = () => ({
	type: FETCH_DOCTORS_LIST_REQUEST,
})

export const getDoctorsListSuccess = (data) => ({
	type: FETCH_DOCTORS_LIST_SUCCESS,
	payload: data,
})

export const getDoctorsListFailure = (error) => ({
	type: FETCH_DOCTORS_LIST_FAILURE,
	payload: error,
})

export const FetchAllDoctorsList = (searchTerm = null) => {
	return async (dispatch) => {
	  dispatch(getDoctorsListRequest());
	  try {
		let url = `${get_doctors}`;
		if (searchTerm) {
		  url += searchTerm
		}
		
		const response = await Axios.get(url);
		
		if (response?.status === 200) {
		  dispatch(getDoctorsListSuccess(response?.data));
		} else {
		  throw new Error('Failed to fetch doctors list');
		}
	  } catch (error) {
		if (error?.response) {
		  dispatch(getDoctorsListFailure(error?.response));
		} else {
		  dispatch(getDoctorsListFailure('An error occurred while fetching the doctors list'));
		}
	  }
	};
  };
