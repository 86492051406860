import React, { useRef, useState } from "react";
import { useCustomDispatch } from "../../hooks";
import * as useEffectHelper from "../../utils/helper/useEffectsHelper";
import { handleHideModal } from "../../assets/js/custome";
import { ModalCloseIcon } from "../../assets/svg/icons";
import { oatUpdateStatus } from "../../services/actions/OAT/addOATData";
import { useFormsSelectors } from "../../services/selectors/formsSelector";
import { Loader } from '../../components/common/loadingView'
export const StatusOATModalPopUp = ({

    setShowModal,
    showModal,
    OATID,
    formData,
    pageNumber,
    setShowToast,
    type = "pharmacy_patient_route",
    route = "/update-patient-status",
    forContainer = "ROUTE"

}) => {
    const modalRef = useRef(null);
    useEffectHelper.useRemoveModalEffect(
        setShowModal,
        showModal,
        "statusOATModal",
        modalRef
    );
    const dispatch = useCustomDispatch();
    const { updateStatusRespons } = useFormsSelectors()

    const [selectedReason, setSelectedReason] = useState('');
    const [otherReason, setOtherReason] = useState('');

    const handleSelectChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleOtherReasonChange = (event) => {
        setOtherReason(event.target.value);
    };

    const handleSubmit = () => {
        const reason = selectedReason

        // Dispatch the submit action with the appropriate reason

        dispatch(oatUpdateStatus(type, OATID, reason, setShowToast, setShowModal, showModal, formData, pageNumber, route, otherReason,forContainer))

    };

    return (
        <>
            {showModal.statusOATModal && (
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
                    overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                >
                    <div ref={modalRef} className="relative w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                onClick={() =>
                                    handleHideModal(setShowModal, showModal, "statusOATModal")
                                }
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
                                    text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                   <ModalCloseIcon />
                            </button>
            

                            <div className="p-6 text-center pt-12">
                                <label style={{ fontSize: "0.9rem" }} className="mb-2 text-lg font-medium text-gray-700 dark:text-gray-400 float-left">Select Reason</label>
                                <select
                                    onChange={handleSelectChange}
                                    className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                        dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
                                        bg-white"
                                    name="reason_to_deactivate"
                                    id="reason_to_deactivate"
                                    value={selectedReason}
                                >
                                    <option value="" disabled selected>Select Reason</option>
                                    <option value="Patient got transferred">Patient got transferred</option>
                                    <option value="Patient in hospital">Patient in hospital</option>
                                    <option value="No Rx">No Rx</option>
                                    <option value="Patient in detox">Patient in detox</option>
                                    <option value="Patient got new Rx at another pharmacy">Patient got new Rx at another pharmacy</option>
                                    <option value="Rx cancelled by Dr.">Rx cancelled by Dr.</option>
                                    <option value="Rx cancelled- # of missed doses">Rx cancelled- # of missed doses</option>
                                    <option value="other">Other</option>
                                </select>
                                

                                {selectedReason === 'other' && (
                                    <input
                                        type="text"
                                        onChange={handleOtherReasonChange}
                                        value={otherReason}
                                        style={{height:'48px'}}
                                        className="mt-2 block w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                            dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
                                            bg-white font-light"
                                        placeholder="Please specify the reason"
                                    />
                                )}

                                <br />

                                <button
                                    onClick={handleSubmit}
                                    style={{ backgroundColor: "#3A52BB" }}
                                    type="button"
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none 
                                        focus:ring-red-300 
                                        dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                >
                                    {updateStatusRespons?.loading ? <>
                                        Updating
                                        &nbsp;<Loader /> </> : 'Yes'}
                                </button>

                                <button
                                    onClick={() =>
                                        handleHideModal(setShowModal, showModal, "statusOATModal")
                                    }
                                    type="button"
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                                        border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                                        dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
