import React, { } from 'react'

import ChatBox from '../../../components/chat/chatbox'
import * as Common from '../../../components/common'

import PrescriptionList from './prescriptionList'

const TransferPrescriptions = () => {

    return (
        <React.Fragment>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">

                            <div className="content">

                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <PrescriptionList />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />
            <Common.Footer />
        </React.Fragment>
    )
}

export default TransferPrescriptions