
export const handleChangeAddPatientFormData = (
	e,
	patientFormData,
	setPatientFormData,
	formDataBorderColor,
	setFormDataBorderColor,
	formFeildsErrors,
	setFormFieldErrors
) => {
	const { name, value } = e.target;

	setPatientFormData({
		...patientFormData,
		[name]: value,
	});

	const fieldMappings = {
		phn: 'lightgrey',
		lastName: 'lightgrey',
		firstName: 'lightgrey',
		route: 'lightgrey',
		gender: 'lightgrey',
	};

	if (fieldMappings[name] && patientFormData[name]) {
		setFormDataBorderColor({
			...formDataBorderColor,
			[name]: fieldMappings[name],
		});

		setFormFieldErrors({
			...formFeildsErrors,
			[name]: '',
		});
	}
};


export const handleChangeAddPatientFormAddress = (e, pateintAddress, setPatientAddress) => {
	const { name, value } = e.target;
	setPatientAddress({
		...pateintAddress,
		[name]: value,
	});
}

export const handleUpdatePatientAddress = (e, setUpdatePatientAddress, updatePatientAddress) => {
	const { name, value } = e.target;
	setUpdatePatientAddress({
		...updatePatientAddress,
		[name]: value,
	});
}

export const handleUpdatePatientFormData = (
	e,
	updatePatientFormData,
	setUpdatePatientFormData,
	formDataBorderColor,
	setFormDataBorderColor,
	formFeildsErrors,
	setFormFieldErrors
) => {
	const { name, value } = e.target;

	setUpdatePatientFormData({
		...updatePatientFormData,
		[name]: value,
	});

	const fieldMappings = {
		phn: 'lightgrey',
		lastName: 'lightgrey',
		firstName: 'lightgrey',
		route: 'lightgrey',
	};

	if (fieldMappings[name] && updatePatientFormData[name]) {
		setFormDataBorderColor({
			...formDataBorderColor,
			[name]: fieldMappings[name],
		});

		setFormFieldErrors({
			...formFeildsErrors,
			[name]: '',
		});
	}
};


export const handleFormPatientNoteChange = (e, setNotesData, notesData) => {
	const { name, value } = e.target;
	setNotesData({
		...notesData,
		[name]: value,
	})
}

export const handleRecurrenceChange = (e, setNotesData, notesData) => {
	const isChecked = e.target.checked;
	setNotesData({
		...notesData,
		recurrence: isChecked ? 1 : 0
	})
}

export const handleAddDoctorsFormChange = (e, setFormData, setErrorMessages, formData, errorMessages) => {
	const { name, value } = e.target
	setFormData({
		...formData,
		[name]: value
	})
	const errorFields = ['lastName', 'firstName', 'cps_id'];

	errorFields.forEach(field => {
		if (name === field) {
			setErrorMessages({ ...errorMessages, [field]: '' });
		}
	});
}

export const onhandlePharmacyFormDataChange = (e, setFormData, formData, setErrorMessages, errorMessages) => {
	const { name, value } = e.target;
	setFormData({
		...formData,
		[name]: value,
	});

	const errorFields = [
		'phn', 'name', 'dob', 'clinicFaxNumber', 'pharmacyName', 'pharmacyManager',
		'pharmacyPhone', 'pharmacyEmail', 'doctorName', 'desgination', 'pharmacyFax',
		'toPharmacy', 'toPharmacyPhone', 'toPharmacyFax', 'requested_Prescription',
		'customToFaxNumber', 'customClinicName', 'customDoctor', 'subjectOfFax',
		'prescriptionFolio', 'descriptionAuthorized', 'prescriptionCPID'
	];

	errorFields.forEach(field => {
		if (name === field) {
			setErrorMessages({ ...errorMessages, [field]: '' });
		}
	});
};


export const handleChangeAddClinicFormData = (e, formData, setFormData, setErrorMessages, errorMessages) => {
	const { name, value } = e.target
	setFormData({
		...formData,
		[name]: value
	})
	const errorFields = ['clinicName', 'phoneExtension', 'city']
	errorFields.forEach(field => {
		if (name === field) {
			setErrorMessages({ ...errorMessages, [field]: '' });
		}
	})
}

export const handlePrescriberPatientRecordCheckboxChange = (event, setFormData) => {
	const checkboxId = event.target.id;
	if (event.target.checked) {
		setFormData((prevFormData) => {
			const newPatientRecords = Array.isArray(prevFormData.patientrecords)
				? [...prevFormData.patientrecords, checkboxId]
				: [checkboxId];
			return {
				...prevFormData,
				patientrecords: newPatientRecords,
			};
		});
	} else {
		setFormData((prevFormData) => {
			const newPatientRecords = Array.isArray(prevFormData.patientrecords)
				? prevFormData.patientrecords.filter((boxId) => boxId !== checkboxId)
				: [];
			return {
				...prevFormData,
				patientrecords: newPatientRecords,
			};
		});
	}
}

export const onhandlePharmacyCompoundFormDataChange = (e, setFormData, formData, setErrorMessages, errorMessages) => {
	const { name, value } = e.target;
	setFormData({
		...formData,
		[name]: value,
	});

	const errorFields = ['toPharmacy', 'toPharmacyPhone', 'toPharmacyFax', 'toPharmacyManager', 'pharmacyName',
		'pharmacyManager', 'desgination', 'provider'];

	errorFields.forEach(field => {
		if (name === field) {
			setErrorMessages({ ...errorMessages, [field]: '' });
		}
	});
};


export const onhandleDispenseAddDeliveryFormDataChange = (e, setFormData, formData, setErrorMessages, errorMessages) => {
	const { name, value } = e.target;
	setFormData({
		...formData,
		[name]: value,
	});

	const errorFields = ['name', 'passcode', 'phone', 'email'];

	errorFields.forEach(field => {
		if (name === field) {
			setErrorMessages({ ...errorMessages, [field]: '' });
		}
	});
};

export const onHandleChangeAddARVFormDATA = (e, setFormData, formData, setErrorMessages, errorMessages) => {
	const { name, value } = e.target;
	setFormData({
		...formData,
		[name]: value,
	});

	const errorFields = [
		'date', 'patientPhone', 'dob', 'name'
	];

	errorFields.forEach(field => {
		if (name === field) {
			setErrorMessages({ ...errorMessages, [field]: '' });
		}
	});
};

