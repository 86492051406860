import React, {
	useEffect,
	useRef,
	useState,
	useCallback
} from 'react';

import {
	PrintLabelSetting,
	muteRxList,
	pharmacyAddlabelsDataFailure,
	pharmacyAddlabelsDataSuccess,
	updateLabelInput
} from '../../services/actions/pharmacy/dispense/addLabelsData';

import { useCustomDispatch } from '../../hooks';
import { ModalCloseIcon } from '../../assets/svg/icons';

import { useDispenseSelectors } from '../../services/selectors/dispenseSelectors';
import { handleHideModal } from '../../assets/js/custome';

import { handleLabelDelete } from '../../services/actions/pharmacy/dispense/deleteDispenseData';
import { validateLabelInput } from '../../services/actions/pharmacy/dispense/labelValidateUpdateInputs';

import * as Dispense from '../../components/pharmacy/dispense/dispense';
import * as useEffectHelper from '../../utils/helper/useEffectsHelper';

import label1 from '../../assets/images/label_1.jpg'
import label2 from '../../assets/images/label_2.jpg'
import debounce from 'lodash/debounce';

export const UpdateDispenseLabel = ({ showModal, onClose = null, labelData, setShowModal, setRxNumber, data }) => {


	const [checkedDays, setCheckedDays] = useState([]);
	const [inputFields, setInputFields] = useState([{ dose: '', day: '' }]);

	const [isChangingDose, setIsChangingDose] = useState('')
	const [errorMessage, setErrorMessage] = useState('');

	const [isTrackInputValues, setIsTrackInputValues] = useState(false)

	const { validateLabelInputResponse, addLabelsDataResponse } = useDispenseSelectors()
	const errors = validateLabelInputResponse?.error?.data
	const labelSuccess = validateLabelInputResponse?.data?.data

	const modalRef = useRef(null);
	const dispatch = useCustomDispatch()

	const handleAddFields = () => {
		setIsTrackInputValues(true)
		setInputFields([...inputFields, { dose: '', day: '' }]);
		setTimeout(() => {
			setIsTrackInputValues(false)
		}, 2000);
	};

	const handleRemoveFields = (index) => {
		setIsTrackInputValues(true)
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);

		setTimeout(() => {
			setIsTrackInputValues(false)
		}, 2000);
		
	};

	const days = [
		{ label: 'Sun', value: 'Sunday' },
		{ label: 'Mon', value: 'Monday' },
		{ label: 'Tue', value: 'Tuesday' },
		{ label: 'Wed', value: 'Wednesday' },
		{ label: 'Thu', value: 'Thursday' },
		{ label: 'Fri', value: 'Friday' },
		{ label: 'Sat', value: 'Saturday' }
	];

	let requestedData;

	const [formData, setFormData] = useState({
		carries_day: checkedDays,
		enter_dose: inputFields,
		labelid: labelData?.id,
		date: '',
		updateformtype: labelData?.scheduke_key
	});

	useEffect(() => {
		setFormData((prev) => ({
			...prev,
			labelid: labelData?.id,
			updateformtype: labelData?.scheduke_key,
			carries_day: checkedDays,
			enter_dose: inputFields,
		}));
	}, [labelData, checkedDays, inputFields]);

	const handleValidateLabels = useCallback(
		debounce((requestedData) => {
			dispatch(validateLabelInput('update_label_input', requestedData));
		}, 500),
		[dispatch]
	);

	useEffect(() => {

		if (isTrackInputValues) {
			return;
		}

		const formType = formData.updateformtype === 'Update' ? 'update' : 'generate';

		requestedData = {
			labelid: formData.labelid,
			updateformtype: formType,
			change_from_date: formData.date,
			enter_dose: {
				dose: formData.enter_dose.map((value) => value.dose),
				day: formData.enter_dose.map((value) => value.day)
			}
		};

		if (formData.carries_day.length > 0) {
			requestedData.carries_day = formData.carries_day;
		}

		if (isChangingDose && formType === 'update') {
			requestedData.is_changing_dose = 1;
		} else if (formType === 'update') {
			requestedData.is_changing_dose = 0;
		}

		if (formData.labelid && formData.date) {
			handleValidateLabels(requestedData);
		}

	}, [formData, isChangingDose, dispatch]);

	const handleCheckboxChange = (event) => {
		const { name, checked } = event.target;
		if (checked) {
			setCheckedDays([...checkedDays, name]);
		} else {
			setCheckedDays(checkedDays?.filter(day => day !== name));
		}
	}

	useEffect(() => {
		const initialCheckedDays = days?.map(day => day.value);
		setCheckedDays(initialCheckedDays);
	}, []);

	const handleUpdateLabelInput = () => {

		for (let i = 0; i < inputFields.length; i++) {
			if (!inputFields[i].dose || !inputFields[i].day) {
				setErrorMessage('Dose and Days are required');
				return;
			}
		}
		setErrorMessage('');

		if (formData.labelid && formData.date) {
			const formType = formData.updateformtype === 'Update' ? 'update' : 'generate';
			const requestedData = {
				labelid: formData.labelid,
				updateformtype: formType,
				change_from_date: formData.date,
				enter_dose: {
					dose: inputFields.map((field) => field.dose),
					day: inputFields.map((field) => field.day)
				}
			};

			if (formData?.carries_day?.length > 0) {
				requestedData.carries_day = formData.carries_day;
			}

			if (isChangingDose && formType === 'update') {
				requestedData.is_changing_dose = 1;
			} else if (formType === 'update') {
				requestedData.is_changing_dose = 0;
			}

			dispatch(updateLabelInput('label-update-schedule-inputs', requestedData, setShowModal, setRxNumber, data));
		}
	};

	useEffect(() => {
		if (addLabelsDataResponse?.loading && addLabelsDataResponse?.type === 'rxlabel-list') {
			setFormData({ ...formData, date: '', scheduke_key: '', labelid: '' })
			setInputFields([{ dose: '', day: '' }])
		}
	}, [addLabelsDataResponse])

	return (
		<React.Fragment>

			{showModal.updateDispenseModal && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100" style={{ zIndex: '99999' }}>
					<div ref={modalRef} className="relative w-full max-w-xl max-h-full" style={{ width: '100%' }}>
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
									Update RX #{labelData?.rx}
								</h3>
								<div onClick={() => {
									return setFormData({ ...formData, date: '', scheduke_key: '', labelid: '' }),
										setInputFields([{ dose: '', day: '' }]),
										dispatch(pharmacyAddlabelsDataFailure(null, null)),
										dispatch(pharmacyAddlabelsDataSuccess(null, null)),
										setErrorMessage('')
								}}>
									<button
										onClick={onClose ? onClose : () => { setFormData({ ...formData, date: '', scheduke_key: '', labelid: '' }) }}
										type="button"
										className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
									text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
										<ModalCloseIcon />
										<span className="sr-only">Close modal</span>
									</button>
								</div>
							</div>

							<div className="p-4">
								<div className="relative rubik-400 text-gray-500 fs-12">
									<p style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
										<span>{labelData?.name} |</span>
										<span>{labelData?.rx_duration} |</span>
										<span>Days {labelData?.days} |</span>
										<span>T. Qty. {labelData?.tqty} |</span>
										<span>{labelData.qty} |</span>
										<span>{labelData.sig} </span>
									</p>
								</div>

								{labelData?.scheduke_key === 'Update' &&
									<div className="relative flex items-center mt-4">
										<input id="isChangingDose" onChange={(event) => {
											setIsChangingDose(event.target.checked)
										}}
											type="checkbox" name='isChangingDose' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
										<label htmlFor="isChangingDose" className="w-full py-2 ml-2 text-sm font-medium rubik-500 text-gray-600 dark:text-gray-300 fs-14 rubik-400 cursor-pointer">Is Changing Dose</label>
									</div>
								}

								<h4 className='rubik-500 mt-1 text-gray-600 fs-14'>Scheduled Days</h4>
								<div className="relative flex items-center gap-2">
									{days?.map((day, index) => (
										<div key={index} className="relative flex items-center flex-1">
											<input
												id={day.label}
												name={day.value}
												type="checkbox"
												checked={checkedDays?.includes(day?.value)}
												onChange={handleCheckboxChange}
												className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 rubik-400"
											/>
											<label htmlFor={day.label} className="w-full py-2 ml-1 fs-12 font-medium text-gray-500 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">
												{day.label}
											</label>
										</div>
									))}
								</div>

								<h4 className='rubik-500 mt-1 text-gray-600 fs-14'>Start Date</h4>
								<div className='mt-2'>
									<Dispense.StartDate formData={formData}
										setFormData={setFormData} />
								</div>

								{labelData?.scheduke_key === 'Update' ? !isChangingDose ?
									<p className='rubik-400 fs-12 text-gray-500 pt-1'>{labelData?.date_range}</p> : null : null}

								{validateLabelInputResponse?.loading && validateLabelInputResponse?.type === 'update_label_input' ? null : errors ? null :
									labelData?.scheduke_key === 'Generate Labels' ?
										labelSuccess?.leftdose < 0 ?
											<div className='text-red-500 fs-12 rubik-500 bg-red-100 rounded-sm w-full p-2 mt-2'>
												<p>Total Qty. remaining on {labelSuccess?.datefrom} is
													{labelSuccess?.totaldoseleft} | Qty Remaining {labelSuccess?.leftdose} | Stop Date {labelSuccess?.stopdate}</p>
											</div>
											:
											formData?.date && labelSuccess?.datefrom ?
												<div className='text-green-500 fs-12 rubik-500 bg-green-100 rounded-sm w-full p-2 mt-2'>
													<p>Total Qty. remaining on {labelSuccess?.datefrom} is &nbsp;
														{labelSuccess?.totaldoseleft} {labelSuccess?.totaldoseleft != labelSuccess?.leftdose &&
															<> | Qty Remaining {labelSuccess?.leftdose} | Stop Date {labelSuccess?.stopdate}</>}</p>
												</div>
												: null
										: null
								}

								{((validateLabelInputResponse?.loading) && (validateLabelInputResponse?.type === 'update_label_input')) ? null : errors ? null :

									labelData?.scheduke_key === 'Update' ?
										labelSuccess?.leftdose < 0 ?
											<div className='text-red-500 fs-12 rubik-500 bg-red-100 rounded-sm w-full p-2 mt-2'>
												<p>Total Qty. remaining on {labelSuccess?.datefrom} is
													{labelSuccess?.totaldoseleft} | Qty Remaining {labelSuccess?.leftdose} | Stop Date {labelSuccess?.stopdate}</p>
											</div>
											:

											formData?.date && labelSuccess?.datefrom ?

												<div className='text-green-500 fs-12 rubik-500 bg-green-100 rounded-sm w-full p-2 mt-2'>
													<p>Total Qty. remaining on {labelSuccess?.datefrom} is &nbsp;
														{labelSuccess?.totaldoseleft} {labelSuccess?.totaldoseleft != labelSuccess?.leftdose &&
															<> | Qty Remaining {labelSuccess?.leftdose} | Stop Date {labelSuccess?.stopdate}</>}</p>
												</div>
												: null
										: null
								}

								{errors &&
									<div className='text-red-500 fs-12 rubik-500 bg-red-100 rounded-sm w-full p-2 mt-2'>
										<p>{errors?.message}</p>
									</div>}

							</div>

							<div className='p-4 relative  gap-4 -mt-6'>
								{inputFields?.map((inputField, index) => (
									<div key={index} className="flex w-full mb-2 gap-2">
										<Dispense.LabelInput
											label='Enter Dose'
											name='dose'
											formData={inputFields}
											setFormData={setInputFields}
											index={index}
										/>
										<Dispense.LabelInput
											label='Enter Days'
											name='day'
											formData={inputFields}
											setFormData={setInputFields}
											index={index}
										/>
										{index === 0 ? (
											<button
												onClick={handleAddFields}
												type="button"
												className="text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
													border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
													dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
											>
												+
											</button>
										) : (
											<button
												onClick={() => handleRemoveFields(index)}
												type="button"
												className="text-white bg-red-700 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
												border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
												dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
											>
												-
											</button>
										)}
									</div>
								))}
								{errorMessage && (
									<div className="text-red-500 mb-2">
										{errorMessage}
									</div>
								)}
							</div>

							{addLabelsDataResponse?.error !== null && addLabelsDataResponse?.type === 'label-update-schedule-inputs' &&
								<div className='p-4 relative  gap-4 -mt-6'>
									<div className='text-red-500 fs-12 rubik-500 bg-red-100 rounded-sm w-full p-4 mt-2'>
										<p>Oops! Got an issue while processing the request please try again.</p>
									</div>
								</div>}


							{validateLabelInputResponse?.loading && validateLabelInputResponse?.type === 'update_label_input' ? null :
								errors ? null : <>

									<div className='pl-4 relative flex items-center -mt-4 pb-6'>

										{
											labelData?.scheduke_key === 'Generate Labels' ?
												labelSuccess?.leftdose < 0 ?
													null
													:
													formData?.date && labelSuccess?.datefrom ?
														<button onClick={handleUpdateLabelInput}
															type="button" className="text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
															border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
															dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
															{addLabelsDataResponse?.loading && addLabelsDataResponse?.type === 'label-update-schedule-inputs' ? 'Genrating...' : 'Generate'}
														</button>
														: null
												: null
										}

									</div>

									<div className='pl-4 relative flex items-center -mt-4 pb-6'>

										{
											labelData?.scheduke_key === 'Update' ?
												labelSuccess?.leftdose < 0 ?
													null
													:
													formData?.date && labelSuccess?.datefrom ?
														<button onClick={handleUpdateLabelInput}
															type="button" className="text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
																border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
																dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
															{addLabelsDataResponse?.loading && addLabelsDataResponse?.type === 'label-update-schedule-inputs' ? 'Updating...' : 'Update'}

														</button>
														: null
												: null
										}

									</div>
								</>}
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

export const MuteRxLabelsModel = ({ setShowModal, showModal, patientData, formData, pageNumber }) => {

	const modalRef = useRef(null);
	const dispatch = useCustomDispatch()
	const { addLabelsDataResponse } = useDispenseSelectors();

	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'muteRxModal', modalRef)

	return (
		<>
			{showModal.muteRxModal &&
				<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
					<div ref={modalRef} className="relative w-full max-w-md max-h-full">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

							<button onClick={() => handleHideModal(setShowModal, showModal, 'muteRxModal')} type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
								<ModalCloseIcon />
							</button>

							<div className="p-6 text-center pt-12">
								<p className='rubik-500 text-gray-500'>Are you sure?</p>
								<p className='rubik-400 text-gray-500'>You are about to mute this RX!</p><br />

								<button onClick={() => dispatch(muteRxList(patientData?.id, patientData?.mute === 1 ? 0 : 1, 'muterxvalue', formData, pageNumber, setShowModal, showModal))}
									style={{ backgroundColor: '#3A52BB' }} type="button"
									className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 
                    							dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
									{addLabelsDataResponse?.loading ? 'Processing...' : 'Yes'}
								</button>

								<button onClick={() => handleHideModal(setShowModal, showModal, 'muteRxModal')} type="button"
									className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                								border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                								dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>}
		</>
	);
};


export const DeleteLabelsModal = ({ setShowModal, showModal, labelId, formData, pageNumber }) => {

	const modalRef = useRef(null);
	const dispatch = useCustomDispatch()
	const { deleteDispenseDataResponse } = useDispenseSelectors();

	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'DeleteRxModal', modalRef)

	return (
		<>
			{showModal.DeleteRxModal &&
				<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
					<div ref={modalRef} className="relative w-full max-w-md max-h-full">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

							<button onClick={() => handleHideModal(setShowModal, showModal, 'DeleteRxModal')} type="button"
								className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
								<ModalCloseIcon />
							</button>

							<div className="p-6 text-center pt-12">
								<p className='rubik-500 text-gray-500'>Are you sure?</p>
								<p className='rubik-400 text-gray-500'>You want to delete this label?</p><br />

								<button
									onClick={() => dispatch(handleLabelDelete(labelId, 'DeleteRxModal', formData, pageNumber, setShowModal, showModal))}
									style={{ backgroundColor: '#3A52BB' }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none 
												focus:ring-red-300 
                    							dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
									{deleteDispenseDataResponse?.loading ? 'Processing...' : 'Yes'}
								</button>

								<button onClick={() => handleHideModal(setShowModal, showModal, 'DeleteRxModal')} type="button"
									className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                								border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                								dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>}
		</>
	);
};



export const PrinterLabelSettingModal = ({ onClose, setShowModal, showModal }) => {
	const [selectedType, setSelectedType] = useState('regular');

	const dispatch = useCustomDispatch()

	const { addLabelsDataResponse } = useDispenseSelectors()

	const handleSubmit = (e) => {
		e.preventDefault();
		const requestData = {
			label_print_type: selectedType
		}
		dispatch(PrintLabelSetting('print_label_setting', requestData, setShowModal))
	}

	return (
		<>
			{showModal.updateLabelPrint &&
				<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
			overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
					<div className="relative w-full max-w-xl max-h-full">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
									Printer Label Setting
								</h3>

								<button
									onClick={() => handleHideModal(setShowModal, showModal, 'updateLabelPrint')} type="button"
									className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
									  text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
									<ModalCloseIcon />
								</button>
							</div>

							<form onSubmit={handleSubmit}>
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<div className="flex justify-between px-4 py-5 sm:p-6">
										<div className="flex flex-col items-center -ml-10">
											<div className="flex items-center">
												<input
													type="radio"
													name="label_print_type"
													value="regular"
													checked={selectedType === 'regular'}
													onChange={() => setSelectedType('regular')}
													className="form-radio h-5 w-5 text-indigo-600"
													id="imageRadio1"
													style={{ position: 'relative', left: '47px' }}
												/>
												<label htmlFor="imageRadio1" className="ml-3">
													<img src={label1} alt="Label Up" className="" />
												</label>
											</div>
										</div>
										<div className="flex flex-col items-center">
											<div className="flex items-center">
												<input
													type="radio"
													name="label_print_type"
													value="toptodown"
													checked={selectedType === 'toptodown'}
													onChange={() => setSelectedType('toptodown')}
													className="form-radio h-5 w-5 text-indigo-600"
													id="imageRadio2"
													style={{ position: 'relative', left: '47px' }}
												/>
												<label htmlFor="imageRadio2" className="ml-3">
													<img src={label2} alt="Label Down" className="" />
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<button
										type="submit"
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
									>
										{addLabelsDataResponse?.loading && addLabelsDataResponse?.type === 'print_label_setting' ? 'Updating...' : 'Update'}

									</button>
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
										onClick={onClose}
									>
										Close
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>}
		</>
	);
};

