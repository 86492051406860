import React,
{
	useEffect,
	useState
} from 'react'

import {
	Sidebar,
	Footer
} from '../../components/common'

import {
	useCustomDispatch,
	useCustomSelector,
	useCustomNavigate
} from '../../hooks'

import { fetchTimeoOffLists } from '../../services/actions/main/pharmacyTimeOffListActions'
import { LoadingView } from '../../components/common'
import { deleteTimeOffRequestList } from '../../services/actions/main/deleteTimeOffRequestListActions'

import { authToken } from '../../storage/authToken'
import { ErrorMessages } from '../../components/error/alertMessages'
import { useCustomSelectors } from '../../services/selectors/allSelectors'
import { useCustomLoadingStates } from '../../hooks/customStates'

import ChatBox from '../../components/chat/chatbox'
import * as routesName from '../../routes/routeName'

const AllRequests = () => {

	const token = authToken()
	const newRequest = 0

	const dispatch = useCustomDispatch()
	const navigate = useCustomNavigate()

	const { pharmacyTimeOffList } = useCustomSelectors()

	const {
		apiCallMade,
		setApiCallMade } = useCustomLoadingStates()

	const deleteTimeRequestResponse = useCustomSelector(state => state.deleteTimeOffRequest)
	const timeOffList = pharmacyTimeOffList?.data?.data

	const [delId, setDelId] = useState(null)

	useEffect(() => {
		if (!apiCallMade && token) {
			dispatch(fetchTimeoOffLists())
			setApiCallMade(true)
		}
	}, [])

	const deleteTimeOffRequest = (id) => {
		setDelId(id)
		if (id) {
			dispatch(deleteTimeOffRequestList(id))
		}
	}

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar newRequest={newRequest} />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="allRequest">
								<div className="container">
									<div className="grid grid-cols-12 gap-4 w-full">

										<div className="mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
											<div>
												<h2 className="rubik-500 fs-30">
													{/* {pharmacyTimeOffList.loading ? null :
														pharmacyTimeOffList?.data?.message === 'No status available for the user.' ? 'No Time Off Request' :
															'All Time Off Request'} */}
													All Time Off Request
												</h2>
											</div>
										</div>

										<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4">
											<div
												className="relative content-between ti_heightSchedule justify-center sm:justify-center md:justify-end lg:justify-end">
												<div
													className="ti_heightScheduleInnerDate flex items-end justify-center sm:justify-center md:justify-end lg:justify-end gap-2">
													<div className="text-center">
														<button type="button" onClick={() => navigate(routesName.NEWREQUEST_ROUTE)}
															className="ti_heightScheduleInnerDateBtn blueBg-color mx-auto sm:text-center md:text-start lg:text-start rubik-400 fs-16 text-white rounded-lg text-sm px-5 py-3"
														>Add New Request</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>



								<div className="container mt-2">
									{pharmacyTimeOffList?.loading ?
										<div className="flex justify-center items-center mt-60">
											<LoadingView />
										</div>
										:
										pharmacyTimeOffList?.error !== null ?
											<ErrorMessages />
											:
											pharmacyTimeOffList?.data?.message === 'No status available for the user.' ?
												<div className="flex flex-col items-center justify-center mt-60">
													<div className="p-8 text-center rounded-lg shadow-lg" style={{ background: '#3A52BB' }}>
														<h1 className="text-4xl font-extrabold text-white">Oops!</h1>
														<p className="text-xl mt-4 text-white">No Requests Found.</p>
													</div>
												</div>
												:
												<div className="relative overflow-x-auto border rounded-lg">
													<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-nowrap">
														<thead className="text-xs text-gray-700 h-12 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">

															<tr>
																<th scope="col" className="px-4 py-3 text-center border-r tableBg-color">
																	<h3 className="rubik-500 fs-16 dark-color">Start Date</h3>
																</th>
																<th scope="col" className="px-4 py-3 text-center border-r tableBg-color">
																	<h3 className="rubik-500 fs-16 dark-color">End Date</h3>
																</th>
																<th scope="col" className="px-4 py-3 text-center border-r tableBg-color">
																	<h3 className="rubik-500 fs-16 dark-color">Type</h3>
																</th>

																<th scope="col" className="px-4 py-3 text-center border-r tableBg-color">
																	<h3 className="rubik-500 fs-16 dark-color">Reason</h3>
																</th>

																<th scope="col" className="px-4 py-3 text-center border-r tableBg-color">
																	<h3 className="rubik-500 fs-16 dark-color">Status</h3>
																</th>
															</tr>
														</thead>

														<tbody>

															{timeOffList?.slice()?.reverse()?.map((value, index) => {
																return (<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
																	<td className="px-4 py-4 text-center">
																		<p className="raleway-500 fs-16 dark-color">{value.start_date?.replace(/-/g, '/')} </p>
																		<p className="raleway-500 fs-16 dark-color">{value.start_time}</p>
																	</td>

																	{value.end_date ?
																		<td className="px-4 py-4 text-center">
																			<p className="raleway-500 fs-16 dark-color">{value.end_date?.replace(/-/g, '/')} </p>
																		</td> :
																		<td className="px-4 py-4 text-center">
																			<p className="raleway-500 fs-16 dark-color">{value.start_date?.replace(/-/g, '/')} </p>
																			<p className="raleway-500 fs-16 dark-color">{value.end_time}</p>
																		</td>
																	}

																	<td className="px-4 py-4 text-center">
																		<p className="raleway-500 fs-16 dark-color uppercase">{value.time_of_type}</p>
																	</td>

																	<td className="px-4 py-4 text-center">
																		<p className="raleway-500 fs-16 dark-color uppercase">{value.region}</p>
																	</td>

																	{value.status === 'Approved' ?
																		<td className="px-4 py-4 text-center">
																			<p className="raleway-500 fs-16 dark-color"><span className=" w-full sm:w-full md:w-full lg:w-full xl:w-3/4 inline-block greenBg-color raleway-500 light-color fs-16 bg-green-100 text-xs font-medium mr-2 px-5 py-2 rounded-full dark:bg-green-900 dark:text-green-300">{value.status}</span>
																			</p>
																		</td>
																		:
																		value.status === 'Pending' ?
																			<td className="px-4 py-4 text-center">
																				<p className="raleway-500 fs-16 dark-color">
																					<span className="yellowBg-color w-full sm:w-full md:w-full lg:w-full xl:w-3/4 inline-block raleway-500 light-color fs-16 bg-green-100 text-xs font-medium mr-2 px-5 py-2 rounded-full dark:bg-green-900 dark:text-green-300">Pending</span>
																				</p>

																				{value.status === 'Pending' &&
																					<div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#1B2336', marginTop: '12px' }}>
																						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" strokeWidth="1.5" stroke="currentColor" className="btnnn w-6 h-6" onClick={() => navigate(`${routesName.EDIT_TIME_OFFR_REQUEST_ROUTE}/${value.id}`)}>
																							<path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
																						</svg>
																						<span onClick={() => deleteTimeOffRequest(value.id)}>
																							{deleteTimeRequestResponse.loading && delId === value.id ? '...' :
																								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" strokeWidth={1.5} stroke="currentColor" className="btnnn w-6 h-6" >
																									<path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
																								</svg>}
																						</span>
																					</div>
																				}

																			</td> : value.status === 'Rejected' ?
																				<td className="px-4 py-4 text-center">
																					<p className="raleway-500 fs-16 dark-color"><span className="redBg-color w-full sm:w-full md:w-full lg:w-full xl:w-3/4 inline-block raleway-500 light-color fs-16 bg-green-100 text-xs font-medium mr-2 px-5 py-2 rounded-full dark:bg-green-900 dark:text-green-300">Rejected</span>
																					</p>
																				</td> : null}
																</tr>)
															})}
														</tbody>
													</table>
												</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
			<ChatBox />
		</>
	)
}

export default AllRequests