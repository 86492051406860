import React, { useCallback } from 'react';
import { useCanvasContext } from '../../hooks/useCanvasContext';
import { fabric } from 'fabric';

function TemplatesPanel() {
    const { canvas } = useCanvasContext();

    // Array of image URLs
    const imageUrls = [
        '/certificates/Certificate-blue2.png',
        '/certificates/certificate1.jpg',
        '/certificates/template2.jpg',
        '/certificates/certificate212.jpg',
        '/certificates/certificate9.png',
        '/certificates/Certificate-orange.png',
        
    ];

    const imgStyles = {
        width: '100%',
        maxWidth: '220px', 
        height: 'auto',
        cursor: 'pointer', 
        marginBottom: '8px',
    };

    // Function to handle setting template as background
    const handleTemplateSelection = useCallback((templateSrc) => {
        fabric.Image.fromURL(templateSrc, function (img) {
            // Remove existing background if any
            canvas.getObjects('image').forEach((object) => {
                canvas.remove(object);
            });

            // Set new background image
            img.set({
                selectable: false,
                evented: false,
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height,
            });
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
        }, { crossOrigin: 'anonymous' });
    }, [canvas]);

    return (
        <div className="row p-4">
            {imageUrls.map((url, index) => (
                <div key={index} className="col-12 pt-2">
                    <img
                        loading="lazy"
                        className="templatePicker"
                        src={url}
                        alt={`Certificate Image ${index + 1}`}
                        style={imgStyles}
                        onClick={() => handleTemplateSelection(url)}
                    />
                </div>
            ))}
        </div>
    );
}

export default TemplatesPanel;
