import {
	FETCH_WALL_POST_LIKES_REQUEST,
	FETCH_WALL_POST_LIKES_SUCCESS,
	FETCH_WALL_POST_LIKES_FAILURE,
} from '../../constants';

import { pharmacy_user_post_likes } from '../../../networking/urlEndPoints';
import Axios from '../../../networking/intraceptor';

export const addWallPostLikesRequest = () => ({
	type: FETCH_WALL_POST_LIKES_REQUEST,
});

export const addWallPostLikesSuccess = (likes) => ({
	type: FETCH_WALL_POST_LIKES_SUCCESS,
	payload: likes,
});

export const addWallPostLikesFailure = (error) => ({
	type: FETCH_WALL_POST_LIKES_FAILURE,
	payload: error,
});

export const addWallPostLikes = (post_id, like_unlike) => {
	return async (dispatch) => {
		dispatch(addWallPostLikesRequest())
		try {
			const response = await Axios.post(`${pharmacy_user_post_likes}`, {
				post_id: post_id,
				like_unlike: like_unlike,
			})
			if (response?.status === 200) {
				dispatch(addWallPostLikesSuccess(response?.data))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(addWallPostLikesFailure(error?.response))
			}
		}
	}
}
