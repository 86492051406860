import React, { useRef, useState } from 'react'

import {
	handleHideModal,
	handleShowModal
} from '../../assets/js/custome'

import { useCustomSelectors } from '../../services/selectors/allSelectors'
import { initalCompoundsFormData } from '../../utils/constants/formInitialStates'

import { useParams } from 'react-router-dom'
import { SuccessToastMessage } from '../../components/loaders/toastMessage'
import { handleAddNewCompound } from '../../utils/validations/compoundValidations'

import { PlusIcon } from '../../assets/svg/icons'
import { Errors } from '../../components/error/alertMessages'
import { pharmacyCompoundDataFailure } from '../../services/actions/pharmacy/other/pharmacyAddDataRequest'

import * as Pharmacy from '../../components/pharmacy/form/form'
import * as Hooks from '../../hooks'
import { useRemoveModalEffect } from '../../utils/helper/useEffectsHelper'

export const AddNewCompound = () => {

	const dispatch = Hooks.useCustomDispatch()
	const { id } = useParams();
	const modalRef = useRef()
	const compound_id = parseInt(id);

	const { pharmacyAddDataResponse } = useCustomSelectors();

	const [showModal, setShowModal] = useState({ compoundModal: false, showToast: false })
	const [formData, setFormData] = useState(initalCompoundsFormData)
	const [errorMessages, setErrorMessages] = useState(initalCompoundsFormData)

	const handleFormDataChange = (e) => {
		const { name, value } = e.target
		setFormData({
			...formData,
			[name]: value
		})
		setErrorMessages({ ...errorMessages, quantity: '' });
	}

	useRemoveModalEffect(setShowModal, showModal, 'compoundModal', modalRef)

	return (
		<>
			<button type="button" onClick={() => {
				return setErrorMessages({ ...errorMessages, price: '', quantity: '', days: '', toPharmacy: '' }),
					setFormData({ ...formData, price: '', quantity: '', days: '', toPharmacy: '' }),
					handleShowModal(setShowModal, showModal, 'compoundModal'),
					dispatch(pharmacyCompoundDataFailure(null))
			}}
				className="blueBg-color w-32 hover:blueBg-color gap-2 flex py-4 items-center justify-center text-white bg-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white">
				<PlusIcon color="white" /> Add New
			</button>
			{showModal.compoundModal &&
				<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center 
						justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">

					<div className="w-full max-w-2xl" ref={modalRef}>
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<button onClick={() => handleHideModal(setShowModal, showModal, 'compoundModal')} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
								<svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
								</svg>
								<span className="sr-only">Close modal</span>
							</button>
							<h1 className='rubik-500 pl-4 pt-4' style={{ fontSize: 20 }}>Add List</h1>
							<hr />
							<div className='px-4'>
								<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4" >
									<Pharmacy.ToPharmacy
										formData={formData}
										errorMessages={errorMessages}
										setFormData={setFormData}
										setErrorMessages={setErrorMessages}
									/>
									<div className='relative mt-4'>
										<input
											onKeyPress={(e) => {
												if (isNaN(String.fromCharCode(e.charCode))) {
													e.preventDefault();
												}
											}}
											type="text"
											id="quantity"
											onChange={handleFormDataChange}
											name="quantity"
											value={formData.quantity}
											className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white 
										${errorMessages.quantity && 'border-red-600'}`}
											placeholder=""
										/>
										<label
											htmlFor="quantity"
											className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
											{!errorMessages.quantity && 'Quantity'}
										</label>
										{errorMessages.quantity && (
											<p className="mt-2 text-xs text-red-600 dark:text-red-400">
												{errorMessages?.quantity}
											</p>
										)}
									</div>
								</div>
								<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mt-4" >

									<div className='relative'>
										<input
											onKeyPress={(e) => {
												if (isNaN(String.fromCharCode(e.charCode))) {
													e.preventDefault();
												}
											}}
											type="text"
											id="price"
											onChange={handleFormDataChange}
											name="price"
											value={formData.price}
											className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white 
										${errorMessages.price && 'border-red-600'}`}
											placeholder=""
										/>
										<label
											htmlFor="price"
											className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
											{!errorMessages.price && 'Price'}
										</label>
										{errorMessages.price && (
											<p className="mt-2 text-xs text-red-600 dark:text-red-400">
												{errorMessages.price}
											</p>
										)}
									</div>
									<div className='relative'>
										<input
											onKeyPress={(e) => {
												if (isNaN(String.fromCharCode(e.charCode))) {
													e.preventDefault();
												}
											}}
											type="text"
											id="days"
											onChange={handleFormDataChange}
											name="days"
											value={formData.days}
											className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white 
										${errorMessages.days && 'border-red-600'}
										`}
											placeholder=""
										/>
										<label
											htmlFor="days"
											className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
											{!errorMessages.days && 'Days'}
										</label>
										{errorMessages.days && (
											<p className="mt-2 text-xs text-red-600 dark:text-red-400">
												{errorMessages.days}
											</p>
										)}
									</div>
								</div>
							</div>

							{pharmacyAddDataResponse.error !== null &&
								< div className='px-4 py-4'>
									<Errors error={'Something went wrong while adding compound price. please try to add again'} />
								</div>}

							<div className='container gap-2 mt-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<button type="button" onClick={() => {
									return handleAddNewCompound(formData, setErrorMessages, errorMessages, compound_id, showModal, setShowModal, dispatch)
								}}
									className="text-white blueBg-color hover:blueBg-color border border-gray-200 focus:ring-4 focus:outline-none 
									focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center 
									dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">

									{pharmacyAddDataResponse?.loading ?
										<p className='flex'>Adding...</p>
										: ' Save List'}

								</button>

								<button onClick={() => handleHideModal(setShowModal, showModal, 'compoundModal')}
									data-modal-hide="popup-modal" type="button"
									className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium 
												rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 
												dark:text-white dark:hover:bg-gray-700 me-2 mb-2">Cancel
								</button>

							</div>
						</div>
					</div>
				</div >}

			{showModal?.showToast && <SuccessToastMessage status={'Compound Added Successfully'} />}
		</>
	)
}
