import React, {
	useEffect,
	useRef,
	useState
} from "react";

import { useCustomDispatch } from "../../../hooks";

import { pharmacyUpdatedDailyDispenselogStatus } from "../../../services/actions/pharmacy/dispense/addDispenseData";
import { fetchAllDeliveryDriversList } from "../../../services/actions/pharmacy/dispense/deliveryDeriversLists";
import { useDispenseSelectors } from "../../../services/selectors/dispenseSelectors";

import Select from "react-select";
import { initialPharmacBlisterTodaysDate, initialPharmacyDispenseTodaysDate } from "../../../assets/js/datepicker";
import { fetchPatientsRouteList } from "../../../services/actions/pharmacy/dispense/routeList";
import * as style from '../../../style';

export const PatientName = ({
	formData,
	setFormData
}) => {
	return (
		<div className="relative">
			<input type="text" id="patient_name"
				onChange={(e) => setFormData({ ...formData, patient_name: e.target.value })}
				name="patient_name"
				value={formData.patient_name}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
				dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
				bg-white `}
				placeholder=""
			/>
			<label htmlFor="patient_name"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
							origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
							peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
							peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
				Patient Name
			</label>
		</div>
	);
};

export const Search = ({
	formData,
	setFormData
}) => {
	return (
		<div className="relative">
			<input type="text" id="search"
				onChange={(e) => setFormData({ ...formData, search: e.target.value })}
				name="search"
				value={formData.search}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
				dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
				bg-white `}
				placeholder=""
			/>
			<label htmlFor="search"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
							origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
							peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
							peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
				Search
			</label>
		</div>
	);
};

const isTwoDigitNumber = (value) => {
	return /^\d{1,2}$/.test(value);
};

export const TextInput = ({
    formData,
    setFormData,
    value,
    label = "No Label",
    name = "",
    enable = false,
    height = "42px",
    textType = 'text',
    numberValue,
    hour,
    maxLength,
    onKeyPress // New prop
}) => {

    const handleChange = (e) => {
        const value = e.target.value;

        if (hour && value > 12) {
            return; 
        }

        if (numberValue) {
            if (value === '' || isTwoDigitNumber(value)) {
                setFormData({ ...formData, [name]: value });
            }
        } else {
            if (name === "fax" || name === "phone") {
                if  (value === "" || (value.length <= 13 && /^[0-9\s\-+()]*$/.test(value))) {
                    setFormData({ ...formData, [name]: value });
                }
            } else {
                setFormData({ ...formData, [name]: value });
            }
        }
    };

    const handleKeyPress = (e) => {
        if (onKeyPress) {
            onKeyPress(e);
        }
    };
        
    return (
        <div className="relative w-full">
            <input type={textType} id={name} autoComplete="off"
                onChange={handleChange}
                onKeyPress={handleKeyPress} // New line
                name={name}
                style={{ height: height }}
                value={formData[name]}
                className={`block pl-4 ${enable && 'bg-gray-100'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                bg-white `}
                placeholder=""
                disabled={enable} 
                />
            <label htmlFor={name}
                className={`ml-4 absolute ${enable && 'bg-gray-100'} text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                            origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                            peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                            peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                {label}
            </label>
        </div>
    );
};

export const RouteList = ({
	formData,
	setFormData,
	placeholder = 'Select Route'
}) => {

	const dispatch = useCustomDispatch();

	const { pharmacyPatientRouteListResponse } = useDispenseSelectors();
	const patientRouteList = pharmacyPatientRouteListResponse?.data?.data

	const [activeRouteList, setActiveRouteList] = useState([]);

	useEffect(() => {
		const list = patientRouteList || [];
		const formattedRouteList = list?.map((value, index) => ({
			label: value,
			value: index,
		}));

		setActiveRouteList(formattedRouteList);
	}, [patientRouteList]);

	const handleGetActivePatientList = () => {
		if (pharmacyPatientRouteListResponse?.data === null) {
			dispatch(fetchPatientsRouteList());
		}
	}

	return (
		<div onClick={handleGetActivePatientList}>
			<Select
				isMulti={false}
				onChange={(selectedValue) => {
					setFormData({
						...formData,
						patientRoutes: selectedValue
					})
				}}
				value={formData?.patientRoutes}
				options={activeRouteList}
				classNamePrefix="react-select"
				styles={style.RouteStyle}
				placeholder={placeholder}
				isSearchable={true}
				noOptionsMessage={() =>
					pharmacyPatientRouteListResponse?.loading ? "Loading..." : "No Options"
				}
				className="basic-multi-select"
			/>
		</div>
	)
}

export const DriverList = ({
	formData,
	setFormData,
}) => {

	const dispatch = useCustomDispatch();

	const { deliveryDriversListResponse, deliveryDriversLists } =
		useDispenseSelectors();

	const [activeDrivers, setActiveDrivers] = useState([]);

	useEffect(() => {
		const list = deliveryDriversLists || [];
		const formattedDerivers = list?.map((value) => ({
			label: `${value.name}`,
			value: value.id,
		}));

		setActiveDrivers(formattedDerivers);
	}, [deliveryDriversLists]);

	const handleGetActivePatientList = () => {
		if (deliveryDriversListResponse?.data === null) {
			dispatch(fetchAllDeliveryDriversList());
		}
	};

	return (
		<div onClick={handleGetActivePatientList}>
			<Select
				isMulti={false}
				onChange={(selectedValue) => {
					setFormData({
						...formData,
						driver: selectedValue
					})
				}}
				value={formData?.driver}
				options={activeDrivers}
				classNamePrefix="react-select"
				styles={style.RouteStyle}
				placeholder="Select Driver"
				isSearchable={false}
				noOptionsMessage={() =>
					deliveryDriversListResponse?.loading ? "Loading..." : "No Options"
				}
				className="basic-multi-select z-20"
			/>
		</div>
	)
}

export const StatusList = ({
	formData,
	setFormData,
	deliveryStatus
}) => {

	const [activeList, setActiveList] = useState([
		{
			"label": "Pending",
			"value": "pending"
		},
		{
			"label": "Missed",
			"value": "missed"
		},
		{
			"label": "Delivered",
			"value": "delivered"
		},
		{
			"label": "Pending & Missed",
			"value": "pending_and_missed"
		},
		{
			"label": "Waiting",
			"value": "waiting"
		}
	]);

	return (
		<div >
			<Select
				isMulti={false}
				onChange={(selectedValue) => {
					return setFormData({ ...formData, deliveryStatus: selectedValue })
				}}
				isSearchable={false}
				value={formData?.deliveryStatus}
				options={activeList}
				classNamePrefix="react-select"
				styles={style.RouteStyle}
				placeholder="Select Status"
				className="basic-multi-select"
				menuShouldScrollIntoView
			/>
		</div>
	)
}

export const StartDate = ({ formData, setFormData, label = 'Date', name = 'date', dValue = formData?.date, height = '42px' }) => {

	const DateRef = useRef();
	const dispatch = useCustomDispatch()

	useEffect(() => {
		const startDOBPickerInstance = initialPharmacyDispenseTodaysDate(
			DateRef,
			setFormData,
			formData,
			'update_label',
			dispatch
		);
		return () => {
			if (startDOBPickerInstance) {
				startDOBPickerInstance.destroy();
			}
		};
	}, [DateRef, formData]);

	return (
		<div className="relative">
			<input
				style={{ height: height }}
				type="text"
				id="text1"
				ref={DateRef}
				name={name}
				onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
				value={dValue}
				className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
				placeholder=" "
			/>
			<label
				htmlFor="text1"
				className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
			>
				{label}
			</label>
		</div>
	);
};

export const MuteUnMutelist = ({
	formData,
	setFormData,
	placeholder = 'Select Status'
}) => {

	const [activeList, setActiveList] = useState([
		{
			"label": "Mute",
			"value": 1
		},
		{
			"label": "Unmute",
			"value": 0
		},
	]);

	return (
		<div >
			<Select
				isMulti={false}
				onChange={(selectedValue) => {
					return setFormData({ ...formData, muteValue: selectedValue })
				}}
				isSearchable={false}
				value={formData?.muteValue}
				options={activeList}
				classNamePrefix="react-select"
				styles={style.RouteStyle}
				placeholder={placeholder}
				className="basic-multi-select"
			/>
		</div>
	)
}


export const BlisterStartDate = ({ date, setDate, label = 'Date' }) => {
	const DateRef = useRef();

	useEffect(() => {
		const startDOBPickerInstance = initialPharmacBlisterTodaysDate(
			DateRef,
			(updatedDate) => setDate(updatedDate),
			{ date }
		);

		return () => {
			if (startDOBPickerInstance) {
				startDOBPickerInstance.destroy();
			}
		};
	}, [DateRef, date, setDate]);

	return (
		<div className="relative">
			<input
				style={{ height: '42px' }}
				type="text"
				id="text1"
				ref={DateRef}
				name="date"
				onChange={(e) => setDate(e.target.value)}
				value={date || ''}
				className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
				placeholder=" "
			/>
			<label
				htmlFor="text1"
				className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
			>
				{label}
			</label>
		</div>
	);
};

export const LabelInput = ({
	formData,
	setFormData,
	label = "No Label",
	name = "",
	index,
	color = ""
}) => {
	// const handleChange = (e) => {
	// 	const updatedFormData = [...formData];
	// 	updatedFormData[index] = {
	// 		...updatedFormData[index],
	// 		[name]: e.target.value
	// 	};
	// 	setFormData(updatedFormData);
	// };

	const handleChange = (e) => {
		const updatedFormData = formData.map((item, i) =>
			i === index ? { ...item, [name]: e.target.value } : item
		);
		setFormData(updatedFormData);
	};


	return (
		<div className="relative w-full">
			<input type="text" id={`${name}-${index}`} autoComplete="off"
				onChange={handleChange}
				name={name}
				style={{ height: '42px', border: color }}
				value={formData[index][name]}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                bg-white `}
				placeholder=""
				// onKeyPress={(e) => {
				// 	if (isNaN(String.fromCharCode(e.charCode))) {
				// 		e.preventDefault();
				// 	}
				// }}

				onKeyPress={(e) => {
					const char = String.fromCharCode(e.charCode);
					if (name === 'dose') {
						const value = e.target.value;
						if (!/^\d*\.?\d*$/.test(value + char)) {
							e.preventDefault();
						}
					} else if (isNaN(char)) {
						e.preventDefault();
					}
				}}
			/>
			<label htmlFor={`${name}-${index}`}
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                            origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                            peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                            peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
				{label}
			</label>
		</div>
	);
};

export const DropdownSelect = ({
	formData,
	setFormData,
	label = "No Label",
	name = "",
	options = [],
	height = "auto",
	classnm = 'w-full',
	padding = 'pb-2.5 pt-4'
}) => {
	return (
		<div className="relative w-full">
			<select
				style={{ height: height,overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis' }}
				id={name}
				name={name}
				value={formData[name] || ''}
				onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
				className={`block pl-4 px-2.5 ${padding} ${classnm} text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
                bg-white `}
			>

				{options.map((option, index) => (
					<option key={index} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
			<label htmlFor={name}
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                            origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                            peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                            peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
				{label}
			</label>
		</div>
	);
};