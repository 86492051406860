import React, {
	useEffect,
	useRef,
	useState
} from "react";

import {
	FolderIcon,
	ImageIcon,
	PDfICon,
	PlusIcon
} from "../../../assets/svg/icons";

import {
	fetchAllFoldersFromS3,
	fetchImagesFromS3,
	filemanagerFolderLoader,
} from "../../../utils/helper/helper";

import { initDropdowns } from "flowbite";
import { Footer, Sidebar } from "../../../components/common";
import { ProfilePicLoader } from "../../../components/common/loadingView";
import { useCustomSelectors } from "../../../services/selectors/allSelectors";

import { CloseOutlined } from "@mui/icons-material";
import AWS from "aws-sdk";
import * as path from "../../../networking/urlEndPoints";
import ChatBox from "../../../components/chat/chatbox";

const CreateFileManager = () => {
	const fileInputRef = useRef();

	const [open, setOpen] = useState(false);
	const [folders, setFolders] = useState([]);
	const [files, setFiles] = useState([]);
	const [breadcrumbs, setBreadcrumbs] = useState([]);

	const [filesLoader, setFilesLoader] = useState(false);
	const [folderLoaders, setFolderLoader] = useState(false);
	const [loading, setLoading] = useState(false);

	const [currentFolder, setCurrentFolder] = useState(null);

	const [folderName, setFolderName] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const { pharmacyId } = useCustomSelectors();

	const handleFolderClicks = (folder) => {
		setLoading(true);
		const updatedBreadcrumbs = [...breadcrumbs, folder];
		setCurrentFolder(folder);
		setBreadcrumbs(updatedBreadcrumbs);
		setCurrentFolder(folder);
		fetchAllFoldersFromS3(
			updatedBreadcrumbs.join("/"),
			setFolders,
			pharmacyId,
			setLoading,
			null
		);
	};

	const handleBreadcrumbClick = (index) => {
		const updatedBreadcrumbs = breadcrumbs.slice(0, index + 1);
		setBreadcrumbs(updatedBreadcrumbs);
		setCurrentFolder(updatedBreadcrumbs.slice(-1)[0]);
		fetchAllFoldersFromS3(
			updatedBreadcrumbs.join("/"),
			setFolders,
			pharmacyId,
			null,
			null
		);
	};

	const handleFolderCreate = async () => {
		if (!folderName) {
			setErrorMessage("Please Enter the Folder Name");
			return;
		}

		if (folders.includes(folderName)) {
			setErrorMessage("Folder name already exists");
			return;
		}
		setFolderLoader(true);
		const s3 = new AWS.S3({
			accessKeyId: path.awsAccessKey,
			secretAccessKey: path.awsSecretKey,
			region: path.awsDefaultRegion,
		});

		let params;
		if (breadcrumbs?.length > 0) {
			const folderPath = breadcrumbs.join("/");
			params = {
				Bucket: path.awsBuketName,
				Key: `pharmacy_file_manager/${pharmacyId}/all_folders/${folderPath}/${folderName}/`,
				Body: "",
			};
		} else {
			params = {
				Bucket: path.awsBuketName,
				Key: `pharmacy_file_manager/${pharmacyId}/all_folders/${folderName}/`,
				Body: "",
			};
		}

		try {
			await s3.putObject(params).promise();
			if (breadcrumbs?.length > 0) {
				fetchAllFoldersFromS3(
					breadcrumbs.join("/"),
					setFolders,
					pharmacyId,
					null,
					null
				);
			} else {
				fetchAllFoldersFromS3(null, setFolders, pharmacyId, null, null);
			}
			setErrorMessage("");
			setFolderLoader(false);
			setOpen(false);
		} catch (error) {
			setFolderLoader(false);
			setErrorMessage(
				"Something went wrong while creating the folder. Please try again."
			);
		}
		setFolderName("");
	};

	const handleFileChange = async (e) => {
		const files = Array.from(e.target.files);
		const selectedFiles = files.filter(
			(file) =>
				file.type.startsWith("image/") || file.type === "application/pdf"
		);
		setFilesLoader(true);

		const s3 = new AWS.S3({
			accessKeyId: path.awsAccessKey,
			secretAccessKey: path.awsSecretKey,
			region: path.awsDefaultRegion,
		});

		Array.from(selectedFiles).forEach(async (file) => {
			let params;
			if (breadcrumbs.length > 0) {
				const folderPath = breadcrumbs.join("/");
				params = {
					Bucket: path.awsBuketName,
					Key: `pharmacy_file_manager/${pharmacyId}/all_folders/${folderPath}/${file.name}`,
					Body: file,
				};
			} else {
				params = {
					Bucket: path.awsBuketName,
					Key: `pharmacy_file_manager/${pharmacyId}/all_files/${file.name}`,
					Body: file,
				};
			}

			try {
				await s3.upload(params).promise();
				if (breadcrumbs?.length > 0) {
					fetchAllFoldersFromS3(
						breadcrumbs?.join("/"),
						setFolders,
						pharmacyId,
						null,
						"uploadImage"
					);
				} else {
					fetchImagesFromS3(setFiles, pharmacyId);
				}
				setFilesLoader(false);
			} catch (error) {
				setErrorMessage(
					"Something went wrong while uploading files please try to load again..!!"
				);
				setFilesLoader(false);
			}
		});
	};

	useEffect(() => {
		fetchImagesFromS3(setFiles, pharmacyId);
		fetchAllFoldersFromS3(null, setFolders, pharmacyId, null, null);
		return () => { };
	}, []);

	function downloadFile(image) {
		const bucketUrl = "https://oatrxemployee.s3.amazonaws.com/";
		const fileUrl = bucketUrl + image;
		window.open(fileUrl, "_blank");
	}

	function downloadFolderFile(name) {
		if (!name) return;
		const url = `https://oatrxemployee.s3.amazonaws.com/pharmacy_file_manager/${pharmacyId}/all_folders/${breadcrumbs.join(
			"/"
		)}/${name}`;
		const extension = name.split(".").pop().toLowerCase();
		if (["jpg", "jpeg", "png", "svg", "pdf"].includes(extension)) {
			window.open(url, "_blank");
		}
	}

	useEffect(() => {
		initDropdowns();
	}, [folders, files]);

	const deleteFolder = (folderName, type) => {
		if (!folderName) return;

		const s3 = new AWS.S3({
			accessKeyId: path.awsAccessKey,
			secretAccessKey: path.awsSecretKey,
			region: path.awsDefaultRegion,
		});

		let params;
		if (breadcrumbs?.length > 0 && type === null) {
			const folderPath = breadcrumbs.join("/");
			params = {
				Bucket: path.awsBuketName,
				Prefix: `pharmacy_file_manager/${pharmacyId}/all_folders/${folderPath}/${folderName}/`,
			};
		} else if (type === "delete_image") {
			params = {
				Bucket: path.awsBuketName,
				Prefix: folderName,
			};
		} else {
			params = {
				Bucket: path.awsBuketName,
				Prefix: `pharmacy_file_manager/${pharmacyId}/all_folders/${folderName}/`,
			};
		}

		s3.listObjectsV2(params, (err, data) => {
			if (err) {
				console.error("Error listing objects:", err);
				return;
			}

			const objectsToDelete = {
				Bucket: path.awsBuketName,
				Delete: { Objects: [] },
			};

			data.Contents.forEach((obj) => {
				objectsToDelete.Delete.Objects.push({ Key: obj.Key });
			});
			setLoading(true);
			s3.deleteObjects(objectsToDelete, (err, data) => {
				if (err) {
					setLoading(false);
					return;
				}
				if (type === "delete_image") {
					return fetchImagesFromS3(setFiles, pharmacyId), setLoading(false);
				} else
					fetchAllFoldersFromS3(
						breadcrumbs.join("/"),
						setFolders,
						pharmacyId,
						setLoading,
						null
					);
			});
		});
	};

	const deleteFile = (fileName) => {
		if (!fileName) return;

		const s3 = new AWS.S3({
			accessKeyId: path.awsAccessKey,
			secretAccessKey: path.awsSecretKey,
			region: path.awsDefaultRegion,
		});

		let params;
		params = {
			Bucket: path.awsBuketName,
			Prefix: fileName,
		};

		s3.listObjectsV2(params, (err, data) => {
			if (err) {
				console.error("Error listing objects:", err);
				return;
			}

			const objectsToDelete = {
				Bucket: path.awsBuketName,
				Delete: { Objects: [] },
			};

			data.Contents.forEach((obj) => {
				objectsToDelete.Delete.Objects.push({ Key: obj.Key });
			});
			setLoading(true);
			s3.deleteObjects(objectsToDelete, (err, data) => {
				if (err) {
					setLoading(false);
					return;
				}
				fetchImagesFromS3(setFiles, pharmacyId);
				setLoading(false);
			});
		});
	};

	return (
		<React.Fragment>
			<div className="BodyBgColor">
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 bg-white p-4" style={{ minHeight: '50%' }}>
								<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 ">
									<div className="flex">
										<button
											className="blueBg-color w-54 hover:blueBg-color ml-2 flex py-4 items-center justify-center text-white bg-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white"
											onClick={() => setOpen(true)}
										>
											<PlusIcon color="white" style={{ marginRight: 10 }} />{" "}
											Create Folder
										</button>
										<button
											onClick={() => fileInputRef.current.click()}
											className="flex py-4 items-center w-54 left-2 relative justify-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
										>
											Upload Files
											{filesLoader && (
												<>
													&nbsp;
													<ProfilePicLoader />
												</>
											)}
										</button>

										<input
											type="file"
											ref={fileInputRef}
											multiple
											style={{ display: "none" }}
											onChange={handleFileChange}
										/>
									</div>
								</div>

								<div>
									<div className="grid grid-cols-12 gap-4 mt-2">
										<div className="mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">
											<nav className="flex" aria-label="Breadcrumb">
												<ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
													{currentFolder && (
														<li
															onClick={() => {
																return (
																	setBreadcrumbs([]),
																	setCurrentFolder(null),
																	setFolders([]),
																	fetchAllFoldersFromS3(
																		null,
																		setFolders,
																		pharmacyId,
																		null,
																		null
																	)
																);
															}}
															className="btnnn inline-flex items-center"
														>
															<a className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
																Home
															</a>
														</li>
													)}

													{currentFolder && (
														<>
															{breadcrumbs?.map((value, index) => {
																return (
																	<li
																		key={index}
																		onClick={() => handleBreadcrumbClick(index)}
																		className="btnnn"
																	>
																		<div className="flex items-center">
																			<svg
																				className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
																				aria-hidden="true"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="none"
																				viewBox="0 0 6 10"
																			>
																				<path
																					stroke="currentColor"
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					strokeWidth="2"
																					d="m1 9 4-4-4-4"
																				/>
																			</svg>
																			{value}
																		</div>
																	</li>
																);
															})}
														</>
													)}
												</ol>
											</nav>
										</div>
									</div>

									{loading ? (
										<div className="flex flex-wrap mt-4">
											{filemanagerFolderLoader?.map((value, index) => {
												return (
													<div
														key={index}
														className="w-36 h-28 bg-white-200 gap-2 mr-2 rounded-lg shadow-md flex justify-center items-center"
													>
														<div className="max-w-sm animate-pulse">
															<div className="w-14 h-14 bg-gray-300 rounded-lg mb-2"></div>
															<div className="w-14 h-2 bg-gray-300 rounded-full"></div>
															<span className="sr-only">Loading...</span>
														</div>
													</div>
												);
											})}
										</div>
									) : (
										<div className="flex flex-wrap gap-2">
											<div>
												<ul className="folder-list flex flex-wrap gap-2 mt-4">
													{folders?.map((folder, index) => {
														const extension = folder
															?.split(".")
															?.pop()
															?.toLowerCase();
														return (
															<React.Fragment key={index}>
																{folder === "" ? null : extension === "jpg" ||
																	extension === "jpeg" ||
																	extension === "png" ||
																	extension === "svg" ? (
																	<li className="btnnn">
																		<div className="folder relative flex flex-col items-center justify-center break-all text-center">

																			<div
																				onClick={() =>
																					downloadFolderFile(folder)
																				}
																			>
																				<ImageIcon />
																			</div>
																			<p className="text-gray-500 rubik-500">
																				{folder}
																			</p>
																		</div>
																	</li>
																) : extension === "pdf" ? (
																	<li className="btnnn">
																		<div className="folder relative flex flex-col items-center justify-center break-all text-center">

																			<div
																				onClick={() =>
																					downloadFolderFile(folder)
																				}
																			>
																				<PDfICon />
																			</div>
																			<p className="text-gray-500 rubik-500">
																				{folder}
																			</p>
																		</div>
																	</li>
																) : (
																	<li className="btnnn">
																		<div className="folder relative flex flex-col items-center justify-center break-all text-center">
																			<button
																				className="absolute top-1 right-1 text-sm font-medium text-center rounded-lg"
																				type="button"
																				onClick={() => {
																					deleteFolder(folder, null);
																				}}
																			>
																				<CloseOutlined />
																			</button>

																			<div
																				onClick={() =>
																					handleFolderClicks(folder)
																				}
																			>
																				<FolderIcon />
																			</div>
																			<p
																				className="text-gray-500 rubik-500"
																			// style={{ marginTop: "-30px " }}
																			>
																				{folder}
																			</p>
																		</div>
																	</li>
																)}
															</React.Fragment>
														);
													})}
												</ul>
											</div>
											{breadcrumbs?.length === 0 && (
												<div>
													<ul className="folder-list flex flex-wrap gap-2 mt-4">
														{files?.map((image, index) => {
															const extension = image
																.split(".")
																.pop()
																.toLowerCase();
															return (
																<li key={index} className="btnnn">
																	<div className="folder relative flex flex-col items-center justify-center break-all text-center">
																		<button
																			className="absolute top-1 right-1 text-sm font-medium text-center rounded-lg"
																			type="button"
																			onClick={() => {
																				deleteFile(image);
																			}}
																		>
																			<CloseOutlined />
																		</button>
																		{extension === "jpg" ||
																			extension === "jpeg" ||
																			extension === "png" ? (
																			<div onClick={() => downloadFile(image)}>
																				<ImageIcon />
																			</div>
																		) : extension === "pdf" ? (
																			<div
																				onClick={() => downloadFile(image)}
																				className="btnnn"
																			>
																				<PDfICon />
																			</div>
																		) : null}
																		<p className="text-gray-500 rubik-500">
																			{image?.slice(36)}
																		</p>
																	</div>
																</li>
															);
														})}
													</ul>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{open ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-auto my-6 mx-auto max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-center justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
									<h3 className="text-2xl font-semibold">Create Folder</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setOpen(false)}
									>
										<svg
											class="w-3 h-3"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 14 14"
										>
											<path
												stroke="red"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
											/>
										</svg>
									</button>
								</div>
								{/*body*/}
								<div class="p-4 md:p-5 space-y-4 flex items-center justify-center flex-col">
									<input
										type="text"
										placeholder="Enter folder name"
										value={folderName}
										onChange={(e) => setFolderName(e.target.value)}
										className={`block pl-4 px-2.5 pb-2.5 pt-2 w-96 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white `}
									/>
									<p className="text-red-400 mt-2  rubik-500">{errorMessage}</p>
									<button
										className="blueBg-color w-54 hover:blueBg-color ml-2 flex py-4 items-center justify-center text-white bg-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white"
										onClick={handleFolderCreate}
									>
										Create Folder
										{folderLoaders && (
											<>
												&nbsp;
												<ProfilePicLoader color="white" />
											</>
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}

			<ChatBox />
			<Footer />
		</React.Fragment>
	);
};

export default CreateFileManager;
