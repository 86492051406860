import React, { useCallback, useEffect, useState } from "react";

import { BreadCrumb, Footer, LoadingView, Sidebar } from "../../../components/common";

import {
	useCustomDispatch,
	useCustomLocation,
	useCustomNavigate,
} from "../../../hooks";

import { debounce } from "lodash";
import { ErrorMessages } from "../../../components/error/alertMessages";
import { useCustomSelectors } from "../../../services/selectors/allSelectors";

import { ErrorToastMessage, SuccessToastMessage } from '../../../components/loaders/toastMessage';
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import { AddCompound } from "../../../models/compounds/addCompound";
import { DeleteCompound } from '../../../models/compounds/compoundDelete';

import ChatBox from "../../../components/chat/chatbox";

import * as path from "../../../networking/urlEndPoints";
import * as routeNames from "../../../routes/routeName";
import * as compounds from "../../../services/actions/pharmacy/other";

const CompoundPriceList = () => {

	const dispatch = useCustomDispatch();
	const location = useCustomLocation();
	const navigate = useCustomNavigate();

	const { pharmacyAllTableListResponse } = useCustomSelectors();
	const compoundPriceList = pharmacyAllTableListResponse?.data?.data;

	const [showModal, setShowModal] = useState({ deleteModal: false, id: null, url: null, showToast: false, errorShow: false })

	const initialPage = parseInt(new URLSearchParams(location.search).get("page")) || 1;

	const [tableList, setTableList] = useState({
		pageNumber: initialPage,
	});

	useEffect(() => {
		dispatch(
			compounds.fetchAllTableListData(
				path.pharmacy_compound_list,
				null,
				tableList?.pageNumber
			)
		);
		return () => { };
	}, []);

	const handleInputChange = useCallback(
		debounce((e) => {
			e.preventDefault();
			const search = e.target.value;
			dispatch(
				compounds.fetchAllTableListData(
					path.pharmacy_compound_list,
					search,
					null
				)
			);
		}, 1000),
		[dispatch, location.search, navigate]
	);

	useEffect(() => {
		setTableList({
			...tableList,
			pageNumber: initialPage,
		});
	}, [initialPage]);

	const handalShowModel = (id) => {
		setShowModal({
			...showModal,
			deleteModal: true,
			id: id,
			url: `${path.pharmacy_delete_compound}/${id}`
		})
	}

	return (
		<>
			<div className="BodyBgColor">
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="flex flex-wrap items-center gap-4">
											<div className="relative flex-0 w-full md:w-4/12">
												<input
													type="text"
													id="search"
													name="search"
													onChange={handleInputChange}
													className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
													placeholder=""
												/>
												<label
													htmlFor="search"
													className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
												>
													Search
												</label>
											</div>

											<div className="flex-0 ml-auto">
												<AddCompound />
											</div>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mt-2">
											<div className="">
												{pharmacyAllTableListResponse?.loading ? (
													<div className='relative flex justify-center items-center mt-60 mb-80'>
														<LoadingView />
													</div>
												) : pharmacyAllTableListResponse?.error !== null ? (
													<div className='relative flex justify-center items-center mt-60 mb-80'>
														<ErrorMessages />
													</div>
												) : compoundPriceList?.data?.length <= 0 ? (
													<h2>Compounds are not available.</h2>
												) : (
													<div className="shadow-md border relative overflow-x-auto rounded-lg">
														<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
															<thead className="text-xs text-white uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400">
																<tr>
																	<th
																		scope="col"
																		className="px-6 py-3 rounded-s-lg"
																	>
																		<p className="rubik-500 fs-14">
																			Compound Name
																		</p>
																	</th>
																	<th scope="col" className="px-6 py-3">
																		<p className="rubik-500 fs-14">List</p>
																	</th>
																	<th scope="col" className="px-6 py-3">
																		<p className="rubik-500 fs-14">View</p>
																	</th>
																	<th
																		scope="col"
																		className="px-6 py-3 rounded-e-lg"
																	>
																		<p className="rubik-500 fs-14">Action</p>
																	</th>
																</tr>
															</thead>

															<tbody>
																{compoundPriceList?.data?.map(
																	(value, index) => {
																		return (
																			<React.Fragment key={index}>
																				<tr className="bg-white dark:bg-gray-800 border-b dark:border-gray-700">
																					<th
																						scope="row"
																						className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
																					>
																						<p className="rubik-500 fs-14">
																							{value?.name}
																						</p>
																					</th>
																					<td className="px-6 py-4">
																						<p className="rubik-400 fs-14">
																							{value?.list}
																						</p>
																					</td>

																					<td className="px-6 py-4">
																						<a
																							onClick={() =>
																								navigate(
																									`${routeNames.pharmacy_compound_price_list}/${value.id}`
																								)
																							}
																							className="btnnn bg-blue-100 flex justify-center items-center text-blue-800 text-xs font-medium inline-flex items-center px-1.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400 flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
																						>
																							Price List
																						</a>
																					</td>

																					<td className="px-6 py-4">
																						<a
																							className="btnnn bg-red-100 flex justify-center items-center text-blue-800 text-xs font-medium inline-flex items-center px-1.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-red-400 flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
																							onClick={() => handalShowModel(value?.id)}
																						>
																							Delete
																						</a>
																					</td>
																				</tr>
																			</React.Fragment>
																		);
																	}
																)}
															</tbody>
														</table>
													</div>
												)}

												{pharmacyAllTableListResponse?.error !== null ? null : (
													<>
														{compoundPriceList?.data?.length !== 0 && (
															<>
																{compoundPriceList?.pagination?.last_page !==
																	1 && (
																		<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
																			<nav aria-label="Page navigation example">
																				<ul className="inline-flex -space-x-px text-base h-10">
																					<CustomPagination
																						count={
																							compoundPriceList?.pagination?.last_page
																						}
																						page={tableList?.pageNumber}
																						onChange={(event, value) => {
																							setTableList({
																								...tableList,
																								pageNumber: value,
																							});
																							dispatch(
																								compounds.fetchAllTableListData(
																									path.pharmacy_compound_list,
																									null,
																									value
																								)
																							);
																							const searchParams = new URLSearchParams();
																							searchParams.append("page", value);
																							const newUrl = `${location?.pathname
																								}?${searchParams.toString()}`;
																							navigate(newUrl);
																						}}
																					/>
																				</ul>
																			</nav>
																		</div>
																	)}
															</>
														)}
													</>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ChatBox />
			<Footer />
			{showModal?.deleteModal &&
				<DeleteCompound id={showModal?.id}
					setShowModal={setShowModal}
					showModal={showModal}
					type='pharmacy-compound'
					urlEndPoint={showModal?.url} />}

			{showModal?.showToast && <SuccessToastMessage status='Deleted Successfully' />}
			{showModal?.errorShow && <ErrorToastMessage />}

		</>
	);
};

export default CompoundPriceList;

