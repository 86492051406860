import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Sidebar } from '../../../components/common';
import ChatBox from '../../../components/chat/chatbox';
import * as Common from '../../../components/common';
import { PharmacyDepartments, PharmacyEmployee, PharmacyPermissions } from '../../../components/pharmacy/admin/form';
import { useCustomSelectors } from '../../../services/selectors/allSelectors';
import Button from '../../../components/common/button';
import { initalShowToastMessage } from '../../../utils/constants/formInitialStates';
import { useCustomDispatch } from '../../../hooks';
import { addUpdateDepartmentUsers, addUpdateSettingIndepartments, createNewPharmacyTeamDepartment } from '../../../services/actions/admin/team/addTeamData';
import { useAdminSelectors } from '../../../services/selectors/adminModuleSelectors';
import { ErrorToastMessage, SuccessToastMessage } from "../../../components/loaders/toastMessage";
import { useCustomLoadingStates } from '../../../hooks/customStates';
import { authToken } from '../../../storage/authToken';
import { fetchPharmacyDepartmentAssigns, fetchPharmacySettings, fetchPharmacyUser } from '../../../services/actions/admin/team/fetchTeamsList';
import { DeleteIcon } from '../../../assets/svg/icons';
import { handleShowModal } from '../../../assets/js/custome';
import { DeleteDepartment } from '../../../models/admin/editTaskList';

const AssignDepartments = () => {

    const [formData, setFormData] = useState({
        name: '',
        username: '',
        email: '',
        pharmacist: '',
        pharmacy_portal: '',
        employee_portal: '',
        profile_photo: '',
        passcode: '',
        department_name: '',
        admin_module: ''
    });

    // const [errorMessage, setErrorMessages] = useState({
    //     name: '',
    //     username: '',
    //     email: '',
    //     pharmacist: '',
    //     pharmacy_portal: '',
    //     employee_portal: '',
    //     profile_photo: '',
    //     passcode: '',
    //     department_name: ''
    // })

    const [isLoaded, setisLoaded] = useState(false)
    const { chatUserList, profileData, adminModule } = useCustomSelectors();
    const [newDepartmentError, setNewDepartmentError] = useState('');
    const [showToast, setShowToast] = useState(initalShowToastMessage);
    // const filteredChatUserList = chatUserList?.data?.data ? chatUserList.data.data.filter(user => user.user_id !== profileData?.user_id)
    //     : chatUserList?.data?.data;

    // const employeeList = filteredChatUserList;
    // let employeeList = ''
    const [departmentSelections, setDepartmentSelections] = useState([{ id: 1, selectedDepartments: [] }]);
    const [showAddDepartment, setShowAddDepartment] = useState(false);
    const [newDepartmentName, setNewDepartmentName] = useState('');
    const dispatch = useCustomDispatch();
    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors();
    const { apiCallMade, setApiCallMade } = useCustomLoadingStates()
    const token = authToken()
    const [departments, setDepartments] = useState([]);
    const [logData, setLogData] = useState(null)
    const [permissionDataDept, setpermissionData] = useState('')
    const [showModal, setShowModal] = useState({ deleteDept: false })
    const [empList, setEmpList] = useState([])
    const [deptError,SetDeptError] = useState('Something went wrong. Please try again later.');

    useEffect(() => {

        if (!apiCallMade && token && adminModule === 1) {
            const fetchData = async () => {
                await dispatch(fetchPharmacyDepartmentAssigns('fetch_pharmacy_department_assign_work'));
                await dispatch(fetchPharmacySettings('fetch_pharmacy_department_settings'));
                await dispatch(fetchPharmacyUser('fetch_pharmacy_department_users'));

                setApiCallMade(true);
                setisLoaded(true)
            };

            fetchData();
        }

    }, []);


    useEffect(() => {
        if (TeamListResponse?.data?.data?.department && TeamListResponse.type === 'fetch_pharmacy_department_assign_work') {
            const departments = TeamListResponse.data.data.department;
            
            // const newDepartmentSelections = departments.map((dept) => ({
            //     id: dept.id,
            //     name: dept.name,
            //     permissionSelections: dept.Permissions.map(perm => ({ value: perm.key, label: perm.name })),
            //     newEmployeeSelections: dept.selected_users.map(user => ({ value: user.id, label: user.name }))
            // }));

            //adder filter to remove user from newEmployeeSelections which is currently logged in
            const newDepartmentSelections = departments.map((dept) => ({
                id: dept.id,
                name: dept.name,
                permissionSelections: dept?.Permissions.map(perm => ({ value: perm.key, label: perm.name })),
                // newEmployeeSelections: dept?.selected_users
                //     .filter(user => user.id !== profileData?.user_id)
                //     .map(user => ({ value: user.id, label: user.name }))
                newEmployeeSelections:dept?.selected_users.map(user => ({ value: user.id, label: user.name }))
            }));
            setDepartmentSelections(newDepartmentSelections);
        }

        if (TeamListResponse.type === 'fetch_pharmacy_department_settings') {
            setpermissionData(TeamListResponse.data?.data)

        }

        if (TeamListResponse.type === 'fetch_pharmacy_department_users') {
            // employeeList = TeamListResponse.data?.data
            // let chatUser = TeamListResponse.data?.data ? TeamListResponse.data?.data.filter(user => user.user_id !== profileData?.user_id) : []
            setEmpList(TeamListResponse.data?.data)
        }

    }, [TeamListResponse]);


    const updatePermissions = (depId, selectedValues) => {
        const roles = selectedValues.map(item => item.value);
        const dataTobeSent = { department_id: depId };

        roles.forEach((role, index) => {
            dataTobeSent[`roles[${index}]`] = role;
        });

        dispatch(addUpdateSettingIndepartments('add_update_settings_in_department', setShowToast, dataTobeSent, setShowAddDepartment, setNewDepartmentName, setNewDepartmentError));
    };

    const updateUsers = (depId, selectedValues) => {
        const roles = selectedValues.map(item => item.value);
        const dataTobeSent = { department_id: depId };

        roles.forEach((role, index) => {
            dataTobeSent[`employees[${index}]`] = role;
        });

        dispatch(addUpdateDepartmentUsers('add_update_users_in_department', setShowToast, dataTobeSent, setShowAddDepartment, setNewDepartmentName, setNewDepartmentError));
    };


    const handleAddDepartmentClick = () => {
        setShowAddDepartment(true);
    };

    const handleSaveDepartment = () => {
        if (newDepartmentName.trim()) {
            const dataTobeSent = {
                'department_name': newDepartmentName
            }
            dispatch(createNewPharmacyTeamDepartment('create_new_department_name_pharmacy', setShowToast, dataTobeSent, setShowAddDepartment, setNewDepartmentName, setNewDepartmentError, setisLoaded,SetDeptError));

        } else {
            setNewDepartmentError('Department name is required');
        }
    };

    const handleCancelAddDepartment = () => {
        setShowAddDepartment(false);
        setNewDepartmentError('');
        setNewDepartmentName('');
    };

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="allRequest">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>


                                <div className="container mt-2">
                                    {isLoaded ? (
                                        <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                            <div className='w-full mb-4'>
                                                <div >
                                                    {!showAddDepartment ? (
                                                        <div style={{ maxWidth: '200px', height: '50px' }} className='ml-auto'>
                                                            <Button text="+ Add New Department" color="white" handleFilter={handleAddDepartmentClick} width="" />
                                                        </div>
                                                    ) : (
                                                        <div style={{ maxWidth: '330px', height: '45px !important' }} className='ml-auto'>
                                                            <div className="flex items-center  mb-0" >
                                                                <div className="relative w-full">
                                                                    <input
                                                                        type="text"
                                                                        id="newDepartment"
                                                                        autoComplete="off"
                                                                        name="newDepartment"
                                                                        style={{
                                                                            height: '42px',
                                                                            borderRadius: "0px",
                                                                            borderTopLeftRadius: "5px",
                                                                            borderBottomLeftRadius: "5px",
                                                                            borderColor: newDepartmentError ? 'red' : '#d1d5db'
                                                                        }}
                                                                        value={newDepartmentName}
                                                                        onChange={(e) => {
                                                                            setNewDepartmentName(e.target.value);
                                                                            if (e.target.value.trim()) {
                                                                                setNewDepartmentError('');
                                                                            }
                                                                        }}
                                                                        className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 appearance-none 
                dark:text-white dark:border-gray-200 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                bg-white ${newDepartmentError ? 'border-red-500' : 'border-gray-300'}`}
                                                                        placeholder=""
                                                                    />
                                                                    <label
                                                                        htmlFor="newDepartment"
                                                                        className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
                                                                    >
                                                                        Department Name
                                                                    </label>

                                                                </div>

                                                                <button
                                                                    onClick={handleSaveDepartment}
                                                                    style={{ height: '42px', borderRadius: "0px", background: '#3b82f6' }}
                                                                    className="px-2 py-1 bg-green-500 text-white rounded-tr"
                                                                >
                                                                    {addTeamDataResponse.loading && addTeamDataResponse.type === 'create_new_department_name_pharmacy' ? 'Saving...' : 'Save'}
                                                                </button>

                                                                <button
                                                                    onClick={handleCancelAddDepartment}
                                                                    style={{ height: '42px', borderRadius: "0px", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                                                                    className="px-4 py-1 bg-red-500 text-white rounded-br"
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                            {newDepartmentError && <p className="text-red-500 text-sm mt-0">{newDepartmentError}</p>}
                                                        </div>

                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-full mb-5">

                                                <div className="">
                                                    {departmentSelections.map((departmentSelection, index) => (
                                                        <div key={departmentSelection.id} className="mb-6 relative">
                                                            <h3 style={{ fontSize: "16px", fontWeight: "500" }} className="text-lg font-light mb-2">{departmentSelection.name}</h3>
                                                            <div className="flex flex-wrap md:flex-nowrap gap-4">
                                                                <div className="w-full md:w-1/2">
                                                                    <PharmacyPermissions
                                                                        index={index}

                                                                        selectedDepartments={departmentSelection.permissionSelections}
                                                                        setFormData={setFormData}

                                                                        onPermissionChange={(selectedValues, index) => {
                                                                            // console.log(selectedValues)
                                                                            updatePermissions(departmentSelection.id, selectedValues)
                                                                        }}
                                                                        permissionData={permissionDataDept}
                                                                    />
                                                                </div>
                                                                <div className="w-full md:w-1/2">
                                                                    <PharmacyEmployee
                                                                        employeeListData={empList}
                                                                        index={index}
                                                                        selectedEmployees={departmentSelection.newEmployeeSelections}
                                                                        setFormData={setFormData}
                                                                        onEmployeeChange={(selectedValues, index) => {
                                                                            updateUsers(departmentSelection.id, selectedValues)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        // onClick={() => handleDeleteDepartment(index,departmentSelection.id)}
                                                                        onClick={() => { return setLogData(departmentSelection.id), handleShowModal(setShowModal, showModal, 'deleteDept') }}
                                                                        className="top-0 right-0 mt-2 ml-3 text-red-500 hover:text-red-700"
                                                                    >
                                                                        <DeleteIcon color="red" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                        </div>
                                    ) :
                                        <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4" style={{height:"calc(100vh - 300px)"}}>
                                            <div style={{ height: '50vh' }} className="flex justify-center items-center ">
                                                <Common.LoadingView />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal.deleteDept &&
                <DeleteDepartment
                    setisLoaded={setisLoaded}
                    logData={logData}
                    setShowModal={setShowModal}
                    text={'Are you sure you want to delete this Department?'}
                    setShowToast={setShowToast} />}

            {showToast.successToast && addTeamDataResponse.type === 'deleteDept' && <SuccessToastMessage status={'Department Deleted successfully'} />}
            {showToast.showToast && addTeamDataResponse.type === 'add_update_settings_in_department' && <SuccessToastMessage status={'Updated successfully.'} />}
            {showToast.showToast && addTeamDataResponse.type === 'create_new_department_name_pharmacy' && <SuccessToastMessage status={'Department added successfully.'} />}
            {showToast.showToast && addTeamDataResponse.type === 'add_update_users_in_department' && <SuccessToastMessage status={'Updated successfully.'} />}

            {showToast.errorToast && <ErrorToastMessage status={deptError} />}
            {showToast.errorToast && addTeamDataResponse.type === 'deleteDept' && <ErrorToastMessage status={'Got an issue while deleting department. Please try again.'} />}
            <ChatBox />
        </>
    );
};

export default AssignDepartments;
