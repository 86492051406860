import React, { useEffect, useCallback } from 'react';
import { useCanvasContext } from '../../hooks/useCanvasContext';
import { AddImageToCanvas, DeleteObject, renderDeleteIcon, DeleteActiveObject } from '../../utils/imageUtils'; 

function ImagePanel() {
    const { canvas } = useCanvasContext();

    // Function to handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
            const dataURL = event.target.result;
            AddImageToCanvas(canvas, dataURL);
        };
        reader.readAsDataURL(file);
    };

    // Function to handle scaling
    const handleScaleChange = useCallback((event) => {
        const scale = parseFloat(event.target.value);
        const activeObject = canvas.getActiveObject();
        if (activeObject && scale > 0) {
            activeObject.scale(scale);
            canvas.renderAll();
        }
    }, [canvas]);

    // Function to handle rotation
    const handleRotationChange = useCallback((event) => {
        const rotation = parseInt(event.target.value);
        const activeObject = canvas.getActiveObject();
        if (activeObject && !isNaN(rotation)) {
            activeObject.rotate(rotation);
            canvas.renderAll();
        }
    }, [canvas]);

    // Effect to initialize canvas event listeners
    useEffect(() => {
        if (canvas) {
            // Event listeners for object selection and deselection
            canvas.on('selection:created', updateControls);
            canvas.on('selection:updated', updateControls);
            canvas.on('selection:cleared', clearControls);

            // Cleanup event listeners
            return () => {
                canvas.off('selection:created', updateControls);
                canvas.off('selection:updated', updateControls);
                canvas.off('selection:cleared', clearControls);
            };
        }
    }, [canvas]);

  
    const updateControls = useCallback(() => {
        const activeObject = canvas.getActiveObject();
      
    }, [canvas]);

    
    const clearControls = useCallback(() => {
    }, []);

    return (
        <div style={{ padding: '1rem 2rem' }}>
            <label className="block mb-2 text-sm font-medium text-white dark:text-white" htmlFor="file_input">
                Upload file
            </label>
            <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="file_input_help"
                id="file_input"
                type="file"
                accept='image/*'
                onChange={handleFileUpload}
            />
            <p className="mt-1 text-sm text-white dark:text-gray-300" id="file_input_help">
                SVG, PNG, JPG.
            </p>

            {/* Scale input */}
            <div className="mt-4">
                <label htmlFor="scale_input" className="block text-sm font-medium text-gray-200 dark:text-white">
                    Scale:
                </label>
                <input
                    type="range"
                    id="scale_input"
                    min="0.1"
                    max="3"
                    step="0.1"
                    defaultValue="1"
                    onChange={handleScaleChange}
                    className="w-full mt-1"
                />
            </div>

            {/* Rotation input */}
            <div className="mt-4">
                <label htmlFor="rotation_input" className="block text-sm font-medium text-gray-200 dark:text-white">
                    Rotation (degrees):
                </label>
                <input
                    type="number"
                    id="rotation_input"
                    min="-180"
                    max="180"
                    step="1"
                    defaultValue="0"
                    onChange={handleRotationChange}
                    className="w-full mt-1 border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-blue-500 transition duration-300 ease-in-out"
                />
            </div>

            {/* Delete button */}
            <div className="mt-4">
                <button
                    onClick={() => DeleteActiveObject(canvas)}
                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 ease-in-out"
                >
                    Delete Image
                </button>
            </div>

            {/* Send Backward and Forward buttons */}
            {/* <div className="mt-4">
                <button
                    onClick={() => sendBackward(canvas)}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-blue-600 transition duration-300 ease-in-out"
                >
                    Send Backward
                </button>
                <button
                    onClick={() => sendForward(canvas)}
                    className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300 ease-in-out"
                >
                    Send Forward
                </button>
            </div> */}
        </div>
    );
}

export default ImagePanel;
