import React, {
	useEffect,
	useState,
	useRef
} from 'react'

import {
	initalPatientAddressForm,
	initialPatientFormState,
	intitalPatientFormErrors
} from '../../utils/constants/formInitialStates'

import {
	useCustomDispatch,
	useCustomNavigate,
	useCustomLocation
} from '../../hooks'

import {
	handleUpdatePatientFormData,
	handleUpdatePatientAddress
} from '../../utils/data/handleFormDataChange'

import {
	AddressIcon,
	CalenderIcon,
	CityIcon,
	EditMode,
	EnvelopIcon,
	LocationIcon,
	ModalCloseIcon,
	PHNIcon,
	ProvinceIcon,
	SearchIcon,
	UserIcon,
	Visible
} from '../../assets/svg/icons'

import {
	onPlaceSetPateintAddresses
} from '../../assets/js/custome'

import {
	initialAddPateintDOBPicker,
} from '../../assets/js/datepicker'

import {
	Errors,
	SuccessMessage
} from '../../components/error/alertMessages'

import { Loader } from '../../components/common/loadingView'
import { autoSearchKey } from '../../networking/urlEndPoints'
import { usePlacesWidget } from 'react-google-autocomplete';
import { handleHideModal } from '../../assets/js/custome'
import { SuccessToastMessage } from '../../components/loaders/toastMessage'
import { initialPateintFormInputColor } from '../../style'
import { validatePatientUpdateProfileForm } from '../../utils/validations/others'
import { useCustomPatientSelectors } from '../../services/selectors/patientSelectors'

import * as routeName from '../../routes/routeName'

export const UpdatePatientsProfileData = ({ patientProfileData, paginationNumber, hideModal, queryStatus,
	querySearch, setShowModal, showModal }) => {
	const dobRef = useRef()
	const location = useCustomLocation()
	const [updatePatientFormData, setUpdatePatientFormData] = useState(initialPatientFormState)
	const [updatePatientAddress, setUpdatePatientAddress] = useState(initalPatientAddressForm)

	const [formDataBorderColor, setFormDataBorderColor] = useState(initialPateintFormInputColor)
	const [formFeildsErrors, setFormFieldErrors] = useState(intitalPatientFormErrors)

	const { provinceList,
		updatePatientProfileResponse } = useCustomPatientSelectors()

	const dispatch = useCustomDispatch()
	const navigate = useCustomNavigate()
	// const location = useCustomLocation()

	const handleChangeFormData = (e) => {
		handleUpdatePatientFormData(
			e, updatePatientFormData,
			setUpdatePatientFormData,
			formDataBorderColor,
			setFormDataBorderColor,
			formFeildsErrors,
			setFormFieldErrors)
	}


	const submitPatientUpdateData = (e) => {
		e.preventDefault()
		validatePatientUpdateProfileForm(
			updatePatientFormData,
			setFormDataBorderColor,
			formDataBorderColor,
			setFormFieldErrors,
			formFeildsErrors,
			updatePatientAddress,
			dispatch,
			navigate,
			patientProfileData?.id,
			paginationNumber,
			hideModal,
			location,
			queryStatus,
			querySearch,
			setShowModal,
			showModal
		)
	}


	useEffect(() => {
		const startDOBPickerInstance = initialAddPateintDOBPicker(dobRef, setUpdatePatientFormData, updatePatientFormData);

		return () => {
			if (startDOBPickerInstance) {
				startDOBPickerInstance.destroy();
			}
		};
	}, [dobRef, updatePatientFormData])

	useEffect(() => {
		if (patientProfileData !== null) {

			setUpdatePatientFormData({
				...updatePatientFormData,
				firstName: patientProfileData?.first_name,
				lastName: patientProfileData?.last_name,
				phn: patientProfileData?.phn,
				gender: patientProfileData?.gender,
				phone: patientProfileData?.phone,
				dob: patientProfileData?.dob,
				email: patientProfileData?.email,
				route: location.pathname === routeName.pharmacy_blister_patients ? patientProfileData?.blister_route :
					location.pathname === routeName.pharmacy_speciality_pack ? patientProfileData?.medication_route :
						location.pathname === routeName.pharmacy_patient_vials_pack ? patientProfileData?.vials_route :
							patientProfileData?.route,
			})

			setUpdatePatientAddress({
				...updatePatientAddress,
				fullAddress: patientProfileData?.full_address,
				address: patientProfileData.display_address ? patientProfileData.display_address : patientProfileData?.address,
				city: patientProfileData?.city,
				province: patientProfileData?.province,
				postalCode: patientProfileData?.post_code
			})
		}
	}, [patientProfileData])


	const { ref: ref1 } = usePlacesWidget({
		apiKey: autoSearchKey,
		onPlaceSelected: (place) => onPlaceSetPateintAddresses(place, setUpdatePatientAddress, updatePatientAddress),
		options: {
			types: ["address"],
			componentRestrictions: { country: "ca" },
			limit: 7,
			googleLogo: false
		},
	});

	const [isEditMode, setIsEditMode] = useState(false);

	const handleEditClick = () => {
		setIsEditMode(!isEditMode);
	};


	return (
		<React.Fragment>
			<div
				className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center 
				justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100 w-full 
				md:inset-0 h-[calc(100%-1rem)] max-h-full">
				<div className="relative p-4 w-full max-w-3xl max-h-full">
					<div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
						<div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
							<h3 className="text-xl font-semibold text-gray-900 dark:text-white flex justify-center items-center">
								{patientProfileData?.first_name} {patientProfileData?.last_name}
							</h3>

							<div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-12 ml-4">
								<button
									onClick={handleEditClick}
									className="px-4 py-2 blueBg-color text-white rounded-md focus:outline-none"
									type='button'
								>
									{isEditMode ? <div className='flex'>
										<Visible />View Detail
									</div>
										:
										<div className='flex'>
											<EditMode />Edit Details
										</div>
									}
								</button>
							</div>


							<button type="button"
								onClick={() => handleHideModal(setShowModal, showModal, 'showPatientModle')}
								data-modal-hide="default-modal"
								className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-6 h-6 ml-auto inline-flex 
								justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
								<ModalCloseIcon />
							</button>
						</div>

						<div className="p-4 md:p-5 space-y-4">
							<form>
								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">


									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
										<div className="relative">
											<PHNIcon />
											<input type="text" id="Phn"
												style={{ borderColor: formDataBorderColor.phn }}

												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}

												onChange={handleChangeFormData}
												name='phn'
												value={updatePatientFormData.phn}
												onKeyPress={(e) => {
													if (isNaN(String.fromCharCode(e.charCode)) || e.target.value.length >= 10) {
														e.preventDefault();
													}
												}}
												placeholder="" disabled={!isEditMode} />

											<label htmlFor="Phn" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}

												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												{formDataBorderColor.phn == '#e85347' ? '' : 'PHN'}
											</label>
											<span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.phn}</span>
										</div>
									</div>

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
										<div className="relative">
											<UserIcon />
											<input type="text"
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={handleChangeFormData}
												style={{ borderColor: formDataBorderColor.lastName }}
												name='lastName'
												value={updatePatientFormData.lastName}
												id="lastName"
												placeholder=""
												disabled={!isEditMode} />

											<label htmlFor="lastName" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												{formDataBorderColor.lastName == '#e85347' ? '' : 'Last Name'}
											</label>
											<span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.lastName}</span>
										</div>
									</div>

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
										<div className="relative">
											<UserIcon />
											<input type="text"
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={handleChangeFormData}
												style={{ borderColor: formDataBorderColor.firstName }}
												name='firstName'
												value={updatePatientFormData.firstName}
												id="firstName"
												placeholder=""
												disabled={!isEditMode} />

											<label htmlFor="firstName" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												{formDataBorderColor.firstName == '#e85347' ? '' : 'First Name'}
											</label>
											<span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.firstName}</span>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
										<div className="relative">
											<LocationIcon />
											<input type="text"
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={handleChangeFormData}
												style={{ borderColor: formDataBorderColor.route }}
												name='route'
												value={updatePatientFormData.route}
												id="route"
												placeholder=""
												disabled={!isEditMode} />

											<label htmlFor="route" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												{formDataBorderColor.route == '#e85347' ? '' : 'Route'}
											</label>
											<span className='rubik-400 fs-12' style={{ color: '#e85347' }}>{formFeildsErrors.route}</span>
										</div>
									</div>

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
										<div className="relative">
											<PHNIcon />
											<input type="text"
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={handleChangeFormData}
												name='phone'
												value={updatePatientFormData.phone}
												id="phone"
												placeholder=""
												disabled={!isEditMode} />

											<label htmlFor="phone" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												Phone
											</label>

										</div>
									</div>

									<div className="sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">

										<div className="relative">
											<ul className={`items-center w-full text-sm font-medium  text-gray-900 ${!isEditMode ? 'bg-gray-100' : 'bg-white'} border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white`}>
												<li className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
													<div className="flex items-center ps-3">
														<input id="Male" checked={updatePatientFormData.gender === "Male"}
															onChange={handleChangeFormData} name='gender' value="Male" type="radio"
															className={`mr-2 btnnn w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 
										 								dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600
																		${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'
																}`}
															disabled={!isEditMode}
														/>
														<label htmlFor="Male" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">M</label>
													</div>
												</li>
												<li className="ml-4 w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
													<div className="flex items-center ps-3">
														<input id="Female" checked={updatePatientFormData.gender === "Female"}
															onChange={handleChangeFormData} name='gender' value="Female"
															type="radio"
															className={`mr-2 w-4 btnnn h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 
										 								dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600
																		${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'
																}`}
															disabled={!isEditMode}
														/>
														<label htmlFor="Female" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">F</label>
													</div>
												</li>
											</ul>
											<label htmlFor="Gender" className="ml-12  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
												Gender
											</label>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 z-50">
									<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="block sm:block md:flex lg:flex items-center ">
											<div className="relative w-full">

												<input type="text" ref={dobRef}
													className={`block pl-4 px-2.5  pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
													onChange={handleChangeFormData}
													name='dob'
													value={updatePatientFormData.dob}
													id="dob"
													placeholder=""
													disabled={!isEditMode} />

												<label htmlFor="dob" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
													className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
													Date of Birth
												</label>

												<div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
													<CalenderIcon />
												</div>
											</div>
										</div>
									</div>

									<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<SearchIcon />
											<input type="text"
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={(e) => handleUpdatePatientAddress(e, setUpdatePatientAddress, updatePatientAddress)}
												ref={ref1}
												name='fullAddress'
												value={updatePatientAddress.fullAddress}
												id="fullAddress"
												placeholder=""
												disabled={!isEditMode} />

											<label htmlFor="fullAddress" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												Search Address
											</label>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
									<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<AddressIcon />

											<input type="text"
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={(e) => handleUpdatePatientAddress(e, setUpdatePatientAddress, updatePatientAddress)}
												name='address'
												value={updatePatientAddress.address}
												id="address"
												placeholder=""
												disabled={!isEditMode} />

											<label htmlFor="address" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												Address
											</label>
										</div>
									</div>

									<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
										<div className="relative">
											<CityIcon />

											<input type="text"
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={(e) => handleUpdatePatientAddress(e, setUpdatePatientAddress, updatePatientAddress)}
												name='city'
												value={updatePatientAddress.city}
												id="city"
												placeholder=""
												disabled={!isEditMode} />

											<label htmlFor="city" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												City
											</label>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7">
									<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
										<div className="relative ot_addNoteText did-floating-label-content">
											<ProvinceIcon />
											<select style={{ height: '48px' }}
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={(e) => handleUpdatePatientAddress(e, setUpdatePatientAddress, updatePatientAddress)}
												value={updatePatientAddress.province} name="province"
											>
												{provinceList?.data?.map((value, index) => {
													return (<option className="fs-14" value={value.short_name} key={index}>{value.long_name}</option>)
												})}
											</select>
											<label className="did-floating-label"
												style={{
													color: 'grey', position: 'absolute', top: '-10px',
													backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}`
												}}>Province</label>
										</div>
									</div>

									<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
										<div className="relative">
											<LocationIcon />

											<input type="text"
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={(e) => handleUpdatePatientAddress(e, setUpdatePatientAddress, updatePatientAddress)}
												name='postalCode'
												value={updatePatientAddress.postalCode}
												id="postalCode"
												placeholder=""
												disabled={!isEditMode} />

											<label htmlFor="postalCode" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												Postal Code
											</label>

										</div>
									</div>

									<div className="mb-4 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
										<div className="relative">
											<EnvelopIcon />

											<input type="text"
												className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 
														dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
														${isEditMode ? 'cursor-text bg-white' : 'cursor-not-allowed bg-gray-100 dark:bg-gray-700'}`}
												onChange={handleChangeFormData}
												value={updatePatientFormData.email}
												name='email'
												id="email"
												placeholder=""
												disabled={!isEditMode} />

											<label htmlFor="postalCode" style={{ backgroundColor: `${isEditMode ? 'white' : '#F3F4F6'}` }}
												className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
														peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
														peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
														rtl:peer-focus:left-auto start-1`}>
												Email (Optional)
											</label>
										</div>
									</div>
								</div>
							</form>
						</div>

						{updatePatientProfileResponse?.data !== null &&
							<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
								<div className="col-span-12">
									<SuccessMessage message={updatePatientProfileResponse?.data?.message} />
								</div>
							</div>}

						{updatePatientProfileResponse?.error !== null &&
							<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-20">
								<div className="col-span-12">
									<Errors error={`Something went wrong while processing your request please try again later..!!`} />
								</div>
							</div>
						}

						<div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
							<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
								<div className="col-span-12">
									<button type="submit" onClick={submitPatientUpdateData} data-ripple-light="true"
										className={`rubik-500 flex rounded-md justify-center items-center text-white ${isEditMode ? 'blueBg-color' : 'cursor-not-allowed blueBg-color'}  w-full text-sm px-5 py-2.5 text-center`}
										disabled={!isEditMode}
									>
										{updatePatientProfileResponse.loading ? <>Processing  &nbsp; <Loader /></> : 'Submit'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >

		</React.Fragment >
	)
}

export default UpdatePatientsProfileData