import {
    ADD_OAT_DATA_REQUEST,
    ADD_OAT_DATA_SUCCESS,
    ADD_OAT_DATA_FAILURE,
} from "../../constants"

import {
    patient_update_route,
    update_patient_delivery_note
} from '../../../networking/urlEndPoints'

import Axios from "../../../networking/intraceptor"
import { oatFetchList } from "./oatFETCH";
import { fetchPatientsList } from "../patients/patientsList";

let debounceTimer;
const debounceDelay = 1000;

const debounce = (func, delay) => {
    return (...args) => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const addOATDataRequest = (fetchType) => ({
    type: ADD_OAT_DATA_REQUEST,
    fetch_type: fetchType
})

export const addOATDataSuccess = (data, fetchType) => ({
    type: ADD_OAT_DATA_SUCCESS,
    payload: data,
    fetch_type: fetchType
})

export const addOATDataFailure = (error, fetchType) => ({
    type: ADD_OAT_DATA_FAILURE,
    payload: error,
    fetch_type: fetchType
})

const debouncedUpdateOatData = debounce(async (dispatch, routeid, setShowToast, type, data, setShowModal) => {
    let path = "";
    dispatch(addOATDataRequest(type));
    try {
        let requestedData = {};
        if (type === "route") {
            requestedData = {
                id: routeid,
                value: data
            };
            path = patient_update_route;
        } else {
            requestedData = {
                id: routeid,
                col: type,
                value: data
            };
            path = update_patient_delivery_note;
        }

        const response = await Axios.post(path, requestedData);
        if (response?.status === 200) {
            dispatch(addOATDataSuccess(response?.data, type));
            if (typeof setShowToast === 'function') {
                setShowModal(prevState => ({
                    ...prevState,

                    showToast: true
                }));

                setTimeout(() => {
                    setShowModal(prevState => ({
                        ...prevState,
                        showToast: false
                    }));
                }, 2000);
            }
        } else {
            throw new Error(response);
        }
    } catch (error) {
        if (error?.response) {
            dispatch(addOATDataFailure(error?.response?.data, type));
            if (typeof setShowToast === 'function') {
                setShowToast((prevStat) => ({ ...prevStat, errorToast: true }));
                setTimeout(() => {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: false }));
                }, 700);
            }
        }
    }
}, debounceDelay);

export const UpdateOatData = (routeid, setShowToast, type, data, setShowModal) => {
    return (dispatch) => {
        debouncedUpdateOatData(dispatch, routeid, setShowToast, type, data, setShowModal);
    };
};

export const oatUpdateStatus = (type, OATPatientID, reason, setShowToast, setShowModal, showModal, formData, pageNumber, route, otherReason = '',forContainer = 'OAT') => {
    return async (dispatch) => {

        dispatch(addOATDataRequest(type))
        try {
            let requestedData = {
                patient_id: OATPatientID,
                reason: reason,
                active: 0,
                other_text: ''
            };

            if (reason === 'other') {
                requestedData.other_text = otherReason
            }

            const response = await Axios.post(route, requestedData)
            if (response?.status === 200) {

                setShowModal(prevState => ({
                    ...prevState,
                    statusOATModal: false,
                    showToast: true
                }));

                dispatch(addOATDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, successToast: true }))
                    setTimeout(() => {
                        setShowModal(prevState => ({
                            ...prevState,
                            statusOATModal: false,
                            showToast: false
                        }));
                    }, 2000);
                }

            } else {

                setShowModal(prevState => ({
                    ...prevState,
                    statusOATModal: false,
                    showToast: true
                }));

                setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                setTimeout(() => {

                    setShowToast((prevStat) => ({ ...prevStat, errorToast: false }));
                }, 2000);
                throw new Error(response)
            }
            document.body.style.overflow = 'auto'

           
            if (forContainer === "ActivePatient") {
                dispatch(fetchPatientsList('', '1', pageNumber))

            } else {
               
                const params = {
                    route: formData?.route ? formData?.route : '',
                    search: formData?.search ? formData?.search : '',
                    page: pageNumber,
                    sortlist: 'latest',
                    no_of_records: 10,
                }
                dispatch(oatFetchList("pharmacy_patient_route", params));
            }


        } catch (error) {

            if (error?.response) {
                setShowModal(prevState => ({
                    ...prevState,
                    statusOATModal: false,
                    showToast: true
                }));

                dispatch(addOATDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {

                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }));
                    }, 2000);
                }


            }
            document.body.style.overflow = 'auto'
        }
    }
}
