import {
	PHARMACY_TEAM_NOTES_MESSAGE_REQUEST,
	PHARMACY_TEAM_NOTES_MESSAGE_SUCCESS,
	PHARMACY_TEAM_NOTES_MESSAGE_FAILURE
} from "../../../constants";

const initialState = {
	data: null,
	loading: false,
	error: null,
};

const pharmacyTeamNotesMessageReducer = (state = initialState, action) => {
	switch (action.type) {
		case PHARMACY_TEAM_NOTES_MESSAGE_REQUEST:
			return {
				...state,
				loading: true,
				data: null,
				error: null,
			};
		case PHARMACY_TEAM_NOTES_MESSAGE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case PHARMACY_TEAM_NOTES_MESSAGE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null,
			};
		default:
			return state;
	}
};

export default pharmacyTeamNotesMessageReducer;
