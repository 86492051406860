import {
	PHARMACY_ADD_DATA_REQUEST,
	PHARMACY_ADD_DATA_SUCCESS,
	PHARMACY_ADD_DATA_FAILURE
} from '../../../constants';


const initialState = {
	data: null,
	loading: false,
	error: null,
};

const pharmacyAddDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case PHARMACY_ADD_DATA_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case PHARMACY_ADD_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case PHARMACY_ADD_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null,
			};
		default:
			return state;
	}
};

export default pharmacyAddDataReducer;
