import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
	toolbar: [
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		[{ font: [] }],
		[{ size: [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { indent: '-1' }, { indent: '+1' }],
		['link', 'image', 'video'],
		[{ color: [] }, { background: [] }],
		['code-block'],
		['clean'],
	],
};

export const TextEditor = ({ formData, setFormData, width = 'w-full', placeholder = 'Text for fax', height = 'h-96' }) => {
	const handleChange = (textForFaxValue) => {
		setFormData({ ...formData, textForFaxValue });
	};

	return (
		<>
			<ReactQuill theme="snow" value={formData.textForFaxValue} onChange={handleChange}
				modules={modules} className={`${formData?.textForFaxValue ? height : 'h-36'} ${width} rounded-md`}
				placeholder={placeholder} />
		</>
	)
}

