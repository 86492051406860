import {
	FETCH_PATIENT_PROVINCE_LISTS_REQUEST,
	FETCH_PATIENT_PROVINCE_LISTS_SUCCESS,
	FETCH_PATIENT_PROVINCE_LISTS_FAILURE
} from "../../constants"

import { pharmacy_patient_province } from "../../../networking/urlEndPoints"
import Axios from "../../../networking/intraceptor"

export const provinceListRequest = () => ({
	type: FETCH_PATIENT_PROVINCE_LISTS_REQUEST,
})

export const provinceListSuccess = (data) => ({
	type: FETCH_PATIENT_PROVINCE_LISTS_SUCCESS,
	payload: data,
})

export const provinceListFailure = (error) => ({
	type: FETCH_PATIENT_PROVINCE_LISTS_FAILURE,
	payload: error,
})

export const fetchProvinceList = () => {
	return async (dispatch) => {
		dispatch(provinceListRequest())
		try {
			const response = await Axios.get(`${pharmacy_patient_province}`)
			if (response?.status === 200) {
				dispatch(provinceListSuccess(response?.data))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(provinceListFailure(error?.response))
			}
		}
	}
}
