import {
	ADD_RECENT_CHATS_REQUEST,
	ADD_RECENT_CHATS_SUCCESS,
	ADD_RECENT_CHATS_FAILURE
} from "../../constants";

import { pharmacy_get_recent_chats } from "../../../networking/urlEndPoints";
import Axios from '../../../networking/intraceptor'

export const fetchRecentChatListRequest = () => ({
	type: ADD_RECENT_CHATS_REQUEST,
})

export const fetchRecentChatListSuccess = (data) => ({
	type: ADD_RECENT_CHATS_SUCCESS,
	payload: data,
})

export const fetchRecentChatListFailure = (error) => ({
	type: ADD_RECENT_CHATS_FAILURE,
	payload: error,
})

export const fetchAllRecentChats = (setRecenetChatLoading, user_name) => {
	return async (dispatch) => {
		dispatch(fetchRecentChatListRequest())
		try {
			const requestData = {}

			if (user_name) {
				requestData.user_name = user_name;
			}

			const response = await Axios.post(`${pharmacy_get_recent_chats}`, requestData)

			if (response?.status === 200) {
				dispatch(fetchRecentChatListSuccess(response?.data))
				if (typeof setRecenetChatLoading === 'function') {
					setRecenetChatLoading(false)
				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchRecentChatListFailure(error?.response))
			}
			if (typeof setRecenetChatLoading === 'function') {
				setRecenetChatLoading(false)
			}
		}
	}
}
