import {
	FETCH_PHARMACY_WALL_POSTS_LISTS_REQUEST,
	FETCH_PHARMACY_WALL_POSTS_LISTS_SUCCESS,
	FETCH_PHARMACY_WALL_POSTS_LISTS_FAILURE
} from "../../constants";

const initialState = {
	pharmacyWallPosts: [],
	loading: false,
	error: null,
};

const pharmacyWallPostsListsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_PHARMACY_WALL_POSTS_LISTS_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case FETCH_PHARMACY_WALL_POSTS_LISTS_SUCCESS:
			return {
				...state,
				pharmacyWallPosts: action.payload,
				loading: false,
				error: null,
			};
		case FETCH_PHARMACY_WALL_POSTS_LISTS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				pharmacyWallPosts: null
			};
		default:
			return state;
	}
};

export default pharmacyWallPostsListsReducer;
