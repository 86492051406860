import {
	PHARMACY_ADD_TEAM_NOTES_REQUEST,
	PHARMACY_ADD_TEAM_NOTES_SUCCESS,
	PHARMACY_ADD_TEAM_NOTES_FAILURE
} from '../../../constants';

import Axios from '../../../../networking/intraceptor'
import { pharmacy_team_add_notes } from '../../../../networking/urlEndPoints'

export const pharmacyAddTeamNotesRequest = () => ({
	type: PHARMACY_ADD_TEAM_NOTES_REQUEST,
});

export const pharmacyAddTeamNotesSuccess = (user) => ({
	type: PHARMACY_ADD_TEAM_NOTES_SUCCESS,
	payload: user,
});

export const pharmacyAddTeamNotesFailure = (error) => ({
	type: PHARMACY_ADD_TEAM_NOTES_FAILURE,
	payload: error,
});

export const pharmacyAddTeamNotes = (addnotes) => {
	return async (dispatch) => {
		dispatch(pharmacyAddTeamNotesRequest());
		try {
			const response = await Axios.post(`${pharmacy_team_add_notes}`, {
				notes: addnotes
			})

			if (response?.status === 200) {
				dispatch(pharmacyAddTeamNotesSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddTeamNotesFailure(error?.response));
			}
		}
	}
}

