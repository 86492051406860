import AddPatients from '../patients/addPatients'

const AddVialsPatient = () => {

    return (
        <>
            <AddPatients />
        </>
    )
}

export default AddVialsPatient 