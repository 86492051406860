import React, { useEffect, useState } from 'react'

import {
    BreadCrumb,
    Footer,
    Sidebar
} from '../../../components/common'

import {
    pharmacyPreviewFormPdf,
    previewPdfFailure
} from '../../../services/actions/pharmacy/forms/previewPdf'

import { currentDate } from '../../../utils/constants/formInitialStates'
import { pharmacy_doctor_form } from '../../../networking/urlEndPoints'
import { Errors } from '../../../components/error/alertMessages'

import { useCustomDispatch } from '../../../hooks'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'

import ChatBox from '../../../components/chat/chatbox'

import * as Pharmacy from '../../../components/pharmacy/form/form'

const AddDoctorNote = () => {

    const dispatch = useCustomDispatch()

    const initalInvoiceFormData = {
        date: currentDate,
        fetchType: 'winrx',
        phn: '',
        name: '',
        phone: '',
        item_details: '',
        quantity: '',
        price: '',
        total: '',
        grand_total: '',
        address: '',
        winrxpatientphn: '',
        winrxpatientname: '',
        winrxpatientphone: '',
        pharmacy_manager: '',
        subject: '',
        desgination: 'Manager',
        pharmacyManager: '',
        option_class: ''

    }
    const { pharmacyLoginData } = useCustomSelectors()
    const { previewPdfResponse } = useFormsSelectors()

    const [formData, setFormData] = useState(initalInvoiceFormData)
    const [errorMessages, setErrorMessages] = useState(initalInvoiceFormData)

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleGenerateDoctorNotePDFValdiate = () => {

        const specificValidations = {
            name: formData.fetchType === 'winrx' ? 'Name is required' : '',
            patientName: formData.fetchType === 'local' ? 'Name is required' : '',
            phn: 'PHN is required',
            dob: 'DOB is required',
            subject: 'Subject is required',
            pharmacyManager: "Pharmacy Manager is required"
        }
        const allValidations = { ...specificValidations }
        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(allValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        )

        setErrorMessages({
            ...errorMessages,
            ...newErrorMessages,
        })

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

        if (allDataPresent) {
            const requestedData = {
                date: formData.date,
                option_class: formData.option_class,
                fax_text: formData.notes,
                fax_subject: formData.subject,
                pharmacyname: 'text',
                pharmacymanager: formData.pharmacyManager,
                pharmacyuserdesignation: formData.desgination

            }
            if (formData.fetchType === 'winrx') {
                requestedData.fetchtype = formData.fetchType
                requestedData.winrxpatientphn = formData.phn
                requestedData.winrxpatientname = formData.name
                requestedData.winrxpatientdob = formData.dob
            }

            if (formData.fetchType === 'local') {
                requestedData.fetchtype = formData.fetchType
                requestedData.patient = formData.patientName.value
                requestedData.patientphn = formData.phn
                requestedData.patientdob = formData.dob

            }

            dispatch(pharmacyPreviewFormPdf(pharmacy_doctor_form, requestedData))
        }
    }

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setFormData({ ...formData, notes: selectedValue, option_class: selectedValue });
    }

    const handleSaveInvoicePdf = () => {
        handleGenerateDoctorNotePDFValdiate()
    }

    useEffect(() => {
        dispatch(previewPdfFailure(null))
    }, [])

    useEffect(() => {
        if (pharmacyLoginData) {
            setFormData({
                ...formData,
                pharmacyManager: pharmacyLoginData.manager_name,
                pharmacyName: pharmacyLoginData.name,
                pharmacyFax: pharmacyLoginData.fax
            })
        }
    }, [pharmacyLoginData])

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="timeSheets">

                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="grid grid-cols-3 gap-4">
                                            <div>
                                                <Pharmacy.Date
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                />
                                            </div>
                                            <div className="flex">
                                                <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                    <Pharmacy.FormWinRxPatients
                                                        formData={formData}
                                                        errorMessages={errorMessages}
                                                        setErrorMessages={setErrorMessages}
                                                        setFormData={setFormData}
                                                        handleFormDataChange={handleFormDataChange}
                                                    />
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-3 mt-5 gap-4">
                                            <div>
                                                <Pharmacy.PatientPhn
                                                    handleFormDataChange={handleFormDataChange}
                                                    formData={formData}
                                                    errorMessages={errorMessages}
                                                    setErrorMessages={setErrorMessages}
                                                    setFormData={setFormData}
                                                />
                                            </div>
                                            <div>
                                                <Pharmacy.PatientName
                                                    handleFormDataChange={handleFormDataChange}
                                                    formData={formData}
                                                    errorMessages={errorMessages}
                                                    setFormData={setFormData}
                                                    setErrorMessages={setErrorMessages}
                                                />
                                            </div>
                                            <div>
                                                <Pharmacy.PatientDob
                                                    handleFormDataChange={handleFormDataChange}
                                                    formData={formData}
                                                    errorMessages={errorMessages}
                                                    setFormData={setFormData}
                                                    setErrorMessages={setErrorMessages}
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-4"> <select onChange={handleSelectChange} class="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                            dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
                                            bg-white" name="reason_to_deactivate" id="reason_to_deactivate">
                                            <option value="Select Note" disabled selected data-select2-id="15">Select Note</option><option value="New Patient" data-select2-id="40">New Patient</option><option value="No Rx" data-select2-id="41">No Rx</option><option value="New Prescription" data-select2-id="42">New Prescription</option><option value="Need a New Prescription" data-select2-id="43">Need a New Prescription</option>
                                        </select>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-4">
                                            <div className="did-floating-label-content">
                                                <div id="cancellationNotesContainer" className="form-group full">
                                                    <textarea style={{ fontSize: '.875rem' }} maxLength="255" placeholder='Notes'
                                                        name='notes' value={formData.notes}
                                                        onChange={handleFormDataChange}
                                                        className={`pl-4 fs-18 rounded-md ${errorMessages.notes ? 'border-red-500' : 'border-gray-300'} rubik-400 border border-gray-300 text-gray-900 !p-3`}>
                                                    </textarea>
                                                    {errorMessages.notes && (
                                                        <p className="text-xs text-red-600 dark:text-red-400">
                                                            {errorMessages.notes}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-3">
                                            <div className="relative ">
                                                <input
                                                    type="text"
                                                    id="subject"
                                                    onChange={handleFormDataChange}
                                                    name='subject' value={formData.subject}

                                                    className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 ${errorMessages.subject ? 'border-red-500' : 'border-gray-300'}appearance-none dark:text-white dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white border-gray-300 ${errorMessages.subject && "border-red-500"
                                                        } `}
                                                    placeholder=""
                                                />
                                                <label
                                                    htmlFor="subject"
                                                    className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
                                                >
                                                    Subject
                                                </label>
                                                {errorMessages.subject && (
                                                    <p className="text-xs text-red-600 dark:text-red-400">
                                                        {errorMessages.subject}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                                            <Pharmacy.LoginPharmacyName
                                                handleFormDataChange={handleFormDataChange}
                                                formData={formData}
                                                errorMessages={errorMessages}
                                                setFormData={setFormData}
                                            />

                                            <div className=''>
                                                <Pharmacy.LoginPharmacyManger handleFormDataChange={handleFormDataChange}
                                                    formData={formData}
                                                    errorMessages={errorMessages}
                                                    setFormData={setFormData}
                                                />
                                            </div>

                                            <Pharmacy.Designation
                                                handleFormDataChange={handleFormDataChange}
                                                formData={formData} errorMessages={errorMessages}
                                                setFormData={setFormData}
                                            />
                                        </div>
                                        {previewPdfResponse?.error !== null &&
                                            <>
                                                <br />
                                                <Errors error={'Something went wrong when Generating PDF. Please try again'} />
                                            </>
                                        }
                                        <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
                                            <button style={{ height: '40px' }} type="button" onClick={handleSaveInvoicePdf}
                                                className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex
                                                                 justify-center items-center">

                                                {previewPdfResponse?.loading ? 'Generating PDF...' : 'Generate PDF'}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChatBox />
            <Footer />


        </>
    )
}

export default AddDoctorNote