import React, { useState, useRef } from 'react'

import {
    useCustomDispatch,
    useCustomNavigate
} from '../../../hooks'

import { ErrorToast } from '../../../components/loaders/toastMessage'
import { VerifyPassocde } from '../../../services/actions/pharmacy/Inventory/addInventoryData'
import { useInventorySelector } from '../../../services/selectors/inventorySelectors'

import * as Common from '../../../components/common'

const VerifyPassCode = () => {

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    const { addInventoryResponse } = useInventorySelector()

    const [formdata, setFormData] = useState({ input1: '', input2: '', input3: '', input4: '' })
    const [errorMessage, setErrorMessage] = useState('')
    const [showToast, setShowToast] = useState('')

    const navigate = useCustomNavigate()
    const dispatch = useCustomDispatch()

    const handleVerifyPasscode = () => {
        if (!formdata.input1 || !formdata.input2 || !formdata.input3 || !formdata.input4) {
            setErrorMessage('All Feilds are required.')
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, 2000);

        } else {
            setErrorMessage('')

            const requestedData = {
                routepasscode: [`${formdata.input1}`, `${formdata.input2}`, `${formdata.input3}`, `${formdata.input4}`]
            }

            dispatch(VerifyPassocde('verify_passcode', requestedData, navigate, setShowToast, setErrorMessage))
        }
    }


    const handleInputChange = (e, nextRef, field) => {
        const value = e.target.value;
        setFormData((prevData) => ({ ...prevData, [field]: value }));

        if (value && nextRef && nextRef.current) {
            nextRef.current.focus();
        }
    };

    return (
        <React.Fragment>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                        <div className='flex items-center justify-center mt-40 mb-80'>
                                            <div className='bg-gray-100 rounded-lg pt-10 pl-16 pr-16 pb-16 h-52'>
                                                <h1 className='rubik-500 text-gray-900 text-center mb-2'>Enter Passcode</h1>
                                                <div className='flex  gap-2'>

                                                    <input
                                                        type="text"
                                                        maxLength={1}
                                                        autoComplete="off"
                                                        onChange={(e) => handleInputChange(e, input2Ref, 'input1')}
                                                        ref={input1Ref}
                                                        className={`block pl-2 px-2.5 pb-2.5 w-8 h-10 text-center text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
                                                        placeholder=""
                                                    />
                                                    <input
                                                        type="text"
                                                        maxLength={1}
                                                        autoComplete="off"
                                                        onChange={(e) => handleInputChange(e, input3Ref, 'input2')}
                                                        ref={input2Ref}
                                                        className={`block pl-2 px-2.5 pb-2.5 w-8 h-10 text-center text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
                                                        placeholder=""
                                                    />
                                                    <input
                                                        type="text"
                                                        maxLength={1}
                                                        autoComplete="off"
                                                        onChange={(e) => handleInputChange(e, input4Ref, 'input3')}
                                                        ref={input3Ref}
                                                        className={`block pl-2 px-2.5 pb-2.5 w-8 h-10 text-center text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
                                                        placeholder=""
                                                    />
                                                    <input
                                                        type="text"
                                                        maxLength={1}
                                                        autoComplete="off"
                                                        onChange={(e) => handleInputChange(e, null, 'input4')}
                                                        ref={input4Ref}
                                                        className={`block pl-2 px-2.5 pb-2.5 w-8 h-10 text-center text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white`}
                                                        placeholder=""
                                                    />
                                                </div>

                                                <button type="button" onClick={handleVerifyPasscode} style={{ backgroundColor: '#3B82F6' }}
                                                    className="flex w-full items-center justify-center text-white bg-red-500 mt-4 mb-10
										            border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-1.5  dark:bg-gray-800">
                                                    {addInventoryResponse?.loading && addInventoryResponse?.type === 'verify_passcode' ? 'Verifying...' : 'Verify'}
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {showToast && <ErrorToast status={errorMessage} />}
        </React.Fragment>
    )
}

export default VerifyPassCode