import React, { useState, useEffect } from 'react'

import {
	BreadCrumb,
	Footer,
	Sidebar
} from '../../../components/common'

import { TextEditor } from '../../../components/pharmacy/form/textEditor'

import { useCustomDispatch } from '../../../hooks'
import { Errors } from '../../../components/error/alertMessages'
import { Loader } from '../../../components/common/loadingView'

import { handleEmergencySupplyForContinuityofCareForm } from '../../../utils/validations/formsValidations'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'

import ChatBox from '../../../components/chat/chatbox'

import * as Pharmacy from '../../../components/pharmacy/form/form'
import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'
import { faxFormFailure } from '../../../services/actions/pharmacy/forms/pharmacyUpdateNotificationsSrFaxForm'

const EmergencySupplyForContinuityOfCareForm = () => {
	const [showOther, setShowOther] = useState(false);
	const dispatch = useCustomDispatch()
	const { previewPdfResponse } = useFormsSelectors()

	const initalEmergencyFormData = {
		'rational[1]': false,
		'rational[2]': false,
		'rational[3]': false,
		'rational[4]': false,
		'rational[5]': false,
		'rational[6]': false,
		'rational[7]': false,
		'rational[8]': false,
		'followup[1]': false,
		'followup[2]': false,
		'nn_code': 'yes',
		'left_with': 0,
		'refill_with': 1,
		'dosage_for': 0,
		'add_other': false,
		'dosage_interval': 'day',
		'fax_text': '',
		patientName: ''
	}

	const [formData, setFormData] = useState(initalEmergencyFormData)
	const [errorMessages, setErrorMessages] = useState(initalEmergencyFormData)

	const handleCheckboxChange = (event) => {
		const { name, checked, value } = event.target;

		setFormData((prevValues) => ({
			...prevValues,
			[name]: event.target.type === 'checkbox' ? checked : value,
		}));
	};




	useEffect(() => {
		dispatch(previewPdfFailure(null))
		dispatch(faxFormFailure(null))
	}, [])

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">

									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="grid grid-cols-12 gap-2 w-full">
											<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 border border-gray-200 rounded dark:border-gray-700">
												<h3 className="fs-18 rubik-500 ml-2 py-1">Rationale</h3>

												<div className="relative flex items-center pl-4 ">
													<input id="rational[1]" type="checkbox" name='rational[1]' onChange={handleCheckboxChange} value={formData['rational[1]']} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="rational[1]" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">NN Code Used </label>

													<div className="absolute left-40">
														<label htmlFor="underline_select" className="sr-only fs-16 rubik-400">Underline select</label>
														<select id="underline_select" onChange={handleCheckboxChange}
															defaultValue={formData.nn_code} name='nn_code' className="cursor-pointer block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer fs-16 rubik-400">
															<option value="yes">Yes</option>
															<option value="no">No</option>
														</select>
													</div>

												</div>
												<div className="relative flex items-center pl-4 ">
													<input id="rational[2]" name='rational[2]' onChange={handleCheckboxChange} value={formData['rational[2]']} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="rational[2]" className="w-full flex py-2 ml-2 relative top-1 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">Patient was left with  days’
														<div className="relative w-10">
															<input type="text" id="left_with" onChange={handleCheckboxChange} name='left_with'
																onKeyPress={(e) => { if (isNaN(Number(e.key))) { e.preventDefault(); } }}
																defaultValue={formData.left_with} className="text-center border-0 border-b-2 border-gray-300 text-gray-900 text-sm focus:ring-0 focus:border-0 block w-full p-0  py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white fs-16 rubik-400" placeholder="0" required />
														</div>
														worth of medication, supplied with
														<div className="relative w-10">
															<input type="text" id="refill_with" onChange={handleCheckboxChange} name='refill_with'
																onKeyPress={(e) => { if (isNaN(Number(e.key))) { e.preventDefault(); } }}
																defaultValue={formData.refill_with} className="text-center border-0 border-b-2 border-gray-300 text-gray-900 text-sm focus:ring-0 focus:border-0 block w-full p-0  py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white fs-16 rubik-400" placeholder="1" required />
														</div>
														day(s) to maintain compliance and/or match other medication(s) supply.</label>
												</div>

												<div className="relative flex items-center pl-4 ">
													<input id="rational[3]" name='rational[3]' onChange={handleCheckboxChange} value={formData['rational[3]']} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="rational[3]" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">Doctor unavailable</label>
												</div>

												<div className="relative flex items-center pl-4 ">
													<input id="rational[4]" name='rational[4]' onChange={handleCheckboxChange} value={formData['rational[4]']} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="rational[4]" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">Pharmacy awaiting reply for the refill request sent to physician.</label>
												</div>

												<div className="relative flex items-center pl-4 ">
													<input id="rational[5]" name='rational[5]' onChange={handleCheckboxChange} value={formData['rational[5]']} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="rational[5]" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">The dose is stable and the mediation is currently effective with no concerns.</label>
												</div>
												<div className="relative flex items-center pl-4 ">
													<input id="rational[6]" name='rational[6]' onChange={handleCheckboxChange} value={formData['rational[6]']} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="rational[6]" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">Patient has been on this drug and dosage for</label>

													<div className="absolute left-96 flex items-center">
														<div className="w-10 mr-2">
															<input type="text" id="dosage_for" onChange={handleCheckboxChange}
																onKeyPress={(e) => { if (isNaN(Number(e.key))) { e.preventDefault() } }}
																value={formData.dosage_for} name='dosage_for' className="text-center border-0 border-b-2 border-gray-300 text-gray-900 text-sm focus:ring-0 focus:border-0 block w-full p-0 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white fs-16 rubik-400" placeholder="0" required />
														</div>
														<div>
															<label htmlFor="underline_select" className="sr-only fs-16 rubik-400">Underline select</label>
															<select id="underline_select" onChange={handleCheckboxChange}
																value={formData.dosage_interval} name='dosage_interval' className="cursor-pointer block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer fs-16 rubik-400">
																<option defaultValue="day">day</option>
																<option defaultValue="days">days</option>
																<option defaultValue="week">week</option>
																<option defaultValue="weeks">weeks</option>
															</select>
														</div>
													</div>

												</div>

												<div className="relative flex items-center pl-4 ">
													<input id="rational[7]" name='rational[7]' onChange={handleCheckboxChange} value={formData['rational[7]']} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="rational[7]" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">CORONA VIRUS/covid-19 OUTBREAK EMERGENCY.</label>
												</div>

												<div className="relative flex items-center pl-4 ">
													<input id="rational[8]" name='rational[8]' onChange={handleCheckboxChange} value={formData['rational[8]']} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="rational[8]" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">OAT continuation. Risk mitigation safe supply</label>
												</div>

												<div className='text-center mt-2 mb-3' style={{ maxWidth: "250px" }}>
													<label className='font-medium text-gray-900'>OR</label>
												</div>

												<div className="relative flex items-center pl-4 ">
													<input
														id="selectOther"
														name='add_other'
														type="checkbox"
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400"
														onChange={(e) => {
															setShowOther(e.target.checked);
															handleCheckboxChange(e);
														}}
														checked={showOther}
													/>
													<label htmlFor="selectOther" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">Other</label>
												</div>
												{showOther && (
													<div className='mb-14 mt-2'>
														<div className='relative flex items-center pl-4 pr-4'>
															<TextEditor placeholder='' formData={formData} setFormData={setFormData} />
														</div>
													</div>
												)}

												<h3 className="fs-18 rubik-500 ml-2 py-1">Follow-up plan</h3>

												<div className="relative flex items-center pl-4 ">
													<input id="followup[1]" name='followup[1]' onChange={handleCheckboxChange} value={formData['followup[1]']} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="followup[1]" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">Patient going to see the doctor for a new prescription.</label>
												</div>

												<div className="relative flex items-center pl-4 ">
													<input id="followup[2]" name='followup[2]' onChange={handleCheckboxChange} value={formData['followup[2]']} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400" />
													<label htmlFor="followup[2]" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400 cursor-pointer">Patient has an appointment with the doctor.</label>
												</div>

											</div>
										</div>
										<br />

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<Pharmacy.PatientList setFormData={setFormData} formData={formData}
												setErrorMessages={setErrorMessages}
												errorMessages={errorMessages} />
										</div>

										{previewPdfResponse?.error !== null &&
											<>
												<br />
												<Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
											</>
										}

										<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
											<button style={{ height: '40px' }} type="button"

												onClick={() => handleEmergencySupplyForContinuityofCareForm(
													formData,
													setErrorMessages,
													errorMessages,
													dispatch,
													'PDF')}

												className="ti_heightScheduleInnerDateBtn blueBg-color sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

												{previewPdfResponse?.loading ? <>
													Genrating PDF
													&nbsp;<Loader /> </> : 'Preview PDF'}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ChatBox />
			<Footer />

		</>
	)
}

export default EmergencySupplyForContinuityOfCareForm