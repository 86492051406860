import React, {
    useEffect,
    useState,
} from "react";

import {
    AddMultipleRevenueModal,
    AddSingleRevenueModal,
    DeleteDailyRevenueModal,
    UpdateDailyRevenueModal
} from "../../../models/revenue/dailyRevenue";

import {
    ErrorToast,
    SuccessToastMessage
} from "../../../components/loaders/toastMessage";

import {
    DeleteIcon,
    EditIcons
} from "../../../assets/svg/icons";

import { handleShowModal } from "../../../assets/js/custome";
import { currentDate } from "../../../utils/constants/formInitialStates";

import { useCustomDispatch } from "../../../hooks";
import { fetchDailyRevenueList } from "../../../services/actions/pharmacy/revenue/revenueList";
import { useRevenueSelector } from "../../../services/selectors/revenueSelectors";
import { ArchivedDelveriesDateRangePicker } from '../../../components/pharmacy/form/dateRangePicker'

import { useCustomSelectors } from "../../../services/selectors/allSelectors";
import { BlisterDropDown } from "../../../components/pharmacy/form/form";
import { utils, writeFile } from 'xlsx';

import ChatBox from "../../../components/chat/chatbox";
import * as Common from "../../../components/common";
import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'

const getCurrentMonthDates = () => {

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    const startDate = new Date(year, month, 2);
    const endDate = new Date(year, month + 1, 1);

    const formatToDateInput = (date) => date.toISOString().split('T')[0];

    return {
        startDate: formatToDateInput(startDate),
        endDate: formatToDateInput(endDate),
    };
};

const revenueListHeading = [
    { text: "DATE", classNames: ['p-3'] },
    { text: "TYPE", classNames: ['p-3'] },
    { text: "AMOUNT", classNames: ['p-3'] },
    { text: "NOTE", classNames: ['p-3'] },
    { text: "", classNames: ['p-3'] },

];

const TableRowData = ({ value, className1 = 'p-3', }) => {
    return (<td  className={className1}>
        <p>{value}</p>
    </td>)
}

export const revenueTypeMapping = {
    'Cash': 'cash',
    'Debit Card': 'debit_card',
    'Visa Card': 'visa_card',
    'Master Card': 'master_card',
    'Cheque': 'cheque',
    'Auto Debit': 'auto_debit',
    'Cash Left Over Previous Month': 'cash_left_over_previous_month',
    'Cash Deposit in Bank or Payout': 'cash_deposit_in_bank_or_payout'
}

export const handleExportSheetDateWise = (data, fileName, dailyRevenueList) => {

    const groupedData = data.reduce((acc, item) => {
        const dateKey = `${item.date} - ${item.dayname}`;
        if (!acc[dateKey]) {
            acc[dateKey] = {
                'Date': dateKey,
                'Cash': 0,
                'Debit Card': 0,
                'Visa Card': 0,
                'Master Card': 0,
                'Cheque': 0,
                'Auto Debit': 0,
                'Cash Left Over Previous Month': 0,
                'Cash Deposit in Bank or Payout': 0
            };
        }
        acc[dateKey][item.type] += parseFloat(item.amount);
        return acc;
    }, {});

    const formattedData = Object.values(groupedData);

    formattedData.push(
        { 'Date': '', 'Cash': '', 'Debit Card': '', 'Visa Card': '', 'Master Card': '', 'Cheque': '', 'Auto Debit': '', 'Cash Left Over Previous Month': '', 'Cash Deposit in Bank or Payout': '' },

        { 'Date': 'SUBTOTAL', 'Cash': dailyRevenueList.subtotal, 'Debit Card': '', 'Visa Card': '', 'Master Card': '', 'Cheque': '', 'Auto Debit': '', 'Cash Left Over Previous Month': '', 'Cash Deposit in Bank or Payout': '' },
        { 'Date': 'MONTHLY CASH', 'Cash': dailyRevenueList.cash_total, 'Debit Card': '', 'Visa Card': '', 'Master Card': '', 'Cheque': '', 'Auto Debit': '', 'Cash Left Over Previous Month': '', 'Cash Deposit in Bank or Payout': '' },
        { 'Date': 'CASH DEPOSIT IN BANK OR PAYOUT', 'Cash': dailyRevenueList.payout_total, 'Debit Card': '', 'Visa Card': '', 'Master Card': '', 'Cheque': '', 'Auto Debit': '', 'Cash Left Over Previous Month': '', 'Cash Deposit in Bank or Payout': '' },
        { 'Date': 'CASH LEFT OVER', 'Cash': dailyRevenueList.cash_left, 'Debit Card': '', 'Visa Card': '', 'Master Card': '', 'Cheque': '', 'Auto Debit': '', 'Cash Left Over Previous Month': '', 'Cash Deposit in Bank or Payout': '' }
    );

    const worksheet = utils.json_to_sheet(formattedData);

    worksheet['!cols'] = [
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
    ];

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${fileName}.xlsx`);
}


export const handleExportSheetEntryWise = (data, fileName, dailyRevenueList) => {
    const formattedData = data.map(item => ({
        'Date': item.date,
        'Type': item.type,
        'Amount': item.amount,
        'Note': item.note,
    }));

    formattedData.push(
        { 'Date': '', 'Type': '', 'Amount': '', 'Note': '' },
        { 'Date': '', 'Type': 'SUBTOTAL', 'Amount': dailyRevenueList.subtotal, 'Note': '' },
        { 'Date': '', 'Type': 'MONTHLY CASH', 'Amount': dailyRevenueList.cash_total, 'Note': '' },
        { 'Date': '', 'Type': 'CASH DEPOSIT IN BANK OR PAYOUT', 'Amount': dailyRevenueList.payout_total, 'Note': '' },
        { 'Date': '', 'Type': 'CASH LEFT OVER', 'Amount': dailyRevenueList.cash_left, 'Note': '' }
    );

    const worksheet = utils.json_to_sheet(formattedData);

    worksheet['!cols'] = [
        { wch: 15 },
        { wch: 25 },
        { wch: 20 },
        { wch: 35 },
    ];

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${fileName}.xlsx`);
}

const DailyRevenue = () => {

    const dispatch = useCustomDispatch()
    const startDateRef = React.useRef()
    const endDateRef = React.useRef()

    const { startDate, endDate } = getCurrentMonthDates();
    const { RevenueListResponse, addRevenueDataResponse } = useRevenueSelector();
    const [reset, setReset] = useState(false)
    const { adminModule } = useCustomSelectors()

    const dailyRevenueList = RevenueListResponse?.data?.data
    const revenuetype = RevenueListResponse?.data?.data?.revenuetype

    const [formData, setFormData] = useState({
        startDate: startDate,
        endDate: endDate,
        revenueType: '',
        date: currentDate,
        amount: '',
        note: '',
        type: ''
    })

    const [showModal, setShowModal] = useState({ multipleRevenue: false, singleRevenue: false, updateRevenueList: false, deleteRevenueList: false })
    const [showToast, setShowToast] = useState({ successToast: false, errorToast: false })
    const [logData, setLogData] = useState(null)

    useEffectHelper.useArchivedDelveriesDateRangePickerEffect(formData, setFormData, startDateRef, endDateRef)

    useEffect(() => {
        const params = {
            from_date: startDate,
            to_date: endDate
        }
        if (adminModule === 1) {
            dispatch(fetchDailyRevenueList('daily_revenue_list', params))
        } else {
            dispatch(fetchDailyRevenueList('daily_revenue_list'))
        }
        return () => { }
    }, [])

    const handleFilter = () => {
        const { startDate, endDate, revenueType } = formData
        if (startDate && endDate) {
            const params = {
                from_date: startDate,
                to_date: endDate
            }
            if (revenueType && revenueType !== 'All') {
                params.type = revenueTypeMapping[revenueType];
            }
            dispatch(fetchDailyRevenueList('daily_revenue_list', params))
        }
    }

    const handleReset = () => {
        setFormData({
            ...formData,
            startDate: startDate,
            endDate: endDate,
            revenueType: ''
        })
        const params = {
            from_date: startDate,
            to_date: endDate
        }
        setTimeout(() => {
            setReset(true)
        }, 100);
        dispatch(fetchDailyRevenueList('daily_revenue_list', params))
    }

    return (
        <>
            <div className="BodyBgColor">
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex flex-wrap lg:flex-nowrap gap-1">

                                            {adminModule === 1 &&
                                                <div className='flex-0 lg:w-8/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full flex' style={{ gridTemplateColumns: '2fr 2fr 7px 2fr 2fr' }}>
                                                    <ArchivedDelveriesDateRangePicker
                                                        startDateRef={startDateRef}
                                                        endDateRef={endDateRef}
                                                        formData={formData}
                                                        setFormData={setFormData} />

                                                    <div className='ml-2 w-32'>
                                                        <BlisterDropDown formData={formData}
                                                            setFormData={setFormData}
                                                            name="revenueType"
                                                            value={formData.revenueType}
                                                            reset={reset}
                                                            filterList={revenuetype}
                                                            placeholder='Type' />
                                                    </div>

                                                    <button onClick={handleFilter} type="button" style={{ width: '80px', backgroundColor: '#3B82F6' }}
                                                        className="flex w-full items-center justify-center text-white bg-red-500
														border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm ml-2 px-5 py-0.5  dark:bg-gray-800">
                                                        Filter
                                                    </button>

                                                    <button onClick={handleReset} type="button" style={{ width: '80px', backgroundColor: 'white', color: 'gray' }}
                                                        className="flex w-full items-center text-gray justify-center text-white bg-red-500
														border border-gray-300 hover:bg-green-300  font-medium rounded-lg text-sm ml-2 px-5 py-0.5  dark:bg-gray-800">
                                                        Reset
                                                    </button>
                                                </div>
                                            }

                                            <div className='flex-0 lg:w-12/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full flex gap-1 '>
                                                <button onClick={() => handleShowModal(setShowModal, showModal, 'singleRevenue')} type="button" style={{ width: '170px', backgroundColor: '#3B82F6' }}
                                                    className="flex w-full items-center justify-center text-white bg-red-500
																border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-0.5  dark:bg-gray-800">
                                                    Add Single Entry
                                                </button>

                                                <button type="button" onClick={() => handleShowModal(setShowModal, showModal, 'multipleRevenue')} style={{ width: '180px', backgroundColor: '#3B82F6' }}
                                                    className="flex w-full items-center justify-center text-white bg-red-500
																border border-gray-300 hover:bg-green-300  font-medium rounded-lg text-sm px-5 py-0.5  dark:bg-gray-800">
                                                    Add Multiple Entry
                                                </button>
                                            </div>
                                        </div>

                                        {adminModule === 1 &&
                                            <div className='flex-0 lg:w-8/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full flex mt-2 gap-2'>
                                                <button type="button" style={{ width: '180px' }} onClick={() => handleExportSheetDateWise(dailyRevenueList?.data, 'Pharmacy-Daily-Revenue', dailyRevenueList)}
                                                    className="flex w-full items-center justify-center text-white bg-red-500
                                                    border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-1.5 me-2 dark:bg-gray-800">Export Date Wise
                                                </button>

                                                <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full flex'>
                                                    <button type="button" style={{ width: '180px' }} onClick={() => handleExportSheetEntryWise(dailyRevenueList?.data, 'Pharmacy-Daily-Revenue', dailyRevenueList)}
                                                        className="flex w-full items-center justify-center text-white bg-green-500
															border border-gray-300 hover:bg-green-300  font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800">Export Entry Wise
                                                    </button>
                                                </div>
                                            </div>}

                                        {RevenueListResponse?.loading && RevenueListResponse?.type === 'daily_revenue_list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            RevenueListResponse?.error !== null && RevenueListResponse?.type === 'daily_revenue_list' ?

                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :

                                                dailyRevenueList?.data?.length === 0 && RevenueListResponse?.type === 'daily_revenue_list' ?

                                                    <div className="bg-white rounded-md mt-4">
                                                        <p className="text-gray-500">No Records are available.</p>
                                                    </div>

                                                    : RevenueListResponse?.type === 'daily_revenue_list' ?

                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {revenueListHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            dailyRevenueList?.data?.map((value, idx) => {
                                                                                return (<React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: '1px solid lightgray' }}>
                                                                                        <TableRowData value={`${value.date} (${value.dayname})`} />

                                                                                        <TableRowData value={value.type} />
                                                                                        <TableRowData value={value.amount} />
                                                                                        <TableRowData value={value.note} />

                                                                                        <td  className={'p-3 flex gap-2 btnnn'}>

                                                                                            <p onClick={() => { return setLogData(value), handleShowModal(setShowModal, showModal, 'updateRevenueList') }}
                                                                                                className="rubik-400 p-1 text-center rounded-lg text-white ">
                                                                                                <EditIcons />
                                                                                            </p>

                                                                                            <div onClick={() => { return setLogData(value), handleShowModal(setShowModal, showModal, 'deleteRevenueList') }}
                                                                                                className="rubik-400 p-1 text-center rounded-lg text-white ">
                                                                                                <DeleteIcon />
                                                                                            </div>
                                                                                        </td>

                                                                                    </tr>
                                                                                </React.Fragment>)
                                                                            })}

                                                                        {adminModule === 1 &&
                                                                            <tr style={{ borderBottom: '1px solid lightgray' }}>
                                                                                <td  className={'p-3'}>
                                                                                    <p><span className='rubik-500 text-gray-500'>SubTotal:</span> {dailyRevenueList?.subtotal}</p>
                                                                                </td>

                                                                                <td  className={'p-3'}>
                                                                                    <p><span className='rubik-500 text-gray-500'>Monthly Cash: </span>{dailyRevenueList?.cash_total}</p>
                                                                                </td>
                                                                                <td  className={'p-3'}>
                                                                                    <p><span className='rubik-500 text-gray-500'>Cash Deposit in Bank Payout: </span>{dailyRevenueList?.payout_total}</p>
                                                                                </td>
                                                                                <td  className={'p-3'}>
                                                                                    <p><span className='rubik-500 text-gray-500'>Cash Left Over: </span>{dailyRevenueList?.cash_left}</p>
                                                                                </td>
                                                                            </tr>}

                                                                    </tbody>
                                                                </table>

                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {
                showModal.multipleRevenue &&
                <AddMultipleRevenueModal setShowModal={setShowModal}
                    formData={formData}
                    setFormData={setFormData}
                    showModal={showModal}
                    setShowToast={setShowToast}
                />
            }

            {
                showModal.singleRevenue &&
                <AddSingleRevenueModal setShowModal={setShowModal}
                    formData={formData}
                    setFormData={setFormData}
                    showModal={showModal}
                    setShowToast={setShowToast}
                />
            }

            {
                showModal.updateRevenueList &&
                <UpdateDailyRevenueModal setShowModal={setShowModal}
                    formData={formData}
                    setFormData={setFormData}
                    showModal={showModal}
                    setShowToast={setShowToast}
                    logData={logData}
                />
            }

            {
                showModal.deleteRevenueList &&
                <DeleteDailyRevenueModal setShowModal={setShowModal}
                    showModal={showModal}
                    setShowToast={setShowToast}
                    logData={logData}
                    text={'Are you sure you want to delete this log?'}
                />
            }


            {
                showToast.successToast && addRevenueDataResponse?.type === 'add_multiple_entry' &&
                <SuccessToastMessage status={'Enteries Added Successfully'} />
            }

            {
                showToast.successToast && addRevenueDataResponse?.type === 'add_single_entry' &&
                <SuccessToastMessage status={'Entry Added Successfully'} />
            }

            {
                showToast.successToast && addRevenueDataResponse?.type === 'update_revenue_list' &&
                <SuccessToastMessage status={'Updated Successfully'} />
            }

            {
                showToast.successToast && addRevenueDataResponse?.type === 'delete_revenue_list' &&
                <SuccessToastMessage status={'Deleted Successfully'} />
            }

            {
                showToast.errorToast && addRevenueDataResponse?.type === 'add_multiple_entry' &&
                <ErrorToast status='Oops Got an issue while uploading details.please try to add again' />
            }

            {
                showToast.errorToast && addRevenueDataResponse?.type === 'delete_revenue_list' &&
                <ErrorToast status='Oops Got an issue while Deleting details.please try again' />
            }

            {
                showToast.errorToast && addRevenueDataResponse?.type === 'add_single_entry' &&
                <ErrorToast status='Oops Got an issue while uploading details.please try to add again' />
            }

            {
                showToast.errorToast && addRevenueDataResponse?.type === 'update_revenue_list' &&
                <ErrorToast status='Oops Got an issue while updating details.please try to update again' />
            }

            <ChatBox />
            <Common.Footer />
        </>
    );
};

export default DailyRevenue;
