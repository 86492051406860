import {
	LINK_PATIENTS_TO_CLINIC_REQUEST,
	LINK_PATIENTS_TO_CLINIC_SUCCESS,
	LINK_PATIENTS_TO_CLINIC_FAILURE
} from '../../constants'

import { pharmacy_patient_link_clinic } from '../../../networking/urlEndPoints';
import { fetchPatientsList } from './patientsList';
import Axios from '../../../networking/intraceptor';
import * as routeNames from '../../../routes/routeName'

export const linkPatientsToClinicRequest = () => ({
	type: LINK_PATIENTS_TO_CLINIC_REQUEST,
});

export const linkPatientsToClinicSuccess = (data) => ({
	type: LINK_PATIENTS_TO_CLINIC_SUCCESS,
	payload: data
});

export const linkPatientsToClinicFailure = (error) => ({
	type: LINK_PATIENTS_TO_CLINIC_FAILURE,
	payload: error
})


export const linkPatientsToClinic = (patient_id, clinic, showToast,
	setShowToast,
	setShowModal,
	showModal,
	paginationNumber,
	location,
	queryStatus,
	querySearch
) => {
	return async (dispatch) => {
		dispatch(linkPatientsToClinicRequest())
		try {
			const response = await Axios.post(`${pharmacy_patient_link_clinic}`, {
				patient_id: patient_id,
				clinic: clinic
			})
			if (response?.status === 200) {
				dispatch(linkPatientsToClinicSuccess(response?.data))

				if (typeof setShowToast === 'function') {
					setShowToast({
						...showToast,
						clinicToast: true
					})

					setTimeout(() => {
						setShowToast({
							...showToast,
							clinicToast: false
						})
					}, 3000);
				}

				setShowModal({
					...showModal,
					linkClinkModal: false
				})

				if ((location.pathname === routeNames.ALL_PATIENTS)) {
					await dispatch(fetchPatientsList(querySearch, queryStatus, paginationNumber))
				}
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(linkPatientsToClinicSuccess(error?.response))
				if (typeof setShowToast === 'function') {
					setShowToast({
						...showToast,
						clinicToastError: true
					})

					setTimeout(() => {
						setShowToast({
							...showToast,
							clinicToastError: false
						})
					}, 3000);
				}

			}
		}
	}
}