export const postStyle = {
	whiteSpace: 'pre-wrap',
};


export const initialPateintFormInputColor = {
	phn: 'lightgrey',
	firstName: 'lightgrey',
	lastName: 'lightgrey'
}

export const customStyles = {
	control: (provided) => ({
		...provided,
		height: 46,
		paddingLeft: '13px',
		color: '#6B7280',
		borderRadius: '8px',
		position: 'relative',
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'auto',
	}),
	menu: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'hidden',
	}),
};

export const customSelectStyles = {
	control: (provided) => ({
		...provided,
		height: 46,
		paddingLeft: '13px',
		color: '#6B7280',
		borderRadius: '8px',
		position: 'relative',
		top: '17.4px'
	}),

	menuList: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'auto',
	}),
	menu: (provided) => ({
		...provided,
		marginTop: '20px',
		maxHeight: '200px',
		overflowY: 'hidden',
	}),
};

export const customSearchStyle = {
	control: (provided) => ({
		...provided,
		height: 46,
		paddingLeft: '13px',
		color: '#6B7280',
		borderRadius: '8px',
		position: 'relative',
	}),

	menuList: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'auto',
	}),
	menu: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'hidden',
	}),
};


export const customPatientListStyle = {
	control: (provided) => ({
		...provided,
		height: 46,
		paddingLeft: '13px',
		color: '#6B7280',
		borderRadius: '8px',
		position: 'relative',
	}),

	menuList: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'auto',
	}),
	menu: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'hidden',
	}),
};

export const mediCationStyle = {
	control: (provided) => ({
		...provided,
		height: 46,
		paddingLeft: '13px',
		color: '#6B7280',
		borderRadius: '8px',
		position: 'relative',
		marginTop: '30px',
		marginBottom: '-20px'
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'auto',
	}),
	menu: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'hidden',
	}),
}

export const authLoginStyle = {
	control: (provided) => ({
		...provided,
		height: 48,
		paddingLeft: '13px',
		color: 'gray',
		position: 'relative',
	}),

	menuList: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'auto',
	}),
	menu: (provided) => ({
		...provided,
		marginTop: '4px',
		maxHeight: '200px',
		overflowY: 'hidden',
	}),
}

export const TopHeaderSearchStyle = {
	control: (provided) => ({
		...provided,
		height: 46,
		borderRadius: '8px',
		position: 'relative',
		width: '110px',
		borderColor: 'transparent !important',
		boxShadow: 'none !important'
	}),

	menuList: (provided) => ({
		...provided,
		maxHeight: '200px',
		maxWidth: '200px',
		overflowY: 'auto',
	}),
	menu: (provided) => ({
		...provided,
		maxHeight: '200px',
		maxWidth: '200px',
		overflowY: 'hidden',
	}),
}

export const RouteStyle = {
	control: (provided) => ({
		...provided,
		height: 42,
		paddingLeft: "13px",
		color: "#6B7280",
		borderRadius: "8px",
		position: "relative",
		border: "1px solid lightgrey",
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: "200px",
		overflowY: "auto",
	}),
	menu: (provided) => ({
		...provided,
		maxHeight: "200px",
		overflowY: "hidden",
	}),
}

export const initialARVFormInputColor = {
	patientPhone: "lightgrey",
	patientFirstName: "lightgrey",
	patientLastName: "lightgrey",
	PatientDob: "lightgrey",
	drugName: "lightgrey",
	dueInDays: "lightgrey",
	name:'lightgrey',
	fetchType: 'lightgrey',
	duein: 'lightgrey',
  };
  
  export const initialARVFormDrugColor = {
	drugName: "lightgrey",
	dueInDays: "lightgrey",
  };