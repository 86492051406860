import React, {
	useCallback,
	useEffect,
	useState
} from "react";

import {
	BreadCrumb,
	Footer,
	Sidebar
} from "../../../components/common";

import { ErrorMessages } from "../../../components/error/alertMessages";
import { NotificationsListLoader } from "../../../components/loaders/patientNotificationListLoader";
import { useCustomSelectors } from "../../../services/selectors/allSelectors";

import { debounce, slice } from "lodash";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import { pharmacy_srfax_log_list } from '../../../networking/urlEndPoints';

import { pharmacySentFaxLogsPreviewPdf } from "../../../services/actions/pharmacy/forms/previewPdf";
import { useFormsSelectors } from "../../../services/selectors/formsSelector";

import ChatBox from "../../../components/chat/chatbox";

import * as Hooks from '../../../hooks';
import * as pharmacyTeamNotes from '../../../services/actions/pharmacy/other';

const SentFaxLogs = () => {

	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();
	const location = Hooks.useCustomLocation();

	const { pharmacyAllTableListResponse } = useCustomSelectors();
	const { previewPdfResponse } = useFormsSelectors()

	const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

	const [tableList, setTableList] = useState({
		pageNumber: initialPage,
		listId: null
	})

	useEffect(() => {
		setTableList({ ...tableList, pageNumber: initialPage })
	}, [initialPage])

	useEffect(() => {
		dispatch(pharmacyTeamNotes.fetchAllTableListData(pharmacy_srfax_log_list, null, tableList?.pageNumber))
		return () => { }
	}, [])

	const handleInputChange = useCallback(
		debounce((e) => {
			e.preventDefault()
			const search = e.target.value;
			dispatch(pharmacyTeamNotes.fetchAllTableListData(pharmacy_srfax_log_list, search, null))
		}, 1000),
		[dispatch, location.search, navigate])

	const HandledownLoadPDF = (value) => {

		setTableList({ ...tableList, listId: value?.id })
		if (value?.id) {
			dispatch(pharmacySentFaxLogsPreviewPdf(value.pdf_link, 'sent_fax_log'))
		}
	}

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">
								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 gap-4 allNotificationSearchBg">
											<div className="relative">
												<input type="text" id="search" name='search' onChange={handleInputChange}
													className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white  
														`} placeholder="" />
												<label htmlFor="search"
													className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
													Search
												</label>
											</div>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mt-2">
											<div className="">
												{pharmacyAllTableListResponse?.loading ?
													<div className="shadow-md border relative overflow-x-auto rounded-lg">
														<NotificationsListLoader />
													</div>
													: pharmacyAllTableListResponse?.error !== null ?
														<ErrorMessages />
														: pharmacyAllTableListResponse?.data?.data?.data?.length <= 0 ?
															<h2>Fax Logs are not available.</h2> :
															<div className="shadow-md border relative overflow-x-auto rounded-lg">
																<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
																	<thead className="text-xs text-white uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400">
																		<tr>
																			<th scope="col" className="px-6 py-3 rounded-s-lg">
																				<p className="rubik-500 fs-14">Clinic/Type</p>
																			</th>
																			<th scope="col" className="px-6 py-3">
																				<p className="rubik-500 fs-14">To Fax Number</p>
																			</th>
																			<th scope="col" className="px-6 py-3">
																				<p className="rubik-500 fs-14">Form Name</p>
																			</th>
																			<th scope="col" className="px-6 py-3 rounded-e-lg">
																				<p className="rubik-500 fs-14">Status</p>
																			</th>

																			<th scope="col" className="px-6 py-3 rounded-e-lg">
																				<p className="rubik-500 fs-14"></p>
																			</th>

																		</tr>
																	</thead>

																	<tbody>
																		{pharmacyAllTableListResponse?.data?.data?.data?.map((value, index) => {
																			return (<React.Fragment key={index}>
																				<tr className="bg-white dark:bg-gray-800 border-b dark:border-gray-700">
																					<th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
																						<p className="rubik-500 fs-14">
																							{value?.pharmacy_clinic_name}
																						</p>

																						<p className="rubik-400 fs-14">{value?.type?.charAt(0)?.toUpperCase() + value?.type?.slice(1)}</p>
																					</th>
																					<td className="px-6 py-4">
																						<p className="rubik-400 fs-14">{value?.to_fax_number}</p>
																					</td>

																					<td className="px-6 py-4">
																						<p className="rubik-400 fs-14">{value?.form_name}</p>
																						<p className="rubik-400 fs-14">{value?.patient_name} {value?.phn}</p>
																					</td>

																					<td className="px-6 py-4">
																						<p className="rubik-400 fs-14">{value?.status}</p>
																					</td>

																					<td className="btnnn px-6 py-4 w-34" onClick={() => HandledownLoadPDF(value)}>
																						<p className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-1.5 
																								py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400 flex items-center px-4 
																								py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{previewPdfResponse?.loading && value?.id === tableList?.listId ? 'Processing...' : 'View Report'}</p>
																					</td>
																				</tr>
																			</React.Fragment>)
																		})}
																	</tbody>
																</table>
															</div>}

												{pharmacyAllTableListResponse?.error !== null ? null : <>
													{pharmacyAllTableListResponse?.data?.data?.data?.length !== 0 && <>
														{pharmacyAllTableListResponse?.data?.data?.pagination?.last_page !== 1 &&
															<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center 
																			md:justify-center justify-center mt-5">
																<nav aria-label="Page navigation example">
																	<ul className="inline-flex -space-x-px text-base h-10">
																		<CustomPagination
																			count={pharmacyAllTableListResponse?.data?.data?.pagination?.last_page}
																			page={tableList?.pageNumber}
																			onChange={(event, value) => {
																				setTableList({
																					...tableList,
																					pageNumber: initialPage,
																				})
																				dispatch(pharmacyTeamNotes.fetchAllTableListData(pharmacy_srfax_log_list, null, value))
																				const searchParams = new URLSearchParams();
																				searchParams.append('page', value);
																				const newUrl = `${location?.pathname}?${searchParams.toString()}`;
																				navigate(newUrl);
																			}} />
																	</ul>
																</nav>
															</div>
														}</>}
												</>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ChatBox />
			<Footer />
		</>
	)
}
export default SentFaxLogs;