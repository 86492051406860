import React, { useState, useEffect } from 'react'

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'

import { AddBlisterPack } from '../../services/actions/pharmacy/blisters';
import { useBlisterSelectors } from '../../services/selectors/blisterSelectors';

import * as Hooks from '../../hooks'
import * as routeNames from '../../routes/routeName'
import { AddPatientMedicationPack, AddPatientVialsPack } from '../../services/actions/pharmacy/blisters/addBlisterPackData';

const recurrenceData = [
    { label: "Every 7 days", value: '7' },
    { label: "Every 14 days", value: '14' },
    { label: "Every 21 days", value: '21' },
    { label: "Every 28 days", value: '28' },
];

const recurrenceRuleType = [
    { label: "System Generated", value: 'automatic' },
    { label: "Custom", value: 'custom' },
];

const recurrenceWeekday = [
    "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
];

const inputFeilds = [
    { label: "No. Of RX Need to bill", key: 'rx_need_to_bill' },
    { label: "Deductibles", key: "deductibles" }
];


const AddBlisterPackModal = ({ setShowModal, showModal, patientProfileData, setShowToast }) => {

    const { AddBlisterPackResponse } = useBlisterSelectors()
    const dispatch = Hooks.useCustomDispatch()
    const location = Hooks.useCustomLocation()

    const [formData, setFormData] = useState({
        recurrence_day: recurrenceData[0].value,
        recurrence_weekday: recurrenceWeekday[0],
        rx_need_to_bill: '',
        deductibles: '',
        note: '',
        recurrence_day_type: recurrenceRuleType[0].value,
        r_days: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmitPackDetails = () => {

        if (!AddBlisterPackResponse?.loading && location.pathname === routeNames.pharmacy_patient_vials_pack) {
            dispatch(AddPatientVialsPack('add_vials_pack', formData, patientProfileData.id, setShowModal, setShowToast))

        }

        if (!AddBlisterPackResponse?.loading && location.pathname === routeNames.pharmacy_speciality_pack) {
            dispatch(AddPatientMedicationPack('add_medication_pack', formData, patientProfileData.id, setShowModal, setShowToast))
        }

        else if (!AddBlisterPackResponse?.loading && patientProfileData.id) {
            dispatch(AddBlisterPack('add_blister_pack', formData, patientProfileData.id, setShowModal, setShowToast))
        }

    }

    return (
        <React.Fragment>
            <div className="overflow-y-auto overflow-x-hidden fixed top-0 left-0 right-0 bottom-0 z-50 flex  items-center 
							justify-center w-full bg-black bg-opacity-50 dark:bg-opacity-100 max-h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {location.pathname === routeNames.pharmacy_patient_vials_pack ? 'Add Vials Pack For' :
                                    location.pathname === routeNames.pharmacy_speciality_pack ? 'Add Speciality Medication Pack For' :
                                        'Add Blister Pack For'} {patientProfileData?.name}
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'addBlisterPackModal')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <form>

                                <div className='col-span-12 -mt-4'>
                                    <h1 className='rubik-500 fs-16 mb-1'>Recurrence Rule Type</h1>
                                    <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-1 flex flex-wrap items-center gap-3">
                                        {recurrenceRuleType?.map((label, index) => (
                                            <div key={index} className="flex items-center gap-3">
                                                <input
                                                    id={`rule-${index}`}
                                                    name="recurrence_day_type"
                                                    type="radio"
                                                    value={label.value}
                                                    checked={formData.recurrence_day_type === label.value}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            recurrence_day_type: e.target.value
                                                        });
                                                    }}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor={`rule-${index}`}
                                                    className="fs-14 -ml-2 rubik-400  text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    {label.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {formData.recurrence_day_type === 'automatic' ? <>
                                    <div className='col-span-12 mt-2'>
                                        <h1 className='rubik-500 fs-16 mb-1'>Recurrence</h1>
                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-1 flex flex-wrap items-center gap-3">
                                            {recurrenceData?.map((label, index) => (
                                                <div key={index} className="flex items-center gap-3">
                                                    <input
                                                        id={`radio-${index}`}
                                                        name="recurrence_day"
                                                        type="radio"
                                                        value={label.value}
                                                        checked={formData.recurrence_day === label.value}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                recurrence_day: e.target.value
                                                            });
                                                        }}
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                    <label htmlFor={`radio-${index}`}
                                                        className="fs-14 -ml-2 rubik-400  text-sm font-medium text-gray-900 dark:text-gray-300">
                                                        {label.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='col-span-12 mt-2'>
                                        <h1 className='rubik-500 fs-16 mb-1'>Day</h1>
                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-1 flex flex-wrap items-center gap-3">
                                            {recurrenceWeekday?.map((label, index) => (
                                                <div key={index} className="flex items-center gap-3">
                                                    <input
                                                        id={`weekday-${index}`}
                                                        name="recurrence_weekday"
                                                        type="radio"
                                                        value={label}
                                                        checked={formData.recurrence_weekday === label}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                recurrence_weekday: e.target.value
                                                            });
                                                        }}
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    />
                                                    <label htmlFor={`weekday-${index}`}
                                                        className="fs-14 -ml-2 rubik-400  text-sm font-medium text-gray-900 dark:text-gray-300">
                                                        {label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div></> :

                                    <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 mt-4">
                                        <div className="relative" >
                                            <input
                                                type="text"
                                                id={"r_days"}
                                                name={"r_days"}
                                                value={formData.r_days}
                                                onChange={(e) => setFormData({ ...formData, r_days: e.target.value })}
                                                className="block pl-4 px-2.5 pb-2.5 pt w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                                placeholder=""
                                                onKeyPress={(e) => {
                                                    if (isNaN(String.fromCharCode(e.charCode))) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />

                                            <label htmlFor={'r_days'}
                                                className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                                Recurrence Days
                                            </label>
                                        </div>

                                    </div>}

                                <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
                                    {inputFeilds?.map((field, idx) => (
                                        <div className="relative mt-4" key={idx}>
                                            <input
                                                type="text"
                                                id={field.key}
                                                name={field.key}
                                                value={formData[field.key]}
                                                onChange={handleInputChange}
                                                className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                                placeholder=""
                                                autoComplete='false'
                                                onKeyPress={(e) => {
                                                    if (isNaN(String.fromCharCode(e.charCode))) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                            <label
                                                htmlFor={field.key}
                                                className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                {field.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">

                                    <div className="relative" >
                                        <textarea
                                            type="text"
                                            id={"note"}
                                            name={"note"}
                                            value={formData.note}
                                            onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                                            className="block pl-4 px-2.5 pb-2.5 pt w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                            placeholder=""
                                        />
                                        <label
                                            htmlFor={'note'}
                                            className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                        >
                                            Note
                                        </label>
                                    </div>

                                </div>


                                <div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
                                    <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
                                        <div className="col-span-12">
                                            <button type="button" onClick={handleSubmitPackDetails}
                                                className={`rubik-500 flex justify-center items-center text-white blueBg-color   w-full text-sm px-5 py-2.5 text-center`}>
                                                {AddBlisterPackResponse?.loading ? 'Processing...' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default AddBlisterPackModal