import React, { useEffect, useState } from 'react';
import { handleHideModal } from '../../assets/js/custome';
import { ModalCloseIcon } from '../../assets/svg/icons';

import { TextInput } from './manageTeamList';
import { StartDate } from '../../components/pharmacy/dispense/dispense';
import { PharmacyAllEmployees } from '../../components/pharmacy/admin/form';
import { useCustomDispatch } from '../../hooks';

import { currentDate } from '../../utils/constants/formInitialStates';
import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors';
import { onhandleAddShiftValidationForm, onhandleEditScheduleShiftValidationForm } from '../../utils/validations/adminModuleValidations';

import { HandleAddPublishShift, HandleADDShift } from '../../services/actions/admin/team/addTeamData';
import { convertTo24HourFormat } from './teamTimeSheet';

import Axios from '../../networking/intraceptor';
import Button from '../../components/common/button';


const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const fetchBreakPayrollValues = (params, setFormData, formData) => {
    return async () => {
        try {
            const response = await Axios.get(`/pharmacy-settings`, { params })
            if (response?.status === 200) {
                const time = response?.data?.data?.enforcement_setting
                const startTime24 = convertTo24HourFormat(time?.startTime);
                const endTime24 = convertTo24HourFormat(time?.end_time);

                setFormData({
                    ...formData,
                    startTime: startTime24,
                    endTime: endTime24,
                });

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
            }
        }
    }
}

export const AddShiftModal = ({ showModal, setShowModal, setShowToast, startDate, endDate }) => {


    const dispatch = useCustomDispatch()

    const { addTeamDataResponse } = useAdminSelectors()

    const [formData, setFormData] = useState({ name: '', date: currentDate, startTime: '', endTime: '', repeatOn: daysOfWeek, occuernce: '', employeesID: '' });
    const [errorMessage, setErrorMessages] = useState({ name: '', date: '', startTime: '', endTime: '', repeatOn: '', occuernce: '', employeesID: '' });

    const handleAddShiftValidationForm = () => {
        onhandleAddShiftValidationForm(setErrorMessages, errorMessage, formData, dispatch, setShowModal, setShowToast, startDate, endDate)
    }

    useEffect(() => {
        dispatch(fetchBreakPayrollValues({ type: 'enforcement' }, setFormData, formData));
    }, [])

    const [repeatDays, setRepeatDays] = useState(
        daysOfWeek.reduce((acc, day) => {
            acc[day] = true;
            return acc;
        }, {})
    );

    useEffect(() => {
        const selectedDays = Object.keys(repeatDays).filter(day => repeatDays[day]);
        setFormData(prevFormData => ({
            ...prevFormData,
            repeatOn: selectedDays
        }));
    }, [repeatDays]);

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Add Shift
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'addShift')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-6 md:p-5 space-y-4">

                        <div>
                            <PharmacyAllEmployees formData={formData} setFormData={setFormData} logData={''} editLog={false} />
                            {errorMessage.employeesID && <p className='text-red-500 fs-10'>{errorMessage.employeesID}</p>}
                        </div>

                        <div>
                            <StartDate formData={formData} setFormData={setFormData} height='48px' />
                            {errorMessage.date && <p className='text-red-500 fs-10'>{errorMessage.date}</p>}
                        </div>

                        <div className='grid grid-cols-2 gap-2'>
                            <div>
                                <TextInput formData={formData} setFormData={setFormData} name='startTime' textType='time' label='START TIME' />
                                {errorMessage.startTime && <p className='text-red-500 fs-10'>{errorMessage.startTime}</p>}
                            </div>
                            <div>
                                <TextInput formData={formData} setFormData={setFormData} name='endTime' textType='time' label='END TIME' />
                                {errorMessage.endTime && <p className='text-red-500 fs-10'>{errorMessage.endTime}</p>}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Repeat On</label>
                            <div className="flex space-x-2 mt-1">
                                {Object.keys(repeatDays).map((day, idx) => (
                                    <div className="flex items-center" key={idx}>
                                        <input checked={repeatDays[day]} id={`${idx}-checkbox`} onChange={() => setRepeatDays({ ...repeatDays, [day]: !repeatDays[day] })} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor={`${idx}-checkbox`} className="text-sm ml-2 font-medium text-gray-900 dark:text-gray-300">{day}</label>
                                    </div>
                                ))}
                            </div>
                            {errorMessage.repeatOn && <p className='text-red-500 fs-10'>{errorMessage.repeatOn}</p>}

                        </div>

                        <div>
                            <TextInput formData={formData} textType='number' setFormData={setFormData} name='occuernce' label='Occurrence (weeks)' />
                            {errorMessage.occuernce && <p className='text-red-500 fs-10'>{errorMessage.occuernce}</p>}
                        </div>

                        <div className="mt-6 flex justify-end space-x-2">
                            <button onClick={() => handleHideModal(setShowModal, showModal, 'addShift')} className="px-4 py-1.5 bg-red-500  fs-12 rubik-500 text-white rounded hover:bg-red-600">Cancel</button>
                            <button className="px-4 py-1.5 bg-green-500 fs-12 rubik-500 text-white rounded hover:bg-green-600" onClick={handleAddShiftValidationForm}>
                                {addTeamDataResponse?.loading && addTeamDataResponse?.type === 'add_shift' ? 'Processing...' : 'Create'}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};


export const EditShiftModal = ({ showModal, setShowModal, setShowToast, logData, employeeDetail, startDate, endDate }) => {

    const dispatch = useCustomDispatch()

    const { addTeamDataResponse } = useAdminSelectors()

    const [formData, setFormData] = useState({ name: '', startTime: '', endTime: '', occuernce: '', employeesID: '' });
    const [errorMessage, setErrorMessages] = useState({ name: '', startTime: '', endTime: '', occuernce: '', employeesID: '' });


    const handleAddShiftValidationForm = () => {
        onhandleEditScheduleShiftValidationForm(setErrorMessages, errorMessage, formData, dispatch, setShowModal, setShowToast, logData?.id, startDate, endDate)
    }


    const TimeFormat = (time12h) => {
        let [time, modifier] = time12h.split(/(?=[AP]M)/);
        let [hours, minutes] = time.split(':');

        hours = parseInt(hours);
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };

    useEffect(() => {
        if (logData) {
            const startTime24 = TimeFormat(logData.start_time);
            const endTime24 = TimeFormat(logData.end_time);

            setFormData({
                ...formData,
                startTime: startTime24,
                endTime: endTime24,
            });
        }
    }, [logData]);

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Edit Time Slot
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'editShift')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-6 md:p-5 space-y-4">

                        <div>
                            <p className='rubik-500 text-gray-500 fs-16'>Employee: <span className='rubik-400'>{employeeDetail.name}</span></p>
                        </div>

                        <div>
                            <p className='rubik-500 text-gray-500 fs-16'><span className='rubik-400'>{logData.start_time} - {logData.end_time}</span></p>
                        </div>

                        <div className='grid grid-cols-2 gap-2'>
                            <div>
                                <TextInput formData={formData} setFormData={setFormData} name='startTime' textType='time' label='START TIME' />
                                {errorMessage.startTime && <p className='text-red-500 fs-10'>{errorMessage.startTime}</p>}
                            </div>
                            <div>
                                <TextInput formData={formData} setFormData={setFormData} name='endTime' textType='time' label='END TIME' />
                                {errorMessage.endTime && <p className='text-red-500 fs-10'>{errorMessage.endTime}</p>}
                            </div>
                        </div>

                        <div className="mt-6 flex justify-end space-x-2">
                            <button className="px-4 py-1.5 bg-red-500  fs-12 rubik-500 text-white rounded hover:bg-red-600">Cancel</button>
                            <button className="px-4 py-1.5 bg-green-500 fs-12 rubik-500 text-white rounded hover:bg-green-600" onClick={handleAddShiftValidationForm}>
                                {addTeamDataResponse?.loading && addTeamDataResponse?.type === 'edit_shift' ? 'Processing...' : 'Save'}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};


export const AddPublishModal = ({ showModal, setShowModal, date, startDate, endDate, setShowToast }) => {

    const dispatch = useCustomDispatch()

    const { addTeamDataResponse, TeamListResponse } = useAdminSelectors()
    const teamScheduleList = TeamListResponse?.data

    const [formData, setFormData] = useState({ type: 'everyone' });

    const handleSubmit = () => {
        const allIds = teamScheduleList?.users_data
            .map(user =>
                user.days.flatMap(day =>
                    day.shifts.map(shift => shift.id)
                )
            ).flat();

        const reqData = {
            slotIds: allIds,
            'notificationPrefernce': formData.type
        }

        dispatch(HandleAddPublishShift('add_publish_shift', setShowToast, setShowModal, reqData, startDate, endDate))
    }

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Edit Time Slot
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'addPublish')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="bg-white rounded-lg w-1/3">
                        <div className="p-4">
                            <p className="text-sm"><span className='rubik-500 text-gray-700'>Summary     &nbsp; &nbsp; :</span> {date}</p>
                            <p className="text-sm"><span className='rubik-500 text-gray-700'>Total Shifts :</span> {teamScheduleList?.total_shift}</p>
                            <p className="text-sm"><span className='rubik-500 text-gray-700'>Total Hours :</span>  {teamScheduleList?.total_hours}</p>
                            <div className="mt-4 w-80">
                                <label htmlFor="notificationPreference" className="block text-sm font-medium text-gray-700">Notification Preference</label>
                                <select onChange={(e) => setFormData({ ...formData, type: e.target.value })} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"                                >
                                    <option value={'everyone'}>Notify everyone on the team</option>
                                    <option value={'changes'}>Notify team members with changes only</option>
                                    <option value={'none'}>Do not notify anyone</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='w-full p-4 -mt-4'>
                        <Button text={addTeamDataResponse?.type === 'add_publish_shift' && addTeamDataResponse.loading ? 'Processing...' : 'Submit'} color='white' width='100%'
                            handleFilter={() => addTeamDataResponse?.type === 'add_publish_shift' && addTeamDataResponse.loading ? null : handleSubmit()} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const AddEmployeeShift = ({ showModal, setShowModal, setShowToast, logData, employeeDetail, startDate, endDate, selectedDays }) => {

    const dispatch = useCustomDispatch()

    const { addTeamDataResponse } = useAdminSelectors()

    const [formData, setFormData] = useState({ name: '', startTime: '', date: currentDate, endTime: '', employeesID: '', repeatOn: daysOfWeek });
    const [errorMessage, setErrorMessages] = useState({ name: '', startTime: '', endTime: '', employeesID: '', repeatOn: '' });

    useEffect(() => {
        if (logData) {
            const startTime24 = convertTo24HourFormat(logData.start_time);
            const endTime24 = convertTo24HourFormat(logData.end_time);

            setFormData({
                ...formData,
                startTime: startTime24,
                endTime: endTime24,
            });
        }
    }, [logData]);

    const [repeatDays, setRepeatDays] = useState(
        daysOfWeek.reduce((acc, day) => {
            acc[day] = false;
            return acc;
        }, {})
    );

    useEffect(() => {
        const selectedDay = selectedDays?.day.split(' ')[0];
        const dayMap = {
            Sun: 'Sunday',
            Mon: 'Monday',
            Tue: 'Tuesday',
            Wed: 'Wednesday',
            Thu: 'Thursday',
            Fri: 'Friday',
            Sat: 'Saturday'
        };

        const updatedRepeatDays = Object.keys(dayMap).reduce((acc, key) => {
            acc[dayMap[key]] = dayMap[key].startsWith(selectedDay);
            return acc;
        }, {});

        setRepeatDays(updatedRepeatDays);
    }, [selectedDays]);

    useEffect(() => {
        const selectedDaysArray = Object.keys(repeatDays).filter(day => repeatDays[day]);
        setFormData(prevFormData => ({
            ...prevFormData,
            repeatOn: selectedDaysArray
        }));
    }, [repeatDays]);

    const handleAddValidateShift = () => {
        const specificValidations = {
            startTime: 'Start Time is required.',
            endTime: 'End Time is required.',
        };

        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const validateTimeOrder = (startTime, endTime) => {
            if (!startTime || !endTime) {
                return '';
            }
            return new Date(`1970-01-01T${endTime}`) <= new Date(`1970-01-01T${startTime}`) ? 'End Time must be after Start Time.' : '';
        };

        const newErrorMessages = Object.fromEntries(
            Object.entries(specificValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        )

        newErrorMessages.endTime = newErrorMessages.endTime || validateTimeOrder(formData.startTime, formData.endTime);

        if (formData.repeatOn.length === 0) {
            newErrorMessages.repeatOn = 'Please select at least one day of the week.';
        }


        setErrorMessages({ ...errorMessage, ...newErrorMessages });

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

        if (allDataPresent) {
            const { startTime, endTime, date, occuernce, employeesID } = formData;

            const reqData = {
                department_employees: [employeeDetail?.user_id],
                start_date: startDate,
                start_time: startTime,
                end_time: endTime,
                weekday: formData.repeatOn.slice(),
                occurrence: 1
            };
            dispatch(HandleADDShift('add_shift', setShowToast, setShowModal, reqData, startDate, endDate))
        }
    }

    useEffect(() => {
        dispatch(fetchBreakPayrollValues({ type: 'enforcement' }, setFormData, formData));
    }, [])

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Add Shift
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'addemployeeShift')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-6 md:p-5 space-y-4">

                        <div>
                            <p className='rubik-500 text-gray-500 fs-16'>Employee: <span className='rubik-400'>{employeeDetail.name}</span></p>
                        </div>

                        <div className='grid grid-cols-2 gap-2'>
                            <div>
                                <TextInput formData={formData} setFormData={setFormData} name='startTime' textType='time' label='START TIME' />
                                {errorMessage.startTime && <p className='text-red-500 fs-10'>{errorMessage.startTime}</p>}
                            </div>
                            <div>
                                <TextInput formData={formData} setFormData={setFormData} name='endTime' textType='time' label='END TIME' />
                                {errorMessage.endTime && <p className='text-red-500 fs-10'>{errorMessage.endTime}</p>}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Repeat On</label>
                            <div className="flex space-x-2 mt-1">
                                {Object.keys(repeatDays).map((day, idx) => (
                                    <div className="flex items-center" key={idx}>
                                        <input checked={repeatDays[day]} id={`${idx}-checkbox`} onChange={() => setRepeatDays({ ...repeatDays, [day]: !repeatDays[day] })} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor={`${idx}-checkbox`} className="text-sm ml-2 font-medium text-gray-900 dark:text-gray-300">{day}</label>
                                    </div>
                                ))}
                            </div>
                            {errorMessage.repeatOn && <p className='text-red-500 fs-10'>{errorMessage.repeatOn}</p>}
                        </div>

                        <div className="mt-6 flex justify-end space-x-2">
                            <button type='button'
                                onClick={() => handleHideModal(setShowModal, showModal, 'addemployeeShift')}
                                className="px-4 py-1.5 bg-red-500  fs-12 rubik-500 text-white rounded hover:bg-red-600">Cancel

                            </button>
                            <button className="px-4 py-1.5 bg-green-500 fs-12 rubik-500 text-white rounded hover:bg-green-600" onClick={handleAddValidateShift}>
                                {addTeamDataResponse?.loading && addTeamDataResponse?.type === 'add_shift' ? 'Processing...' : 'Add'}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};