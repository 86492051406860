import React, { useEffect, useState } from 'react'

import {
    ErrorToastMessage,
    SuccessToastMessage
} from '../../components/loaders/toastMessage'

import {
    useCustomDispatch,
    useCustomLocation,
    useCustomNavigate
} from '../../hooks'

import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors'

import { TextErrorInput } from '../../components/pharmacy/admin/form'
import { fetchPharmacySettingsList } from '../../services/actions/admin/team/fetchTeamsList'

import { onhandleValidataePharmacySettingsForm } from '../../utils/validations/adminModuleValidations'
import { initalPharmacySettingData } from '../../utils/constants/formInitialStates'
import { useCustomSelectors } from '../../services/selectors/allSelectors'

import * as Common from '../../components/common'

import ChatBox from '../../components/chat/chatbox'
import Button from '../../components/common/button'
import DeliveryModule from './deliveryModule'
import Defaultpharmacist from './defaultpharmacist'
import MismatchDeliveryMail from './manageMismatchDeliveryTime'

const ManagePharmacySettings = () => {

    const dispatch = useCustomDispatch()
    const location = useCustomLocation()
    const navigate = useCustomNavigate()

    const { addTeamDataResponse, TeamListResponse } = useAdminSelectors()
    const { pharmacyLoginData } = useCustomSelectors()
    const pharmacy_details = TeamListResponse?.data?.data

    const [formData, setFormData] = useState(initalPharmacySettingData)
    const [errorMessage, setErrorMessages] = useState(initalPharmacySettingData)

    const [showToast, setShowToast] = useState({ successToast: '', errorToast: '' })
    const [activeTab, setActiveTab] = useState('winrx_settings');

    const handleValidataePharmacySettingsForm = (type) => {
        onhandleValidataePharmacySettingsForm(type, formData, setErrorMessages, errorMessage, dispatch, setShowToast)
    }

    useEffect(() => {
        dispatch(fetchPharmacySettingsList('winrx_settings'))
        return () => { }
    }, [dispatch])

    useEffect(() => {

        if (pharmacy_details) {
            const { winrx_setting, srfax_setting, app_notification } = pharmacy_details
            if (addTeamDataResponse?.loading) {
                setFormData({
                    ...formData,
                    winrx_pharmacy_id: '',
                    winrx_token: '',
                    winrx_status: '',

                    fax_accound_id: '',
                    fax_password: '',
                    sender_fax_number: '',
                    sender_email: '',
                    fax_status: '',

                    app_fcm_token_key: ''
                })
            } else if (winrx_setting || srfax_setting || app_notification) {

                setFormData({
                    ...formData,
                    winrx_pharmacy_id: winrx_setting?.pharmacy_id || '',
                    winrx_token: winrx_setting?.pharmacy_token || '',
                    winrx_status: winrx_setting?.active || '',
                    fax_accound_id: srfax_setting?.account_id || '',
                    fax_password: srfax_setting?.password || '',
                    sender_fax_number: srfax_setting?.fax_number || '',
                    sender_email: srfax_setting?.fax_email || '',
                    fax_status: srfax_setting?.fax_active || '',

                    app_fcm_token_key: app_notification?.fcm_token || ''
                })
            }
        }

    }, [TeamListResponse, activeTab])

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`${location.pathname}?active=${tab}`);

        if (tab === 'mistmatch_delivery') {
            dispatch(fetchPharmacySettingsList('winrx_settings'))
        } else if (tab === 'delivery_access_code') {
            dispatch(fetchPharmacySettingsList('winrx_settings'))
        }
    };

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const activeParam = params.get('active');

        const validTabs = [
            'fax_settings',
            'app_settings',
            'delivery_settings',
            'default_pharmacist_settings',
            'mistmatch_delivery',
            'delivery_access_code'
        ];

        if (validTabs.includes(activeParam)) {
            setActiveTab(activeParam);
        }
    }, []);

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" >
                                <div className="grid grid-cols-12 gap-4 w-full">

                                </div>

                                <div className="container mt-2">
                                    {/* ----------------------------------------Winrx_settings------------------------------------------ */}

                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                        <div className="mb-4 border-b border-gray-200 dark:border-gray-700 flex">
                                            <ul className="flex flex-nowrap -mb-px text-sm font-medium text-center overflow-x-auto whitespace-nowrap w-full scroll-box" id="filter-tab" role="tablist">

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block border-b-1 rounded-t-sm p-4 ${activeTab === 'winrx_settings' ? 'bg-gray-100' : ''}`}
                                                        onClick={() => handleTabChange('winrx_settings')}
                                                        type="button"
                                                        aria-pressed={activeTab === 'winrx_settings' ? 'true' : 'false'}>
                                                        Winrx Settings
                                                    </button>
                                                </li>

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'fax_settings' ? 'bg-gray-100' : ''}`}
                                                        onClick={() => {
                                                            return handleTabChange('fax_settings')
                                                        }}
                                                        type="button"
                                                        aria-pressed={activeTab === 'fax_settings' ? 'true' : 'false'}>
                                                        Fax Settings
                                                    </button>
                                                </li>

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'delivery_settings' ? 'bg-gray-100' : ''}`}
                                                        onClick={() => {
                                                            return handleTabChange('delivery_settings')
                                                        }}
                                                        type="button"
                                                        aria-pressed={activeTab === 'delivery_settings' ? 'true' : 'false'}>
                                                        Delivery Time Settings
                                                    </button>
                                                </li>

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'default_pharmacist_settings' ? 'bg-gray-100' : ''}`}
                                                        onClick={() => {
                                                            return handleTabChange('default_pharmacist_settings')
                                                        }}
                                                        type="button"
                                                        aria-pressed={activeTab === 'default_pharmacist_settings' ? 'true' : 'false'}>
                                                        Default Pharmacist Settings
                                                    </button>
                                                </li>

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'mistmatch_delivery' ? 'bg-gray-100' : ''}`}
                                                        onClick={() => {
                                                            return handleTabChange('mistmatch_delivery')
                                                        }}
                                                        type="button"
                                                        aria-pressed={activeTab === 'mistmatch_delivery' ? 'true' : 'false'}>
                                                        Manage Mismatch Delivery Mail
                                                    </button>
                                                </li>

                                                <li className="me-2" role="presentation">
                                                    <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'delivery_access_code' ? 'bg-gray-100' : ''}`}
                                                        onClick={() => {
                                                            return handleTabChange('delivery_access_code')
                                                        }}
                                                        type="button"
                                                        aria-pressed={activeTab === 'delivery_access_code' ? 'true' : 'false'}>
                                                        Delivery Access Code Mail
                                                    </button>
                                                </li>

                                                {pharmacyLoginData?.app_mode === 1 &&
                                                    <li className="me-2" role="presentation">
                                                        <button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'app_settings' ? 'bg-gray-100' : ''}`}
                                                            onClick={() => {
                                                                return handleTabChange('app_settings')

                                                            }}
                                                            type="button"
                                                            aria-pressed={activeTab === 'app_settings' ? 'true' : 'false'}>
                                                            App Notification FCM Token
                                                        </button>
                                                    </li>}
                                            </ul>

                                        </div>

                                        <div id="filter-tab-content">
                                            <div className={`rounded-lg dark:bg-gray-800 mb-80 ${activeTab === 'winrx_settings' ? '' : 'hidden'}`}>
                                                {activeTab === 'winrx_settings' &&
                                                    <div>
                                                        {TeamListResponse?.loading && TeamListResponse?.type === 'winrx_settings' ?
                                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                                <Common.LoadingView />
                                                            </div> :
                                                            <div className='border border-gray-200 p-6 rounded-md'>
                                                                <div className='grid grid-cols-2 gap-2 mt-2'>
                                                                    <div>
                                                                        <TextErrorInput label='PHARMACY ID' name='winrx_pharmacy_id' formData={formData} setFormData={setFormData} textType='Number' errorMessage={errorMessage.winrx_pharmacy_id} />
                                                                    </div>
                                                                    <div>
                                                                        <TextErrorInput label='TOKEN' name='winrx_token' formData={formData} setFormData={setFormData} textType='text' errorMessage={errorMessage.winrx_token} />
                                                                    </div>
                                                                </div>

                                                                <div className='mt-2'>
                                                                    <h1 className='text-gray-700 rubik-500 fs-12'>STATUS</h1>
                                                                    <label className="inline-flex items-center cursor-pointer mt-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={formData?.winrx_status === 1}
                                                                            onChange={(e) => {
                                                                                setFormData({ ...formData, winrx_status: e.target.checked ? 1 : 0 })
                                                                            }}
                                                                            className="sr-only peer"
                                                                        />

                                                                        <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                                        <span className="ms-3 text-sm font-medium text-gray-700 dark:text-gray-300 ml-2">active / inactive</span>
                                                                    </label>
                                                                </div>

                                                                <div className='mt-2' style={{ height: '35px' }}>
                                                                    <Button text={addTeamDataResponse?.type === 'update_winrx_settings' && addTeamDataResponse?.loading ? 'Updating...' : 'Update'} color='white' width='10%' height='38px !important' handleFilter={() => handleValidataePharmacySettingsForm('winrx_settings')} />
                                                                </div>
                                                            </div>} </div>
                                                }
                                            </div>
                                        </div>


                                        {/* ----------------------------------------FAx settings------------------------------------------ */}

                                        <div className={`rounded-lg dark:bg-gray-800 mb-80 ${activeTab === 'fax_settings' ? '' : 'hidden'}`} >
                                            {activeTab === 'fax_settings' && <>
                                                {TeamListResponse?.loading && TeamListResponse?.type === 'winrx_settings' ?
                                                    <div className="flex justify-center items-center mt-60 mb-80">
                                                        <Common.LoadingView />
                                                    </div> :
                                                    <div className='border border-gray-200 p-6 rounded-md mt-2'>
                                                        <div className='grid grid-cols-2 gap-2 mt-2'>
                                                            <div>
                                                                <TextErrorInput label='ACCOUNT ID' name='fax_accound_id' formData={formData} setFormData={setFormData} textType='number' errorMessage={errorMessage.fax_accound_id} />
                                                            </div>
                                                            <div>
                                                                <TextErrorInput label='PASSWORD' name='fax_password' formData={formData} setFormData={setFormData} textType='text' errorMessage={errorMessage.fax_password} />
                                                            </div>
                                                        </div>

                                                        <div className='grid grid-cols-2 gap-2 mt-2'>
                                                            <div>
                                                                <TextErrorInput label='SENDER FAX NUMBER' name='sender_fax_number' formData={formData} setFormData={setFormData} textType='Number' errorMessage={errorMessage.sender_fax_number} />
                                                            </div>
                                                            <div>
                                                                <TextErrorInput label='SENDER EMAIL' name='sender_email' formData={formData} setFormData={setFormData} textType='text' errorMessage={errorMessage.sender_email} />
                                                            </div>
                                                        </div>

                                                        <div className='mt-2'>
                                                            <h1 className='text-gray-700 rubik-500 fs-12'>STATUS</h1>
                                                            <label className="inline-flex items-center cursor-pointer mt-2">

                                                                <input
                                                                    type="checkbox"
                                                                    checked={formData?.fax_status === 1}
                                                                    onChange={(e) => {
                                                                        setFormData({ ...formData, fax_status: e.target.checked ? 1 : 0 })
                                                                    }}
                                                                    className="sr-only peer"
                                                                />
                                                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                                <span className="ms-3 text-sm font-medium text-gray-700 dark:text-gray-300 ml-2">active / inactive</span>
                                                            </label>
                                                        </div>

                                                        <div className='mt-2' style={{ height: '35px' }}>
                                                            <Button text={addTeamDataResponse?.type === 'update_fax_settings' && addTeamDataResponse?.loading ? 'Updating...' : 'Update'} color='white' width='10%' height='38px !important' handleFilter={() => handleValidataePharmacySettingsForm('fax_settings')} />
                                                        </div>

                                                    </div>
                                                }
                                            </>}
                                        </div>

                                        {/* ----------------------------------------Delivery settings------------------------------------------ */}

                                        <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'delivery_settings' ? '' : 'hidden'}`} >
                                            {activeTab === 'delivery_settings' && <>
                                                <DeliveryModule />
                                            </>}
                                        </div>


                                        {/* ----------------------------------------Delivery settings------------------------------------------ */}

                                        <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'default_pharmacist_settings' ? '' : 'hidden'}`} >
                                            {activeTab === 'default_pharmacist_settings' && <>
                                                <Defaultpharmacist />
                                            </>}
                                        </div>

                                        <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'mistmatch_delivery' ? '' : 'hidden'}`} >
                                            {activeTab === 'mistmatch_delivery' && <>
                                                <MismatchDeliveryMail />
                                            </>}
                                        </div>

                                        <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'delivery_access_code' ? '' : 'hidden'}`} >
                                            {activeTab === 'delivery_access_code' && <>
                                                <MismatchDeliveryMail />
                                            </>}
                                        </div>

                                        {/* ----------------------------------------APP NOTIFICATION FCM TOKEN ------------------------------------------ */}

                                        <div className={`rounded-lg dark:bg-gray-800 mb-96 ${activeTab === 'app_settings' ? '' : 'hidden'}`} >
                                            {activeTab === 'app_settings' && <>
                                                {TeamListResponse?.loading && TeamListResponse?.type === 'winrx_settings' ?
                                                    <div className="flex justify-center items-center mt-60 mb-80">
                                                        <Common.LoadingView />
                                                    </div> :
                                                    <div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'app_settings' ? '' : 'hidden'}`} >

                                                        <div className='border border-gray-200 p-6 rounded-md mt-2'>
                                                            <div className='grid grid-cols-2 gap-2 mt-2'>
                                                                <div>
                                                                    <TextErrorInput label='FCM TOKEN KEY' name='app_fcm_token_key' formData={formData} setFormData={setFormData} textType='text' errorMessage={errorMessage.app_fcm_token_key} />
                                                                </div>
                                                            </div>
                                                            <div className='mt-2' style={{ height: '35px' }}>
                                                                <Button text={addTeamDataResponse?.type === 'app_notifications' && addTeamDataResponse?.loading ? 'Updating...' : 'Update'} color='white' width='8%' height='18px !important' handleFilter={() => handleValidataePharmacySettingsForm('app_notifications')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showToast.successToast && addTeamDataResponse.type === 'update_winrx_settings' && <SuccessToastMessage status={'Updated successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'update_winrx_settings' && <ErrorToastMessage status={'Got an issue while updating.please try again.'} />}


            {showToast.successToast && addTeamDataResponse.type === 'update_fax_settings' && <SuccessToastMessage status={'Updated successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'update_fax_settings' && <ErrorToastMessage status={'Got an issue while updating.please try again.'} />}

            {showToast.successToast && addTeamDataResponse.type === 'app_notifications' && <SuccessToastMessage status={'Updated successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'app_notifications' && <ErrorToastMessage status={'Got an issue while updating.please try again.'} />}

            <ChatBox />
        </>
    )
}

export default ManagePharmacySettings