import {
	PHARMACY_PATIENTS_ROUTE_LIST_REQUEST,
	PHARMACY_PATIENTS_ROUTE_LIST_SUCCESS,
	PHARMACY_PATIENTS_ROUTE_LIST_FAILURE

} from '../../../constants';

import Axios from '../../../../networking/intraceptor'
import { patient_route_list } from '../../../../networking/urlEndPoints'

export const pharmacyPatientRouteListRequest = () => ({
	type: PHARMACY_PATIENTS_ROUTE_LIST_REQUEST
});

export const pharmacyPatientRouteListSuccess = (data) => ({
	type: PHARMACY_PATIENTS_ROUTE_LIST_SUCCESS,
	payload: data,
});

export const pharmacyPatientRouteListFailure = (error) => ({
	type: PHARMACY_PATIENTS_ROUTE_LIST_FAILURE,
	payload: error,
})

export const fetchPatientsRouteList = () => {
	return async (dispatch) => {
		dispatch(pharmacyPatientRouteListRequest());
		try {
			const response = await Axios.get(`${patient_route_list}`)

			if (response?.status === 200) {
				dispatch(pharmacyPatientRouteListSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyPatientRouteListFailure(error?.response));
			}
		}
	}
}

