import {
	PHARMACY_ADD_DISPENSE_DATA_REQUEST,
	PHARMACY_ADD_DISPENSE_DATA_SUCCESS,
	PHARMACY_ADD_DISPENSE_DATA_FAILURE
} from '../../../constants';

const initialState = {
	data: null,
	loading: false,
	error: null,
	type: null,
};

const addDispenseDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case PHARMACY_ADD_DISPENSE_DATA_REQUEST:
			return {
				...state,
				loading: true,
				data: null,
				error: null,
				type: action.types,
			};
		case PHARMACY_ADD_DISPENSE_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				type: action.types,
				error: null,
			};
		case PHARMACY_ADD_DISPENSE_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				type: action.types,
				data: null,
			};
		default:
			return state;
	}
};

export default addDispenseDataReducer;
