import * as path from '../../networking/urlEndPoints';
import * as forms from '../../services/actions/pharmacy/forms';
import * as modalCompound from '../../services/actions/pharmacy/other';

const validateField = (fieldName, value, requiredMessage) => (!value || value.length === 0 ? requiredMessage : '');

export const handlePharmacyCompoundAggrementForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	type,
	showToast,
	setShowToast
) => {

	const specificValidations = {
		toPharmacy: 'Pharmacy is required',
		toPharmacyManager: 'Manager is required',
		toPharmacyFax: 'Pharmacy Fax is required',
		toPharmacyPhone: 'Pharmacy Phone is required',
		provider: 'Provider is required',
		pharmacyManager: 'Pharmacy Manager is required'
	};

	const allValidations = { ...specificValidations };

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	if (allDataPresent) {

		const requestedData = {
			date: formData.date,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyuserdesignation: formData.desgination,
			topharmacy: formData.toPharmacy.value,
			topharmacyphone: formData.toPharmacyPhone,
			topharmacymanagername: formData.toPharmacyManager,
			topharmacyfaxnumber: formData.toPharmacyFax,
			provider: formData.provider
		}

		if (type === 'PDF') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_agreement_compound_form, requestedData));
		}

		if (type === 'FAX') {
			dispatch(forms.pharmacySendFax(path.pharmacy_agreement_compound_srfax_form, requestedData, showToast, setShowToast))
		}
	}
};

export const handlePharmacyCompoundControlledSubstancesOrderRequestForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	addNewCompounds,
	type,
) => {
	debugger;
	const specificValidations = {
		toPharmacy: 'Pharmacy is required',
		toPharmacyFax: 'Pharmacy Fax is required',
		toPharmacyPhone: 'Pharmacy Phone is required',
		pharmacyManager: 'Pharmacy Manager is required',
		patientName: 'Patient Name is required'
	};

	const allValidations = { ...specificValidations };

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const formattedData = addNewCompounds.map((obj, index) => {
		const compound = {};
		obj.selectComponents.forEach(item => {
			let key = item.name.replace(/([A-Z])/g, '_$1').toLowerCase();
			if (key === 'package_type') {
				key = 'type';
			}
			compound[key] = item.value;
		});

		debugger;

		const patientIndex = index % formData.patientName.length;
		const patientObj = formData.patientName[patientIndex];
		if (patientObj) {
			const patientKey = Object.keys(patientObj).find(key => key.startsWith('patient-'));
			if (patientKey) {
				compound['patient_name'] = patientObj[patientKey].value;
			}
		}

		return compound;
	});


	if (allDataPresent) {
		const requestedData = {
			date: formData.date,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyuserdesignation: formData.desgination,

			topharmacy: formData.toPharmacy.value,
			topharmacyphone: formData.toPharmacyPhone,
			topharmacyfaxnumber: formData.toPharmacyFax,
			compounddetails: formattedData,
			rational: formData.rational
		}

		if (type === 'PDF') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_controlled_request_form, requestedData));
		}
	}
};

export const handleAddNewCompound = (
	formData,
	setErrorMessages,
	errorMessages,
	compound_id,
	showModal,
	setShowModal,
	dispatch,
) => {

	const specificValidations = {
		quantity: 'Quantity Name is required.',
		price: 'Price is required',
		toPharmacy: 'The pharmacy field is required.',
		days: 'Days are required.'
	};

	const allValidations = { ...specificValidations };

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	if (allDataPresent) {
		const requestedData = {
			quantity: formData.quantity,
			price: formData.price,
			pharmacy: formData.toPharmacy.value,
			days: formData.days,
			compound_id: compound_id
		}
		dispatch(modalCompound.pharmacyAddData(path.pharmacy_add_compound_price_list,
			requestedData,
			showModal,
			setShowModal,
			'pharmacy-compound-price-list',
			`${path.pharmacy_price_compound_list}/${compound_id}`));
	};
}

export const handlePharmacyOrderCompoundForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	addNewCompounds,
	type,
) => {

	const specificValidations = {
		toPharmacy: 'Pharmacy is required',
		toPharmacyFax: 'Pharmacy Fax is required',
		toPharmacyPhone: 'Pharmacy Phone is required',
		pharmacyManager: 'Pharmacy Manager is required',
	};

	const allValidations = { ...specificValidations };

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const formattedData = addNewCompounds.map(obj => {
		const compound = {};
		obj.selectComponents.forEach(item => {
			let key = item.name
			compound[key] = item.value;
		});
		return compound;
	});

	if (allDataPresent) {
		const requestedData = {
			date: formData.date,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyuserdesignation: formData.desgination,

			topharmacy: formData.toPharmacy.value,
			topharmacyphone: formData.toPharmacyPhone,
			topharmacyfaxnumber: formData.toPharmacyFax,
			compounddetails: formattedData,
			rational: formData.rational
		}

		if (type === 'PDF') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_compound_order_form, requestedData));
		}
	}
};

export const handlePharmacyCompoundingOrderForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	addNewCompounds,
	type,
	showToast,
	setShowToast
) => {

	const specificValidations = {
		toPharmacy: 'Pharmacy is required',
		toPharmacyFax: 'Pharmacy Fax is required',
		toPharmacyPhone: 'Pharmacy Phone is required',
		pharmacyManager: 'Pharmacy Manager is required',
	};

	const allValidations = { ...specificValidations };

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const formattedData = addNewCompounds.map(obj => {
		const compound = {};
		obj.selectComponents.forEach(item => {
			let key = item.name
			compound[key] = item.value;
		});
		return compound;
	});

	if (allDataPresent) {
		const requestedData = {
			date: formData.date,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyuserdesignation: formData.desgination,

			topharmacy: formData.toPharmacy.value,
			topharmacyphone: formData.toPharmacyPhone,
			topharmacyfaxnumber: formData.toPharmacyFax,
			compounddetails: formattedData,
			rational: formData.rational
		}

		if (type === 'PDF') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_compound_form, requestedData));
		}

		if (type === 'FAX') {
			dispatch(forms.pharmacySendFax(path.pharmacy_compound_srfaxform, requestedData, showToast, setShowToast));
		}
	}
}
