import {
    INVENTORY_DATA_LIST_REQUEST,
    INVENTORY_DATA_LIST_SUCCESS,
    INVENTORY_DATA_LIST_FAILURE
} from "../../../constants"

const initialState = {
    data: null,
    loading: false,
    error: null,
    type: null,
};

const InventoryListReducer = (state = initialState, action) => {
    switch (action.type) {
        case INVENTORY_DATA_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                // data: null,
                error: null,
                type: action.fetch_type,
            };
        case INVENTORY_DATA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                type: action.fetch_type,
                error: null,
            };
        case INVENTORY_DATA_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                type: action.fetch_type,
                data: null,
            };
        default:
            return state;
    }
};

export default InventoryListReducer;
