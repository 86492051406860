import React from 'react'

export const DateRangePicker = ({ startDateRef, endDateRef }) => {

	return (
		<React.Fragment>
			<div className="relative">
				<input type="text" id="startDateRef" ref={startDateRef} style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
					className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
				<label htmlFor="startDateRef" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Start Date</label>
			</div>
			<div className="relative flex ">
				<span className="bg-gray-200 text-gray-500 p-3" style={{ marginLeft: '-16px' }}>To</span>
			</div>

			<div className="relative">
				<input type="text" id="endDateRef" ref={endDateRef} style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
					className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
				<label htmlFor="endDateRef" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">End Date</label>
			</div>
		</React.Fragment>
	)
}

export const ArchivedDelveriesDateRangePicker = ({ startDateRef, endDateRef, formData, setFormData, placeholder1 = 'Start Date', placeholder2 = 'End Date' }) => {

	return (
		<React.Fragment>
			<div className="relative flex-1">
				<input type="text" id="startDateRef" onChange={(e) => setFormData(e.target.value)} ref={startDateRef} value={formData.startDate} style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', height: '42px' }}
					className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
				<label htmlFor="startDateRef" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">{placeholder1}</label>
			</div>
			<div className="relative flex " style={{ height: '42px' }}>
				<span className="bg-gray-200 text-gray-500 p-3" style={{ marginLeft: '-16px' }}>To</span>
			</div>

			<div className="relative flex-1">
				<input type="text" id="endDateRef" ref={endDateRef} onChange={(e) => setFormData(e.target.value)} value={formData.endDate} style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', height: '42px' }}
					className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
				<label htmlFor="endDateRef" className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">{placeholder2}</label>
			</div>
		</React.Fragment>
	)
}
