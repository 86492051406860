import {
	PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_REQUEST,
	PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_SUCCESS,
	PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_FAILURE
} from "../../../constants"

import {
	showToastMessage,
	hideToastMessage
} from "../../../../assets/js/custome"

import { pharmacy_update_notification_srfax_form } from '../../../../networking/urlEndPoints'
import Axios from "../../../../networking/intraceptor"

export const faxFormRequest = () => ({
	type: PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_REQUEST,
})

export const faxFormSuccess = (data) => ({
	type: PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_SUCCESS,
	payload: data,
})

export const faxFormFailure = (error) => ({
	type: PHARMACY_UPDATE_CLINIC_NOTIFICATION_FAX_FORM_FAILURE,
	payload: error,
})

export const pharmacyUpdateNoficationSRFaxForm = (formData, showToast, setShowToast) => {
	return async (dispatch) => {
		dispatch(faxFormRequest())

		const requestedData = {
			date: formData.date,
			clinic: formData.clinicName.value,
			doctor: formData.doctorName.value,
			clinicfaxnumber: formData.clinicFaxNumber,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyfax: formData.pharmacyFax,
			pharmacyphone: formData.pharmacyPhone,
			pharmacyemail: formData.pharmacyEmail,
			pharmacyuserdesignation: formData.desgination
		}
		if (formData.fetchType === 'winrx') {
			requestedData.fetchtype = formData.fetchType
			requestedData.winrxpatientphn = formData.phn
			requestedData.winrxpatientname = formData.name
			requestedData.winrxpatientdob = formData.dob
		}

		if (formData.fetchType === 'local') {
			requestedData.fetchtype = formData.fetchType
			requestedData.patient = formData.patientName.value
			requestedData.patientphn = formData.phn
			requestedData.patientdob = formData.dob
		}

		try {
			const response = await Axios.post(`${pharmacy_update_notification_srfax_form}`, requestedData)
			if (response?.status === 200) {
				dispatch(faxFormSuccess(response?.data))

				showToastMessage(showToast, setShowToast)

				setTimeout(() => {
					hideToastMessage(showToast, setShowToast)
				}, 2000)

			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(faxFormFailure(error?.response?.data))
			}
		}
	}
}