import {
    PHARMACY_RX_NUMBER_ARCHIVED_LIST_REQUEST,
    PHARMACY_RX_NUMBER_ARCHIVED_LIST_SUCCESS,
    PHARMACY_RX_NUMBER_ARCHIVED_LIST_FAILURE,
} from '../../../constants';

const initialState = {
    data: null,
    loading: false,
    error: null,
    type: null
};

const searchByRxNumberArchivedListReducer = (state = initialState, action) => {
    switch (action.type) {
        case PHARMACY_RX_NUMBER_ARCHIVED_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                // data: null,
                error: null,
                type: action.types
            };
        case PHARMACY_RX_NUMBER_ARCHIVED_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                type: action.types,
                error: null,
            };
        case PHARMACY_RX_NUMBER_ARCHIVED_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null,
                type: action.types
            };
        default:
            return state;
    }
};

export default searchByRxNumberArchivedListReducer;
