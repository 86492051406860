export const onPlaceAddClinic = async (place, formData, setFormData) => {

	if (place && place.place_id) {
		const request = {
			placeId: place.place_id,
			fields: ['address_components', 'geometry', 'formatted_address', 'name', 'website', 'vicinity']
		}

		const service = await new window.google.maps.places.PlacesService(document.createElement('div'));
		service.getDetails(request, (placeResult, status) => {

			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
				const { lat, lng } = placeResult.geometry.location;
				const latitude = lat();
				const longitude = lng();

				const addressComponents = placeResult.address_components;
				let addClinicData = { ...formData }

				addClinicData.mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
				addClinicData.lat = latitude
				addClinicData.long = longitude

				addClinicData.clinicName = placeResult.name
				addClinicData.streetName = placeResult.vicinity

				for (let i = 0; i < addressComponents.length; i++) {
					const component = addressComponents[i]
					if (component.types.includes('locality')) {
						addClinicData.city = component.long_name;
					} else if (component.types.includes('administrative_area_level_1')) {
						addClinicData.province = component.short_name;
					} else if (component.types.includes('postal_code')) {
						addClinicData.zip = component.long_name;
					} else if (component.types.includes('country')) {
						addClinicData.country = component.long_name;
					} else if (component.types.includes('route')) {
						addClinicData.street = component.long_name;
					}
				}
				setFormData({ ...addClinicData });
			}
		});
	}
};

