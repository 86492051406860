import { useSelector } from "react-redux";

export const useFDASelectors = () => {
  const fdaListResponse = useSelector(
    (state) => state.fdaList
  );


  return {
    fdaListResponse,
  };
};
