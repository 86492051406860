import React, { useEffect } from "react";

import {
	Route,
	Routes,
	useLocation
} from "react-router-dom";

import { pharmacyMembershipAccessList } from "../auth/permissions";
import { Header } from "../components/common";
import { NotFound } from "../components/error/notFound";
import { useCustomSelectors } from "../services/selectors/allSelectors";

import { authToken } from "../storage/authToken";
import { removeWorkSpaceTab } from "../utils/helper/helper";

import SignIn from "../auth/signIn";
import ResponsiveChatView from "../components/chat/responsiveChatView";
import WorkspaceTabs from "../components/pharmacy/workSpace/workspaceTabs";
import ProtectedRoute from "./protectedRoutes";

import * as pages from "../screens";
import * as adminpage from "../screens/admin";

import * as routeNames from "./routeName";

const AppRoutes = () => {

	const token = authToken();
	const location = useLocation()

	const { pharmacyLoginUser, profileData, pharmacyLoginData, adminModule } = useCustomSelectors()
	const isPermissionAccess = pharmacyMembershipAccessList()

	const isKnownRoute = Object.values(routeNames).includes(location.pathname);

	useEffect(() => {
		if (location.pathname === routeNames.HOME_ROUTE) {
			removeWorkSpaceTab()
		}
	}, [])

	return (
		<>
			{location.pathname === routeNames.pharmacy_pharmacist_signature_updation ? null :
				(location.pathname === routeNames.HOME_ROUTE && token) ?
					<div className="sticky top-0 z-30">
						<Header />
						<WorkspaceTabs />
					</div>
					:
					token && isKnownRoute && <div className="sticky top-0 z-30">
						{!routeNames.HOME_ROUTE.includes(location.pathname) && <Header />}
						{!routeNames.HOME_ROUTE.includes(location.pathname) && <WorkspaceTabs />}
					</div>}

			<Routes>
				{!token && <Route exact path={routeNames.HOME_ROUTE} element={<SignIn />} />}

				<Route exact path={routeNames.DASHBOARD_ROUTE} element={<ProtectedRoute Component={pages.Dashboard}></ProtectedRoute>} />
				<Route exact path={routeNames.TIMESHEET_ROUTE} element={<ProtectedRoute Component={pages.TimeSchedule}></ProtectedRoute>} />
				<Route exact path={routeNames.APPRECIATIONS_ROUTE} element={<ProtectedRoute Component={pages.Appreciation}></ProtectedRoute>} />
				<Route exact path={routeNames.NEWREQUEST_ROUTE} element={<ProtectedRoute Component={pages.NewRequest}></ProtectedRoute>} />

				{adminModule !== 1 && <Route exact path={routeNames.ALLREQUEST_ROUTE} element={<ProtectedRoute Component={pages.AllRequests}></ProtectedRoute>} />}

				<Route exact path={routeNames.SETTINGS_ROUTE} element={<ProtectedRoute Component={pages.Settings}></ProtectedRoute>} />
				<Route exact path={routeNames.ALLEMPLOYEES_ROUTE} element={<ProtectedRoute Component={pages.AllEmployees}></ProtectedRoute>} />
				<Route exact path={`${routeNames.EDIT_TIME_OFFR_REQUEST_ROUTE}/:id`} element={<ProtectedRoute Component={pages.EditRequest}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_break_parroll_settings}`} element={<ProtectedRoute Component={pages.BreakPayrollSetting}></ProtectedRoute>} />

				<Route exact path={`${routeNames.CHAT_NOW}/:user_id`} element={<ProtectedRoute Component={ResponsiveChatView}></ProtectedRoute>} />
				<Route exact path={`${routeNames.MY_PROFILE}`} element={<ProtectedRoute Component={pages.MyProfile}></ProtectedRoute>} />

				{isPermissionAccess?.pharmacy_patient === pharmacyLoginUser?.pharmacy_patient && <>
					<Route exact path={routeNames.ADD_PATIENTS} element={<ProtectedRoute Component={pages.AddPatients}></ProtectedRoute>} />
					<Route exact path={`${routeNames.ALL_PATIENTS}`} element={<ProtectedRoute Component={pages.AllPatientsList}></ProtectedRoute>} />
					{/* <Route exact path={`${routeNames.PHARMACY_PRINT_OAT_LOG}/:id`} element={<ProtectedRoute Component={PharmacyPrintOatLog}></ProtectedRoute>} /> */}
				</>}

				{isPermissionAccess?.pharmacy_forms === pharmacyLoginUser?.pharmacy_forms && <>
					<Route exact path={`${routeNames.PHARMACY_INFORMATION_UPDATE_FORM}`} element={<ProtectedRoute Component={pages.PharmacyInformationUpdateForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.PHARMACY_PRESCRIPTION_TRASNSFER_FORM}`} element={<ProtectedRoute Component={pages.RxTransferForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_Emergency_Supply_OAT_medications_form}`} element={<ProtectedRoute Component={pages.DrNotificationForEmergencySupply}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_medication_form}`} element={<ProtectedRoute Component={pages.AntiViralRequestForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.missing_Rx_Requirements}`} element={<ProtectedRoute Component={pages.MixingRxRequirementsForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_emergency_supply_continuity_form}`} element={<ProtectedRoute Component={pages.EmergencySupplyForContinuityOfCareForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.patient_agreement_for_take_home_dosing}`} element={<ProtectedRoute Component={pages.PatientAgreementForTakeHomeDosing}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_Plan_G_Form}`} element={<ProtectedRoute Component={pages.PlanGForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_Plan_Z_Form}`} element={<ProtectedRoute Component={pages.PlanZForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_doctor_communication_form}`} element={<ProtectedRoute Component={pages.DoctorCommunicationForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_Pharmacist_Prescriber_Communication}`} element={<ProtectedRoute Component={pages.PharmacistPrescriberCommunication}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_original_prescription_form}`} element={<ProtectedRoute Component={pages.RxCopyOfOriginalForOatDD}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_doctor_note_form}`} element={<ProtectedRoute Component={pages.AddDoctorNote}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_tb_skin_test_mantoux_form}`} element={<ProtectedRoute Component={pages.TBSkinMantouxForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_tb_skin_test_logs}`} element={<ProtectedRoute Component={pages.TbSkinTestLogs}></ProtectedRoute>} />
				</>}

				<Route exact path={`${routeNames.pharmacy_search_module}`} element={<ProtectedRoute Component={pages.GeneralInformation}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_all_notifications}`} element={<ProtectedRoute Component={pages.AllNotifications}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_front_profile}`} element={<ProtectedRoute Component={pages.PharmacyFrontProfile}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_file_manager}`} element={<ProtectedRoute Component={pages.CreateFileManager}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_fax_log}`} element={<ProtectedRoute Component={pages.SentFaxLogs}></ProtectedRoute>} />

				{isPermissionAccess?.pharmacy_compounds === pharmacyLoginUser?.pharmacy_compounds && <>
					{isPermissionAccess?.pharmacy_compound_price_list === pharmacyLoginUser?.pharmacy_compound_price_list && <>
						<Route exact path={`${routeNames.pharmacy_compounds}`} element={<ProtectedRoute Component={pages.CompoundPriceList}></ProtectedRoute>} />
						<Route exact path={`${routeNames.pharmacy_compound_price_list}/:id`} element={<ProtectedRoute Component={pages.EditCompoundPriceList}></ProtectedRoute>} />
					</>
					}

					<Route exact path={`${routeNames.pharmacy_compound_agreement_form}`} element={<ProtectedRoute Component={pages.CompoundAggrementForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_controlled_order_request}`} element={<ProtectedRoute Component={pages.ControlledSubstancesOrderRequestForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_order_compound_form}`} element={<ProtectedRoute Component={pages.CompoundOrderForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_order_compounding_form}`} element={<ProtectedRoute Component={pages.OrderCompoundingForm}></ProtectedRoute>} />
				</>}

				{isPermissionAccess?.pharmacy_daily_dispense === pharmacyLoginUser?.pharmacy_daily_dispense && <>
					<Route exact path={`${routeNames.pharmacy_archived_deliveries}`} element={<ProtectedRoute Component={pages.ArchivedDispense}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_delivery_lables}`} element={<ProtectedRoute Component={pages.DeliveryLabels}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_expiring_rx}`} element={<ProtectedRoute Component={pages.ExpiringRx}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_patient_counselling}`} element={<ProtectedRoute Component={pages.Counselling}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_consent_form_logs}`} element={<ProtectedRoute Component={pages.ConsentSubmissions}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_memo}`} element={<ProtectedRoute Component={pages.PharmacyMemo}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_drugs_billing}`} element={<ProtectedRoute Component={pages.BillingList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_oat_prescriptions}`} element={<ProtectedRoute Component={pages.PrescriptionsList}></ProtectedRoute>} />
					{/* <Route exact path={`${routeNames.upload_prescription}`} element={<ProtectedRoute Component={pages.uploadePrescription}></ProtectedRoute>} /> */}
					<Route exact path={`${routeNames.pharmacy_ongoing_prescritiptions}`} element={<ProtectedRoute Component={pages.OnGoingPrescriptions}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_returned_patches}`} element={<ProtectedRoute Component={pages.ReturnedFentanylPatches}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_reversed_deliveries}`} element={<ProtectedRoute Component={pages.PharmacyReversedDelveries}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_tranfer_patient_concent}`} element={<ProtectedRoute Component={pages.TransferConsentsForms}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_sig_check_ai}`} element={<ProtectedRoute Component={pages.SignCheckAI}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_accountability}`} element={<ProtectedRoute Component={pages.Accountability}></ProtectedRoute>} />
				</>}

				{isPermissionAccess?.pharmacy_blister === pharmacyLoginUser?.pharmacy_blister && <>
					<Route exact path={`${routeNames.pharmacy_patient_blister_pack}`} element={<ProtectedRoute Component={pages.AddBlisterPack}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_blister_patients}`} element={<ProtectedRoute Component={pages.BlisterPatients}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_blister_packs}`} element={<ProtectedRoute Component={pages.BlistersPackList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_blister_daily_packs}`} element={<ProtectedRoute Component={pages.DailyPacksList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_blister_pack_on_shelf}`} element={<ProtectedRoute Component={pages.BlisterPacksOnShelf}></ProtectedRoute>} />
				</>}

				{isPermissionAccess?.pharmacy_patient_vials_pack === pharmacyLoginUser?.pharmacy_patient_vials_pack && <>
					<Route exact path={`${routeNames.pharmacy_patient_vials_pack}`} element={<ProtectedRoute Component={pages.VialsPatients}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_add_vials_patient}`} element={<ProtectedRoute Component={pages.AddVialsPatient}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_vials_packs}`} element={<ProtectedRoute Component={pages.VialsPackList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_dailyvials_packs}`} element={<ProtectedRoute Component={pages.DailyVialsList}></ProtectedRoute>} />
				</>}


				{isPermissionAccess?.pharmacy_speciality_medication === pharmacyLoginUser?.pharmacy_speciality_medication && <>
					<Route exact path={`${routeNames.pharmacy_add_medication_patients}`} element={<ProtectedRoute Component={pages.AddMedicationPatients}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_speciality_pack}`} element={<ProtectedRoute Component={pages.MedicationPatients}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_speciality_medication_packs}`} element={<ProtectedRoute Component={pages.SpecialtyMedicationPackList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_dailymedication_packs}`} element={<ProtectedRoute Component={pages.DailySpecialityMedicationList}></ProtectedRoute>} />
				</>}

				{isPermissionAccess?.pharmacy_arv === pharmacyLoginUser?.pharmacy_arv && <>
					<Route exact path={`${routeNames.ADD_ARV}`} element={<ProtectedRoute Component={pages.addARV}></ProtectedRoute>} />
					<Route exact path={`${routeNames.ARV_LIST}`} element={<ProtectedRoute Component={pages.ArvList}></ProtectedRoute>} />
				</>}

				{isPermissionAccess?.pharmacy_revinue_management === pharmacyLoginUser?.pharmacy_revinue_management && <>
					<Route exact path={`${routeNames.pharmacy_daily_revenue}`} element={<ProtectedRoute Component={pages.DailyRevenue}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_cash_change}`} element={<ProtectedRoute Component={pages.CashChange}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_deposit_info}`} element={<ProtectedRoute Component={pages.DepositInformation}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_hand_cash}`} element={<ProtectedRoute Component={pages.InHandCashInfo}></ProtectedRoute>} />
				</>}

				{isPermissionAccess?.pharmacy_fda === pharmacyLoginUser?.pharmacy_fda && <>
					<Route exact path={`${routeNames.create_new_fda_form}`} element={<ProtectedRoute Component={pages.CreateNewFDAForm}></ProtectedRoute>} />
					<Route exact path={`${routeNames.all_generated_fda_form_list}`} element={<ProtectedRoute Component={pages.ALLGeneratedFDAformList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_expiring_frequent_dispense}`} element={<ProtectedRoute Component={pages.ExpiringFDAList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_expired_frequent_dispense}`} element={<ProtectedRoute Component={pages.ExpiredFDAList}></ProtectedRoute>} />

				</>}

				{isPermissionAccess?.pharmacy_oat === pharmacyLoginUser?.pharmacy_oat && <>
					<Route exact path={`${routeNames.pharmacy_patient_route}`} element={<ProtectedRoute Component={pages.PatientRoute}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_add_patient_route}`} element={<ProtectedRoute Component={pages.AddOatPatientPack}></ProtectedRoute>} />
				</>}

				<Route exact path={`${routeNames.pharmacy_pharmacist_signature_updation}`} element={<ProtectedRoute Component={pages.AddNewPharmacistSignatures}></ProtectedRoute>} />


				<Route exact path={`${routeNames.pharmacy_generate_invoice}`} element={<ProtectedRoute Component={pages.GenerateInvoice}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_previous_invoices}`} element={<ProtectedRoute Component={pages.PreviousInvoicesList}></ProtectedRoute>} />

				<Route exact path={`${routeNames.pharmacy_inventory_list}`} element={<ProtectedRoute Component={pages.InventoryList}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_total_reversed}`} element={<ProtectedRoute Component={pages.TotalReversedList}></ProtectedRoute>} />

				<Route exact path={`${routeNames.pharmacy_reminder_notes}`} element={<ProtectedRoute Component={pages.ReminderNotifications}></ProtectedRoute>} />

				<Route exact path={`${routeNames.pharmacy_add_travel_patient}`} element={<ProtectedRoute Component={pages.AddTravelPatients}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_add_travel_pack}`} element={<ProtectedRoute Component={pages.TravelPatients}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_travel_logs}`} element={<ProtectedRoute Component={pages.TravelLogs}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_billing_due}`} element={<ProtectedRoute Component={pages.BillingDueList}></ProtectedRoute>} />
				<Route exact path={`${routeNames.pharmacy_travel_returning_patients}`} element={<ProtectedRoute Component={pages.ReturningPatientsList}></ProtectedRoute>} />

				{adminModule !== 1 && <>
					<Route exact path={`${routeNames.pharmacy_tasks_list}`} element={<ProtectedRoute Component={pages.PharmacyEmployeesTaskList}></ProtectedRoute>} />
				</>}

				{pharmacyLoginData?.app_mode === 1 && <>
					<Route exact path={`${routeNames.pharmacy_order_transfer}`} element={<ProtectedRoute Component={pages.TransferPrescriptions}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_order_new}`} element={<ProtectedRoute Component={pages.NewPrescriptionList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_view_order_details}/:id`} element={<ProtectedRoute Component={pages.ViewOrderDetails}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_app_user_list}`} element={<ProtectedRoute Component={pages.AppUser}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_app_announcements}`} element={<ProtectedRoute Component={pages.AppAnnouncements}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_app_notification_list}`} element={<ProtectedRoute Component={pages.AppCustomNotification}></ProtectedRoute>} />
				</>}


				{/* --------------------------------------------Admin Routes Start ----------------------------------- */}

				{adminModule === 1 && <>
					<Route exact path={`${routeNames.pharmacy_timeoff}`} element={<ProtectedRoute Component={adminpage.TimeOffRequest}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_check_out_in}`} element={<ProtectedRoute Component={adminpage.CheckInOutLogs}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_create_certificate}`} element={<ProtectedRoute Component={adminpage.Certificate}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_edit_certificate}`} element={<ProtectedRoute Component={adminpage.Certificate}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_create_new_task}`} element={<ProtectedRoute Component={adminpage.CreateTaskManger}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_tasks}`} element={<ProtectedRoute Component={adminpage.AllTasksList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_team_attendance}`} element={<ProtectedRoute Component={adminpage.TeamTimeSheet}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_certificate_template}`} element={<ProtectedRoute Component={adminpage.ViewCertificateTemplates}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_assign_certificate}`} element={<ProtectedRoute Component={adminpage.EmployeCertificateTemplates}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_assign_certificate_employee}`} element={<ProtectedRoute Component={adminpage.AssignCertificateTemplate}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_user}`} element={<ProtectedRoute Component={adminpage.ManageTeamList}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_department}`} element={<ProtectedRoute Component={adminpage.AssignDepartments}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_settings}`} element={<ProtectedRoute Component={adminpage.ManagePharmacySettings}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_team_avalibility}`} element={<ProtectedRoute Component={adminpage.TeamSchedule}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_driver}`} element={<ProtectedRoute Component={pages.DeliveryDeriverManagement}></ProtectedRoute>} />
					<Route exact path={`${routeNames.pharmacy_holidays_calander}`} element={<ProtectedRoute Component={pages.PharmacyStatHolidays}></ProtectedRoute>} />
				</>}

				{/* --------------------------------------------Admin Routes EnD----------------------------------- */}

				{profileData?.user_id && token && location.pathname === routeNames.HOME_ROUTE ?
					<Route exact path="*" element={<ProtectedRoute Component={pages.Dashboard}></ProtectedRoute>} />
					: profileData?.user_id && token ?
						<Route path="*" element={<NotFound />} /> :
						<Route path="*" element={<SignIn />} />}

			</Routes>
		</>
	)
}

export default AppRoutes

