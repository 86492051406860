
import React, { useEffect, useState } from 'react';

import { handleShowModal } from "../../../assets/js/custome";
import { AddEmployeeShift, AddPublishModal, AddShiftModal, EditShiftModal } from '../../../models/admin/teamSchedule';
import { useAdminSelectors } from '../../../services/selectors/adminModuleSelectors';

import { ErrorToastMessage, SuccessToastMessage } from '../../../components/loaders/toastMessage';
import { useCustomDispatch } from '../../../hooks';
import { fetchPharmacyTeamScheduleList } from '../../../services/actions/admin/team/fetchTeamsList';

import { DeleteIcon, EditIcons } from '../../../assets/svg/icons';
import { LoadingView } from '../../../components/common';
import { ErrorMessages } from '../../../components/error/alertMessages';

import { DeleteDepartment } from '../../../models/admin/editTaskList';
import ThemeAvatar from '../../../components/common/avatar';

const TeamSchedule = () => {

    const [showModal, setShowModal] = useState({ addShift: false, addemployeeShift: false })
    const [showToast, setShowToast] = useState({ successToast: '', errorToast: '', editShift: false, deleteDept: false, addPublish: false })

    const [logData, setLogData] = useState(null)
    const [employeeDetail, setEmployeeDetail] = useState(null)
    const [hoveredShift, setHoveredShift] = useState(null);
    const [selectedDays, setSelectedDays] = useState(null)

    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors()
    const dispatch = useCustomDispatch()

    const teamScheduleList = TeamListResponse?.data

    const [startDate, setStartDate] = useState(getStartOfWeek(new Date()));
    const [startFormatted, setStartFormatted] = useState('');
    const [endFormatted, setEndFormatted] = useState('');

    function getStartOfWeek(date) {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    }

    function getEndOfWeek(start) {
        const end = new Date(start);
        end.setDate(start.getDate() + 6);
        return end;
    }

    function formatDate(date) {
        const options = { month: 'short', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }

    function getFormattedDate(date) {
        return date.toISOString().split('T')[0];
    }

    const updateWeekRange = (start) => {
        const end = getEndOfWeek(start);
        setStartFormatted(getFormattedDate(start));
        setEndFormatted(getFormattedDate(end));
    };

    const handleNextWeek = () => {
        const nextWeek = new Date(startDate);
        nextWeek.setDate(startDate.getDate() + 7);
        setStartDate(nextWeek);
    };

    const handlePrevWeek = () => {
        const prevWeek = new Date(startDate);
        prevWeek.setDate(startDate.getDate() - 7);
        setStartDate(prevWeek);
    };

    useEffect(() => {
        updateWeekRange(startDate);
    }, [startDate]);

    useEffect(() => {
        if (startFormatted && endFormatted) {
            const params = {
                start_date: startFormatted,
                end_date: endFormatted,
            }
            dispatch(fetchPharmacyTeamScheduleList('team_schedule_list', params))
        }
    }, [startFormatted, endFormatted])

    return (<>
        <div className="container mx-auto p-4">
            <div className="bg-white rounded-lg shadow-md p-6">

                <button className="bg-blue-500 text-white px-4 py-1 rounded-md" onClick={() => window.history.back()}>Back</button>

                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-bold">Team Schedule</h1>
                    <div className="flex items-center">
                        <div className='' style={{ background: 'lavender', marginRight: '10px', padding: '4px', borderRadius: '6px' }}>
                            <button className="mr-4 rubik-500 fs-16 px-3 py-0.5  rounded-md" onClick={handlePrevWeek}>&lt;</button>
                            <span className="mr-4 rubik-500 text-gray-700 fs-14">{`${formatDate(startDate)} - ${formatDate(getEndOfWeek(startDate))}`}</span>
                            <button className="mr-2 px-3 py-0.5 rubik-500 fs-16 rounded-md" onClick={handleNextWeek}>&gt;</button>
                        </div>
                        <button className="text-white px-4 py-2 fs-12 rubik-500 rounded-md" style={{ background: '#de3d5b' }} onClick={() => handleShowModal(setShowModal, showModal, 'addShift')}>Add Shift</button>
                    </div>
                </div>

                {TeamListResponse?.loading && TeamListResponse.type === 'team_schedule_list' ?

                    <div className="flex items-center justify-center mt-60 mb-80">
                        <LoadingView />
                    </div>

                    : TeamListResponse?.error !== null && TeamListResponse.type === 'team_schedule_list' ?
                        <div className="flex items-center justify-center mb-80">
                            <ErrorMessages />
                        </div>
                        : teamScheduleList?.users_data?.length === 0 && TeamListResponse.type === 'team_schedule_list' ?
                            <h2 className="text-gray-500">
                                No Record Available.
                            </h2> :

                            TeamListResponse?.type === 'team_schedule_list' ?
                                <>
                                    <table className="w-full border-collapse">
                                        <thead>
                                            <tr>
                                                <th className="border p-2">Employees</th>
                                                {teamScheduleList?.dates_between?.map(day => (
                                                    <th key={day} className="border p-2">{day}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {teamScheduleList?.users_data?.map(value => (
                                                <tr key={value.name}>
                                                    <td className="border p-2 w-32">
                                                        <div className="flex items-center">
                                                            <span className="rounded-full  bg-blue-200 flex items-center justify-center mr-2">
                                                                <ThemeAvatar name={value?.name?.charAt(0).toUpperCase() + value.name.slice(1)} size="30" fontSize={'12px'} image={value?.image} />
                                                            </span>
                                                            <span>{value.name}</span>
                                                        </div>
                                                    </td>

                                                    {value?.days?.map((val, dayIndex) => (
                                                        <td key={`${dayIndex}`} className="border p-2 relative group w-32">
                                                            {val?.shifts?.length > 0 ? (
                                                                val.shifts.map((shift, index) => (
                                                                    <React.Fragment key={index} >

                                                                        <div style={{ height: '36px', width: '156.3px', marginTop: '4px' }}
                                                                            // key={index}
                                                                            className=""
                                                                            onMouseEnter={() => setHoveredShift(`${value.name}-${dayIndex}-${index}`)}
                                                                            onMouseLeave={() => setHoveredShift(null)}
                                                                        >
                                                                            {hoveredShift === `${value.name}-${dayIndex}-${index}` ? (
                                                                                <div className="text-sm bg-red-500 text-white text-center mb-1 p-2 flex items-center justify-center rounded-md gap-4" style={{ height: '36px' }}>
                                                                                    <span onClick={() => { return setLogData(shift), setEmployeeDetail(value), handleShowModal(setShowModal, showModal, 'editShift') }}>
                                                                                        <EditIcons color='white' />
                                                                                    </span>

                                                                                    <span className='-mt-1 btnnn' onClick={() => { return setLogData(shift), setEmployeeDetail(value), handleShowModal(setShowModal, showModal, 'deleteDept') }}>
                                                                                        <DeleteIcon color='white' />
                                                                                    </span>

                                                                                    <span onClick={() => { return setLogData(shift), setSelectedDays(val), setEmployeeDetail(value), handleShowModal(setShowModal, showModal, 'addemployeeShift') }} className='fs-14 -mt-2 btnnn' style={{ fontSize: '24px' }}>
                                                                                        +
                                                                                    </span>

                                                                                </div>
                                                                            ) : (
                                                                                <div className={`text-sm text-center ${shift.publish === 1 ? 'bg-blue-500 text-white' : 'bg-gray-100'} p-2 rounded-md`}>
                                                                                    {shift.start_time}-{shift.end_time}
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                        {val?.timeoff_request &&
                                                                            <div className="text-sm text-gray-700 p-2 rounded-md mt-2" style={{ background: 'rgb(252 211 77)', width: '156.3px' }}>
                                                                                {val?.timeoff_request?.whole_day === 0 ? <p className='fs-10'> {val?.timeoff_request?.start_time} {val?.timeoff_request?.end_time}</p> : <p className='fs-10'> Whole Day</p>}
                                                                                {val?.timeoff_request?.status && <p className='fs-12 -mt-2'> {val?.timeoff_request?.status} Time Off </p>}
                                                                            </div>}

                                                                    </React.Fragment>
                                                                ))
                                                            ) : (

                                                                <>

                                                                    {
                                                                        val?.timeoff_request ?
                                                                            <div className="text-sm text-gray-700 p-2 rounded-md" style={{ background: 'rgb(252 211 77)', width: '156.3px' }}>
                                                                                {val?.timeoff_request?.whole_day === 0 ? <p className='fs-10'> {val?.timeoff_request?.start_time} {val?.timeoff_request?.end_time}</p> : <p className='fs-10'> Whole Day</p>}
                                                                                {val?.timeoff_request?.status && <p className='fs-12 -mt-2'> {val?.timeoff_request?.status} Time Off </p>}
                                                                            </div>
                                                                            :
                                                                            <div style={{ height: '40px' }}
                                                                                className="p-0.5"
                                                                                onMouseEnter={() => setHoveredShift(`no-shift-${value.name}-${dayIndex}`)}
                                                                                onMouseLeave={() => setHoveredShift(null)}
                                                                            >
                                                                                {hoveredShift === `no-shift-${value.name}-${dayIndex}` && (
                                                                                    <div onClick={() => { return setEmployeeDetail(value), setSelectedDays(val), handleShowModal(setShowModal, showModal, 'addemployeeShift') }} className="btnnn text-sm bg-blue-500 text-white text-center mb-1 p-2 rounded-md">
                                                                                        + ADD
                                                                                    </div>
                                                                                )}
                                                                            </div>}
                                                                </>
                                                            )}


                                                        </td>
                                                    ))}

                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                    <div className="mt-4 flex justify-between items-center">
                                        <button className="text-white rubik-500 fs-12 px-4 py-2 rounded-md"
                                            style={{ background: '#543535' }}
                                            onClick={teamScheduleList?.publish_count !== 0 ? () => handleShowModal(setShowModal, showModal, 'addPublish') : undefined}>
                                            Publish {teamScheduleList?.publish_count}
                                        </button>
                                    </div>
                                </> : null
                }

            </div>
        </div>

        {showModal.addShift &&
            <AddShiftModal setShowModal={setShowModal}
                showModal={showModal}
                setShowToast={setShowToast}
                startDate={startFormatted}
                endDate={endFormatted}
            />
        }

        {showModal.addPublish && <AddPublishModal setShowModal={setShowModal}
            showModal={showModal}
            setShowToast={setShowToast}
            date={`${formatDate(startDate)} - ${formatDate(getEndOfWeek(startDate))}`}
            startDate={startFormatted}
            endDate={endFormatted}
        />}

        {showModal.editShift && <EditShiftModal logData={logData}
            employeeDetail={employeeDetail}
            setShowModal={setShowModal}
            showModal={showModal}
            setShowToast={setShowToast}
            startDate={startFormatted}
            endDate={endFormatted}
        />}

        {showModal.addemployeeShift && <AddEmployeeShift logData={logData}
            employeeDetail={employeeDetail}
            setShowModal={setShowModal}
            showModal={showModal}
            setShowToast={setShowToast}
            startDate={startFormatted}
            endDate={endFormatted}
            selectedDays={selectedDays}
        />}

        {showModal.deleteDept && <DeleteDepartment
            logData={logData}
            employeeDetail={employeeDetail}
            setShowModal={setShowModal}
            showModal={showModal}
            setShowToast={setShowToast}
            startDate={startFormatted}
            endDate={endFormatted}
            text={'Are you sure? You are about to remove this time slot.'}
        />}

        {addTeamDataResponse.type === 'add_shift' && showToast.successToast && <SuccessToastMessage status={'Shift Added Successfully'} />}
        {addTeamDataResponse.type === 'add_shift' && showToast.errorToast && <ErrorToastMessage status={'Please try to add again.'} />}

        {addTeamDataResponse.type === 'edit_shift' && showToast.successToast && <SuccessToastMessage status={'Updated Successfully'} />}
        {addTeamDataResponse.type === 'edit_shift' && showToast.errorToast && <ErrorToastMessage status={'Please try to update again.'} />}

        {addTeamDataResponse.type === 'delete_shift' && showToast.successToast && <SuccessToastMessage status={'Shift Deleted Successfully.'} />}

        {addTeamDataResponse.type === 'add_publish_shift' && showToast.successToast && <SuccessToastMessage status={'Updated Successfully'} />}
        {addTeamDataResponse.type === 'add_publish_shift' && showToast.errorToast && <ErrorToastMessage status={'Please try to update again.'} />}

    </>
    );
};

export default TeamSchedule;