import {
	LIST_ARV_DELETE_REQUEST,
	LIST_ARV_DELETE_SUCCESS,
	LIST_ARV_DELETE_FAILURE
} from '../../../constants';

const initialState = {
	data: null,
	loading: false,
	error: null,
	type: null,
};

const deleteARVReducer = (state = initialState, action) => {
	switch (action.type) {
		case LIST_ARV_DELETE_REQUEST:
			return {
				...state,
				loading: true,
				data: null,
				error: null,
				type: null,
			};
		case LIST_ARV_DELETE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				type: action.types,
				error: null,
			};
		case LIST_ARV_DELETE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				type: action.types,
				data: null,
			};
		default:
			return state;
	}
};

export default deleteARVReducer;
