import {
	SYNC_TODAYS_DELIVERIES_REQUEST,
	SYNC_TODAYS_DELIVERIES_SUCCESS,
	SYNC_TODAYS_DELIVERIES_FAILURE,
} from '../../../constants';

const initialState = {
	data: null,
	loading: false,
	error: null,
	type: null
};

const syncTodaysDelveriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case SYNC_TODAYS_DELIVERIES_REQUEST:
			return {
				...state,
				loading: true,
				data: null,
				error: null,
				type: action.types
			};
		case SYNC_TODAYS_DELIVERIES_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				type: action.types,
				error: null,
			};
		case SYNC_TODAYS_DELIVERIES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null,
				type: action.types
			};
		default:
			return state;
	}
};

export default syncTodaysDelveriesReducer;
