import Swal from 'sweetalert2';
import Axios from '../../networking/intraceptor';

export const PharmacySendFaxLog = (reqData, url) => {
    const swalWithTailwindButtons = Swal.mixin({
        customClass: {
            cancelButton: 'bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded',
            confirmButton: 'm-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded',
        },
        buttonsStyling: false
    });

    swalWithTailwindButtons.fire({
        title: 'Are you sure?',
        text: `This will send a fax. Do you want to proceed?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true,
        preConfirm: async () => {
            const confirmButton = Swal.getConfirmButton();
            confirmButton.textContent = 'Processing...';
            confirmButton.disabled = true;

            try {
                const response = await Axios.post(url, reqData);
                if (response?.status === 200) {
                    swalWithTailwindButtons.fire({
                        title: 'Success',
                        text: 'Fax Sent Successfully',
                        icon: 'success'
                    });
                } else {
                    throw new Error(response);
                }
            } catch (error) {
                swalWithTailwindButtons.fire({
                    title: 'Error',
                    text: `Oops! Got an issue while sending fax. Please try again later.`,
                    icon: 'error'
                });
            }
        }
    }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithTailwindButtons.fire({
                title: 'Process has been cancelled',
                icon: 'error'
            });
        }
    });
}