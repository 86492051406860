import {
	PREVIEW_PDF_REQUEST,
	PREVIEW_PDF_SUCCESS,
	PREVIEW_PDF_FAILURE
} from "../../../constants"

const initialState = {
	loading: false,
	data: null,
	error: null,
}

const pharmacyPreviewFormPdfReducer = (state = initialState, action) => {
	switch (action.type) {
		case PREVIEW_PDF_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				data: null,
			};
		case PREVIEW_PDF_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			}
		case PREVIEW_PDF_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null
			}
		default:
			return state;
	}
}
export default pharmacyPreviewFormPdfReducer