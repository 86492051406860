import {
    LIST_FDA_DATA_REQUEST,
    LIST_FDA_DATA_SUCCESS,
    LIST_FDA_DATA_FAILURE
} from "../../constants"

import { frequent_dispensing_autorization_list } from "../../../networking/urlEndPoints"

import Axios from "../../../networking/intraceptor"

export const FDARequest = (types) => ({
    type: LIST_FDA_DATA_REQUEST,
    types: types
})

export const FDASuccess = (data, types) => ({
    type: LIST_FDA_DATA_SUCCESS,
    payload: data,
    types: types
})

export const FDAFailure = (error, types) => ({
    type: LIST_FDA_DATA_FAILURE,
    payload: error,
    types: types
})

export const fetchFrequestDispensingAuthList = (type, params, path) => {

    return async (dispatch) => {

        dispatch(FDARequest(type))
        try {
            const response = await Axios.get(`${frequent_dispensing_autorization_list}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(FDASuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(FDAFailure(error?.response, type))
            }
        }
    }
}


export const fetchFDAExpired_ExpiringList = (type, params, path) => {

    return async (dispatch) => {

        dispatch(FDARequest(type))
        try {
            const response = await Axios.get(`${path}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(FDASuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(FDAFailure(error?.response, type))
            }
        }
    }
}


