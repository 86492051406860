import React, { useState, useCallback, useEffect } from 'react'

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'

import {
    AddInventoryData,
    FetchDrugListByDIN,
    UpdateInventory,
    addInventoryFailure
} from '../../services/actions/pharmacy/Inventory/addInventoryData';

import { useInventorySelector } from '../../services/selectors/inventorySelectors';
import { currentDate } from '../../utils/constants/formInitialStates';

import { Date, PreviousDate, PreviousDatePicker } from '../../components/pharmacy/form/form';
import { Errors } from '../../components/error/alertMessages';
import { debounce } from 'lodash';

import * as Hooks from '../../hooks'

const recurrenceRuleType = [
    { label: "Current Sheet", value: 'current_sheet' },
    { label: "Previous Sheet", value: 'previous_sheet' },
];

const AddInventoryModal = ({ setShowModal, showModal, setShowToast, logData, isEdit, data, pageNumber }) => {

    const dispatch = Hooks.useCustomDispatch()

    const { addInventoryResponse } = useInventorySelector()

    const [errorMessage, setErrorMessage] = useState({ din: '' })

    const [formData, setFormData] = useState({
        recurrence_day_type: recurrenceRuleType[0].value,
        din: '',
        drugName: '',
        blackSa: '',
        white1A: '',
        white1B: '',
        White_2A_2B: '',
        bts: '',
        rts: '',
        onShelf: '',
        computer: '',
        difference: '',
        date: currentDate
    });


    const inputFeild = [{ name: 'din', label: 'DIN', val: formData.din, error: errorMessage.din, isEdit: isEdit },
    { name: 'drugName', label: 'DRUG NAME', val: formData.drugName, isEdit: isEdit },
    { name: 'blackSa', label: 'BLACK SA', val: formData.blackSa },
    { name: 'white1A', label: 'WHITE 1A', val: formData.white1A },
    { name: 'white1B', label: 'WHITE 1B', val: formData.white_1b },
    { name: 'White_2A_2B', label: 'WHITE 2A,2B', val: formData.White_2A_2B },
    { name: 'bts', label: 'BTS', val: formData.bts },
    { name: 'rts', label: 'RTS', val: formData.rts },
    { name: 'onShelf', label: 'ON SHELF', val: formData.onShelf, disabled: true },
    { name: 'computer', label: 'COMPUTER', val: formData.computer },
    { name: 'difference', label: 'DIFFERENCE', val: formData.difference, disabled: true }]

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };

        const sum = ['blackSa', 'white1A', 'White_2A_2B', 'bts', 'rts','white1B']
            .map(key => parseFloat(updatedFormData[key]) || 0)
            .reduce((a, b) => a + b, 0);

        updatedFormData.onShelf = sum.toString();

        const computerValue = parseFloat(updatedFormData.computer) || 0;
        updatedFormData.difference = (sum - computerValue).toString();

        setFormData(updatedFormData);

        if (name === 'din' && value) {
            debouncedFetchDrugListByDIN(value);
        }

    };

    const debouncedFetchDrugListByDIN = useCallback(debounce((value) => {
        dispatch(FetchDrugListByDIN('drug_by_din', value, setFormData));
    }, 500), []);

    const handleAddInventory = () => {
        if (!formData.din) {
            setErrorMessage({ ...errorMessage, din: 'DIN is required' })
        } else {
            setErrorMessage({ ...errorMessage, din: '' })

            if (isEdit) {
                const requestedData = {
                    id: logData.id,
                    date: formData.recurrence_day_type === 'previous_sheet' ? formData.date : '',
                    black_sa: formData.blackSa,
                    white_1b: formData.white1B,
                    white_1a: formData.white1A,
                    white_2a_2b: formData.White_2A_2B,
                    bts_rts: formData.bts,
                    rts: formData.rts,
                    onshell: formData.onShelf,
                    computer: formData.computer,
                    difference: formData.difference,
                    current_sheet: formData.recurrence_day_type
                }
                dispatch(UpdateInventory('add_inventory', requestedData, setShowToast, setShowModal, data, pageNumber))
            }


            else {
                const requestedData = {
                    din: formData.din,
                    drug_name: formData.drugName,
                    date: formData.recurrence_day_type === 'previous_sheet' ? formData.date : '',
                    black_sa: formData.blackSa,
                    white_1a: formData.white1A,
                    white_1b: formData.white1B,
                    white_2a_2b: formData.White_2A_2B,
                    bts_rts: formData.bts,
                    rts: formData.rts,
                    onshell: formData.onShelf,
                    computer: formData.computer,
                    difference: formData.difference,
                    current_sheet: formData.recurrence_day_type
                }
                dispatch(AddInventoryData('add_inventory', requestedData, setShowToast, setShowModal))
            }
        }
    }

    useEffect(() => {
        setFormData({
            ...formData,
            din: logData?.din,
            drugName: logData?.drug ? logData?.drug : '',
            blackSa: logData?.black_sa,
            white1A: logData?.white_1a,
            white_1b: logData?.white1B,
            White_2A_2B: logData?.white_2a_2b,
            bts: logData?.bts_rts,
            rts: logData?.rts,
            onShelf: logData?.onshell,
            computer: logData?.computer,
            difference: logData?.difference,
        });
    }, [logData])

    return (
        <React.Fragment>
            <div className="overflow-y-auto overflow-x-hidden fixed top-0 left-0 right-0 bottom-0 z-50 flex  items-center 
							justify-center w-full bg-black bg-opacity-50 dark:bg-opacity-100 max-h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {isEdit ? 'Update' : 'Add Inventory'}
                            </h3>
                            <button type="button" onClick={() => { return dispatch(addInventoryFailure(null, null)), handleHideModal(setShowModal, showModal, 'addInventory') }}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <form>
                                {isEdit ? null : <>
                                    <div className='col-span-12 mb-4'>
                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-1 flex flex-wrap items-center gap-10">
                                            {recurrenceRuleType?.map((label, index) => (
                                                <div key={index} className="flex items-center gap-4">
                                                    <input
                                                        id={`rule-${index}`}
                                                        name="recurrence_day_type"
                                                        type="radio"
                                                        defaultValue={label.value}
                                                        checked={formData.recurrence_day_type === label.value}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                recurrence_day_type: e.target.value
                                                            });
                                                        }}
                                                        className="w-6 h-6 text-blue-400 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                    <label htmlFor={`rule-${index}`} style={{ fontWeight: '500' }}
                                                        className="fs-16 -ml-2 rubik-400  text-sm font-semibold text-gray-900 dark:text-gray-300">
                                                        {label.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {formData.recurrence_day_type === 'previous_sheet' &&
                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                            <PreviousDatePicker setFormData={setFormData} formData={formData} />
                                        </div>}
                                </>}

                                <div className={`grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 ${isEdit ? '' : 'mt-4'}`}>
                                    {inputFeild?.map((value, index) => {
                                        return (<React.Fragment key={index}>
                                            {value.isEdit ? null :
                                                <div className="relative w-full">
                                                    <input type={`${value.name === 'drugName' ? 'text' : 'number'}`} id={value.name} autoComplete="off"
                                                        onChange={handleInputChange}
                                                        name={value.name}
                                                        value={value.val}
                                                        className={`block pl-4 ${value.name === 'difference' && 'bg-gray-100'} ${value.name === 'onShelf' && 'bg-gray-100'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                                    dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                    bg-white `}
                                                        placeholder=""
                                                        disabled={value.disabled}
                                                    />
                                                    <label htmlFor={value.name}
                                                        className={`ml-4 absolute ${value.name === 'difference' && 'bg-gray-100'} ${value.name === 'onShelf' && 'bg-gray-100'}  text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                                                origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                                                peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
                                                        {value.label}
                                                    </label>
                                                    {value.error &&
                                                        <p className='text-red-600 fs-10'>{value.error}</p>}
                                                </div>}
                                        </React.Fragment>)
                                    })}
                                </div>

                                {(addInventoryResponse?.error !== null && addInventoryResponse?.type === 'add_inventory') &&
                                    <div className='mt-2'>
                                        <Errors error={'Oops! Got an issue while adding data.please try to add again.'} />
                                    </div>}

                                <div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light mt-4">
                                    <button onClick={() => addInventoryResponse?.loading ? null : handleAddInventory()} type="button" style={{ backgroundColor: '#3B82F6' }}
                                        className="flex w-full items-center justify-center text-white bg-red-500 mt-4
										border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-1.5  dark:bg-gray-800">
                                        {addInventoryResponse?.loading && addInventoryResponse?.type === 'add_inventory' ? 'Saving...' : isEdit ? 'Update' : 'Save'}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default AddInventoryModal