import {
    ADD_REVENUE_DATA_REQUEST,
    ADD_REVENUE_DATA_SUCCESS,
    ADD_REVENUE_DATA_FAILURE,
} from "../../../constants"

import {
    pharmacy_add_revenue,
    add_multiple_revinue,
    update_revinue_list,
    delete_revenue,
    cash_change_save,
    deposit_info_save,
    pharmacy_deposit_print,
    delete_deposit_print,
    save_handcash,
    print_handcash,
    delete_handcash,
    delete_frequent_log
} from './../../../../networking/urlEndPoints'

import {
    FetchPharmacyDepositInfo,
    FetchPharmacyInCashHandList,
    fetchDailyRevenueList
} from "./revenueList"

import { PreviewPdf } from "../../../../utils/helper/helper"
import { fetchFrequestDispensingAuthList } from "../../FDA/fdaFetch"

import Axios from "../../../../networking/intraceptor"

export const addRevenueDataRequest = (fetchType) => ({
    type: ADD_REVENUE_DATA_REQUEST,
    fetch_type: fetchType
})

export const addRevenueDataSuccess = (data, fetchType) => ({
    type: ADD_REVENUE_DATA_SUCCESS,
    payload: data,
    fetch_type: fetchType
})

export const addRevenueDataFailure = (error, fetchType) => ({
    type: ADD_REVENUE_DATA_FAILURE,
    payload: error,
    fetch_type: fetchType
})

export const AddRevenueSingleEntryData = (type, requestedData, setShowToast, setShowModal) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${pharmacy_add_revenue}`, requestedData)
            if (response?.status === 200) {
                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, successToast: true }))
                    setShowModal((prevStat) => ({ ...prevStat, singleRevenue: false }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, successToast: false }))
                    }, 2000);
                    document.body.style.overflow = 'auto'

                }

                dispatch(fetchDailyRevenueList('daily_revenue_list'))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}

export const AddMultipleRevenueData = (type, requestedData, setShowToast, setShowModal) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${add_multiple_revinue}`, requestedData)
            if (response?.status === 200) {
                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, successToast: true }))
                    setShowModal((prevStat) => ({ ...prevStat, multipleRevenue: false }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, successToast: false }))
                    }, 2000);
                    document.body.style.overflow = 'auto'

                }

                dispatch(fetchDailyRevenueList('daily_revenue_list'))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}

export const UpdateDailyRevenueData = (type, requestedData, setShowToast, setShowModal) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${update_revinue_list}`, requestedData)
            if (response?.status === 200) {
                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, successToast: true }))
                    setShowModal((prevStat) => ({ ...prevStat, updateRevenueList: false }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, successToast: false }))
                    }, 2000);
                    document.body.style.overflow = 'auto'

                }

                dispatch(fetchDailyRevenueList('daily_revenue_list'))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}

export const DeleteRevenuelistData = (type, id, setShowToast, setShowModal) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${delete_revenue}/${id}`)

            if (response?.status === 200) {

                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, successToast: true }))
                    setShowModal((prevStat) => ({ ...prevStat, deleteRevenueList: false }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, successToast: false }))
                    }, 2000);
                    document.body.style.overflow = 'auto'
                }

                dispatch(fetchDailyRevenueList('daily_revenue_list'))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}

export const AddCashChangeData = (type, requestedData) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${cash_change_save}`, requestedData)

            if (response?.status === 200) {
                dispatch(addRevenueDataSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))
            }
        }
    }
}

export const AddNewDepositData = (type, requestedData) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${deposit_info_save}`, requestedData)

            if (response?.status === 200) {
                dispatch(addRevenueDataSuccess(response?.data, type))
                dispatch(FetchPharmacyDepositInfo('deposit_list_info'))
                PreviewPdf(response?.data?.data)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))
            }
        }
    }
}

export const DepositPrintData = (type, id) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_deposit_print}/${id}`)

            if (response?.status === 200) {
                dispatch(addRevenueDataSuccess(response?.data, type))
                PreviewPdf(response?.data?.data)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))
            }
        }
    }
}

export const DeletePharmacyDepositInfoList = (type, id, setShowToast, setShowModal, pageNumber) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${delete_deposit_print}/${id}`)

            if (response?.status === 200) {

                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    // setShowToast((prevStat) => ({ ...prevStat, successToast: true }))
                    setShowModal((prevStat) => ({ ...prevStat, deleteRevenueList: false }))
                    // setTimeout(() => {
                    //     setShowToast((prevStat) => ({ ...prevStat, successToast: false }))
                    // }, 2000);
                    document.body.style.overflow = 'auto'
                }

                const params = {
                    page: pageNumber
                }
                dispatch(FetchPharmacyDepositInfo('deposit_list_info', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}

export const AddINHandCashPrintSave = (type, requestedData) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${save_handcash}`, requestedData)

            if (response?.status === 200) {
                dispatch(addRevenueDataSuccess(response?.data, type))
                dispatch(FetchPharmacyInCashHandList('cash_in_hand_list'))
                PreviewPdf(response?.data?.data)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))
            }
        }
    }
}


export const PrintHandCashData = (type, id) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${print_handcash}/${id}`)

            if (response?.status === 200) {
                dispatch(addRevenueDataSuccess(response?.data, type))
                PreviewPdf(response?.data?.data)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))
            }
        }
    }
}


export const DeleteInHandCashList = (type, id, setShowToast, setShowModal, pageNumber) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${delete_handcash}/${id}`)

            if (response?.status === 200) {

                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    // setShowToast((prevStat) => ({ ...prevStat, successToast: true }))
                    setShowModal((prevStat) => ({ ...prevStat, deleteRevenueList: false }))
                    // setTimeout(() => {
                    //     setShowToast((prevStat) => ({ ...prevStat, successToast: false }))
                    // }, 2000);
                    document.body.style.overflow = 'auto'
                }

                const params = {
                    page: pageNumber
                }
                dispatch(FetchPharmacyInCashHandList('cash_in_hand_list', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}

export const DeleteAllGenerateFdaList = (type, id, setShowToast, setShowModal, pageNumber) => {
    return async (dispatch) => {

        dispatch(addRevenueDataRequest(type))
        try {
            const response = await Axios.post(`${delete_frequent_log}/${id}`)

            if (response?.status === 200) {

                dispatch(addRevenueDataSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, deleteRevenueList: false }))
                    document.body.style.overflow = 'auto'
                }

                const params = {
                    page: pageNumber,
                    no_of_records: 10,
                };

                dispatch(fetchFrequestDispensingAuthList("all_generated_fda_form_list", params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addRevenueDataFailure(error?.response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))

                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);

                }
            }
        }
    }
}