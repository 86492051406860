import React, {
    useEffect,
    useState,
} from "react";

import {
    initalShowToastMessage,
    initialFormARVData,
} from "../../../utils/constants/formInitialStates";

import { onHandleChangeAddARVFormDATA } from "../../../utils/data/handleFormDataChange";
import { useCustomDispatch } from "../../../hooks";
import { addARVDataSuccess } from "../../../services/actions/pharmacy/forms/addARV";

import { handleARVForm } from "../../../utils/validations/formsValidations";
import { useCustomPatientSelectors } from "../../../services/selectors/patientSelectors";
import { SuccessToastMessage } from "../../../components/loaders/toastMessage";

import { Loader } from "../../../components/common/loadingView";
import { Errors } from "../../../components/error/alertMessages";

import ChatBox from "../../../components/chat/chatbox";

import * as Common from "../../../components/common";
import * as Pharmacy from "../../../components/pharmacy/form/form";

const AddARV = () => {

    const { addARVResponse } = useCustomPatientSelectors();

    const dispatch = useCustomDispatch();

    const [ARVFormData, setARVFormData] = useState(initialFormARVData);
    const [errorMessages, setErrorMessages] = useState(initialFormARVData);
    const [showToast, setShowToast] = useState(initalShowToastMessage);

    const handleChangeFormData = (e) => {
        onHandleChangeAddARVFormDATA(
            e,
            setARVFormData,
            ARVFormData,
            setErrorMessages,
            errorMessages
        );
    };

    const drugInputFeilds = [
        {
            id: "drugName",
            label: "Drug Name",
            name: "drugname",
            value: ARVFormData.drugname,
            errorMessage: errorMessages.drugName,
            type: "text",
        },
        {
            id: "dueInDays",
            label: "Due in Days",
            name: "duein",
            value: errorMessages.duein,
            errorMessage: errorMessages.duein,
            type: "number",
        },
    ];

    const [addNewDrugs, setaddNewDrugs] = useState([
        { addedDrugs: drugInputFeilds },
    ]);

    const handleAddNew = () => {
        setaddNewDrugs([...addNewDrugs, { addedDrugs: drugInputFeilds }]);
    };

    useEffect(() => {
        setaddNewDrugs([{ addedDrugs: drugInputFeilds }]);
    }, []);

    const handleFormDataChanges = (e, i, index) => {
        const { name, value } = e.target;
        setaddNewDrugs((prevCompounds) => {
            const updatedCompounds = [...prevCompounds];
            updatedCompounds[i].addedDrugs[index].value = value;
            return updatedCompounds;
        });
    };

    const handleDelete = (index) => {
        setaddNewDrugs((prevCompounds) => {
            const updatedCompounds = [...prevCompounds];
            updatedCompounds.splice(index, 1);
            return updatedCompounds;
        });
    };

    const AddARV = () => {
        let isValid = true;
        const updatedCompounds = addNewDrugs?.map((compound) => {
            const updatedSelectComponents = compound.addedDrugs.map((field) => {
                if (!field.value) {
                    isValid = false;
                    return { ...field, errorMessage: `${field.label} is required` };
                }
                return { ...field, errorMessage: "" };
            });
            return { ...compound, addedDrugs: updatedSelectComponents };
        });

        setaddNewDrugs(updatedCompounds);

        handleARVForm(
            ARVFormData,
            setErrorMessages,
            errorMessages,
            dispatch,
            showToast,
            setShowToast,
            addNewDrugs,
            isValid
        );
    };


    useEffect(() => {
        dispatch(addARVDataSuccess(null));
    }, []);



    return (
        <>
            <div className="BodyBgColor">
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className=" ">
                                            <div className="">
                                                <div className="grid grid-cols-3 gap-4">
                                                    <div>
                                                        <Pharmacy.Date
                                                            formData={ARVFormData}
                                                            setFormData={setARVFormData}
                                                        />
                                                    </div>
                                                    <div className="flex">
                                                        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                            <Pharmacy.FormWinRxPatients
                                                                formData={ARVFormData}
                                                                errorMessages={errorMessages}
                                                                setErrorMessages={setErrorMessages}
                                                                setFormData={setARVFormData}
                                                                handleFormDataChange={handleChangeFormData}
                                                            />
                                                        </ul>
                                                    </div>
                                                </div>

                                                {/* Form holds patient Details */}

                                                <div className="grid grid-cols-3 mt-5 gap-4">
                                                    <div>
                                                        <Pharmacy.PatientPhn
                                                            handleFormDataChange={handleChangeFormData}
                                                            formData={ARVFormData}
                                                            errorMessages={errorMessages}
                                                            setErrorMessages={setErrorMessages}
                                                            setFormData={setARVFormData}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Pharmacy.PatientName
                                                            handleFormDataChange={handleChangeFormData}
                                                            formData={ARVFormData}
                                                            errorMessages={errorMessages}
                                                            setFormData={setARVFormData}
                                                            setErrorMessages={setErrorMessages}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Pharmacy.PatientDob
                                                            handleFormDataChange={handleChangeFormData}
                                                            formData={ARVFormData}
                                                            errorMessages={errorMessages}
                                                            setFormData={setARVFormData}
                                                            setErrorMessages={setErrorMessages}
                                                        />
                                                    </div>
                                                </div>

                                                {/* Patient's Drug Details */}

                                                <div className="flex flex-wrap gap-x-4 gap-y-1 mt-4 ">
                                                    {addNewDrugs?.map((value, i) => {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                <div className="mb-2 flex flex-wrap gap-x-4 gap-y-1  w-full">
                                                                    {value?.addedDrugs?.map((field, index) => (
                                                                        <div
                                                                            className="relative flex-0 w-full md:flex-1"
                                                                            key={index}
                                                                        >
                                                                            <input
                                                                                type={field.type}
                                                                                id={field.name[index]}
                                                                                onChange={(e) =>
                                                                                    handleFormDataChanges(e, i, index)
                                                                                }
                                                                                name={field.name}
                                                                                value={field.value}
                                                                                placeholder=""
                                                                                className={`block pl-4 px-2.5 pb-2.5 ${field.errorMessage ? `border-red-500` : 'border-gray-300'} pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 
                                                                                appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 
                                                                                focus:border-blue-600 peer cursor-text bg-white`}
                                                                            />
                                                                            <label
                                                                                htmlFor={field.name[index]}
                                                                                className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 
                                                                                scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
                                                                                peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                                                                                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 
                                                                                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
                                                                            >
                                                                                {!field.errorMessage && field.label}
                                                                            </label>
                                                                            {field.errorMessage && (
                                                                                <p className="text-xs text-red-600 dark:text-red-400">
                                                                                    {field.errorMessage}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    ))}

                                                                    {i === 0 && 
                                                                    <button  style={{ height: '48px' }}
                                                                        onClick={handleAddNew}
                                                                        type="button"
                                                                        className="flex  py-4 items-center w-38 justify-center text-white bg-red-600 border border-gray-300 focus:outline-none hover:bg-red-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                                                                    >
                                                                        Add Drug
                                                                    </button>} 

                                                                    {i > 0 && (
                                                                        <button style={{ height: '48px' }}
                                                                            onClick={() => handleDelete(i)}
                                                                            type="button"
                                                                            className="flex w-auto flex-0 py-4 items-center w-38 justify-center text-red-900 bg-white border border-red-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                    <div className="w-full"></div>
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                   
                                                </div>
                                            </div>

                                            {addARVResponse?.error !== null && (
                                                <>
                                                    <br />
                                                    <Errors
                                                        error={
                                                            "Something went wrong while saving details.Please try again. "
                                                        }
                                                    />
                                                </>
                                            )}
                                         
                                                <div className="relative">
                                                    <button style={{ background: '#3A52BB' }}
                                                        type="button"
                                                        onClick={() => AddARV()}
                                                        className="flex  py-4 items-center w-38 justify-center text-white bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                        <span className="flex items-center">
                                                            {addARVResponse?.loading ? (
                                                                <>
                                                                    Processing &nbsp;
                                                                    <Loader />
                                                                </>
                                                            ) : (
                                                                "Save"
                                                            )}
                                                        </span>
                                                    </button>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showToast.showToast && (
                <SuccessToastMessage status={"AVR form submitted successfully."} />
            )}

            <ChatBox />
            <Common.Footer />
        </>
    );
};

export default AddARV;
