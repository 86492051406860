import React, { useRef, useState } from "react";
import { useCustomDispatch } from "../../hooks";
import * as useEffectHelper from "../../utils/helper/useEffectsHelper";
import { handleHideModal } from "../../assets/js/custome";
import { ModalCloseIcon } from "../../assets/svg/icons";
import * as routeName from '../../routes/routeName'
import { useBlisterSelectors } from '../../services/selectors/blisterSelectors';
import { handleDeleteARV } from "../../services/actions/pharmacy/arv/patientARVDelete";
import { useCustomPatientSelectors } from "../../services/selectors/patientSelectors";
import * as Pharmacy from "../../components/pharmacy/form/form";
import { onhandlePharmacyFormDataChange } from "../../utils/data/handleFormDataChange";
import { useFormsSelectors } from '../../services/selectors/formsSelector'
import { Loader } from '../../components/common/loadingView'
import { Errors } from '../../components/error/alertMessages'
import { ErrorToastMessage, SuccessToastMessage } from '../../components/loaders/toastMessage'
import { currentDate } from '../../utils/constants/formInitialStates';
import * as Hooks from '../../hooks'
import { Date } from '../../components/pharmacy/form/form';
import {
    initalPharmacyInformationUpdateForm,
    initalShowToastMessage,
} from "../../utils/constants/formInitialStates";
import { TextInput } from "../../components/pharmacy/dispense/dispense";
import { handleARVFaxValidation } from "../../utils/validations/arvValidations";
import { useCustomSelectors } from "../../services/selectors/allSelectors";
import { addNewAnnouncementValidation } from "../../utils/validations/appValidations";
import { AddAnnouncementAction, AddAppNotification, EditAnnouncementAction } from "../../services/actions/pharmacy/app/addAppData";
import { useAppSelector } from "../../services/selectors/appSelector";
import { handleDeleteAnnouncements } from "../../services/actions/pharmacy/app/deleteApp";

export const AppSendNotificationPopup = ({ setShowModal, showModal }) => {
    const initalDataForAddAnnouncements = {
        title: '',
        body: '',
    }

    const initalDataForAddAnnouncementsErrors = {
        title: '',
        body: '',
    }


    const { AddBlisterPackResponse } = useBlisterSelectors()
    const dispatch = Hooks.useCustomDispatch()
    const location = Hooks.useCustomLocation()
    const updateTravelLogs = location.pathname === routeName.pharmacy_travel_logs
    const modalRef = useRef(null);
    const [formData, setFormData] = useState(initalDataForAddAnnouncements)
    const [errorMessages, setErrorMessages] = useState(initalDataForAddAnnouncementsErrors)
    const [showToast, setShowToast] = useState(initalShowToastMessage);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevFormData => {
            const updatedData = { ...prevFormData, [name]: value };
            return updatedData;
        });
    };

    const inputFeilds = [
        { label: "Title", key: "title", error: errorMessages.title },
        { label: "Body", key: "body", error: errorMessages.body },

    ]

    const handleSubmitPackDetails = () => {
        const specificValidations = {
            title: 'Title is required',
            body: 'Body is required',

        }

        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(specificValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        );

        setErrorMessages({
            ...errorMessages,
            ...newErrorMessages,
        });

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

        if (allDataPresent) {
            const requestData = {
                title: formData.title,
                body: formData.body
            }

            dispatch(AddAppNotification('add_app_notification', requestData, setShowToast, setShowModal))

        }
    };


    return (
        <>
            <div>
                {showModal.AddNotificationModal && (
                    <div
                        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                    >
                        <div ref={modalRef} className="relative w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className=" justify-center align-items-center p-5 mt-5">
                                    <div className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Add Notification
                                    </div>


                                    <div className="mt-5">
                                        {inputFeilds?.map((field, idx) => (
                                            <div className="relative mb-4" key={idx}>
                                                <input
                                                    type="text"
                                                    id={field.key}
                                                    name={field.key}
                                                    value={formData[field.key]}
                                                    onChange={handleInputChange}
                                                    className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                                    placeholder=""
                                                    autoComplete='false'

                                                />
                                                <label
                                                    htmlFor={field.key}
                                                    className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {field.error ? '' : field.label}
                                                </label>

                                                {field.error && (
                                                    <p className="text-xs text-red-600 dark:text-red-400">
                                                        {field.error}
                                                    </p>
                                                )}

                                            </div>
                                        ))}


                                    </div>



                                </div>
                                <div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
                                    <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
                                        <div className="col-span-12">
                                            <button type="button" onClick={handleSubmitPackDetails}
                                                className={`rubik-500 flex justify-center items-center text-white blueBg-color   w-full text-sm px-5 py-2.5 text-center`}>
                                                {AddBlisterPackResponse?.loading ? 'Processing...' : updateTravelLogs ? 'Update' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {" "}
                                    <button
                                        onClick={() =>
                                            handleHideModal(setShowModal, showModal, "AddNotificationModal")
                                        }
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                        <ModalCloseIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showToast.showToast && <SuccessToastMessage status={'Added Successfully'} />}
        </>
    );
};



export const AppAddNewAccouncement = ({ setShowModal, showModal, page }) => {
    const initalDataForAddAnnouncements = {
        title: '',
        banner: '',
        fromdate: currentDate,
        todate: currentDate,
        status: true,
        billed_first_day: '',
        next_bill_qty: ''
    }

    const initalDataForAddAnnouncementsErrors = {
        title: '',
        banner: '',
        fromdate: '',
        todate: '',
        status: true,
        billed_first_day: '',
        next_bill_qty: ''
    }
    const [isChecked, setIsChecked] = useState(true);
    const { AddBlisterPackResponse } = useBlisterSelectors()
    const dispatch = Hooks.useCustomDispatch()
    const location = Hooks.useCustomLocation()
    const updateTravelLogs = location.pathname === routeName.pharmacy_travel_logs
    const modalRef = useRef(null);
    const [formData, setFormData] = useState(initalDataForAddAnnouncements)
    const [errorMessages, setErrorMessages] = useState(initalDataForAddAnnouncementsErrors)
    const [showToast, setShowToast] = useState(initalShowToastMessage);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'status') {
            setFormData({
                ...formData,
                [name]: !isChecked,
            });

            setIsChecked(!isChecked)
        }
        if (name === 'banner') {
            setFormData({
                ...formData,
                banner: e.target.files[0],
            });

        }
        setFormData(prevFormData => {
            const updatedData = { ...prevFormData, [name]: value };

            return updatedData;

        });
    };

    const inputFeilds = [
        { label: "Title", key: "title", error: errorMessages.title },

    ]
    const handleFileUpload = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const resetFormData = () => {
        setFormData(initalDataForAddAnnouncements);
        setErrorMessages(initalDataForAddAnnouncementsErrors);
        setSelectedFile(null);
        setIsChecked(true);
    };

    const handleSubmitPackDetails = () => {
        const specificValidations = {
            title: 'Title is required',
            fromdate: 'From Date is required',
            todate: 'To Date is required',

        }

        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(specificValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        );

        setErrorMessages({
            ...errorMessages,
            ...newErrorMessages,
        });

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

        let status = "0";
        if (formData.status === true) {
            status = "1"
        }

        if (allDataPresent) {
            const requestData = {
                banner: selectedFile,
                text: formData.title,
                from_date: formData.fromdate,
                to_date: formData.todate,
                status: status,
            }

            dispatch(AddAnnouncementAction('add_announcement_action', requestData, setShowToast, setShowModal, page))
            .then(() => {
                resetFormData();
            })

        }
    };


    return (
        <>
            <div>
                {showModal.AddAnnouncements && (
                    <div
                        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                    >
                        <div ref={modalRef} className="relative w-full max-w-md max-h-full" style={{ maxWidth: '35rem' }}>
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className=" justify-center align-items-center p-5 mt-5">
                                    <div className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Add Announcements
                                    </div>


                                    <div className="mt-5">
                                        {inputFeilds?.map((field, idx) => (
                                            <div className="relative mb-4" key={idx}>
                                                <input
                                                    type="text"
                                                    id={field.key}
                                                    name={field.key}
                                                    value={formData[field.key]}
                                                    onChange={handleInputChange}
                                                    className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                                    placeholder=""
                                                    autoComplete='false'

                                                />
                                                <label
                                                    htmlFor={field.key}
                                                    className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {field.error ? '' : field.label}
                                                </label>

                                                {field.error && (
                                                    <p className="text-xs text-red-600 dark:text-red-400">
                                                        {field.error}
                                                    </p>
                                                )}

                                            </div>
                                        ))}


                                        <div className='relative mt-4 mb-4'>
                                            <Date formData={formData}
                                                setFormData={setFormData}
                                                handleFormDataChange={handleInputChange}
                                                label='From Date'
                                                name='fromdate'
                                                dValue={formData.fromdate}
                                            />

                                            {errorMessages.fromdate && (
                                                <p className="text-xs text-red-600 dark:text-red-400">
                                                    {errorMessages.fromdate}
                                                </p>
                                            )}

                                        </div>

                                        <div className='relative mt-4 mb-4'>
                                            <Date formData={formData}
                                                setFormData={setFormData}
                                                handleFormDataChange={handleInputChange}
                                                label='To Date'
                                                name='todate'
                                                dValue={formData.todate}
                                            />

                                            {errorMessages.todate && (
                                                <p className="text-xs text-red-600 dark:text-red-400">
                                                    {errorMessages.todate}
                                                </p>
                                            )}

                                        </div>

                                        <div className=" mb-4 mt-4">
                                            <div className="mb-2"><label>Status</label></div>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" name="status" checked={isChecked} onChange={handleInputChange} className="sr-only peer" />
                                                <div
                                                    className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 mr-2 p-0.5">
                                                </div>
                                                <span
                                                    className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 "></span>
                                            </label>
                                        </div>

                                        <div className="relative  mb-4 mt-0">
                                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload file</label>
                                            <input accept=".jpeg,.png,.jpg,.gif,.svg" onChange={handleFileUpload} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" name="banner" type="file" />
                                        </div>
                                    </div>



                                </div>
                                <div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
                                    <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
                                        <div className="col-span-12">
                                            <button type="button" onClick={handleSubmitPackDetails}
                                                className={`rubik-500 flex justify-center items-center text-white blueBg-color   w-full text-sm px-5 py-2.5 text-center`}>
                                                {AddBlisterPackResponse?.loading ? 'Processing...' : updateTravelLogs ? 'Update' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {" "}
                                    <button
                                        onClick={() =>
                                            handleHideModal(setShowModal, showModal, "AddAnnouncements")
                                        }
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                        <ModalCloseIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showToast.showToast && <SuccessToastMessage status={'Added Successfully'} />}
            {showToast.errorToast && <ErrorToastMessage status={'Something went wrong. Please try again.'} />}
        </>
    );
};




export const EditAppAccouncement = ({ setShowModal, showModal, announcementsData = '', pageNumber = '1' }) => {

    const initalDataForAddAnnouncements = {
        title: announcementsData.title,
        banner: '',
        fromdate: announcementsData.from,
        todate: announcementsData.to,
        status: announcementsData.status,
    }

    const initalDataForAddAnnouncementsErrors = {
        title: '',
        banner: '',
        fromdate: '',
        todate: '',
        status: true,
    }
    const [isChecked, setIsChecked] = useState(true);
    const { AddBlisterPackResponse } = useBlisterSelectors()
    const dispatch = Hooks.useCustomDispatch()
    const location = Hooks.useCustomLocation()
    const updateTravelLogs = location.pathname === routeName.pharmacy_travel_logs
    const modalRef = useRef(null);
    const [formData, setFormData] = useState(initalDataForAddAnnouncements)
    const [errorMessages, setErrorMessages] = useState(initalDataForAddAnnouncementsErrors)
    const [showToast, setShowToast] = useState(initalShowToastMessage);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'status') {
            setFormData({
                ...formData,
                [name]: !isChecked,
            });

            setIsChecked(!isChecked)
        }
        if (name === 'banner') {
            setFormData({
                ...formData,
                banner: e.target.files[0],
            });

        }
        setFormData(prevFormData => {
            const updatedData = { ...prevFormData, [name]: value };

            return updatedData;

        });



        // if (name === 'banner') {
        //     setFormData({
        //         ...formData,
        //         banner: e.target.files[0],
        //     });

        // }
        // setSelectedFile( e.target.files[0])

        // console.log( e.target.files[0])
    };

    const inputFeilds = [
        { label: "Title", key: "title", error: errorMessages.title },

    ]
    const handleFileUpload = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmitPackDetails = () => {
        const specificValidations = {
            title: 'Title is required',
            fromdate: 'From Date is required',
            todate: 'To Date is required',

        }

        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(specificValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        );

        setErrorMessages({
            ...errorMessages,
            ...newErrorMessages,
        });

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

        let status = "0";
        if (formData.status === true) {
            status = "1"
        }

        if (allDataPresent) {
            const requestData = {
                banner: selectedFile,
                text: formData.title,
                from_date: formData.fromdate,
                to_date: formData.todate,
                status: status,
                announce_id: announcementsData.id
            }

            dispatch(EditAnnouncementAction('edit_announcement_action', requestData, setShowToast, setShowModal, pageNumber))

        }
    };


    return (
        <>
            <div>
                {showModal.EditAnnouncements && (
                    <div
                        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                    >
                        <div ref={modalRef} className="relative w-full max-w-md max-h-full" style={{ maxWidth: '35rem' }}>
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className=" justify-center align-items-center p-5 mt-5">
                                    <div className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Edit Announcements
                                    </div>


                                    <div className="mt-5">
                                        {inputFeilds?.map((field, idx) => (
                                            <div className="relative mb-4" key={idx}>
                                                <input
                                                    type="text"
                                                    id={field.key}
                                                    name={field.key}
                                                    value={formData[field.key]}
                                                    onChange={handleInputChange}
                                                    className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                                    placeholder=""
                                                    autoComplete='false'

                                                />
                                                <label
                                                    htmlFor={field.key}
                                                    className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {field.error ? '' : field.label}
                                                </label>

                                                {field.error && (
                                                    <p className="text-xs text-red-600 dark:text-red-400">
                                                        {field.error}
                                                    </p>
                                                )}

                                            </div>
                                        ))}


                                        <div className='relative mt-4 mb-4'>
                                            <Date formData={formData}
                                                setFormData={setFormData}
                                                handleFormDataChange={handleInputChange}
                                                label='From Date'
                                                name='fromdate'
                                                dValue={formData.fromdate}
                                            />

                                            {errorMessages.fromdate && (
                                                <p className="text-xs text-red-600 dark:text-red-400">
                                                    {errorMessages.fromdate}
                                                </p>
                                            )}

                                        </div>

                                        <div className='relative mt-4 mb-4'>
                                            <Date formData={formData}
                                                setFormData={setFormData}
                                                handleFormDataChange={handleInputChange}
                                                label='To Date'
                                                name='todate'
                                                dValue={formData.todate}
                                            />

                                            {errorMessages.todate && (
                                                <p className="text-xs text-red-600 dark:text-red-400">
                                                    {errorMessages.todate}
                                                </p>
                                            )}

                                        </div>

                                        <div className=" mb-4 mt-4">
                                            <div className="mb-2"><label>Status</label></div>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" name="status" checked={isChecked} onChange={handleInputChange} className="sr-only peer" />
                                                <div
                                                    className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 mr-2 p-0.5">
                                                </div>
                                                <span
                                                    className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 "></span>
                                            </label>
                                        </div>

                                        <div className="relative  mb-4 mt-0">
                                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload file</label>
                                            <input onChange={handleFileUpload} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" name="banner" accept="image/*" type="file" />
                                        </div>
                                    </div>



                                </div>
                                <div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
                                    <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
                                        <div className="col-span-12">
                                            <button type="button" onClick={handleSubmitPackDetails}
                                                className={`rubik-500 flex justify-center items-center text-white blueBg-color   w-full text-sm px-5 py-2.5 text-center`}>
                                                {AddBlisterPackResponse?.loading ? 'Processing...' : updateTravelLogs ? 'Update' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {" "}
                                    <button
                                        onClick={() =>
                                            handleHideModal(setShowModal, showModal, "EditAnnouncements")
                                        }
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                        <ModalCloseIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showToast.showToast && <SuccessToastMessage status={'Edited Successfully'} />}
        </>
    );
};


export const ViewAppAnnouncementImg = ({ img = '', setShowModal, showModal }) => {
    const modalRef = useRef(null);
    const [imgError, setImgError] = useState(false);

    return (
        <>
            <div>
                {showModal.ImgAnnouncements && (
                    <div
                        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
                        overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                    >
                        <div ref={modalRef} className="relative w-full max-w-md max-h-full" style={{ maxWidth: '55rem' }}>
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div style={{ height: '85vh', overflow: "auto" }} className="">
                                    {!imgError ? (
                                        <img
                                            src={img}
                                            alt="Announcement"
                                            style={{ objectFit: 'cover', width: '100%' }}
                                            onError={() => setImgError(true)}
                                        />
                                    ) : (
                                        <div className="flex items-center justify-center h-64 text-red-500">
                                            Image not found
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            setImgError(false);
                                            handleHideModal(setShowModal, showModal, "ImgAnnouncements");
                                        }}

                                        type="button"
                                        className="absolute top-3 right-2.5 text-black bg-white mr-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
                                        text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                        <ModalCloseIcon />
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                )}
                {showModal.showToast && <SuccessToastMessage status={'Edited Successfully'} />}
            </div>

        </>
    );
};


export const DeleteAnnoucementsModalPopUp = ({
    setShowModal,
    showModal,
    announceId,
    formData,
    pageNumber,
}) => {
    const modalRef = useRef(null);
    useEffectHelper.useRemoveModalEffect(
        setShowModal,
        showModal,
        "DeleteAnnounceModal",
        modalRef
    );

    const dispatch = useCustomDispatch();
    const { deleteAppResponse } = useCustomPatientSelectors();

    return (
        <>
            {showModal.DeleteAnnounceModal && (
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                >
                    <div ref={modalRef} className="relative w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                onClick={() =>
                                    handleHideModal(setShowModal, showModal, "DeleteAnnounceModal")
                                }
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                <ModalCloseIcon />
                            </button>

                            <div className="p-6 text-center pt-12">
                                <p className="rubik-500 text-gray-500">Are you sure?</p>
                                <p className="rubik-400 text-gray-500">
                                    You want to delete this announcement?
                                </p>
                                <br />

                                <button
                                    onClick={() =>
                                        dispatch(
                                            handleDeleteAnnouncements(
                                                announceId,
                                                "DeleteAnnounceModal",
                                                formData,
                                                pageNumber,
                                                setShowModal,
                                                showModal
                                            )
                                        )
                                    }
                                    style={{ backgroundColor: "#3A52BB" }}
                                    type="button"
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none 
												focus:ring-red-300 
                    							dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                >
                                    {deleteAppResponse?.loading ? "Processing..." : "Yes"}
                                </button>

                                <button
                                    onClick={() =>
                                        handleHideModal(setShowModal, showModal, "DeleteAnnounceModal")
                                    }
                                    type="button"
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                								border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                								dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showModal.showToast && <SuccessToastMessage status={'Deleted Successfully'} />}
        </>
    );
};

export const AddNewDeliveyTimes = ({ setShowModal, showModal, page }) => {
    const initalDataForAddAnnouncements = {
        title: '',
        banner: '',
        fromdate: currentDate,
        todate: currentDate,
        status: true,
        billed_first_day: '',
        next_bill_qty: ''
    }

    const initalDataForAddAnnouncementsErrors = {
        title: '',
        banner: '',
        fromdate: '',
        todate: '',
        status: true,
        billed_first_day: '',
        next_bill_qty: ''
    }
    const [isChecked, setIsChecked] = useState(true);
    const { AddBlisterPackResponse } = useBlisterSelectors()
    const dispatch = Hooks.useCustomDispatch()
    const location = Hooks.useCustomLocation()
    const updateTravelLogs = location.pathname === routeName.pharmacy_travel_logs
    const modalRef = useRef(null);
    const [formData, setFormData] = useState(initalDataForAddAnnouncements)
    const [errorMessages, setErrorMessages] = useState(initalDataForAddAnnouncementsErrors)
    const [showToast, setShowToast] = useState(initalShowToastMessage);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'status') {
            setFormData({
                ...formData,
                [name]: !isChecked,
            });

            setIsChecked(!isChecked)
        }
        if (name === 'banner') {
            setFormData({
                ...formData,
                banner: e.target.files[0],
            });

        }
        setFormData(prevFormData => {
            const updatedData = { ...prevFormData, [name]: value };

            return updatedData;

        });
    };

    const inputFeilds = [
        { label: "Title", key: "title", error: errorMessages.title },

    ]
    const handleFileUpload = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmitPackDetails = () => {
        const specificValidations = {
            title: 'Title is required',
            fromdate: 'From Date is required',
            todate: 'To Date is required',

        }

        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(specificValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        );

        setErrorMessages({
            ...errorMessages,
            ...newErrorMessages,
        });

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

        let status = "0";
        if (formData.status === true) {
            status = "1"
        }

        if (allDataPresent) {
            const requestData = {
                banner: selectedFile,
                text: formData.title,
                from_date: formData.fromdate,
                to_date: formData.todate,
                status: status,
            }

            dispatch(AddAnnouncementAction('add_announcement_action', requestData, setShowToast, setShowModal, page))

        }
    };


    return (
        <>
            <div>
                {showModal.AddAnnouncements && (
                    <div
                        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                    >
                        <div ref={modalRef} className="relative w-full max-w-md max-h-full" style={{ maxWidth: '35rem' }}>
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className=" justify-center align-items-center p-5 mt-5">
                                    <div className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Add Announcements
                                    </div>


                                    <div className="mt-5">
                                        {inputFeilds?.map((field, idx) => (
                                            <div className="relative mb-4" key={idx}>
                                                <input
                                                    type="text"
                                                    id={field.key}
                                                    name={field.key}
                                                    value={formData[field.key]}
                                                    onChange={handleInputChange}
                                                    className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                                    placeholder=""
                                                    autoComplete='false'

                                                />
                                                <label
                                                    htmlFor={field.key}
                                                    className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {field.error ? '' : field.label}
                                                </label>

                                                {field.error && (
                                                    <p className="text-xs text-red-600 dark:text-red-400">
                                                        {field.error}
                                                    </p>
                                                )}

                                            </div>
                                        ))}


                                        <div className='relative mt-4 mb-4'>
                                            <Date formData={formData}
                                                setFormData={setFormData}
                                                handleFormDataChange={handleInputChange}
                                                label='From Date'
                                                name='fromdate'
                                                dValue={formData.fromdate}
                                            />

                                            {errorMessages.fromdate && (
                                                <p className="text-xs text-red-600 dark:text-red-400">
                                                    {errorMessages.fromdate}
                                                </p>
                                            )}

                                        </div>

                                        <div className='relative mt-4 mb-4'>
                                            <Date formData={formData}
                                                setFormData={setFormData}
                                                handleFormDataChange={handleInputChange}
                                                label='To Date'
                                                name='todate'
                                                dValue={formData.todate}
                                            />

                                            {errorMessages.todate && (
                                                <p className="text-xs text-red-600 dark:text-red-400">
                                                    {errorMessages.todate}
                                                </p>
                                            )}

                                        </div>

                                        <div className=" mb-4 mt-4">
                                            <div className="mb-2"><label>Status</label></div>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" name="status" checked={isChecked} onChange={handleInputChange} className="sr-only peer" />
                                                <div
                                                    className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 mr-2 p-0.5">
                                                </div>
                                                <span
                                                    className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 "></span>
                                            </label>
                                        </div>

                                        <div className="relative  mb-4 mt-0">
                                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload file</label>
                                            <input accept="image/*" onChange={handleFileUpload} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" name="banner" type="file" />
                                        </div>
                                    </div>



                                </div>
                                <div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
                                    <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
                                        <div className="col-span-12">
                                            <button type="button" onClick={handleSubmitPackDetails}
                                                className={`rubik-500 flex justify-center items-center text-white blueBg-color   w-full text-sm px-5 py-2.5 text-center`}>
                                                {AddBlisterPackResponse?.loading ? 'Processing...' : updateTravelLogs ? 'Update' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {" "}
                                    <button
                                        onClick={() =>
                                            handleHideModal(setShowModal, showModal, "AddAnnouncements")
                                        }
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                        <ModalCloseIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showToast.showToast && <SuccessToastMessage status={'Added Successfully'} />}
        </>
    );
};