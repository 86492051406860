import React, { useState } from 'react';
import PanelIcons from '../Pannels/PanelItemIcon';
import { useTabContext } from '../hooks/TabContext'; 
import SignatureModal from './Modal/SignatureModal';
import { useCanvasContext } from '../hooks/useCanvasContext';
import { fabric } from 'fabric';

const CertificateSidebar = () => {
    const { setActiveTab, activeTab } = useTabContext();
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const { canvas } = useCanvasContext();

    const handleClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'employee') {
            addNewDataToCanvas('{{employee_name}}');
        } else if (tab === 'date') {
            addNewDataToCanvas('{{current_date}}');
        }
    };

    const openSignatureModal = () => {
        setIsSignatureModalOpen(true);
    };

    const closeSignatureModal = () => {
        setIsSignatureModalOpen(false);
    };

    const addNewDataToCanvas = (text = '{{employee_name}}') => {
        if (canvas) {
            const textbox = new fabric.Textbox(text, {
                fontSize: 20,
                lineHeight: 1.2,
                fill: '#000000',
                width: 200,
                fontWeight: 500,
                textAlign: 'center',
                left: canvas.getWidth() / 2,
                top: canvas.getHeight() / 2,
                originX: 'center',
                originY: 'center',
            });
            canvas.add(textbox);
            canvas.setActiveObject(textbox);
            canvas.renderAll();
        }
    };

    return (
        <div className="" style={{ background: "#374151", width: '100px' }}>
            {['templates', 'images', 'text', 'sign', 'employee', 'date', 'pharmacy'].map((tab) => (
                <div 
                    key={tab}
                    className={`cursor-pointer panel-items-list-item flex flex-col mt-4 text-white col items-center justify-center ${activeTab === tab ? 'activeSideBarCertificate' : 'SideBarCertificate'}`} 
                    onClick={() => tab === 'sign' ? openSignatureModal() : handleClick(tab)}
                >
                    {PanelIcons[tab]?.render()}
                    <div style={{ fontSize: "14px", textAlign: "center" }}>{tab.charAt(0).toUpperCase() + tab.slice(1)}</div>
                </div>
            ))}
    
            <SignatureModal isOpen={isSignatureModalOpen} onClose={closeSignatureModal} />
        </div>
    );
};

export default CertificateSidebar;
