import React, { useEffect, useState } from 'react'

import { DropdownSelect } from '../../../components/pharmacy/dispense/dispense'
import { EditIcons } from '../../../assets/svg/icons'
import { useParams } from 'react-router-dom'
import { useCustomDispatch } from '../../../hooks'

import { fetchAllOrdersNotesList, fetchOrdersDetails } from '../../../services/actions/pharmacy/app/prescriptionList'
import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors'
import { handleShowModal } from '../../../assets/js/custome'

import { HandleUpdateCustomerNotes } from '../../../services/actions/pharmacy/app/addAppData'
import { ErrorToastMessage, SuccessToastMessage } from '../../../components/loaders/toastMessage'

import { useInventorySelector } from '../../../services/selectors/inventorySelectors'
import { TransferPrescriptionSendFax } from '../../../components/pharmacy/app/sendFax'

import ChatBox from '../../../components/chat/chatbox'
import WorkspaceTabs from '../../../components/pharmacy/workSpace/workspaceTabs'

import UpdateShipping_Billing_AddressModal from '../../../models/pharmacy/updateShipping_BillingAddress'
import CreateShipment from '../../../components/pharmacy/app/createShipment'
import CreatePillReminder from '../../../components/pharmacy/app/pillReminder'

import * as Common from '../../../components/common'

const ViewOrderDetails = () => {

    const { id } = useParams()
    const dispatch = useCustomDispatch()

    const { patientBlisterPackListResponse, AddBlisterPackResponse } = useBlisterSelectors()
    const { InventoryListResponse } = useInventorySelector()

    const orderNotesListResponse = InventoryListResponse
    const orderDetailsResponse = patientBlisterPackListResponse
    const UpdateOrderStatusResponse = AddBlisterPackResponse

    const orderNotesList = orderNotesListResponse?.data?.data?.data
    const orderDetails = orderDetailsResponse?.data?.data?.order

    const error = orderDetailsResponse?.error?.data?.data?.error
    const updateOrderStatusError = UpdateOrderStatusResponse?.error?.data?.data?.error

    const [showModal, setShowModal] = useState({ addressModal: false })
    const [viewOrderDetail, setViewOrderDetail] = useState({ detail: null, addressType: null })

    const [formData, setFormData] = useState({ order_status: '', notes: '', check: '' })
    const [errorMessage, setErrorMessage] = useState({ order_status: '', notes: '', check: '' })

    const [showToast, setShowToast] = useState({ showToast: false, errorToast: false })

    const [overlayImage, setOverlayImage] = useState(null)
    const [apiCalMade, setApiCallMade] = useState(false)

    useEffect(() => {
        if (id && !apiCalMade) {
            dispatch(fetchOrdersDetails('view_order_details', id))
            dispatch(fetchAllOrdersNotesList('view_order_notes_list', id))
            setApiCallMade(true)
        }
        return () => { }
    }, [])


    const handleShowImage = (image) => {
        document.getElementById('overlay').style.display = 'flex';
        setOverlayImage(image)
    }

    const handleCloseOverlayImg = () => {
        document.getElementById('overlay').style.display = 'none';
        setOverlayImage(null)
    }

    useEffect(() => {
        setFormData({ ...formData, order_status: orderDetails?.status })
    }, [orderDetails])

    const handleUpdateNotes = () => {
        let errors = {};

        if (!formData.notes) {
            errors.notes = '1px solid red';
        } else {
            errors.notes = '';
        }

        if (!formData.check) {
            errors.check = '1px solid red';
        } else {
            errors.check = '';
        }

        setErrorMessage(errors)

        if (formData.notes && formData.check) {
            setErrorMessage({ ...errorMessage, ...errors });
            const reqData = {
                order_id: orderDetails?.id,
                order_status: formData.order_status,
                admin_note: formData.notes,
                is_send_sms: formData.check
            }
            dispatch(HandleUpdateCustomerNotes('update_order_status', reqData, setShowToast))
        }
    };

    return (
        <React.Fragment>
            <div className="sticky top-0 z-30">
                <Common.Header />
                <WorkspaceTabs />
            </div>

            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">

                            <div className="content">

                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            {/* <Common.BreadCrumb /> */}
                                        </h2>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        {orderDetailsResponse?.loading && orderDetailsResponse?.type === 'view_order_details' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            orderDetailsResponse?.error !== null && orderDetailsResponse?.type === 'view_order_details' && error === 'Order not found.' ?
                                                <div className="bg-white rounded-md mt-4">
                                                    <p className="text-gray-500">No Order Found.</p>
                                                </div>
                                                :
                                                orderDetailsResponse?.error !== null && orderDetailsResponse?.type === 'view_order_details' ?
                                                    <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                        <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                        <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                        <button onClick={() => window.location.reload()}
                                                            className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                            Please Try Again.
                                                        </button>
                                                    </div> :
                                                    orderDetailsResponse?.type === 'view_order_details'
                                                        ?
                                                        <div className="-mt-2 ">
                                                            <p className="text-2xl font-bold text-gray-700">Order #{orderDetails?.id} {orderDetails?.is_transfer === 1 ? 'Transfer Prescription' : 'New Prescription'}</p>
                                                            <p className="text-gray-700 rubik-400"><span className='text-gray-700 rubik-400 fs-16' style={{ textDecoration: 'bold' }}>Order Date:</span> <strong className='rubik-500 text-gray-600 fs-14'>
                                                                {orderDetails?.order_date} </strong> Patient IP : <strong className='rubik-500 text-gray-600 fs-14'>{orderDetails?.customer_ip}</strong></p>

                                                            <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-7 border p-4 shadow-md rounded-md mb-2">
                                                                {orderDetails?.order_prescriptions?.map((value, index) => {
                                                                    return (<React.Fragment key={index}>
                                                                        <img src={value.image} onClick={() => handleShowImage(value.image)} className='w-32 btnnn rounded-md bg-gray-100 p-2' alt={`img-index`} />
                                                                    </React.Fragment>)
                                                                })}
                                                            </div>

                                                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-2 border p-4 shadow-md rounded-md">
                                                                <div>
                                                                    <h3 className="text-gray-700 fs-14 rubik-500 flex" onClick={() => { return setViewOrderDetail({ detail: orderDetails, addressType: 'Billing' }), handleShowModal(setShowModal, showModal, 'addressModal') }}>Billing  &nbsp; <EditIcons /></h3>
                                                                    <address className="not-italic text-gray-600">
                                                                        <p className='text-gray-700 rubik-400 fs-14' dangerouslySetInnerHTML={{ __html: orderDetails?.billing_address?.full_address }}></p>
                                                                    </address>
                                                                </div>

                                                                <div>
                                                                    <h3 className="text-gray-700 fs-14 rubik-500 flex" onClick={() => { return setViewOrderDetail({ detail: orderDetails, addressType: 'Shipping' }), handleShowModal(setShowModal, showModal, 'addressModal') }}>Shipping &nbsp; <EditIcons /></h3>
                                                                    <address className="not-italic text-gray-600">
                                                                        <p className='text-gray-700 rubik-400 fs-14' dangerouslySetInnerHTML={{ __html: orderDetails?.shipping_address?.full_address }}></p>
                                                                    </address>
                                                                </div>

                                                                <div>
                                                                    <h3 className="text-gray-700 fs-14 rubik-500">Preferred Pharmacy</h3>
                                                                    <address className="not-italic text-gray-600">
                                                                        <p className='text-gray-700 rubik-400 fs-14'>{orderDetails?.patient_preferred_pharmacy?.name}</p>
                                                                        <p className='text-gray-700 rubik-400 fs-14'>P: {orderDetails?.patient_preferred_pharmacy?.phone}</p>
                                                                        <p className='text-gray-700 rubik-400 fs-14'>F: {orderDetails?.patient_preferred_pharmacy.fax}</p>
                                                                    </address>
                                                                </div>

                                                            </div>

                                                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6 mt-2 border p-4 shadow-md rounded-md">
                                                                <div>
                                                                    <h3 className="text-gray-700 fs-16 rubik-500">Customer Note</h3>
                                                                    <address className="not-italic text-gray-600">
                                                                        <p className='text-gray-700 rubik-400 fs-14'>{orderDetails?.customer_note}</p>
                                                                    </address>
                                                                </div>
                                                            </div>


                                                            <div className='flex gap-2'>

                                                                {orderDetails?.is_transfer === 1 &&

                                                                    <div style={{ width: '33%' }} className=" border p-4 shadow-md rounded-md scroll-box">
                                                                        <h3 className="text-gray-700 fs-16 rubik-500">Transfer From</h3>
                                                                        <div className="mt-2">
                                                                            <p className="text-gray-700 rubik-500 fs-14">Pharmacy Name:</p>
                                                                            <p className="text-gray-700 rubik-400 fs-14">{orderDetails?.order_transfer_pharmacy?.name}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-gray-700 rubik-500 fs-14">Phone Number:</p>
                                                                            <p className="text-gray-700 rubik-400 fs-14">{orderDetails?.order_transfer_pharmacy?.phone}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-gray-700 rubik-500 fs-14">Fax Number:</p>
                                                                            <p className="text-gray-700 rubik-400 fs-14">{orderDetails?.order_transfer_pharmacy?.fax}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-gray-700 rubik-500 fs-14">Address:</p>
                                                                            <p className="text-gray-700 rubik-400 fs-14">{orderDetails?.order_transfer_pharmacy?.address}
                                                                                {orderDetails?.order_transfer_pharmacy?.province} {orderDetails?.order_transfer_pharmacy?.zip_code}</p>
                                                                        </div>
                                                                        {/* <div>
                                                                            <p className="text-gray-700 rubik-500 fs-14">Date Of Transfer:</p>
                                                                            <p className="text-gray-700 rubik-400 fs-14">{orderDetails?.date_of_transfer}</p>
                                                                        </div> */}
                                                                    </div>
                                                                }

                                                                <div style={{ width: orderDetails?.is_transfer === 1 ? '33%' : '50%' }} className='mt-2 border p-4 shadow-md rounded-md'>
                                                                    <div>
                                                                        <h3 className="text-gray-700 fs-14 rubik-500 ">Order Status</h3>
                                                                        <DropdownSelect
                                                                            label=""
                                                                            name="order_status"
                                                                            formData={formData}
                                                                            setFormData={setFormData}
                                                                            options={
                                                                                orderDetails?.is_transfer === 0 ?
                                                                                    [
                                                                                        { value: "Processing", label: "Processing" },
                                                                                        { value: "Label Prepared", label: "Label Prepared" },
                                                                                        { value: "Local Delivery", label: "Local Delivery" },
                                                                                        { value: "Ready To Ship", label: "Ready To Ship" },
                                                                                        { value: "Shipped", label: "Shipped" },

                                                                                    ] : orderDetails?.is_transfer === 1 ?
                                                                                        [
                                                                                            { value: "Processing", label: "Processing" },
                                                                                            { value: "Send Fax", label: "Send Fax" },
                                                                                            { value: "Waiting Approval", label: "Waiting Approval" },
                                                                                            { value: "Fax Received", label: "Fax Received" },
                                                                                            { value: "Label Prepared", label: "Label Prepared" },
                                                                                            { value: "Local Delivery", label: "Local Delivery" },
                                                                                            { value: "Ready To Ship", label: "Ready To Ship" },
                                                                                            { value: "Shipped", label: "Shipped" },
                                                                                        ] : null
                                                                            }
                                                                        />
                                                                    </div>

                                                                    <div className='mt-2'>
                                                                        <h3 className="text-gray-700 fs-14 rubik-500 ">Add Customer Note</h3>
                                                                        <textarea
                                                                            defaultValue={formData.notes}
                                                                            style={{ border: errorMessage.notes }}
                                                                            maxLength="555"
                                                                            name='notes'
                                                                            onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                                                                            className="fs-14 w-full pl-2 h-14 rounded-md rubik-400 border border-gray-300 text-gray-700"
                                                                            id="reason"
                                                                        ></textarea>
                                                                    </div>

                                                                    <div className='mt-2'>
                                                                        <input type='checkbox' defaultValue={formData.check} defaultChecked={formData.check} style={{ border: errorMessage.check }}
                                                                            onChange={(e) => setFormData({ ...formData, check: e.target.checked })}
                                                                            id='check'
                                                                            className='fs-14 w-4 pl-2 h-4 rounded-md rubik-400 border border-gray-300 text-blue-700' />
                                                                        <label htmlFor='check' className='fs-14 text-gray-500 rubik-400 pt-2'> Send msg to Customer?</label>
                                                                    </div>

                                                                    <div className='mt-2'>
                                                                        <button type="button" onClick={handleUpdateNotes}
                                                                            className="bg-blue-500 fs-14 rubik-400 text-white px-4 py-2 rounded-md">
                                                                            {UpdateOrderStatusResponse?.loading && UpdateOrderStatusResponse?.type === 'update_order_status' ? 'Updating...' : 'Update'}
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <div style={{ width: orderDetails?.is_transfer === 1 ? '33%' : '50%', maxHeight: '276px', overflowY: 'auto' }} className='mt-2 border p-4 shadow-md rounded-md scroll-box '>
                                                                    <h3 className="text-gray-700 fs-14 rubik-500 ">Order Notes</h3>
                                                                    {orderNotesListResponse?.type === 'view_order_notes_list' && orderNotesList?.length > 0 &&
                                                                        orderNotesList?.map((value, index) => {
                                                                            return (<React.Fragment key={index}>
                                                                                <div className='bg-gray-100 mb-2 p-4 rounded-md'>
                                                                                    <p className='text-gray-500 rubik-400 fs-14'>{value.note}</p>
                                                                                    <p className='mt-2 text-gray-700 rubik-500 fs-12'>{value.note_value}</p>
                                                                                    <p className='mt-2 text-gray-400 rubik-400 fs-12'>Updated on
                                                                                        <span className='text-gray-500 rubik-500 fs-12 pl-1 pr-1'>{value.s_date}</span> at
                                                                                        <span className='text-gray-500 rubik-500 fs-12 pl-1 pr-1'>{value.s_time}</span>| By
                                                                                        <span className='text-gray-500 rubik-500 fs-12 pl-1 pr-1'>  {value.by}</span></p>
                                                                                </div>

                                                                            </React.Fragment>)
                                                                        })}
                                                                </div>
                                                            </div>

                                                            {formData.order_status === 'Label Prepared' && <CreateShipment order_id={orderDetails?.id} />}
                                                            {formData.order_status === 'Shipped' && <CreatePillReminder orderDetails={orderDetails} />}

                                                            {formData.order_status === 'Send Fax' && orderDetails?.is_transfer === 1 &&
                                                                <TransferPrescriptionSendFax order_id={orderDetails} />}

                                                        </div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id='overlay' style={{ zIndex: '9999999999' }}>
                <span id="close-btn" onClick={handleCloseOverlayImg}>&times;</span>
                {overlayImage &&
                    <img src={overlayImage} className="rounded-full" alt="Large Image" />
                }
            </div>

            <ChatBox />

            {showModal.addressModal &&
                <UpdateShipping_Billing_AddressModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    viewOrderDetail={viewOrderDetail}
                    orderDetails={orderDetails}
                />}

            {showToast.showToast && UpdateOrderStatusResponse.type === 'update_order_status' && <SuccessToastMessage status={'Status updated successfully'} />}
            {showToast.errorToast && updateOrderStatusError && UpdateOrderStatusResponse.type === 'update_order_status' && <ErrorToastMessage status={updateOrderStatusError} />}

            {showToast.showToast && UpdateOrderStatusResponse.type === 'update_address' && <SuccessToastMessage status={'Address updated successfully'} />}
            {showToast.errorToast && UpdateOrderStatusResponse.type === 'update_address' && <ErrorToastMessage status={'Got an issue while updating address please try again'} />}

        </React.Fragment>
    )
}

export default ViewOrderDetails