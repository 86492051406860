import React, { useState } from 'react'

import { AddVialsItems, BlistersItems, DispenseItems, InventoryItems, MedicationItems, menuItems } from '../../../constants/workspace'
import { useCustomNavigate } from '../../../hooks'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'
import * as routeNames from '../../../routes/routeName'

import Cookies from 'js-cookie'

const WorkspaceTabs = () => {

	const navigate = useCustomNavigate()
	const activeWorkTabs = parseInt(Cookies.get('work_tab'));

	const [activeDropdown, setActiveDropdown] = useState(null);
	const { pharmacyLoginUser, pharmacyLoginData, adminModule } = useCustomSelectors()

	const handleMouseEnter = (route) => {
		if (route.name === 'BLISTERS' || route.name === 'VIALS' || route.name === 'SPECIALITY MEDICATION' || route.name === 'DISPENSE' || route.name === 'INVENTORY') {
			setActiveDropdown(route.name);
		}
	};

	const handleMouseLeave = () => {
		setActiveDropdown(null);
	};

	const handlenNavigateSubItems = (route) => {
		setTimeout(() => {
			navigate(route)
		})
	}

	const handlenNavigateMenuItems = (route) => {
		if (route.name === 'BLISTERS' || route.name === 'VIALS' || route.name === 'SPECIALITY MEDICATION' || route.name === 'DISPENSE' || route.name === 'INVENTORY') {
			return
		} else {
			navigate(route.route)
		}
	}

	return (
		<>
			<div className="bg-gray-700">
				<div className="container mx-auto px-4 listActive">
					<ul className="flex items-center gap-1 justify-start ot_headerList">
						{menuItems?.map((item, index) => {
							const userPermissions = {
								'PATIENTS': pharmacyLoginUser?.pharmacy_patient,
								'FORMS': pharmacyLoginUser?.pharmacy_forms,
								'OAT': pharmacyLoginUser?.pharmacy_oat,
								'COMPOUND': pharmacyLoginUser?.pharmacy_compounds,
								'BLISTERS': pharmacyLoginUser?.pharmacy_blister,
								'ARV': pharmacyLoginUser?.pharmacy_arv,
								'VIALS': pharmacyLoginUser?.pharmacy_vials,
								'SPECIALITY MEDICATION': pharmacyLoginUser?.pharmacy_speciality_medication,
								'REVENUE': pharmacyLoginUser?.pharmacy_revinue_management,
								'DISPENSE': pharmacyLoginUser?.pharmacy_daily_dispense,
								'FDA FORM': pharmacyLoginUser?.pharmacy_fda,
								'DOCTOR': pharmacyLoginUser?.pharmacy_doctor_integration,
								'INVOICE': 'INVOICE',
								'INVENTORY': 'INVENTORY',
								'TRAVEL': 'TRAVEL',
							};

							if (userPermissions[item.name] === item?.PermissionStatus) {
								return (
									<React.Fragment key={index}>
										<li onClick={() => handlenNavigateMenuItems(item)}
											className='relative flex-0'
											onMouseEnter={() => handleMouseEnter(item)}
											onMouseLeave={() => handleMouseLeave()}>

											<a onClick={() => {
												return Cookies.set('work_tab', index),
													navigate(item.route)
											}}
												className={`${activeWorkTabs === index ? "active" : ""} hover:bg-gray-600 cursor-pointer px-3 py-3 fs-16 md:me-6 text-white-500 flex justify-center text-center`}
												style={{ color: 'white', fontWeight: '500' }}
											>
												{item.name}
											</a>

											{item.name === 'BLISTERS' && activeDropdown === 'BLISTERS' && (
												<div
													onClick={() => Cookies.set('work_tab', index)}
													onMouseEnter={() => handleMouseEnter(item)}
													onMouseLeave={() => handleMouseLeave()}
													className="dropdown-menu absolute left-0 w-48 bg-gray-700 shadow-lg"
												>
													<div className="z-20 w-full max-w-sm bg-white divide-y divide-gray-100 shadow-md dark:bg-gray-800 dark:divide-gray-700" >
														<div className="divide-y divide-gray-100 dark:divide-gray-700">
															{BlistersItems?.sort((a, b) => a.name.localeCompare(b.name))?.map((value, index) => (
																<React.Fragment key={index}>
																	<a
																		onClick={() => handlenNavigateSubItems(value.route)}
																		className="flex btnnn px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
																	>
																		<div className="w-full ps-3">
																			<div className="text-gray-500 font-semibold text-sm mb-1.5 dark:text-gray-400">{value.name}</div>
																		</div>
																	</a>
																</React.Fragment>
															))}
														</div>
													</div>
												</div>
											)}

											{item.name === 'VIALS' && activeDropdown === 'VIALS' && (
												<div
													onClick={() => Cookies.set('work_tab', index)}
													onMouseEnter={() => handleMouseEnter(item)}
													onMouseLeave={() => handleMouseLeave()}
													className="dropdown-menu absolute left-0 w-48 bg-gray-700 shadow-lg"
												>
													<div className="z-20 w-full max-w-sm bg-white divide-y divide-gray-100 shadow-md dark:bg-gray-800 dark:divide-gray-700" >
														<div className="divide-y divide-gray-100 dark:divide-gray-700">
															{AddVialsItems?.sort((a, b) => a.name.localeCompare(b.name))?.map((value, index) => (
																<React.Fragment key={index}>
																	<a
																		onClick={() => handlenNavigateSubItems(value.route)}
																		className="flex btnnn px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
																	>
																		<div className="w-full ps-3">
																			<div className="text-gray-500 font-semibold text-sm mb-1.5 dark:text-gray-400">{value.name}</div>
																		</div>
																	</a>
																</React.Fragment>
															))}
														</div>
													</div>
												</div>
											)}

											{item.name === 'SPECIALITY MEDICATION' && activeDropdown === 'SPECIALITY MEDICATION' && (
												<div
													onClick={() => Cookies.set('work_tab', index)}
													onMouseEnter={() => handleMouseEnter(item)}
													onMouseLeave={() => handleMouseLeave()}
													className="dropdown-menu absolute left-0 w-64 bg-gray-700 shadow-lg"
												>
													<div className="z-20 w-full max-w-sm bg-white divide-y divide-gray-100 shadow-md dark:bg-gray-800 dark:divide-gray-700" >
														<div className="divide-y divide-gray-100 dark:divide-gray-700">
															{MedicationItems?.sort((a, b) => a.name.localeCompare(b.name))?.map((value, index) => (
																<React.Fragment key={index}>
																	<a
																		onClick={() => handlenNavigateSubItems(value.route)}
																		className="flex btnnn px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
																	>
																		<div className="w-full ps-3">
																			<div className="text-gray-500 font-semibold text-sm mb-1.5 dark:text-gray-400">{value.name}</div>
																		</div>
																	</a>
																</React.Fragment>
															))}
														</div>
													</div>
												</div>
											)}

											{item.name === 'DISPENSE' && activeDropdown === 'DISPENSE' && (
												<div style={{ maxHeight: '400px', overflowX: 'auto' }}
													onClick={() => Cookies.set('work_tab', index)}
													onMouseEnter={() => handleMouseEnter(item)}
													onMouseLeave={() => handleMouseLeave()}
													className="dropdown-menu absolute left-0 w-64 bg-gray-700 shadow-lg"
												>
													<div className="z-20 w-full max-w-sm bg-white divide-y divide-gray-100 shadow-md dark:bg-gray-800 dark:divide-gray-700" >
														<div className="divide-y divide-gray-100 dark:divide-gray-700">
															{DispenseItems?.sort((a, b) => a.name.localeCompare(b.name))?.map((value, index) => (
																<React.Fragment key={index}>
																	{adminModule === 0 && value.name === 'Delivery Drivers' ? null :
																		<a
																			onClick={() => handlenNavigateSubItems(value.route)}
																			className="flex btnnn px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
																		>
																			<div className="w-full ps-3">
																				<div className="text-gray-500 font-semibold text-sm mb-1.5 dark:text-gray-400">{value.name}</div>
																			</div>
																		</a>}
																</React.Fragment>
															))}
														</div>
													</div>
												</div>
											)}

											{item.name === 'INVENTORY' && activeDropdown === 'INVENTORY' && (
												<div style={{ maxHeight: '400px', overflowX: 'auto' }}
													onClick={() => Cookies.set('work_tab', index)}
													onMouseEnter={() => handleMouseEnter(item)}
													onMouseLeave={() => handleMouseLeave()}
													className="dropdown-menu absolute left-0 w-64 bg-gray-700 shadow-lg"
												>
													<div className="z-20 w-full max-w-sm bg-white divide-y divide-gray-100 shadow-md dark:bg-gray-800 dark:divide-gray-700" >
														<div className="divide-y divide-gray-100 dark:divide-gray-700">
															{InventoryItems?.sort((a, b) => a.name.localeCompare(b.name))?.map((value, index) => (
																<React.Fragment key={index}>
																	<a
																		onClick={() => handlenNavigateSubItems(value.route)}
																		className="flex btnnn px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
																	>
																		<div className="w-full ps-3">
																			<div className="text-gray-500 font-semibold text-sm mb-1.5 dark:text-gray-400">{value.name}</div>
																		</div>
																	</a>
																</React.Fragment>
															))}
														</div>
													</div>
												</div>
											)}

										</li>

									</React.Fragment>
								);
							}
							return null;
						})}

						{pharmacyLoginData?.app_mode === 1 &&
							<li onClick={() => navigate(routeNames.pharmacy_order_transfer)}>
								<a onClick={() => Cookies.set('work_tab', 20)}
									className={`${activeWorkTabs === 20 ? "active" : ""} hover:bg-gray-600 cursor-pointer px-3 py-3 fs-16 md:me-6 text-white-500 flex justify-center text-center`}
									style={{ color: 'white', fontWeight: '500' }}
								>
									APP
								</a>
							</li>}

						{adminModule === 1 &&
							<li onClick={() => navigate(routeNames.pharmacy_timeoff)}>
								<a onClick={() => Cookies.set('work_tab', 21)}
									className={`${activeWorkTabs === 21 ? "active" : ""} hover:bg-gray-600 cursor-pointer px-3 py-3 fs-16 md:me-6 text-white-500 flex justify-center text-center`}
									style={{ color: 'white', fontWeight: '500' }}
								>
									TEAM
								</a>
							</li>}
					</ul>
				</div>
			</div>
		</>
	)
}

export default WorkspaceTabs