import {
	ADD_WALL_POST_LIST_USER_COMMENTS_REQUEST,
	ADD_WALL_POST_LIST_USER_COMMENTS_SUCCESS,
	ADD_WALL_POST_LIST_USER_COMMENTS_FAILURE
} from "../../constants";

import { pharmacy_user_comments } from "../../../networking/urlEndPoints";
import Axios from "../../../networking/intraceptor";

export const fetchUserCommentsRequest = () => ({
	type: ADD_WALL_POST_LIST_USER_COMMENTS_REQUEST,
});

export const fetchUserCommentsSuccess = (comments) => ({
	type: ADD_WALL_POST_LIST_USER_COMMENTS_SUCCESS,
	payload: comments
});

export const fetchUserCommentsFailure = (error) => ({
	type: ADD_WALL_POST_LIST_USER_COMMENTS_FAILURE,
	payload: error,
});

export const addPostComments = (id, comments, setLoading) => {
	return async (dispatch) => {
		dispatch(fetchUserCommentsRequest());
		try {
			const response = await Axios.post(`${pharmacy_user_comments}/${id}`, {
				comments: comments
			});
			if (response?.status === 200) {
				dispatch(fetchUserCommentsSuccess(response?.data))
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(fetchUserCommentsFailure(error?.response))
				if (typeof setLoading === 'function') {
					setLoading(false)
				}
			}
		}
	}
}
