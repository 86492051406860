import {
    UPDATE_RX_WITNESS_DOSE_REQUEST,
    UPDATE_RX_WITNESS_DOSE_SUCCESS,
    UPDATE_RX_WITNESS_DOSE_FAILURE,
} from '../../../constants';

import { pharmacy_update_wit_rx } from '../../../../networking/urlEndPoints'
import { fetchPatientPrescriptionDetail } from './pharmacyPatientPrescriptionsDetails';

import Axios from '../../../../networking/intraceptor'
import { searchByRxNumberArchivedlistData } from './searchByRxNumberArchivedList';

export const pharmacyUpdateRxWitnessDoseRequest = (type) => ({
    type: UPDATE_RX_WITNESS_DOSE_REQUEST,
    types: type
});

export const pharmacyUpdateRxWitnessDoseSuccess = (data, type) => ({
    type: UPDATE_RX_WITNESS_DOSE_SUCCESS,
    payload: data,
    types: type
});

export const pharmacyUpdateRxWitnessDoseFailure = (error, type) => ({
    type: UPDATE_RX_WITNESS_DOSE_FAILURE,
    payload: error,
    types: type
});

export const pharmacyPatientUpdateWitnessDose = (type, setShowModal, rxId, logId, newValue, patient_id, formData,rx_sheettype) => {
    return async (dispatch) => {
        dispatch(pharmacyUpdateRxWitnessDoseRequest(type));
        const requestData = {
            rxDataId: rxId,
            logId: logId,
            newValue: newValue
        }
        try {
            const response = await Axios.post(`${pharmacy_update_wit_rx}`, requestData)

            if (response?.status === 200) {
                dispatch(pharmacyUpdateRxWitnessDoseSuccess(response?.data, type));

                if (newValue && type === 'update_wit_dose') {
                    dispatch(fetchPatientPrescriptionDetail(patient_id, formData))
                }

                if (newValue && rx_sheettype === 'rx_sheet_update_wit_dose') {
                    dispatch(searchByRxNumberArchivedlistData(formData, 'rx_list'))
                }

                if (typeof setShowModal === "function" && type === 'update_wit_dose') {

                    setShowModal(prevState => ({
                        ...prevState,
                        witSuccess: true
                    }));

                    setTimeout(() => {
                        setShowModal(prevState => ({
                            ...prevState,
                            witSuccess: false
                        }));
                    }, 2000);
                }


            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowModal === "function" && type === 'update_wit_dose') {

                    setShowModal(prevState => ({
                        ...prevState,
                        witError: true
                    }));

                    setTimeout(() => {
                        setShowModal(prevState => ({
                            ...prevState,
                            witError: false
                        }));
                    }, 2000);
                }

                dispatch(pharmacyUpdateRxWitnessDoseFailure(error?.response?.data, type));
            }
        }
    }
}

