import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAILURE
} from "../../constants";

import { pharmacy_login_user } from '../../../networking/urlEndPoints'
import { fetchUserProfile } from './userProfileActions'
import { fetchTimesheetDates } from "../main/pharmacyTimesheetDatesActions";

import { addUserActivityStatus } from "../chat/activityStatusActions";
import { fetchProvinceList } from "../patients/provinceListsActions";
import { fetchPatientNotifications } from "../pharmacy/other/pharmacyPatientNotifications";
import { fetchTeamNotesMessage } from "../pharmacy/other";

import Cookies from "js-cookie";
import Axios from '../../../networking/intraceptor'

import * as routeNames from '../../../routes/routeName'
import { fetchAllRecentChats } from "../chat/recentChatsListsActions";
import { authPharmacyPermissions } from "./authPermissions";

export const loginRequest = () => ({
	type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
	type: LOGIN_SUCCESS,
	payload: user,
});

export const loginFailure = (error) => ({
	type: LOGIN_FAILURE,
	payload: error,
});

export const authLogin = (email, password, navigate, ip_address) => {
	return async (dispatch) => {
		dispatch(loginRequest());
		try {
			const response = await Axios.post(`${pharmacy_login_user}`, {
				email: email,
				password: password,
				// ip_address: ip_address
			});

			if (response?.status === 200) {
				Cookies.set('authToken', response?.data?.data?.token)
				dispatch(loginSuccess(response?.data?.data));
				dispatch(authPharmacyPermissions())
				dispatch(fetchUserProfile())
				dispatch(fetchTimesheetDates())
				dispatch(fetchProvinceList())
				dispatch(addUserActivityStatus('online'));
				dispatch(fetchPatientNotifications())
				dispatch(fetchTeamNotesMessage())
				dispatch(fetchAllRecentChats())
				navigate(routeNames.DASHBOARD_ROUTE)
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(loginFailure(error?.response));
			}
		}
	}
}


export const HandleVerifyOtp = (email, password, navigate, otp) => {
	return async (dispatch) => {
		dispatch(loginRequest());
		try {
			const response = await Axios.post(`${pharmacy_login_user}`, {
				email: email,
				password: password,
				otp: otp
			});

			if (response?.status === 200) {
				Cookies.set('authToken', response?.data?.data?.token)
				dispatch(loginSuccess(response?.data?.data));
				dispatch(authPharmacyPermissions())
				dispatch(fetchUserProfile())
				dispatch(fetchTimesheetDates())
				dispatch(fetchProvinceList())
				dispatch(addUserActivityStatus('online'));
				dispatch(fetchPatientNotifications())
				dispatch(fetchTeamNotesMessage())
				dispatch(fetchAllRecentChats())
				navigate(routeNames.DASHBOARD_ROUTE)
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(loginFailure(error?.response));
			}
		}
	}
}