import {
	FETCH_PATIENTS_LIST_REQUEST,
	FETCH_PATIENTS_LIST_SUCCESS,
	FETCH_PATIENTS_LIST_FAILURE
} from "../../constants"

import { pharmacy_patient_list } from "../../../networking/urlEndPoints"

import Axios from "../../../networking/intraceptor"

export const patientListRequest = () => ({
	type: FETCH_PATIENTS_LIST_REQUEST,
})

export const patientListSuccess = (data) => ({
	type: FETCH_PATIENTS_LIST_SUCCESS,
	payload: data,
})

export const patientListFailure = (error) => ({
	type: FETCH_PATIENTS_LIST_FAILURE,
	payload: error,
})

export const fetchPatientsList = (search, status, page) => {
	return async (dispatch) => {
		dispatch(patientListRequest())

		const params = {
			no_of_records: 10,
			status: status,
			page: page
		}

		if (search) {
			params.search = search
		}

		if (page) {
			params.page = page
		}

		if (status) {
			params.status = status
		}

		try {
			const response = await Axios.get(`${pharmacy_patient_list}`, { params })
			if (response?.status === 200) {
				dispatch(patientListSuccess(response?.data))
			} else {
				throw new Error(response)
			}
		} catch (error) {
			if (error?.response) {
				dispatch(patientListFailure(error?.response))
			}
		}
	}
}
