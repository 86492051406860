import React, { useEffect, useState } from "react";

import { useCustomDispatch, useCustomLocation } from "../../hooks";

import {
	handleFormPatientNoteChange,
	handleRecurrenceChange,
} from "../../utils/data/handleFormDataChange";

import { hidePatientNoteModal } from "../../assets/js/custome";
import { ModalCloseIcon } from "../../assets/svg/icons";
import { useCustomPatientSelectors } from "../../services/selectors/patientSelectors";
import { customStyles } from "../../style";

import { PatientList } from "../../components/pharmacy/form/form";
import { fetchPatientNotesList } from "../../services/actions/patients/patientNotesList";
import { savePatientNotes } from "../../services/actions/patients/savePatientNote";
import { submitPatientNote } from "../../utils/validations/others";

import Select from "react-select";

import * as initial from "../../utils/constants/formInitialStates";

const AddNotes = ({
	setShowModal,
	showModal,
	patientProfileData,
	paginationNumber,
	showToast,
	setShowToast,
	queryStatus,
	querySearch,
	type,
}) => {
	const dispatch = useCustomDispatch();
	const location = useCustomLocation();

	const {
		savePatientListResponse,
		patientListNotesData,
		patientListNotesResponse,
	} = useCustomPatientSelectors();

	const [patientNotesData, setPatientNoteData] = useState([]);
	const [selectedNoteOptions, setSelectedNoteOptions] = useState([]);
	const [color, setColor] = useState('#FFFFFF')

	const [notesData, setNotesData] = useState(initial.initalPatientNotes);
	const [formData, setFormData] = useState(initial.initalTopHeaderAddNoteData);

	const [errorMessages, setErrorMessages] = useState(
		initial.initalTopHeaderAddNoteData
	);

	const handleSelectChange = (selectedValues) => {
		setSelectedNoteOptions(selectedValues);
		setNotesData({
			...notesData,
			notes: selectedValues.data,
			selectedNoteOptions: selectedValues?.label,
		});
	};

	useEffect(() => {
		const notesData = patientListNotesData;
		const formattedNotesData = notesData?.map((value, index) => ({
			label: value.selectednoteoption,
			value: index,
			data: value.notes,
		}));

		setPatientNoteData(formattedNotesData);
	}, [patientListNotesData]);

	const handlePetientNotesList = () => {
		if (patientListNotesResponse?.data === null) {
			dispatch(fetchPatientNotesList());
		}
	};

	const handleColorChange = (event) => {
		setColor(event.target.value);
	};

	return (
		<>
			<div
				className="overflow-y-auto overflow-x-hidden fixed top-0 left-0 right-0 bottom-0 z-50 flex  items-center 
							justify-center w-full bg-black bg-opacity-50 dark:bg-opacity-100 max-h-full"
			>
				<div className="relative p-4 w-full max-w-2xl max-h-full">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
							<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
								Patient Notes
							</h3>
							<button
								onClick={() => hidePatientNoteModal(setShowModal, showModal)}
								type="button"
								className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
							>
								<ModalCloseIcon />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						{type === "Header_Add_Note" && (
							<div className="pt-4 md:pl-5 md:pr-5 relative z-20">
								<PatientList
									formData={formData}
									setFormData={setFormData}
									errorMessages={errorMessages}
									setErrorMessages={setErrorMessages}
								/>
							</div>
						)}

						<div className="md:p-5 space-y-4 ot_addNoteText z-10 relative">
							<div
								className="relative did-floating-label-content"
								onClick={handlePetientNotesList}
							>
								<Select
									isClearable={false}
									options={patientNotesData}
									isSearchable
									value={selectedNoteOptions}
									onChange={handleSelectChange}
									className="basic-multi-select z-20"
									classNamePrefix="react-select"
									styles={customStyles}
									placeholder="Select From Note Option"
									noOptionsMessage={() =>
										patientListNotesResponse?.loading
											? "Loading..."
											: "No options"
									}
								/>
							</div>

							<div className="did-floating-label-content">
								<label
									htmlFor="Phn"
									className="ml-2 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
												-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
												peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
												peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
								>
									Notes
								</label>

								<div
									id="cancellationNotesContainer"
									className="form-group full"
								>
									<textarea
										maxLength="255"
										onChange={(e) =>
											handleFormPatientNoteChange(e, setNotesData, notesData)
										}
										value={notesData.notes}
										name="notes"
										className="fs-18 rubik-400 border border-gray-300 text-gray-900"
										id="cancellationNotes"
									></textarea>
								</div>
							</div>

							<div className="flex items-center mb-4">
								<input
									id="default-checkbox"
									type="checkbox"
									onChange={(e) =>
										handleRecurrenceChange(e, setNotesData, notesData)
									}
									value={notesData.recurrence}
									className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
								/>
								<label
									htmlFor="default-checkbox"
									className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
								>
									Recurrence
								</label>

								<label className="ml-2">
									<input
										type="color"
										value={color}
										onChange={handleColorChange}
									/>
								</label>

							</div>

							{notesData?.recurrence === 1 && (
								<div className="mb-2 sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-6">
									<div className="relative">
										<input
											type="text"
											id="remind"
											className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
														rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
														focus:outline-none focus:ring-0 focus:border-blue-600 peer"
											onChange={(e) =>
												handleFormPatientNoteChange(e, setNotesData, notesData)
											}
											value={notesData.remind}
											name="remind"
											placeholder=" "
											onKeyPress={(e) => {
												if (
													isNaN(String.fromCharCode(e.charCode)) ||
													e.target.value.length >= 10
												) {
													e.preventDefault();
												}
											}}
										/>
										<label
											htmlFor="remind"
											className="ml-2  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
														-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
														peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
														peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
										>
											Remind in Days
										</label>
									</div>
								</div>
							)}
						</div>



						{notesData?.message !== null && (
							<div
								className="flex items-center justify-center fs-12"
								style={{ color: "red" }}
							>
								{notesData?.message}
							</div>
						)}

						<div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
							<div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
								<div className="col-span-12">
									<button
										type="submit"
										onClick={() =>
											type === "Header_Add_Note"
												? (() => {
													if (!formData.patientName) {
														setErrorMessages({
															...errorMessages,
															patientName: "Please Select the Patient",
														});
													} else if (!selectedNoteOptions.label) {
														setNotesData({
															...notesData,
															message: "Please Select the Note",
														});
													} else {
														setNotesData({ ...notesData, message: "" });
														dispatch(
															savePatientNotes(
																formData?.patientName?.value,
																notesData,
																showToast,
																setShowToast,
																paginationNumber,
																location,
																showModal,
																setShowModal,
																queryStatus,
																querySearch,
																color
															)
														);
													}
												})()
												: submitPatientNote(
													selectedNoteOptions,
													setNotesData,
													notesData,
													patientProfileData,
													setShowToast,
													showToast,
													paginationNumber,
													location,
													showModal,
													setShowModal,
													dispatch,
													queryStatus,
													querySearch,
													color
												)
										}
										className="rubik-500 fs-16 h-12 text-white blueBg-color w-full text-sm px-5 py-2.5"
										style={{ position: "relative", overflow: "hidden" }}
									>
										{savePatientListResponse?.loading ? "Saving..." : "Save"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default AddNotes;
