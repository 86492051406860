import React, { useState } from 'react';

import { useCustomNavigate } from '../../hooks';
import { useCustomSelectors } from '../../services/selectors/allSelectors';
import { chatUserListLoader } from '../../utils/helper/helper';

import Cookies from 'js-cookie';
import InitatedChatBox from './initatedChatBox';

import * as routNames from '../../routes/routeName';

export const RecentChats = ({
	openChatbox,
	employeesOnlineStatus,
	selectedEmployeeId,
	setMyInputMessages,
	setChatAppenedMessages,
	setChatInput,
	setAllGroupMessages,
	allGroupMessages,
	chatInput,
	myInputMessages,
	chatAppenedMessages,
	openOverlay,
	closeOverlay,
	setSelectedEmployeeId,
	recentChatLoading,
}) => {

	const {
		profileData,
		recentChatList } = useCustomSelectors()

	const recentChatsResponse = recentChatList?.data?.latest_messages

	const [chatValue, setChatValue] = useState(null);

	return (
		<>
			<ul className="overflow-y-auto m-3">
				{
					recentChatLoading ? <>
						{chatUserListLoader?.map((item) => (
							<li key={item} className="py-2 border-b btnnn z-10">
								<div className="flex gap-3 items-start">
									<div className="animate-pulse flex gap-2 w-full">
										<svg className="w-12 h-12 text-gray-200 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
											<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
										</svg>
										<div>
											<div className="h-5 bg-gray-300 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
											<div className="w-48 h-3 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></div>
										</div>
									</div>
								</div>
							</li>
						))
						} </>

						:
						recentChatsResponse?.length === 0 ?
							<div>
								<h4 className="rubik-500 fs-16 dark-color">
									No results found.
								</h4>
							</div>
							:
							recentChatList?.error !== null ?
								<div>
									<h4 className="rubik-500 fs-16 dark-color">
										Something Went wrong please try again later.
									</h4>
								</div>
								:
								recentChatsResponse?.map((value, index) => {

									return (
										<React.Fragment key={index}>
											{
												profileData?.user_id === value?.user_id ? null :
													<>
														<li className="py-2 border-b btnnn">
															<div className="flex gap-3 items-start" onClick={() => {
																setChatValue(value)
																openChatbox(value)
															}}>
																<div className="relative">
																	<img src={value.thumbnail} alt="" className='max-w-none w-12 h-12 rounded-full bg-light' />
																	{value?.group_type === 'one_to_many' ? null :

																		value.online_status === 'online' ?
																			<span className="bottom-0 left-9 absolute  w-3.5 h-3.5 bg-green-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
																			: value.online_status === 'offline' ?
																				<span className="bottom-0 left-9 absolute  w-3.5 h-3.5 bg-red-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
																				: value.online_status === 'idle' ?
																					<span className="bottom-0 left-9 absolute  w-3.5 h-3.5 bg-yellow-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
																					: null
																	}

																</div>
																<div className="text-left" >
																	<h4 className="rubik-500 fs-16 dark-color">{value?.name}</h4>
																	<div className='flex'>
																		{
																			value?.latest_message === null ? null : <>
																				{
																					value?.latest_message?.message_media?.length !== 0 ?
																						<p className="rubik-400 fs-16 dark-color flex">
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
																							</svg>
																							<span style={{ marginLeft: '3px' }}>Photo</span>
																						</p>
																						:

																						value?.latest_message?.media_pdf?.length !== 0 ?
																							<p className="rubik-400 fs-16 dark-color flex">
																								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																									<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
																								</svg>
																								<span style={{ marginLeft: '3px' }}>Attachement</span>
																							</p>
																							:
																							<p className="rubik-400 fs-16 dark-color break-all">
																								{value?.latest_message?.message_text?.split(' ')?.slice(0, 3)?.join(' ')}
																							</p>
																				}
																			</>
																		}
																	</div>
																</div>

																{
																	value?.latest_message === null ? null : <>
																		<p className="rubik-400 fs-14 dark-color ml-auto">
																			{value?.latest_message.update_time}
																		</p>
																	</>
																}

																{value.update_time}

															</div>

															{value?.unread_count === '0' || value?.unread_count === 0 ? null :
																profileData?.user_id !== value?.user_id ?
																	<p className=" left-9 w-6 h-6 bg-green-500 border-2 fs-12 border-white dark:border-gray-800 ml-auto" style={{ color: 'white', textAlign: 'center', borderRadius: '100%', marginTop: '-20px' }}>{value?.unread_count}</p> : null
															}


														</li>
													</>
											}
										</React.Fragment>

									)
								})}
			</ul >
			<InitatedChatBox value={chatValue}
				selectedEmployeeId={selectedEmployeeId}
				profileData={profileData}
				employeesOnlineStatus={employeesOnlineStatus}
				setMyInputMessages={setMyInputMessages}
				setChatAppenedMessages={setChatAppenedMessages}
				setChatInput={setChatInput}
				setAllGroupMessages={setAllGroupMessages}
				allGroupMessages={allGroupMessages}
				chatInput={chatInput}
				myInputMessages={myInputMessages}
				chatAppenedMessages={chatAppenedMessages}
				openOverlay={openOverlay}
				closeOverlay={closeOverlay}
				setSelectedEmployeeId={setSelectedEmployeeId}
			/>
		</>
	)
}


export const ResponsiveRecentChatsView = ({
	recentChatLoading,
}) => {

	const navigate = useCustomNavigate()
	const { profileData, recentChatList } = useCustomSelectors()

	const recentChatsResponse = recentChatList?.data?.latest_messages

	return (
		<>
			<ul className="overflow-y-auto m-3">
				{

					recentChatLoading ? <>
						{
							chatUserListLoader?.map((item) => (
								<li key={item} className="py-2 border-b btnnn z-10">
									<div className="flex gap-3 items-start">
										<div className="animate-pulse flex gap-2 w-full">
											<svg className="w-12 h-12 text-gray-200 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
												<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
											</svg>
											<div>
												<div className="h-5 bg-gray-300 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
												<div className="w-48 h-3 bg-gray-200 rounded-full dark:bg-gray-700 mb-3"></div>
											</div>
										</div>
									</div>
								</li>
							))
						} </>
						:
						recentChatsResponse?.length === 0 ?
							<div>
								<h4 className="rubik-500 fs-16 dark-color">
									No results found.
								</h4>
							</div>
							:
							recentChatList?.error !== null ?
								<div>
									<h4 className="rubik-500 fs-16 dark-color">
										Something Went wrong please try again later.
									</h4>
								</div>
								:
								recentChatsResponse?.map((value, index) => {
									return (<React.Fragment key={index}>
										{
											profileData?.user_id === value?.user_id ? null :
												<>
													<li className="py-2 border-b btnnn">
														<div className="flex gap-3 items-start" onClick={() => { return navigate(`${routNames.CHAT_NOW}/${value.group_id}`), Cookies.remove('previous') }}>
															<div className="relative" >
																<img src={value.thumbnail} alt="" className='max-w-none w-12 h-12 rounded-full bg-light' />
																{
																	value.online_status === 'online' ?
																		<span className="bottom-0 left-9 absolute  w-3.5 h-3.5 bg-green-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
																		: value.online_status === 'offline' ?
																			<span className="bottom-0 left-9 absolute  w-3.5 h-3.5 bg-red-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
																			: value.online_status === 'idle' ?
																				<span className="bottom-0 left-9 absolute  w-3.5 h-3.5 bg-yellow-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
																				: null
																}
															</div>

															<div className="text-left" >
																<h4 className="rubik-500 fs-16 dark-color">{value?.name}</h4>
																<div className='flex'>
																	{
																		value.latest_message === null ? null : <>
																			{
																				value?.latest_message?.message_media?.length !== 0 ?
																					<p className="rubik-400 fs-16 dark-color flex">
																						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																							<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
																						</svg>
																						<span style={{ marginLeft: '3px' }}>Photo</span>
																					</p>
																					:
																					value?.latest_message?.media_pdf?.length !== 0 ?
																						<p className="rubik-400 fs-16 dark-color flex">
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
																							</svg>

																							<span style={{ marginLeft: '3px' }}>Attachement</span>
																						</p>
																						:
																						<p className="rubik-400 fs-16 dark-color break-all">
																							{value?.latest_message?.message_text?.split(' ')?.slice(0, 3)?.join(' ')}
																						</p>
																			}
																		</>
																	}
																</div>
															</div>

															{
																value?.latest_message === null ? null : <>
																	<p className="rubik-400 fs-14 dark-color ml-auto">
																		{value?.latest_message.update_time}
																	</p>
																</>
															}
															{value.update_time}
														</div>

														{value?.unread_count === '0' || value?.unread_count === 0 ? null :
															profileData?.user_id !== value?.user_id ?
																<p className=" left-9 w-6 h-6 bg-green-500 border-2 fs-12 border-white dark:border-gray-800 ml-auto" style={{ color: 'white', textAlign: 'center', borderRadius: '100%', marginTop: '-20px' }}>{value?.unread_count}</p> : null
														}

													</li>
												</>
										}
									</React.Fragment>

									)
								})}
			</ul>
		</>
	)
}