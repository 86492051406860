import React, {
    useEffect,
    useRef,
    useState,
    useCallback
} from "react";

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    convertToPixelCrop,
} from 'react-image-crop'

import { useCustomDispatch } from "../../hooks";
import { handleHideModal } from "../../assets/js/custome";
import { ModalCloseIcon } from "../../assets/svg/icons";
import { useCustomPatientSelectors } from "../../services/selectors/patientSelectors";

import { SuccessToastMessage } from '../../components/loaders/toastMessage'
// import { initalShowToastMessage } from "../../utils/constants/formInitialStates";

import { canvasPreview } from "../../utils/helper/imageCanvasPreview";
import { useDebounceEffect } from "../../utils/helper/useDebounceEffect";
import { handleReuploadPrescription } from "../../services/actions/pharmacy/dispense/dispenseceCustom";

import Axios from '../../networking/intraceptor'

import * as path from '../../networking/urlEndPoints'
import * as Common from "../../components/common";
import * as useEffectHelper from "../../utils/helper/useEffectsHelper";

import * as pdfjsLib from 'pdfjs-dist/webpack';
import 'pdfjs-dist/web/pdf_viewer.css';
import 'react-image-crop/dist/ReactCrop.css'

export const ViewUploadPrescriptionImage = ({
    setShowModal,
    showModal,
    arvID,
}) => {

    const modalRef = useRef(null);

    useEffectHelper.useRemoveModalEffect(setShowModal, showModal, "ViewUploadPrescriptionModal", modalRef)

    const [fetchedImage, setFetchedImage] = useState(null);

    const handleFetchImage = async (arvID) => {

        setFetchedImage(null)
        if (arvID === "" || arvID === null) {
            return
        }
        arvID = arvID[0]
        try {
            const response = await Axios.post(`${path.show_upload_image}`,
                {
                    triplicateId: arvID,
                    type: 'image',
                })

            setFetchedImage(response?.data?.data)
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    }

    useEffect(() => {
        handleFetchImage(arvID);
    }, [arvID]);

    useEffect(() => {
        setFetchedImage(null)

    }, [showModal.ViewUploadPrescriptionModal]);

    return (
        <>
            {showModal.ViewUploadPrescriptionModal && (
                <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">

                    <div ref={modalRef} className="relative w-full max-h-full" style={{ maxWidth: "45rem" }}>
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex">

                            </div>
                            <button
                                onClick={() => {
                                    setFetchedImage(null);
                                    handleHideModal(setShowModal, showModal, "ViewUploadPrescriptionModal");
                                }}

                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                <ModalCloseIcon />
                            </button>

                            <div className="p-4  text-center flex justify-center items-center " style={{
                                overflow: "auto",
                                height: "calc(100vh - 97px)", marginTop: "45px"
                            }}>
                                {fetchedImage !== null ? (
                                    <img style={{ width: "100%", height: "auto" }} src={fetchedImage} alt="Fetched Image" />
                                ) : (
                                    <div>
                                        <Common.LoadingView />
                                    </div>
                                )}
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};


export const ViewUploadRePrescriptionImage = ({
    setShowModal,
    showModal,
    imgSrc
}) => {

    const modalRef = useRef(null);

    useEffectHelper.useRemoveModalEffect(
        setShowModal,
        showModal,
        "UploadPrescriptionModal",
        modalRef
    )

    return (
        <>
            {showModal.UploadPrescriptionModal && (
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				    overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">

                    <div ref={modalRef} className="relative w-full max-w-xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex">
                                <h2></h2>
                            </div>
                            <button
                                onClick={() =>
                                    handleHideModal(setShowModal, showModal, "UploadPrescriptionModal")
                                }
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                <ModalCloseIcon />
                            </button>

                            <div className="p-6 text-center pt-12">
                                <img style={{ width: "100%", height: "auto" }} src={imgSrc} />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export const ViewUploadPrescriptionImageAfterSave = ({
    setShowModal,
    showModal,
    imgSrc
}) => {
    const modalRef = useRef(null);
    useEffectHelper.useRemoveModalEffect(
        setShowModal,
        showModal,
        "UploadPrescriptionModal",
        modalRef
    );
    return (
        <>
            {showModal.UploadPrescriptionModal && (
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
				overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                >
                    <div ref={modalRef} className="relative w-full max-w-xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex">
                                <h2></h2>
                            </div>
                            <button
                                onClick={() =>
                                    handleHideModal(setShowModal, showModal, "UploadPrescriptionModal")
                                }
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              							text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                <ModalCloseIcon />
                            </button>

                            <div className="p-6 text-center pt-12">
                                <img style={{ width: "100%", height: "auto" }} src={imgSrc} />
                                <br />


                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}
export const ReUploadPrescriptionImage = ({
    setShowModal,
    showModal,
    arvID,
    formData,
    pageNumber,
    setImgSrcs
}) => {

    const dispatch = useCustomDispatch();

    const { uploadPresImageResponse } = useCustomPatientSelectors();

    const modalRef = useRef(null);
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const blobUrlRef = useRef('')

    const [imgSrc, setImgSrc] = useState(setImgSrcs)
    const [pages, setPages] = useState([]); // State to hold array of page data URLs
    const [currentPage, setCurrentPage] = useState(1); // State to track current page number

    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)

    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(16 / 9) //set useState(16 / 9) if want dragable by default
    const [imgSrcBlob, setImgSrcBlob] = useState('')

    const [showPopUp, setShowPopUp] = useState(false)
    const [orignalimg, setOrignalimg] = useState()
    const [showOrignalimg, setShowOrignalimg] = useState('')

    const rotateLeft = () => {
        setRotate((prevRotate) => Math.max(prevRotate - 1, -180));
    };

    const rotateRight = () => {
        setRotate((prevRotate) => Math.min(prevRotate + 1, 180));
    };

    const onSelectFile = (e) => {
        setRotate(0)

        setOrignalimg("")
        handleResetCrop()

        setCompletedCrop(convertToPixelCrop({
            "unit": "px",
            "x": 0,
            "y": 221.5625000000001,
            "width": 381.5,
            "height": 171.875
        }))
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const fileType = file.type;

            if (fileType === 'application/pdf') {
                handlePdfFile(file);
                handleResetCrop()
            } else if (fileType.startsWith('image/')) {
                handleImageFile(file);
                handleResetCrop()
            } else {
                alert('Unsupported file type. Please upload an image or PDF.');
            }
        }
    };

    const handleResetCrop = () => {
        if (imgRef.current) {

            const { naturalWidth, naturalHeight } = imgRef.current;
            const aspectRatio = 16 / 9;
            setImgSrcBlob('');
            setCrop(centerAspectCrop(naturalWidth, naturalHeight, aspectRatio));
            forceResetCropRectangle()
            setScale(1);
            setRotate(0);
            setCompletedCrop(convertToPixelCrop({
                "unit": "px",
                "x": 0,
                "y": 221.5625000000001,
                "width": 381.5,
                "height": 171.875
            }))

        }
    };


    const handleImageFile = (file) => {
        setPages("")
        handleResetCrop()// Makes crop preview update between images.
        const reader = new FileReader();
        //converting to base64
        reader.onloadend = () => {
            // The result property contains the data as a base64 encoded string
            const base64String = reader.result;
            setOrignalimg(base64String);
        };

        reader.addEventListener('load', () =>
            setImgSrc(reader.result?.toString() || '')
        );
        reader.readAsDataURL(file);
    };


    const handlePdfFile = async (file) => {
        handleResetCrop()

        const reader = new FileReader();

        reader.onload = async () => {
            const pdfData = new Uint8Array(reader.result);
            const loadingTask = pdfjsLib.getDocument({ data: pdfData });
            const pdf = await loadingTask.promise;

            // Initialize array to hold page data URLs
            const pageUrls = [];

            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                const page = await pdf.getPage(pageNum);
                const viewport = page.getViewport({ scale: 2.5 });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                await page.render(renderContext).promise;
                const imageDataUrl = canvas.toDataURL();

                pageUrls.push(imageDataUrl);
                if (pageNum === 1) {

                    forceResetCropRectangle()

                }
            }
            setImgSrc(pageUrls[0]) //by default we are setting to index 0 could fount any other
            setOrignalimg(pageUrls[0])
            setPages(pageUrls); // Set state with array of page data URLs
            setCurrentPage(1); // Set initial current page

        };

        reader.readAsArrayBuffer(file);
        setImgSrc(1)
    };


    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
            setAspect(undefined)
        }
    }

    // async function onDownloadCropClick() {
    //     const image = imgRef.current

    //     const previewCanvas = previewCanvasRef.current
    //     if (!image || !previewCanvas || !completedCrop) {
    //         throw new Error('Crop canvas does not exist')
    //     }


    //     // This will size relative to the uploaded image
    //     // size. If you want to size according to what they
    //     // are looking at on screen, remove scaleX + scaleY
    //     const scaleX = image.naturalWidth / image.width
    //     const scaleY = image.naturalHeight / image.height

    //     const offscreen = new OffscreenCanvas(
    //         completedCrop.width * scaleX,
    //         completedCrop.height * scaleY,
    //     )
    //     const ctx = offscreen.getContext('2d')
    //     if (!ctx) {
    //         throw new Error('No 2d context')
    //     }

    //     ctx.drawImage(
    //         previewCanvas,
    //         0,
    //         0,
    //         previewCanvas.width,
    //         previewCanvas.height,
    //         0,
    //         0,
    //         offscreen.width,
    //         offscreen.height,
    //     )
    //     // You might want { type: "image/jpeg", quality: <0 to 1> } to
    //     // reduce image size
    //     const blob = await offscreen.convertToBlob({
    //         type: 'image/png',
    //     })
    //     console.log(blob)

    //     if (blobUrlRef.current) {
    //         URL.revokeObjectURL(blobUrlRef.current)
    //     }
    //     blobUrlRef.current = URL.createObjectURL(blob)
    //     console.log("hi" + blobUrlRef.current)
    //     // if (hiddenAnchorRef.current) {
    //     //     hiddenAnchorRef.current.href = blobUrlRef.current
    //     //     hiddenAnchorRef.current.click()
    //     // }
    // }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                const base64coded = await canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
                setImgSrcBlob(base64coded)
            }
        },
        100,
        [completedCrop, scale, rotate],
    )


    const updateImgSrcBlob = useCallback(async () => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
            // Call your canvasPreview function or any logic to update imgSrcBlob
            const base64coded = await canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                scale,
                rotate,
            );
            setImgSrcBlob(base64coded);
        }
    }, [completedCrop, scale, rotate]);


    useDebounceEffect(updateImgSrcBlob, 100, [completedCrop, scale, rotate]);

    // function handleToggleAspectClick() {
    //     if (aspect) {

    //         setAspect(undefined)
    //     } else {
    //         setAspect(16 / 9)

    //         if (imgRef.current) {
    //             const { width, height } = imgRef.current
    //             const newCrop = centerAspectCrop(width, height, 16 / 9)
    //             setCompletedCrop(convertToPixelCrop(newCrop, width, height))
    //             setCrop(newCrop)
    //             // Updates the preview

    //         }
    //     }
    // }

    const setViewImage = () => {
        setShowPopUp(true);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber); // Update current page state
    };

    function forceResetCropRectangle() {
        setCompletedCrop(convertToPixelCrop({
            "unit": "px",
            "x": 0,
            "y": 221.5625000000001,
            "width": 381.5,
            "height": 171.875
        }))
    }

    return (
        <>
            {showModal.ReUploadPrescriptionModal && (
                <div>
                    <div
                        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
                    overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                    >

                        <div ref={modalRef} className="relative w-full  max-h-full" style={{ maxWidth: "60rem" }}>
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    onClick={() => {
                                        setPages([])
                                        forceResetCropRectangle()
                                        setImgSrc("");
                                        handleHideModal(setShowModal, showModal, "ReUploadPrescriptionModal");
                                    }}
                                    type="button"
                                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
                                    text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <ModalCloseIcon />
                                </button>


                                <div className="p-6 text-center pt-12">
                                    <div className="App mt-2">

                                        <div className="Crop-Controls mb-3">
                                            {imgSrc && (
                                                <div className="flex items-center justify-center">
                                                    <div><button
                                                        onClick={() => {
                                                            handleResetCrop()

                                                        }}
                                                        disabled={!imgSrc} type="button" style={{ width: "100px", }} className="ml-2 bg-red-500  sm:text-center md:text-start 
                                                    lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">Reset</button></div>

                                                    <div>
                                                        <button className="ml-2 bg-yellow-500 sm:text-center md:text-start lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center"
                                                            onClick={rotateLeft} disabled={!imgSrc}>
                                                            Rotate Left
                                                        </button>

                                                    </div>
                                                    <div><button className="ml-2 bg-yellow-500 sm:text-center md:text-start lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center"
                                                        onClick={rotateRight} disabled={!imgSrc}>
                                                        Rotate Right
                                                    </button></div>
                                                    <div><button
                                                        onClick={() => {
                                                            setShowPopUp(true);
                                                            setShowOrignalimg(true);
                                                        }}
                                                        disabled={!imgSrc} type="button" style={{ width: "100px", opacity: "0.9", background: 'oragne' }} className="ml-2 blueBg-color  sm:text-center md:text-start 
 lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">Original</button></div>
                                                    {/* <div><button disabled={!imgSrc} type="button" style={{ width: "100px" }} className="ml-2 blueBg-color  sm:text-center md:text-start 
 lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">Rotate left</button></div>
                                                <div><button    disabled={!imgSrc} type="button" style={{ width: "100px" }} className="ml-2 blueBg-color  sm:text-center md:text-start 
 lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">Rotate right</button></div> */}

                                                    <div><button
                                                        disabled={!imgSrc || !imgSrcBlob}
                                                        type="button"
                                                        style={{ width: "100px" }}
                                                        className="ml-2 bg-green-500 sm:text-center md:text-start lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center"
                                                        onClick={setViewImage}
                                                    >
                                                        Crop
                                                    </button>
                                                    </div>

                                                    {/* <div>
                                                <label htmlFor="scale-input">Scale: </label>
                                                <input
                                                    id="scale-input"
                                                    type="number"
                                                    step="0.1"
                                                    value={scale}
                                                    disabled={!imgSrc}
                                                    onChange={(e) => setScale(Number(e.target.value))}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="rotate-input">Rotate: </label>
                                                <input
                                                    id="rotate-input"
                                                    type="number"
                                                    value={rotate}
                                                    disabled={!imgSrc}
                                                    onChange={(e) =>
                                                        setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                                                    }
                                                /></div> */}
                                                    {/* <div>
                                                        <label htmlFor="rotate-input">Rotate: </label>
                                                        <input
                                                            id="rotate-input"
                                                            type="number"
                                                            value={rotate}
                                                            disabled={!imgSrc}
                                                            onChange={(e) =>
                                                                setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                                                            }
                                                        /></div> */}
                                                    {/* <div onClick={() => {
                                                                                        return (
                                                                                           
                                                                                            Modals.handleShowModal(
                                                                                                setShowModal,
                                                                                                showModal,
                                                                                                "UploadPrescriptionModal"
                                                                                            )
                                                                                        );
                                                                                    }} className='fs-12 rubik-400 text-gray-400 '
                                                                                    >
                                                                                      view Image
                                                                                    </div> */}


                                                </div>

                                            )}
                                            <div>
                                                {/* <button onClick={handleToggleAspectClick}>
                                                    {arvID}   Toggle aspect {aspect ? 'off' : 'on'}
                                                </button> */}
                                            </div>
                                            {pages.length > 0 && (
                                                <div>
                                                    {/* Display thumbnails of all pages */}
                                                    <div className="flex flex-wrap justify-center items-center mt-3 mb-3" >
                                                        {pages.map((pageUrl, index) => (
                                                            <div key={index} style={{ margin: '10px', cursor: 'pointer' }} onClick={() => {
                                                                handleResetCrop();
                                                                setImgSrc(pageUrl);
                                                                setOrignalimg(pageUrl)
                                                                handlePageChange(index + 1);
                                                            }}>
                                                                <img style={{ borderRadius: '4px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;', width: '100px', height: '100px', objectFit: 'cover', border: currentPage === index + 1 ? '2px solid blue' : 'none' }} src={pageUrl} alt={`Page ${index + 1}`} />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    {/* Display current page */}
                                                    {/* <div style={{ marginTop: '20px' }}>
                        <img style={{ width: '100%', height: 'auto' }} src={pages[currentPage - 1]} alt={`Page ${currentPage}`} />
                    </div> */}
                                                </div>
                                            )}

                                        </div>
                                        {!!imgSrc && (
                                            <ReactCrop
                                                crop={crop}
                                                onChange={(_, percentCrop) => {
                                                    setCrop(percentCrop);
                                                    // console.log(percentCrop);
                                                }}
                                                onComplete={(c) => {
                                                    setCompletedCrop(c);

                                                }}
                                                aspect={aspect}
                                            >
                                                <img
                                                    ref={imgRef}
                                                    alt="Crop me"
                                                    src={imgSrc}
                                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, height: "600px", width: "100%", objectFit: "cover" }}
                                                    onLoad={onImageLoad}
                                                />
                                            </ReactCrop>
                                        )}
                                        {!!completedCrop && (
                                            <>
                                                <div style={{ display: 'none' }}>
                                                    <canvas
                                                        ref={previewCanvasRef}
                                                        style={{
                                                            border: '1px solid black',
                                                            objectFit: 'contain',
                                                            width: completedCrop.width,
                                                            height: completedCrop.height,
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    {/* <button onClick={onDownloadCropClick}>Download Crop</button>
                                                <div style={{ fontSize: 12, color: '#666' }}>
                                                    If you get a security error when downloading try opening the
                                                    Preview in a new tab (icon near top right).
                                                </div> */}
                                                    {/* <a
                                                    href="#hidden"
                                                    ref={hiddenAnchorRef}
                                                    download
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-200vh',
                                                        visibility: 'hidden',
                                                    }}
                                                >
                                                    Hidden download
                                                </a> */}
                                                </div>
                                            </>
                                        )}
                                        <div className="mt-5 mb-5">
                                            <input style={{ background: '#fff', color: "#002ae0", border: "1px solid #3a52bb" }} type="file" accept="image/*,application/pdf" onChange={onSelectFile} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showPopUp && (
                        <div
                            className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 
                    overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100"
                        >
                            <div ref={modalRef} className="relative w-full  max-h-full" style={{ maxWidth: "45rem" }}>
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <div className="flex">
                                        <h2></h2>
                                    </div>
                                    <div className="p-3 text-center " >
                                        <div className="flex justify-center items-center" style={{
                                            overflow: "auto",
                                            height: "calc(100vh - 200px)", marginTop: "45px"
                                        }}>
                                            {showOrignalimg ? (
                                                <img
                                                    style={{ width: "100%", height: "auto" }}
                                                    src={orignalimg}
                                                    alt="Original Prescription"
                                                />
                                            ) : (
                                                <img
                                                    style={{ width: "100%", height: "auto" }}
                                                    src={imgSrcBlob}
                                                    alt="Modified Prescription"
                                                />
                                            )}
                                        </div>
                                        <br />

                                        {/* {showOrignalimg !== true && ( */}
                                        <button
                                            onClick={() =>
                                                dispatch(
                                                    handleReuploadPrescription(
                                                        arvID,
                                                        "ReUploadPrescriptionModal",
                                                        formData,
                                                        pageNumber,
                                                        setShowModal,
                                                        showModal,
                                                        imgSrcBlob,
                                                        setImgSrc,
                                                        setShowPopUp,
                                                        setPages
                                                    )
                                                )
                                            }
                                            style={{ backgroundColor: "#3A52BB" }}
                                            type="button"
                                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                        >
                                            {uploadPresImageResponse?.loading ? "Uploading..." : "Upload"}
                                        </button>
                                        {/* )} */}

                                        <button
                                            onClick={() => {
                                                setShowPopUp(false);
                                                setShowOrignalimg(false);
                                            }}
                                            style={{ backgroundColor: "#fff", color: "#3A52BB", border: "1px solid #3A52BB" }}
                                            type="button"
                                            className="  bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none 
    focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                        >
                                            Cancel
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>)}
                </div>
            )}

            {showModal.showToast && <SuccessToastMessage status={'Image uploaded successfully'} />}
        </>
    );
};