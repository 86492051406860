import {
	LIST_ARV_DELETE_REQUEST,
	LIST_ARV_DELETE_SUCCESS,
	LIST_ARV_DELETE_FAILURE

} from '../../../constants';


import Axios from '../../../../networking/intraceptor'
import * as path from '../../../../networking/urlEndPoints'

import { fetchARVList } from './patientARVList';

export const deleteDataRequest = () => ({
	type: LIST_ARV_DELETE_REQUEST
});

export const deleteDataSuccess = (user, types) => ({
	type: LIST_ARV_DELETE_SUCCESS,
	payload: user,
	types: types
});

export const deleteDataFailure = (error, types) => ({
	type: LIST_ARV_DELETE_FAILURE,
	payload: error,
	types: types
})



export const handleDeleteARV = (avrID, type, formData, pageNumber, setShowModal, showModal) => {
	return async (dispatch) => {
		dispatch(deleteDataRequest());
	
		try {
			const response = await Axios.post(`${path.delete_arv}/${avrID}`,
				{
					id: avrID
				})

			if (response?.status === 200) {
				dispatch(deleteDataSuccess(response?.data, type));

				if (typeof setShowModal === "function" && (type === 'DeleteARVModal')) {
					setShowModal(prevState => ({
						...prevState,
						DeleteARVModal: false,
						showToast: true
					}));

					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false
						}));
					}, 2000);

					document.body.style.overflow = 'auto'

					const params = {
						
						route: formData?.patientRoutes ? formData?.patientRoutes?.label : 'default',
						page: pageNumber,
						muteunmute: !formData?.muteValue ? 0 : formData?.muteValue?.value
					}
					dispatch(fetchARVList('arv_list',params))

				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(deleteDataFailure(error?.response, type));
			}
		}
	}
}

